import netcoServer from '../../apis/netcoServer';
import {GET_ALL_METADATA} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";


const getAllMetadata= (callbackdata=[]) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getAllMetadata', {})); 
  handleResponse(dispatch, response, GET_ALL_METADATA, onSuccessPayload, null, [], [], [], callbackdata);
};


const onSuccessPayload = data => {
  return {
    reportList: {
      kind: 'metaData',
      total: 0,
      entries: data.result
    }
  };
};
export default getAllMetadata;