import React from 'react';
import PublicLayout from "../../Layouts/Public";
import QuickSignUpForm from './QuickSignUpForm';
import {connect} from 'react-redux';
import TitleAndInfo from '../../Helpers/TitleAndInfo';
import basicRegistration from '../../../actions/auth/basicRegistration';
import logoutUser from "../../../actions/auth/logoutUser";
import modalShow from "../../../actions/modal/modalShow";
import modalDismiss from "../../../actions/modal/modalDismiss";
import isLoggedIn from "../../../actions/auth/isLoggedIn";
import {push} from 'connected-react-router';
import _ from "lodash";
import auth from "../../../const/auth";
import {email} from "../../Helpers/Input/validations";
import {CONFIRM_MODAL} from "../../Feedback/Modal/types";
import general from "../../../const/general";
import {makeID} from "../../Helpers/utils";
import hideMenu from "../../../actions/signature/hideMenu";
import logOutAfter from "../../../actions/auth/logoutAfter";
import getRegistrationOptions from "../../../actions/auth/getRegistrationOptions";

class QuickSignUp extends React.Component {

    componentDidMount()
    {
        this.props.logoutUser(true,false).then(()=>{
            this.props.isLoggedIn();
            if(this.props.hideMenuParam)
                this.props.hideMenu();
            if(this.props.logout)
                this.props.logOutAfter();
            if(this.props.createUser==="true" && this.props.uniqueID && this.props.uniqueID!=="" && this.props.initialValues.userName && this.props.initialValues.userName!=="")
            {
                const pass=makeID();
                console.log(pass);
                this.createUser({userName:this.props.initialValues.userName,password:pass,password_confirmation:pass,createUser:true});
            }
        });
    }

    quickSignUp = (formValues) =>
    {
        if(email(formValues.userName))
        {
            let msg=general[this.props.language].mailWarning;
            this.props.modalShow({
                modalType: CONFIRM_MODAL,
                modalProps: {footer: null, title: general[this.props.language].import},
                componentProps: {
                    language: this.props.language,info: msg,
                    btn: general[this.props.language].edit, onCancel:this.props.modalDismiss, onSubmit: ()=>this.createUser(formValues)}
            });
        }
        else
            this.createUser(formValues);
    };

    createUser=(formValues)=>
    {
        formValues.redirect=this.props.redirect;
        formValues.uniqueID=this.props.uniqueID;
        formValues.option=this.props.option;
        formValues.referenceNumber=this.props.referenceNumber;
        formValues.authCode=this.props.authCode;
        this.props.basicRegistration(formValues);
        this.props.modalDismiss();
    };

  render() {
      return (
      <PublicLayout title={'logo'}>
          <TitleAndInfo info={auth[this.props.language].quickregdesclbl}/>
          <QuickSignUpForm search={this.props.search}
                           language={this.props.language} push={this.props.push} onSubmit={this.quickSignUp} initialValues={this.props.initialValues}/>
      </PublicLayout>
    );
  }
}

const mapStateToProps = state => {
    let queryParams = state.router.location.search ? _.reduce(state.router.location.search.substring(1).split("&"),
      (result, value) => {
        let params = value.split("=");
        result[params[0]] = params[1];
        return result;
      }, {}) : {};
  return {
      initialValues: {
          userName: queryParams.userName,
          language: state.auth.language,
          registration: state.registration
      },
      language: state.auth.language,
      redirect: queryParams.redirect,
      option: queryParams.option,
      uniqueID: queryParams.uniqueID,
      createUser: queryParams.createUser,
      referenceNumber: queryParams.referenceNumber,
      authCode: queryParams.authCode,
      hideMenuParam:queryParams.hideMenu,
      logout:queryParams.logout,
      search:state.router.location.search
  };
};

export default connect(mapStateToProps, {basicRegistration, push,logoutUser,isLoggedIn,modalShow,modalDismiss,hideMenu,
logOutAfter, getRegistrationOptions})(QuickSignUp);
