import React, {useEffect, useRef} from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Col, Row, Typography } from "antd";
import {Field, reduxForm} from "redux-form";
import {
  CreateCheckbox,
  CreateTextField
} from "../../Helpers/Input/InputCreator";
import {numberNormalizer} from "../../Helpers/Input/normalizers";
import _ from 'lodash';
import admin from "../../../const/admin";
import general from "../../../const/general";

const TaskConfigurationForm = (props) => {

  const localProps =useRef(props);
  useEffect(() => {
    localProps.current.initialize({..._.omit(props.task, ['hasTask', 'locked']), hasTask: props.task.hasTask === 'true'});
  }, [props.task]);

  return (
      <Form onFinish={props.handleSubmit(props.setTaskConfiguration)} style={{margin:'44px 52px' }}>
        <Typography className='text-title color-black'>Firma masiva</Typography>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Typography className='text-title size12 mb-10 mt-30'/>
            <Field
                   name="hasTask"
                   component={CreateCheckbox}
                   placeholder="Activar"
                   hasFeedback>
              {admin[props.language].activate_task}
            </Field>
          </Col>
          <Col xs={24} md={12}>
            <Typography className='text-title size12 mb-10 mt-30'>{admin[props.language].in_folder_task.toUpperCase()}</Typography>
            <Field
                   name="inputFolder"
                   component={CreateTextField}
                   placeholder={admin[props.language].in_folder_task}
                   disabled={!props.hasTask}
                   hasFeedback/>
          </Col>
          <Col xs={24} md={12}>
            <Typography className='text-title size12 mb-10 mt-30'>{admin[props.language].out_folder_task.toUpperCase()}</Typography>
            <Field
                   name="outputFolder"
                   component={CreateTextField}
                   placeholder={admin[props.language].out_folder_task}
                   disabled={!props.hasTask}
                   hasFeedback/>
          </Col>
          <Col xs={24} md={12}>
            <Typography className='text-title size12 mb-10 mt-30'>{admin[props.language].minutes_task.toUpperCase()}</Typography>
            <Field
                   name="seconds"
                   component={CreateTextField}
                   style={{width: '100%'}}
                   placeholder={admin[props.language].minutes_task}
                   disabled={!props.hasTask}
                   normalize={numberNormalizer}
                   hasFeedback/>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={'mb-10 mt-30'}/>
            <Button className={'float-right'} htmlType={'submit'} type={'primary'}>{general[props.language].edit.toUpperCase()}</Button>
          </Col>
        </Row>
      </Form>
  );
};

export default reduxForm({form: 'admin_task_form'})(TaskConfigurationForm);
