import React, { useState } from 'react';
import { Form, Button } from "antd";
import { CreateCheckbox, CreateFileField, CreatePasswordField } from "../Helpers/Input/InputCreator";
import { required } from "../Helpers/Input/validations";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import importCertificateNetcoCodes from '../../actions/certificates/importCertificateNetcoCodes';
import PrivateRoute from "../Root/PrivateRoute";
import certificates from "../../const/certificates";
import { ReactComponent as OtherCertificatesIcon } from '../../resources/img/NewIcons/certificates/other-certificates.svg'
import { INFO_SHOW } from "../../actions/types";
import infoPayload from "../../actions/helpers/payloadHandlers/infoPayload";
import general from "../../const/general";

const OtherFormatsOption = (props) => {

    const [formFile, setFormFile] = useState([])
    const [formCert, setFormCert] = useState([])

    const onSubmit = (formValues) => {
        let key = formFile[0] === undefined ? null : formFile[0].file;
        let cert = formCert[0] === undefined ? null : formCert[0].file;
        formValues.language = props.language;
        formValues.requiresCert = props.requiresCert;
        props.importCertificateFromFiel(formValues, key, cert);
    };

    const handleOnDrop = (newFile, onChange, name) => {
        if (!newFile || newFile.length <= 0) {
            props.dispatch({ type: INFO_SHOW, payload: infoPayload('error', certificates[props.language].invalidFile) });
            return;
        }
        if (newFile[0]) {
            const formFileDrop = {
                file: newFile[0],
                name: newFile[0].name,
                preview: URL.createObjectURL(newFile[0]),
                size: newFile[0].size
            };
            props.change(name, formFileDrop);
            name === 'key' ? setFormFile([formFileDrop]) : setFormCert([formFileDrop])
        }
    }

    return (
        <>
            {props.renderCardCertificates(certificates[props.language].otherTitle, certificates[props.language].fiel_info, OtherCertificatesIcon, certificates[props.language].fiel_info2)}
            <Form onFinish={props.handleSubmit(onSubmit)} className={'text-title size12 mt-20'} style={{ marginLeft: 14 }}>
                <div className={'mb-10 mt-10'}>{certificates[props.language].key_file}</div>
                <Field
                    name="key"
                    component={CreateFileField}
                    handleOnDrop={handleOnDrop}
                    type='file'
                    formFile={formFile}
                    validate={required}
                    multiple={false}
                />
                <div className={'mb-10 mt-10'}>{certificates[props.language].cer_file}</div>
                <Field
                    name="cert"
                    component={CreateFileField}
                    handleOnDrop={handleOnDrop}
                    type='file'
                    formFile={formCert}
                    validate={required}
                    multiple={false}
                />
                <div className={'mb-10 mt-10'}>{general[props.language].password}</div>
                <Field
                    name="password"
                    component={CreatePasswordField}
                    size={'large'}
                    className={'mb-10'}
                    placeholder={general[props.language].password}
                    validate={[required]}
                    hasFeedback />
                <Field name="agree"
                    type={'checkbox'}
                    component={CreateCheckbox}
                    validate={required}
                    hasFeedback>
                    <a rel='noopener noreferrer' target={'_blank'} href={window.netcourlid + '/comagre/AcuerdoDeComunicaciones.pdf'}>
                        {general[props.language].agree}
                    </a>
                </Field>
                <Field name="updateGraphicImage"
                    type={'checkbox'}
                    component={CreateCheckbox}
                    hasFeedback>
                    {certificates[props.language].graphic_image}
                </Field>
                <Button htmlType={'submit'} className='mt-20' type={'primary'} style={{ marginRight: 21 }}>
                    {certificates[props.language].createZIP}
                </Button>
                <Button type={'ghost'} onClick={() => props.changeOption(0)}>
                    {certificates[props.language].cancel}
                </Button>
            </Form>
        </>

    );
};

const mapStateToProps = state => {
    return {
        language: state.auth.language,
        requiresCert: state.auth.requiresCert,
        initialValues: {
            agree: false,
            updateGraphicImage: false
        }
    }
};

export default PrivateRoute(connect(mapStateToProps, { importCertificateNetcoCodes })((reduxForm({
    form: 'netcoCertificate_form'
})(OtherFormatsOption))));

