import React from 'react';
import { connect } from "react-redux";
import { push } from 'connected-react-router';
import { Typography, Menu } from 'antd';
import timerIcon from '../../../resources/img/NewIcons/shortcuts/time.svg'
import { ReactComponent as BandejaEntradaIcon } from '../../../resources/img/NewIcons/shortcuts/bandeja-entrada.svg';
import { ReactComponent as BandejaSalidaIcon } from '../../../resources/img/NewIcons/shortcuts/bandeja-salida.svg';
import { ReactComponent as CompletadosIcon } from '../../../resources/img/NewIcons/shortcuts/completados.svg';
//import { ReactComponent as ArchivadosIcon } from '../../../resources/img/NewIcons/shortcuts/archivados.svg';
import authorization from '../../../authorization';
import {
    NEW_PENDING_FILES_ROUTE,
    MANAGEMENT_ROUTE, WORKFLOW_FILES_ROUTE
} from '../../Root/routes';
import shortcuts from '../../../const/shortcuts';
import {getMenuItems} from "../../Helpers/utils";

class ShortCuts extends React.Component {

    getShortcutItems=()=>
    {
        const items=[
            {key:NEW_PENDING_FILES_ROUTE, icon: BandejaEntradaIcon, visible:true},
            {key:MANAGEMENT_ROUTE, icon: BandejaSalidaIcon, visible:true},
            {key:WORKFLOW_FILES_ROUTE, icon: CompletadosIcon, visible:true},
        ];
        return getMenuItems(items,shortcuts[this.props.language].shortCuts_path,'shortcuts-icon',this.props.pathname);
    }

    handleItemClick = (event) =>
    {
        this.props.push(event.key);
    }

    render() {
        return (
            <div style={{ display: 'flex'}}>
                <div className='separator' style={{height: '100%'}}/>
                <div style={{minWidth:240}}>
                    <Typography className='text-title size18' style={{ marginTop: 16, marginLeft: 14, marginBottom:11 }}>
                        <img alt='shortcuts' className='shortcuts-icon' src={timerIcon} />
                        {shortcuts[this.props.language].shortcuts}
                    </Typography>
                    <Menu theme="dark"
                          mode="vertical"
                          selectedKeys={[this.props.pathname]}
                          defaultSelectedKeys={['/']} items={this.getShortcutItems()}
                          onClick={this.handleItemClick}/>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.auth.language,
        pathname: state.router.location.pathname
    };
};

export default authorization(connect(mapStateToProps, { push })(ShortCuts));
