import {GET_LDAP_USER_INFO, LOG_OUT} from "../actions/types";

const INITIAL_STATE = {
  country: null,
  mail: null,
  city: null,
  streetAddress: null,
  phone: null,
  cedula: null,
  organization: null,
  state: null,
  cn: null,
  organizationalUnit: null
};

const ldapUser= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LDAP_USER_INFO:
      return {...state, ...action.payload.ldapUser};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default ldapUser;