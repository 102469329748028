import React from 'react';
import {connect} from 'react-redux'
import modalDismiss from '../../../actions/modal/modalDismiss';
import AdminServersModal from '../../NewAdmin/AdminTables/AdminServersModal';
import AdminProfilesModal from '../../NewAdmin/AdminTables/AdminProfilesModal';
import AdminWebservicesModal from '../../NewAdmin/AdminTables/AdminWebservicesModal';
import AdminMetadataModal from '../../NewAdmin/AdminTables/AdminMetadataModal';
import AdminConfigurationsModal from '../../Admin/AdminConfigurations/AdminConfigurationsModal';
import AdminUsersModal from '../../NewAdmin/AdminTables/AdminUsersModal';
import ConfirmDeleteUserModal from "../../NewAdmin/AdminTables/ConfirmDeleteUserModal";
import SystemInformationModal from "../../Admin/SystemInformationModal";
import VerificationsModal from '../../Verification/VerificationsModal';
import PagesReportModal from '../../Report/PagesReportModal';
import MailModal from '../../Docs/MailModal';
import FolderModal from "../../Docs/FolderModal";
import NewPasswordModal from '../../Profile/NewPasswordModal';
import TemplateViewerModal from '../../Templates/TemplateTable/TemplateViewerModal';
import SignatureOptionsModal from "../../Signature/SignatureOptionsModal";
import PdfPasswordModal from "../../Signature/SignPreviewer/PdfPasswordModal";
import SignatureModal from "../../Signature/SignatureModal";
import ImageModal from "../../Auth/NetcoCodeSignUp/ImageModal";
import TokenModal from "../../CheckPayment/TokenModal";
import RejectModal from "../../Docs/RejectModal";
import WorkflowModal from "../../Docs/WorkflowModal";
import ConfirmModal from "./ConfirmModal";
import SignatureUpImageModal from '../../Signature/SignatureUpImageModal';
import RenameKey from '../../Certificate/RenameKeyModal';
import InfoKey from '../../Certificate/InfoKeyModal';
import MessageModal from "../../Docs/MessageModal";
import ZoomModal from '../../NewSignature/SignPreviewer/ZoomModal';
import GraphicChooserModal from "../../Signature/GraphicChooserModal";
import ModifySubscriptionModal from "../../Subscription/ModifySubscriptionModal";
import LoadingModal from "./LoadingModal";
import UploadTemplatesModal from "../../NewTemplates/UploadTemplatesModal";
import SavePreferenceModal from "../../NewSendAndSign/NewSign/SavePreferenceModal";
import TemplatesModal from '../../NewTemplates/TemplatesModal';
import ViewPreferenceModal from '../../NewSendAndSign/NewSign/ViewPreferenceModal';
import HandwrittenModal from '../../NewGraphicChooser/HandwrittenModal';
import NewCertificateModal from "../../Home/NewCertificateModal";
import SignAndShareModal from '../../NewSignature/SignAndShareModal';
import AddMetadataModal from "../../NewSendAndSign/NewSign/AddMetadataModal";
import AdminAddMetadataOptModal from '../../NewAdmin/AdminTables/AdminAddMetadataOptModal';
import ConfirmTransferModal from '../../NewSendAndSign/NewSign/ConfirmTransferModal';


const MODAL_COMPONENTS = {
  ADMIN_SERVERS_MODAL: AdminServersModal,
  ADMIN_PROFILES_MODAL: AdminProfilesModal,
  ADMIN_WEBSERVICES_MODAL: AdminWebservicesModal,
  ADMIN_METADATA_MODAL: AdminMetadataModal,
  ADMIN_CREATE_METADATA_OPT_MODAL: AdminAddMetadataOptModal,
  ADMIN_CONFIGURATIONS_MODAL: AdminConfigurationsModal,
  ADMIN_USERS_MODAL: AdminUsersModal,
  VERIFICATIONS_MODAL: VerificationsModal,
  PAGES_REPORT_MODAL: PagesReportModal,
  MAIL_MODAL: MailModal,
  NEW_PASSWORD_MODAL: NewPasswordModal,
  TEMPLATE_VIEWER_MODAL: TemplateViewerModal,
  SIGNATURE_OPTIONS_MODAL: SignatureOptionsModal,
  SYSTEM_INFORMATION_MODAL: SystemInformationModal,
  PDF_PASSWORD_MODAL: PdfPasswordModal,
  SIGNATURE_MODAL: SignatureModal,
  IMAGE_MODAL: ImageModal,
  TOKEN_MODAL:TokenModal,
  FOLDER_MODAL: FolderModal,
  DELETE_USER_MODAL: ConfirmDeleteUserModal,
  REJECT_MODAL:RejectModal,
  WORKFLOW_MODAL:WorkflowModal,
  CONFIRM_MODAL:ConfirmModal,
  SIGNATURE_UPIMAGE_MODAL : SignatureUpImageModal,
  RENAME_KEY : RenameKey,
  INFO_KEY : InfoKey,
  MESSAGE_MODAL : MessageModal,
  ZOOM_MODAL : ZoomModal,
  SIGNATURE_CHOOSER_MODAL: GraphicChooserModal,
  MODIFY_SUBSCRIPTION_MODAL: ModifySubscriptionModal,
  UPLOAD_TEMPLATES_MODAL: UploadTemplatesModal,
  LOADING_MODAL:LoadingModal,
  NEW_PREFERENCE_MODAL: SavePreferenceModal,
  TEMPLATES_MODAL: TemplatesModal,
  NEW_VIEW_PREFERENCE_MODAL: ViewPreferenceModal,
  HANDWRITTEN_MODAL: HandwrittenModal,
  NEW_CERTIFICATE_MODAL: NewCertificateModal,
  SIGN_AND_SHARE: SignAndShareModal,
  METADATA_MODAL: AddMetadataModal,
  CONFIRM_TRANSFER_MODAL: ConfirmTransferModal
};

const Modal = (props) => {

  if (!props.modal.modalType) {
    return null
  }

  const dismissModal = () => {
    props.modalDismiss();
  };

  const SpecificModal = MODAL_COMPONENTS[props.modal.modalType];
  const modalProps = {
    onOk: dismissModal,
    onCancel: dismissModal,
    maskClosable: true,
    ...props.modal.modalProps
  };
  return <SpecificModal modalProps={modalProps} {...props.modal.componentProps} />;

};

function mapStateToProps(state) {
  return {
    modal: state.modal
  }
}

export default (connect(mapStateToProps, {modalDismiss})(Modal));
