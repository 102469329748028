import netcoServer from '../../apis/netcoServer';
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";
import getKeysInfo from "./getKeysInfo";

const updateKeyName= (id, keyName) => async (dispatch) => {
  let formData = {keyIDS:id,keyName:keyName};
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/updateKeyName', formData));
  handleResponse(dispatch, response, null, null, null, [getKeysInfo]);
};
export default updateKeyName;