import React from 'react';
import {Field, reduxForm} from 'redux-form';
import {CreatePasswordField, CreateTextField} from "../../Helpers/Input/InputCreator";

// import '@ant-design/compatible/assets/index.css';


import {Form, Button, Typography} from 'antd';

// import 'antd/lib/form/style/css';


// import 'antd/lib/row/style/css';


// import 'antd/lib/col/style/css';


import '../../Helpers/newCustomAnt.css';
import {userNormalizer} from "../../Helpers/Input/normalizers";
import auth from "../../../const/auth";
import {getLoginPath, renderIDPS, renderSeparator} from "../../Helpers/utils";
import {connect} from "react-redux";
import PasswordPolicies from "../../PasswordPolicies";

import getRegistrationOptions from "../../../actions/auth/getRegistrationOptions";
import {push} from "connected-react-router";

class QuickSignUp extends React.Component {

  required = (value) => {return value ? undefined : auth[this.props.language].requiredfld;};

  componentDidMount()
  {
    this.props.getRegistrationOptions();
  }

  render() {
      let userName=this.props.initialValues.userName;
    if(!userName)
        userName="";
    let idps=this.props.registration.idps;
      return (
      <Form className={'regularForm'} onFinish={this.props.handleSubmit(this.props.onSubmit)}>
        {renderIDPS(idps,this.props.search,userName,auth[this.props.language].signUpWith)}
        {renderSeparator(idps,auth[this.props.language].messageHome3)}
        <Typography className='size12' style={{ fontWeight: 700 }}>
          {auth[this.props.language].maillbl.toUpperCase()}</Typography>
        <Field placeholder={auth[this.props.language].usernamelbl}
               name="userName"
               disabled={userName !== ""}
               size={'large'} className={'mb-10'}
               component={CreateTextField}
               validate={this.required}
               normalize={userNormalizer}
               maxLength={50}
               hasFeedback/>
        <Typography className='text-title size12' style={{ width: '50%' }}>
          {auth[this.props.language].passwordlbl.toUpperCase()}</Typography>
        <Field placeholder={auth[this.props.language].passwordlbl}
               name="password"
               size={'large'} className={'mb-10'}
               component={CreatePasswordField}
               validate={(value) => {return value ? undefined : auth[this.props.language].requiredfld;}}
               hasFeedback/>
        <PasswordPolicies/>
        <Typography className='text-title size12' style={{ width: '50%' }}>
          {auth[this.props.language].passconfirmlbl.toUpperCase()}</Typography>
        <Field placeholder={auth[this.props.language].passwordlbl}
               name="password_confirmation"
               size={'large'} className={'mb-10'}
               component={CreatePasswordField}
               validate={this.required}
               hasFeedback/>
        <Form.Item className={'text-center'}>
          <Button type="primary light-primary rounded" htmlType="submit">
            {auth[this.props.language].newuserbtn}
          </Button>
        </Form.Item>
          <Form.Item className={'text-center'} >
            <div className='center' style={{ display: 'flex', width: '261px', marginTop: 15 }}>
              <Typography className='size15 color-black'>
                {auth[this.props.language].userExistsLabel}</Typography>
              <Typography className={'size15 color-black text-link'} onClick={() => this.props.dispatch(push(getLoginPath(this.props.search)))} style={{ paddingLeft: '2px', marginBottom:43 }}>
                {auth[this.props.language].signinbtn}</Typography>
            </div>

          </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    registration: state.registration
  };
};

export default  connect(mapStateToProps, {getRegistrationOptions})(reduxForm({
  form: 'quick_signup_form'
})(QuickSignUp))
