import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Modal } from "antd";

// import 'antd/lib/modal/style/css';


import {reduxForm} from "redux-form";
import TitleAndInfo from '../../Helpers/TitleAndInfo';
import auth from "../../../const/auth";

class ImageModal extends React.Component {
   
  cancelImage =() =>{
	  sessionStorage.removeItem("base64Signature");
	  this.props.modalProps.onCancel();	  
  }
    
  render() {
    return (
      (<Modal
          open={this.props.modalType !== null} width={400} {...this.props.modalProps} title={auth[this.props.language].modaltitle}>
        <Form onFinish={this.props.handleSubmit(this.props.onSubmit)}>
          <TitleAndInfo info={auth[this.props.language].modalmsg}/>
          <div style={{'text-align': 'center'}}>
          <img alt={''} src={`data:image/png;base64, ${this.props.newImage}`}/>
          <br/>
          <br/>
          <Button type={'primary'} onClick={this.props.modalProps.onCancel}>{auth[this.props.language].yesmsg}</Button>
          <Button type={'primary'} onClick={this.cancelImage}>{auth[this.props.language].nomsg}</Button>
          </div>
        </Form>
      </Modal>)
    );
  }


}

export default reduxForm({form: 'image_modal_form'})(ImageModal);