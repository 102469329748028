import netcoServer from '../../apis/netcoServer';
import { GET_METADATA_OPTIONS } from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const getMetadataOptions = (metadataIDs) => async (dispatch) => {
  const allMetadataOptions = []

  const onSuccessPayload = data => {
    allMetadataOptions.push(data.result)
  }

  for (let i = 0; i < metadataIDs.length; i++) {
    const entry = metadataIDs[i];

    if (entry.type === 'select' || entry.type === 'checkbox') {
      let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getMetadataOptions', { metadataID: entry.id }));
      handleResponse(dispatch, response, null, onSuccessPayload, null);
    }

    if (metadataIDs.length === i + 1) {
      dispatch({ type: GET_METADATA_OPTIONS, payload: { options: allMetadataOptions.flat() } });
    }
  }
};


export default getMetadataOptions;