import React from 'react';
import {Button, Col, Input, Radio, Row, Select, Space} from "antd";
import {currencyFormat} from "../Helpers/utils";
import {INFO_SHOW} from "../../actions/types";
import infoPayload from "../../actions/helpers/payloadHandlers/infoPayload";
import Cards from "react-credit-cards";
import subscriptions from "../../const/subscriptions";

class CreditCardForm extends React.Component
{
    //initialState = {cvc: '123',expiry: '1225',focus: '',name: 'Diego Garzon',number: '4575623182290326', docNumber:'80210717', cost:0,currentPlan:null};
    initialState={cvc: '',expiry: '',focus: '',name: '',number: '', docNumber:'', currentPlan:null};
    state = this.initialState;

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(this.props.resetForm && !prevProps.resetForm)
            this.setState(this.initialState);
    }

    handleInputFocus = (e) => {
        this.setState({ focus: e.target.name });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    submitForm=()=>
    {
        const formValues={};
        if(this.props.showPlans)
        {
            const plan=this.state.currentPlan;
            if(!plan)
            {
                this.props.dispatch({type: INFO_SHOW,payload: infoPayload('error', subscriptions[this.props.language].planRequiredError)});
                return;
            }
            formValues.planID=plan;
        }
        formValues.creditCardNumber=this.state.number;
        formValues.expirationMonth=this.state.expiry.substring(0,2);
        formValues.expirationYear=this.state.expiry.substring(2,4);
        formValues.cvc=this.state.cvc;
        if(this.props.showDocument)
        {
            formValues.documentType='CC';
            formValues.documentNumber=this.state.docNumber;
        }
        formValues.customerName=this.state.name;
        this.props.submitForm(formValues);
    };

    render()
    {
        return (
            <Row>
                <Col style={{marginBottom:'20px'}} xs={24} sm={10} xl={8}>
                    <Cards cvc={this.state.cvc} expiry={this.state.expiry} focused={this.state.focus} name={this.state.name} number={this.state.number}/>
                </Col>
                <Col xs={24} sm={14} xl={16}>
                    <Space direction={"vertical"}>
                        <Input value={this.state.number} placeholder={subscriptions[this.props.language].cardNumber} maxLength={16} type={'tel'} name={"number"}
                               onChange={this.handleInputChange} onFocus={this.handleInputFocus}/>
                        <Input value={this.state.name} placeholder={subscriptions[this.props.language].customerName} maxLength={50} name={"name"}
                               onChange={this.handleInputChange} onFocus={this.handleInputFocus}/>
                        <Row>
                            <Input value={this.state.expiry} style={{width: '50%'}} placeholder={subscriptions[this.props.language].expirationDate} maxLength={4}
                                   name={"expiry"} onChange={this.handleInputChange} onFocus={this.handleInputFocus}/>
                            <Input value={this.state.cvc} style={{width: '50%'}} placeholder={subscriptions[this.props.language].securityCode} type={'tel'}
                                   maxLength={3} name={"cvc"} onChange={this.handleInputChange} onFocus={this.handleInputFocus}/>
                        </Row>
                        {this.props.showDocument &&<Row>
                            <Select style={{width:'50%'}} value={'CC'} name={'docType'}><Select.Option value={'CC'}>ID / Cédula de ciudadania</Select.Option></Select>
                            <Input value={this.state.docNumber} style={{width: '50%'}} placeholder={subscriptions[this.props.language].docNumber} maxLength={20}
                                   name={"docNumber"} onChange={this.handleInputChange} onFocus={this.handleInputFocus}/>
                        </Row>}
                        {this.props.showPlans && this.props.plans && this.props.plans.length>0 && <>
                            <Row>
                                <Radio.Group name={'currentPlan'} value={this.state.currentPlan}
                                             onChange={(e)=>this.setState({currentPlan:e.target.value})}>
                                    <Space direction="vertical">
                                        {this.props.plans.map(plan=><Radio key={plan.id} value={plan.id}>{plan.description} ({currencyFormat(plan.cost,plan.currency)})</Radio>)}
                                    </Space>
                                </Radio.Group>
                            </Row></>}

                        <Button type={'primary'} onClick={this.submitForm}>{this.props.buttonLabel}</Button>
                    </Space>
                </Col>
            </Row>
        );
    }
}

export default CreditCardForm;