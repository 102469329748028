import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import {Form,Button, Col, Radio, Row} from "antd";
import {Field, reduxForm} from "redux-form";
import {CreateRadioGroup, CreateTextField} from "../../Helpers/Input/InputCreator";
import admin from "../../../const/admin";
import general from "../../../const/general";
import {required} from "../../Helpers/Input/validations";
import signed_files from "../../../const/signed_files";
import { Typography } from 'antd';

const DeletePublicForm = (props) => {
  return (
      <Form initialValues={props.initialValues} onFinish={props.handleSubmit(props.deletePublicFiles)} style={{margin:'44px 52px' }}>
        <Typography className='text-title color-black'>Borrar archivos</Typography>
          <Row gutter={16}>
              <Col xs={24} md={12}>
                  <div style={{marginTop: '30px'}} className={'mb-10'}/>
                  <Field
                         name="published"
                         component={CreateRadioGroup}
                         hasFeedback>
                      <Radio value={false}>{signed_files[props.language].public_tab}</Radio>
                      <Radio value={true}>{signed_files[props.language].private_tab}</Radio>
                  </Field>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col xs={24} md={12}>
                  <Typography className='text-title size12 mb-10' style={{marginTop:'10px'}}>{admin[props.language].user_column.toUpperCase()}</Typography>
                  <Field
                         name="userName"
                         component={CreateTextField}
                         validate={[required]}
                         placeholder={admin[props.language].user_column}
                         hasFeedback/>
              </Col>
          </Row>
          <Row gutter={16}>
              <Col xs={24} md={12}>
                <Typography className='text-title size12 mb-10' style={{marginTop:'10px'}}>{admin[props.language].uids.toUpperCase()}</Typography>
                  <Field
                         name="uids"
                         component={CreateTextField}
                         placeholder={admin[props.language].uids}
                         hasFeedback/>
              </Col>
          </Row>
          <Row>
              <Col>
                  <div style={{marginTop: '10px'}} className={'mb-10'}/>
                <Button className={'float-right mt-30'} htmlType={'submit'} type={'primary'}>{general[props.language].delete}</Button>
              </Col>
          </Row>
      </Form>
  );
};

export default reduxForm({form: 'admin_delete_public_form'})(DeletePublicForm);
