import {GET_SIGNATUREQR} from "../types";

const updateHWImage= (info=undefined) => async (dispatch) => {
  const data = {
    signatureFiles: {
      infoImageQR: info!=='' && info?JSON.parse(info.replaceAll('\\','"')):{base64:''},
    }
  }
  dispatch({type:GET_SIGNATUREQR, payload:data})
};

export default updateHWImage;