import {
  LOG_OUT,
  GET_CONFIGURATION,
  GET_LDAP_CONFIGURATION, GET_TASK_CONFIGURATION,
  GET_CUSTOMIZATION
} from "../actions/types";

const INITIAL_STATE = {
  configuration: [],
  ldap: {},
  task: {},
  customization:{}
};

const adminConfigurationReducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONFIGURATION:
    case GET_TASK_CONFIGURATION:
    case GET_CUSTOMIZATION:
    case GET_LDAP_CONFIGURATION:
      return {...state, ...action.payload.adminConfiguration};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default adminConfigurationReducer;