import {
  HIDE_MODAL, LOG_OUT,
  SHOW_MODAL
} from "../actions/types";

const INITIAL_STATE = {
  modalType: null,
  modalProps: {},
  componentProps: {}
};

const modalReducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {...state, ...action.payload};
    case HIDE_MODAL:
      return INITIAL_STATE;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default modalReducer;