import {
  GET_REGISTRATION_OPTIONS
} from "../actions/types";

const INITIAL_STATE = {
  quick: false,
  data: false,
  codes: false,
  id: false
};

const registrationReducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REGISTRATION_OPTIONS:
      return {...state, ...action.payload.registration};
    default:
      return state;
  }
};
export default registrationReducer;