import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import {Modal, Typography, Row} from "antd";

// import 'antd/lib/modal/style/css';


import { ReactComponent as VerifyingIcon } from '../../../resources/img/NewIcons/verification/verifyingIcon.svg';

class LoadingModal extends React.Component {

    render()
    {
        return (
            (<Modal centered={true} open={true} closable={false} width={300} height={300} footer={null}>
                <Row style={{paddingTop:'40px'}} justify={'center'}>
                    <VerifyingIcon />
                    <Typography className='size15' style={{textAlign:'center', marginTop:'30px'}}>{this.props.text}</Typography>
                </Row>
            </Modal>)
        );
    }
}
export default LoadingModal;