import netcoServer from '../../apis/netcoServer';
import {SEND_SIGNED_FILES_UID_MAIL} from "../types";
import handleResponse from "../helpers/handleResponse";
import _ from 'lodash';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import modalDismiss from '../modal/modalDismiss';
import loadingCall from "../helpers/loadingCall";

const sendSignedFilesUIDMail= (formValues, selectedFiles) => async (dispatch) => {
  formValues.uids =_.join(selectedFiles, ',');
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/sendSignedFilesUIDMail', formValues));
  handleResponse(dispatch, response, SEND_SIGNED_FILES_UID_MAIL, onSuccessPayload, null, [modalDismiss]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Archivos enviados al destinatario correctamente!');
};
export default sendSignedFilesUIDMail;