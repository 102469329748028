import netcoServer from '../../apis/netcoServer';
import {DELETE_WEBSERVICE_USER} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import getAllWebserviceUsers from "./getAllWebserviceUsers";
import loadingCall from "../helpers/loadingCall";

const deleteWebserviceUser= (id) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/deleteWebserviceUser', {id: id}));
  handleResponse(dispatch, response, DELETE_WEBSERVICE_USER, onSuccessPayload, null, [getAllWebserviceUsers]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'IP autorizada eliminada satisfactoriamente!');
};
export default deleteWebserviceUser;