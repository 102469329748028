import React from "react";
import { CloudUploadOutlined } from '@ant-design/icons';

// import 'antd/lib/icon/style/css';


import {Col, Row, Typography} from "antd";

const Placeholder = ({getInputProps, getRootProps, error, touched, label}) => (
  <div
    {...getRootProps()}
    className={`placeholder-preview ${error && touched ? "has-error" : ""}`}
  >
    <input {...getInputProps()} />
    <Row style={{background: 'var(--colorPrimaryOpacity)'}} align={'middle'} justify={'center'} className={'h-100'}>
      <Col span={24}>
        <CloudUploadOutlined style={{fontSize: '50px'}} />
        <div>
          <span style={{fontWeight:'bold', color:'black'}}>Haz clic aquí </span><span style={{color:'black'}}>o arrastra los documentos para añadirlos</span>
        </div>
        <Typography>{label}</Typography>
      </Col>
    </Row>
  </div>
);

export default Placeholder;
