import React from 'react';
import {connect} from 'react-redux'
import getProfileInfo from '../../../actions/admin/getProfileInfo';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Col, Modal, Row, Select } from "antd";

// import 'antd/lib/modal/style/css';


import {Field, reduxForm} from "redux-form";
import {CreateCheckbox, CreateSelectField, CreateTextField} from "../../Helpers/Input/InputCreator";
import {required, requiredNumber} from "../../Helpers/Input/validations";
import {numberNormalizer} from "../../Helpers/Input/normalizers";
import admin from "../../../const/admin";
import getAllAuthTypes from "../../../actions/admin/getAllAuthTypes";


class AdminProfilesModal extends React.Component {

  state = {formFile: []};

  componentDidMount() {
    this.props.getProfileInfo(this.props.entryId);
    this.props.getAllAuthTypes();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.initialValues !== prevProps.initialValues) {
      this.props.initialize(this.props.initialValues);
    }
  }

  renderAuthTypes=()=>
  {
    if(this.props.authTypes)
      return this.props.authTypes.map((authType)=><Select.Option key={authType.id} value={authType.id}>{authType.description}</Select.Option>);
    return <></>;
  };

  render() {
    return (
      (<Modal
        open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
        <Form onFinish={this.props.handleSubmit(this.props.onSubmit)}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].profile_column}</div>
              <Field
                     name="description"
                     component={CreateTextField}
                     placeholder={admin[this.props.language].profile_column}
                     validate={required}
                     hasFeedback/>
            </Col>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].authentication_column}</div>
              <Field
                     name="authTypeId"
                     component={CreateSelectField}
                     placeholder={admin[this.props.language].authentication_column}
                     validate={required}
                     hasFeedback>
                {this.renderAuthTypes()}
              </Field>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].min_psw_column}</div>
              <Field
                     name="passMinLength"
                     component={CreateTextField}
                     style={{width: '100%'}}
                     placeholder={admin[this.props.language].min_psw_column}
                     validate={requiredNumber}
                     normalize={numberNormalizer}
                     hasFeedback/>
            </Col>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].max_psw_column}</div>
              <Field
                     name="passMaxLength"
                     component={CreateTextField}
                     style={{width: '100%'}}
                     placeholder={admin[this.props.language].max_psw_column}
                     validate={requiredNumber}
                     normalize={numberNormalizer}
                     hasFeedback/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].blocked_column}</div>
              <Field
                     name="failedAttempts"
                     component={CreateTextField}
                     style={{width: '100%'}}
                     placeholder={admin[this.props.language].blocked_column}
                     validate={requiredNumber}
                     normalize={numberNormalizer}
                     hasFeedback/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Field name="requireUPLOWLT"
                     component={CreateCheckbox}
                     placeholder="Mayusc y Minusc"
                     hasFeedback>
                {admin[this.props.language].up_down_column}
              </Field>
            </Col>
            <Col xs={24} md={12}>
              <Field name="requireSPECIALC"
                     component={CreateCheckbox}
                     placeholder="Simbolos"
                     hasFeedback>
                {admin[this.props.language].symbol_column}
              </Field>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Field name="isAdmin"
                     component={CreateCheckbox}
                     placeholder="Administrador"
                     hasFeedback>
                {admin[this.props.language].admin_column}
              </Field>
            </Col>
            <Col xs={24} md={12}>
              <Field name="isTxAuth"
                     component={CreateCheckbox}
                     placeholder="Autenticacion por tx"
                     hasFeedback>
                {admin[this.props.language].tx_column}
              </Field>
            </Col>
          </Row>
          <Row>
            <Button htmlType={'submit'} type={'primary'}>{this.props.btn}</Button>
          </Row>
        </Form>
      </Modal>)
    );
  }
  
}

function mapStateToProps(state) {
  return {
    language: state.auth.language,
    webservice: state.reportList.selectedEntry,
    initialValues: state.reportList.selectedEntry,
    authTypes:state.reportList.authTypes
  }
}

export default connect(mapStateToProps, {getProfileInfo,getAllAuthTypes})(reduxForm({form: 'admin_profiles_form'})(AdminProfilesModal));