import netcoServer from '../../apis/netcoServer';
import {INFO_SHOW, UPDATE_PROFILE} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import modalDismiss from "../modal/modalDismiss";
import _ from "lodash";
import loadingCall from "../helpers/loadingCall";
import profile from "../../const/profile";

const updateProfile= (formValues, callback) => async (dispatch, getState) => {
  let formData = _.omit(formValues, 'authTypeId', 'isAdmin', 'isTxAuth');
  if(parseInt(formData.passMinLength)>parseInt(formData.passMaxLength))
  {
    dispatch({type: INFO_SHOW, payload: infoPayload('error', profile[getState().auth.language].minMaxError)})
    return;
  }
  formData.authTypeDescription = formValues.authTypeId;
  formData.admin = formValues.isAdmin;
  formData.txAuth = formValues.isTxAuth;
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/updateProfile', formData));
  handleResponse(dispatch, response, UPDATE_PROFILE, onSuccessPayload, null, [modalDismiss], [callback]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Perfil actualizado satisfactoriamente!');
};
export default updateProfile;