
const certificates = {
  es: {
    createPKI: 'Crear certificado con NETCO PKI',
    createP12: 'Registrar certificado',
    createZIP: 'Importar certificado',
    createCSR: 'Crear Solicitud CSR',
    pkiTitle: 'Códigos Netco PKI',
    pkcs12Title: 'PKCS#12, P12, PFX',
    zipTitle: '.ZIP, .CER o .CRT',
    otherTitle: 'Otros formatos de certificación (.cer, .crt .key)',
    title: 'Tipo de certificado',
    main_info: 'Nuestra plataforma puede utilizar cualquier tipo de certificado estándar X509V3. Tenemos las siguientes opciones para que importes y actives el certificado que te haya generado cualquier entidad de certificación.',
    netco_info: 'Si tu empresa ya tiene nuestra solución Netco PKI da clic aquí',
    netco_info2: 'Tu empresa ya cuenta con el servicio Netco PKI, aquí podrás activar tu certificado digital corporativo con los códigos y números de referencia que te envío el administrador a tu email. ',
    netco_info3: 'Puedes copiar y pegar el número de referencia y código de autorización que enviamos a tu correo.',
    entrust_sm_info: 'Crea tu certificado con Entrust SM',
    entrust_epf_info: 'Crea tu certificado con Entrust EPF',
    p12_info: 'Si tu entidad certificadora te entregó un archivo (P12 o PFX) da clic aquí',
    p12_info2: 'Registra tu identidad digital (P12 o PFX) para habilitar tu firma digital en nuestra plataforma.',
    andes_info: 'Crea tu solicitud de certificado de Andes SCD',
    andes_info2: 'Digita el código que llegó a tu email.',
    csr_info: 'Crea una solicitud de certificado (CSR) para enviar a tu entidad certificadora.',
    csr_info3: 'Diligencia tus datos y crea tu solicitud.',
    csr_info2: 'No está permitido el uso de los caracteres \',\' o  \'=\' en los campos ',
    zip_info: 'Si tu entidad certificadora te entregó  un .cer, .crt o un .zip da clic aquí',
    zip_info2: 'Importa tu certificado con .ZIP, .CER o con .CRT',
    fiel_info: 'Otras entidades te pueden generar certificados digitales. Impórtalos y actívalos dando clic aquí',
    fiel_info2: 'Importa el certificado (.cer y .key) y utilízalo en Netco Signer para firmar tus documentos. ',
    directory_info: 'Crea tu certificado con Directorio',
    reference_number: 'Número de referencia',
    authorization_code: 'Código de autorización',
    graphic_image: 'Crear imagen gráfica usando el nombre en el certificado',
    epf_file: 'Seleccionar archivo .epf',
    p12_file: 'Selecciona el archivo (P12 o PFX) que quieres registrar',
    zip_file: 'Selecciona el archivo que quieres importar',
    key_file: 'Seleccionar archivo .key',
    cer_file: 'Seleccionar archivo .cer o .crt',
    nameLabel: 'Nombres:',
    lastNameLabel: 'Apellidos:',
    complete_name: 'Nombre completo:',
    email: 'Correo electrónico:',
    idTypeLabel: 'Tipo de Identificación:',
    identification: 'ID/Cédula:',
    country: 'País de residencia:',
    countrySmall: 'País',
    department: 'Departamento o estado:',
    city: 'Ciudad de residencia:',
    address: 'Dirección:',
    phone: 'Teléfono:',
    company_id: 'NIT Empresa:',
    company_state: 'Departamento de la Empresa:',
    company_title: 'Cargo:',
    company_address: 'Dirección de la empresa',
    cert_valid: 'Vigencia del certificado:',
    attach_public: 'Carga el documento que acredita función pública en PDF:',
    attach_id: 'Carga tu documento en PDF:',
    attach_rut: 'Carga el RUT de la empresa en PDF:',
    attach_cert: 'Carga el certificado laboral en PDF:',
    attach_trade: 'Carga el certificado de camara y comercio en PDF:',
    clickUploadLabel: 'Clic para cargar archivo',
    company_name: 'Nombre de la empresa:',
    company_area: 'Área de la empresa:',
    processok: 'Certificado importado satisfactoriamente!',
    processcsrok: 'CSR generado satisfactoriamente!',
    invalidFile: 'Archivo con extensión inválida',
    certExists: 'El usuario ya tiene un certificado importado en la herramienta, al importar uno nuevo se reemplazará el anterior, ¿Desea continuar?',
    nameKey: 'Nombre de la llave',
    changeNameKey: 'Cambiar nombre de la llave',
    changeNameKeyBtn: 'Cambiar nombre',
    infoKey: 'Información de la llave',
    confirmDelete: '¿Está seguro de eliminar este certificado?',
    certificate: 'Certificado',
    certificates: 'Certificados',
    since: 'Valido Desde',
    until: 'Valido Hasta',
    manage: 'Gestiona y organiza tus certificados.',
    use: 'Usar',
    subjectData: 'Datos del titular',
    commonName: 'Nombre común (CN)',
    organization: 'Organización',
    issuerData: 'Datos del emisor',
    certificateData: 'Datos del certificado',
    hsm: 'HSM',
    delete: 'Eliminar llave',
    id: 'Identificador',
    publicKey: 'Llave pública',
    certLabelFor9: 'Pertenencia a empresa 1 año',
    certLabelFor8: 'Representante Legal 1 año',
    certLabelFor12: 'Función pública 1 año',
    certLabelFor13: 'Persona Juridica 1 año',
    selectCertificateType: 'Selecciona el tipo de certificado que deseas solicitar',
    notFoundCertLabel: 'No hay productos asignados para el token y el usuario actual',
    createAndesBtn: 'Crear certificado',
    andes: 'Andes SCD',
    detailsAction: 'Ver detalles',
    deleteAction: 'Borrar',
    downloadAction: 'Descargar',
    certificateInUse: 'Certificado en uso',
    useCertificate: 'Usar certificado',
    firstStep: 'Primeros pasos: implementa o importa tu certificado',
    addNewCertificate: 'Añadir nuevo certificado',
    startCertificates: 'Empieza a generar Certificados',
    startDescription: 'Nuestra plataforma puede utilizar cualquier tipo de certificado estándar X509V3. Tenemos las siguientes opciones para que importes y actives el certificado que te haya generado cualquier entidad de certificación.',
    fromHere: 'desde aquí',
    changeOption: 'Cambiar opción',
    addCertificates: 'Añade certificados',
    selectOption: '1. Selecciona el tipo de certificado',
    cancel: 'Cancelar',
    cc: 'Cédula de ciudadanía',
    ce: 'Cédula de extranjería',
    pas: 'Pasaporte',
    steps: [
      '1. Genera tu solicitud de certificado digital (CSR)',
      '2. Envía el archivo CSR a la entidad de certificación',
      '3. Descarga tu certificado digital o si tu certificado fue emitido por Netco PKI, utiliza los códigos de autorización y referencia enviados al correo',
      '4. Añade un nuevo certificado'
    ],
    getCertTitle:'Configura tu certificado',
    getCertLabel:'Obten tu certificado de forma rápida y sencilla con Netco.',
    getCertDescLabel:'Recuerda que tu certificado garantiza que tu firma cuente con mayor seguridad',
    getCertButton:'Gestionar mi Certificado',
    dontShowAgain:'No volver a mostrar',
    generalTitle:'Datos Generales',
    addressTitle: 'Domicilio',
    companyTitle: 'Empresa',
    confirmationTitle:'Confirmación',
    checkMailTitle:'Revisa tu bandeja de entrada',
    checkMailDesc:'Te hemos enviado un correo electrónico un código de seguridad para que finalices el proceso de registro.',
  },
  en: {
    createPKI: 'Create certificate with NETCO PKI',
    createP12: 'Register certificate',
    createZIP: 'Import certificate',
    createCSR: 'Request Certificate CSR',
    pkiTitle: 'Netco PKI codes',
    pkcs12Title: 'PKCS#12',
    zipTitle: '.ZIP, .CER or .CRT',
    otherTitle: 'Other certification formats (.cer, .crt .key)',
    title: 'Type of certificate',
    main_info: 'Choose the certificate to be issued',
    netco_info: 'Create your certificate with Netko PKI',
    netco_info2: 'Your company uses Netco PKI, here you will activate your digital certificate with codes sent by your manager',
    netco_info3: 'You can copy and paste the reference number and authorization code that we sent to your email.',
    entrust_sm_info: 'Create your certificate with Entrust SM',
    entrust_epf_info: 'Create your certificate with Entrust EPF',
    p12_info: 'Create your certificate with P12',
    p12_info2: 'Register your digital identity (P12 o PFX) to enable your digital signature in Netco Signer',
    andes_info: 'Request your certificate to Andes SCD',
    andes_info2: 'Enter the token you got when you bought the certificate',
    csr_info: 'Create your certificate with CSR',
    csr_info3: 'Enter your information and create your request',
    csr_info2: 'Characters \',\' or  \'=\' are not allowed on any field ',
    zip_info: 'Create your certificate with ZIP',
    zip_info2: 'Import your certificate with .ZIP, .CER or .CRT',
    fiel_info: 'Create your certificate with Fiel Mexico',
    fiel_info2: 'Import the certificate of Firma Fiel given by SAT (.cer or .key) and use it in Netco Signer to sign your documents',
    directory_info: 'Create your certificate with Directorio',
    reference_number: 'Reference number',
    authorization_code: 'Authorization code',
    graphic_image: 'Create graphic image using the name on the certificate',
    epf_file: 'Select file .epf',
    p12_file: 'Select file .p12',
    zip_file: 'Select file .zip',
    key_file: 'Select file .key',
    cer_file: 'Select file .cer or .crt',
    nameLabel: 'Name:',
    lastNameLabel: 'Last Name:',
    complete_name: 'Full name:',
    email: 'Email:',
    idTypeLabel: 'ID Type:',
    identification: 'ID/Identification card:',
    country: 'Country of residence:',
    countrySmall: 'Country',
    department: 'Department or state:',
    city: 'City of residence:',
    address: 'Address:',
    phone: 'Phone number:',
    company_id: 'Company NIT:',
    company_state: 'Company State',
    company_title: 'Title:',
    company_address: 'Company Address:',
    cert_valid: 'Certificate Validity:',
    attach_public: 'Attach PDF Public Function file:',
    attach_id: 'Attach PDF ID File:',
    attach_rut: 'Attach PDF RUT File',
    attach_cert: 'Attach PDF Certificate of employment',
    attach_trade: 'Attach PDF trade certificate',
    clickUploadLabel: 'Click to upload file',
    company_name: 'Name of the company:',
    company_area: 'Area of the company:',
    processok: 'Certificate imported succesfully',
    processcsrok: 'CSR generated succesfully',
    invalidFile: 'Invalid file extension',
    certExists: 'Your user already has a certificate, If you import a new one it will be replaced, Dow you like to continue?',
    nameKey: 'Key name',
    changeNameKey: 'Rename key',
    changeNameKeyBtn: 'Rename',
    infoKey: 'Key information',
    confirmDelete: 'Are you sure to delete this certificate?',
    certificate: 'Certificate',
    certificates: 'Certificates',
    since: 'Valid Since',
    until: 'Valid Until',
    manage: 'Manage certificates',
    use: 'Use',
    subjectData: 'Subject Data',
    commonName: 'Common name(CN)',
    organization: 'Company',
    issuerData: 'Issuer data',
    certificateData: 'Certificate data',
    hsm: 'HSM',
    delete: 'Delete key',
    id: 'Identifier',
    publicKey: 'Public key',
    selectCertificateType: 'Choose the certificate type you want to request',
    certLabelFor9: 'Pertenencia a empresa 1 año',
    certLabelFor8: 'Representante Legal 1 año',
    certLabelFor12: 'Función pública 1 año',
    certLabelFor13: 'Persona Juridica 1 año',
    notFoundCertLabel: 'No assigned products found for current token and user',
    createAndesBtn: 'Create certificate',
    andes: 'Andes SCD',
    detailsAction: 'View details',
    deleteAction: 'Delete',
    downloadAction: 'Download',
    certificateInUse: 'Certificate in use',
    useCertificate: 'Use certificate',
    firstStep: 'First steps: deploy or import your certificate',
    addNewCertificate: 'Add new certificate',
    startCertificates: 'Start generating Certificates',
    startDescription: 'Our platform can use any type of standard X509V3 certificate. We have the following options for you to import and activate the certificate generated by any certification authority.',
    fromHere: 'from here',
    changeOption: 'Change Option',
    addCertificates: 'Add certificates',
    selectOption: '1. Select the type of certificate',
    cancel: 'Cancel',
    cc: 'Citizenship card',
    ce: 'Foreigners ID',
    pas: 'Passport',
    steps: [
      '1. Generate your digital certificate request (CSR)',
      '2. Send the CSR file to the certification authority',
      '3. Download your digital certificate or if your certificate was issued by Netco PKI, use the authorization and reference codes sent to the mail',
      '4. Add a new certificate'
    ],
    getCertTitle:'Configure your certificate',
    getCertLabel:'Get your certificate in no time with Netco.',
    getCertDescLabel:'Keep in mind your certificate improves your document"s security',
    getCertButton:'Generate my certificate',
    dontShowAgain:'Don"t show again',
    generalTitle:'General Data',
    addressTitle: 'Address',
    companyTitle: 'Company',
    confirmationTitle:'Confirmation',
    checkMailTitle:'Check your mail inbox',
    checkMailDesc:'We just sent you an OTP to finish your registration process',
  }
};
export default certificates;
