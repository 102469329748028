import {
  GET_ALL_TIMESTAMP_SERVERS,
  GET_ALL_PROFILES,
  GET_ALL_AUTH_TYPES,
  GET_ALL_WEBSERVICE_USERS,
  LOG_OUT,
  GET_TIMESTAMP_SERVER_INFO,
  GET_WEBSERVICE_USER_INFO,
  GET_PROFILE_INFO,
  GET_ALL_USERS_INFO,
  GET_ADMIN_USER_INFO,
  GET_SYSTEM_CONFIGURATION,
  GET_PROFILES,
  GET_SYSTEM_LOG,
  GET_ALL_LOG_OPERATIONS,
  GET_ALL_METADATA
} from "../actions/types";

const INITIAL_STATE = {
  entries: [],
  total: 0,
  kind: 'users',
  pagesSystemReport: 0,
  selectedEntry: {},
  systemReport: {},
  profiles: []
};

const reportList= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TIMESTAMP_SERVERS:
      return {...state, ...action.payload.reportList};
    case GET_TIMESTAMP_SERVER_INFO:
      return {...state, ...action.payload.reportList};
    case GET_ALL_PROFILES:
      return {...state, ...action.payload.reportList};
    case GET_ALL_AUTH_TYPES:
      return {...state, ...action.payload.reportList};
    case GET_PROFILES:
      return {...state, ...action.payload.reportList};
    case GET_PROFILE_INFO:
      return {...state, ...action.payload.reportList};
    case GET_ALL_WEBSERVICE_USERS:
      return {...state, ...action.payload.reportList};
    case GET_WEBSERVICE_USER_INFO:
      return {...state, ...action.payload.reportList};
    case GET_ALL_USERS_INFO:
      return {...state, ...action.payload.reportList};
    case GET_ADMIN_USER_INFO:
      return {...state, ...action.payload.reportList};
    case GET_SYSTEM_CONFIGURATION:
      return {...state, ...action.payload.reportList};
    case GET_SYSTEM_LOG:
      return {...state, ...action.payload.reportList};
    case GET_ALL_LOG_OPERATIONS:
      return {...state, ...action.payload.reportList};
    case GET_ALL_METADATA:
      return {...state, ...action.payload.reportList};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reportList;