import React from 'react';
import TabsHelper from "../Helpers/TabsHelper";
import AdminTables from "./AdminTables";
import AdminConfigurations from "./AdminConfigurations";
import PrivateRoute from "../Root/PrivateRoute";
import { connect } from "react-redux";
import getSystemInformation from "../../actions/admin/getSystemInformation";
import testSMTP from "../../actions/admin/testSMTP";
import getSystemLog from "../../actions/admin/getSystemLog";
import modalShow from "../../actions/modal/modalShow";
import admin from "../../const/admin";
import Customization from './AdminCustomization';
import showShortcuts from '../../actions/info/showShortcuts'
import AdminDeleteFiles from './AdminDeleteFiles';
import general from "../../const/general"
import modalDismiss from '../../actions/modal/modalDismiss';
import delSignedFiles from '../../actions/files/delSignedFiles';
import infoShow from '../../actions/info/infoShow';
import { CONFIRM_MODAL } from "../Feedback/Modal/types";
import AdminLDAP from './AdminLDAP';
import setLDAPConfiguration from '../../actions/admin/setLDAPConfiguration';
import AdminSignMassive from './AdminSignMassive';
import setTaskConfiguration from '../../actions/admin/setTaskConfiguration';
import { formValueSelector } from "redux-form";
import getLDAPConfiguration from '../../actions/admin/getLDAPConfiguration';
import getConfiguration from '../../actions/admin/getConfiguration';
import getValidOperations from '../../actions/signature/getValidOperations';
import getTaskConfiguration from '../../actions/admin/getTaskConfiguration';
import getUserInfo from '../../actions/profile/getUserInfo';
import cleanReportList from '../../actions/admin/cleanReportList';
import AdminDeleteNotifications from './AdminDeleteNotifications';
import delNotifications from '../../actions/files/delNotifications';



class Admin extends React.Component {

  state = { readyCleanReport : false, notificationsList : {} }

  constructor(props) {
    super(props);
    this.props.cleanReportList(this.finishCleanReportList)
  }

  finishCleanReportList = () =>{
    this.setState({readyCleanReport:true});
  } 

  componentDidMount() {
    this.props.getLDAPConfiguration();
    this.props.getConfiguration();
    this.props.getValidOperations();
    if (this.props.userName && this.props.userName === 'master')
      this.props.getTaskConfiguration();
    this.props.getUserInfo();
    
  }

  delete = (formValues) => {
    this.props.delSignedFiles([formValues.uids], [], formValues.published, () => this.props.infoShow({ info: { message: 'Archivos eliminados correctamente!', type: 'success' } }), formValues.userName, "Eliminado por admin");
  }

  delSignedFiles = (formValues) => {
    if (!formValues.uids || formValues.uids === '') {
      this.props.modalShow({
        modalType: CONFIRM_MODAL,
        modalProps: { footer: null, title: general[this.props.language].delete },
        componentProps: {
          language: this.props.language, info: general[this.props.language].deleteAllFiles.replace('{0}', formValues.userName),
          btn: general[this.props.language].delete, onCancel: () => { this.props.modalDismiss() },
          onSubmit: () => { this.props.modalDismiss(); this.delete(formValues) }
        }
      });
    }
    else
      this.delete(formValues);
  };

  cleanListNotifications = (cleanForm) => {
    this.setState({notificationsList:{}},cleanForm);
  }

  updateDelNotificationList = (results) => {
    const originalJson = results.notificationsList
    const newArrayNotifications = Object.keys(originalJson).map((key, index) => {
      return {
        id: key,
        value: originalJson[key]
      };
    });
    this.setState({notificationsList:newArrayNotifications})
  }

  delNotifications = (formValues) => {
    if (!formValues.ids || formValues.ids === '') {
      this.props.modalShow({
        modalType: CONFIRM_MODAL,
        modalProps: { footer: null, title: general[this.props.language].delete },
        componentProps: {
          language: this.props.language, info: general[this.props.language].deleteNotifications,
          btn: general[this.props.language].delete, onCancel: () => { this.props.modalDismiss() },
          onSubmit: () => { this.props.modalDismiss(); this.props.delNotifications(formValues, [this.updateDelNotificationList])}
        }
      });
    }
    else
      this.props.delNotifications(formValues, [this.updateDelNotificationList]);
  }

  tabProps() {
    return (
      {
        number: this.props.userName && this.props.userName === 'master' ? 7 : 6,
        titles: [
          admin[this.props.language].tables_tab,
          admin[this.props.language].config_tab,
          admin[this.props.language].personalization,
          admin[this.props.language].deletePublic,
          admin[this.props.language].ldap_option,
          admin[this.props.language].deleteNotifications,
          admin[this.props.language].task_option
        ],
        keys: [
          'tables',
          'configuration',
          'deleteFiles',
          'ldap',
          'customization',
          'deleteNotifications',
          'signMassive'
        ],
        content: [
          AdminTables,
          AdminConfigurations,
          Customization,
          AdminDeleteFiles,
          AdminLDAP,
          AdminDeleteNotifications,
          AdminSignMassive
        ],
        tabProps: [
          {},
          {},
          {},
          { initialValues: { published: true }, language: this.props.language, deletePublicFiles: this.delSignedFiles },
          { language: this.props.language, ldap: this.props.adminConfiguration.ldap, setLDAPConfiguration: this.props.setLDAPConfiguration },
          { language: this.props.language, notificationsList:this.state.notificationsList, cleanListNotifications:this.cleanListNotifications,deleteNotifications: this.delNotifications },
          { language: this.props.language, hasTask: this.props.hasTask, task: this.props.adminConfiguration.task, setTaskConfiguration: this.props.setTaskConfiguration, validOperations: this.props.validOperations },
        ]
      }
    );
  };

  render() {
    return ( this.state.readyCleanReport ?
      <div className="p-20 tabs-container">
        <TabsHelper {...this.tabProps()} />
      </div> :<></>
    );
  };

}

const selector = formValueSelector('admin_task_form');

const mapStateToProps = state => {
  return {
    language: state.auth.language,
    adminConfiguration: state.adminConfiguration,
    hasTask: selector(state, 'hasTask'),
    validOperations: state.signatureFiles.validOperations,
    userName: state.user.userName,
  };
};


export default PrivateRoute(connect(mapStateToProps, {
  modalShow, getSystemInformation, getSystemLog, testSMTP, showShortcuts, modalDismiss, delSignedFiles, infoShow, cleanReportList, delNotifications,
  setLDAPConfiguration, setTaskConfiguration, getLDAPConfiguration, getConfiguration,getValidOperations,getTaskConfiguration,getUserInfo
})(Admin));