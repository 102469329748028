import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Row, Select, Col } from 'antd';

// import 'antd/lib/button/style/css';


// import 'antd/lib/col/style/css';


// import 'antd/lib/form/style/css';


// import 'antd/lib/input/style/css';


// import 'antd/lib/modal/style/css';


// import 'antd/lib/row/style/css';


// import 'antd/lib/select/style/css';


import {Field, reduxForm} from "redux-form";
import {CreateSelectField, CreateTextField} from "../../Helpers/Input/InputCreator";
import getUserInfo from "../../../actions/profile/getUserInfo";
import general from "../../../const/general";
import profile from "../../../const/profile";
import '../../../resources/css/fonts.css';
import {fontNames} from "../../../const/fonts";
import DropZoneField from "../../Helpers/DropzoneField";
import getBase64File from '../../../actions/helpers/getBase64File';
import updateUserImage from '../../../actions/profile/updateUserImage';
import { connect } from 'react-redux'
import {formValueSelector} from "redux-form";

class GraphicSignatureForm extends React.Component {


  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  onSubmit= async (formValues)=>
  {
    const fromText=formValues.name;
    const fontName=fromText?formValues.selectedFont:null;
    await this.props.updateUserImage(this.props.base64Image,fromText,fontName);
    await this.props.getUserInfo([this.props.finishUpImageSignature]);
  };

  componentDidMount() {
    this.props.initialize({selectedFont: 'Arial',base64Image:this.props.user.image});
  }

  setText = (newFont,newValue) => {
    if(!newFont || !newValue)
    {
      this.props.change('base64Image', this.props.user.image);
      return;
    }
    const canvas=this.canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, 300, 50);
    ctx.font = "40px " + newFont;
    ctx.textBaseline = 'middle';
    ctx.fillText(newValue, 5, canvas.height / 2,290);
    this.props.change('base64Image', canvas.toDataURL("image/png").split(';base64,')[1]);
  };

  handleOnDrop = async (files) => {
    if(files[0]){
      let base64 = await getBase64File(files[0]);
      await this.props.reset();
      this.props.change('base64Image', base64);
    }
  };

  
  render() {
    return (
      <div className={'signerBorder'}  style={{padding: 10, marginBottom:10}}>
          <h2 className={'title'} style={{textAlign: 'left'}}>{profile[this.props.language].generate_graphic}</h2>
          <div className={'signerBorder'}  style={{borderBottom:'none',marginBottom: 10}} />
          <Form onFinish={this.handleSubmit}>
            <Row gutter={16} className={'flexRight'}>
              <Col xs={24} md={12} style={{textAlign: 'left', margin: 'auto'}}>
                <Form.Item style={{width:'100%'}}>
                  <div style={{marginTop:'10px'}} className={'mb-10'}>{profile[this.props.language].write_name}</div>
                  <Field
                      name="name"
                      onChange={(event, newValue)=>{this.setText(this.props.selectedFont, newValue)}}
                      component={CreateTextField}
                      placeholder={profile[this.props.language].write_name}
                      maxLength={40}
                      hasFeedback/>
                </Form.Item>
                <Form.Item style={{width:'100%'}}>
                  <div style={{marginTop:'10px'}} className={'mb-10'}>{profile[this.props.language].font}</div>
                  <Field
                      name="selectedFont"
                      component={CreateSelectField}
                      onChange={(newValue)=>this.setText(newValue,this.props.name)}
                      hasFeedback>
                    {fontNames.map((fontName,index) => <Select.Option key={index} value={fontName}>{fontName}</Select.Option>)}
                  </Field>
                </Form.Item>
              </Col>
              <Col xs={24} md={12} style={{textAlign: 'center', margin: 'auto'}}>
                <DropZoneField
                  multiple={false}
                  handleOnDrop={this.handleOnDrop}
                  label={profile[this.props.language].allowed_values}
                  />
              </Col>
          </Row>
          <Row>
            <Form.Item style={{width:'100%'}}>
              <div style={{marginTop:'10px', textAlign:'left'}} className={'mb-10'}>{profile[this.props.language].preview}</div>
              {this.props.user.image && this.props.user.image !==''?<img className='border-image' style={{maxWidth:'300px',maxHeight:'130px'}} alt={''} src={`data:image/png;base64, ${this.props.base64Image}`}/>:profile[this.props.language].noimage}
              <canvas width={300} height={50} ref={this.canvasRef} style={{visibility:"hidden"}}/>
            </Form.Item>
          </Row>
            <Row>
              <Form.Item style={{width:'100%'}}>
                <Field type={"hidden"} component={CreateTextField} name="base64Image" />
              </Form.Item>
            </Row>
          <Row className={'flexRight'}>
            <Button className={'float-right'} type={'primary light-primary rounded-sm float-right'} htmlType={'submit'}
                    onClick={this.props.handleSubmit(this.onSubmit)}>
              {general[this.props.language].save}
            </Button>
          </Row>
            {fontNames.map((fontName,index) => <div key={index} style={{fontFamily:fontName,color:'white',display:'inline-flex'}}>.</div>)}
          </Form>
      </div>
    );
  }
}

const selector = formValueSelector('graphic_signature_form');

const mapStateToProps = state => {
  return {
    language: state.auth.language,
    user: state.user,
    base64Image:selector(state, 'base64Image'),
    selectedFont:selector(state, 'selectedFont'),
    name:selector(state, 'name')
  };
};

GraphicSignatureForm = connect(mapStateToProps,{updateUserImage,getUserInfo})(GraphicSignatureForm)
export default reduxForm({form: 'graphic_signature_form'})(GraphicSignatureForm);
