import netcoServer from '../../apis/netcoServer';
import {CHECK_STRONG_AUTHENTICATION, CREATE_SESSION} from "../types";
import {push} from 'connected-react-router';
import {SIGNATURE_OPTIONS_ROUTE} from "../../components/Root/routes";
import loadingCall from "../helpers/loadingCall";
import invalidSession from "./invalidSession";
import signPendingFiles from "../files/signPendingFiles";
import reSignFiles from "../files/reSignFiles";
import {dispatchRootRoute} from "../helpers/utils";
import logoutUser from "./logoutUser";

const checkStrongAuthentication= (formValues) => async (dispatch, getState) => {
  
  let response, data, txAuth;
  if(getState().session.hasSession && getState().auth.txAuth) {
    if (formValues.nut){
      response = await netcoServer.post('/LoginService/checkStrongAuthentication', formValues);
    } else {
      response = await loadingCall(dispatch, () => netcoServer.newPost('/LoginService/checkStrongAuthentication', formValues));
    }
    if(response.data.success) {
      switch (getState().signatureFiles.txCallback) {
        case 'UIDS':
          data = {
            signatureFiles: {
              txValues: formValues
            }
          };
          dispatch({type: CHECK_STRONG_AUTHENTICATION, payload: data});
          dispatch(reSignFiles(getState().signatureFiles.txContent,null,null));
          data = {
            signatureFiles: {
              txCallback: null,
              txContent: null
            }
          };
          dispatch({type: CHECK_STRONG_AUTHENTICATION, payload: data});
          break;
        case 'PENDING':
          data = {
            signatureFiles: {
              txValues: formValues
            }
          };
          dispatch({type: CHECK_STRONG_AUTHENTICATION, payload: data});
          dispatch(signPendingFiles(getState().signatureFiles.txContent));
          data = {
            signatureFiles: {
              txCallback: null,
              txContent: null
            }
          };
          dispatch({type: CHECK_STRONG_AUTHENTICATION, payload: data});
          break;
        default:
          data = {
            auth: {
              txAuthenticated: true
            }            ,
            signatureFiles: {
              txValues: formValues,
              txCallback: null,
              txContent: null
            }
          };
          dispatch({type: CHECK_STRONG_AUTHENTICATION, payload: data});
          dispatch(push(SIGNATURE_OPTIONS_ROUTE));
          break;
      }
      txAuth = true;
    } else {
      data = {
        info: {
          message: response.data.detail,
          type: 'error'
        }
      };
      dispatch(push('/'));
    }
  } else {
    if (formValues.nut){
      response = await netcoServer.post('/LoginService/checkStrongAuthentication', formValues);
    } else {
      response = await loadingCall(dispatch, () => netcoServer.newPost('/LoginService/checkStrongAuthentication', formValues));
    }
    if(!response || !response.data ||!response.data.success)
    {
      dispatch(logoutUser(null,false,true));
      return;
    }


    if (response.data.success) {
      data = {
        auth: {
          admin: response.data.admin,
          txAuth: response.data.txAuth,
          requiresCert: response.data.requiresCert,
		  entrust: response.data.entrust,
		  netco: response.data.netco,
		  ldap: response.data.ldap,
          enableecom:response.data.enableecom,
          userName:response.data.userName,
          limitFiles:response.data.limitFiles
        },
        info: {
          message: '',
          type: 'close'
        }
      };
      sessionStorage.setItem("idp", getState().auth.params.state);
      sessionStorage.setItem("logged", true);
      dispatch({type:CREATE_SESSION,payload:{hasSession:true}});
      if (getState().auth.invalidSessionPath) {
        dispatch(push(getState().auth.invalidSessionPath));
        dispatch(invalidSession(true));
      } else
        dispatchRootRoute(dispatch,formValues.params);
    } else {
      data = {
        auth: {
        },
        info: {
          message: response.data.detail,
          type: 'error'
        }
      };
      dispatch(logoutUser(null,false,true));
    }
  }
  if(!txAuth)
    dispatch({type: CHECK_STRONG_AUTHENTICATION, payload: data});
};
export default checkStrongAuthentication;