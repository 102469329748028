import netcoServer from '../../apis/netcoServer';
import {CREATE_ORDER} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const createOrder= (order,onOrderCreated) => async (dispatch) => {
  const onSuccessPayload = data => {
    return data;
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/createOrder  ', order));
  handleResponse(dispatch, response, CREATE_ORDER, onSuccessPayload, null, [],[],[onOrderCreated],[onOrderCreated]);
};
export default createOrder;
