import React from 'react';
import {connect} from 'react-redux';
import {Button, Card, InputNumber, Select, Table, Row, Divider, Tooltip, ConfigProvider, Col, Alert} from "antd";

// import 'antd/lib/card/style/css';


// import 'antd/lib/row/style/css';


// import 'antd/lib/col/style/css';


// import 'antd/lib/alert/style/css';


import getProductsInfo from "../../actions/admin/getProductsInfo";
import createOrder from '../../actions/admin/createOrder';
import deleteOrder from "../../actions/admin/deleteOrder";
import PrivateRoute from "../Root/PrivateRoute";
import admin from "../../const/admin";
import {Helmet} from "react-helmet";
import {CHECK_ECOM_ROUTE, ECOM_ROUTE, PROFILE_ROUTE} from "../Root/routes";
import {push} from "connected-react-router";
import {INFO_SHOW} from "../../actions/types";
import infoPayload from "../../actions/helpers/payloadHandlers/infoPayload";
import {sha256} from "js-sha256";
import {getProductTotalCost} from "../Helpers/utils";
import {DeleteOutlined} from "@ant-design/icons";
import esCO from "antd/es/locale/es_ES";
import enUS from "antd/es/locale/en_US";
import SubmitButton from "../Helpers/SubmitButton";
import ConfirmWordPress from "../ConfirmWordPress";

const { Column } = Table;

class BuyOperations extends React.Component {

    state = {
        operations:1,
        unitValue: 0, totalValue: 0,  retries: 0,
        items: [{quantity: 1, key: 1}], order: null,
        currentOperation:"1",
        addedItems:[],
        purchaseType:0
    };

    constructor(props) {
        super(props);
        this.startPayu = React.createRef();
        this.merchantId = React.createRef();
        this.accountId = React.createRef();
        this.description = React.createRef();
        this.referenceCode = React.createRef();
        this.amount = React.createRef();
        this.tax = React.createRef();
        this.currency = React.createRef();
        this.signature = React.createRef();
        this.test = React.createRef();
        this.buyerEmail = React.createRef();
        this.responseUrl = React.createRef();
        this.confirmationUrl = React.createRef();
    }

    getProductsInfo=()=>
    {
        if(this.props.wordpressUser)
            this.props.getProductsInfo();
    };

    componentDidMount()
    {
        this.getProductsInfo();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.products !== this.props.products && this.props.products.length>0)
            this.updateProduct();
        if(this.props.wordpressUser && this.props.wordpressUser!==prevProps.wordpressUser)
            this.getProductsInfo();
        if(prevProps.active!==this.props.active && this.props.active==='buy')
            this.getProductsInfo();
    }

    openPaymentState = () => {
        this.openPayment({result: {customer: this.props.customer, number: this.props.number}});
    };

    openPayment = (data, method) => {
        if(!data || !method)
        {
            console.log("Datos incompletos...");
            return;
        }
        let newOrder = data.result;
        let mail = this.props.mail;
        if (mail === "" && newOrder.customer)
            mail = newOrder.customer.mail;
        switch (method) {
            case 'paypal':
                this.openPaypal(newOrder);
                break;
            case 'payulatam':
                this.openPayuLatam(newOrder, mail);
                break;
            case 'epayco':
                this.openEpayco(newOrder, mail);
                break;
            default:
                break;
        }
        this.props.getProductsInfo();
    };

    openPaypal=(newOrder)=>
    {
        window.location.href = this.props.checkoutURL.paypal +
            '/cgi-bin/webscr?cmd=_express-checkout&useraction=commit&token=' + newOrder.paymentID;
            //'&bank_txn_pending_url=' + window.location.href;
    };

    openPayuLatam = (newOrder, mail) => {
        const keyInfo = this.props.keyInfo;
        const ApiKey = keyInfo.apikey;
        const merchantId = keyInfo.merchant_id;
        const accountId = keyInfo.apiLogin;
        const referenceCode = newOrder.id;// + makeid(5);
        const description = "Orden " + newOrder.id;
        const amount = this.getBigTotal();
        const tax = 0;
        const currency = this.getCurrency();
        const separator = '~'
        const signature = sha256(ApiKey + separator + merchantId + separator + referenceCode + separator + amount + separator + currency);
        /*let body='merchantId=' + merchantId + '&ApiKey=' + ApiKey + '&referenceCode=' + referenceCode + '&accountId=' + accountId;
        body=body+ '&description=' + description + '&amount=' + amount + '&tax=' + tax + '&taxReturnBase=' + taxReturnBase + '&currency=' + currency;
        body=body + '&signature=' + signature + '&test=true' + '&buyerEmail=' + buyerEmail + '&responseUrl=' + confirmation;
        body=body+'&algorithmSignature=SHA256';*/
        this.merchantId.current.value = merchantId;
        this.accountId.current.value = accountId;
        this.description.current.value = description;
        this.referenceCode.current.value = referenceCode;
        this.amount.current.value = amount;
        this.tax.current.value = tax;
        this.currency.current.value = currency;
        this.signature.current.value = signature;
        this.test.current.value = test;
        this.buyerEmail.current.value = mail;
        this.responseUrl.current.value = window.location.href;
        this.confirmationUrl.current.value = window.location.href;
        this.startPayu.current.click();

    };

    openEpayco = (newOrder, mail) => {
        const key=JSON.parse(this.props.keyInfo).epayco;
        const confirmation=window.location.href.replace(ECOM_ROUTE,CHECK_ECOM_ROUTE);
        try {
            let handler = window.ePayco.checkout.configure({
                key: key,
                test: true
            });
            let data = {
                name: admin[this.props.language].buy_detail,
                description: admin[this.props.language].buy_detail,
                currency: this.getCurrency(),
                amount: this.getBigTotal(),
                tax_base: "0",
                email_billing: mail,
                tax: "0",
                country: "co",
                invoice: newOrder.number,
                extra1: newOrder.number,
                lang: this.props.language,
                external: "true",
                confirmation: confirmation + '?x_id_factura=' + newOrder.number,
                pending: confirmation + '?x_id_factura=' + newOrder.number,
                rejected: confirmation + '?x_id_factura=' + newOrder.number,
                response: confirmation + '?x_id_factura=' + newOrder.number,
                confirm_method:"GET",
                p_confirm_method:"GET"
            };
            handler.open(data);
        } catch (e) {
            this.props.dispatch({
                type: INFO_SHOW,
                payload: infoPayload('error', admin[this.props.language].error_epayco)
            });
            console.log("Error al abrir epayco : " + e);
            let retries = this.state.retries;
            console.log("Retries:", retries);
            if (retries >= 3)
                this.props.deleteOrder({number: newOrder.number}, this.redirect);
            this.setState({retries: retries + 1});
        }
    };

    redirect = () => {
        this.props.dispatch(push(PROFILE_ROUTE));
    };

    handleSubmit = (callback,paymentMethod) =>
    {
        const items=JSON.stringify(this.state.addedItems.map(item=>{
            const newItem={quantity:item.quantity};
            newItem["product_id"]=item["product_id"];
            return newItem;
        }));
        this.props.createOrder({paymentMethod:paymentMethod,paymentReturnURL:window.location.href,items}, callback);
    };

    handleQuantity = (value) => {
        this.setState({operations: value});
        return true;
    };


    handleOperation = (value) => {
        this.setState({currentOperation: value},()=>{
            const product=this.getProductFromNetcoID();
            if(product.options && product.options.length>0)
                this.setState({operations:product.minQuantity});
            else
                this.handleQuantity(product.minQuantity);
        });
    };

    getCurrency=()=>
    {
        let currency=this.getProductFromNetcoID().currency;
        if(!currency)
            currency="COP";
        return currency;
    };

    getCost=(quantity)=>
    {
        const product=this.getProductFromNetcoID();
        if(this.state.currentOperation==="1")
        {
            let previousValue=product.cost;
            let prices=product.prices;
            if(!prices)
                return previousValue;
            prices=JSON.parse(prices);
            for (let key of Object.keys(prices))
            {
                if(key>quantity)
                    return previousValue;
                previousValue=prices[key];
            }
            return previousValue;
        }
        return product.cost;
    };

    currencyFormat(num) {
        num=parseFloat(num);
        return num.toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    getProductFromNetcoID=()=>
    {
        const product=this.props.products.filter((product)=>product.netcoID===this.state.currentOperation)[0];
        if(!product)
            return {tax:0,minQuantity:0,cost:0};
        return product;
    };

    getTaxValue=(product,cost)=>
    {
        const operations=this.state.operations;
        if(!product)
            product=this.getProductFromNetcoID();
        if(!cost)
            cost=this.getCost(operations);
        const total = Math.max(operations * cost,0);
        return product.tax ? total * product.tax.rate / 100 : 0;
    };

    getBigTotal=()=>
    {
        let total=0;
        const addedItems=this.state.addedItems;
        for(let i=0;i<addedItems.length;i++)
            total+=addedItems[i].totalValue;
        return total;
    };

    getTotal=(product)=>
    {
        if(!product)
            product=this.getProductFromNetcoID();
        const operations=this.state.operations;
        return getProductTotalCost(operations,this.getCost(operations),product.tax);
    };

    createOrderPromise = () => {
        const myThis = this;
        return new Promise(function (resolve, reject) {
            try {
                myThis.handleSubmit((result) => {
                    if (result) {
                        myThis.setState({order: result.result}, () => resolve())
                    } else
                        reject("Error al crear orden");
                },'paypal');
            } catch (err) {
                console.log(err);
                reject(err);
            }
        });
    };

    renderPaymentButtons = () => {
        const methods = this.props.methods;
        if (methods && methods.length > 0 && this.state.addedItems.length>0) {
            return methods.map((method,index) => {
                switch (method) {
                    case 'payulatam':
                        return <Row key={index} align={'center'}>
                            <SubmitButton type="primary light-primary rounded-sm" label={admin[this.props.language].buy_button_payu}
                                          onSubmit={() => this.handleSubmit((data) => this.openPayment(data, method),method)}/>
                            </Row>;
                    case 'epayco':
                        return <Row key={index} align={'center'}>
                            <SubmitButton type="primary light-primary rounded-sm" label={admin[this.props.language].buy_button_epayco}
                                          onSubmit={() => this.handleSubmit((data) => this.openPayment(data, method),method)}/>
                        </Row>;
                    case 'paypal':
                        return <Row key={index} align={'center'}>
                            <SubmitButton type="primary light-primary rounded-sm" label={admin[this.props.language].buy_button_paypal}
                                          onSubmit={() => this.handleSubmit((data) => this.openPayment(data, method),method)}/>
                            </Row>;
                        //return <div key={index}>{this.renderPaypal()}</div>;
                    default:
                        return '';
                }
            });
        }
    };

    updateProduct=()=>
    {
        let netcoID='';
        const products=this.getAvailableProducts();
        if(products.length>0)
            netcoID=products[0].netcoID;
        this.handleOperation(netcoID);
    };

    addItem=()=>
    {
        const quantity=this.state.operations,product=this.getProductFromNetcoID(),name=product.name;
        const unitValue=this.getCost(quantity),taxValue=this.getTaxValue(product,unitValue), totalValue=this.getTotal(product);
        const newItem={name,netcoID: product.netcoID,quantity,unitValue,taxValue,totalValue};
        newItem["product_id"]=product.productID;
        this.setState({addedItems:[...this.state.addedItems,newItem]},this.updateProduct);
    };

    removeItem=(netcoID)=>
    {
        this.setState({addedItems:this.state.addedItems.filter(item=>item.netcoID!==netcoID)},this.updateProduct);
    };

    getAvailableProducts=()=>
    {
        const products=this.props.products;
        if(!products)
            return [];
        return products.filter(product=> this.state.addedItems.filter(item=>item.netcoID===product.netcoID).length===0);
    };

    renderOptions=()=>
    {
        const bigTotal=this.getBigTotal();
        return <>
            <Alert style={{textAlign:'left'}} className={'mb-10 info'} message={admin[this.props.language].selectProducts} type="info"/>
            {this.renderSelectProduct()}
            {this.renderAddedTable()}
            {bigTotal >0 &&<><h3>{admin[this.props.language].buy_bigTotal + ' (' + this.getCurrency() + ') :' + bigTotal}</h3><Divider/></>}
            {this.renderPaymentButtons()}</>;
    };

    renderSelectProduct=()=>
    {
        const operations=this.state.operations;
        const product=this.getProductFromNetcoID(),products=this.getAvailableProducts();
        return products.length>0 && product.netcoID && <>
            <Row>
                <Col xs={20} sm={16} style={{textAlign:'center'}}>
                    <Select style={{width:'100%'}} value={this.state.currentOperation} onChange={this.handleOperation}>
                        {products.map((product)=>
                            <Select.Option key={product.netcoID} value={product.netcoID}>{product.name}</Select.Option>)}</Select>
                </Col>
                <Col xs={4} sm={3} style={{textAlign:'center'}}>
                    <InputNumber style={{width: 65}} type={"number"} min={product.minQuantity}
                                 value={operations} max={10000} onChange={this.handleQuantity}/>
                </Col>
                <Col  xs={24} sm={5} style={{textAlign:'center'}}>
                    <Button type={'primary'} htmlType={'button'} onClick={this.addItem}>
                        {admin[this.props.language].buy_add}
                    </Button>
                </Col>
            </Row></>;
    }

    renderAddedTable=()=>
    {
        return this.state.addedItems.length>0 && <ConfigProvider locale={this.props.language==='es'?esCO:enUS}>
            <Table title={()=><h3>{admin[this.props.language].buy_product_added}</h3>} scroll={{ x: true}} size={"small"}
                   dataSource={this.state.addedItems} pagination={false} rowKey={'netcoID'}>
                <Column className={'mobile-show'} title={admin[this.props.language].buy_product} dataIndex="name"/>
                <Column className={'mobile-show'} title={admin[this.props.language].buy_quantity} dataIndex="quantity"/>
                <Column className={'mobile-hide'} title={admin[this.props.language].buy_unitValue + '(' + this.getCurrency() + ')'} dataIndex="unitValue"/>
                <Column className={'mobile-hide'} title={admin[this.props.language].buy_taxValue + '(' + this.getCurrency() + ')'} dataIndex="taxValue"/>
                <Column className={'mobile-show'} title={admin[this.props.language].buy_totalValue + '(' + this.getCurrency() + ')'} dataIndex="totalValue"/>
                <Column className={'mobile-show'} render={(text,record)=>
                    <Button type={'danger'} htmlType={'button'} onClick={()=>this.removeItem(record.netcoID)}
                            icon={<Tooltip title={admin[this.props.language].buy_del}><DeleteOutlined /></Tooltip>}>
                    </Button>}/>
            </Table><Divider/></ConfigProvider>;
    }

    render() {
        if(!this.props.wordpressUser)
            return <ConfirmWordPress/>;
        const products=this.props.products;
        if(!products || products.length===0)
            return <></>;
        return (
            <Card size={'small'} bodyStyle={{paddingLeft:0}} bordered={false}>
                {this.state.retries === 0? this.renderOptions(): <div style={{textAlign: 'center'}}><Button style={{'width': '400px'}}
                                                                    type="primary light-primary rounded-sm"
                                                                    onClick={this.openPaymentState}>{admin[this.props.language].buy_retry}</Button>
                </div>}
                <Helmet>
                    <script type="text/javascript" src="js/epayco.js"/>
                </Helmet>
                <form method="post" action={this.props.checkoutURL?this.props.checkoutURL.payulatam:''}>
                    <input name="merchantId" type="hidden" ref={this.merchantId}/>
                    <input name="accountId" type="hidden" ref={this.accountId}/>
                    <input name="description" type="hidden" ref={this.description}/>
                    <input name="referenceCode" type="hidden" ref={this.referenceCode}/>
                    <input name="amount" type="hidden" ref={this.amount}/>
                    <input name="tax" type="hidden" ref={this.tax}/>
                    <input name="currency" type="hidden" ref={this.currency}/>
                    <input name="signature" type="hidden" ref={this.signature}/>
                    <input name="test" type="hidden" ref={this.test}/>
                    <input name="buyerEmail" type="hidden" ref={this.buyerEmail}/>
                    <input name="responseUrl" type="hidden" ref={this.responseUrl}/>
                    <input name="confirmationUrl" type="hidden" ref={this.confirmationUrl}/>
                    <input style={{visibility: 'hidden'}} ref={this.startPayu} type="submit" value="Submit"/>
                </form>
            </Card>
        );
    }
}
const mapStateToProps=(state) =>
{
    return {
        language: state.auth.language,mail: state.user.mail,
        userName:state.auth.userName, customer:state.payment.customer,number:state.payment.number,
        keyInfo:state.payment.keyInfo, methods:state.payment.methods, checkoutURL: state.payment.checkoutURL,
        products:state.payment.products,wordpressUser:state.user.wordpressUser
    }
};
export default PrivateRoute(connect(mapStateToProps, {getProductsInfo,createOrder,deleteOrder})(BuyOperations));
