import netcoServer from '../../apis/netcoServer';
import {CREATE_PROFILE, INFO_SHOW} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import getAllProfiles from "./getAllProfiles";
import modalDismiss from "../modal/modalDismiss";
import _ from 'lodash';
import loadingCall from "../helpers/loadingCall";
import profile from "../../const/profile";

const createProfile= (formValues) => async (dispatch,getState) => {
  let formData = _.omit(formValues, 'authTypeId', 'isAdmin', 'isTxAuth');
  if(parseInt(formData.passMinLength)>parseInt(formData.passMaxLength))
  {
    dispatch({type: INFO_SHOW, payload: infoPayload('error', profile[getState().auth.language].minMaxError)})
    return;
  }
  formData.authTypeDescription = formValues.authTypeId;
  formData.admin = formValues.isAdmin;
  formData.txAuth = formValues.isTxAuth;
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/createProfile', formData));
  handleResponse(dispatch, response, CREATE_PROFILE, onSuccessPayload, null, [getAllProfiles, modalDismiss]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Perfil agregado satisfactoriamente!');
};
export default createProfile;