import netcoServer from '../../apis/netcoServer';
import {GENERATE_CSR} from "../types";
import {ROOT_ROUTE} from "../../components/Root/routes";
import handleResponse from '../helpers/handleResponse';
import infoPayload from '../helpers/payloadHandlers/infoPayload';
import handleBase64ZipResponse from "../helpers/handleBase64ZipResponse";
import loadingCall from "../helpers/loadingCall";
import certificates from "../../const/certificates";
import {push} from "connected-react-router";
import logoutUser from '../auth/logoutUser';
import _ from "lodash";

const generateCSR= (formValues) => async (dispatch) => {
  const onSuccessPayload = () => {
	if(formValues.requiresCert)
	{
		dispatch(logoutUser());
		dispatch(push('/'));
	}
	return infoPayload('success', certificates[formValues.language].processcsrok);
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/generateCSR', _.omit(formValues, ['language','requiresCert'])));
  handleResponse(dispatch, response, GENERATE_CSR, onSuccessPayload, ROOT_ROUTE);
  if (response.data.success)
    handleBase64ZipResponse(response.data.csr, 'certificado.csr');
};
export default generateCSR;