import netcoServer from '../../apis/netcoServer';
import {GET_PUBLIC_SIGNED_FILES_INFO, GET_SIGNED_FILES_INFO, GET_ALL_SIGNED_FILES_INFO} from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";

const getSignedFilesInfo= (published, searchString, page, pageSize,parentId,sidx,sord,getAll=false,sameRedux=false, filterColumn = false, filterMetadataString) => async (dispatch) => {
  let formData = {
    published: published,
    filterString: searchString,
    limit: pageSize,
    skip: (page - 1) * pageSize,
    parentId: parentId,
    sidx:sidx,
    sord:sord,
    getAll:getAll,
    filterMetadataString
  };  
  if(filterColumn)
    formData.filterColumn = filterColumn
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getSignedFilesInfo', formData));
  if(response && response.data  && response.data.filesInfo && response.data.filesInfo.message)
	dispatch({type: 'INFO_SHOW', payload: {    info: {        message: response.data.filesInfo.message,        type: 'success'      }    }});
  handleResponse(
      dispatch,
      response,
      sameRedux || getAll?GET_ALL_SIGNED_FILES_INFO:(published ? GET_PUBLIC_SIGNED_FILES_INFO : GET_SIGNED_FILES_INFO),
      sameRedux || getAll?onSignedFilesSuccessPayload:(published ? onPublicSuccessPayload : onSuccessPayload)
  );
};

const onSuccessPayload = data => {
  return {
    privateFileList: {
      total: data.filesInfo.total,
      entries: data.filesInfo.files,
      parentId: data.filesInfo.parentId
    }
  };
};

const onPublicSuccessPayload = data => {
  return {
    publicFileList: {
      total: data.filesInfo.total,
      entries: data.filesInfo.files,
      parentId: data.filesInfo.parentId
    }
  };
};

const onSignedFilesSuccessPayload = data => {
  return {
    signedFileList: {
      total: data.filesInfo.total,
      entries: data.filesInfo.files,
      parentId: data.filesInfo.parentId
    }
  };
};
export default getSignedFilesInfo;