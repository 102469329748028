import React, {useEffect, useMemo, useRef, useState} from "react";
import {Field, reduxForm} from "redux-form";
import {Button, Row} from "antd";

// import 'antd/lib/col/style/css';


// import 'antd/lib/row/style/css';


// import 'antd/lib/select/style/css';


import {CreateFileField} from "../Helpers/Input/InputCreator";
import {required} from "../Helpers/Input/validations";
import signature from "../../const/signature";
import general from "../../const/general";
import {renderTemplates} from "../Helpers/utils";
import SubmitButton from "../Helpers/SubmitButton";

const ThirdStep = (props) =>
{

    const initialValues = useMemo(() =>
        props.initialValues, [props.initialValues]);
    //const initialValues={props};
    const localProps =useRef(props);
    useEffect(() => {
        localProps.current.change('selectedTemplate', initialValues.selectedTemplate);
  }, [initialValues]);

  const handleDrop = (files, onChange) => {
    props.change('key', files);
    props.handleOnDropFile(files, onChange)
  };

  const [ids, setIds] = useState( [] );
  const upCallBack =() =>
  {
      props.getPDFTemplatesInformation(props.parentId)
  };
  const enterCallBack=(parentId)=>
  {
      props.getPDFTemplatesInformation(parentId);
  };

    const onCheckTemplate=(id,checked)=>
    {
        if(checked)
            setIds([...ids,id]);
        else
            setIds(ids.filter(item => item !== id));
    };

    const renderTemplateGrid=()=>
    {
        return renderTemplates(props.language,props.templates,onCheckTemplate,enterCallBack,upCallBack,8);
    };


    const render = () => {
    if(props.selectedOption === 'useTemplate') {
      return (
        <div>
            {renderTemplateGrid()}
          <Row type="flex" gutter={16} justify="center">
            <Button htmlType={'button'} type={"primary light-primary rounded-sm"} style={{ marginLeft: 8 }} onClick={props.prev}>{general[props.language].prev}</Button>
            <Button htmlType={'button'} type={"primary light-primary rounded-sm"} onClick={props.handleSubmit((formValues)=>props.onSubmit(formValues,ids))}>{signature[props.language].end}</Button>
          </Row>
        </div>

      );
    } else {
      return (
        <div>
          <Row  style={{display:'block',marginTop:15}} gutter={16} className={'p-20'}>
              <Field label2={signature[props.language].select_files}
                     name="key"
                     component={CreateFileField}
                     handleOnDrop={handleDrop}
                     type='file'
                     formFile={props.formFile}
                     validate={required}
                     multiple={true}
              />


          </Row>
          <Row type="flex" gutter={16} justify="center">
            <Button htmlType={'button'} type={"primary light-primary rounded-sm"} style={{marginLeft: 8}}
                    onClick={props.prev}>{general[props.language].prev}</Button>
            <SubmitButton type={"primary light-primary rounded-sm"} label={signature[props.language].end} onSubmit={props.handleSubmit(props.onSubmit)} />
          </Row>
        </div>

      );
    }

  };

  return (
    render()
  );
};


export default reduxForm({
  form: 'signature_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(ThirdStep);
