import React from 'react';
import {connect} from 'react-redux'
import {Button, Modal, Row} from "antd";

// import 'antd/lib/modal/style/css';


import {Document, Page} from "react-pdf";
import general from "../../../const/general";
import {checkPDFPassword} from "../../Helpers/utils";
import {TEMPLATE_VIEWER_MODAL} from "../../Feedback/Modal/types";

class TemplateViewerModal extends React.Component {

  state = {
    numPages: null,
    pageNumber: 1,
    file: null
  };

  componentDidMount()
  {
    if(this.props.file)
      this.setState({file:this.props.file})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(!this.state.file && this.props.templateList.selectedTemplate)
      this.setState({file: handleFile(this.props.templateList.selectedTemplate)})
  }

  onPassword = callback =>
  {
    if(this.props.password)
    {
      callback(this.props.password);
      //this.props.password=null;
      return;
    }
    this.props.modalDismiss();
    checkPDFPassword(this.props.templateList.selectedTemplate,this.props.modalProps.title,'',
          this.props.dispatch,this.props.language).then((password)=>{
            this.props.modalShow({
          modalType: TEMPLATE_VIEWER_MODAL,
          modalProps: {footer: null, title: this.props.modalProps.title},
          componentProps: {password:password,file:this.state.file}
        });
      }).catch(reason =>{
        return reason;
      });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handleNext = () => {
    this.setState({pageNumber: this.state.pageNumber + 1});
  };

  handlePrev = () => {
    this.setState({pageNumber: this.state.pageNumber - 1});
  };

  renderNext = () => {
      return(
          <Button disabled={this.state.pageNumber >= this.state.numPages} htmlType={'button'} type={'primary'} onClick={this.handleNext}>{general[this.props.language].next}</Button>
      );
  };

  renderPrev = () => {

    return (
        <Button disabled={this.state.pageNumber <= 1} htmlType={'button'} type={'primary'}
                onClick={this.handlePrev}>{general[this.props.language].prev}</Button>
    );
  };

  renderDocument = () => {
    if(this.state.file) {
      return (
          <Document onPassword={this.onPassword} file={this.state.file} onLoadSuccess={this.onDocumentLoadSuccess}>
            <Page scale={0.7} pageNumber={this.state.pageNumber} width={window.innerWidth < 720 ?  (window.innerWidth- 70) : 650}/>
          </Document>
      );
    }

  };

  render() {
    return (
      (<Modal centered={true}
        open={this.props.modalType !== null} {...this.props.modalProps}>
        <Row style={{justifyContent:'center'}}>
        {this.renderPrev()}
        {this.renderNext()}
        </Row>
        <Row style={{justifyContent:'center'}}>
        {this.renderDocument()}
        </Row>
      </Modal>)
    );
  }
  
}

const handleFile = (base64File) => {
  let binaryString =  window.atob(base64File);
  let binaryLen = binaryString.length;

  let ab = new ArrayBuffer(binaryLen);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < binaryLen; i++) {
    ia[i] = binaryString.charCodeAt(i);
  }
  return new Blob([ab]);
}

function mapStateToProps(state) {
  return {
    language: state.auth.language,
    templateList: state.templateList
  }
}

export default connect(mapStateToProps)(TemplateViewerModal);