
const subscriptions = {
  es: {
    modifyModalButton: 'Cambiar',
    planRequiredError: 'Debes seleccionar un plan',
    cardNumber: 'Número de la tarjeta',
    customerName: 'Nombre',
    expirationDate: 'Fecha de expiración MMAA',
    securityCode: 'Código de seguridad 3 Dígitos',
    docNumber: 'Número de documento',
    confirmDelete: 'Esto borrará los datos de su tarjeta e inactivará su suscripción. ¿Está seguro?',
    changeCard:'Cambiar tarjeta de crédito asociada',
    subscriptionStatusText: 'Tu suscripción se encuentra ',
    nextDate: 'Próxima fecha de renovación automática:',
    associatedCard: 'Tarjeta Asociada:',
    retryCharge: 'Reintentar pago',
    delete: 'Eliminar suscripción',
    info1:'Aquí podrás comprar una suscripción para Netco Signer usando tu tarjeta de crédito. Una vez realizado el pago podrás firmar todos los documentos que quieras durante el periodo que escojas',
    buy: 'Comprar suscripción',
    info2: '***Esta suscripción se renovará automáticamente, si deseas cancelarla puede ingresar a esta opción donde verás un botón para eliminarla'
  },
  en: {
    modifyModalButton: 'Change',
    planRequiredError: 'Plan is required',
    cardNumber: 'Credit card number',
    customerName: 'Name',
    expirationDate: 'Expiration Date MMYY',
    securityCode: 'CVV (3 digits)',
    docNumber: 'Document Number',
    confirmDelete: 'This action will delete your credit card data and your subscription. ¿Are you sure?',
    changeCard:'Change credit card information',
    subscriptionStatusText: 'Your subscription is currently ',
    nextDate: 'Next charge date:',
    associatedCard: 'Current Credit Card Number:',
    retryCharge: 'Try to charge again',
    delete: 'Delete subscription',
    info1:'Buy a subscription to Netco Signer using your credit card. Once charged you will be able to sign all the documents that you want for the chosen period',
    buy: 'Buy subscription',
    info2: '***This subscription will be automatically renewed, if you want to cancel it you can navigate to this option where you will see a delete button'
  }
};
export default subscriptions;
