import React from 'react';
import { connect } from 'react-redux';
import getProfileInfo from '../../../actions/admin/getProfileInfo';

// import '@ant-design/compatible/assets/index.css';


import { Form, Button, Col, Modal, Row } from "antd";

// import 'antd/lib/modal/style/css';


import { Field, reduxForm } from "redux-form";
import { CreateTextField } from "../../Helpers/Input/InputCreator";
import getAllAuthTypes from "../../../actions/admin/getAllAuthTypes";
import { Typography } from 'antd';


class AdminAddMetadataOptModal extends React.Component {

    onSubmit = (formValues) => {
        formValues.metadataID = this.props.metadataID;
        this.props.onSubmit(formValues)
    }
  
  render() {
    return (
      (<Modal open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
        <Form onFinish={this.props.handleSubmit((formValues) => this.onSubmit(formValues))} >
          <Row gutter={16}>
            <Col xs={24}>
                <Row gutter={16}>
                  <Col span={6}>
                    <Typography>Nombre de la opción</Typography>
                  </Col>
                  <Col span={18}>
                    <Field
                      name={"value"}
                      component={CreateTextField}
                      hasFeedback
                    />
                  </Col>
                </Row>
            </Col>
          </Row>

          <Row>
            <Button htmlType={'submit'} type={'primary'}>
              {this.props.btn}
            </Button>
          </Row>
        </Form>
      </Modal>)
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.auth.language,
  };
}

export default connect(mapStateToProps, { getProfileInfo, getAllAuthTypes })(
  reduxForm({ form: 'admin_add_metadat_opt_form' })(AdminAddMetadataOptModal)
);
