import { GET_ALL_METADATA } from "../types";

const cleanReportList = (postAction) => async (dispatch) => {
    await dispatch({
        type: GET_ALL_METADATA, payload: {
            reportList: {
                kind: 'users',
                total: 0,
                entries: []
            }
        }
    });
    if(postAction)
        postAction();
};

export default cleanReportList;