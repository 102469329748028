import netcoServer from '../../apis/netcoServer';
import {INCREASE_PENDING_FILES_COUNT, INFO_SHOW, LOAD_END, LOAD_START, LOCATE_SIGNATURES, PUT_FILES} from "../types";
import infoPayload from '../helpers/payloadHandlers/infoPayload';
import {push} from 'connected-react-router';
import _ from "lodash";
import {LOCATE_ROUTE, WORKFLOW_FILES_ROUTE} from "../../components/Root/routes";
import {
  addDocumentMetadata,
  checkMaxFileSize,
  checkPDFPassword, confirmTransferOperationModal, getBase64FromFileObject, isPDF,
  isPDFExtension,
  shouldIncreasePendingFiles,
  willBePDF
} from "../../components/Helpers/utils";
import getPDFTemplate from "../templates/getPDFTemplate";
import signPendingFiles from "../files/signPendingFiles";
import loadingCall from "../helpers/loadingCall";
import general from "../../const/general";
import {formValueSelector} from "redux-form";
import getBase64File from "../helpers/getBase64File";
let toPutFiles=[],toCombineFiles=[],toCombineFileNames=[],toCombineFilePasswords=[],toCombineFilesMetadata=[];
async function fillPasswords(formValues,dispatch,getState)
{
  for(let i = 0; i < toCombineFiles.length; i++ )
  {
    let currentPass=toCombineFilePasswords[i];
    if(!currentPass || currentPass=== "")
      toCombineFilePasswords[i]="";
    try
    {
      toCombineFilePasswords[i]=await checkPDFPassword(toCombineFiles[i],toCombineFileNames[i],
          toCombineFilePasswords[i],dispatch,getState().auth.language);
    }
    catch(err)
    {
      clearFiles();
      dispatch({type: INFO_SHOW, payload: err});
      return null;
    }
  }
  await combinePDFS(formValues,dispatch,getState);
}

export const putFilesLocalNew=(files,formValues) => async (dispatch,getState) =>
{
  const pendingFiles=[];
  if(!files || files.length<1)
    return null;
  if(!checkMaxFileSize(getState().user.maxFileSize,getState().auth.language,dispatch,files))
  {
    clearFiles();
    return;
  }
  for(let i = 0; i < files.length; i++ )
  {
    formValues.base64FileBytes=await getBase64FromFileObject(files[i]);
    formValues.fileName=files[i].name;
    formValues.metadata=files[i].metadata;
    let defaultPassword='';
    if(formValues.passwords)
      defaultPassword=formValues.passwords[formValues.fileName];
    const pendingFileArray= await putFile(formValues,dispatch,getState,defaultPassword);
    if(!pendingFileArray)
      return null;
    Array.prototype.push.apply(pendingFiles, pendingFileArray);
  }
  clearFiles();
  return pendingFiles;
}

const clearFiles=()=>
{
  toCombineFiles = [];
  toCombineFileNames = [];
  toPutFiles=[];
  toCombineFilePasswords=[];
  toCombineFilesMetadata=[];
}

async function putFile(formValues,dispatch,getState,defaultPassword='')
{
  let fileName=formValues.fileName;
  let password=null;
  try
  {
    password=await checkPDFPassword(formValues.base64FileBytes,fileName,defaultPassword,dispatch,getState().auth.language);
  }
  catch(err)
  {
    let message=err.info.message;
    if(message && message.message)
      message=message.message;
    manageError(dispatch,{data:{detail:message}},getState);
    return null;
  }
  if (isPDFExtension(fileName,getState))
  {
    let formDataConvert = {
      base64File: formValues.base64FileBytes,
      fileName: fileName,
      returnBase64File: true
    };
    let response = await loadingCall(dispatch,()=>netcoServer.newPost('/UserService/convertToPDFA', formDataConvert));
    if(!response || !response.data || !response.data.success)
    {
      manageError(dispatch,response,getState);
      return null;
    }
    if(!isPDF(fileName))
      fileName=`${fileName}.pdf`;
    formValues.base64FileBytes=response.data.base64File;
    formValues.fileName = fileName;
  }
  const passwords={},oldPasswords=formValues.passwords;
  passwords[fileName]=password;
  formValues.passwords=JSON.stringify(passwords);
  let newNumTransfer = formValues.numTransfer;
  if (newNumTransfer > 0 && !formValues.confirmTransfer) {
    if(formValues.newUsersToPut){
      Object.entries(formValues.signerFileSettings).forEach(([signer, settings]) => {
        if(settings.transferOperations && formValues.newUsersToPut.hasOwnProperty(signer)){
          newNumTransfer = newNumTransfer + JSON.parse(formValues.newUsersToPut[signer]).length-1;
        }
      });
    }
    try{
      formValues.confirmTransfer = await confirmTransferOperationModal(dispatch, newNumTransfer, getState().auth.language)
    }catch(err){
      let message=err.info.message;
      if(message && message.message)
        message=message.message;
      manageError(dispatch,{data:{detail:message}},getState);
      return null;
    }
  }
  const response = await loadingCall(dispatch,()=>netcoServer.newPost('/UserService/putFiles', _.omit(formValues, ['key'])));
  if(!response || !response.data || !response.data.success)
  {
    manageError(dispatch,response,getState);
    return null;
  }
  const metadata=formValues.metadata;
  if(metadata && metadata.length>0)
  {
    
    const files = response.data.result.filesInfo;
    for(let file in files){
      const uid=files[file].uid;
      for(var i=0;i<metadata.length;i++)
      {
        await addDocumentMetadata(metadata[i],uid,dispatch);
      }
    }
  }
  formValues.passwords=oldPasswords;
  if(shouldIncreasePendingFiles(formValues,getState))
    dispatch({type: INCREASE_PENDING_FILES_COUNT});
  const pendingFiles=response.data.result.filesInfo;
  pendingFiles.map((pendingFile)=>pendingFile.password=passwords);
  return pendingFiles;
}

const manageError=(dispatch,response,getState)=>
{
  clearFiles();
  console.log(response);
  if(response && response.data)
  {
    const detail=response.data.detail;
    if(detail && detail!=='')
      dispatch({type: INFO_SHOW, payload: infoPayload('error', detail)});
    else
      dispatch({type: INFO_SHOW, payload: infoPayload('error', response.status +"")});
  }
  if(!response)
    dispatch({type: INFO_SHOW, payload: infoPayload('error', general[getState().auth.language].error_com)});
  dispatch({type: LOAD_END});
};

async function combinePDFS(formValues,dispatch,getState)
{
  if(!checkMaxFileSize(getState().user.maxFileSize,getState().auth.language,dispatch,toCombineFiles))
  {
    clearFiles();
    return;
  }
  let response = await loadingCall(dispatch,()=>netcoServer.newPost('/UserService/combinePDFs', {base64Files:toCombineFiles,fileNames:toCombineFileNames,filePasswords:toCombineFilePasswords}));
  if(response && response.data && response.data.success)
  {
    let combinedFileName=formValues.combinedFileName;
    if(!combinedFileName)
      combinedFileName="combined.pdf";
    if(!combinedFileName.toLowerCase().endsWith(".pdf"))
      combinedFileName=combinedFileName + ".pdf";
    toPutFiles.push({file: response.data.base64File, name: combinedFileName,metadata:toCombineFilesMetadata});
  }
  else
    manageError(dispatch,response,getState);
}

const putFiles= (formValues, files, selectedUser = '',callSignFiles=false) => async (dispatch,getState) =>
{
  dispatch({type: LOAD_START});
  if(!formValues.userToPut && selectedUser)
    formValues.userToPut = selectedUser;
  const ids=formValues.templateIds;
  const hasTemplate=formValues.withTemplate && ids;
  if(files && files.length)
  {
    for(let i = 0; i < files.length; i++ )
    {
      const fileName=files[i].name;
      //formValues.base64FileBytes = await getBase64File(files[i]);
      //formValues.fileName = fileName;
      if(willBePDF(fileName,getState) && formValues.combinePDF && (files.length>1 || hasTemplate))
      {
        toCombineFilesMetadata=files[i].metadata;
        toCombineFiles.push(await getBase64File(files[i]));
        toCombineFileNames.push(fileName);
      }
      else
      {
        toPutFiles.push(files[i]);
        //toPutFiles.push({file:formValues.base64FileBytes,name:fileName});
      }

    }
  }
  if(hasTemplate)
  {
    for(let i=0;i<ids.length;i++)
    {
      let fileName=ids[i].id;
      if(!fileName)
        fileName=ids[i];
      const response=await dispatch(getPDFTemplate(fileName));
      const templateFile=getState().templateList.selectedTemplate;
      if(!templateFile)
      {
        manageError(dispatch,response,getState);
        return;
      }
      if(response && response.data && response.data.success)
      {
        const templateName=response.data.fileName;
        if(toCombineFiles.length>0 || (ids.length>1 && formValues.combinePDF))
        {
          toCombineFiles.push(templateFile);
          toCombineFileNames.push(templateName);
          toCombineFilesMetadata=ids[i].metadata;
        }
        else
          toPutFiles.push({file:templateFile,name:templateName,metadata:ids[i].metadata});
      }
    }
  }
  formValues.templateIds='';
  let pendingFiles=null;
  if(toCombineFiles.length>0)
  {
    if(toCombineFiles.length===1)
      toPutFiles.push({file: toCombineFiles[0], name: toCombineFileNames[0],metadata:ids&& ids.length>0?ids[0].metadata:[]});
    else
      await fillPasswords(formValues, dispatch, getState);
  }
  if(toPutFiles.length>0)
  {
    const selector = formValueSelector('load_files_form');
    let locate=selector(getState(), 'locate');
    if(locate || formValues.locate)
    {
      dispatch({type:LOCATE_SIGNATURES,payload: {files:toPutFiles,formValues:formValues}});
      dispatch(push(LOCATE_ROUTE));
      clearFiles();
      return;
    }
    else
      pendingFiles=await dispatch(putFilesLocalNew(toPutFiles,formValues));
  }
  if(!pendingFiles)
    return null;
  if(callSignFiles)
    await dispatch(signPendingFiles(pendingFiles));
  else
  {
    dispatch({type:PUT_FILES,payload:infoPayload('success', 'Archivos cargados satisfactoriamente!')});
    //dispatch(push(REDIRECT_DOCS_TABS_ROUTE + '?active=newworkflow'));
    dispatch(push(WORKFLOW_FILES_ROUTE))
  }
};
export default putFiles;
