import React from 'react';
import { Button, Input, Typography } from "antd";
import upPageIcon from "../../../resources/img/NewIcons/paginator/up-page.svg"
import downPageIcon from "../../../resources/img/NewIcons/paginator/down-page.svg"
import upPageIconInit from "../../../resources/img/NewIcons/paginator/up-page-init.svg"
import downPageIconEnd from "../../../resources/img/NewIcons/paginator/down-page-end.svg"

class PDFPaginator extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.numPages !== this.props.numPages)
            this.setState({ numPages: this.props.numPages });
        if (prevProps.pageNumber !== this.props.pageNumber)
            this.setState({ pageNumber: this.props.pageNumber });
    }

    constructor(props) {
        super(props);
        this.state = { pageNumber: this.props.pageNumber, numPages: this.props.numPages };
    }

    pageChange = (e) => {
        let page = e.target.value.replace(/\D/g, '');
        page = page && parseInt(page)
        this.setState({ pageNumber: page });
        
        if (page >= 1 && page<=this.state.numPages ) {
            this.props.onPageChange(page);
        }
    }

    pageBlur = () => {
        let newPage = this.state.pageNumber
        if (!newPage || newPage<1)
            newPage = 1
        else if (newPage > this.state.numPages)
            newPage = this.state.numPages

        if (newPage !== this.state.pageNumber) {
            this.setState({ pageNumber: newPage });
            this.props.onPageChange(newPage);
        }
    }

    handleNext = () => {
        const newPage = this.state.pageNumber + 1;
        this.setState({ pageNumber: newPage });
        this.props.onPageChange(newPage);
    };

    handlePrev = () => {
        const newPage = this.state.pageNumber - 1;
        this.setState({ pageNumber: this.state.pageNumber - 1 });
        this.props.onPageChange(newPage);
    };

    handleInitPage = () => {        
        this.setState({ pageNumber: 1 });
        this.props.onPageChange(1);
    };

    handleEndPage = () => {
        this.setState({ pageNumber:  this.state.numPages});
        this.props.onPageChange(this.state.numPages);
    };

    render() {
        if (this.state.numPages <= 0)
            return <></>;
        return <div style={{ display: 'flex', alignItems: 'center', background: '#E4E9F2', width:'100%', height:46 , justifyContent:'center', padding:'10px 0px'  }}>
                
                <Button style={{ margin: 0, marginRight: -20}} disabled={this.state.pageNumber <= 1} htmlType={'button'} className={'unstyled-button'}
                        onClick={this.handleInitPage}>
                        <img src={upPageIconInit} alt='upPageIcon' />
                </Button>
                <Button style={{ margin: 0 }} disabled={this.state.pageNumber <= 1} htmlType={'button'} className={'unstyled-button'}
                    onClick={this.handlePrev}>
                    <img src={upPageIcon} alt='upPageIcon' />
                </Button>
                <Input value={this.state.pageNumber} style={{ width: 50, height: 25, textAlign: 'center', fontSize: 12 }} onChange={this.pageChange}
                    onBlur={this.pageBlur} />
                <Typography className='text-title size12' style={{ marginLeft: 3 }}>{` de ${this.state.numPages}`}</Typography>
                
                <Button style={{ margin: 0 }} disabled={this.state.pageNumber >= this.state.numPages} htmlType={'button'} className={'unstyled-button'}
                    onClick={this.handleNext}>
                    <img src={downPageIcon} alt='downPage' />
                </Button>
                <Button style={{ margin: 0, marginLeft: -20 }} disabled={this.state.pageNumber >= this.state.numPages} htmlType={'button'} className={'unstyled-button'}
                    onClick={this.handleEndPage}>
                    <img src={downPageIconEnd} alt='downPage' />
                </Button>
            </div>
    }
}
export default PDFPaginator;