import netcoServer from '../../apis/netcoServer';
import {DELETE_PDF_TEMPLATE} from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";

const deletePDFTemplate= (selectedFile, isPrivate,parentId, id,callback) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/deletePDFTemplate', {selectedTemplate: selectedFile, private: isPrivate,id:id}));
  handleResponse(dispatch, response, DELETE_PDF_TEMPLATE, onSuccessPayload, null, [],[callback]);
};

const onSuccessPayload = () => {

};
export default deletePDFTemplate;