import React from "react";
import PrivateRoute from "../Root/PrivateRoute";
import {connect} from "react-redux";
import activateSubscription from "../../actions/admin/activateSubscription";
import {Alert, Button, Row} from "antd";
import getSubscriptionInfo from "../../actions/admin/getSubscriptionInfo";
import chargeSubscription from "../../actions/admin/chargeSubscription";
import 'react-credit-cards/es/styles-compiled.css';
import TitleAndInfo from "../Helpers/TitleAndInfo"
import getSubscriptionPlans from "../../actions/admin/getSubscriptionPlans";
import modifySubscription from "../../actions/admin/modifySubscription";
import {INFO_SHOW} from "../../actions/types";
import infoPayload from "../../actions/helpers/payloadHandlers/infoPayload";
import admin from "../../const/admin";
import getUserInfo from "../../actions/profile/getUserInfo";
import {CONFIRM_MODAL, MODIFY_SUBSCRIPTION_MODAL} from "../Feedback/Modal/types";
import modalDismiss from "../../actions/modal/modalDismiss";
import modalShow from "../../actions/modal/modalShow";
import general from "../../const/general";
import ConfirmWordPress from "../ConfirmWordPress";
import CreditCardForm from "./CreditCardForm";
import subscriptions from "../../const/subscriptions";

class Subscription extends React.Component
{
    state={resetForm:false}
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(this.props.wordpressUser && this.props.wordpressUser!==prevProps.wordpressUser)
            this.getSubscriptionInfo();
    }

    getSubscriptionInfo=()=>
    {
        if(this.props.wordpressUser)
        {
            this.props.getSubscriptionInfo();
            this.props.getSubscriptionPlans();
        }
    };

    componentDidMount()
    {
        this.getSubscriptionInfo();
    }

    activateSubscription=(formValues)=>
    {
        this.props.activateSubscription(formValues,this.activateOK,this.getSubscriptionInfo);
    };

    activateOK=()=>
    {
        this.getSubscriptionInfo();
        this.props.getUserInfo();
        this.props.dispatch({type: INFO_SHOW,payload: infoPayload('success', admin[this.props.language].processok)});
    };

    chargeSubscription=()=>
    {
        this.props.chargeSubscription(this.props.getSubscriptionInfo);
    };

    cancelOK=()=>
    {
        this.props.dispatch({type: INFO_SHOW,payload: infoPayload('success', admin[this.props.language].processok)});
        this.setState({resetForm:true},()=>this.setState({resetForm:false},this.getSubscriptionInfo));
    };

    cancelSubscription=()=>
    {
        this.props.modalShow({
            modalType: CONFIRM_MODAL,
            modalProps: {footer: null, title:general[this.props.language].delete},
            componentProps: {
                language: this.props.language,info: subscriptions[this.props.language].confirmDelete,
                onCancel:this.props.modalDismiss, onSubmit: ()=>{
                    this.props.modalDismiss();
                    this.props.modifySubscription({cancel:true},this.cancelOK,this.getSubscriptionInfo);
                }}
        });
    };

    modifySubscription=()=>
    {
        this.props.modalShow({
            modalType: MODIFY_SUBSCRIPTION_MODAL,
            modalProps: {footer: null, title:subscriptions[this.props.language].changeCard},
            componentProps: {
                onCancel:this.props.modalDismiss, onSubmit: (formValues)=>{
                    this.props.modalDismiss();
                    this.props.modifySubscription(formValues,this.cancelOK,this.getSubscriptionInfo);
                }}
        });
    };

    render()
    {
        if(!this.props.wordpressUser)
            return <ConfirmWordPress/>;
        const subscriptionInfo=this.props.subscriptionInfo;
        if(subscriptionInfo)
        {
            const endDate=subscriptionInfo.endDate;
            return <>
                <Row>{subscriptions[this.props.language].subscriptionStatusText}{endDate?'activa':'inactiva'}</Row>
                {endDate && <Row>{subscriptions[this.props.language].nextDate} {new Date(endDate).toISOString().slice(0,10)}</Row>}
                <Row>{subscriptions[this.props.language].associatedCard} {subscriptionInfo.mask}</Row>
                <Row style={{marginTop:'20px'}}>
                    <Button type={'danger'} onClick={this.modifySubscription}>{subscriptions[this.props.language].changeCard}</Button>
                </Row>
                <Row style={{marginTop:'20px'}}>{!endDate && <Button type={'primary'} onClick={this.chargeSubscription}>{subscriptions[this.props.language].retryCharge}</Button>}
                    <Button type={'danger'} onClick={this.cancelSubscription}>{subscriptions[this.props.language].delete}</Button>
                </Row>
            </>;
        }
        return <>
            <TitleAndInfo info={subscriptions[this.props.language].info1}/>
            <CreditCardForm showDocument={true} showPlans={true} submitForm={this.activateSubscription} resetForm={this.state.resetForm}
            plans={this.props.plans} buttonLabel={subscriptions[this.props.language].buy} dispatch={this.props.dispatch} language={this.props.language}/>
            <Row justify={'center'}>
                <Alert className={'mb-10 text-center info'} message={subscriptions[this.props.language].info2} type="info"/>
            </Row>
        </>;
    }
}
const mapStateToProps=(state) =>
{
    return {
        language: state.auth.language,
        subscriptionInfo: state.payment.subscriptionInfo,
        plans: state.payment.plans,
        wordpressUser:state.user.wordpressUser
    }
};
export default PrivateRoute(connect(mapStateToProps, {activateSubscription, getSubscriptionInfo,chargeSubscription,
getSubscriptionPlans,modifySubscription, getUserInfo, modalDismiss,modalShow})(Subscription));