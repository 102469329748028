import netcoServer from '../../apis/netcoServer';
import {CREATE_TIMESTAMP_SERVER} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import getAllTimestampServers from './getAllTimestampServers';
import modalDismiss from '../modal/modalDismiss';
import loadingCall from "../helpers/loadingCall";

const createTimestampServer= (formValues) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/createTimestampServer', formValues));
  handleResponse(dispatch, response, CREATE_TIMESTAMP_SERVER, onSuccessPayload, null, [getAllTimestampServers, modalDismiss]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Servidor de sello de tiempo agregado satisfactoriamente!');
};
export default createTimestampServer;