import netcoServer from '../../apis/netcoServer';
import {CREATE_WEBSERVICE_USER} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import getAllWebserviceUsers from "./getAllWebserviceUsers";
import modalDismiss from "../modal/modalDismiss";
import getBase64File from "../helpers/getBase64File";
import loadingCall from "../helpers/loadingCall";

const createWebserviceUser=(formValues, file) => async (dispatch) => {
  if(file !== null)
    formValues.base64File = await getBase64File(file);
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/createWebserviceUser', formValues));
  handleResponse(dispatch, response, CREATE_WEBSERVICE_USER, onSuccessPayload, null, [getAllWebserviceUsers, modalDismiss]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'IP autorizada agregada satisfactoriamente!');
};
export default createWebserviceUser;