import React from 'react';
import {Col} from "antd";
import {connect} from "react-redux";
import auth from "../../const/auth";

const PasswordPolicies = (props) =>
{
    if(!props.passwordPolicies)
        return <></>;
    const policies=JSON.parse(props.passwordPolicies);
    return <>
        <div className={'info size12'} style={{marginBottom:'20px'}}>
        <Col span={24}>
            {auth[props.language].passwordLength.replace('{0}',policies.minLength).replace('{1}',policies.maxLength)}
        </Col>
        {policies.requiresSpecialChars?<Col span={24}>
                {auth[props.language].passwordSpecialChars}
        </Col>:''}
        {policies.requiresUpperLowerChars?<Col span={24}>
                {auth[props.language].passwordUpperLower}
        </Col>:''}
    </div></>;
}
const mapStateToProps = state => {
    return {
        passwordPolicies: state.auth.passwordPolicies,
        language:state.auth.language
    };
};

export default connect(mapStateToProps,{})(PasswordPolicies);