import React from "react";
import { Button, Modal, Typography } from "antd";
import bookmark from '../../../resources/img/NewIcons/sign/bookmark.svg'
import trashIcon from '../../../resources/img/NewIcons/sign/trash.svg'
import { Table } from "antd";
import load_files from "../../../const/load_files";

class ViewPreferenceModal extends React.Component {

    state={option:{}}

    componentDidMount(){
        const saveOption = this.props.optionsTable.filter(item => item.temp === true)[0]
        this.setState({
            option:saveOption?saveOption:this.props.optionsTable[0]
        })
    }

    rowSelection = () => {
        return (
            {
                type: 'radio',
                selectedRowKeys: [this.state.option.optionName],
                onChange: (selectedRowKeys, selectedRows) => {
                    const option = selectedRows[0];
                    this.setState({option})
                },
                getCheckboxProps: (record) => ({
                    // Column configuration not to be checked
                    name: record.optionName,
                })
            }
        )
    }

    columns() {
        return (
            [{
                title: load_files[this.props.language].newPreferenceName,
                dataIndex: 'name',
                className: 'mobile-show',
                sorter: false,
                render: (text, record) => {
                    return <div style={{ display: 'flex' }}>
                        <img alt="bookmark" src={bookmark} />
                        <Typography>{record.optionName}</Typography>
                    </div>;
                }
            }, {
                dataIndex: 'actions',
                className: 'mobile-show',
                render: (text, record) => {
                    return record.optionName!=='Flujo de firma por defecto' && <Button className="unstyled-button float-right" onClick={()=>this.props.deletePreference(record.optionName,record.temp)}><img src={trashIcon} alt='' /></Button>
                }
            },]
        );
    }

    render() {
        return (
            (<Modal
                open={this.props.modalType !== null} width={519} {...this.props.modalProps} title={this.props.modalProps.title} centered={true}>
                <Table
                    columns={this.columns()}
                    dataSource={this.props.optionsTable}
                    size={'small'}
                    pagination={true}
                    rowSelection={this.rowSelection()}
                    rowKey={'optionName'}

                />
                <Button onClick={() => this.props.updatePreference(this.state.option.optionName, this.state.option.temp)} style={{ width: '231px' }} className='size12'
                    type={"primary light-primary rounded-sm"}> {load_files[this.props.language].selectedPreference.toUpperCase()}
                </Button>
            </Modal>)
        );
    }
}
export default ViewPreferenceModal;