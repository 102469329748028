import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form, Button, Modal, Row, Col, Typography } from "antd";

// import 'antd/lib/modal/style/css';


import { Field, reduxForm } from "redux-form";
import { CreatePasswordField } from "../Helpers/Input/InputCreator";
import { required } from "../Helpers/Input/validations";
import admin from "../../const/admin";
import home from "../../const/home"
import createsignok from "../../resources/img/NewIcons/home/createsignok.svg";

class NewPasswordModal extends React.Component {

  renderPasswordForm = () => {
    return <Form onFinish={this.props.handleSubmit(this.props.updateUserPassword)}>
      <Form.Item>
        <div style={{ marginTop: '5px' }} className={'mb-10'}>{admin[this.props.language].new_password}</div>
        <Field
          name="password"
          component={CreatePasswordField}
          size={'large'}
          className={'mb-10'}
          validate={[required]}
          hasFeedback />
      </Form.Item>
      <Form.Item>
        <div style={{ marginTop: '10px' }} className={'mb-10'}>{admin[this.props.language].confirm_password}</div>
        <Field
          name="password_confirmation"
          component={CreatePasswordField}
          size={'large'}
          className={'mb-10'}
          validate={[required]}
          hasFeedback />
      </Form.Item>
      <Button style={{ width: '260px', fontSize: '10px', marginRight: '20px', }} type={'light-primary rounded-sm'} onClick={this.props.onCancel}>{home[this.props.language].noThanks.toUpperCase()}</Button>
      <Button style={{ width: '260px' }} className='size12' type={"primary light-primary rounded-sm"} htmlType={'submit'}>{home[this.props.language].signUpHere.toUpperCase()}</Button>
      <div style={{ textAlign: 'center' }}>
        <Typography className={'text-title size15 color-black mt-10'}>{home[this.props.language].deleteUserDescription}</Typography>
        <Button className='mt-10' style={{ width: '260px', fontSize: '10px', marginRight: '20px' }} type={'light-primary rounded-sm'} onClick={this.props.selfDelete}>{home[this.props.language].deleteUser.toUpperCase()}</Button>
      </div>
    </Form>;
  }

  render() {
    return (
      (<Modal
        open={this.props.modalType !== null} width={600} {...this.props.modalProps} centered={true}>
        <Row justify={'center'}>
          <Col style={{ textAlign: 'center', marginTop: '67px' }} span={24}><img alt={''} src={createsignok} /></Col>
          <Col style={{ textAlign: 'center', marginTop: '37px' }} span={24}>
            <Typography style={{ marginBottom: '5px' }} className={'text-title size15 color-black'}>{this.props.message1}</Typography>
          </Col>
          <Col style={{ textAlign: 'center', marginTop: '5px' }} span={24}>
            <Typography style={{ marginBottom: '14px' }} className={'text-title size15 color-black'}>{this.props.message2}</Typography>
          </Col>
          <Col style={{ textAlign: 'center', maxWidth: '293px' }} span={24}>
            <Typography style={{ marginBottom: '1px' }}>{this.props.message3}</Typography>
          </Col>
        </Row>
        {this.props.message2 === '' ? <></> : this.renderPasswordForm()}
      </Modal>)
    );
  }


}

export default reduxForm({ form: 'new_password_modal_form' })(NewPasswordModal);
