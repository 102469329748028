import React from 'react';
import {Field, reduxForm} from 'redux-form';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Col, Row } from 'antd';
import {CreateCheckbox, CreatePasswordField, CreateTextField} from "../../Helpers/Input/InputCreator";

// import 'antd/lib/form/style/css';


// import 'antd/lib/row/style/css';


// import 'antd/lib/col/style/css';


import '../../Helpers/newCustomAnt.css';
import {CERTIFICATES_ROUTE, SIGNUP_ROUTE} from "../../Root/routes";
import {trimNormalizer, userNormalizer} from "../../Helpers/Input/normalizers";
import auth from "../../../const/auth";
import {getLoginPath} from "../../Helpers/utils";

class NetcoCodeSignUp extends React.Component {

  required = (value) => {return value ? undefined : auth[this.props.language].requiredfld;}   ;

  render() {
    const ref=this.props.initialValues.referenceNumber;
    const authCode=this.props.initialValues.authCode;
    return (
      <Form className={'regularForm'} onFinish={this.props.handleSubmit(this.props.onSubmit)}>
        <Row gutter={16}>
          <Col span={12}>
            <Field placeholder={auth[this.props.language].maillbl}
                   name="userName"
                   component={CreateTextField}
                   disabled={!!(this.props.initialValues.userName && this.props.initialValues.userName !== "")}
                   size={'large'}
                   className={'mb-10'}
                   validate={(value) => {return value ? undefined : auth[this.props.language].requiredfld;}}
                   normalize={userNormalizer}
                   maxLength={50}
                   hasFeedback/>
          </Col>
          <Col span={12}>
            <Field placeholder={auth[this.props.language].passwordlbl}
                   name="password"
                   size={'large'}
                   className={'mb-10'}
                   component={CreatePasswordField}
                   validate={this.required}
                   hasFeedback/>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Field placeholder={auth[this.props.language].passconfirmlbl}
                   name="password_confirmation"
                   size={'large'}
                   className={'mb-10'}
                   component={CreatePasswordField}
                   validate={this.required}
                   hasFeedback/>
          </Col>
          <Col span={12}>
            <Field placeholder={auth[this.props.language].referencelbl}
                   name="referenceNumber"
                   disabled={!!(this.props.initialValues.referenceNumber && this.props.initialValues.referenceNumber !== "")}
                   size={'large'}
                   className={'mb-10'}
                   readOnly={!!ref}
                   component={CreateTextField}
                   validate={this.required}
                   normalize={trimNormalizer}
                   hasFeedback/>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Field placeholder={auth[this.props.language].authcodelbl}
                   name="authCode"
                   disabled={!!(this.props.initialValues.authCode && this.props.initialValues.authCode !== "")}
                   size={'large'}
                   className={'mb-10 netcoIconPadding'}
                   readOnly={!!authCode}
                   component={CreateTextField}
                   validate={this.required}
                   normalize={trimNormalizer}
                   hasFeedback/>
          </Col>
          <Col span={12}>
            <Field name="agree"
                   component={CreateCheckbox}
                   validate={this.required}
                   hasFeedback>
              <a rel='noopener noreferrer' target={'_blank'}
                 href={window.netcourlid + '/comagre/AcuerdoDeComunicaciones.pdf'}>{auth[this.props.language].agreelbl}</a>
            </Field>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24} className={'text-center'}>
            <Form.Item>
              <Button type="primary light-primary rounded" htmlType="submit">
                {auth[this.props.language].newuserbtn}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="ghost rounded" htmlType="button" onClick={() => this.props.push(SIGNUP_ROUTE)}>
                {auth[this.props.language].backbtn}
              </Button>
            </Form.Item>
            <Form.Item className={'text-center'} >
              <p className={'link'}
                 onClick={() => this.props.push(getLoginPath(this.props.search + "&redirect=" + CERTIFICATES_ROUTE + "&option=1"))}>{auth[this.props.language].userExistsLabel}</p>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'netco_code_signup_form'
})(NetcoCodeSignUp)
