import netcoServer from '../../apis/netcoServer';
import {UPDATE_TIMESTAMP_SERVER} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import modalDismiss from "../modal/modalDismiss";
import loadingCall from "../helpers/loadingCall";

const updateTimestampServer= (formValues, callback) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/updateTimestampServer', formValues));
  handleResponse(dispatch, response, UPDATE_TIMESTAMP_SERVER, onSuccessPayload, null, [modalDismiss], [callback]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Servidor de sello de tiempo actualizado satisfactoriamente!');
};
export default updateTimestampServer;