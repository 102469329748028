import React from 'react';
import {connect} from 'react-redux';
import {notification} from "antd";

// import 'antd/lib/notification/style/css';


import './notification.css'
import '../../Helpers/newCustomAnt.css'
import danger from '../../../resources/img/NewIcons/danger.svg'
import success from '../../../resources/img/NewIcons/success.svg'

import infoDismiss from '../../../actions/info/infoDismiss'

class NotificationCreator extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      active: false,
      current: null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.renderNotification();
  }

  getIcon=()=>
  {
    const type=this.props.type;
    if(!type)
      return <img alt={''} src={success}/>;
    switch(type)
    {
      case "success":
        return <img alt={''} src={success}/>;
      case "error":
        return <img alt={''} src={danger}/>;
      default:
        return <img alt={''} src={success}/>;
    }
  }

  renderNotification() {
	if(this.props.type && this.props.type === 'close')
	{
		this.props.infoDismiss();
        this.setState({active: false, current: null});
		notification.destroy('active');
		return;
	}
	if (this.props.message !== null && !this.state.active) {
      this.createNotification();
    } else if (this.props.message !== null && this.props.message !== this.state.current && this.state.active) {
      notification.destroy('active');
      this.createNotification();
    }
  }

  createNotification () {
	let currentMessage=this.props.message;
	if(currentMessage)
	{
        currentMessage = currentMessage.replace("java.lang.Exception:", "");
        currentMessage = currentMessage.replace("javax.servlet.ServletException:", "");
        currentMessage = currentMessage.replace("java.lang.Exception", "");
        currentMessage = currentMessage.replace("java.lang.NullPointerException", "Error no controlado");
        if(currentMessage.startsWith("certificate expired on"))
        {
          console.warn(currentMessage);
          currentMessage = "Certificado Expirado";
        }
    }

    this.setState({active: true, current: this.props.message});
    notification[this.props.type]({
      message: this.props.type !== 'success' ? 'Error' : '',
      description: currentMessage,
      icon: this.getIcon(),
      key: 'active',
      className: 'new-notification-' + this.props.type,
      duration: this.props.type !== 'success' ? 8 : 6,
      onClose: () => {
        this.props.infoDismiss();
        this.setState({active: false, current: null});
      }
    });
  }

  render() {
    return (
      <React.Fragment/>
    );
  }

}

const mapStateToProps = state => {
  return {
    message: state.info.message,
    type: state.info.type,
    description: state.info.description
  };
};

export default connect(mapStateToProps, {infoDismiss})(NotificationCreator);
