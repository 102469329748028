import netcoServer from '../../apis/netcoServer';
import handleResponse from "../helpers/handleResponse";
import {GET_KEYS_INFO} from "../types";
import loadingCall from "../helpers/loadingCall";

const getKeysInfo= () => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getUserKeys', {}));
  handleResponse(dispatch, response, GET_KEYS_INFO, onSuccessPayload,null);
};

const onSuccessPayload = data => {
  return {
    keys: data.result.keys
  };
};
export default getKeysInfo;