import netcoServer from '../../apis/netcoServer';
import {GET_LDAP_CONFIGURATION} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const getLDAPConfiguration= () => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/getLDAPConfiguration', {}));
  handleResponse(dispatch, response, GET_LDAP_CONFIGURATION, onSuccessPayload);
};

const onSuccessPayload = data => {
  return {
    adminConfiguration: {
      ldap: data.result
    }
  };
};
export default getLDAPConfiguration;