import React from "react";
import {CreateSelectField} from "../Helpers/Input/InputCreator";
import {required} from "../Helpers/Input/validations";
import {Field, reduxForm} from "redux-form";
import {Button, Col, Row, Select} from "antd";

// import 'antd/lib/radio/style/css';


import signature from "../../const/signature";
import general from "../../const/general";

const SecondStep = (props) =>
{
  return (
    <div>

      <Row justify={'center'}>
        <Col sm={12} xs={24}>
         <Field
                 name="option"
                 component={CreateSelectField}
                 placeholder={signature[props.language].select_option}
                 validate={required}
                 hasFeedback>
            <Select.Option value="useTemplate">{signature[props.language].template}</Select.Option>
            <Select.Option value="loadFile">{signature[props.language].load_files}</Select.Option>
          </Field>
        </Col>
      </Row>
      <Row type="flex" gutter={16} justify="center" style={{marginTop: 20}}>
        <Button htmlType={'button'} type={"primary light-primary rounded-sm"}
                onClick={props.handleSubmit(props.next)}>{general[props.language].next}</Button>
      </Row>
    </div>
  );
};

export default reduxForm({
  form: 'signature_form',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(SecondStep);
