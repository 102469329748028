import React from 'react';
import PrivateRoute from "../Root/PrivateRoute";
import {connect} from "react-redux";
import DocsViewer from "../NewManagementDocs/DocsViewer";
import getPDFTemplatesInformation from "../../actions/templates/getPDFTemplatesInformation";

class NewTemplates extends React.Component
{

  handleSearch=(page,pageSize,parentId)=>
  {
    this.props.getPDFTemplatesInformation({parentId,limit: pageSize, skip:((page-1)*pageSize)});
  };

  render()
  {
    return <DocsViewer {...this.props} handleSearch={this.handleSearch} docsType={'templates'}/>;
  };
}

const mapStateToProps = state => {
  return {
    signedFileList: state.templateList,
    language: state.auth.language,
    user: state.user,
    dimApp: state.locate.dimensionsApp,
    auth: state.auth,
    pathname: state.router.location.pathname
  };
};

export default PrivateRoute(connect(mapStateToProps, {getPDFTemplatesInformation})(NewTemplates));