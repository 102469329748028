import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Modal } from "antd";

// import 'antd/lib/modal/style/css';


import {Field, reduxForm} from "redux-form";
import {CreateTextField} from "../Helpers/Input/InputCreator";
import general from "../../const/general";
import {required} from "../Helpers/Input/validations";

const MailModal = (props) => {

  const onSubmit = (formValues) => {
    props.sendSignedFilesUIDMail(formValues, props.uids);
  };

  return (
    (<Modal open={props.modalType !== null} width={500} {...props.modalProps}>
      <Form onFinish={props.handleSubmit(onSubmit)}>
        <div style={{marginTop:'10px'}} className={'mb-10'}>{general[props.language].mail}</div>
        <Field
               name="mail"
               component={CreateTextField}
               placeholder={general[props.language].mail}
               validate={required}
               hasFeedback/>
        <div style={{padding: '20px 0'}}>
          <Button htmlType={'submit'} type={'primary light-primary rounded-sm float-right'}>{general[props.language].send}</Button>
        </div>
      </Form>
    </Modal>)
  );
};

export default reduxForm({form: 'email_modal_form'})(MailModal);