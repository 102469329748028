//LOGIN MODULE
export const SIGNUP_ROUTE = '/signup';
export const BASIC_REGISTRATION_ROUTE = '/quick-signup';
export const NETCO_PKI_REGISTRATION_ROUTE = '/basic-signup';
export const NETCO_CODES_REGISTRATION_ROUTE = '/netco-code-signup';
export const ID_REGISTRATION_ROUTE = '/id-signup';
export const RECOVER_PASSWORD_ROUTE = '/recover-password';
export const RESTORE_PASSWORD_ROUTE = '/restore-password';
export const OTP_CODE_ROUTE = '/otp-code';
export const FINGERPRINT_ROUTE = '/fingerprint';
export const SQRL_ROUTE = '/sqrl';

//Home
export const HOME_ROUTE='/home';
export const HOME_NEWCERTIFICATE_ROUTE='/newcertificatepki';

// Modulo de Certificados
export const CERTIFICATES_ROUTE = '/certificado';
export const NETCO_CODES_CERTIFICATES_ROUTE = CERTIFICATES_ROUTE + '/netco-codes';
export const ENTRUST_CODES_CERTIFICATES_ROUTE = CERTIFICATES_ROUTE + '/entrust-codes';
export const ENTRUST_EPF_CERTIFICATES_ROUTE = CERTIFICATES_ROUTE + '/entrust-epf';
export const FIEL_CERTIFICATES_ROUTE = CERTIFICATES_ROUTE + '/fiel';
export const P12_CERTIFICATES_ROUTE = CERTIFICATES_ROUTE + '/p12';
export const ZIP_CERTIFICATES_ROUTE = CERTIFICATES_ROUTE + '/zip';
export const CSR_CERTIFICATES_ROUTE = CERTIFICATES_ROUTE + '/csr';
export const ANDES_CERTIFICATES_ROUTE = CERTIFICATES_ROUTE + '/andes';
export const DIR_CERTIFICATES_ROUTE = CERTIFICATES_ROUTE + '/directorio';

// Modulo de firmado
export const SIGNATURE_ROUTE = '/signature';
export const SIGNATURE_OPTIONS_ROUTE = SIGNATURE_ROUTE + '/opciones';
export const SIGNATURE_SIGN_ROUTE = SIGNATURE_ROUTE + '/firmar';
export const SIGNATURE_FIELDS_ROUTE = SIGNATURE_ROUTE + '/fields';
export const SIGNATUREQR_ROUTE = '/signatureqr';
export const SEND_AND_SIGN_ROUTE = '/sendsign';

// Modulo de documentos firmados
export const FILES_ROUTE = '/files';
export const PRIVATE_FILES_ROUTE = FILES_ROUTE + '/private';
export const PUBLIC_FILES_ROUTE = FILES_ROUTE + '/public';
export const PENDING_FILES_ROUTE = FILES_ROUTE + '/pending';
export const NEW_PENDING_FILES_ROUTE = FILES_ROUTE + '/newpending';
export const WORKFLOW_FILES_ROUTE = FILES_ROUTE + '/workflow';
export const REDIRECT_DOCS_ROUTE= '/redirectdocs';
//export const REDIRECT_DOCS_ROUTE= PENDING_FILES_ROUTE;
export const REDIRECT_DOCS_TABS_ROUTE= '/redirectdocs';
export const MANAGEMENT_ROUTE = FILES_ROUTE + '/management';
export const ARCHIVED_ROUTE = MANAGEMENT_ROUTE + '/archived'

//Modulo de verificaciones
export const VERIFICATION_ROUTE = '/verifications';

//Modulo de reportes
export const REPORTS_ROUTE = '/reports';

//Modulo de plantillas
export const TEMPLATES_ROUTE = '/plantillas';

//Modulo de carga de archivos
export const LOCATE_ROUTE = '/locate';

// Modulo de admin
export const ADMIN_ROUTE = '/admin';
export const SYSTEM_STATUS_ROUTE='/system';

// Modulo de perfil
export const PROFILE_ROUTE = '/profile';

//Módulo de compra de certificados
export const ECOM_ROUTE= '/ecommerce';
export const CHECK_ECOM_ROUTE= '/checkecom';
export const ACTIVATE_ROUTE= '/activate';
export const SUBSCRIPTION_ROUTE= '/subscription';
export const CHECKWP_ROUTE= '/checkwp';



//TEST
export const TEST_ROUTE='/test'

// Configuracion root
export const ROOT_ROUTE = HOME_ROUTE;
//export const ROOT_ROUTE = PENDING_FILES_ROUTE;
//export const ROOT_ROUTE='/files/pending/?signAllLogged=true&usePAD=false'
//export const ROOT_ROUTE = PENDING_FILES_ROUTE + "?signAllLogged=true&hideMenu=true";
//export const ROOT_ROUTE='/verifications'
