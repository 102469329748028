import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import {Col, Modal, Typography, Row, Button} from "antd";

// import 'antd/lib/modal/style/css';


import DropZoneField from "../Helpers/DropzoneField";
import templates from "../../const/templates";

class UploadTemplatesModal extends React.Component
{
    state={formFile:[]}

    handleOnDrop = (files, onChange) =>
    {
        this.setState({formFile: [...this.state.formFile, ...files]}, () => onChange(files));
    };

    handleDeleteFile=(value)=>
    {
        this.setState({formFile: this.state.formFile.filter(item => item.name !== value)});
    }

    render()
    {
        return (
            (<Modal
                open={this.props.modalType !== null} width={768} {...this.props.modalProps} title={this.props.modalProps.title} centered={true}>
                <Typography style={{paddingTop:'30px'}}>{templates[this.props.language].add_info}</Typography>
                <Row style={{marginTop:'30px'}}>
                    <Col span={24}>
                        <DropZoneField  multiple={true} handleOnDrop={this.handleOnDrop} formFile={this.state.formFile}
                            deleteCard={this.handleDeleteFile}
                        />
                    </Col>
                    <Col span={24} style={{textAlign:'center',marginTop:'15px'}}>
                        <Button className='text-title size12 border-button' onClick={()=>this.props.onSubmit(this.state.formFile)}  disabled={this.state.formFile.length>0?'':'disabled'}
                                style={{ width: 194, height: 32}}>
                            {templates[this.props.language].load.toUpperCase()}
                        </Button>
                    </Col>
                </Row>
            </Modal>)
        );
    }
}
export default UploadTemplatesModal;