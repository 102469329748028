import React from "react";
import { Button, Modal, Typography, Divider } from "antd";
import load_files from "../../../const/load_files";

class ConfirmTransferModal extends React.Component {

    render() {
        return (
            (<Modal
                open={this.props.modalType !== null} width={500} {...this.props.modalProps} title={this.props.modalProps.title} centered={true}>
                <Typography style={{margin:9}}>
                    {load_files[this.props.language].confirmTransferText}
                    <span style={{ fontWeight: 'bold' }}>
                        {load_files[this.props.language].confirmTransferText1.replace('{numTransfer}', this.props.numTransfer)}
                    </span>
                    {load_files[this.props.language].confirmTransferText2}
                </Typography>
                <Divider/>
                <div style={{display:'flex', justifyContent:'end'}}>
                    <Button onClick={this.props.onCancel} style={{ width: '95px' }} className='text-title size12 dropdown-button'>
                        {load_files[this.props.language].cancel.toUpperCase()}
                    </Button>
                    <Button onClick={this.props.send} style={{ width: '145px', marginLeft:25 }} className='text-title size12'
                        type={"primary light-primary rounded-sm"}> {load_files[this.props.language].acceptSend.toUpperCase()}
                    </Button>
                </div>
            </Modal>)
        );
    }
}
export default ConfirmTransferModal;