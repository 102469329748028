import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import {Modal} from "antd";

// import 'antd/lib/modal/style/css';


import {renderMessageWithLinks} from "../Helpers/utils";

const showMessage = (props) => {
  return (
    (<Modal open={props.modalType !== null} width={500} {...props.modalProps}>
      <h3>{props.name}</h3>
      <div>{renderMessageWithLinks(props.message)}</div>
    </Modal>)
  );
};

export default showMessage;