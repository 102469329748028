import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Modal } from "antd";

// import 'antd/lib/modal/style/css';


import {Field, reduxForm} from "redux-form";
import {CreateTextField} from "../Helpers/Input/InputCreator";
import {required} from "../Helpers/Input/validations";

const RenameKey = (props) => {

  return (
    (<Modal open={props.modalType !== null} width={500} {...props.modalProps}>
      <Form onFinish={props.handleSubmit(props.onSubmit)}>
        <Field
               name="nameKey"
               component={CreateTextField}
               rows={4}
               placeholder={"Nombre de la llave"}
               validate={[required]}
               hasFeedback/>
        <div style={{padding: '20px 0'}}>
          <Button htmlType={'submit'} type={'primary light-primary rounded-sm float-right'}>{props.btn}</Button>
        </div>
      </Form>
    </Modal>)
  );
};

export default reduxForm({form: 'rename_key_modal_form'})(RenameKey);