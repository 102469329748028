import React from 'react';
import { connect } from 'react-redux';

// import '@ant-design/compatible/assets/index.css';


import { Form, Button, Col, Modal, Row } from "antd";

// import 'antd/lib/modal/style/css';


import { Field, reduxForm } from "redux-form";
import { CreateTextField } from "../../Helpers/Input/InputCreator";
import { Typography } from 'antd';


class AdminMetadataModal extends React.Component {
  state = { options: [], id: this.props.metaID };

  componentDidMount() {
    const options = this.props.metaDataOpt.filter(option => option.metadataID === this.state.id);
    const initialValues = options.reduce((acc, option) => {
      acc["opt-" + option.id] = option.value;
      return acc;
    }, {});

    this.props.initialize(initialValues);
    this.setState({ options });
  }

  onSubmit = (formValues) => {
    const sendForm = {};
    sendForm.values = Object.keys(formValues).reduce((acc, key) => {
      const newKey = key.replace("opt-", "");
      acc[newKey] = formValues[key];
      return acc;
    }, {});

    const opcionesOriginales = this.state.options.reduce((obj, item) => {
      obj[item.id] = item.value;
      return obj;
    }, {});

    const opcionesDiferentes = {};

    Object.entries(sendForm.values).forEach(([key, value]) => {
      if (!opcionesOriginales[key] || opcionesOriginales[key] !== value) {
        opcionesDiferentes[key] = value;
      }
    });

    this.props.onSubmit({ metadataID: this.state.id, values: opcionesDiferentes })
  }

  render() {
    return (
      (<Modal open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
        <Form onFinish={this.props.handleSubmit((formValues) => this.onSubmit(formValues))} >
          <Row gutter={16}>
            <Col xs={24}>
              {this.state.options.map((option) => (
                <Row gutter={16} key={option.id}>
                  <Col span={6}>
                    <Typography>Opción ID {option.id}</Typography>
                  </Col>
                  <Col span={18}>
                    <Field
                      name={"opt-" + option.id.toString()}
                      component={CreateTextField}
                      placeholder={option.value}
                      hasFeedback
                    />
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>

          <Row>
            <Button htmlType={'submit'} type={'primary'}>
              {this.props.btn}
            </Button>
          </Row>
        </Form>
      </Modal>)
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.auth.language,
  };
}

export default connect(mapStateToProps, { })(
  reduxForm({ form: 'admin_edit_metadata_opt_form' })(AdminMetadataModal)
);
