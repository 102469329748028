import React from 'react';
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import NewSignatureOptionsForm from './NewSignatureOptionsForm'
import authorization from "../../../../authorization";
import showOptionsSignature from '../../../../actions/info/showOptionsSignature';
import updatePDFFeatures from '../../../../actions/profile/updatePDFFeatures';
import { initializeProfileOptions } from '../../../Helpers/utils';
import getValidOperations from '../../../../actions/signature/getValidOperations';
import updateUserParam from "../../../../actions/profile/updateUserParam";
import updateUserSettings from '../../../../actions/profile/updateUserSettings';
import _ from "lodash";

class NewSignatureOptions extends React.Component {

  componentDidMount() {
    this.props.getValidOperations().then(() => initializeProfileOptions(this.props));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      initializeProfileOptions(this.props);
    }
  }

  getOnlyDifferentValues = (newValues) => {
    const userSettings = this.props.user
    const jsonValues = {
      ..._.pick(userSettings, ['tsid', 'reason', 'previewPDF', 'mails', 'signAllPages']),
      publish: userSettings.publish === 'true',
      certification: userSettings.certification === 'true',
      operation: userSettings.operation,
      useImage: userSettings.useImage + '',
      p7z: userSettings.p7z,
      fillpdf: userSettings.fillpdf
    }
    const differentValues = {}
    Object.keys(newValues).forEach(key => {
      if(newValues[key] !== jsonValues[key])
        differentValues[key] = newValues[key]
    });
    return Object.entries(differentValues).length === 0? null : differentValues
  }

  onSubmit = (form) => {
    const newValues = this.getOnlyDifferentValues(form)
    if (newValues)
      this.props.updateUserSettings(newValues, () => { this.props.showOptionsSignature(false) })
    else
      this.props.showOptionsSignature(false) 
  }

  render() {
    return (
      <nav >
        <NewSignatureOptionsForm mode={'horizontal'}  {...this.props} onSubmit={this.onSubmit} handleSubmit={this.props.handleSubmit} />
      </nav>
    );
  }
}


function mapStateToProps(state) {
  return {
    visible: state.locate.visibleSignatureOptions,
    language: state.auth.language,
    user: state.user,
    validOperations: state.signatureFiles.validOperations,
    dimApp: state.locate.dimensionsApp
  }
}

export default authorization(connect(mapStateToProps, { showOptionsSignature, updatePDFFeatures, getValidOperations, updateUserParam, updateUserSettings })(reduxForm({ form: 'new_options_form' })(NewSignatureOptions)));
