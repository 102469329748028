import React, {useEffect,useRef} from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Col, Row, Typography } from "antd";
import {Field, reduxForm} from "redux-form";
import {CreateCheckbox, CreatePasswordField, CreateTextField} from "../../Helpers/Input/InputCreator";
import admin from "../../../const/admin";
import general from "../../../const/general";

const LDAPConfigurationForm = (props) => {

  const {ldap} = props;
  const localProps =useRef(props);
  useEffect(() => {
    localProps.current.initialize(ldap);
  }, [ldap]);

  return (
      <Form onFinish={props.handleSubmit(props.setLDAPConfiguration)} style={{margin:'44px 52px' }}>
        <Typography className='text-title color-black'>Configurar LDAP</Typography>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Typography className='text-title size12 mb-10 mt-30'/>
            <Field
                   name="hasInt"
                   component={CreateCheckbox}
                   placeholder="Activar"
                   hasFeedback>
              {admin[props.language].activate_ldap}
            </Field>
          </Col>
          <Col xs={24} md={12}/>
          <Col xs={24} md={12}>
            <Typography className='text-title size12 mb-10 mt-30'>{admin[props.language].server_ldap.toUpperCase()}</Typography>
            <Field
                   name="ldapServer"
                   component={CreateTextField}
                   placeholder={admin[props.language].server_ldap}
                   hasFeedback/>
          </Col>
          <Col xs={24} md={12}>
            <Typography className='text-title size12 mb-10 mt-30'>{admin[props.language].user_ldap.toUpperCase()}</Typography>
            <Field
                   name="ldapUserName"
                   component={CreateTextField}
                   placeholder={admin[props.language].user_ldap}
                   hasFeedback/>
          </Col>
          <Col xs={24} md={12}>
            <Typography className='text-title size12 mb-10 mt-30'>{admin[props.language].password.toUpperCase()}</Typography>
            <Field
                   name="ldapPass"
                   size={'large'}
                   className={'mb-10'}
                   component={CreatePasswordField}
                   placeholder={admin[props.language].password}
                   hasFeedback/>
          </Col>
          <Col xs={24} md={12}>
            <Typography className='text-title size12 mb-10 mt-30'>{admin[props.language].confirm_password.toUpperCase()}</Typography>
            <Field
                   name="ldapPassConfirm"
                   size={'large'}
                   className={'mb-10'}
                   component={CreatePasswordField}
                   placeholder={admin[props.language].confirm_password}
                   hasFeedback/>
          </Col>
          <Col xs={24} md={12}>
            <Typography className='text-title size12 mb-10 mt-30'>{admin[props.language].db_ldap.toUpperCase()}</Typography>
            <Field
                   name="ldapBaseDN"
                   className={'mb-10'}
                   component={CreateTextField}
                   placeholder={admin[props.language].db_ldap}
                   hasFeedback/>
          </Col>
          <Col xs={24} md={12}>
            <Typography className='text-title size12 mb-10 mt-30'>{admin[props.language].id_ldap.toUpperCase()}</Typography>
            <Field
                name="ldapID"
                   className={'mb-10'}
                   component={CreateTextField}
                   placeholder={admin[props.language].id_ldap}
                   hasFeedback/>
          </Col>
        </Row>
        <Row>
          <Button className={'float-right mt-30'} htmlType={'submit'} type={'primary'}>{general[props.language].edit.toUpperCase()}</Button>
        </Row>
      </Form>
  );
};

export default reduxForm({form: 'admin_ldap_form'})(LDAPConfigurationForm);
