import React from "react";
import {useDrag, useDrop} from "react-dnd";

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const type = 'DragbleBodyRow';
    const ref = React.useRef();
    const entries=restProps.entries;
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: monitor => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: item => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type:type,
        item:()=>({index}),
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => {
            return entries[index].name!=='...';
        }
    });
    drop(drag(ref));
    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: entries && entries[index] && entries[index].name !=='...'?'move':'default', ...style }}
            {...restProps}
        />
    );
};

export default DraggableBodyRow;
