import netcoServer from '../../apis/netcoServer';
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const checkWordpress= (formValues,callback, onError) => async (dispatch) =>
{
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/checkWP', formValues));
  handleResponse(dispatch, response, null, null, null, [],[callback],[onError]);
};
export default checkWordpress;
