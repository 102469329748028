import {
  CLEAR_PDF_TEMPLATE,
  GET_PDF_TEMPLATE,
  GET_PDF_TEMPLATES_INFORMATION,
  LOG_OUT
} from "../actions/types";

const INITIAL_STATE = {
  entries: [],
  totalCount: 0,
  selectedTemplate: null
};

const templateList= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PDF_TEMPLATES_INFORMATION:
      return {...state, ...action.payload.templateList};
    case GET_PDF_TEMPLATE:
      return {...state, ...action.payload.templateList};
    case CLEAR_PDF_TEMPLATE:
      return INITIAL_STATE;
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default templateList;