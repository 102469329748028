//INITIAL SETUP
export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const INVALID_SESSION = 'INVALID_SESSION';
export const GET_REGISTRATION_OPTIONS = 'GET_REGISTRATION_OPTIONS';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

//SESSION
export const CREATE_SESSION='CREATE_SESSION'

//AUTH
export const SIGN_UP = 'SIGN_UP';
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const LOG_IN = 'LOG_IN';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_AFTER = 'LOG_OUT_AFTER';
export const CHECK_BASIC_AUTHENTICATION = 'CHECK_BASIC_AUTHENTICATION';
export const CHECK_APP_CODE_AUTHENTICATION = 'CHECK_APP_CODE_AUTHENTICATION';
export const CLEAR_APP_CODE='CLEAR_APP_CODE';
export const CLEAR_SIGNER_CODE='CLEAR_SIGNER_CODE';
export const START_STRONG_AUTHENTICATION = 'START_STRONG_AUTHENTICATION';
export const CHECK_STRONG_AUTHENTICATION = 'CHECK_STRONG_AUTHENTICATION';
export const BASIC_REGISTRATION = 'BASIC_REGISTRATION';
export const NETCO_PKI_REGISTRATION = 'NETCO_PKI_REGISTRATION';
export const NETCO_CODES_REGISTRATION = 'NETCO_CODES_REGISTRATION';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const NEW_PASSWORD = 'NEW_PASSWORD';


//INFO
export const INFO_DISMISS = 'INFO_DISMISS';
export const INFO_SHOW = 'INFO_SHOW';

//LOAD
export const TABLE_LOAD_START = 'TABLE_LOAD_START';
export const TABLE_LOAD_END = 'TABLE_LOAD_END';
export const LOAD_START = 'LOAD_START';
export const LOAD_END = 'LOAD_END';


//QUERIES
export const FETCH_USER = 'FETCH_USER';
export const FETCH_PROFILES = 'FETCH_PROFILES';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PETS = 'FETCH_PETS';
export const CLEAN_FORM = 'CLEAN_FORM';

//MODAL
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';


//SELECT
export const SELECT_PET = 'SELECT_PET';
export const SELECT_PROFILE = 'SELECT_PROFILE';


//CERTIFICATES MODULE
export const IMPORT_CERTIFICATE_NETCO_CODES = 'IMPORT_CERTIFICATE_NETCO_CODES';
export const IMPORT_CERTIFICATE_ENTRUST_CODES = 'IMPORT_CERTIFICATE_ENTRUST_CODES';
export const IMPORT_CERTIFICATE_FROM_ENTRUST_EPF = 'IMPORT_CERTIFICATE_FROM_ENTRUST_EPF';
export const IMPORT_CERTIFICATE_FROM_P12 = 'IMPORT_CERTIFICATE_FROM_P12';
export const IMPORT_CERTIFICATE_FROM_ZIP = 'IMPORT_CERTIFICATE_FROM_ZIP';
export const IMPORT_CERTIFICATE_FROM_FIEL = 'IMPORT_CERTIFICATE_FROM_FIEL';
export const GENERATE_CSR = 'GENERATE_CSR';
export const REQUEST_ANDES = 'REQUEST_ANDES';
export const GET_LDAP_USER_INFO = 'GET_LDAP_USER_INFO';
export const GET_KEYS_INFO = 'GET_KEYS_INFO';

//ADMINS MODULE
export const GET_ALL_TIMESTAMP_SERVERS = 'GET_ALL_TIMESTAMP_SERVERS';
export const GET_ALL_LOG_OPERATIONS = 'GET_ALL_LOG_OPERATIONS';
export const CREATE_TIMESTAMP_SERVER = 'CREATE_TIMESTAMP_SERVER';
export const DELETE_TIMESTAMP_SERVER = 'DELETE_TIMESTAMP_SERVER';
export const GET_TIMESTAMP_SERVER_INFO = 'GET_TIMESTAMP_SERVER_INFO';
export const UPDATE_TIMESTAMP_SERVER = 'UPDATE_TIMESTAMP_SERVER';
export const GET_ALL_PROFILES = 'GET_ALL_PROFILES';
export const GET_ALL_AUTH_TYPES = 'GET_ALL_AUTH_TYPES';
export const GET_PROFILES = 'GET_PROFILES';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export const GET_WEBSERVICE_USER_INFO = 'GET_WEBSERVICE_USER_INFO';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const TEST_SMTP = 'TEST_SMTP';
export const GET_ALL_WEBSERVICE_USERS = 'GET_ALL_WEBSERVICE_USERS';
export const CREATE_WEBSERVICE_USER = 'CREATE_WEBSERVICE_USER';
export const DELETE_WEBSERVICE_USER = 'DELETE_WEBSERVICE_USER';
export const UPDATE_WEBSERVICE_USER = 'UPDATE_WEBSERVICE_USER';
export const GET_CONFIGURATION = 'GET_CONFIGURATION';
export const SET_CONFIGURATION_PARAM = 'SET_CONFIGURATION_PARAM';
export const UPDATE_USER_PARAM = 'UPDATE_USER_PARAM';
export const GET_LDAP_CONFIGURATION = 'GET_LDAP_CONFIGURATION';
export const SET_LDAP_CONFIGURATION = 'SET_LDAP_CONFIGURATION';
export const GET_TASK_CONFIGURATION = 'GET_TASK_CONFIGURATION';
export const GET_SYSTEM_CONFIGURATION = 'GET_SYSTEM_CONFIGURATION';
export const GET_SYSTEM_LOG = 'GET_SYSTEM_LOG';
export const SET_TASK_CONFIGURATION = 'SET_TASK_CONFIGURATION';
export const GET_ALL_USERS_INFO = 'GET_ALL_USERS_INFO';
export const GET_ADMIN_USER_INFO = 'GET_ADMIN_USER_INFO';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCTS_INFO = 'GET_PRODUCTS_INFO';
export const GET_SUBSCRIPTION_INFO = 'GET_SUBSCRIPTION_INFO';
export const GET_ORDERS = 'GET_ORDERS';
export const CREATE_ORDER = 'CREATE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const GET_TOKEN_ITEMS = 'GET_TOKEN_ITEMS';
export const GET_TOKEN = 'GET_TOKEN';
export const USE_TOKEN = 'USE_TOKEN';
export const GET_PURCHASEOPTIONS = 'GET_PURCHASEOPTIONS';
export const GET_CUSTOMIZATION = 'GET_CUSTOMIZATION';

//VERIFICATIONS MODULE
export const VERIFY_FILES = 'VERIFY_FILES';
export const CLEAN_VERIFIED_FILES = 'CLEAN_VERIFIED_FILES';
export const VERIFY_ENCRYPTED_FILES = 'VERIFY_ENCRYPTED_FILES';

//REPORT MODULE
export const GET_LOGS = 'GET_LOGS';
export const GET_SIGN_REPORT = 'GET_SIGN_REPORT';
export const GET_PAGES_REPORT = 'GET_PAGES_REPORT';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_CERTIFICATES_REPORT = 'GET_CERTIFICATES_REPORT';
export const GET_PENDING_REPORT = 'GET_PENDING_REPORT';
export const GET_ANDES_REPORT = 'GET_ANDES_REPORT';


//FILES
export const GET_SIGNED_FILES_INFO = 'GET_SIGNED_FILES_INFO';
export const GET_PUBLIC_SIGNED_FILES_INFO = 'GET_PUBLIC_SIGNED_FILES_INFO';
export const DEL_SIGNED_FILES = 'DEL_SIGNED_FILES';
export const SEND_SIGNED_FILES_UID_MAIL = 'SEND_SIGNED_FILES_UID_MAIL';
export const VERIFY_FILES_WITH_UID = 'VERIFY_FILES_WITH_UID';
export const GET_PENDING_FILES_INFORMATION = 'GET_PENDING_FILES_INFORMATION';
export const INCREASE_PENDING_FILES_COUNT='INCREASE_PENDING_FILES_COUNT';
export const UPDATE_PENDING_FILES_COUNT='UPDATE_PENDING_FILES_COUNT';
export const DECREASE_PENDING_FILES_COUNT='DECREASE_PENDING_FILES_COUNT';
export const GET_WORKFLOW_FILES_INFORMATION = 'GET_WORKFLOW_FILES_INFORMATION';
export const GET_WORKFLOW_DETAILS_INFORMATION = 'GET_WORKFLOW_DETAILS_INFORMATION';
export const GET_WORKFLOW_OPTIONS = 'GET_WORKFLOW_OPTIONS';
export const ADD_WORKFLOW_OPTIONS = 'ADD_WORKFLOW_OPTIONS';
export const REMOVE_WORKFLOW_OPTIONS = 'REMOVE_WORKFLOW_OPTIONS';
export const CLEAR_WORKFLOW_DETAILS_INFORMATION = 'CLEAR_WORKFLOW_DETAILS_INFORMATION';
export const DELETE_PENDING_FILES = 'DELETE_PENDING_FILES';
export const PUT_FILES_UID = 'PUT_FILES_UID';
export const SIGN_PENDING_FILES = 'SIGN_PENDING_FILES';
export const RE_SIGN_FILES = 'RE_SIGN_FILES';
export const GET_ALL_SIGNED_FILES_INFO = 'GET_ALL_SIGNED_FILES_INFO'

//SEND FILES
export const PUT_FILES = 'PUT_FILES';
export const LOCATE_SIGNATURES = 'LOCATE_SIGNATURES';

//LOCATE
export const LOCATE_APP_OPTIONS = 'LOCATE_APP_OPTIONS';
export const LOCATE_OPTIONS_SIGNATURE = 'LOCATE_OPTIONS_SIGNATURE';
export const LOCATE_DIMENSIONS_APP = 'LOCATE_DIMENSIONS_APP';
export const LOCATE_SHOW_SHORTCUTS = 'LOCATE_SHOW_SHORTCUTS';
export const LOCATE_SHOW_MENU = 'LOCATE_SHOW_MENU';
export const LOCATE_SHOW_SIDER = 'LOCATE_SHOW_SIDER';
export const LOCATE_SHOW_SIDER_DRAWER = 'LOCATE_SHOW_SIDER_DRAWER';
export const LOCATE_HIDE_MENU = 'LOCATE_HIDE_MENU';
export const LOCATE_HIDE_HEADER = 'LOCATE_HIDE_HEADER';
export const LOCATE_HIDE_FOOTER = 'LOCATE_HIDE_FOOTER';

//SIGNATURE
export const SIGN_FILES = 'SIGN_FILES';
export const SIGN_FROM_TEMPLATE = 'SIGN_FROM_TEMPLATE';
export const PUT_PREVIEW_SIGN_FILES = 'PUT_PREVIEW_SIGN_FILES';
export const GET_VALID_OPERATIONS = 'GET_VALID_OPERATIONS';
export const UPDATE_USER_COORDS = 'UPDATE_USER_COORDS';
export const CREATE_PDF_FROM_TEMPLATE = 'CREATE_PDF_FROM_TEMPLATE';
export const START_STRONG_TX = 'START_STRONG_TX';
export const RESET_STRONG_TX = 'RESET_STRONG_TX';
export const RESET_AUTH_SIGN_PARAMS = 'RESET_AUTH_SIGN_PARAMS';
export const CLEAN_SIGNATURE_FILES = 'CLEAN_SIGNATURE_FILES';
export const SIGN_PENDING_URL_FILE = 'SIGN_PENDING_URL_FILE';
export const HIDE_MENU = 'HIDE_MENU';
export const SHOW_MENU = 'SHOW_MENU';
export const GET_SIGNATUREQR = 'GET_SIGNATUREQR'
export const SET_LOADING_PAD = 'SET_LOADING_PAD'

//TEMPLATES
export const GET_PDF_TEMPLATES_INFORMATION = 'GET_PDF_TEMPLATES_INFORMATION';
export const UPLOAD_PDF_TEMPLATE = 'UPLOAD_PDF_TEMPLATE';
export const DELETE_PDF_TEMPLATE = 'DELETE_PDF_TEMPLATE';
export const GET_PDF_TEMPLATE = 'GET_PDF_TEMPLATE';
export const CLEAR_PDF_TEMPLATE = 'CLEAR_PDF_TEMPLATE';
export const GET_PDF_TEMPLATE_FIELDS = 'GET_PDF_TEMPLATE_FIELDS';

// PROFILE
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_IMAGE_TYPE_LIST = 'GET_IMAGE_TYPE_LIST';
export const GET_FONTS = 'GET_FONTS';
export const GET_TIMESTAMPS_INFO = 'GET_TIMESTAMPS_INFO';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const DELETE_USER_CREDENTIALS = 'DELETE_USER_CREDENTIALS';
export const UPDATE_PDF_FEATURES = 'UPDATE_PDF_FEATURES';
export const UPDATE_USER_FINGERPRINT = 'UPDATE_USER_FINGERPRINT';
export const UPDATE_USER_IMAGE = 'UPDATE_USER_IMAGE';
export const GET_USER_IMAGE_TO_SHOW = 'GET_USER_IMAGE_TO_SHOW';

// Metadatos
export const GET_ALL_METADATA = 'GET_ALL_METADATA';
export const GET_METADATA_OPTIONS = 'GET_METADATA_OPTIONS';