import netcoServer from '../../apis/netcoServer';
import {DELETE_TIMESTAMP_SERVER} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import getAllTimestampServers from './getAllTimestampServers';
import loadingCall from "../helpers/loadingCall";

const deleteTimestampServer= (id) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/deleteTimestampServer', {id: id}));
  handleResponse(dispatch, response, DELETE_TIMESTAMP_SERVER, onSuccessPayload, null, [getAllTimestampServers]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Servidor de sello de tiempo eliminado satisfactoriamente!');
};
export default deleteTimestampServer;