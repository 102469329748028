import netcoServer from "../../apis/netcoServer";
import {push} from 'connected-react-router';
import {authType} from "../../const/authType";
import {START_STRONG_AUTHENTICATION, START_STRONG_TX} from "../types";
import {
  FINGERPRINT_ROUTE,
  OTP_CODE_ROUTE,
  SQRL_ROUTE
} from "../../components/Root/routes";
import loadingCall from "../helpers/loadingCall";
import infoShow from "../info/infoShow";
import checkStrongAuthentication from "../auth/checkStrongAuthentication";


const startStrongTx= (callback, content = null) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/LoginService/startStrongAuthentication', {}));
  let basicAuth;
  if (response.data.success === 'true' || response.data.success === true) {
    switch (authType[response.data.authType]) {
      case 'BASIC_AUTHENTICATION':
        basicAuth = true;
        break;
      case 'FINGERPRINT_AUTHENTICATION':
        dispatch(push(FINGERPRINT_ROUTE));
        break;
      case 'SQRL_AUTHENTICATION':
        dispatch({type: START_STRONG_AUTHENTICATION, payload: {auth: {sqrl: response.data.sqrl, sqrlUrl: response.data.sqrlUrl, nut: response.data.nut}}});
        dispatch(push(SQRL_ROUTE));
        break;
      default:
        switch (authType[response.data.authType]) {
          case 'SMS_OTP_AUTHENTICATION':
            dispatch(infoShow({info: {message: `Se ha enviado el codigo OTP via SMS al numero ${response.data.phone}`, type: 'success'}}))
            break;
          case 'MAIL_OTP_AUTHENTICATION':
            dispatch(infoShow({info: {message: `Se ha enviado el codigo OTP al correo ${response.data.mail}`, type: 'success'}}))
            break;
          case 'PHONE_OTP_AUTHENTICATION':
            dispatch(infoShow({info: {message: `Se te dictara el codigo OTP via llamada al numero ${response.data.phone}`, type: 'success'}}))
            break;
          default:
            break;
        }
        dispatch(push(OTP_CODE_ROUTE));
        break;
    }
    dispatch({type: START_STRONG_TX, payload: {signatureFiles: {txCallback: callback, txContent: content}}});	
    if(basicAuth)
      dispatch(checkStrongAuthentication({}));
  } else {
    dispatch(infoShow({info: {message: response.data.detail, type: 'error'}}));
  }
  
};
export default startStrongTx;