import React from "react";
import { connect } from "react-redux";
import signature from '../../const/signature';
import imgCertifiedSignature from '../../../src/resources/img/certifiedSignature.png'
import { usePromiseTracker } from "react-promise-tracker";
import { Typography } from "antd";
import ImageOrTextForm from "./ImageOrTextForm";
import HandwrittenForm from "./HandwrittenForm";
import OtherOptions from "./OtherOptions";

const renderType = (props, imageTypeID, image) => {
    switch (imageTypeID) {
         case 1:
            return <ImageOrTextForm finishUpImageSignature={props.getImageToShow} closeDrawer={props.closeDrawer} />
        case 2:
            return <HandwrittenForm onStartPadSigning={props.onStartPadSigning} onSubmit={props.onSubmit} padData={props.padData} hwimgid={props.signatureFiles.hwimgid} setTimerGetImageQR={props.setTimerGetImageQR} clearTimerGetImageQR={props.clearTimerGetImageQR} modalDismiss={props.modalDismiss}
            padOverwrite= {props.padOverwrite} handlePadOverwriteChange={()=>props.handlePadOverwriteChange()} closeDrawer={props.closeDrawer} />; 
        default:
            return <OtherOptions text={signature[props.language].signatureTitleTypeID[imageTypeID]} text2={signature[props.language].signatureTextTypeID[imageTypeID]} image={image} />
    }
}

const renderContent = (props, promiseInProgress) => {
    const imageTypeID = parseInt(props.defaultType)
    const image = promiseInProgress ? "" : (imageTypeID === 3 ? imgCertifiedSignature : `data:image/png;base64,${props.signatureFiles.image}`);
    return (<>
        <Typography className="text-title size15">Opciones</Typography>
        <div className="mt-15 mb-15" style={{ display: 'flex' }}>
            {props.getIconImageType(imageTypeID, 'changeColor')}
            <Typography className="text-title size15" style={{ marginLeft: 13 }}>{signature[props.language].signatureTitleTypeID1[imageTypeID]}</Typography>
        </div>
        {renderType(props, imageTypeID, image)}
    </>)

}


const NewGraphicChooser = (props) => {
    const { promiseInProgress } = usePromiseTracker();
    const imageTypes = props.imageTypes;
    if (!imageTypes || imageTypes.length < 1)
        return <div>No data...</div>;
    else
        return renderContent(props, promiseInProgress)

}

function mapStateToProps(state) {
    return {
        signatureFiles: state.signatureFiles
    }
}

export default (connect(mapStateToProps, {})(NewGraphicChooser));