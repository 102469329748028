import React from 'react';
import {Button, Modal} from "antd";

// import 'antd/lib/modal/style/css';


import signed_files from "../../const/signed_files";
import clearWorkflowDetailsInformation from "../../actions/files/clearWorkflowDetailsInformation";
import getWorkflowDetailsInformation from "../../actions/files/getWorkflowDetailsInformation";
import modalDismiss from "../../actions/modal/modalDismiss";
import PrivateRoute from "../Root/PrivateRoute";
import {connect} from "react-redux";

class WorkflowModal extends React.Component {

    componentDidMount()
    {
        this.props.clearWorkflowDetailsInformation().then(()=>this.props.getWorkflowDetailsInformation({uid:this.props.record.uid},[],[this.props.modalDismiss]));
    }

    render() {
    return (
        (<Modal open={this.props.modalType !== null} width={'70%'} {...this.props.modalProps}>
            {/*{this.props.workflowList.pendingSigners && this.props.workflowList.pendingSigners.length>0?<div><p>
                <strong>{signed_files[this.props.language].pendingSigners}</strong>
            </p>
            <p>
                {this.props.workflowList.pendingSigners.map(
                    (pendingSigner)=>
                    {
                        return <p><span>{pendingSigner.signer}</span></p>
                    })}
            </p></div>:''}
            {this.props.workflowList.finishedSigners && this.props.workflowList.finishedSigners.length>0?<div><p>
                <strong>{signed_files[this.props.language].finishedSigners}</strong>
            </p>
                <p>
                    {this.props.workflowList.finishedSigners.map(
                        (finishedSigner)=>
                        {
                            return <p><span>{finishedSigner.userName} - {finishedSigner.fecha} - {finishedSigner.status===0?signed_files[this.props.language].signed:signed_files[this.props.language].canceled} - {finishedSigner.detail} - {finishedSigner.ip}</span></p>
                        })}
                </p>
            </div>:''}*/}
            <div style={{width:'100%'}} className={'ant-table-wrapper'}>
                <div className={'ant-table-content'}>
                    <table style={{tableLayout:'auto',width:'100%'}}>
                        <colgroup/>
                        <thead className={'ant-table-thead'}><tr>
                            <th style={{textAlign:'center'}} className={'ant-table-cell mobile-show'}>{signed_files[this.props.language].wuser}</th>
                            <th style={{textAlign:'center'}} className={'ant-table-cell mobile-hide'}>{signed_files[this.props.language].wdate}</th>
                            <th style={{textAlign:'center'}} className={'ant-table-cell mobile-show'}>{signed_files[this.props.language].status}</th>
                            <th style={{textAlign:'center'}} className={'ant-table-cell mobile-hide'}>{signed_files[this.props.language].wdetail}</th>
                            <th style={{textAlign:'center'}} className={'ant-table-cell mobile-hide'}>{signed_files[this.props.language].wip}</th>
                        </tr></thead>
                        <tbody>
                        {this.props.workflowList.pendingSigners && this.props.workflowList.pendingSigners.length>0?this.props.workflowList.pendingSigners.map(
                            (pendingSigner,index)=>
                            {
                                return <tr key={'p'+ index}><td style={{textAlign:'center'}} className={'ant-table-cell mobile-show'}>{pendingSigner.signer}</td>
                                    <td style={{textAlign: 'center'}} className={'ant-table-cell mobile-show'}/>
                                    <td style={{textAlign:'center'}} className={'ant-table-cell mobile-show'}>{signed_files[this.props.language].wpending}</td>
                                </tr>;
                            }):<></>}
                        {this.props.workflowList.finishedSigners && this.props.workflowList.finishedSigners.length>0?this.props.workflowList.finishedSigners.map(
                            (finishedSigner,index)=>
                            {
                                return <tr  key={'f'+ index}><td style={{textAlign:'center'}} className={'ant-table-cell mobile-show'}>{finishedSigner.userName}</td>
                                    <td style={{textAlign:'center'}} className={'ant-table-cell mobile-hide'}>{finishedSigner.fecha}</td>
                                    <td style={{textAlign:'center'}} className={'ant-table-cell mobile-show'}>{finishedSigner.status===0?signed_files[this.props.language].signed:signed_files[this.props.language].canceled}</td>
                                    <td style={{textAlign:'center'}} className={'ant-table-cell mobile-hide'}>{finishedSigner.detail}</td>
                                    <td style={{textAlign:'center'}} className={'ant-table-cell mobile-hide'}>{finishedSigner.ip}</td></tr>;
                            }):<></>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={{padding: '20px 0'}}>
              <Button onClick={this.props.modalDismiss}
                      type={'primary light-primary rounded-sm float-right'}>{signed_files[this.props.language].close}</Button>
            </div>
        </Modal>)
    );
  }
}
const mapStateToProps = state => {
     return {
      language: state.auth.language,
      workflowList: state.workflowList
  };
};

export default PrivateRoute(connect(mapStateToProps, {modalDismiss,getWorkflowDetailsInformation,clearWorkflowDetailsInformation})(WorkflowModal));
