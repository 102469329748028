import React from "react";
import DropZone from "react-dropzone";
import ImagePreview from "./ImagePreview";
import Placeholder from './Placeholder';
import './dropzone.css';

class DropZoneField extends React.Component {

    constructor(props)
    {
        super(props);
        this.onChange = this.onChange.bind(this)
    }

    onChange = () =>
    {
        //console.log(e);
    };

  render()
  {
    return (
        <>
            <ImagePreview imageFile={this.props.formFile} moveCard={this.props.moveCard} deleteCard={this.props.deleteCard} viewFile={this.props.viewFile} />
            <div style={{background: 'var(--colorPrimaryOpacity)' , border: '1px solid var(--colorPrimary)', borderRadius: 8}}
                 className={this.props.containerClassName?this.props.containerClassName:"preview-container"}>
                <DropZone
                    className="upload-container"
                    onDrop={file=>this.props.handleOnDrop(file, this.onChange, this.props.name)} multiple={this.props.multiple}>
                    {({getRootProps, getInputProps}) =>
                    {
                        if (this.props.formFile && this.props.formFile.length > 0)
                        {
                            return (
                                <div>
                                    <Placeholder error={this.props.error} touched={this.props.touched}
                                                 label={this.props.label?this.props.label:this.props.label2} getInputProps={getInputProps}
                                                 getRootProps={getRootProps}/></div>);
                        } else {
                            return (<Placeholder error={this.props.error} touched={this.props.touched} getInputProps={getInputProps}
                                                 getRootProps={getRootProps} label={this.props.label?this.props.label:this.props.label2}/>);
                        }
                    }
                    }
                </DropZone>
            </div></>
    );
  }
}

export default DropZoneField;
