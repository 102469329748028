import React from 'react';
import {Alert, Col, Row, Typography} from "antd";
import profile from "../../../const/profile";
import fingerprintIcon from "../../../resources/img/NewIcons/profileInfo/fingerprintIcon.svg";
import infoIcon from "../../../resources/img/NewIcons/profileInfo/infoIcon.svg";
import noFingerprint from "../../../resources/img/NewIcons/profileInfo/noFingerprint.png";

class FingerPrint extends React.Component {

  state = {message: profile[this.props.language].loading_fingerprint, fingerTimer: null, wsclosed:false};  

  componentDidMount() {
	if(!this.state.wsclosed)
		this.initFingerprint();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.state.fingerTimer === null && !this.state.wsclosed)
      this.initFingerprint();
  }

  initFingerprint () {
    let fingerTimer, socket;
	socket = new WebSocket("wss://signsocket.stepover.com:62045/netcoWebSocket/wsocketendpoint");	
    socket.onopen = () => {
      console.log('OPEN');
    };
    socket.onmessage = (msg) => {
      switch(msg.data)
      {
        case "onOpen":
          fingerTimer = setInterval( () => {
            if(socket.readyState === socket.OPEN){
              socket.send('getImage');
            }
          }, 500 );
          this.setState({fingerTimer: fingerTimer, message: profile[this.props.language].found_fingerprint,wsclosed:true});
          break;
        case "noSensor":
          this.setState({message: profile[this.props.language].not_found_fingerprint});
          if(socket.readyState === socket.OPEN)
            socket.close();
          break;
        default:
          window.clearInterval(fingerTimer);
          if(socket.readyState === socket.OPEN)
            socket.close();
          this.submitFingerprint(msg.data);
          window.clearInterval(this.state.fingerTimer);
          this.setState({fingerTimer: null});
      }
    };
    socket.onerror = () => {
		socket.close();
		};
    socket.onclose = (msg)=> {      
	  if(msg.code === 1006)
        this.setState({message: profile[this.props.language].installConnector,wsclosed:true});
	  else if(msg.code === 1011)
        this.setState({message: "Error desconocido",wsclosed:true});	  
	  else
		  this.setState({wsclosed:true});	  
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.state.fingerTimer);
  }

  submitFingerprint = (fingerprint) => {
    this.props.updateUserFingerprint({base64FingerPrint: fingerprint});
  };

  renderFingerprint = () =>
  {
    return <>
      <Row align={'center'}>
        <Col xs={24} sm={6}><Alert message={'Huella Actual'}/></Col>
      </Row>
      <Row align={'center'}>
        <Col style={{textAlign:'center', maxHeight:'300px'}} xs={24} sm={6}>
          <img style={{width:'40%'}} alt={''} src={this.props.user.hasFingerprint?`data:image/png;base64, ${this.props.user.fingerPrint}`:noFingerprint}/>
        </Col>
      </Row>
    </>;
  };
  
  render() {
    return (
      <>
        <Row style={{ position: 'relative', marginTop: 2, marginBottom: 10, paddingLeft: 28, paddingBottom: 37, background: 'linear-gradient(180deg, #FFFFFF 0%, #F9F9F9 100%)', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)', borderRadius: 23 }}>
          <Col xs={24} xl={13} style={{ marginTop: 59 }}>
            <Typography className='text-title size18' >{profile[this.props.language].whyFingerprintTitle}</Typography>
            <Typography className='size15' style={{ marginTop: 13 }}>
              {profile[this.props.language].whyFingerprintDescription}
            </Typography>
          </Col>
          <Col xs={24} xl={11} style={{ marginTop: 34, textAlign: 'center' }}>
            <img alt={''} src={fingerprintIcon}/>
          </Col>
        </Row>
        <Row style={{background: 'rgba(0, 149, 255, 0.08)',border: '1px solid #3366FF',borderRadius: '8px', padding: '20px'}}>
          <div><img style={{paddingRight:'20px'}} alt={''} src={infoIcon}/><span>{this.state.message}</span></div>
        </Row>
        {this.renderFingerprint()}
      </>
    );
  }
}

export default FingerPrint;