import netcoServer from '../../apis/netcoServer';
import {GET_TOKEN_ITEMS, INFO_SHOW} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import auth from "../../const/auth";

const getItemsByToken= (order, onError) => async (dispatch,getState) => {
  const onSuccessPayload = data => {
    const tokenInfo=data.result;
    return {result:{tokenInfo}};
  };
  if(!order || !order.token)
  {
    dispatch({type: INFO_SHOW,payload: infoPayload('error', auth[getState().auth.language].requiredfld)});
    return;
  }
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/getItemsByToken  ', order));
  handleResponse(dispatch, response, GET_TOKEN_ITEMS, onSuccessPayload, null, [],[],[onError]);
};
export default getItemsByToken;
