import netcoServer from '../../apis/netcoServer';
import {INFO_SHOW, SET_LDAP_CONFIGURATION} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import getConfiguration from "./getConfiguration";
import modalDismiss from "../modal/modalDismiss";
import loadingCall from "../helpers/loadingCall";

const setLDAPConfiguration= (formValues) => async (dispatch) => {
  if(formValues.ldapPass === formValues.ldapPassConfirm) {
	formValues.ldapPassword=formValues.ldapPass;
    let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/setLDAPConfiguration', formValues));
    handleResponse(dispatch, response, SET_LDAP_CONFIGURATION, onSuccessPayload, null, [getConfiguration, modalDismiss]);
  } else {
    dispatch({type: INFO_SHOW, payload: infoPayload('error', 'Las claves deben coincidir!')})
  }
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Configuracion LDAP guardada satisfactoriamente!');
};
export default setLDAPConfiguration;