import netcoServer from '../../apis/netcoServer';
import {GET_ALL_LOG_OPERATIONS} from "../types";
import tableLoadingCall from '../helpers/tableLoadingCall';

const getAllLogOperations= () => async (dispatch) => {
  await tableLoadingCall(dispatch, () => netcoServer.newPost('/SystemService/getAllLogOperations', {}), GET_ALL_LOG_OPERATIONS, onSuccessPayload);
};

const onSuccessPayload = data => {
  return {reportList:{securityOperations: data.result.securityOperations}};
};
export default getAllLogOperations;