import React from 'react';
import { Button, Col, Dropdown, Input, Pagination, Row, Select, Table, Menu } from "antd";

// import 'antd/lib/table/style/css';


// import 'antd/lib/row/style/css';


// import 'antd/lib/col/style/css';


// import 'antd/lib/popover/style/css';


// import 'antd/lib/button/style/css';


import getAllTimestampServers from '../../../actions/admin/getAllTimestampServers';
import createTimestampServer from '../../../actions/admin/createTimestampServer';
import deleteTimestampServer from '../../../actions/admin/deleteTimestampServer';
import updateTimestampServer from '../../../actions/admin/updateTimestampServer';
import getAllProfiles from '../../../actions/admin/getAllProfiles';
import createProfile from '../../../actions/admin/createProfile';
import deleteProfile from '../../../actions/admin/deleteProfile';
import updateProfile from '../../../actions/admin/updateProfile';
import getAllWebserviceUsers from '../../../actions/admin/getAllWebserviceUsers';
import createWebserviceUser from '../../../actions/admin/createWebserviceUser';
import deleteWebserviceUser from '../../../actions/admin/deleteWebserviceUser';
import updateWebserviceUser from '../../../actions/admin/updateWebserviceUser';
import getAllUsersInfo from '../../../actions/admin/getAllUsersInfo';
import createUser from '../../../actions/admin/createUser';
import deleteUser from '../../../actions/admin/deleteUser';
import updateUser from '../../../actions/admin/updateUser';
import updateMetadataOptions from '../../../actions/admin/updateMetadataOptions';
import addMetadataOptions from '../../../actions/admin/addMetadataOption';
import modalShow from '../../../actions/modal/modalShow';
import modalDismiss from "../../../actions/modal/modalDismiss";
import getAllMetadata from '../../../actions/admin/getAllMetadata';
import getMetadataOptions from '../../../actions/admin/getMetadataOptions';
import {
  ADMIN_PROFILES_MODAL,
  ADMIN_SERVERS_MODAL,
  ADMIN_USERS_MODAL,
  ADMIN_WEBSERVICES_MODAL, DELETE_USER_MODAL,
  ADMIN_METADATA_MODAL,
  ADMIN_CREATE_METADATA_OPT_MODAL
} from '../../Feedback/Modal/types';
import { connect } from 'react-redux'
import admin from "../../../const/admin";
import general from "../../../const/general";
import profile from "../../../const/profile";
import TagLabel from '../../Helpers/TagLabel';
import DropdownList from '../../Helpers/DropdownList';
import actionsIcon from '../../../resources/img/NewIcons/management/actions.svg'
import pArrow from '../../../resources/img/pArrow.svg'
import nArrow from '../../../resources/img/nArrow.svg'



class AdminTables extends React.Component {

  state = {
    pageSize: 10,
    page: 1,
    sidx: '',
    sord: '',
    filter: false,
    searchString: '',
    metadataID: ''
  };

  componentDidMount() {
    this.setInitialFilterValues(this.props.reportList.kind);
  }

  handlePageChange = (page) => {
    this.setState({ page: page }, () => this.handleChange(this.props.reportList.kind));
  };

  handlePageSizeChange = (value) => {
    this.setState({ pageSize: parseInt(value), page: 1 }, () => this.handleChange(this.props.reportList.kind));
  };

  handleSearchChange = (e) => {
    this.setState({ searchString: e.target.value });
  };

  handleSort = (pagination, filters, sorter) => {
    if (sorter.field !== undefined) {
      this.setState({ sidx: sorter.field, sord: sorter.order }, () => this.handleChange(this.props.reportList.kind));
    } else {
      this.setState({ sidx: '', sord: '' }, () => this.handleChange(this.props.reportList.kind));
    }
  };

  handleSimpleSort = () => {
    switch (this.state.sord) {
      case 'ascend':
        this.setState({ sord: 'descend' }, () => this.handleChange(this.props.reportList.kind));
        break;
      case 'descend':
        this.setState({ sord: '' }, () => this.handleChange(this.props.reportList.kind));
        break;
      default:
        this.setState({ sord: 'ascend' }, () => this.handleChange(this.props.reportList.kind));
        break;
    }
  };

  handleFilter = (searchString) => {
    this.setState({ page: 1, filter: true, searchString: searchString }, () => this.handleChange(this.props.reportList.kind));
  };

  handleClean = () => {
    this.setState({ page: 1, filter: false, searchString: '' }, () => this.handleChange(this.props.reportList.kind));
  };

  handleReportChange = (value) => {
    this.setInitialFilterValues(value);
  };

  handleCreate = () => {
    switch (this.props.reportList.kind) {
      case 'servers':
        this.props.modalShow({
          modalType: ADMIN_SERVERS_MODAL,
          modalProps: { footer: null, title: admin[this.props.language].create_server_modal },
          componentProps: { btn: general[this.props.language].create, entryId: null, onSubmit: this.props.createTimestampServer }
        });
        break;
      case 'profiles':
        this.props.modalShow({
          modalType: ADMIN_PROFILES_MODAL,
          modalProps: { footer: null, title: admin[this.props.language].create_profile_modal },
          componentProps: { btn: general[this.props.language].create, entryId: null, onSubmit: this.props.createProfile }
        });
        break;
      case 'webservices':
        this.props.modalShow({
          modalType: ADMIN_WEBSERVICES_MODAL,
          modalProps: { footer: null, title: admin[this.props.language].create_ip_modal },
          componentProps: { btn: general[this.props.language].create, disabledFields: false, entryId: null, onSubmit: this.props.createWebserviceUser }
        });
        break;
      case 'users':
        this.props.modalShow({
          modalType: ADMIN_USERS_MODAL,
          modalProps: { footer: null, title: admin[this.props.language].create_user_modal },
          componentProps: { btn: general[this.props.language].create, update: false, onSubmit: this.createUser, entryId: null, }
        });
        break;
      default:
        return null;
    }
  };

  afterDelete = () => {
    this.props.modalDismiss();
    this.handleChange('users');
  };

  handleDelete = id => {
    switch (this.props.reportList.kind) {
      case 'servers':
        this.props.deleteTimestampServer(id);
        break;
      case 'profiles':
        this.props.deleteProfile(id);
        break;
      case 'webservices':
        this.props.deleteWebserviceUser(id);
        break;
      case 'users':
        const msg = admin[this.props.language].confirmDeleteUser.replace('{0}', id);
        this.props.modalShow({
          modalType: DELETE_USER_MODAL,
          modalProps: { footer: null, title: general[this.props.language].delete },
          componentProps: {
            language: this.props.language, info: msg,
            btn: general[this.props.language].edit, onCancel: this.props.modalDismiss, onSubmit: (formValues) => this.props.deleteUser(id, this.afterDelete, formValues.delReason)
          }
        });
        break;
      default:
        return null;
    }
  };

  handleUpdate = record => {
    switch (this.props.reportList.kind) {
      case 'servers':
        this.props.modalShow({
          modalType: ADMIN_SERVERS_MODAL,
          modalProps: { footer: null, title: admin[this.props.language].edit_server_modal },
          componentProps: { btn: general[this.props.language].edit, entryId: record.id, onSubmit: this.updateTimestampServer }
        });
        break;
      case 'profiles':
        this.props.modalShow({
          modalType: ADMIN_PROFILES_MODAL,
          modalProps: { footer: null, title: admin[this.props.language].edit_profile_modal },
          componentProps: { btn: general[this.props.language].edit, entryId: record.id, onSubmit: this.updateProfile }
        });
        break;
      case 'webservices':
        this.props.modalShow({
          modalType: ADMIN_WEBSERVICES_MODAL,
          modalProps: { footer: null, title: admin[this.props.language].edit_ip_modal },
          componentProps: { btn: general[this.props.language].edit, disabledFields: true, entryId: record.ip, onSubmit: this.updateWebserviceUser }
        });
        break;
      case 'users':
        this.props.modalShow({
          modalType: ADMIN_USERS_MODAL,
          modalProps: { footer: null, title: admin[this.props.language].edit_user_modal },
          componentProps: { btn: general[this.props.language].edit, entryId: record.userName, onSubmit: this.updateUser, update: true }
        });
        break;
      case 'metaData':
        this.props.modalShow({
          modalType: ADMIN_METADATA_MODAL,
          modalProps: { footer: null, title: "Editar Metadato" },
          componentProps: { btn: general[this.props.language].edit, metaDataOpt: this.props.metaDataList.options, metaID: record.id, onSubmit: this.updateMetadataOptions, update: true }
        });
        break;
      default:
        return null;
    }
  };

  handleCreateAction = record => {
    switch (this.props.reportList.kind) {
      case 'metaData':
        this.props.modalShow({
          modalType: ADMIN_CREATE_METADATA_OPT_MODAL,
          modalProps: { footer: null, title: general[this.props.language].addMetadataTitle + record.name },
          componentProps: { btn: general[this.props.language].create,  metadataID: record.id,onSubmit: this.createMetadataOptions }
        });
        break;
      default:
        return null;
    }
  };


  createUser = formValues => {
    this.props.createUser(formValues, () => this.handleChange('users'));
  };

  updateUser = formValues => {
    formValues.language = this.props.language;
    this.props.updateUser(formValues, () => this.handleChange('users'));
  };

  updateMetadataOptions = (formValues) => {
    this.props.updateMetadataOptions(formValues, () => this.handleChange('metaData'));
  };

  createMetadataOptions = (formValues) => {
    this.props.addMetadataOptions(formValues, () => this.handleChange('metaData'));
  };

  updateWebserviceUser = (formValues, file) => {
    this.props.updateWebserviceUser(formValues, file, () => this.handleChange('webservices'));
  };

  updateProfile = formValues => {
    this.props.updateProfile(formValues, () => this.handleChange('profiles'));
  };

  updateTimestampServer = (formValues) => {
    this.props.updateTimestampServer(formValues, () => this.handleChange('servers'));
  };

  setInitialFilterValues = (value) => {
    switch (value) {
      case 'servers':
        this.setState({ page: 1, sord: '', sidx: '', filter: false }, () => this.handleChange(value));
        break;
      case 'profiles':
        this.setState({ page: 1, sord: '', sidx: '', filter: false }, () => this.handleChange(value));
        break;
      case 'webservices':
        this.setState({ page: 1, sord: 'descend', sidx: 'lastLogin', filter: false }, () => this.handleChange(value));
        break;
      case 'users':
        this.setState({ page: 1, sord: 'ascend', sidx: 'userName', filter: false }, () => this.handleChange(value));
        break;
      case 'metaData':
        this.setState({}, () => this.handleChange(value));
        break;
      default:
        return null;
    }
  };

  handleChange = value => {
    switch (value) {
      case 'servers':
        this.props.getAllTimestampServers(this.state.page, this.state.pageSize, this.state.sidx, this.state.sord);
        break;
      case 'profiles':
        this.props.getAllProfiles(this.state.page, this.state.pageSize, this.state.sord);
        break;
      case 'webservices':
        this.props.getAllWebserviceUsers(this.state.page, this.state.pageSize, this.state.sord);
        break;
      case 'users':
        this.props.getAllUsersInfo(this.state.searchString, this.state.page, this.state.pageSize, this.state.sidx, this.state.sord);
        break;
      case 'metaData':
        this.props.getAllMetadata([(data) => this.props.getMetadataOptions(data.reportList.entries)]);
        break;
      default:
        return null;
    }
  };

  booleanTranslation = (option) => {
    return option === undefined ? '' : option ? 'Si' : 'No';
  };

  renderActions = (record) => {
    if (record.type !== "text" && record.type !== "boolean") {
      return <Dropdown dropdownRender={()=>this.renderOptions(record)}>
        <button className={'unstyled-button'} onClick={e => e.preventDefault()}>
          <img alt={''} src={actionsIcon} />
        </button>
      </Dropdown>
    }
  }

  columns = () => {
    switch (this.props.reportList.kind) {
      case 'servers':
        return [{
          title: admin[this.props.language].id_column,
          dataIndex: 'id',
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].url_column,
          dataIndex: 'url',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.state.sidx === 'url' ? this.state.sord : false
        }, {
          title: admin[this.props.language].short_name_column,
          dataIndex: 'friendlyName',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.state.sidx === 'friendlyName' ? this.state.sord : false
        }, {
          title: admin[this.props.language].oid_column,
          dataIndex: 'policyOID',
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].user_column,
          dataIndex: 'userName',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.state.sidx === 'userName' ? this.state.sord : false
        }, {
          title: general[this.props.language].actions,
          key: 'action',
          render: (text, record) => (
            this.renderActions(record)
          ),
          className: 'mobile-show'
        }];
      case 'profiles':
        return [{
          title: admin[this.props.language].id_column,
          dataIndex: 'id',
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].profile_column,
          dataIndex: 'description',
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].authentication_column,
          dataIndex: 'authTypeDescription',
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].min_psw_column,
          dataIndex: 'passMinLength',
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].max_psw_column,
          dataIndex: 'passMaxLength',
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].up_down_column,
          dataIndex: 'requireUPLOWLT',
          render: (text, record) => this.booleanTranslation(record.requireUPLOWLT),
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].symbol_column,
          dataIndex: 'requireSPECIALC',
          render: (text, record) => this.booleanTranslation(record.requireSPECIALC),
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].admin_column,
          dataIndex: 'isAdmin',
          render: (text, record) => this.booleanTranslation(record.isAdmin),
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].tx_column,
          dataIndex: 'isTxAuth',
          render: (text, record) => this.booleanTranslation(record.isTxAuth),
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].blocked_column,
          dataIndex: 'failedAttempts',
          className: 'mobile-show'
        }, {
          title: general[this.props.language].actions,
          key: 'action',
          render: (text, record) => (
            this.renderActions(record)
          ),
          className: 'mobile-show'
        }];
      case 'webservices':
        return [{
          title: admin[this.props.language].ip_column,
          dataIndex: 'ip',
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].name_column,
          dataIndex: 'certSubject',
          className: 'mobile-show',
          render: (texto, record) => (
            <span>
              <a href={'data:application/x-x509-ca-cert;base64,' + record.cert}>{record.certSubject}</a>
            </span>
          )
        }, {
          title: admin[this.props.language].last_entrance_column,
          dataIndex: 'lastLogin',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.state.sidx === 'lastLogin' ? this.state.sord : false
        }, {
          title: general[this.props.language].actions,
          key: 'action',
          render: (text, record) => (
            this.renderActions(record)
          ),
          className: 'mobile-show'
        }];
      case 'users':
        return [{
          title: admin[this.props.language].user_column,
          dataIndex: 'userName',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.state.sidx === 'userName' ? this.state.sord : false
        }, {
          title: admin[this.props.language].profile_column,
          dataIndex: 'profileDescription',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.state.sidx === 'profileDescription' ? this.state.sord : false
        }, {
          title: admin[this.props.language].state_column,
          dataIndex: 'userStatus',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.state.sidx === 'userStatus' ? this.state.sord : false,
          render: (text) => (<TagLabel type={text === 'Activo' ? 'Info' : 'Danger'} text={text} />)
        }, {
          title: admin[this.props.language].operations_column,
          dataIndex: 'maxOper',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: false,
          sortOrder: this.state.sidx === 'maxOper' ? this.state.sord : false,
          render: (texto) => texto === -1 ? profile[this.props.language].unlimited : texto
        },
        {
          title: admin[this.props.language].used_column,
          dataIndex: 'usedOper',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: false,
          sortOrder: this.state.sidx === 'usedOper' ? this.state.sord : false
        },
        {
          title: admin[this.props.language].av_column,
          dataIndex: 'avOper',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: false,
          sortOrder: this.state.sidx === 'avOper' ? this.state.sord : false
        },
        {
          title: admin[this.props.language].last_login_column,
          dataIndex: 'lastLogin',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.state.sidx === 'lastLogin' ? this.state.sord : false
        }, {
          title: admin[this.props.language].cert_exp_column,
          dataIndex: 'certExpiration',
          className: 'mobile-show',
          sorter: false
        }, {
          key: 'action',
          render: (text, record) => (
            this.renderActions(record)
          ),
          className: 'mobile-show'
        }];
      case 'metaData':
        return [{
          title: admin[this.props.language].id_column,
          dataIndex: 'id',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: (a, b) => a.id - b.id,
          sortOrder: 'ascend'
        }, {
          title: admin[this.props.language].name_column,
          dataIndex: 'name',
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].mandatory,
          dataIndex: 'mandatory',
          render: (text, record) => this.booleanTranslation(record.mandatory),
          className: 'mobile-show',
          with: 'auto'
        }, {
          title: admin[this.props.language].Type,
          dataIndex: 'type',
          className: 'mobile-show'
        }, {
          title: admin[this.props.language].values,
          className: 'mobile-show',
          render: (text, record) => {
            const valores = this.props.metaDataList.options
              .filter(objeto => objeto.metadataID === record.id)
              .map(objeto => objeto.value);
            return (
              valores.map(valor => (
                <TagLabel key={valor} type={'Info'} text={valor} inTable={true} />
              ))
            );
          },

        }, {
          key: 'action',
          render: (text, record) => (
            this.renderActions(record)
          ),
          className: 'mobile-show'
        }];
      default:
        return null;
    }
  };

  handleItemClick = (evt, record) => {
    switch (evt.key) {
      case general[this.props.language].edit:
        this.handleUpdate(record);
        break;
      case general[this.props.language].create:
        this.handleCreateAction(record);
        break;
      case general[this.props.language].delete:
        let id = record.id;
        switch (this.props.reportList.kind) {
          case 'users':
            id = record.userName;
            break;
          case 'webservices':
            id = record.ip;
            break;
          default:
        }
        this.handleDelete(id);
        break;
      default:
        break;
    }
  }

  renderOptions = (record) => {
    let items;
    if (record.type === "select" || record.type === "checkbox") {
      items = [{ key: general[this.props.language].edit, label: general[this.props.language].editMetadata },
      { key: general[this.props.language].create, label: general[this.props.language].addMetadata }];
    } else {
      items = [{ key: general[this.props.language].edit, label: general[this.props.language].edit },
      { key: general[this.props.language].delete, label: general[this.props.language].delete }];
    }
    return (
      <Menu items={items} onClick={(evt) => this.handleItemClick(evt, record)} />
    );
  };

  renderHeader = () => {
    return (
      <Row style={{ columnGap: 16, rowGap: 10 }} className='mb-30 mt-30'>
        <Col style={{ paddingLeft: 0 }} xs={24} md={7}>
          <DropdownList menu={admin[this.props.language].tablesList} defaultValue={this.props.reportList.kind}
            onChange={this.handleReportChange} width={'100%'} />
        </Col>
        <Col xs={24} md={7}>
          {this.renderSearch()}
        </Col>
        <Col xs={11} md={4}>
          {this.renderClean()}
        </Col>
        {this.props.reportList.kind !== 'metaData' ? <Col xs={11} md={4}>
          <Button type={'ghost'} className={'float-right'} style={{ width: 83 }} htmlType={'button'} onClick={this.handleCreate}>
            {general[this.props.language].create.toUpperCase()}
          </Button>
        </Col> : <></>}
      </Row>
    );
  };

  renderClean() {
    if (this.state.filter)
      return (
        <Button type={"primary"} htmlType={'button'} onClick={this.handleClean}>
          {general[this.props.language].clean}
        </Button>
      );
  }

  renderSearch() {
    if (this.props.reportList.kind === 'users') {
      return (
        <Input.Search
          className={'w-100'}
          placeholder={admin[this.props.language].user_starts}
          onSearch={this.handleFilter}
          onChange={this.handleSearchChange}
          value={this.state.searchString}
          enterButton
        />
      );
    } else if (this.props.reportList.kind === 'profiles' || this.props.reportList.kind === 'webservices') {
      return "";
    }
  }

  paginationItemRender = (_, type, originalElement) => {
    if (type === 'prev')
      return <img alt={''} src={pArrow} />;
    if (type === 'next')
      return <img alt={''} src={nArrow} />;
    return originalElement;
  };

  render() {
    return (
      <div>
        <Table
          columns={this.columns()}
          dataSource={this.props.reportList.entries}
          size={'small'}
          pagination={false}
          title={this.renderHeader}
          rowKey={this.props.reportList.kind === 'webservices' ? 'ip' : this.props.reportList.kind === 'users' ? 'userName' : 'id'}
          loading={this.props.tableLoading}
          onChange={this.handleSort}
          scroll={{ x: 1300 }}
        />
        <Row className={'p-20 size12 color-black'}>
          <Col xs={24} md={7}>
            <Select value={this.state.pageSize} onChange={this.handlePageSizeChange}>
              <Select.Option value="10">10</Select.Option>
              <Select.Option value="20">20</Select.Option>
              <Select.Option value="30">30</Select.Option>
            </Select>
            <span style={{ marginLeft: 12 }}>{general[this.props.language].results_per_page} </span>
          </Col>
          <Col xs={24} md={10} className='text-center'>
            <Pagination size="small" pageSize={this.state.pageSize} current={this.state.page}
              onChange={this.handlePageChange} total={this.props.reportList.total}
              showSizeChanger={false} itemRender={this.paginationItemRender} />
          </Col>
          <Col xs={24} md={7} style={{ textAlign: 'right', paddingBottom: '10px' }} >
            <span>{general[this.props.language].total_elements.replace('$0', this.props.reportList.total)}</span>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.auth.language,
    tableLoading: state.loading.tableLoading,
    reportList: state.reportList,
    metaDataList: state.metaDataList
  };
};

export default connect(
  mapStateToProps,
  {
    getAllTimestampServers,
    createTimestampServer,
    updateTimestampServer,
    deleteTimestampServer,
    getAllProfiles,
    getAllWebserviceUsers,
    modalShow,
    createProfile,
    deleteProfile,
    updateProfile,
    createWebserviceUser,
    deleteWebserviceUser,
    updateWebserviceUser,
    getAllUsersInfo,
    createUser,
    deleteUser,
    updateUser,
    modalDismiss,
    getAllMetadata,
    getMetadataOptions,
    updateMetadataOptions,
    addMetadataOptions
  })(AdminTables);
