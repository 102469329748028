import netcoServer from '../../apis/netcoServer';
import {BASIC_REGISTRATION, INFO_SHOW} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import checkBasicAuthentication from "./checkBasicAuthentication";
import _ from 'lodash';
import loadingCall from "../helpers/loadingCall";

const basicRegistration= (formValues) => async (dispatch) => {
  if(formValues.password === formValues.password_confirmation) {
    let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/basicRegistration', _.omit(formValues, ['redirect','uniqueID'])));
    handleResponse(dispatch, response, BASIC_REGISTRATION, onSuccessPayload, '/', [() => checkBasicAuthentication(_.pick(formValues, ['userName', 'password','redirect','uniqueID','option','referenceNumber','authCode','createUser']))]);
  } else {
    dispatch({type: INFO_SHOW, payload: infoPayload('error', 'Las claves deben coincidir!')})
  }
};
const onSuccessPayload = () => {
  return infoPayload('success', 'Registro completado satisfactoriamente!');
};
export default basicRegistration;