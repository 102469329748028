import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Modal } from "antd";

// import 'antd/lib/modal/style/css';


import {Field, reduxForm} from "redux-form";
import {CreatePasswordField} from "../../Helpers/Input/InputCreator";
import {required} from "../../Helpers/Input/validations";
import general from "../../../const/general";
import padLock from "../../../resources/img/padLock.svg";

class PdfPasswordModal extends React.Component {

  render() {
      return (
        (<Modal
            open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
          <Form onFinish={this.props.handleSubmit(this.props.onSubmit)}>
              <Form.Item>
                  <div style={{marginTop:'10px'}} className={'mb-10'}>{general[this.props.language].password}</div>
              <Field
                     name="password"
                     size={'large'}
                     iconRender={() => { return (<img alt='' src={padLock} />)}}
                     className={'mb-10'}
                     component={CreatePasswordField}
                     validate={[required]}
                     hasFeedback/>
            </Form.Item>
            <Button htmlType={'submit'} type={'primary'}>{general[this.props.language].send}</Button>
          </Form>
        </Modal>)
      );
  }


}

export default reduxForm({form: 'pdf_password_modal_form'})(PdfPasswordModal);
