import React from 'react';
import PublicLayout from "../../Layouts/Public";
import {connect} from "react-redux";
import checkStrongAuthentication from '../../../actions/auth/checkStrongAuthentication'
import autho from "../../../const/auth";
import logoutUser from "../../../actions/auth/logoutUser";

class Sqrl extends React.Component {

  state = {sqrlTimer: null};

  componentDidMount() {
    this.submitSqrl();
    let sqrlTimer = setInterval( () => {
      this.submitSqrl();
    }, 300000 );
    this.setState({sqrlTimer: sqrlTimer})
  }

  componentWillUnmount() {
    window.clearInterval(this.state.sqrlTimer);
  }

  submitSqrl = () => {
    this.props.checkStrongAuthentication({nut: this.props.auth.nut,params:this.props.auth.params});
  };
  
  render() {
    return (
      <PublicLayout bodyStyle={{textAlign:'center'}} title={autho[this.props.auth.language].scansqrllbl}>
        <a href={this.props.auth.sqrlUrl}>
          <img alt={''} src={`data:image/png;base64, ${this.props.auth.sqrl}`} />
        </a>
        <p className={'link'} onClick={this.props.logoutUser}>{autho[this.props.auth.language].cancel}</p>
      </PublicLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps, {checkStrongAuthentication,logoutUser})(Sqrl);