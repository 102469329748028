import {routerMiddleware} from "connected-react-router";
import {thunk} from "redux-thunk";
import history from "../../history";
import { configureStore as newStore } from '@reduxjs/toolkit'
import rootReducer from "../../reducers";

export default function configureStore() {
  return newStore({
    middleware: ()=>[thunk, routerMiddleware(history)],
     //middleware: (getDefaultMiddleware) =>
         //getDefaultMiddleware().concat(routerMiddleware(history)),
    reducer: rootReducer(history)
  });
}