import netcoServer from '../../apis/netcoServer';
import {UPDATE_PDF_FEATURES} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import getUserInfo from "./getUserInfo";
import loadingCall from "../helpers/loadingCall";
import modalDismiss from "../modal/modalDismiss";
import getValidOperations from "../signature/getValidOperations";

const updatePDFFeatures= (formValues) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/updatePDFFeatures', formValues));
  handleResponse(dispatch, response, UPDATE_PDF_FEATURES, onSuccessPayload, null, [getUserInfo, modalDismiss, getValidOperations]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Opciones guardadas satisfactoriamente!');
};
export default updatePDFFeatures;