import React from 'react';
import './PrivateHeader.css';
import { connect } from "react-redux";
import PrivateHeaderMenu from "./PrivateHeaderMenu";
import { push } from 'connected-react-router';
import changeLanguage from "../../../../actions/auth/changeLanguage";
import getImageTypesList from "../../../../actions/profile/getImageTypesList";
import getTimestampsInfo from "../../../../actions/profile/getTimestampsInfo";
import getValidOperations from "../../../../actions/signature/getValidOperations";
import getRegistrationOptions from "../../../../actions/auth/getRegistrationOptions"
import getUserInfo from "../../../../actions/profile/getUserInfo";
import authorization from "../../../../authorization";
import logOut from "../../../../actions/auth/logoutUser";
import showOptionsSignature from '../../../../actions/info/showOptionsSignature';
import showMenu from '../../../../actions/info/showMenu';
import showSiderDrawer from "../../../../actions/info/showSiderDrawer";
import showAppOptions from "../../../../actions/info/showAppOptions";

class NewPrivateHeader extends React.Component {

  componentDidMount() {
    if (!this.props.requiresCert) {
      this.props.getUserInfo();
      this.props.getValidOperations();
      this.props.getImageTypesList();
      this.props.getTimestampsInfo();
      this.props.getRegistrationOptions();
    }
  }

  render() {
    if (this.props.requiresCert || this.props.hideHeader)
      return <></>;
    return (
      <nav>
        <PrivateHeaderMenu changeLanguage={this.props.changeLanguage} language={this.props.language} userName={this.props.returnUserName}
                           cn={this.props.cn} push={this.props.push} pathname={this.props.pathname} siderMenu={this.props.siderMenu}
                           caDescription={this.props.caDescription} caID={this.props.caID} logOut={this.props.logOut}
                           showOptionsSignature={this.props.showOptionsSignature} showMenu={this.props.showMenu}
                           showAppOptions={this.props.showAppOptions}
                           showSiderDrawer={this.props.showSiderDrawer} pendingTotal={this.props.localTotal}
                           visibleMenu={this.props.visibleMenu} dimApp={this.props.dimApp} hideAllMenu={this.props.hideAllMenu}
        />
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    pathname: state.router.location.pathname,
    requiresCert: state.auth.requiresCert,
    returnUserName: state.auth.userName,
    language: state.auth.language,
    cn: state.user.cn,
    caID: state.user.caID,
    caDescription: state.user.caDescription,
    visibleMenu: state.locate.visibleMenu,
    visibleSiderDrawer: state.locate.visibleSiderDrawer,
    dimApp: state.locate.dimensionsApp,
    localTotal: state.pendingFileList.localTotal,
    hideAllMenu: state.locate.hideAllMenu,
    hideHeader: state.locate.hideHeader
  }
}

export default authorization(connect(mapStateToProps, {
  push, changeLanguage, getImageTypesList, getTimestampsInfo, getValidOperations, getUserInfo, showOptionsSignature, logOut, showMenu,
  showSiderDrawer, showAppOptions, getRegistrationOptions
})(NewPrivateHeader));
