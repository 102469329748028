import React from 'react';
import {connect} from 'react-redux';
import {Button, Card, Input, Table, Row, Select, Col, Tooltip} from "antd";
import TitleAndInfo from '../Helpers/TitleAndInfo';

// import 'antd/lib/card/style/css';


// import 'antd/lib/row/style/css';


// import 'antd/lib/col/style/css';


// import 'antd/lib/alert/style/css';


import PrivateRoute from "../Root/PrivateRoute";
import admin from "../../const/admin";
import {getQueryParams} from "../Helpers/utils";
import auth from "../../const/auth";
import {CLEAR_ORDER, INFO_SHOW} from "../../actions/types";
import general from "../../const/general";
import {CONFIRM_MODAL} from "../Feedback/Modal/types";
import modalShow from "../../actions/modal/modalShow";
import modalDismiss from "../../actions/modal/modalDismiss";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import getItemsByToken from "../../actions/admin/getItemsByToken";
import activateToken from "../../actions/admin/activateToken";
import infoPayload from "../../actions/helpers/payloadHandlers/infoPayload";
import redirect from "../../actions/auth/redirect";
import {REDIRECT_DOCS_ROUTE} from "../Root/routes";
const { Column } = Table;

class Activate extends React.Component {
  
  state = {token:'',assigned:[], currentID:'', currentQuantity:'',currentUser:'',currentKey:1};


  componentDidMount()
  {
      if(this.props.token)
          this.setState({token:this.props.token},this.getItems);
  }

  getItems=()=>
  {
      this.props.getItemsByToken({token:this.state.token},()=>{},false);
  };

  assign=()=>
  {
      if(!this.props.tokenInfo)
          return "";
      const userName=this.state.currentUser;
      if(!userName)
          return "";
      const newItems=this.props.tokenInfo.items.filter(item=>item["product_id"]===this.state.currentID);
      if(newItems && newItems.length>0)
      {
          const quantity=this.state.currentQuantity, currentKey=this.state.currentKey;
          const newProduct={id:newItems[0]["product_id"],name:newItems[0].name,quantity,userName,currentKey:currentKey}
          this.setState({assigned:[...this.state.assigned,newProduct],currentID:'',currentQuantity:'',currentUser:'',currentKey:currentKey+1});
      }
  };

  remove=(record)=>
  {
      this.setState({assigned:this.state.assigned.filter(item=>item.currentKey!==record.currentKey)});
  };

  getAvailableProducts=()=>
  {
      const products=[];
      if(!this.props.tokenInfo)
          return products;
      const items=this.props.tokenInfo.items;
      if(items)
      {
          for(let i=0;i<items.length;i++)
          {
              const id=items[i]["product_id"];
              if(this.getMaxQuantity(id)>0)
                  products.push(<Select.Option key={id} value={id}>{items[i].name}</Select.Option>);
          }
      }
      return products;
  };

  getOptions=(index)=>
  {
      const options=[];
      if(index<=0)
          return options;
      for(let i=1;i<=index;i++)
          options.push(<Select.Option value={i} key={i}>{i}</Select.Option>);
      return options;
  };

  getMaxQuantity=(id)=>
  {
      if(!id)
          id=this.state.currentID;
      if(!id || !this.props.tokenInfo)
          return 0;
      const items=this.props.tokenInfo.items.filter(item=>item["product_id"]===id);
      let availableQuantity=0;
      for(let i=0;i<items.length;i++)
        availableQuantity=availableQuantity + items[i].quantity;
      const assignedItems=this.state.assigned.filter(item=>item.id===id);
      let usedQuantity=0;
      for(let i=0;i<assignedItems.length;i++)
          usedQuantity=usedQuantity + assignedItems[i].quantity;
      return availableQuantity-usedQuantity;
  };

  updateProduct=(id)=>
  {
      this.setState({currentID:id, currentQuantity:this.getMaxQuantity(id),currentUser:''});
  };

  renderAssignedTable=(dataSource,showActions=true, showDate=true)=>
  {
      return dataSource.length>0 && <Row>
          <Table scroll={{ x: true}} bordered title={() => <h3>{admin[this.props.language].assignedTitle}</h3>} dataSource={dataSource}
                 pagination={false} rowKey={'currentKey'} size={"small"}>
              <Column className={'mobile-show'} title={admin[this.props.language].buy_product} dataIndex="name"/>
              <Column className={'mobile-show'} title={admin[this.props.language].buy_quantity} dataIndex="quantity"/>
              <Column className={'mobile-show'} title={admin[this.props.language].buy_topUpCreatedFor} dataIndex="userName"/>
              {showDate && <Column className={'mobile-hide'} title={admin[this.props.language].buy_usedDate} dataIndex="activationDate"
                      render={(text)=>text?new Date(text).toLocaleString():''}/>}
              {showActions && <Column className={'mobile-show'} dataIndex="id" render={(text, record) =>
                  <Button icon={<MinusOutlined/>} type={'danger'} htmlType={'button'} onClick={() => this.remove(record)}/>}/>}
          </Table></Row>;
  };

  showOK=()=>
  {
      this.props.dispatch({type: CLEAR_ORDER});
      this.props.dispatch({type: INFO_SHOW,payload: infoPayload('success', admin[this.props.language].processok)});
      this.props.redirect(REDIRECT_DOCS_ROUTE);
  };

  activateToken=()=>
  {
      this.props.modalShow({
          modalType: CONFIRM_MODAL,
          modalProps: {footer: null, title:admin[this.props.language].buy_activatetitle},
          componentProps: {
              language: this.props.language,info: admin[this.props.language].confirmActivate,
              renderComponent: ()=>this.renderAssignedTable(this.state.assigned,false,false),
              btn: general[this.props.language].edit, onCancel:this.props.modalDismiss, onSubmit: ()=>{
                  this.props.modalDismiss();
                  this.props.activateToken({token:this.state.token,items:this.state.assigned},this.showOK);
              }}
      });
  }

    renderTokenInformation= () =>
    {
        if(this.props.tokenInfo)
            return "";
        const B = (props) => <div style={{display: 'inline',fontWeight: 'bold'}}>{props.children}</div>;
        let msg=<div style={{color:'black',display:'inline'}} className={'mb-10 text-center info'}>{admin[this.props.language].buy_topUpInfo}<B>{admin[this.props.language].buy_topUpInfoValidate}</B></div>;
        return (<>
                <TitleAndInfo info={msg}/>
            <div style={{textAlign:'center'}}>
                <div><Input style={{ width: '80%', textAlign:'center' }} value={this.state.token} onChange={(event)=>this.setState({token:event.target.value})} /></div>
                <div><Button style={{width:'100px',marginTop:'10px'}} type="primary light-primary rounded-sm" onClick={this.getItems}>
                    {admin[this.props.language].buy_topUpValidate}</Button></div>
            </div></>
        );
    };

  renderPendingTable=(products)=>
  {
      return this.props.tokenInfo.assignedItems.length===0 && products.length>0 &&<Row>
          <Table bordered title={() => <h3>{admin[this.props.language].pendingTitle}</h3>} size={"small"} dataSource={[{id:1}]}
                 pagination={false} rowKey={'id'} scroll={{ x: true}}>
              <Column width={'50%'} className={'mobile-show'} title={admin[this.props.language].buy_product} dataIndex="name"
                      render={() => <Select style={{width:'100%'}} value={this.state.currentID}
                                            onChange={this.updateProduct}>
                          {products}
                      </Select>}/>
              <Column className={'mobile-show'} title={admin[this.props.language].buy_quantity}
                      render={()=><Select
                          value={this.state.currentQuantity}
                          onChange={(value) => this.setState({currentQuantity: value})}>
                          {this.getOptions(this.getMaxQuantity())}
                      </Select>}/>
                  <Column className={'mobile-show'} title={admin[this.props.language].buy_topUpCreatedFor} dataIndex="quantity"
                          render={()=><Tooltip title={admin[this.props.language].buy_topUpCreatedForTooltip}>
                              <Input maxLength={50} value={this.state.currentUser}
                                     onChange={(e) => this.setState({currentUser: e.target.value})}/></Tooltip>}/>
              <Column className={'mobile-show'} render={()=>
                  <Button icon={<Tooltip title={admin[this.props.language].addLabel}><PlusOutlined/></Tooltip>}
                          type={'primary sign-btn'} htmlType={'button'} onClick={this.assign}/>}/>
          </Table>
      </Row>;
  };


    renderOrderInformation = () =>
  {
      const tokenInfo=this.props.tokenInfo;
      if(tokenInfo)
      {
          const products=this.getAvailableProducts(),items=tokenInfo.assignedItems;
          let assignedItems=[];
          if(items && items.length>0)
          {
              assignedItems=items.map(assignedItem=>{
                  assignedItem.currentKey=assignedItem.id;
                  if(tokenInfo.items)
                  {
                      const assignedProduct=tokenInfo.items.find(product=>product["product_id"].toString()===assignedItem.productID);
                      if(assignedProduct)
                          assignedItem.name=assignedProduct.name;
                  }
                  return assignedItem;
              });
          }
          return (<>
                  <TitleAndInfo info={admin[this.props.language].activateText}/>
                  <Row style={{marginLeft:'8px'}}>
                      <Col span={12}>{admin[this.props.language].buy_order}</Col>
                      <Col span={12}>{tokenInfo.orderID}</Col>
                  </Row>
                  <Row style={{marginLeft:'8px'}}>
                      <Col span={12}>{admin[this.props.language].buy_topUpToken}</Col>
                      <Col span={12}>{tokenInfo.token}</Col>
                  </Row>
                  <Row>
                      <Table bordered title={() => <h3>{admin[this.props.language].productsTitle}</h3>} dataSource={tokenInfo.items}
                             pagination={false} rowKey={'id'} size={"small"}>
                          <Column className={'mobile-show'} title={admin[this.props.language].buy_product} dataIndex="name"/>
                          <Column className={'mobile-show'} title={admin[this.props.language].buy_quantity} dataIndex="quantity" key="quantity"/>
                      </Table>
                  </Row>
                  {this.renderAssignedTable(this.state.assigned,true,false)}
                  {this.renderPendingTable(products)}
                  {this.renderAssignedTable(assignedItems,false,true)}
                  <Row justify={'center'}>
                      {products.length===0?<Button style={{width:'100px',marginTop:'10px'}} type="primary light-primary rounded-sm"
                                                   onClick={this.activateToken}> {general[this.props.language].save}</Button>:''}
                      <Button style={{width:'100px',marginTop:'10px'}} type={'danger rounded-sm float-right'} onClick={()=>{
                          this.setState({token:''},()=>this.props.dispatch({type: CLEAR_ORDER}));
                      }}>{auth[this.props.language].cancel}</Button>
                  </Row>
              </>
          );
      }
      return "";
  };

  render() {
      return (
          <Card bodyStyle={{padding:0}} bordered={false}>
              {this.renderTokenInformation()}
              {this.renderOrderInformation()}
          </Card>
      );
  }
}
const mapStateToProps=(state) => {
    let queryParams = getQueryParams(state.router.location.search);
    return {
        language: state.auth.language,
        tokenInfo:state.payment.tokenInfo,
        token: queryParams.token
    }
};

export default PrivateRoute(connect(mapStateToProps, {getItemsByToken,modalShow,modalDismiss, activateToken,redirect})(Activate));