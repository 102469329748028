
const templates = {
  es: {
    title: 'Formularios o plantillas en formato .PDF.',
    manage_tab: 'Gestionar',
    add_tab: 'Agregar',
    manage_info: 'Aquí puedes administrarlos y organizarlos',
    add_info: 'Agrega y/o gestiona tus formularios o plantillas en formato .PDF que vayas a firmar.',
    document_column: 'Nombre',
    type_column: 'Tipo',
    select_pdf: 'Selecciónalos y cárgalos',
    is_private: 'Es privado?',
    load: 'Cargar como plantillas',
    see: 'Ver',
    selectTemplate: 'Seleccione los documentos a firmar',
    withTemplate: '¿Desea cargar plantillas PDF al usuario?',
    fileRequired: 'Debes cargar por lo menos un archivo o escoger una plantilla',
    id_column: 'Id',
    emptyTitle: '¿Reenviando los mismos documentos?',
    emptyComment: 'Las plantillas te ayudarán. Ahorra tiempo guardando y reutilizando documentos con marcadores de posición y campos.',
    newTemplatePrivate: 'Nueva plantilla privada',
    newTemplatePublic: 'Nueva plantilla pública',
    loadingTemplates: 'No tardamos, estamos cargando tus documentos',
    use: 'Usar plantilla',
    view: 'Ver',
    delete: 'Eliminar',
    download: 'Descargar'
  },
  en: {
    title: 'PDF templates',
    manage_tab: 'Manage',
    add_tab: 'Add',
    manage_info: 'Manage your PDF templates',
    add_info: 'Add PDF templates',
    document_column: 'Documents',
    type_column: 'Type',
    select_pdf: 'Select PDF file',
    is_private: 'Is private?',
    load: 'Load',
    see: 'See',
    selectTemplate: 'Select template',
    withTemplate: '¿Do you want to send pdf templates to the user?',
    fileRequired: 'Error, You must upload a file or select a template',
    id_column: 'Id',
    emptyTitle: '¿Sending again the same documents?',
    emptyComment: 'Templates will help you, Save time by saving and reusing PDF documents with position markers and fields.',
    newTemplatePrivate: 'New private template',
    newTemplatePublic: 'New public template',
    loadingTemplates: 'Won"t take long. We are uploading your documents',
    use: 'Use template',
    view: 'View',
    delete: 'Delete',
    download: 'Download'
  }
};
export default templates;
