import {CLEAR_WORKFLOW_DETAILS_INFORMATION} from "../types";

const clearWorkflowDetailsInformation= () => async (dispatch) => {
  let payload = {
    workflowList: {
      pendingSigners: [],
      finishedSigners:[],
      base64File: null,
      fileName:null
    }
  };
  dispatch({type:CLEAR_WORKFLOW_DETAILS_INFORMATION,payload:payload});
};
export default clearWorkflowDetailsInformation;