import netcoServer from '../../apis/netcoServer';
import _ from 'lodash';
import loadingCall from "../helpers/loadingCall";
import handleBlob from "../helpers/handleBlob";
import {INFO_SHOW} from "../types";
import general from "../../const/general";

const getSignedFiles= (selectedFiles) => async (dispatch, getState) => {
  let formData = {uids: _.join(selectedFiles, ',')};
  formData.returnBase64File = true;
  let response = await loadingCall(dispatch, () => netcoServer.newPostBlob('/UserService/getSignedFilesStream', formData));
  if(response && response.data)
  {
    if(response.data.size===0)
      dispatch({type: INFO_SHOW,payload: { info: { message: general[getState().auth.language].unknownError, type: 'error'}  }});
    else
      handleBlob(response.data,'download.zip')
  }
  else
    console.log(response);
};
export default getSignedFiles;