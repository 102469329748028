export const required = value => value ? undefined : 'Campo Requerido';
export const commaequals = value => value.includes(",") || value.includes("=") ? 'Caracter invalido': undefined;
export const requireden = value => value ? undefined : 'Mandatory Field';
export const requiredlang = message => value => value ? undefined : message;
export const requiredNumber = value => value !== undefined ? undefined : 'El campo es requerido';

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLengthEs = max => value =>
    value && value.length > max ? `Máximo ${max} caracteres` : undefined;

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const maxLength200 = maxLength(200);
export const maxLength20 = maxLength(20);
export const maxLengthEs50 = maxLengthEs(50);
export const maxLengthEs500 = maxLengthEs(500);
export const maxLengthEs200 = maxLengthEs(200);
export const maxLengthEs100 = maxLengthEs(100);

export const minLength10 = minLength(10);
export const minLength8 = minLength(8);

export const email = value =>  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? 'Invalid email address' : undefined;
export const emailes = value =>  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? 'Correo inválido' : undefined;
export const emaillang = message => value =>  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? message : undefined;