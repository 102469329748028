import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Col, Modal, Row, Select } from "antd";

// import 'antd/lib/modal/style/css';


import {Field, reduxForm} from "redux-form";
import {
  CreateCheckbox,
  CreatePasswordField,
  CreateSelectField,
  CreateTextField
} from "../../Helpers/Input/InputCreator";
import {required} from "../../Helpers/Input/validations";
import _ from 'lodash';
import {numberNormalizer} from "../../Helpers/Input/normalizers";
import admin from "../../../const/admin";
import {LockOutlined} from "@ant-design/icons";

class AdminConfigurationsModal extends React.Component {

  componentDidMount() {
    this.props.initialize(_.pick(this.props.entry, ['name', 'value']));
  }

  renderField() {
    if(this.props.entry.type === 0) {
      return (
          <Field label={admin[this.props.language].value_column}
                 name="value"
                 size={'large'}
                 className={'mb-10'}
                 component={CreateSelectField}
                 placeholder={admin[this.props.language].value_column}
                 validate={this.props.entry.required ? [required] : []}
                 hasFeedback>
            {this.props.entry.values.map(item => <Select.Option value={item}>{item}</Select.Option>)}
          </Field>
      );
    } else if(this.props.entry.type === 1) {
      return (
          <Field label={admin[this.props.language].value_column}
                 name="value"
                 size={'large'}
                 className={'mb-10'}
                 component={CreateTextField}
                 placeholder={admin[this.props.language].value_column}
                 validate={this.props.entry.required ? [required] : []}
                 hasFeedback/>
          );
    } else if(this.props.entry.type === 2) {
      return (
          <Field label={admin[this.props.language].value_column}
                 name="value"
                 size={'large'}
                 className={'mb-10'}
                 component={CreateCheckbox}
                 placeholder={admin[this.props.language].value_column}
                 hasFeedback/>
      );
    } else if(this.props.entry.type === 3) {
      return (
          <Field label={admin[this.props.language].value_column}
                 name="value"
                 size={'large'}
                 className={'mb-10'}
                 component={CreateTextField}
                 placeholder={admin[this.props.language].value_column}
                 validate={this.props.entry.required ? [required] : []}
                 normalize={numberNormalizer}
                 hasFeedback/>
      );
    } else if(this.props.entry.type === 4) {
      return (
          <Field label={admin[this.props.language].value_column}
                 name="value"
                 size={'large'}
                 className={'mb-10'}
                 prefix={<LockOutlined className={'icon-font'} />}
                 component={CreatePasswordField}
                 placeholder={admin[this.props.language].value_column}
                 validate={this.props.entry.required ? [required] : []}
                 hasFeedback/>
      );
    }
  }

  render() {
    return (
      (<Modal
        open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
        <Form onFinish={this.props.handleSubmit(this.props.onSubmit)}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Field label={admin[this.props.language].name_column}
                     name="name"
                     size={'large'}
                     className={'mb-10'}
                     component={CreateTextField}
                     disabled={true}
                     placeholder={admin[this.props.language].name_column}
                     hasFeedback/>
            </Col>
            <Col className={'netcoConfig'} xs={24} md={12}>
              {this.renderField()}
            </Col>
          </Row>
          <Row>
            <Button className={'float-right'} htmlType={'submit'} type={'primary'}>{this.props.btn}</Button>
          </Row>
        </Form>
      </Modal>)
    );
  }

}

export default reduxForm({form: 'admin_configurations_form'})(AdminConfigurationsModal);
