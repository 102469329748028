import React from 'react';
import PublicLayout from "../../Layouts/Public";
import {connect} from "react-redux";
import logoutUser from "../../../actions/auth/logoutUser";
import checkStrongAuthentication from '../../../actions/auth/checkStrongAuthentication'
import autho from "../../../const/auth";
import profile from "../../../const/profile";

class FingerPrint extends React.Component {

  state = {message: 'Cargando...', fingerTimer: null, wsclosed:false};

  componentDidMount() {
	if(this.state.fingerTimer !== null || this.state.wsclosed)
		return;
    let fingerTimer, socket;
    socket = new WebSocket("wss://signsocket.stepover.com:62045/netcoWebSocket/wsocketendpoint");
    socket.onopen = () => {
      console.log('OPEN');
    };
    socket.onmessage = (msg) => {
      switch(msg.data)
      {
        case "onOpen":
          fingerTimer = setInterval( () => {
            if(socket.readyState === socket.OPEN){
              socket.send('getImage');
            }
          }, 500 );
          this.setState({fingerTimer: fingerTimer, message: 'Por favor coloca la huella'});
          break;
        case "noSensor":
          if(socket.readyState === socket.OPEN)
            socket.close();
		  this.setState({message: "Lector no encontrado, por favor conectelo y recargue la página"});
          break;
        default:
          window.clearInterval(fingerTimer);
          if(socket.readyState === socket.OPEN)
            socket.close();
          this.submitFingerprint(msg.data);
      }
    };
    socket.onerror = () => {socket.close()};
    socket.onclose = (msg)=> {
	  if(msg.code === 1006)
        this.setState({message: profile[this.props.auth.language].installConnector,wsclosed:true});
	  else if(msg.code === 1011)
        this.setState({message: "Error desconocido",wsclosed:true});
	  else
		  this.setState({wsclosed:true});
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.state.fingerTimer);
  }

  submitFingerprint = (fingerprint) => {
    this.props.checkStrongAuthentication({fingerPrint: fingerprint,params:this.props.auth.params});
  };

  render() {
    return (
      <PublicLayout title={'Ingresar huella'}>
        <h1>{this.state.message}</h1>
        <p style={{textAlign:'center'}} className={'link'} onClick={this.props.logoutUser}>{autho[this.props.auth.language].cancel}</p>
      </PublicLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps, {checkStrongAuthentication,logoutUser})(FingerPrint);
