import React from 'react';
import { Field, reduxForm } from "redux-form";
import { Form, Button, Select } from "antd";
import { CreateCheckbox, CreateSelectField, CreateTextField } from "../Helpers/Input/InputCreator";
import { email, required, commaequals } from "../Helpers/Input/validations";
import { connect } from "react-redux";
import importCertificateNetcoCodes from '../../actions/certificates/importCertificateNetcoCodes';
import PrivateRoute from "../Root/PrivateRoute";
import certificates from "../../const/certificates";
import general from "../../const/general";
import { ReactComponent as OtherCertificatesIcon } from '../../resources/img/NewIcons/certificates/other-certificates.svg'

const CSROption = (props) => {
    const onSubmit = (formValues) => {
        formValues.language = props.language;
        formValues.requiresCert = props.requiresCert;
        props.generateCSR(formValues);
    };
    return (
        <>
            {props.renderCardCertificates(certificates[props.language].createCSR, certificates[props.language].csr_info, OtherCertificatesIcon, certificates[props.language].csr_info3)}
            <Form onFinish={props.handleSubmit(onSubmit)} className={'text-title size12 mt-20'} style={{ marginLeft: 14 }}>
                <div className={'mb-10 mt-10'}>{certificates[props.language].complete_name}</div>
                <Field
                    name="cn"
                    component={CreateTextField}
                    placeholder={certificates[props.language].complete_name}
                    validate={[required, commaequals]}
                    hasFeedback />

                <div className={'mb-10 mt-10'}>{certificates[props.language].email}</div>
                <Field
                    name="mail"
                    component={CreateTextField}
                    placeholder={certificates[props.language].email}
                    validate={[required, email]}
                    hasFeedback />
                <div className={'mb-10  mt-10'}>{certificates[props.language].identification}</div>
                <Field
                    name="cedula"
                    component={CreateTextField}
                    placeholder={certificates[props.language].identification}
                    validate={[required, commaequals]}
                    hasFeedback />
                <div className={'mb-10  mt-10'}>{certificates[props.language].country}</div>
                <Field
                    name="country"
                    component={CreateSelectField}
                    validate={[required]}
                    hasFeedback>
                    <Select.Option value="AF">Afghanistan</Select.Option>
                    <Select.Option value="AX">Ã…land Islands</Select.Option>
                    <Select.Option value="AL">Albania</Select.Option>
                    <Select.Option value="DZ">Algeria</Select.Option>
                    <Select.Option value="AS">American Samoa</Select.Option>
                    <Select.Option value="AD">Andorra</Select.Option>
                    <Select.Option value="AO">Angola</Select.Option>
                    <Select.Option value="AI">Anguilla</Select.Option>
                    <Select.Option value="AQ">Antarctica</Select.Option>
                    <Select.Option value="AG">AntiguaandBarbuda</Select.Option>
                    <Select.Option value="AR">Argentina</Select.Option>
                    <Select.Option value="AM">Armenia</Select.Option>
                    <Select.Option value="AW">Aruba</Select.Option>
                    <Select.Option value="AU">Australia</Select.Option>
                    <Select.Option value="AT">Austria</Select.Option>
                    <Select.Option value="AZ">Azerbaijan</Select.Option>
                    <Select.Option value="BS">Bahamas</Select.Option>
                    <Select.Option value="BH">Bahrain</Select.Option>
                    <Select.Option value="BD">Bangladesh</Select.Option>
                    <Select.Option value="BB">Barbados</Select.Option>
                    <Select.Option value="BY">Belarus</Select.Option>
                    <Select.Option value="BE">Belgium</Select.Option>
                    <Select.Option value="BZ">Belize</Select.Option>
                    <Select.Option value="BJ">Benin</Select.Option>
                    <Select.Option value="BM">Bermuda</Select.Option>
                    <Select.Option value="BT">Bhutan</Select.Option>
                    <Select.Option value="BO">Bolivia</Select.Option>
                    <Select.Option value="BA">BosniaandHerzegovina</Select.Option>
                    <Select.Option value="BW">Botswana</Select.Option>
                    <Select.Option value="BV">BouvetIsland</Select.Option>
                    <Select.Option value="BR">Brazil</Select.Option>
                    <Select.Option value="IO">BritishIndianOceanTerritory</Select.Option>
                    <Select.Option value="BN">BruneiDarussalam</Select.Option>
                    <Select.Option value="BG">Bulgaria</Select.Option>
                    <Select.Option value="BF">BurkinaFaso</Select.Option>
                    <Select.Option value="BI">Burundi</Select.Option>
                    <Select.Option value="KH">Cambodia</Select.Option>
                    <Select.Option value="CM">Cameroon</Select.Option>
                    <Select.Option value="CA">Canada</Select.Option>
                    <Select.Option value="CV">CapeVerde</Select.Option>
                    <Select.Option value="KY">CaymanIslands</Select.Option>
                    <Select.Option value="CF">CentralAfricanRepublic</Select.Option>
                    <Select.Option value="TD">Chad</Select.Option>
                    <Select.Option value="CL">Chile</Select.Option>
                    <Select.Option value="CN">China</Select.Option>
                    <Select.Option value="CX">ChristmasIsland</Select.Option>
                    <Select.Option value="CC">Cocos(Keeling)Islands</Select.Option>
                    <Select.Option selected="" value="CO">Colombia</Select.Option>
                    <Select.Option value="KM">Comoros</Select.Option>
                    <Select.Option value="CG">Congo</Select.Option>
                    <Select.Option value="CD">Congo,TheDemocraticRepublicofthe</Select.Option>
                    <Select.Option value="CK">CookIslands</Select.Option>
                    <Select.Option value="CR">CostaRica</Select.Option>
                    <Select.Option value="CI">Cote DIvoire</Select.Option>
                    <Select.Option value="HR">Croatia</Select.Option>
                    <Select.Option value="CU">Cuba</Select.Option>
                    <Select.Option value="CY">Cyprus</Select.Option>
                    <Select.Option value="CZ">CzechRepublic</Select.Option>
                    <Select.Option value="DK">Denmark</Select.Option>
                    <Select.Option value="DJ">Djibouti</Select.Option>
                    <Select.Option value="DM">Dominica</Select.Option>
                    <Select.Option value="DO">DominicanRepublic</Select.Option>
                    <Select.Option value="EC">Ecuador</Select.Option>
                    <Select.Option value="EG">Egypt</Select.Option>
                    <Select.Option value="SV">ElSalvador</Select.Option>
                    <Select.Option value="GQ">EquatorialGuinea</Select.Option>
                    <Select.Option value="ER">Eritrea</Select.Option>
                    <Select.Option value="ES">España</Select.Option>
                    <Select.Option value="EE">Estonia</Select.Option>
                    <Select.Option value="ET">Ethiopia</Select.Option>
                    <Select.Option value="FK">FalklandIslands(Malvinas)</Select.Option>
                    <Select.Option value="FO">FaroeIslands</Select.Option>
                    <Select.Option value="FJ">Fiji</Select.Option>
                    <Select.Option value="FI">Finland</Select.Option>
                    <Select.Option value="FR">France</Select.Option>
                    <Select.Option value="GF">FrenchGuiana</Select.Option>
                    <Select.Option value="PF">FrenchPolynesia</Select.Option>
                    <Select.Option value="TF">FrenchSouthernTerritories</Select.Option>
                    <Select.Option value="GA">Gabon</Select.Option>
                    <Select.Option value="GM">Gambia</Select.Option>
                    <Select.Option value="GE">Georgia</Select.Option>
                    <Select.Option value="DE">Germany</Select.Option>
                    <Select.Option value="GH">Ghana</Select.Option>
                    <Select.Option value="GI">Gibraltar</Select.Option>
                    <Select.Option value="GR">Greece</Select.Option>
                    <Select.Option value="GL">Greenland</Select.Option>
                    <Select.Option value="GD">Grenada</Select.Option>
                    <Select.Option value="GP">Guadeloupe</Select.Option>
                    <Select.Option value="GU">Guam</Select.Option>
                    <Select.Option value="GT">Guatemala</Select.Option>
                    <Select.Option value="GG">Guernsey</Select.Option>
                    <Select.Option value="GN">Guinea</Select.Option>
                    <Select.Option value="GW">Guinea-Bissau</Select.Option>
                    <Select.Option value="GY">Guyana</Select.Option>
                    <Select.Option value="HT">Haiti</Select.Option>
                    <Select.Option value="HM">HeardIslandandMcdonaldIslands</Select.Option>
                    <Select.Option value="VA">HolySee(VaticanCityState)</Select.Option>
                    <Select.Option value="HN">Honduras</Select.Option>
                    <Select.Option value="HK">HongKong</Select.Option>
                    <Select.Option value="HU">Hungary</Select.Option>
                    <Select.Option value="IS">Iceland</Select.Option>
                    <Select.Option value="IN">India</Select.Option>
                    <Select.Option value="ID">Indonesia</Select.Option>
                    <Select.Option value="IR">Iran,IslamicRepublicOf</Select.Option>
                    <Select.Option value="IQ">Iraq</Select.Option>
                    <Select.Option value="IE">Ireland</Select.Option>
                    <Select.Option value="IM">IsleofMan</Select.Option>
                    <Select.Option value="IL">Israel</Select.Option>
                    <Select.Option value="IT">Italy</Select.Option>
                    <Select.Option value="JM">Jamaica</Select.Option>
                    <Select.Option value="JP">Japan</Select.Option>
                    <Select.Option value="JE">Jersey</Select.Option>
                    <Select.Option value="JO">Jordan</Select.Option>
                    <Select.Option value="KZ">Kazakhstan</Select.Option>
                    <Select.Option value="KE">Kenya</Select.Option>
                    <Select.Option value="KI">Kiribati</Select.Option>
                    <Select.Option value="KP">Korea,DemocraticPeopleSRepublicof</Select.Option>
                    <Select.Option value="KR">Korea,Republicof</Select.Option>
                    <Select.Option value="KW">Kuwait</Select.Option>
                    <Select.Option value="KG">Kyrgyzstan</Select.Option>
                    <Select.Option value="LA">LaoPeopleSDemocraticRepublic</Select.Option>
                    <Select.Option value="LV">Latvia</Select.Option>
                    <Select.Option value="LB">Lebanon</Select.Option>
                    <Select.Option value="LS">Lesotho</Select.Option>
                    <Select.Option value="LR">Liberia</Select.Option>
                    <Select.Option value="LY">LibyanArabJamahiriya</Select.Option>
                    <Select.Option value="LI">Liechtenstein</Select.Option>
                    <Select.Option value="LT">Lithuania</Select.Option>
                    <Select.Option value="LU">Luxembourg</Select.Option>
                    <Select.Option value="MO">Macao</Select.Option>
                    <Select.Option value="MK">Macedonia,TheFormerYugoslavRepublicof</Select.Option>
                    <Select.Option value="MG">Madagascar</Select.Option>
                    <Select.Option value="MW">Malawi</Select.Option>
                    <Select.Option value="MY">Malaysia</Select.Option>
                    <Select.Option value="MV">Maldives</Select.Option>
                    <Select.Option value="ML">Mali</Select.Option>
                    <Select.Option value="MT">Malta</Select.Option>
                    <Select.Option value="MH">MarshallIslands</Select.Option>
                    <Select.Option value="MQ">Martinique</Select.Option>
                    <Select.Option value="MR">Mauritania</Select.Option>
                    <Select.Option value="MU">Mauritius</Select.Option>
                    <Select.Option value="YT">Mayotte</Select.Option>
                    <Select.Option value="MX">Mexico</Select.Option>
                    <Select.Option value="FM">Micronesia,FederatedStatesof</Select.Option>
                    <Select.Option value="MD">Moldova,Republicof</Select.Option>
                    <Select.Option value="MC">Monaco</Select.Option>
                    <Select.Option value="MN">Mongolia</Select.Option>
                    <Select.Option value="MS">Montserrat</Select.Option>
                    <Select.Option value="MA">Morocco</Select.Option>
                    <Select.Option value="MZ">Mozambique</Select.Option>
                    <Select.Option value="MM">Myanmar</Select.Option>
                    <Select.Option value="NA">Namibia</Select.Option>
                    <Select.Option value="NR">Nauru</Select.Option>
                    <Select.Option value="NP">Nepal</Select.Option>
                    <Select.Option value="NL">Netherlands</Select.Option>
                    <Select.Option value="AN">NetherlandsAntilles</Select.Option>
                    <Select.Option value="NC">NewCaledonia</Select.Option>
                    <Select.Option value="NZ">NewZealand</Select.Option>
                    <Select.Option value="NI">Nicaragua</Select.Option>
                    <Select.Option value="NE">Niger</Select.Option>
                    <Select.Option value="NG">Nigeria</Select.Option>
                    <Select.Option value="NU">Niue</Select.Option>
                    <Select.Option value="NF">NorfolkIsland</Select.Option>
                    <Select.Option value="MP">NorthernMarianaIslands</Select.Option>
                    <Select.Option value="NO">Norway</Select.Option>
                    <Select.Option value="OM">Oman</Select.Option>
                    <Select.Option value="PK">Pakistan</Select.Option>
                    <Select.Option value="PW">Palau</Select.Option>
                    <Select.Option value="PS">PalestinianTerritory,Occupied</Select.Option>
                    <Select.Option value="PA">Panama</Select.Option>
                    <Select.Option value="PG">PapuaNewGuinea</Select.Option>
                    <Select.Option value="PY">Paraguay</Select.Option>
                    <Select.Option value="PE">Peru</Select.Option>
                    <Select.Option value="PH">Philippines</Select.Option>
                    <Select.Option value="PN">Pitcairn</Select.Option>
                    <Select.Option value="PL">Poland</Select.Option>
                    <Select.Option value="PT">Portugal</Select.Option>
                    <Select.Option value="PR">PuertoRico</Select.Option>
                    <Select.Option value="QA">Qatar</Select.Option>
                    <Select.Option value="RE">Reunion</Select.Option>
                    <Select.Option value="RO">Romania</Select.Option>
                    <Select.Option value="RU">RussianFederation</Select.Option>
                    <Select.Option value="RW">RWANDA</Select.Option>
                    <Select.Option value="SH">SaintHelena</Select.Option>
                    <Select.Option value="KN">SaintKittsandNevis</Select.Option>
                    <Select.Option value="LC">SaintLucia</Select.Option>
                    <Select.Option value="PM">SaintPierreandMiquelon</Select.Option>
                    <Select.Option value="VC">SaintVincentandtheGrenadines</Select.Option>
                    <Select.Option value="WS">Samoa</Select.Option>
                    <Select.Option value="SM">SanMarino</Select.Option>
                    <Select.Option value="ST">SaoTomeandPrincipe</Select.Option>
                    <Select.Option value="SA">SaudiArabia</Select.Option>
                    <Select.Option value="SN">Senegal</Select.Option>
                    <Select.Option value="CS">SerbiaandMontenegro</Select.Option>
                    <Select.Option value="SC">Seychelles</Select.Option>
                    <Select.Option value="SL">SierraLeone</Select.Option>
                    <Select.Option value="SG">Singapore</Select.Option>
                    <Select.Option value="SK">Slovakia</Select.Option>
                    <Select.Option value="SI">Slovenia</Select.Option>
                    <Select.Option value="SB">SolomonIslands</Select.Option>
                    <Select.Option value="SO">Somalia</Select.Option>
                    <Select.Option value="ZA">SouthAfrica</Select.Option>
                    <Select.Option value="GS">SouthGeorgiaandtheSouthSandwichIslands</Select.Option>
                    <Select.Option value="LK">SriLanka</Select.Option>
                    <Select.Option value="SD">Sudan</Select.Option>
                    <Select.Option value="SR">Suriname</Select.Option>
                    <Select.Option value="SJ">SvalbardandJanMayen</Select.Option>
                    <Select.Option value="SZ">Swaziland</Select.Option>
                    <Select.Option value="SE">Sweden</Select.Option>
                    <Select.Option value="CH">Switzerland</Select.Option>
                    <Select.Option value="SY">SyrianArabRepublic</Select.Option>
                    <Select.Option value="TW">Taiwan,ProvinceofChina</Select.Option>
                    <Select.Option value="TJ">Tajikistan</Select.Option>
                    <Select.Option value="TZ">Tanzania,UnitedRepublicof</Select.Option>
                    <Select.Option value="TH">Thailand</Select.Option>
                    <Select.Option value="TL">Timor-Leste</Select.Option>
                    <Select.Option value="TG">Togo</Select.Option>
                    <Select.Option value="TK">Tokelau</Select.Option>
                    <Select.Option value="TO">Tonga</Select.Option>
                    <Select.Option value="TT">TrinidadandTobago</Select.Option>
                    <Select.Option value="TN">Tunisia</Select.Option>
                    <Select.Option value="TR">Turkey</Select.Option>
                    <Select.Option value="TM">Turkmenistan</Select.Option>
                    <Select.Option value="TC">TurksandCaicosIslands</Select.Option>
                    <Select.Option value="TV">Tuvalu</Select.Option>
                    <Select.Option value="UG">Uganda</Select.Option>
                    <Select.Option value="UA">Ukraine</Select.Option>
                    <Select.Option value="AE">UnitedArabEmirates</Select.Option>
                    <Select.Option value="GB">UnitedKingdom</Select.Option>
                    <Select.Option value="US">UnitedStates</Select.Option>
                    <Select.Option value="UM">UnitedStatesMinorOutlyingIslands</Select.Option>
                    <Select.Option value="UY">Uruguay</Select.Option>
                    <Select.Option value="UZ">Uzbekistan</Select.Option>
                    <Select.Option value="VU">Vanuatu</Select.Option>
                    <Select.Option value="VE">Venezuela</Select.Option>
                    <Select.Option value="VN">VietNam</Select.Option>
                    <Select.Option value="VG">VirginIslands,British</Select.Option>
                    <Select.Option value="VI">VirginIslands,U.S.</Select.Option>
                    <Select.Option value="WF">WallisandFutuna</Select.Option>
                    <Select.Option value="EH">WesternSahara</Select.Option>
                    <Select.Option value="YE">Yemen</Select.Option>
                    <Select.Option value="ZM">Zambia</Select.Option>
                    <Select.Option value="ZW">Zimbabwe</Select.Option>
                </Field>
                <div className={'mb-10  mt-10'}>{certificates[props.language].department}</div>
                <Field
                    name="state"
                    component={CreateTextField}
                    placeholder={certificates[props.language].department}
                    validate={[required, commaequals]}
                    hasFeedback />

                <div className={'mb-10  mt-10'}>{certificates[props.language].city}</div>
                <Field
                    name="city"
                    component={CreateTextField}
                    placeholder={certificates[props.language].city}
                    validate={[required, commaequals]}
                    hasFeedback />


                <div className={'mb-10 mt-10'}>{certificates[props.language].address}</div>
                <Field
                    name="streetAddress"
                    component={CreateTextField}
                    placeholder={certificates[props.language].address}
                    validate={[required, commaequals]}
                    hasFeedback />

                <div className={'mb-10  mt-10'}>{certificates[props.language].phone}</div>
                <Field
                    name="phone"
                    component={CreateTextField}
                    placeholder={certificates[props.language].phone}
                    validate={[required, commaequals]}
                    hasFeedback />


                <div className={'mb-10  mt-10'}>{certificates[props.language].company_name}</div>
                <Field
                    name="organization"
                    component={CreateTextField}
                    placeholder={certificates[props.language].company_name}
                    validate={[required, commaequals]}
                    hasFeedback />

                <div className={'mb-10  mt-10'}>{certificates[props.language].company_area}</div>
                <Field
                    name="organizationalUnit"
                    component={CreateTextField}
                    placeholder={certificates[props.language].company_area}
                    validate={[required, commaequals]}
                    hasFeedback />
                <Field name="agree"
                    className='mt-20'
                    type={'checkbox'}
                    component={CreateCheckbox}
                    validate={required}
                    hasFeedback>
                    <a rel='noopener noreferrer' target={'_blank'} href={window.netcourlid + '/comagre/AcuerdoDeComunicaciones.pdf'}>
                        {general[props.language].agree}
                    </a>
                </Field>
                <Button htmlType={'submit'} className='mt-20' type={'primary'} style={{ marginRight: 21 }}>
                    {certificates[props.language].createCSR}
                </Button>
                <Button type={'ghost'} onClick={() => props.changeOption(0)}>
                    {certificates[props.language].cancel}
                </Button>
            </Form>
        </>

    );
};

const mapStateToProps = state => {
    return {
        language: state.auth.language,
        requiresCert: state.auth.requiresCert,
        initialValues: {
            agree: false,
            country: 'CO'
        }
    }
};

export default PrivateRoute(connect(mapStateToProps, { importCertificateNetcoCodes })((reduxForm({
    form: 'netcoCertificate_form'
})(CSROption))));

