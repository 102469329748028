import netcoServer from '../../apis/netcoServer';
import { GET_WORKFLOW_DETAILS_INFORMATION } from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";

const getWorkflowDetailsInformation = (formData, callBack = [], callBackError = [], multipleUID = null, noUpdateRedux = null) => async (dispatch) => {
  if (multipleUID && Array.isArray(multipleUID)) {
    const data = []
    for (let i = 0; i < multipleUID.length; i++) {
      let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getWorkflowDetailsInformation', { uid: multipleUID[i], returnBase64File: true }));
      handleResponse(dispatch, response);
      if (response && response.data && response.data.success) {
        data.push({ name: response.data.filesInfo.fileName, base64File: response.data.filesInfo.base64File })
      }
    }
    callBack && callBack[0](data)
  } else {
    let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getWorkflowDetailsInformation', formData));
    handleResponse(dispatch, response, noUpdateRedux?null:GET_WORKFLOW_DETAILS_INFORMATION, onSuccessPayload, null, [], [], callBackError, callBack);
  }
};

const onSuccessPayload = data => {
  return {
    workflowList: {
      pendingSigners: data.filesInfo.pendingSigners,
      finishedSigners: data.filesInfo.finishedSigners,
      base64File: data.filesInfo.base64File,
      fileName: data.filesInfo.fileName
    }
  };
};
export default getWorkflowDetailsInformation;