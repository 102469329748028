import netcoServer from '../../apis/netcoServer';
import {UPLOAD_PDF_TEMPLATE} from "../types";
import handleResponse from '../helpers/handleResponse';
import getBase64File from '../helpers/getBase64File';
import infoPayload from '../helpers/payloadHandlers/infoPayload';
import _ from "lodash";
import loadingCall from "../helpers/loadingCall";
import {checkPDFPassword} from "../../components/Helpers/utils";

const uploadPDFTemplate= (formValues, file) => async (dispatch,getState) => {
  formValues.base64File = await getBase64File(file);
  formValues.selectedTemplate = file.name;
  try
  {
    formValues.passwords=await checkPDFPassword(formValues.base64File,file.name,formValues.password,
        dispatch,getState().auth.language);
  }
  catch(err)
  {
    dispatch({type: 'INFO_SHOW', payload:err});
    return;
  }
  const onSuccessPayload = () => {
    return infoPayload('success', 'Plantilla PDF cargada satisfactoriamente!');
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/uploadPDFTemplate', _.omit(formValues, ['avatar'])));
  handleResponse(dispatch, response, UPLOAD_PDF_TEMPLATE, onSuccessPayload);
};
export default uploadPDFTemplate;
