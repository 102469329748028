import netcoServer from '../../apis/netcoServer';
import { GET_WORKFLOW_OPTIONS} from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";

const getWorkflowOptions= () => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getWorkflowOptions', {}));
  handleResponse(dispatch, response, GET_WORKFLOW_OPTIONS, onSuccessPayload);
};

const onSuccessPayload = data => {
  return {
    workflowList: {
      workflowOptions: data.result.workflowOptions
    }
  };
};
export default getWorkflowOptions;