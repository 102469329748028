import React from 'react';
import PrivateRoute from "../Root/PrivateRoute";
import { connect } from "react-redux";
import CertificatesManager from "./CertificatesManager"
import CertificateOptions from './CertificateOptions';
import {getQueryParams} from "../Helpers/utils";
import './certificate.css'

class Certificates extends React.Component {
  state = { 
    visibleOptions: false,
    option : 0 // 0:options, 1: Netco PKI, 2: P12, 3: CSR, 4: ZIP, 5:Other, 6:Andes
  }

  componentDidMount()
  {
    if(this.props.option)
      this.setState({visibleOptions: true,option:parseInt(this.props.option)});
  }

  showOptionsCertificates = (visible) => {
    this.setState({ visibleOptions: visible, option:0 })
  }

  changeOption = (option) => {
    this.setState({option})
  }

  render() {
    return (
      <>
        <CertificatesManager language={this.props.language} showOptionsCertificates={this.showOptionsCertificates} />
        <CertificateOptions language={this.props.language} visibleOptions={this.state.visibleOptions} showOptionsCertificates={this.showOptionsCertificates} option={this.state.option} changeOption={this.changeOption} />
      </>
    );
  };
}

const mapStateToProps = state => {
  let queryParams = getQueryParams(state.router.location.search);
  return {
    language: state.auth.language,
    option: queryParams.option
  };
};

export default PrivateRoute(connect(mapStateToProps)(Certificates));