import {
  CONFIRM_EMAIL,
  INFO_DISMISS,
  INFO_SHOW,
  LOG_IN,
  RECOVER_PASSWORD,
  RESEND_EMAIL,
  RESTORE_PASSWORD,
  SIGN_UP,
  UPDATE_PROFILE,
  LOG_OUT,
  BASIC_REGISTRATION,
  CHECK_STRONG_AUTHENTICATION,
  CHECK_BASIC_AUTHENTICATION,
  IMPORT_CERTIFICATE_NETCO_CODES,
  IMPORT_CERTIFICATE_ENTRUST_CODES,
  IMPORT_CERTIFICATE_FROM_ENTRUST_EPF,
  IMPORT_CERTIFICATE_FROM_P12,
  IMPORT_CERTIFICATE_FROM_ZIP,
  IMPORT_CERTIFICATE_FROM_FIEL,
  CREATE_TIMESTAMP_SERVER,
  UPDATE_TIMESTAMP_SERVER,
  DELETE_TIMESTAMP_SERVER,
  CREATE_PROFILE,
  DELETE_PROFILE,
  CREATE_WEBSERVICE_USER,
  UPDATE_WEBSERVICE_USER,
  DELETE_WEBSERVICE_USER,
  SET_CONFIGURATION_PARAM,
  SET_LDAP_CONFIGURATION,
  NETCO_PKI_REGISTRATION,
  NETCO_CODES_REGISTRATION,
  FORGOT_PASSWORD,
  NEW_PASSWORD,
  DEL_SIGNED_FILES,
  SEND_SIGNED_FILES_UID_MAIL,
  PUT_FILES,
  DELETE_PDF_TEMPLATE,
  UPLOAD_PDF_TEMPLATE,
  GENERATE_CSR,
  PUT_FILES_UID,
  UPDATE_USER_PASSWORD,
  CREATE_USER,
  DELETE_USER,
  DELETE_USER_CREDENTIALS,
  UPDATE_PDF_FEATURES,
  SIGN_FILES,
  PUT_PREVIEW_SIGN_FILES,
  GET_USER_IMAGE_TO_SHOW,
  SIGN_FROM_TEMPLATE,
  UPDATE_USER_COORDS,
  SET_TASK_CONFIGURATION,
  UPDATE_USER_IMAGE,
  SIGN_PENDING_FILES,
  RE_SIGN_FILES,
  CHECK_APP_CODE_AUTHENTICATION,
  TEST_SMTP,
  UPDATE_USER_PARAM
} from "../actions/types";

const INITIAL_STATE = {
  message: null,
  type: null
};

const infoReducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_UP:
      return {...state, ...action.payload.info};
    case LOG_IN:
      return {...state, ...action.payload.info};
    case INFO_DISMISS:
      return {...state, ...action.payload.info};
    case INFO_SHOW:
      return {...state, ...action.payload.info};
    case CONFIRM_EMAIL:
      return {...state, ...action.payload.info};
    case RESEND_EMAIL:
      return {...state, ...action.payload.info};
    case RECOVER_PASSWORD:
      return {...state, ...action.payload.info};
    case RESTORE_PASSWORD:
      return {...state, ...action.payload.info};
    case BASIC_REGISTRATION:
      return {...state, ...action.payload.info};
    case NETCO_PKI_REGISTRATION:
      return {...state, ...action.payload.info};
    case NETCO_CODES_REGISTRATION:
      return {...state, ...action.payload.info};
    case FORGOT_PASSWORD:
      return {...state, ...action.payload.info};
    case NEW_PASSWORD:
      return {...state, ...action.payload.info};
    case CHECK_STRONG_AUTHENTICATION:
      return {...state, ...action.payload.info};
    case CHECK_BASIC_AUTHENTICATION:
      return {...state, ...action.payload.info};
    case IMPORT_CERTIFICATE_NETCO_CODES:
      return {...state, ...action.payload.info};
    case IMPORT_CERTIFICATE_ENTRUST_CODES:
      return {...state, ...action.payload.info};
    case IMPORT_CERTIFICATE_FROM_ENTRUST_EPF:
      return {...state, ...action.payload.info};
    case IMPORT_CERTIFICATE_FROM_P12:
      return {...state, ...action.payload.info};
    case IMPORT_CERTIFICATE_FROM_ZIP:
      return {...state, ...action.payload.info};
    case IMPORT_CERTIFICATE_FROM_FIEL:
      return {...state, ...action.payload.info};
    case GENERATE_CSR:
      return {...state, ...action.payload.info};
    case CREATE_TIMESTAMP_SERVER:
      return {...state, ...action.payload.info};
    case UPDATE_TIMESTAMP_SERVER:
      return {...state, ...action.payload.info};
    case DELETE_TIMESTAMP_SERVER:
      return {...state, ...action.payload.info};
    case CREATE_PROFILE:
      return {...state, ...action.payload.info};
    case UPDATE_PROFILE:
      return {...state, ...action.payload.info};
    case UPDATE_USER_PARAM:
      return {...state, ...action.payload.info};
    case TEST_SMTP:
        return {...state, ...action.payload.info};
    case DELETE_PROFILE:
      return {...state, ...action.payload.info};
    case CREATE_WEBSERVICE_USER:
      return {...state, ...action.payload.info};
    case UPDATE_WEBSERVICE_USER:
      return {...state, ...action.payload.info};
    case DELETE_WEBSERVICE_USER:
      return {...state, ...action.payload.info};
    case SET_CONFIGURATION_PARAM:
      return {...state, ...action.payload.info};
    case SET_LDAP_CONFIGURATION:
      return {...state, ...action.payload.info};
    case DEL_SIGNED_FILES:
      return {...state, ...action.payload.info};
    case SEND_SIGNED_FILES_UID_MAIL:
      return {...state, ...action.payload.info};
    case PUT_FILES:
      return {...state, ...action.payload.info};
    case DELETE_PDF_TEMPLATE:
      return {...state, ...action.payload.info};
    case UPLOAD_PDF_TEMPLATE:
      return {...state, ...action.payload.info};
    case PUT_FILES_UID:
      return {...state, ...action.payload.info};
    case UPDATE_USER_PASSWORD:
      return {...state, ...action.payload.info};
    case CREATE_USER:
      return {...state, ...action.payload.info};
    case DELETE_USER:
      return {...state, ...action.payload.info};
    case DELETE_USER_CREDENTIALS:
      return {...state, ...action.payload.info};
    case UPDATE_PDF_FEATURES:
      return {...state, ...action.payload.info};
    case SIGN_FILES:
      return {...state, ...action.payload.info};
    case PUT_PREVIEW_SIGN_FILES:
      return {...state, ...action.payload.info};
    case SIGN_FROM_TEMPLATE:
      return {...state, ...action.payload.info};
    case UPDATE_USER_COORDS:
      return {...state, ...action.payload.info};
    case SET_TASK_CONFIGURATION:
      return {...state, ...action.payload.info};
    case UPDATE_USER_IMAGE:
      return {...state, ...action.payload.info};
    case SIGN_PENDING_FILES:
      return {...state, ...action.payload.info};
    case RE_SIGN_FILES:
      return {...state, ...action.payload.info};
    case CHECK_APP_CODE_AUTHENTICATION:
      return {...state, ...action.payload.info};
    case GET_USER_IMAGE_TO_SHOW:
      return {...state, ...action.payload.info};
    case LOG_OUT:
      return {...state, ...action.payload.info};
    default:
      return state;
  }
};
export default infoReducer;