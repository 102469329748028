import netcoServer from '../../apis/netcoServer';
import loadingCall from "../helpers/loadingCall";
import { handleQRError } from '../helpers/utils';
import updateHWImage from './updateHWImage';

const getHWImage= (formValues,state=null) => async (dispatch, getState) => {
  let response = await loadingCall(dispatch, () => netcoServer.post('/UserService/getHWImage', formValues));
  handleQRError(dispatch,response,state,getState().auth.language)
  if(response && response.data && response.data.success && response.data.result){
    const info = response.data.result.info;
    dispatch(updateHWImage(info))
  }
};

export default getHWImage;