import React from "react";
import PrivateRoute from "../Root/PrivateRoute";
import {connect} from "react-redux";
import hideAllMenu from "../../actions/info/hideAllMenu";
import hideHeader from "../../actions/info/hideHeader";
import {getCustomLogos, sGrid} from "../Helpers/utils";
import {Row, Col, Typography, Steps, Button, Input, Select} from 'antd';
import newCertificate from "../../resources/img/NewIcons/certificates/newCertificate.svg";
import certificates from "../../const/certificates";
import {HOME_ROUTE} from "../Root/routes";
import auth from "../../const/auth";
import {push} from "connected-react-router";

// import 'antd/lib/button/style/css';


// import 'antd/lib/form/style/css';


// import 'antd/lib/input/style/css';


// import 'antd/lib/select/style/css';


// import 'antd/lib/icon/style/css';


import iconUser from "../../resources/img/user.svg";
import iconMail from "../../resources/img/mail.svg";
import iconEye from "../../resources/img/eye.svg";
import iconPhone from "../../resources/img/phone.svg";
import infoIcon from "../../resources/img/NewIcons/profileInfo/infoIcon.svg";
import arrowLeft from "../../resources/img/NewIcons/arrowLeft.svg";
import checkMail from "../../resources/img/NewIcons/checkMail.svg";
import {userNormalizer} from "../Helpers/Input/normalizers";
import Form from "antd/es/form/Form";
import netcoPKIRegistration from "../../actions/auth/netcoPKIRegistration";
import logoutUser from "../../actions/auth/logoutUser";
import hideFooter from "../../actions/info/hideFooter";


class NewCertificatePersonal extends React.Component
{
    state={current:0, formValues:{}}
    componentDidMount()
    {
        this.props.hideAllMenu(true);
        this.props.hideHeader(true);
        this.props.hideFooter(true);
    }

    componentWillUnmount()
    {
        this.props.hideAllMenu(false);
        this.props.hideHeader(false);
        this.props.hideFooter(false);
    }

    createUser=(formValues)=>
    {
        this.props.logoutUser(false,false,false).then(()=>this.props.netcoPKIRegistration(formValues));
    }

    onSubmit=(formValues)=>
    {
        if(this.state.current===2)
            this.setState({current:this.state.current+1,formValues:{...this.state.formValues,...formValues}},()=>this.createUser(this.state.formValues));
        else
            this.setState({current:this.state.current+1, formValues:{...this.state.formValues,...formValues}});
    }

    renderForm=()=>
    {
        const isMobile = this.props.dimApp.width <= sGrid;
        const current=this.state.current;
        return <Form initialValues={{areaCode:'57', country:'CO'}} onFinish={this.onSubmit} validateMessages={{ required: auth[this.props.language].requiredfld }}>
            { current===0 && <><Row justify={'center'}>
                <Typography style={{marginBottom:'30px'}} className={'text-title size26 color-black'}>{certificates[this.props.language].generalTitle}</Typography>
            </Row>
            <Row justify={'center'}>
                <Col span={20} style={{background: 'rgba(0, 149, 255, 0.08)',border: '1px solid #3366FF',borderRadius: '8px', padding: '20px', marginBottom:'28px'}}>
                    <div style={{textAlign:'left'}}><img style={{paddingRight:'20px'}} alt={''} src={infoIcon}/><span>{certificates[this.props.language].getCertDescLabel}</span></div>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col span={20}>
                    <Typography className='size12' style={{ fontWeight: 700, textAlign:'left' }}>
                        {auth[this.props.language].emaillbl.toUpperCase()}</Typography>
                    <Form.Item name={"mail"} normalize={userNormalizer} rules={[{ required: true, max: 50 }]} >
                        <Input style={{margin:'11px 0 11px 0'}} suffix={<img alt='' src={iconMail} />} maxLength={50} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col span={20}>
                    <Typography className='size12' style={{ fontWeight: 700, textAlign:'left' }}>
                        {certificates[this.props.language].complete_name.toUpperCase()}</Typography>
                    <Form.Item name={"cn"} rules={[{ required: true}]} >
                        <Input style={{margin:'11px 0 11px 0'}} suffix={<img alt='' src={iconUser} />} maxLength={50} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col span={20}>
                    <Typography className='size12' style={{ fontWeight: 700, textAlign:'left' }}>
                        {certificates[this.props.language].identification.toUpperCase()}</Typography>
                    <Form.Item name={"cedula"} rules={[{ required: true}]} >
                        <Input style={{margin:'11px 0 11px 0'}} suffix={<img alt='' src={iconEye} />} maxLength={50} />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{marginTop:'10px'}} justify={'center'}>
                <Col span={10}>
                    <Typography className='size12' style={{ fontWeight: 700, textAlign:'left' }}>
                        {certificates[this.props.language].countrySmall.toUpperCase()}</Typography>
                    <Form.Item name={"areaCode"} rules={[{ required: true}]} >
                        <Select size={'large'} style={{width:'100%',margin:'11px 0 11px 0', textAlign:'left'}}>
                            <Select.Option value="93">(+93) - Afghanistan</Select.Option>
                            <Select.Option value="355">(+355) - Albania</Select.Option>
                            <Select.Option value="1403">(+1403) - Alberta</Select.Option>
                            <Select.Option value="1780">(+1780) - Alberta</Select.Option>
                            <Select.Option value="213">(+213) - Algeria</Select.Option>
                            <Select.Option value="376">(+376) - Andorra</Select.Option>
                            <Select.Option value="244">(+244) - Angola</Select.Option>
                            <Select.Option value="1264">(+1264) - Anguilla</Select.Option>
                            <Select.Option value="6721">(+6721) - Antarctica (Australian bases)</Select.Option>
                            <Select.Option value="1268">(+1268) - Antigua and Barbuda</Select.Option>
                            <Select.Option value="54">(+54) - Argentina</Select.Option>
                            <Select.Option value="374">(+374) - Armenia</Select.Option>
                            <Select.Option value="297">(+297) - Aruba</Select.Option>
                            <Select.Option value="247">(+247) - Ascension</Select.Option>
                            <Select.Option value="61">(+61) - Australia</Select.Option>
                            <Select.Option value="43">(+43) - Austria</Select.Option>
                            <Select.Option value="994">(+994) - Azerbaijan</Select.Option>
                            <Select.Option value="1242">(+1242) - Bahamas</Select.Option>
                            <Select.Option value="973">(+973) - Bahrain</Select.Option>
                            <Select.Option value="880">(+880) - Bangladesh</Select.Option>
                            <Select.Option value="1246">(+1246) - Barbados</Select.Option>
                            <Select.Option value="375">(+375) - Belarus</Select.Option>
                            <Select.Option value="32">(+32) - Belgium</Select.Option>
                            <Select.Option value="501">(+501) - Belize</Select.Option>
                            <Select.Option value="229">(+229) - Benin</Select.Option>
                            <Select.Option value="1441">(+1441) - Bermuda</Select.Option>
                            <Select.Option value="975">(+975) - Bhutan</Select.Option>
                            <Select.Option value="591">(+591) - Bolivia</Select.Option>
                            <Select.Option value="387">(+387) - Bosnia and Herzegovina</Select.Option>
                            <Select.Option value="267">(+267) - Botswana</Select.Option>
                            <Select.Option value="55">(+55) - Brazil</Select.Option>
                            <Select.Option value="1250">(+1250) - British Columbia</Select.Option>
                            <Select.Option value="1604">(+1604) - British Columbia</Select.Option>
                            <Select.Option value="1778">(+1778) - British Columbia</Select.Option>
                            <Select.Option value="246">(+246) - British Indian Ocean Territory</Select.Option>
                            <Select.Option value="1284">(+1284) - British Virgin Islands</Select.Option>
                            <Select.Option value="673">(+673) - Brunei</Select.Option>
                            <Select.Option value="359">(+359) - Bulgaria</Select.Option>
                            <Select.Option value="226">(+226) - Burkina Faso</Select.Option>
                            <Select.Option value="257">(+257) - Burundi</Select.Option>
                            <Select.Option value="855">(+855) - Cambodia</Select.Option>
                            <Select.Option value="237">(+237) - Cameroon</Select.Option>
                            <Select.Option value="238">(+238) - Cape Verde</Select.Option>
                            <Select.Option value="1345">(+1345) - Cayman Islands</Select.Option>
                            <Select.Option value="236">(+236) - Central African Republic</Select.Option>
                            <Select.Option value="235">(+235) - Chad</Select.Option>
                            <Select.Option value="56">(+56) - Chile</Select.Option>
                            <Select.Option value="86">(+86) - China</Select.Option>
                            <Select.Option value="57">(+57) - Colombia</Select.Option>
                            <Select.Option value="269">(+269) - Comoros</Select.Option>
                            <Select.Option value="243">(+243) - Democratic Republic of the Congo</Select.Option>
                            <Select.Option value="242">(+242) - Republic of the Congo</Select.Option>
                            <Select.Option value="682">(+682) - Cook Islands</Select.Option>
                            <Select.Option value="506">(+506) - Costa Rica</Select.Option>
                            <Select.Option value="712">(+712) - Cote dIvoire</Select.Option>
                            <Select.Option value="385">(+385) - Croatia</Select.Option>
                            <Select.Option value="53">(+53) - Cuba</Select.Option>
                            <Select.Option value="357">(+357) - Cyprus</Select.Option>
                            <Select.Option value="420">(+420) - Czech Republic</Select.Option>
                            <Select.Option value="45">(+45) - Denmark</Select.Option>
                            <Select.Option value="253">(+253) - Djibouti</Select.Option>
                            <Select.Option value="1767">(+1767) - Dominica</Select.Option>
                            <Select.Option value="1809">(+1809) - Dominican Republic</Select.Option>
                            <Select.Option value="1829">(+1829) - Dominican Republic</Select.Option>
                            <Select.Option value="670">(+670) - East Timor</Select.Option>
                            <Select.Option value="593">(+593) - Ecuador</Select.Option>
                            <Select.Option value="20">(+20) - Egypt</Select.Option>
                            <Select.Option value="503">(+503) - El Salvador</Select.Option>
                            <Select.Option value="240">(+240) - Equatorial Guinea</Select.Option>
                            <Select.Option value="291">(+291) - Eritrea</Select.Option>
                            <Select.Option value="34">(+34) - España</Select.Option>
                            <Select.Option value="372">(+372) - Estonia</Select.Option>
                            <Select.Option value="251">(+251) - Ethiopia</Select.Option>
                            <Select.Option value="500">(+500) - Falkland Islands</Select.Option>
                            <Select.Option value="298">(+298) - Faroe Islands</Select.Option>
                            <Select.Option value="679">(+679) - Fiji</Select.Option>
                            <Select.Option value="358">(+358) - Finland</Select.Option>
                            <Select.Option value="33">(+33) - France</Select.Option>
                            <Select.Option value="594">(+594) - French Guiana</Select.Option>
                            <Select.Option value="689">(+689) - French Polynesia</Select.Option>
                            <Select.Option value="241">(+241) - Gabon</Select.Option>
                            <Select.Option value="220">(+220) - Gambia</Select.Option>
                            <Select.Option value="995">(+995) - Georgia</Select.Option>
                            <Select.Option value="49">(+49) - Germany</Select.Option>
                            <Select.Option value="233">(+233) - Ghana</Select.Option>
                            <Select.Option value="350">(+350) - Gibraltar</Select.Option>
                            <Select.Option value="30">(+30) - Greece</Select.Option>
                            <Select.Option value="299">(+299) - Greenland</Select.Option>
                            <Select.Option value="1473">(+1473) - Grenada</Select.Option>
                            <Select.Option value="1671">(+1671) - Guam</Select.Option>
                            <Select.Option value="502">(+502) - Guatemala</Select.Option>
                            <Select.Option value="224">(+224) - Guinea</Select.Option>
                            <Select.Option value="245">(+245) - Guinea-Bissau</Select.Option>
                            <Select.Option value="592">(+592) - Guyana</Select.Option>
                            <Select.Option value="509">(+509) - Haiti</Select.Option>
                            <Select.Option value="504">(+504) - Honduras</Select.Option>
                            <Select.Option value="852">(+852) - Hong Kong</Select.Option>
                            <Select.Option value="36">(+36) - Hungary</Select.Option>
                            <Select.Option value="354">(+354) - Iceland</Select.Option>
                            <Select.Option value="91">(+91) - India</Select.Option>
                            <Select.Option value="62">(+62) - Indonesia</Select.Option>
                            <Select.Option value="964">(+964) - Iraq</Select.Option>
                            <Select.Option value="98">(+98) - Iran</Select.Option>
                            <Select.Option value="353">(+353) - Ireland (Eire)</Select.Option>
                            <Select.Option value="972">(+972) - Israel</Select.Option>
                            <Select.Option value="39">(+39) - Italy</Select.Option>
                            <Select.Option value="1876">(+1876) - Jamaica</Select.Option>
                            <Select.Option value="81">(+81) - Japan</Select.Option>
                            <Select.Option value="962">(+962) - Jordan</Select.Option>
                            <Select.Option value="254">(+254) - Kenya</Select.Option>
                            <Select.Option value="686">(+686) - Kiribati</Select.Option>
                            <Select.Option value="965">(+965) - Kuwait</Select.Option>
                            <Select.Option value="996">(+996) - Kyrgyzstan</Select.Option>
                            <Select.Option value="856">(+856) - Laos</Select.Option>
                            <Select.Option value="371">(+371) - Latvia</Select.Option>
                            <Select.Option value="961">(+961) - Lebanon</Select.Option>
                            <Select.Option value="266">(+266) - Lesotho</Select.Option>
                            <Select.Option value="231">(+231) - Liberia</Select.Option>
                            <Select.Option value="218">(+218) - Libya</Select.Option>
                            <Select.Option value="423">(+423) - Liechtenstein</Select.Option>
                            <Select.Option value="370">(+370) - Lithuania</Select.Option>
                            <Select.Option value="352">(+352) - Luxembourg</Select.Option>
                            <Select.Option value="853">(+853) - Macau</Select.Option>
                            <Select.Option value="389">(+389) - Republic of Macedonia</Select.Option>
                            <Select.Option value="261">(+261) - Madagascar</Select.Option>
                            <Select.Option value="265">(+265) - Malawi</Select.Option>
                            <Select.Option value="60">(+60) - Malaysia</Select.Option>
                            <Select.Option value="960">(+960) - Maldives</Select.Option>
                            <Select.Option value="223">(+223) - Mali</Select.Option>
                            <Select.Option value="356">(+356) - Malta</Select.Option>
                            <Select.Option value="1204">(+1204) - Manitoba</Select.Option>
                            <Select.Option value="692">(+692) - Marshall Islands</Select.Option>
                            <Select.Option value="596">(+596) - Martinique</Select.Option>
                            <Select.Option value="222">(+222) - Mauritania</Select.Option>
                            <Select.Option value="230">(+230) - Mauritius</Select.Option>
                            <Select.Option value="52">(+52) - Mexico</Select.Option>
                            <Select.Option value="691">(+691) - Federated States of Micronesia</Select.Option>
                            <Select.Option value="373">(+373) - Moldova</Select.Option>
                            <Select.Option value="377">(+377) - Monaco</Select.Option>
                            <Select.Option value="976">(+976) - Mongolia</Select.Option>
                            <Select.Option value="382">(+382) - Montenegro</Select.Option>
                            <Select.Option value="1664">(+1664) - Montserrat</Select.Option>
                            <Select.Option value="212">(+212) - Morocco</Select.Option>
                            <Select.Option value="258">(+258) - Mozambique</Select.Option>
                            <Select.Option value="95">(+95) - Myanmar</Select.Option>
                            <Select.Option value="264">(+264) - Namibia</Select.Option>
                            <Select.Option value="674">(+674) - Nauru</Select.Option>
                            <Select.Option value="31">(+31) - Netherlands</Select.Option>
                            <Select.Option value="599">(+599) - Netherlands Antilles</Select.Option>
                            <Select.Option value="977">(+977) - Nepal</Select.Option>
                            <Select.Option value="1506">(+1506) - New Brunswick</Select.Option>
                            <Select.Option value="687">(+687) - New Caledonia</Select.Option>
                            <Select.Option value="64">(+64) - New Zealand</Select.Option>
                            <Select.Option value="1709">(+1709) - Newfoundland</Select.Option>
                            <Select.Option value="505">(+505) - Nicaragua</Select.Option>
                            <Select.Option value="227">(+227) - Niger</Select.Option>
                            <Select.Option value="234">(+234) - Nigeria</Select.Option>
                            <Select.Option value="683">(+683) - Niue</Select.Option>
                            <Select.Option value="6723">(+6723) - Norfolk Island</Select.Option>
                            <Select.Option value="850">(+850) - North Korea</Select.Option>
                            <Select.Option value="1670">(+1670) - Northern Mariana Islands</Select.Option>
                            <Select.Option value="1867">(+1867) - Northwest Territories</Select.Option>
                            <Select.Option value="47">(+47) - Norway</Select.Option>
                            <Select.Option value="1902">(+1902) - Nova Scotia</Select.Option>
                            <Select.Option value="968">(+968) - Oman</Select.Option>
                            <Select.Option value="1416">(+1416) - Ontario</Select.Option>
                            <Select.Option value="1519">(+1519) - Ontario</Select.Option>
                            <Select.Option value="1613">(+1613) - Ontario</Select.Option>
                            <Select.Option value="1647">(+1647) - Ontario</Select.Option>
                            <Select.Option value="1705">(+1705) - Ontario</Select.Option>
                            <Select.Option value="1807">(+1807) - Ontario</Select.Option>
                            <Select.Option value="1905">(+1905) - Ontario</Select.Option>
                            <Select.Option value="92">(+92) - Pakistan</Select.Option>
                            <Select.Option value="680">(+680) - Palau</Select.Option>
                            <Select.Option value="507">(+507) - Panama</Select.Option>
                            <Select.Option value="675">(+675) - Papua New Guinea</Select.Option>
                            <Select.Option value="595">(+595) - Paraguay</Select.Option>
                            <Select.Option value="51">(+51) - Peru</Select.Option>
                            <Select.Option value="63">(+63) - Philippines</Select.Option>
                            <Select.Option value="48">(+48) - Poland</Select.Option>
                            <Select.Option value="351">(+351) - Portugal</Select.Option>
                            <Select.Option value="974">(+974) - Qatar</Select.Option>
                            <Select.Option value="1418">(+1418) - Quebec</Select.Option>
                            <Select.Option value="1450">(+1450) - Quebec</Select.Option>
                            <Select.Option value="1514">(+1514) - Quebec</Select.Option>
                            <Select.Option value="1819">(+1819) - Quebec</Select.Option>
                            <Select.Option value="262">(+262) - Reunion</Select.Option>
                            <Select.Option value="40">(+40) - Romania</Select.Option>
                            <Select.Option value="7">(+7) - Russia</Select.Option>
                            <Select.Option value="250">(+250) - Rwanda</Select.Option>
                            <Select.Option value="290">(+290) - Saint Helena</Select.Option>
                            <Select.Option value="1869">(+1869) - Saint Kitts and Nevis</Select.Option>
                            <Select.Option value="1758">(+1758) - Saint Lucia</Select.Option>
                            <Select.Option value="590">(+590) - Saint Martin (French side)</Select.Option>
                            <Select.Option value="508">(+508) - Saint Pierre and Miquelon</Select.Option>
                            <Select.Option value="685">(+685) - Samoa</Select.Option>
                            <Select.Option value="239">(+239) - Sao Tome and Principe</Select.Option>
                            <Select.Option value="1306">(+1306) - Saskatchewan</Select.Option>
                            <Select.Option value="966">(+966) - Saudi Arabia</Select.Option>
                            <Select.Option value="221">(+221) - Senegal</Select.Option>
                            <Select.Option value="381">(+381) - Serbia</Select.Option>
                            <Select.Option value="248">(+248) - Seychelles</Select.Option>
                            <Select.Option value="232">(+232) - Sierra Leone</Select.Option>
                            <Select.Option value="65">(+65) - Singapore</Select.Option>
                            <Select.Option value="421">(+421) - Slovakia</Select.Option>
                            <Select.Option value="386">(+386) - Slovenia</Select.Option>
                            <Select.Option value="677">(+677) - Solomon Islands</Select.Option>
                            <Select.Option value="252">(+252) - Somalia</Select.Option>
                            <Select.Option value="27">(+27) - South Africa</Select.Option>
                            <Select.Option value="82">(+82) - South Korea</Select.Option>
                            <Select.Option value="211">(+211) - South Sudan</Select.Option>
                            <Select.Option value="94">(+94) - Sri Lanka</Select.Option>
                            <Select.Option value="249">(+249) - Sudan</Select.Option>
                            <Select.Option value="597">(+597) - Suriname</Select.Option>
                            <Select.Option value="268">(+268) - Swaziland</Select.Option>
                            <Select.Option value="46">(+46) - Sweden</Select.Option>
                            <Select.Option value="41">(+41) - Switzerland</Select.Option>
                            <Select.Option value="963">(+963) - Syria</Select.Option>
                            <Select.Option value="886">(+886) - Taiwan</Select.Option>
                            <Select.Option value="992">(+992) - Tajikistan</Select.Option>
                            <Select.Option value="255">(+255) - Tanzania</Select.Option>
                            <Select.Option value="66">(+66) - Thailand</Select.Option>
                            <Select.Option value="228">(+228) - Togo</Select.Option>
                            <Select.Option value="690">(+690) - Tokelau</Select.Option>
                            <Select.Option value="676">(+676) - Tonga</Select.Option>
                            <Select.Option value="1868">(+1868) - Trinidad and Tobago</Select.Option>
                            <Select.Option value="216">(+216) - Tunisia</Select.Option>
                            <Select.Option value="90">(+90) - Turkey</Select.Option>
                            <Select.Option value="993">(+993) - Turkmenistan</Select.Option>
                            <Select.Option value="1649">(+1649) - Turks and Caicos Islands</Select.Option>
                            <Select.Option value="688">(+688) - Tuvalu</Select.Option>
                            <Select.Option value="256">(+256) - Uganda</Select.Option>
                            <Select.Option value="380">(+380) - Ukraine</Select.Option>
                            <Select.Option value="971">(+971) - United Arab Emirates</Select.Option>
                            <Select.Option value="44">(+44) - United Kingdom</Select.Option>
                            <Select.Option value="1">(+1) - United States of America</Select.Option>
                            <Select.Option value="598">(+598) - Uruguay</Select.Option>
                            <Select.Option value="998">(+998) - Uzbekistan</Select.Option>
                            <Select.Option value="678">(+678) - Vanuatu</Select.Option>
                            <Select.Option value="58">(+58) - Venezuela</Select.Option>
                            <Select.Option value="84">(+84) - Vietnam</Select.Option>
                            <Select.Option value="1340">(+1340) - U.S. Virgin Islands</Select.Option>
                            <Select.Option value="681">(+681) - Wallis and Futuna</Select.Option>
                            <Select.Option value="970">(+970) - West Bank</Select.Option>
                            <Select.Option value="967">(+967) - Yemen</Select.Option>
                            <Select.Option value="260">(+260) - Zambia</Select.Option>
                            <Select.Option value="263">(+263) - Zimbabwe</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Typography className='size12' style={{ fontWeight: 700, textAlign:'left', paddingLeft:'10px' }}>
                        {certificates[this.props.language].phone.toUpperCase()}</Typography>
                    <Form.Item name={"telefono"} rules={[{ required: true}]} >
                        <Input style={{margin:'11px 0 11px 0', marginLeft:'10px'}} suffix={<img alt='' src={iconPhone} />} maxLength={50} />
                    </Form.Item>
                </Col>
            </Row>
            </>}
            { current===1 && <><Row>
                <Col style={{alignSelf:'center'}} span={4}>
                    <img onClick={()=>this.setState({current: this.state.current-1})} src={arrowLeft} alt={''}/>
                </Col>
                <Col span={16}>
                    <Typography style={{marginBottom:'0'}} className={'text-title size26 color-black'}>
                        {certificates[this.props.language].addressTitle}</Typography>
                </Col>
            </Row>
                <Row style={{marginTop:'20px'}} justify={'center'}>
                    <Col span={20}>
                        <Typography className='size12' style={{ fontWeight: 700, textAlign:'left' }}>
                            {certificates[this.props.language].country.toUpperCase()}</Typography>
                        <Form.Item name={"country"} rules={[{ required: true}]} >
                            <Select size={'large'} style={{width:'100%',margin:'11px 0 11px 0', textAlign:'left'}}>
                                <Select.Option value="AF">Afghanistan</Select.Option>
                                <Select.Option value="AX">Ã…land Islands</Select.Option>
                                <Select.Option value="AL">Albania</Select.Option>
                                <Select.Option value="DZ">Algeria</Select.Option>
                                <Select.Option value="AS">American Samoa</Select.Option>
                                <Select.Option value="AD">Andorra</Select.Option>
                                <Select.Option value="AO">Angola</Select.Option>
                                <Select.Option value="AI">Anguilla</Select.Option>
                                <Select.Option value="AQ">Antarctica</Select.Option>
                                <Select.Option value="AG">AntiguaandBarbuda</Select.Option>
                                <Select.Option value="AR">Argentina</Select.Option>
                                <Select.Option value="AM">Armenia</Select.Option>
                                <Select.Option value="AW">Aruba</Select.Option>
                                <Select.Option value="AU">Australia</Select.Option>
                                <Select.Option value="AT">Austria</Select.Option>
                                <Select.Option value="AZ">Azerbaijan</Select.Option>
                                <Select.Option value="BS">Bahamas</Select.Option>
                                <Select.Option value="BH">Bahrain</Select.Option>
                                <Select.Option value="BD">Bangladesh</Select.Option>
                                <Select.Option value="BB">Barbados</Select.Option>
                                <Select.Option value="BY">Belarus</Select.Option>
                                <Select.Option value="BE">Belgium</Select.Option>
                                <Select.Option value="BZ">Belize</Select.Option>
                                <Select.Option value="BJ">Benin</Select.Option>
                                <Select.Option value="BM">Bermuda</Select.Option>
                                <Select.Option value="BT">Bhutan</Select.Option>
                                <Select.Option value="BO">Bolivia</Select.Option>
                                <Select.Option value="BA">BosniaandHerzegovina</Select.Option>
                                <Select.Option value="BW">Botswana</Select.Option>
                                <Select.Option value="BV">BouvetIsland</Select.Option>
                                <Select.Option value="BR">Brazil</Select.Option>
                                <Select.Option value="IO">BritishIndianOceanTerritory</Select.Option>
                                <Select.Option value="BN">BruneiDarussalam</Select.Option>
                                <Select.Option value="BG">Bulgaria</Select.Option>
                                <Select.Option value="BF">BurkinaFaso</Select.Option>
                                <Select.Option value="BI">Burundi</Select.Option>
                                <Select.Option value="KH">Cambodia</Select.Option>
                                <Select.Option value="CM">Cameroon</Select.Option>
                                <Select.Option value="CA">Canada</Select.Option>
                                <Select.Option value="CV">CapeVerde</Select.Option>
                                <Select.Option value="KY">CaymanIslands</Select.Option>
                                <Select.Option value="CF">CentralAfricanRepublic</Select.Option>
                                <Select.Option value="TD">Chad</Select.Option>
                                <Select.Option value="CL">Chile</Select.Option>
                                <Select.Option value="CN">China</Select.Option>
                                <Select.Option value="CX">ChristmasIsland</Select.Option>
                                <Select.Option value="CC">Cocos(Keeling)Islands</Select.Option>
                                <Select.Option selected="" value="CO">Colombia</Select.Option>
                                <Select.Option value="KM">Comoros</Select.Option>
                                <Select.Option value="CG">Congo</Select.Option>
                                <Select.Option value="CD">Congo,TheDemocraticRepublicofthe</Select.Option>
                                <Select.Option value="CK">CookIslands</Select.Option>
                                <Select.Option value="CR">CostaRica</Select.Option>
                                <Select.Option value="CI">Cote DIvoire</Select.Option>
                                <Select.Option value="HR">Croatia</Select.Option>
                                <Select.Option value="CU">Cuba</Select.Option>
                                <Select.Option value="CY">Cyprus</Select.Option>
                                <Select.Option value="CZ">CzechRepublic</Select.Option>
                                <Select.Option value="DK">Denmark</Select.Option>
                                <Select.Option value="DJ">Djibouti</Select.Option>
                                <Select.Option value="DM">Dominica</Select.Option>
                                <Select.Option value="DO">DominicanRepublic</Select.Option>
                                <Select.Option value="EC">Ecuador</Select.Option>
                                <Select.Option value="EG">Egypt</Select.Option>
                                <Select.Option value="SV">ElSalvador</Select.Option>
                                <Select.Option value="GQ">EquatorialGuinea</Select.Option>
                                <Select.Option value="ER">Eritrea</Select.Option>
                                <Select.Option value="ES">España</Select.Option>
                                <Select.Option value="EE">Estonia</Select.Option>
                                <Select.Option value="ET">Ethiopia</Select.Option>
                                <Select.Option value="FK">FalklandIslands(Malvinas)</Select.Option>
                                <Select.Option value="FO">FaroeIslands</Select.Option>
                                <Select.Option value="FJ">Fiji</Select.Option>
                                <Select.Option value="FI">Finland</Select.Option>
                                <Select.Option value="FR">France</Select.Option>
                                <Select.Option value="GF">FrenchGuiana</Select.Option>
                                <Select.Option value="PF">FrenchPolynesia</Select.Option>
                                <Select.Option value="TF">FrenchSouthernTerritories</Select.Option>
                                <Select.Option value="GA">Gabon</Select.Option>
                                <Select.Option value="GM">Gambia</Select.Option>
                                <Select.Option value="GE">Georgia</Select.Option>
                                <Select.Option value="DE">Germany</Select.Option>
                                <Select.Option value="GH">Ghana</Select.Option>
                                <Select.Option value="GI">Gibraltar</Select.Option>
                                <Select.Option value="GR">Greece</Select.Option>
                                <Select.Option value="GL">Greenland</Select.Option>
                                <Select.Option value="GD">Grenada</Select.Option>
                                <Select.Option value="GP">Guadeloupe</Select.Option>
                                <Select.Option value="GU">Guam</Select.Option>
                                <Select.Option value="GT">Guatemala</Select.Option>
                                <Select.Option value="GG">Guernsey</Select.Option>
                                <Select.Option value="GN">Guinea</Select.Option>
                                <Select.Option value="GW">Guinea-Bissau</Select.Option>
                                <Select.Option value="GY">Guyana</Select.Option>
                                <Select.Option value="HT">Haiti</Select.Option>
                                <Select.Option value="HM">HeardIslandandMcdonaldIslands</Select.Option>
                                <Select.Option value="VA">HolySee(VaticanCityState)</Select.Option>
                                <Select.Option value="HN">Honduras</Select.Option>
                                <Select.Option value="HK">HongKong</Select.Option>
                                <Select.Option value="HU">Hungary</Select.Option>
                                <Select.Option value="IS">Iceland</Select.Option>
                                <Select.Option value="IN">India</Select.Option>
                                <Select.Option value="ID">Indonesia</Select.Option>
                                <Select.Option value="IR">Iran,IslamicRepublicOf</Select.Option>
                                <Select.Option value="IQ">Iraq</Select.Option>
                                <Select.Option value="IE">Ireland</Select.Option>
                                <Select.Option value="IM">IsleofMan</Select.Option>
                                <Select.Option value="IL">Israel</Select.Option>
                                <Select.Option value="IT">Italy</Select.Option>
                                <Select.Option value="JM">Jamaica</Select.Option>
                                <Select.Option value="JP">Japan</Select.Option>
                                <Select.Option value="JE">Jersey</Select.Option>
                                <Select.Option value="JO">Jordan</Select.Option>
                                <Select.Option value="KZ">Kazakhstan</Select.Option>
                                <Select.Option value="KE">Kenya</Select.Option>
                                <Select.Option value="KI">Kiribati</Select.Option>
                                <Select.Option value="KP">Korea,DemocraticPeopleSRepublicof</Select.Option>
                                <Select.Option value="KR">Korea,Republicof</Select.Option>
                                <Select.Option value="KW">Kuwait</Select.Option>
                                <Select.Option value="KG">Kyrgyzstan</Select.Option>
                                <Select.Option value="LA">LaoPeopleSDemocraticRepublic</Select.Option>
                                <Select.Option value="LV">Latvia</Select.Option>
                                <Select.Option value="LB">Lebanon</Select.Option>
                                <Select.Option value="LS">Lesotho</Select.Option>
                                <Select.Option value="LR">Liberia</Select.Option>
                                <Select.Option value="LY">LibyanArabJamahiriya</Select.Option>
                                <Select.Option value="LI">Liechtenstein</Select.Option>
                                <Select.Option value="LT">Lithuania</Select.Option>
                                <Select.Option value="LU">Luxembourg</Select.Option>
                                <Select.Option value="MO">Macao</Select.Option>
                                <Select.Option value="MK">Macedonia,TheFormerYugoslavRepublicof</Select.Option>
                                <Select.Option value="MG">Madagascar</Select.Option>
                                <Select.Option value="MW">Malawi</Select.Option>
                                <Select.Option value="MY">Malaysia</Select.Option>
                                <Select.Option value="MV">Maldives</Select.Option>
                                <Select.Option value="ML">Mali</Select.Option>
                                <Select.Option value="MT">Malta</Select.Option>
                                <Select.Option value="MH">MarshallIslands</Select.Option>
                                <Select.Option value="MQ">Martinique</Select.Option>
                                <Select.Option value="MR">Mauritania</Select.Option>
                                <Select.Option value="MU">Mauritius</Select.Option>
                                <Select.Option value="YT">Mayotte</Select.Option>
                                <Select.Option value="MX">Mexico</Select.Option>
                                <Select.Option value="FM">Micronesia,FederatedStatesof</Select.Option>
                                <Select.Option value="MD">Moldova,Republicof</Select.Option>
                                <Select.Option value="MC">Monaco</Select.Option>
                                <Select.Option value="MN">Mongolia</Select.Option>
                                <Select.Option value="MS">Montserrat</Select.Option>
                                <Select.Option value="MA">Morocco</Select.Option>
                                <Select.Option value="MZ">Mozambique</Select.Option>
                                <Select.Option value="MM">Myanmar</Select.Option>
                                <Select.Option value="NA">Namibia</Select.Option>
                                <Select.Option value="NR">Nauru</Select.Option>
                                <Select.Option value="NP">Nepal</Select.Option>
                                <Select.Option value="NL">Netherlands</Select.Option>
                                <Select.Option value="AN">NetherlandsAntilles</Select.Option>
                                <Select.Option value="NC">NewCaledonia</Select.Option>
                                <Select.Option value="NZ">NewZealand</Select.Option>
                                <Select.Option value="NI">Nicaragua</Select.Option>
                                <Select.Option value="NE">Niger</Select.Option>
                                <Select.Option value="NG">Nigeria</Select.Option>
                                <Select.Option value="NU">Niue</Select.Option>
                                <Select.Option value="NF">NorfolkIsland</Select.Option>
                                <Select.Option value="MP">NorthernMarianaIslands</Select.Option>
                                <Select.Option value="NO">Norway</Select.Option>
                                <Select.Option value="OM">Oman</Select.Option>
                                <Select.Option value="PK">Pakistan</Select.Option>
                                <Select.Option value="PW">Palau</Select.Option>
                                <Select.Option value="PS">PalestinianTerritory,Occupied</Select.Option>
                                <Select.Option value="PA">Panama</Select.Option>
                                <Select.Option value="PG">PapuaNewGuinea</Select.Option>
                                <Select.Option value="PY">Paraguay</Select.Option>
                                <Select.Option value="PE">Peru</Select.Option>
                                <Select.Option value="PH">Philippines</Select.Option>
                                <Select.Option value="PN">Pitcairn</Select.Option>
                                <Select.Option value="PL">Poland</Select.Option>
                                <Select.Option value="PT">Portugal</Select.Option>
                                <Select.Option value="PR">PuertoRico</Select.Option>
                                <Select.Option value="QA">Qatar</Select.Option>
                                <Select.Option value="RE">Reunion</Select.Option>
                                <Select.Option value="RO">Romania</Select.Option>
                                <Select.Option value="RU">RussianFederation</Select.Option>
                                <Select.Option value="RW">RWANDA</Select.Option>
                                <Select.Option value="SH">SaintHelena</Select.Option>
                                <Select.Option value="KN">SaintKittsandNevis</Select.Option>
                                <Select.Option value="LC">SaintLucia</Select.Option>
                                <Select.Option value="PM">SaintPierreandMiquelon</Select.Option>
                                <Select.Option value="VC">SaintVincentandtheGrenadines</Select.Option>
                                <Select.Option value="WS">Samoa</Select.Option>
                                <Select.Option value="SM">SanMarino</Select.Option>
                                <Select.Option value="ST">SaoTomeandPrincipe</Select.Option>
                                <Select.Option value="SA">SaudiArabia</Select.Option>
                                <Select.Option value="SN">Senegal</Select.Option>
                                <Select.Option value="CS">SerbiaandMontenegro</Select.Option>
                                <Select.Option value="SC">Seychelles</Select.Option>
                                <Select.Option value="SL">SierraLeone</Select.Option>
                                <Select.Option value="SG">Singapore</Select.Option>
                                <Select.Option value="SK">Slovakia</Select.Option>
                                <Select.Option value="SI">Slovenia</Select.Option>
                                <Select.Option value="SB">SolomonIslands</Select.Option>
                                <Select.Option value="SO">Somalia</Select.Option>
                                <Select.Option value="ZA">SouthAfrica</Select.Option>
                                <Select.Option value="GS">SouthGeorgiaandtheSouthSandwichIslands</Select.Option>
                                <Select.Option value="LK">SriLanka</Select.Option>
                                <Select.Option value="SD">Sudan</Select.Option>
                                <Select.Option value="SR">Suriname</Select.Option>
                                <Select.Option value="SJ">SvalbardandJanMayen</Select.Option>
                                <Select.Option value="SZ">Swaziland</Select.Option>
                                <Select.Option value="SE">Sweden</Select.Option>
                                <Select.Option value="CH">Switzerland</Select.Option>
                                <Select.Option value="SY">SyrianArabRepublic</Select.Option>
                                <Select.Option value="TW">Taiwan,ProvinceofChina</Select.Option>
                                <Select.Option value="TJ">Tajikistan</Select.Option>
                                <Select.Option value="TZ">Tanzania,UnitedRepublicof</Select.Option>
                                <Select.Option value="TH">Thailand</Select.Option>
                                <Select.Option value="TL">Timor-Leste</Select.Option>
                                <Select.Option value="TG">Togo</Select.Option>
                                <Select.Option value="TK">Tokelau</Select.Option>
                                <Select.Option value="TO">Tonga</Select.Option>
                                <Select.Option value="TT">TrinidadandTobago</Select.Option>
                                <Select.Option value="TN">Tunisia</Select.Option>
                                <Select.Option value="TR">Turkey</Select.Option>
                                <Select.Option value="TM">Turkmenistan</Select.Option>
                                <Select.Option value="TC">TurksandCaicosIslands</Select.Option>
                                <Select.Option value="TV">Tuvalu</Select.Option>
                                <Select.Option value="UG">Uganda</Select.Option>
                                <Select.Option value="UA">Ukraine</Select.Option>
                                <Select.Option value="AE">UnitedArabEmirates</Select.Option>
                                <Select.Option value="GB">UnitedKingdom</Select.Option>
                                <Select.Option value="US">UnitedStates</Select.Option>
                                <Select.Option value="UM">UnitedStatesMinorOutlyingIslands</Select.Option>
                                <Select.Option value="UY">Uruguay</Select.Option>
                                <Select.Option value="UZ">Uzbekistan</Select.Option>
                                <Select.Option value="VU">Vanuatu</Select.Option>
                                <Select.Option value="VE">Venezuela</Select.Option>
                                <Select.Option value="VN">VietNam</Select.Option>
                                <Select.Option value="VG">VirginIslands,British</Select.Option>
                                <Select.Option value="VI">VirginIslands,U.S.</Select.Option>
                                <Select.Option value="WF">WallisandFutuna</Select.Option>
                                <Select.Option value="EH">WesternSahara</Select.Option>
                                <Select.Option value="YE">Yemen</Select.Option>
                                <Select.Option value="ZM">Zambia</Select.Option>
                                <Select.Option value="ZW">Zimbabwe</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col span={20}>
                        <Typography className='size12' style={{ fontWeight: 700, textAlign:'left' }}>
                            {certificates[this.props.language].department.toUpperCase()}</Typography>
                        <Form.Item name={"state"} rules={[{ required: true}]} >
                            <Input style={{margin:'11px 0 11px 0'}} maxLength={50} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col span={20}>
                        <Typography className='size12' style={{ fontWeight: 700, textAlign:'left' }}>
                            {certificates[this.props.language].city.toUpperCase()}</Typography>
                        <Form.Item name={"city"} rules={[{ required: true}]} >
                            <Input style={{margin:'11px 0 11px 0'}} maxLength={50} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col span={20}>
                        <Typography className='size12' style={{ fontWeight: 700, textAlign:'left' }}>
                            {certificates[this.props.language].address.toUpperCase()}</Typography>
                        <Form.Item name={"direccion"} rules={[{ required: true}]} >
                            <Input style={{margin:'11px 0 11px 0'}} maxLength={50} />
                        </Form.Item>
                    </Col>
                </Row>
            </>}
            { current===2 && <><Row>
                <Col style={{alignSelf:'center'}} span={4}>
                    <img onClick={()=>this.setState({current: this.state.current-1})} src={arrowLeft} alt={''}/>
                </Col>
                <Col span={16}>
                    <Typography style={{marginBottom:'0'}} className={'text-title size26 color-black'}>
                        {certificates[this.props.language].companyTitle}</Typography>
                </Col>
            </Row>
                <Row style={{marginTop:'20px'}} justify={'center'}>
                    <Col span={20}>
                        <Typography className='size12' style={{ fontWeight: 700, textAlign:'left' }}>
                            {certificates[this.props.language].company_name.toUpperCase()}</Typography>
                        <Form.Item name={"organization"} rules={[{ required: true}]} >
                            <Input style={{margin:'11px 0 11px 0'}} maxLength={50} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col span={20}>
                        <Typography className='size12' style={{ fontWeight: 700, textAlign:'left' }}>
                            {certificates[this.props.language].company_area.toUpperCase()}</Typography>
                        <Form.Item name={"organizationalUnit"} rules={[{ required: true}]} >
                            <Input style={{margin:'11px 0 11px 0'}} maxLength={50} />
                        </Form.Item>
                    </Col>
                </Row>
            </>}
            {current<3 && <>
                <Col style={{marginTop:'40px'}} span={24}>
                    <Button style={{ width: '231px' }} className='size12' type={"primary light-primary rounded-sm"} htmlType="submit">
                        {auth[this.props.language].nextbtn.toUpperCase()}
                    </Button>
                </Col>
                <Col span={24}>
                    <Button style={{ minHeight: '48px', width: '138px', marginBottom: 0, border:'none' }} type={'light-primary rounded-sm'}
                            onClick={() => this.props.dispatch(push(HOME_ROUTE))}>
                        {auth[this.props.language].cancel}
                    </Button>
                </Col>
            </>}
            {current>=3 && <>
                <Row justify={'center'}>
                    <Col style={{textAlign:'center', marginTop:'70px'}} span={24}>
                        <img style={{maxWidth:'300px'}} alt={''} src={checkMail}/>
                    </Col>
                    <Col style={{textAlign:'center', marginTop:'37px'}} span={24}>
                        <Typography style={{marginBottom:'14px'}} className={'text-title size15 color-black'}>{certificates[this.props.language].checkMailTitle}</Typography>
                    </Col>
                    <Col style={{textAlign:'center', maxWidth:'488px'}} span={24}>
                        <Typography style={{marginBottom:'67px'}}>{certificates[this.props.language].checkMailDesc}</Typography>
                    </Col>
                    <div style={{textAlign:'center', marginTop: isMobile?0:'275px', display: 'flex', width: '348px'}}>
                        <Typography className='size15 color-black'>
                                {auth[this.props.language].signinlblcert}
                        </Typography>
                        <Typography className={'size15 color-black text-link'} onClick={() => this.props.dispatch(push('/'))} style={{ paddingLeft: '2px', marginBottom:43 }}>
                            {auth[this.props.language].signinbtn}</Typography>
                    </div>
                </Row>
            </>}
        </Form>;
    }

    render()
    {
        const base64LogoDark = getCustomLogos().base64LogoDark;
        const isMobile = this.props.dimApp.width <= sGrid;
        return <Row style={{height:'100%'}}>
            {!isMobile &&<Col style={{backgroundColor:'#F3F7F9', paddingTop:'120px'}} span={isMobile?24:14}>
                <Row>
                    <Col style={{textAlign:'center'}} span={12}>
                        <img style={{maxWidth:'300px'}} alt={''} src={base64LogoDark}/>
                        <Col style={{textAlign:'center', marginTop:'67px'}} span={24}><img alt={''} src={newCertificate}/></Col>
                        <Col style={{textAlign:'center', marginTop:'37px'}} span={24}>
                            <Typography style={{marginBottom:'14px'}} className={'text-title size15 color-black'}>{certificates[this.props.language].getCertLabel}</Typography>
                        </Col>
                        <Col style={{textAlign:'center'}} span={24}>
                            <Typography style={{marginBottom:'67px'}}>{certificates[this.props.language].getCertDescLabel}</Typography>
                        </Col>
                    </Col>
                    <Col span={3}>
                    </Col>
                    <Col span={6}>
                        <Steps direction={'vertical'} current={this.state.current}>
                            <Steps.Step title={certificates[this.props.language].generalTitle} />
                            <Steps.Step title={certificates[this.props.language].addressTitle} />
                            <Steps.Step title={certificates[this.props.language].companyTitle} />
                            <Steps.Step title={certificates[this.props.language].confirmationTitle} />
                        </Steps>
                    </Col>
                    <Col span={3}>
                    </Col>
                </Row>

            </Col>}
            <Col style={{textAlign:'center', paddingTop:'120px'}} span={isMobile?24:10}>
                {this.renderForm()}
            </Col>

        </Row>;
    }

}
const mapStateToProps = state => {
    return {
        language: state.auth.language,
        dimApp: state.locate.dimensionsApp

    };
};

export default PrivateRoute(connect(mapStateToProps,{hideAllMenu, hideHeader, netcoPKIRegistration,logoutUser,
hideFooter})(NewCertificatePersonal));