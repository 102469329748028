import React from 'react';
import {connect} from 'react-redux'
import NewLogin from '../Auth/NewLogIn';
import infoShow from "../../actions/info/infoShow";

class Root extends React.Component {

    componentDidMount()
    {
        if (this.props.hash.includes('unauthorized'))
            this.props.infoShow({
                message: 'No esta autorizado',
                type: 'error'
            });

    }

    render()
    {
        return <NewLogin/>;
    }
}


const mapStateToProps = state => {
    return {
        hash: state.router.location.hash
    };
};

export default connect(mapStateToProps, {infoShow})(Root);
