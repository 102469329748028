import React from "react";
import { usePromiseTracker } from "react-promise-tracker";

// import 'antd/lib/spin/style/css';


import './Loader.css'
//import '../../resources/css/Loader.css';
import auth from "../../../const/auth";
import { SettingTwoTone } from '@ant-design/icons';
import { Spin } from 'antd';
import { getCustomColors } from "../../Helpers/utils";

export const Loading= props  =>
{
    const colorPrimary = getCustomColors().colorPrimary
    const antIcon = <SettingTwoTone twoToneColor={colorPrimary} style={{ fontSize: 24}} spin />;
    const { promiseInProgress } = usePromiseTracker();
    return promiseInProgress &&
        <div className="loading-container">
            <div id="circle">
                <Spin className="loading-spin" indicator={antIcon} tip={auth[props.language].loading}>
                    <div className="content" />
                </Spin>
            </div>
        </div>;
};