import netcoServer from '../../apis/netcoServer';
import {CLEAR_PDF_TEMPLATE, GET_PDF_TEMPLATES_INFORMATION} from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";

const getPDFTemplatesInformation= (formValues) => async (dispatch) => {
  dispatch({type:CLEAR_PDF_TEMPLATE});
  if(!formValues)
    formValues={};
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getPDFTemplatesInformation', formValues));
  handleResponse(dispatch, response, GET_PDF_TEMPLATES_INFORMATION, onSuccessPayload);
};

const onSuccessPayload = data => {
  return {
    templateList: {
      entries: data.result.templates,
      parentId: data.result.parentId,
      total: data.result.total
    }
  };
};
export default getPDFTemplatesInformation;