
const layout = {
  es: {
    header_shortcut: 'Accesos Directos',
    header_sign: 'Firmar',
    header_load: 'Ayuda',
    header_certificate: 'Certificado:',
    header_profile: 'Perfil',
    header_configuration_sign: 'Configura tu firma',
    header_appconfiguration_sign: 'Configura tu aplicación',
    header_logOut: 'Salida segura',
    header_av: 'operaciones disponibles',
    docs_header_av: 'documentos disponibles',
    sider_signed_files: 'Mis Archivos',
    sider_certificate: 'Mis certificados',
    sider_sign: 'Firmar',
    sider_verify: 'Verificar firmas',
    sider_reports: 'Reportes',
    sider_templates: 'Mis Plantillas',
    sider_load: 'Enviar a firma',
    sider_admin: 'Configuración',
    sider_profile: 'Mi perfil',
    sider_ecom: 'Compras',
    sider_check_ecom: 'Consultar compras',
    sider_topup: 'Recargar Operaciones',
    sider_logout: 'Salir',
    sider_new_sign: 'Firmar',
    sider_button_sign: 'Empieza a firmar',
    footer:'Versión 3.1 - 2024 © Derechos Reservados - Desarrollado por',
    footer1:'Netco LTDA',
    sider_path: {
      '/home': 'Inicio',
      '/plantillas': 'Tus plantillas',
      '/verifications': 'Verificaciones',
      '/reports': 'Reportes',
      '/system': 'Estado del sistema',
      '/activate': 'Compras',
      '/ecommerce': 'Compras',
      '/checkecom': 'Compras',
      '/subscription': 'Compras',
      '/admin': 'Configuraciones',
      '/profile': 'Perfil',
      '/files/management': 'Firmados',
      '/certificado': 'Certificados',
      '/files/newpending':'Pendientes por firmar',
      '/files/workflow':'Enviados',
      '/signature/firmar':'Firma tus documentos.',
      '/locate':'Ubica la imagen de cada firmante'
    },
    sider_management:'Gestión de firmas'
  },
  en: {
    header_shortcut: 'Shortcuts',
    header_sign: 'Sign',
    header_load: 'Help',
    header_certificate: 'Certificate:',
    header_profile: 'Profile',
    header_configuration_sign: 'Configure your signature',
    header_appconfiguration_sign: 'Configure your app',
    header_logOut: 'Secure Logout',
    header_av: 'available operations',
    docs_header_av: 'available documents',
    sider_signed_files: 'Signed files',
    sider_certificate: 'Certificates',
    sider_sign: 'Sign',
    sider_verify: 'Verify',
    sider_reports: 'Reports',
    sider_templates: 'PDF Templates',
    sider_load: 'Send to sign',
    sider_admin: 'Admin',
    sider_profile: 'Profile',
    sider_ecom: 'Buy Signatures',
    sider_check_ecom: 'List Orders',
    sider_topup: 'Top Up Operations',
    sider_logout: 'Logout',
    sider_new_sign: 'New Sign',
    sider_button_sign: 'Start signing',
    footer:'Version 3.1 - 2024 © All Rights Reserved - Developed by',
    footer1:'Netco LTDA',
    sider_path: {
      '/home': 'Home',
      '/plantillas': 'Your templates',
      '/verifications': 'Verifications',
      '/reports': 'Reports',
      '/stateAPI': 'System status',
      '/system': 'System Status',
      '/activate': 'Shopping',
      '/ecommerce': 'Shopping',
      '/checkecom': 'Shopping',
      '/subscription': 'Shopping',
      '/admin': 'Settings',
      '/profile': 'Profile',
      '/files/management': 'Firmados',
      '/certificado': 'Certificates',
      '/files/newpending':'Pending sign',
      '/files/workflow':'Workflow',
      '/signature/signar':'Sign your files.'
    },
    sider_management:'Signatures'
  }
};
export default layout;
