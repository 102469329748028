import netcoServer from '../../apis/netcoServer';
import {GET_WORKFLOW_FILES_INFORMATION} from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";
import {fixSord} from "../../components/Helpers/utils";

const getWorkflowFilesInformation= (formData) => async (dispatch) => {
  let skip=formData.skip;
  const limit=formData.limit;
  if(skip && limit)
  {
    skip = (skip - 1) * limit;
    formData.skip=skip;
  }
  formData.sord=fixSord(formData.sord);
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getWorkflowFilesInformation', formData));
  handleResponse(dispatch, response, GET_WORKFLOW_FILES_INFORMATION, onSuccessPayload);
};

const onSuccessPayload = data => {
  return {
    workflowList: {
      entries: data.filesInfo.filesInfo,
      total:data.filesInfo.total
    }
  };
};
export default getWorkflowFilesInformation;