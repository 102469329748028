import React from 'react';

import { Form, Button, Col, Row, Table } from "antd";
import { Field, reduxForm, reset } from "redux-form";
import { CreateTextField } from "../../Helpers/Input/InputCreator";
import admin from "../../../const/admin";
import general from "../../../const/general";
import { Typography } from 'antd';
import TagLabel from '../../Helpers/TagLabel';

const DeleteNotificationsForm = (props) => {

    const cleanForm = () => {
        props.dispatch(reset('admin_delete_notifications_form'));
    }

    const columns = () => {
        return [{
            title: admin[props.language].id_column,
            dataIndex: 'id',
            className: 'mobile-show'
        }, {
            title: admin[props.language].buy_product,
            dataIndex: 'value',
            className: 'mobile-show',
            render: (text) => {
                return text?admin[props.language].deletedNotification:admin[props.language].noDeletedNotification
            }
        }, {
            title: admin[props.language].system_state,
            dataIndex: 'value',
            className: 'mobile-show',
            render: (text, record) => {
                return (
                    <TagLabel type={text?'Success':'Danger'} text={text.toString().toUpperCase()} inTable={true} />
                );
              },
        }];
    }

    const renderHeader = () => {
        return (
            <div style={{ height: 50 }}>
                <Button className={'float-right mt-10 mb-20'} onClick={() => props.cleanListNotifications(cleanForm)} type={'ghost'}>{general[props.language].clean}</Button>
            </div>
        );
    };

    return (
        <div>
            <Form initialValues={props.initialValues} onFinish={props.handleSubmit(props.deleteNotifications)} style={{ margin: '44px 52px' }}>
                <Typography className='text-title color-black'>{admin[props.language].deleteNotifications}</Typography>

                <Typography className='mt-10 mb-10'>{admin[props.language].deleteNotificationsDetail}</Typography>
                <Row gutter={16}>
                    <Col xs={24} md={20}>
                        <Typography className='text-title size12 mt-10 mb-10'>{admin[props.language].uids.toUpperCase()}</Typography>
                        <Field
                            name="ids"
                            component={CreateTextField}
                            placeholder={admin[props.language].uids}
                            hasFeedback />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button className={'float-right mt-10 mb-20'} htmlType={'submit'} type={'primary'}>{general[props.language].delete}</Button>
                    </Col>
                </Row>
                <Row>
                    {Object.keys(props.notificationsList).length !== 0 && <Table
                        columns={columns()}
                        dataSource={props.notificationsList}
                        title={() => renderHeader()}
                        size={'small'}
                        pagination={false}
                        rowKey={'id'}
                    />}
                </Row>
            </Form>
        </div>
    );
};

export default reduxForm({ form: 'admin_delete_notifications_form' })(DeleteNotificationsForm);
