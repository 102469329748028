import {Button, Card, Input, Row} from "antd";
import TitleAndInfo from "../Helpers/TitleAndInfo";
import React from "react";
import PrivateRoute from "../Root/PrivateRoute";
import {connect} from "react-redux";
import confirmWordpress from "../../actions/admin/confirmWordpress";
import {getValidMail} from "../Helpers/utils";
import getUserInfo from "../../actions/profile/getUserInfo";
import modalShow from "../../actions/modal/modalShow";
import modalDismiss from "../../actions/modal/modalDismiss";
import {MESSAGE_MODAL} from "../Feedback/Modal/types";
import admin from "../../const/admin";

class ConfirmWordPress extends React.Component
{
    state={userName:null};
    refreshTimer=null;

    setDefaultUser=()=>
    {
        const mail=getValidMail(this.props.user);
        if(mail)
            this.setState({userName:mail});
    };

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(prevProps.user!==this.props.user)
            this.setDefaultUser();
    }

    componentWillUnmount()
    {
        this.props.modalDismiss();
        if(this.refreshTimer)
            clearInterval(this.refreshTimer);
    }

    componentDidMount()
    {
        this.setDefaultUser();
    }

    showOK=()=>
    {
        this.props.modalShow({
            modalType: MESSAGE_MODAL,
            modalProps: {footer: null, title: admin[this.props.language].waitingConfirmTitle,maskClosable:false,closable:false},
            componentProps: {language:this.props.language, message:admin[this.props.language].waitingConfirmText}});
        this.refreshTimer=setInterval(()=>{
            this.props.getUserInfo();
        },5000);
        this.props.getUserInfo();
    };

    render()
    {
        return <Card size={'small'} bodyStyle={{paddingLeft:0}} bordered={false}>
            <TitleAndInfo info={admin[this.props.language].typeUserInfo}/>
            <Row justify={'center'}>
                <Input value={this.state.userName} onChange={(e)=>this.setState({userName:e.target.value})}
                       placeholder={admin[this.props.language].typeUserLabel} style={{width:'50%',textAlign:'center'}}/>
            </Row>
            <Row justify={'center'}>
                <Button style={{width:'100px',marginTop:'10px'}} type="primary light-primary rounded-sm"
                        onClick={()=>this.props.confirmWordpress({userName:this.state.userName},this.showOK,this.props.getUserInfo)}>
                    {admin[this.props.language].confirmBtn}
                </Button>
            </Row>
        </Card>
    }
}
const mapStateToProps = state => {
    return {
        language: state.auth.language,
        user:state.user
    };
};

export default PrivateRoute(connect(mapStateToProps, {confirmWordpress,getUserInfo, modalShow, modalDismiss})(ConfirmWordPress));