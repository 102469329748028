import netcoServer from '../../apis/netcoServer';
import {DELETE_PENDING_FILES} from "../types";
import handleResponse from "../helpers/handleResponse";
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import loadingCall from "../helpers/loadingCall";

const deletePendingFiles= (uid,comment=null,postActions=[],postCallBacks=[]) => async (dispatch) => {
  let formData = {uids: uid,comment:comment};
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/deletePendingFiles', formData));
  handleResponse(dispatch, response, DELETE_PENDING_FILES, onSuccessPayload, null, postActions,postCallBacks);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Archivos eliminados correctamente!');
};
export default deletePendingFiles;