import netcoServer from '../../apis/netcoServer';
import {UPDATE_WEBSERVICE_USER} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import modalDismiss from "../modal/modalDismiss";
import getBase64File from "../helpers/getBase64File";
import loadingCall from "../helpers/loadingCall";

const updateWebServiceUser= (formValues, file, callback) => async (dispatch) => {
  if(file !== null)
    formValues.base64File = await getBase64File(file);
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/updateWebserviceUser', formValues));
  handleResponse(dispatch, response, UPDATE_WEBSERVICE_USER, onSuccessPayload, null, [modalDismiss], [callback]);
};
const onSuccessPayload = () => {
  return infoPayload('success', 'IP autorizada actualizada satisfactoriamente!');
};
export default updateWebServiceUser;