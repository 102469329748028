import {GET_ADMIN_USER_INFO} from "../types";

const getUserInfo= (userName) => async (dispatch, getState) => {
  let payload = {
    reportList: {
      selectedEntry: userName === null ? {} : getState().reportList.entries.find(x => x.userName === userName)
    }
  };
  dispatch({type: GET_ADMIN_USER_INFO, payload: payload});
};
export default getUserInfo;