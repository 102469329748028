import netcoServer from '../../apis/netcoServer';
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import loadingCall from "../helpers/loadingCall";
import getUserInfo from "./getUserInfo";
import admin from "../../const/admin";
import { INFO_SHOW } from '../types';

const updateUserSettings= (formValues, callback) => async (dispatch, getState) => {
  const onSuccessPayload = () => {
      return infoPayload('success', admin[getState().auth.language].processok);
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/updateUserSettings', formValues));
  handleResponse(dispatch, response, INFO_SHOW, onSuccessPayload, null, [getUserInfo], [callback]);
};
export default updateUserSettings;