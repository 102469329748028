import React from "react";
import {Modal} from "antd";
import profile from "../../const/profile";
import certificates from "../../const/certificates";

const infoKey = props => {
  return (
    (<Modal open={props.modalType !== null} width={400} {...props.modalProps}>
      <div className={'profile-info'}>
        <h3 style={{fontWeight:700}}>{certificates[props.language].subjectData}</h3>
        <p>{props.infoKey.subject?props.infoKey.subject:'.'}</p>
        <h3 style={{fontWeight:700}}>{certificates[props.language].issuerData} </h3>
        <p>{props.infoKey.issuer?props.infoKey.issuer:'.'}</p>
        <h3 style={{fontWeight:700}}>{certificates[props.language].certificateData}</h3>
        <h4 style={{fontWeight:700}}>{profile[props.language].serial}</h4>
        <p>{props.infoKey.serial?props.infoKey.serial:'.'}</p>
        <h4 style={{fontWeight:700}}>{certificates[props.language].since}</h4>
        <p>{props.infoKey.notBefore?props.infoKey.notBefore:'.'}</p>
        <h4 style={{fontWeight:700}}>{certificates[props.language].until}</h4>
        <p>{props.infoKey.notAfter?props.infoKey.notAfter:'.'}</p>
        {/*<h4 style={{fontWeight:700}}>{certificates[props.language].publicKey}</h4>
        <p>{props.infoKey.publicKey?base64ToHex(props.infoKey.publicKey):'.'}</p>*/}
      </div>
    </Modal>)
  );
};

export default infoKey;
