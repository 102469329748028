import netcoServer from '../../apis/netcoServer';
import { GET_TOKEN} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const getToken= (order, onError, onSuccess) => async (dispatch) => {
  const onSuccessPayload = data => {
    return {result:{token:data.result.token}};
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/getTokenByOrder  ', order));
  handleResponse(dispatch, response, GET_TOKEN, onSuccessPayload, null, [],[],[onError],[onSuccess]);
};
export default getToken;
