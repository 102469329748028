const shortcuts = {
    es: {
        shortcuts: 'Accesos directos',
        shortCuts_path: {
            '/files/pending': 'Pendientes',
            '/files/newpending': 'Pendientes',
            '/files/workflow': 'Enviados',
            '/files/management': 'Firmados',
            '/files/management/archived': 'Archivados',
            '/files/private':'Archivados'
        }
    },
    en: {
        shortcuts: 'Shortcuts',
        shortCuts_path: {
            '/files/pending': 'Pending',
            '/files/newpending': 'Pending',
            '/files/workflow': 'Sent',
            '/files/management': 'Signed',
            '/files/management/archived': 'Archived',
            '/files/private':'Archived'
        }
    }
};
export default shortcuts;
