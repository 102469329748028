import netcoServer from '../../apis/netcoServer';
import {CREATE_PROFILE, INFO_SHOW} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import modalDismiss from "../modal/modalDismiss";
import _ from 'lodash';
import loadingCall from "../helpers/loadingCall";

const createUser= (formValues,callback) => async (dispatch) => {
  if(formValues.blankPassword)
    formValues.userPassword=formValues.password_confirmation="";
  if(formValues.userPassword === formValues.password_confirmation) {
    formValues.locked = false;
    formValues.maxOper = -2;
    formValues.profileDescription = -1;
    let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/createUser', _.omit(formValues, ['password_confirmation'])));
    handleResponse(dispatch, response, CREATE_PROFILE, onSuccessPayload, null, [modalDismiss],[callback]);
  } else {
    dispatch({type: INFO_SHOW, payload: infoPayload('error', 'Las claves deben coincidir!')})
  }

};

const onSuccessPayload = () => {
  return infoPayload('success', 'Usuario agregado satisfactoriamente!');
};
export default createUser;
