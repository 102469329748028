import netcoServer from '../../apis/netcoServer';
import handleResponse from "../helpers/handleResponse";
import {GET_USER_IMAGE_TO_SHOW} from "../types";
import loadingCall from "../helpers/loadingCall";

const getUserImageToShow= (uid=null,callback=null) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getUserImageToShow', {uniqueID:uid,preview:true}));
  handleResponse(dispatch, response, GET_USER_IMAGE_TO_SHOW, onSuccessPayload,null,[],[],[],callback?[callback]:[]);
};

const onSuccessPayload = data => {
  return {
    signatureFiles: {
      image: data.result.image,
      hwimgid: data.result.hwimgid,
    }
  };
};
export default getUserImageToShow;