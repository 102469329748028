import netcoServer from '../../apis/netcoServer';
import {REMOVE_WORKFLOW_OPTIONS} from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";

const deleteWorkflowOption= (formValues, callback) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/removeWorkflowOption', formValues));
  handleResponse(dispatch, response, REMOVE_WORKFLOW_OPTIONS, onSuccessPayload,null,[],[callback]);

};

const onSuccessPayload = data => {
  return {
    workflowList: {
      workflowOptions: data.result.workflowOptions
    }
  };
};
export default deleteWorkflowOption;