import {GET_WEBSERVICE_USER_INFO} from "../types";

const getWebServiceUserInfo= (id) => async (dispatch, getState) => {
  let payload = {
    reportList: {
      selectedEntry: id === null ? {} : getState().reportList.entries.find(x => x.ip === id)
    }
  };
  dispatch({type: GET_WEBSERVICE_USER_INFO, payload: payload});
};
export default getWebServiceUserInfo;