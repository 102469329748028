import netcoServer from '../../apis/netcoServer';
import {GET_REGISTRATION_OPTIONS} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const getRegistrationOptions= () => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/LoginService/getRegistrationOptions', {}));
  handleResponse(dispatch, response, GET_REGISTRATION_OPTIONS, onSuccessPayload, null);
};

const onSuccessPayload = data => {
  return {
    registration: {
      quick: data.quick,
      data: data.data,
      codes: data.codes,
      id: data.id,
      idps:data.idps
    }
  };
};
export default getRegistrationOptions;