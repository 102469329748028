import React from "react";
import PrivateRoute from "../Root/PrivateRoute";
import { connect } from "react-redux";
import { Button, Col, Pagination, Row, Select, Table, Input, DatePicker, ConfigProvider } from "antd";
import reports from "../../const/reports";
import general from "../../const/general";
import getLogs from "../../actions/report/getLogs";
import pArrow from '../../resources/img/pArrow.svg'
import nArrow from '../../resources/img/nArrow.svg'
import getAllKeysReport from "../../actions/report/getAllKeysReport";
import getSignReport from "../../actions/report/getSignReport";
import getPendingReport from "../../actions/report/getPendingReport";
import getAndesReport from "../../actions/report/getAndesReport";
import moment from "moment";
import certificates from "../../const/certificates";
import profile from "../../const/profile";
import signed_files from "../../const/signed_files";
import handleBase64ZipResponse from "../../actions/helpers/handleBase64ZipResponse";
import DropdownList from "../Helpers/DropdownList";
import getAllLogOperations from "../../actions/admin/getAllLogOperations";
import esCO from "antd/es/locale/es_ES";
import enUS from "antd/es/locale/en_US";
import getAllUsers from "../../actions/report/getAllUsers";
import TagLabel from "../Helpers/TagLabel";
import getAllMetadata from "../../actions/admin/getAllMetadata";
import { getDictionaryMetadata } from '../Helpers/utils';
import { Typography } from "antd";

const { RangePicker } = DatePicker;

class NewLogsTable extends React.Component {

    state = {
        pageSize: 10, page: 1, sidx: '', sord: '', filter: false, formValues: {}, currentReport: '', searchString: '', searchString2: '', selectedUser: '', selectedDocType: '', selectedOp: '0', identifier: '',
        dictionaryMetadata: { metadata: {}, options: {}, optionsText: [] },
        filterMetadataString: [], userList: {}, textValues: {}
    }

    componentDidMount() {
        this.props.getAllLogOperations();
        this.props.getAllUsers();
        this.props.getAllMetadata();
        this.setState({ currentReport: this.props.id }, this.setInitialFilterValues);

    }

    componentDidUpdate(prevProps) {
        if (this.props.metadata !== prevProps.metadata)
            this.setState({ dictionaryMetadata: getDictionaryMetadata(this.props.metadata, this.props.language) })
        if (this.props.userReportList.users !== prevProps.userReportList.users) {
            const userList = this.props.userReportList.users.reduce((obj, item, index) => {
                obj[index + 1] = item.userName;
                return obj;
            }, { 0: reports[this.props.language].allUsers });
            this.setState({ userList })
        }
    }

    setInitialFilterValues = () => {
        switch (this.state.currentReport) {
            case 'logs':
                this.setState({ page: 1, sord: 'descend', sidx: 'date', filter: false, formValues: { searchField: 'userName', searchOper: 'eq', searchString: '' }, searchString: '', searchString2: '', currentFilter: this.props.admin ? 'userName' : 'date' }, this.handleChange);
                break;
            case 'documents':
            case 'operations':
                this.setState({ page: 1, sord: '', sidx: '', filter: false, formValues: { initialDate: moment('2014-07-01', 'YYYY-MM-DD'), endDate: moment(new Date()).format('YYYY-MM-DD') }, searchString: '', searchString2: '', selectedUser: '', selectedDocType: '', selectedOp: '0' }, this.handleChange);
                break;
            case 'certificates':
                this.setState({ page: 1, sord: 'descend', sidx: 'notAfter', filter: false, formValues: { selectedUser: '', initialDate: moment().startOf('year').format('YYYY-MM-DD'), endDate: moment().endOf('month').format('YYYY-MM-DD') }, searchString: '', searchString2: '', selectedUser: '' }, this.handleChange);
                break;
            case 'pending':
                this.setState({ page: 1, sord: 'ascend', sidx: 'creationDate', filter: false, formValues: { selectedUser: '' }, selectedUser: '', filterMetadataString: [], textValues: {} }, this.handleChange);
                break;
            case 'andes':
                this.setState({ page: 1, sord: 'ascend', sidx: 'date', filter: false, formValues: { selectedUser: '' }, searchString: '', searchString2: '', selectedUser: '', selectedDocType: '', identifier: '' }, this.handleChange);
                break;
            default:
                return null;
        }
    };

    handleChange = (isExport = false) => {
        switch (this.state.currentReport) {
            case 'logs':
                this.props.getLogs(this.state.filter ? this.state.formValues : {}, this.state.page, this.state.pageSize, this.state.sidx, this.state.sord, !isExport);
                break;
            case 'documents':
                this.props.getSignReport(this.state.filter ? this.state.formValues : { initialDate: moment('2014-07-01', 'YYYY-MM-DD').format('YYYY-MM-DD'), endDate: moment().endOf('month').format('YYYY-MM-DD') }, this.state.page, this.state.pageSize, this.state.sidx, this.state.sord, !isExport, true);
                break;
            case 'operations':
                this.props.getSignReport(this.state.filter ? this.state.formValues : { initialDate: moment('2014-07-01', 'YYYY-MM-DD').format('YYYY-MM-DD'), endDate: moment().endOf('month').format('YYYY-MM-DD') }, this.state.page, this.state.pageSize, this.state.sidx, this.state.sord, !isExport, false);
                break;
            case 'certificates':
                this.props.getAllKeysReport(this.state.filter ? this.state.formValues : { initialDate: moment('2014-07-01', 'YYYY-MM-DD').format('YYYY-MM-DD'), endDate: moment().endOf('month').format('YYYY-MM-DD') }, this.state.page, this.state.pageSize, this.state.sidx, this.state.sord, !isExport);
                break;
            case 'pending':
                this.props.getPendingReport(this.state.filter ? this.state.formValues : {}, this.state.page, this.state.pageSize, this.state.sidx, this.state.sord, !isExport);
                break;
            case 'andes':
                this.props.getAndesReport(this.state.filter ? this.state.formValues : { initialDate: moment('2014-07-01', 'YYYY-MM-DD').format('YYYY-MM-DD'), endDate: moment().endOf('month').format('YYYY-MM-DD') }, this.state.page, this.state.pageSize, this.state.sidx, this.state.sord, !isExport);
                break;
            default:
                return null;
        }
    };

    downloadCert = (cert, name) => {
        handleBase64ZipResponse(cert, name + '.cer');
    }

    columns = () => {
        switch (this.state.currentReport) {
            case 'logs':
                return (
                    [{
                        title: reports[this.props.language].date_column,
                        dataIndex: 'date',
                        className: 'mobile-show',
                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'date' ? this.state.sord : false
                    }, {
                        title: reports[this.props.language].user_column,
                        dataIndex: 'userName',

                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'userName' ? this.state.sord : false
                    }, {
                        title: reports[this.props.language].operation_column,
                        dataIndex: 'operation',

                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'operation' ? this.state.sord : false
                    }, {
                        title: reports[this.props.language].detail_column,
                        dataIndex: 'detail',
                        className: 'mobile-show'
                    }, {
                        title: reports[this.props.language].ip_column,
                        dataIndex: 'ip',

                    }]
                );
            case 'documents':
            case 'operations':

                return (
                    [{
                        title: reports[this.props.language].user_column,
                        dataIndex: 'userName',

                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'userName' ? this.state.sord : false
                    }, {
                        title: reports[this.props.language].operation_column,
                        dataIndex: 'fileName',
                        className: 'mobile-show',
                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'fileName' ? this.state.sord : false
                    }, {
                        title: reports[this.props.language].type_column,
                        dataIndex: 'documentType',

                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'documentType' ? this.state.sord : false
                    }, {
                        title: this.props.reportList.kind === 'documents' ? reports[this.props.language].number_column : reports[this.props.language].operations_column,
                        dataIndex: 'id',
                        className: 'mobile-show',
                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'id' ? this.state.sord : false
                    }]
                );
            case 'certificates':
                return (
                    [{
                        title: reports[this.props.language].user_column,
                        dataIndex: 'alias',
                        className: 'mobile-show',
                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'alias' ? this.state.sord : false
                    }, {
                        title: certificates[this.props.language].id,
                        dataIndex: 'id',
                        className: 'mobile-show',
                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'id' ? this.state.sord : false
                    }, {
                        title: profile[this.props.language].serial,
                        dataIndex: 'serial',
                        className: 'mobile-show',
                        render: (text, record) =>
                            <Button type="link" onClick={() => this.downloadCert(record.cert, record.serial)}>{text}</Button>
                    }, {
                        title: certificates[this.props.language].until,
                        dataIndex: 'notAfter',
                        className: 'mobile-show',
                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'notAfter' ? this.state.sord : false
                    }]
                );
            case 'pending':
                return (
                    [{
                        title: certificates[this.props.language].id,
                        dataIndex: 'uid',
                        className: 'mobile-show',
                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'uid' ? this.state.sord : false
                    },
                    {
                        title: signed_files[this.props.language].creator,
                        dataIndex: 'creator',
                        className: 'mobile-show',
                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'creator' ? this.state.sord : false
                    }, {
                        title: signed_files[this.props.language].wpending,
                        dataIndex: ["signer", "signer"],
                        className: 'mobile-show',
                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'signer' ? this.state.sord : false
                    }
                        , {
                        title: signed_files[this.props.language].wactive,
                        dataIndex: ["signer", "active"],
                        render: (val) => val ? 'Si' : 'No',
                        className: 'mobile-show',
                    },
                    {
                        title: signed_files[this.props.language].document_column,
                        dataIndex: 'fileName',
                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'fileName' ? this.state.sord : false
                    }, {
                        title: signed_files[this.props.language].metadata,
                        className: 'mobile-show',
                        render: (text, record) => {
                            return (
                                record.documentMetadata && record.documentMetadata.map((metadata, index) => (
                                    <TagLabel key={index} type={'Info'} text={this.state.dictionaryMetadata.metadata[metadata.idmetadata] + ' : ' + metadata.value} inTable={true} />
                                ))
                            );
                        },
                    }, {
                        title: reports[this.props.language].date_column,
                        dataIndex: 'creationDate',
                        className: 'mobile-show',
                        sortDirections: ['ascend', 'descend'],
                        sorter: () => true,
                        sortOrder: this.state.sidx === 'creationDate' ? this.state.sord : false
                    }
                    ]
                );
            case 'andes':
                return (
                    [{
                        title: certificates[this.props.language].id,
                        dataIndex: 'idSolicitud',
                        className: 'mobile-show'
                    },
                    {
                        title: reports[this.props.language].user_column,
                        dataIndex: 'userName',
                        className: 'mobile-show'
                    }, {
                        title: reports[this.props.language].detail_column,
                        dataIndex: ["description"],
                        className: 'mobile-show'
                    }
                        , {
                        title: signed_files[this.props.language].status,
                        dataIndex: ["status"],
                        render: (val) => {
                            switch (val) {
                                case "0":
                                    return reports[this.props.language].pendingStatus;
                                case "1":
                                    return reports[this.props.language].okStatus;
                                case "2":
                                    return reports[this.props.language].cancelStatus;
                                default:
                                    return val;
                            }
                        },
                        className: 'mobile-show',
                    },
                    {
                        title: reports[this.props.language].date_column,
                        dataIndex: 'date',
                        className: 'mobile-show'
                    }]
                );
            default: return null;
        }
    };

    getRecordKey = (record) => {
        switch (this.props.reportList.kind) {
            case 'documents':
            case 'operations':
                return record.userName + "-" + record.fileName + "-" + record.documentType;
            case 'pending':
                return record.uid + '-' + record.signer.signer;
            case 'andes':
                return record.idSolicitud;
            default:
                return record.id;
        }
    };

    handlePageChange = (page) => {
        this.setState({ page: page }, this.handleChange);
    };

    handlePageSizeChange = (value) => {
        this.setState({ pageSize: parseInt(value), page: 1 }, this.handleChange);
    };

    handleFilter = () => {
        const formValues = { searchField: this.state.currentFilter, searchOper: 'bw', searchString: this.state.searchString, searchString2: this.state.searchString2, selectedUser: this.state.selectedUser, selectedDocType: this.state.selectedDocType, selectedOp: this.state.selectedOp, identifier: this.state.identifier };
        if (this.state.currentReport === 'documents' || this.state.currentReport === 'operations' || this.state.currentReport === 'certificates' || this.state.currentReport === 'andes') {
            let initialDate = this.state.searchString;
            let endDate = this.state.searchString2;
            if (!initialDate || initialDate === '')
                initialDate = "2014-07-01";
            if (!endDate || endDate === '')
                endDate = moment(new Date()).format('YYYY-MM-DD');
            formValues.initialDate = initialDate;
            formValues.endDate = endDate;
        }
        if (this.state.currentReport === 'pending') {
            formValues.fileName = this.state.searchString;
            formValues.filterMetadataString = JSON.stringify(this.state.filterMetadataString);
        }
        this.setState({ formValues, filter: true }, this.handleChange);
    };

    handleExport = () => {
        this.handleChange(true);
    };

    handleSort = (pagination, filters, sorter) => {
        let field = sorter.field;
        if (field !== undefined) {
            if (Array.isArray(field))
                field = "signer";
            this.setState({ sidx: field, sord: sorter.order }, this.handleChange);
        } else {
            this.setState({ sidx: '', sord: '' }, this.handleChange);
        }
    };

    paginationItemRender = (_, type, originalElement) => {
        if (type === 'prev')
            return <img alt={''} src={pArrow} />;
        if (type === 'next')
            return <img alt={''} src={nArrow} />;
        return originalElement;
    };

    handleFilterChange = (value) => {
        this.setState({ currentFilter: value });
    };

    updateFilterMetadata = (idmetadata, valor, type) => {
        let filterMetadataString = this.state.filterMetadataString;
        const nuevoElemento = { idmetadata, valor, type };
        const index = filterMetadataString.findIndex(
            (elemento) => elemento.idmetadata === nuevoElemento.idmetadata
        );
        const isEmptyOption = valor === '0' || valor === '';
        if (index === -1 && !isEmptyOption) {
            filterMetadataString.push(nuevoElemento);
        } else if (isEmptyOption) {
            filterMetadataString = filterMetadataString.filter(
                (elemento) => elemento.idmetadata !== idmetadata
            );
        } else {
            filterMetadataString[index] = nuevoElemento;
        }
        this.setState({ filterMetadataString }, () => this.handleFilter())
    }

    renderFilterSelectOptions = (menu, defaultValue, onChange, title, key) => {
        return (
            <div key={key}>
                <Typography className='mt-10 size15 color-black'>{title}</Typography>
                <DropdownList menu={menu} defaultValue={defaultValue} onChange={onChange} width={200} />
            </div>
        )
    }

    renderFilterTextOptions = (idMetadata, title, action) => {
        return (
            <div key={idMetadata}>
                <Typography className='mt-20 size15 color-black'>{title}</Typography>
                <Input.Search
                    value={this.state.textValues[idMetadata]}
                    onChange={(e) => {
                        const textValues = this.state.textValues;
                        textValues[idMetadata] = e.target.value;
                        this.setState({ textValues })
                    }}
                    onSearch={(valor) => { action ? action(valor) : this.updateFilterMetadata(idMetadata.toString(), valor, 'text') }}
                    enterButton
                />
            </div>
        )
    }

    renderFiltersMetadata = () => {
        const filters = []
        const options = this.state.dictionaryMetadata.options;
        for (let idmetadata in options) {
            const index = this.state.filterMetadataString.findIndex(
                (elemento) => elemento.idmetadata === idmetadata
            );
            const defaultValue = index === -1 ? 0 : this.state.filterMetadataString[index].valor
            filters.push(this.renderFilterSelectOptions(options[idmetadata], defaultValue, (valor) => { this.updateFilterMetadata(idmetadata, valor, 'select') }, this.state.dictionaryMetadata.metadata[idmetadata], idmetadata))
        }
        const optionsText = this.state.dictionaryMetadata.optionsText;
        optionsText.forEach(optionText => {
            filters.push(this.renderFilterTextOptions(optionText, this.state.dictionaryMetadata.metadata[optionText]))
        })
        return filters
    }

    renderSearchComponent = () => {
        switch (this.state.currentFilter) {
            case "date":
                return <ConfigProvider locale={this.props.language === 'es' ? esCO : enUS}><RangePicker onChange={(dates, dateStrings) => {
                    if (dateStrings && dateStrings.length === 2)
                        this.setState({ searchString: dateStrings[0], searchString2: dateStrings[1] });
                }
                } /></ConfigProvider>;
            case "operation":
                return <Select style={{ width: '200px' }} onChange={(value) => this.setState({ searchString: value })}>
                    {this.props.securityOperations ? this.props.securityOperations.map(item => <Select.Option key={item.id} value={item.description}>{item.description}</Select.Option>) : ''}
                </Select>;
            case "userName":
            default:
                return <Input onChange={(e) => this.setState({ searchString: e.target.value })} value={this.state.searchString} style={{ height: '32px' }} />;
        }
    };

    renderFilters = () => {
        switch (this.state.currentReport) {
            case 'logs':
                const filterMenu = {
                    date: reports[this.props.language].date_column,
                    operation: reports[this.props.language].operation_column,
                    detail: reports[this.props.language].detail_column,
                    ip: reports[this.props.language].ip_column
                };
                if (this.props.admin)
                    filterMenu.userName = reports[this.props.language].user_column;
                return <Row style={{ marginBottom: '30px' }}>
                    <Col style={{ marginRight: '10px' }}>
                        <DropdownList menu={filterMenu} defaultValue={this.state.currentFilter}
                            onChange={this.handleFilterChange} width={'130px'} />
                    </Col>
                    <Col style={{ marginRight: '10px' }}>
                        {this.renderSearchComponent()}
                    </Col>
                    {this.renderFilterButtons()}
                </Row>;
            case "documents":
            case "operations":
                return <Row style={{ alignItems: 'center', marginBottom: '30px', columnGap: '10px' }}>
                    {this.renderDateFilter()}
                    {this.renderUserFilter()}
                    <Col style={{ marginRight: '10px' }}>
                        <Select value={this.state.selectedDocType} style={{ width: '200px', fontSize: '12px' }} onChange={(value) => this.setState({ selectedDocType: value }, this.handleFilter)}>
                            {Object.keys(reports[this.props.language].allExtensions).map((extension,index) => <Select.Option key={index} value={extension}>{reports[this.props.language].allExtensions[extension]}</Select.Option>)}
                        </Select>
                    </Col>
                    <Col style={{ marginRight: '10px' }}>
                        <Select value={this.state.selectedOp} style={{ width: '200px', fontSize: '12px' }} onChange={(value) => this.setState({ selectedOp: value }, this.handleFilter)}>
                            {Object.keys(reports[this.props.language].allOperations).map((extension,index) => <Select.Option key={index} value={extension}>{reports[this.props.language].allOperations[extension]}</Select.Option>)}
                        </Select>
                    </Col>
                    {this.renderFilterButtons()}
                </Row>
            case "certificates":
                return <Row style={{ alignItems: 'center', marginBottom: '30px', columnGap: '10px' }}>
                    {this.renderDateFilter()}
                    {this.renderUserFilter()}
                    {this.renderFilterButtons()}
                </Row>
            case "pending":
                return <Row style={{ alignItems: 'center', marginBottom: '30px', columnGap: '10px' }}>
                    {this.renderUserFilter()}
                    {this.renderFiltersMetadata()}
                    {this.renderFilterTextOptions('usersListReport', reports[this.props.language].fileName, (valor) => { this.setState({ searchString: valor }, this.handleFilter) })}
                    {this.renderFilterButtons()}
                </Row>
            case "andes":
                return <Row style={{ alignItems: 'center', marginBottom: '30px', columnGap: '10px' }}>
                    {this.renderDateFilter()}
                    {this.renderUserFilter()}
                    <Col style={{ marginRight: '10px' }}>
                        <Select value={this.state.selectedDocType} style={{ width: '200px', fontSize: '12px' }} onChange={(value) => this.setState({ selectedDocType: value }, this.handleFilter)}>
                            {Object.keys(reports[this.props.language].allStatus).map((extension) => <Select.Option value={extension}>{reports[this.props.language].allStatus[extension]}</Select.Option>)}
                        </Select>
                    </Col>
                    <Col style={{ marginRight: '10px' }}>
                        <Input placeholder={certificates[this.props.language].id} onChange={(e) => this.setState({ identifier: e.target.value })} value={this.state.identifier} style={{ height: '32px' }} />
                    </Col>
                    {this.renderFilterButtons()}
                </Row>
            default:
                break;
        }
    };

    buscarLlavePorValor = (valor, objeto) => {
        for (let llave in objeto) {
            if (objeto[llave] === valor) {
                return llave;
            }
        }
        return null;
    }

    renderUserFilter = () => {
        if (this.state.currentReport === 'pending') {
            const selectedUser = this.state.selectedUser === '' ? 0 : this.buscarLlavePorValor(this.state.selectedUser, this.state.userList);
            return this.renderFilterSelectOptions(this.state.userList, selectedUser, (value) => this.setState({ selectedUser: value === '0' ? '' : this.state.userList[value] }, this.handleFilter), reports[this.props.language].user_column, 'userFilter')
        } else
            return this.props.admin && <Col style={{ marginRight: '10px' }}>
                <Select value={this.state.selectedUser} style={{ width: '200px', fontSize: '12px' }} onChange={(value) => this.setState({ selectedUser: value }, this.handleFilter)}>
                    <Select.Option value="">{reports[this.props.language].allUsers}</Select.Option>
                    {this.props.userReportList.users.map(item => {
                        return <Select.Option key={item.userName} value={item.userName}>{item.userName}</Select.Option>;
                    })}
                </Select>
            </Col>;
    };

    renderDateFilter = () => {
        return <Col style={{ marginRight: '10px' }}>
            <ConfigProvider locale={this.props.language === 'es' ? esCO : enUS}><RangePicker value={[this.state.searchString ? moment(this.state.searchString, 'YYYY-MM-DD') : '', this.state.searchString2 ? moment(this.state.searchString2, 'YYYY-MM-DD') : '']} onChange={(dates, dateStrings) => {
                if (dateStrings && dateStrings.length === 2)
                    this.setState({ searchString: dateStrings[0], searchString2: dateStrings[1] });
            }
            } /></ConfigProvider>
        </Col>;
    }

    renderFilterButtons = () => {
        return <div style={{ marginLeft: 'auto', display: 'flex', marginTop: 'auto', marginBottom: '10px' }}>
            {this.state.currentReport !== 'pending' && <Col style={{ marginRight: '10px' }}>
                <Button type={'ghost'} className={'float-right'} style={{ width: 83, marginBottom: '0' }} htmlType={'button'} onClick={this.handleFilter}>
                    {reports[this.props.language].filter.toUpperCase()}</Button>
            </Col>}
            {this.state.filter &&
                <Col style={{ marginRight: '10px' }}>
                    <Button type={'ghost'} className={'float-right'} style={{ width: 83, marginBottom: '0' }} htmlType={'button'} onClick={this.setInitialFilterValues}>
                        {general[this.props.language].clean.toUpperCase()}</Button>
                </Col>
            }
            <Button onClick={this.handleExport}
                type={'ghost'} className={'float-right'} style={{ width: 159, marginBottom: '0' }} >
                {reports[this.props.language].export.toUpperCase()}</Button>
        </div>;

    };

    render() {
        //Workaround to show data in it's correct order
        let dats = [...this.props.reportList.entries];
        if (this.state.sord && this.state.sord === 'descend')
            dats.reverse();
        //End workaround
        return <>
            {this.renderFilters()}
            <Table
                size={'small'}
                dataSource={dats}
                columns={this.columns()}
                rowKey={this.getRecordKey}
                pagination={false}
                onChange={this.handleSort}
                showSizeChanger={false}
                scroll={{ x: 1300 }}

            />
            <Row style={{ paddingTop: '20px' }}>
                <Col style={{ textAlign: 'center', paddingBottom: '10px' }} xs={24} md={6}>
                    <Select style={{ paddingRight: '5px' }} value={this.state.pageSize} onChange={this.handlePageSizeChange}>
                        <Select.Option value="10">10</Select.Option>
                        <Select.Option value="100">100</Select.Option>
                        <Select.Option value="1000">1000</Select.Option>
                    </Select>
                    <span style={{ fontSize: '12px' }}>{general[this.props.language].results_per_page}</span>

                </Col>
                <Col style={{ alignSelf: 'center', textAlign: 'center', paddingBottom: '10px' }} xs={24} md={12}>
                    <Pagination pageSize={this.state.pageSize}
                        current={this.state.page}
                        itemRender={this.paginationItemRender}
                        showSizeChanger={false}
                        onChange={this.handlePageChange}
                        total={this.props.reportList.total}
                    />
                </Col>
                <Col style={{ alignSelf: 'center', textAlign: 'center', paddingBottom: '10px' }} xs={24} md={6}>
                    <span style={{ fontSize: '12px' }}>{general[this.props.language].total_elements.replace('$0', this.props.reportList.total)}</span>
                </Col>
            </Row>

        </>;
    }
}

const mapStateToProps = state => {
    return {
        language: state.auth.language,
        reportList: state.userReportList,
        securityOperations: state.reportList.securityOperations,
        userReportList: state.userReportList,
        admin: state.auth.admin,
        metadata: state.reportList.entries
    };
};

export default PrivateRoute(connect(mapStateToProps, {
    getLogs, getAllMetadata,
    getAllKeysReport, getSignReport, getPendingReport, getAndesReport, getAllLogOperations,
    getAllUsers
})(NewLogsTable));