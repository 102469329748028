import netcoServer from '../../apis/netcoServer';
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";
import { INFO_SHOW } from '../types';

const delNotifications = (formValues, callback = []) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/removeNotifications', formValues));
  handleResponse(dispatch, response, null, onSuccessPayload, null, [], [], [], callback);
  if (response && response.data && response.data.success && response.data.result) {
    const results = response.data.result.result;
    let showUpdate = Object.values(results).every(notification => notification);
    let message = 'No se logró eliminar todas las notificaciónes';
    let typeMessage = 'error';
    if (showUpdate){
      message = "Notificaciones eliminadas correctamente";
      typeMessage = 'success';
    }
      
    dispatch(
      {
        type: INFO_SHOW,
        payload: {
          info: {
            message: message,
            type: typeMessage
          }
        }
      });
  }
};

const onSuccessPayload = data => {
  return { notificationsList: data.result.result };
};
export default delNotifications;