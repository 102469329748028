import React from 'react';
import {connect} from 'react-redux';

const authorization = (Component) => {

  class HOC extends React.Component {

    render() {
        if (this.props.hasSession && !this.props.hideMenu && !this.props.appCode) {
        return (
          <Component {...this.props} />
        )
      } else {
        return (
		<React.Fragment/>
        )
      }
    }
  }

  function mapStateToProps(state) {
      return {
        hideMenu: state.auth.hideMenu,
        appCode: state.auth.appCode.appCode,
        hasSession:state.session.hasSession,
        requiresCert:state.auth.requiresCert,

    }
  }

  return connect(mapStateToProps)(HOC)

};

export default authorization;
