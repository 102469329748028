import netcoServer from '../../apis/netcoServer';
import {GET_ALL_USERS} from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";

const getAllUsers= () => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getAllUsers', {}));
  handleResponse(dispatch, response, GET_ALL_USERS, onSuccessPayload)
};

const onSuccessPayload = data => {
  return {
    userReportList: {
      users: data.result.users
    }
  };
};
export default getAllUsers;