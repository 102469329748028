import React from 'react';
import {connect} from 'react-redux'

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Col, Modal, Row, Select } from "antd";

// import 'antd/lib/modal/style/css';


import {Field, reduxForm} from "redux-form";
import {
  CreateCheckbox,
  CreateNumberField,
  CreatePasswordField,
  CreateSelectField,
  CreateTextField
} from "../../Helpers/Input/InputCreator";
import {emailes, required} from "../../Helpers/Input/validations";
import getUserInfo from "../../../actions/admin/getUserInfo";
import getProfiles from "../../../actions/admin/getProfiles";
import {userNormalizer} from "../../Helpers/Input/normalizers";
import admin from "../../../const/admin";


class AdminUsersModal extends React.Component {

  state = {formFile: [],showPassword:'false'};

  componentDidMount() {
    this.props.getUserInfo(this.props.entryId);
    this.props.getProfiles();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.initialValues !== prevProps.initialValues) {
      this.props.initialize(this.props.initialValues);
    }
  }

  renderSqrlField ()
  {
      if(this.props.newProfiles && this.props.user)
      {
          const currentProfile=this.props.newProfiles.filter(profile=>profile.id.toString()===this.props.user.profileID);
          if(currentProfile && currentProfile.length>0 && currentProfile[0].authTypeId ===8)
          {
              return (
                <Col md={24}>
                    <Field name="delSQRL"
                           component={CreateCheckbox}
                           hasFeedback>
                        {admin[this.props.language].del_sqrl}
                    </Field>
                </Col>
            );
          }
      }

      /*if (this.props.user.profileDescription === 'SQRL')
      return (
          <Col md={24}>
            <Field name="delSQRL"
                   component={CreateCheckbox}
                   hasFeedback>
              {admin[this.props.language].del_sqrl}
            </Field>
          </Col>
      );*/
  }

  renderFields () {
    if(this.props.update) {
	    return (
        <Row>
          <Col md={24}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].profile_column}</div>
            <Field
                   name="profileDescription"
                   component={CreateSelectField}
                   placeholder={admin[this.props.language].profile_column}
                   hasFeedback>
              {this.props.newProfiles.map(item => <Select.Option key={item.id}>{item.description}</Select.Option>)}
            </Field>
          </Col>
          <Col md={24}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].state_column}</div>
            <Field
                   name="userStatus"
                   component={CreateSelectField}
                   placeholder={admin[this.props.language].state_column}
                   hasFeedback>
              <Select.Option value={'Activo'}>Activo</Select.Option>
              <Select.Option value={'Bloqueado'}>Bloqueado</Select.Option>
            </Field>
          </Col>
          {this.renderSqrlField()}
          <Col md={24}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].operations_column}</div>
            <Field
                   name="maxOper"
                   component={CreateNumberField}
                   style={{width: '100%'}}
                   placeholder={admin[this.props.language].operations_column}
                   className={'mb-10'}
                   hasFeedback/>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col md={24}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].user_column}</div>
            <Field placeholder={admin[this.props.language].user_column}
                   name="userName"
                   component={CreateTextField}
                   size={'large'}
                   style={{marginBottom: '5px'}}
                   validate={this.state.showPassword?[required]:[required,emailes]}
                   normalize={userNormalizer}
                   hasFeedback/>
          </Col>
            <Col md={24}>
                <Field name="blankPassword"
                       value={this.state.showPassword}
                       onChange={()=>this.setState({showPassword:!this.state.showPassword})}
                       component={CreateCheckbox}
                       hasFeedback>
                    {admin[this.props.language].newPassSend}
                </Field>
            </Col>
            {this.state.showPassword?<div style={{width:'100%'}}><Col md={24}>
                <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].new_password}</div>
            <Field
                   name="userPassword"
                   component={CreatePasswordField}
                   size={'large'}
                   className={'mb-10'}
                   placeholder={admin[this.props.language].new_password}
                   validate={[required]}
                   hasFeedback/>
          </Col>
          <Col md={24}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].confirm_password}</div>
              <Field
                   name="password_confirmation"
                   component={CreatePasswordField}
                   size={'large'}
                   className={'mb-10'}
                   placeholder={admin[this.props.language].confirm_password}
                   validate={[required]}
                   hasFeedback/>
          </Col></div>:''}
        </Row>
      );

    }
  }

  render() {
    return (
      (<Modal
        open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
        <Form className={'loginForm2'} onFinish={this.props.handleSubmit(this.props.onSubmit)}>
          {this.renderFields()}
          <Row>
            <Button htmlType={'submit'} type={'primary'}>{this.props.btn}</Button>
          </Row>
        </Form>
      </Modal>)
    );
  }

}

function mapStateToProps(state) {
  return {
    language: state.auth.language,
    user: state.reportList.selectedEntry,
    initialValues: state.reportList.selectedEntry,
      newProfiles: state.reportList.profiles
  }
}

export default connect(mapStateToProps, {getUserInfo,getProfiles})(reduxForm({form: 'admin_users_form'})(AdminUsersModal));
