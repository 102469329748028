import React from "react";
import {Button, Col, Input, Modal, Row, Typography} from "antd";
import bookmark from '../../../resources/img/NewIcons/sign/bookmark.svg'
import load_files from "../../../const/load_files";

class SavePreferenceModal extends React.Component
{
    state={value:''}
    render()
    {
        return (
            (<Modal
                open={this.props.modalType !== null} width={519} {...this.props.modalProps} title={this.props.modalProps.title} centered={true}>
                <Row>
                    <Col span={24}>
                        <Typography style={{paddingTop:'30px', margin:'2px 20px', textAlign:'center'}}>{load_files[this.props.language].newPreferenceText1}</Typography>
                    </Col>
                    <Col span={24}>
                        <Input className={'size15'} onChange={(e)=>this.setState({value:e.target.value})} value={this.state.value} style={{background:'#F7F9FC'}}
                               placeholder={load_files[this.props.language].newOptionName} addonAfter={<img alt={''} src={bookmark}/>} />
                    </Col>
                    <Col span={24}>
                        <Typography style={{margin:'5px 10px', textAlign:'center'}}>{load_files[this.props.language].newPreferenceText2}</Typography>
                    </Col>
                </Row>
                <Col span={24} style={{textAlign:'center',marginTop:'15px'}}>
                        <Button onClick={()=>this.props.onSave(this.state.value)} style={{width:'231px'}} className='text-title size12'
                                type={"primary light-primary rounded-sm"}> {load_files[this.props.language].savePreferenceButton.toUpperCase()}
                        </Button>
                </Col>
            </Modal>)
        );
    }
}
export default SavePreferenceModal;