import netcoServer from '../../apis/netcoServer';
import {UPDATE_USER_PARAM} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import loadingCall from "../helpers/loadingCall";
import getUserInfo from "./getUserInfo";
import admin from "../../const/admin";
import getValidOperations from "../signature/getValidOperations";

const updateUserParam= (formValues,showOKMessage=true) => async (dispatch, getState) => {
  const onSuccessPayload = () => {
    if(showOKMessage)
      return infoPayload('success', admin[getState().auth.language].processok);
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/updateUserParam', formValues));
  handleResponse(dispatch, response, UPDATE_USER_PARAM, onSuccessPayload, null, [getUserInfo,getValidOperations]);
};
export default updateUserParam;