import React from 'react';
import configureStore from './configureStore';
import {Provider} from "react-redux";
import RouterContainer from './RouterContainer';

const store = configureStore();

const ReduxContainer= () => {
  
  return (
    <Provider store={store}>
      <RouterContainer />
    </Provider>
  );
  
}
export default ReduxContainer;