import netcoServer from '../../apis/netcoServer';
import {UPDATE_ORDER} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const updateOrder= (order,onOrderUpdated,onError) => async (dispatch) => {
  const onSuccessPayload = data => {
    return {token:data.result.token};
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/updateOrder  ', order));
  handleResponse(dispatch, response, UPDATE_ORDER, onSuccessPayload, null, [],[],[onError],[onOrderUpdated]);
};
export default updateOrder;

