import netcoServer from '../../apis/netcoServer';
import {GET_ALL_AUTH_TYPES} from "../types";
import tableLoadingCall from '../helpers/tableLoadingCall';

const getAllAuthTypes= () => async (dispatch) => {
  await tableLoadingCall(dispatch, () => netcoServer.newPost('/SystemService/getAllAuthTypes', {}), GET_ALL_AUTH_TYPES, onSuccessPayload);
};
const onSuccessPayload = data => {
  return {
    reportList: {
      authTypes: data.result.authTypes
    }
  };
};
export default getAllAuthTypes;