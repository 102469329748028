import netcoServer from '../../apis/netcoServer';
import {GET_PRODUCTS_INFO} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const getProductsInfo= () => async (dispatch) => {
  const onSuccessPayload = data =>
  {
      return {result:{products:data.result.products,
        methods:data.result.paymentMethods, keyInfo:data.result.paymentKeyInfo,
        checkoutURL:JSON.parse(data.result.paymentCheckoutURL)}};
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/getProductsInfo', {}));
  handleResponse(dispatch, response, GET_PRODUCTS_INFO, onSuccessPayload, null, [],[]);
};
export default getProductsInfo;
