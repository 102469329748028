import React from 'react';
import {connect} from 'react-redux'
import getWebServiceUserInfo from '../../../actions/admin/getWebServiceUserInfo';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Col, Modal, Row } from "antd";

// import 'antd/lib/modal/style/css';


import {Field, reduxForm} from "redux-form";
import {CreateFileField, CreatePasswordField, CreateTextField} from "../../Helpers/Input/InputCreator";
import {required} from "../../Helpers/Input/validations";
import admin from "../../../const/admin";
import general from "../../../const/general";
import {LockOutlined} from "@ant-design/icons";


class AdminWebservicesModal extends React.Component {

  state = {formFile: []};

  componentDidMount() {
    this.props.getWebServiceUserInfo(this.props.entryId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.initialValues !== prevProps.initialValues) {
      this.props.initialize(this.props.initialValues);
    }
  }


  onSubmit = (formValues) => {
    let file = this.state.formFile[0] === undefined ? null : this.state.formFile[0].file;
    this.props.onSubmit(formValues, file);
  };

  handleOnDrop = (newImageFile, onChange) => {
    if(newImageFile[0]){
      const formFile = {
        file: newImageFile[0],
        name: newImageFile[0].name,
        preview: URL.createObjectURL(newImageFile[0]),
        size: newImageFile[0].size
      };
      this.setState({formFile: [formFile]}, () => onChange(formFile));
    }
  };

  render() {
    return (
      (<Modal
        open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
        <Form onFinish={this.props.handleSubmit(this.onSubmit)}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].select_cert}</div>
              <Field
                     name="avatar"
                     accept={".cer, .crt"}
                     component={CreateFileField}
                     handleOnDrop={this.handleOnDrop}
                     type='file'
                     formFile={this.state.formFile}
                     multiple={false}
              />
            </Col>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].ip_column}</div>
              <Field
                     name="ip"
                     disabled={this.props.disabledFields}
                     component={CreateTextField}
                     validate={required}
                     placeholder={admin[this.props.language].ip_column}
                     hasFeedback/>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].password}</div>
              <Field
                     name="password"
                     prefix={<LockOutlined className={'icon-font'} />}
                     component={CreatePasswordField}
                     size={'large'}
                     className={'mb-10'}
                     validate={required}
                     placeholder={general[this.props.language].password}
                     hasFeedback/>

            </Col>
            <Col xs={24} md={12}>


            </Col>
          </Row>
          <Row>
            <Button htmlType={'submit'} type={'primary'}>{this.props.btn}</Button>
          </Row>
        </Form>
      </Modal>)
    );
  }

}

function mapStateToProps(state) {
  return {
    language: state.auth.language,
    webservice: state.reportList.selectedEntry,
    initialValues: state.reportList.selectedEntry
  }
}

export default connect(mapStateToProps, {getWebServiceUserInfo})(reduxForm({form: 'admin_webservices_form'})(AdminWebservicesModal));
