import netcoServer from '../../apis/netcoServer';
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";

const getPendingFileInformation= (data=null) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getPendingFilesInformation', data));
  handleResponse(dispatch, response, null, null);
  if(response && response.data && response.data.filesInfo && response.data.filesInfo.pendingFilesInfo)
    return response.data.filesInfo.pendingFilesInfo[0];
  return null;
};
export default getPendingFileInformation;