
const signed_files = {
  es: {
    private_tab: 'Mis archivos privados',
    public_tab: 'Mis archivos públicos',
    pending_tab: 'Archivos pendientes por firmar',
    workflow_tab: 'Archivos enviados a otros firmantes',
    private_info: 'Gestiona y organiza tus archivos privados.',
    public_info: 'Gestiona y organiza tus archivos públicos.',
    pending_info: 'Gestiona los archivos que tengas pendiente de firma. ',
    delete_action: 'Eliminar',
    sign_action: 'Volver a firmar',
    sign: 'Firmar',
    email_action: 'Enviar copia al correo',
    save_action: 'Guardar como...',
    verify_action: 'Verificar firma',
    load_action: 'Cargar archivo a otro usuario',
    download_action: 'Descargar',
    document_column: 'Nombre',
    extension_column: 'Extensión',
    modification_column: 'Modificación',
    send_modal: 'Enviar copia al correo',
    load_modal: 'Cargar a otro usuario',
    logout: 'Salir',
    createFolder: 'Crear Carpeta',
    cut: 'Cortar',
    paste: 'Pegar',
    defaultAction: 'Establecer carpeta por defecto',
    invalidMail: 'Correo inválido',
    creator: 'Creador/Remitente',
    creationDate: 'Fecha de creación',
    creationDate1: 'Creado',
    finishDate: 'Fecha de Finalización',
    finishDate1: 'Finalizado',
    signersCount: 'Firmantes Pendientes',
    showTrack: 'Ver historial',
    workflowInfo: 'Gestiona los archivos que enviaste a otros usuarios para firmar. ',
    workflowStatus: 'Seleccione el estado para filtrar la información',
    pendingStatus: 'Pendientes',
    finishedStatus: 'Terminados',
    close: 'Cerrar',
    pendingSigners: 'Usuarios pendientes por firmar',
    finishedSigners: 'Usuarios que terminaron',
    status: 'Estado',
    signed: 'Firmado',
    canceled: 'Cancelado',
    wuser: 'Usuario',
    wdate: 'Fecha de firma',
    wdetail: 'Detalles',
    wip: 'Dirección IP',
    wpending: 'Pendiente por firmar',
    pending: 'Pendiente',
    wactive: 'Activo',
    size: 'Tamaño',
    info: 'Información',
    category: 'Categoría',
    type: 'Tipo',
    created: 'Creación',
    folder: 'Carpeta',
    delete: 'Eliminar',
    public: 'Público',
    private: 'Privado',
    selectTemplate: 'Selecciona una plantilla',
    noDeletePublic: 'No se pueden eliminar archivos públicos, contacte con su administrador.',
    selectAll: 'Seleccionar todo',
    traceability: 'Trazabilidad',
    to: 'Para: ',
    from: 'De: ',
    recipients: 'Destinatarios',
    comments: 'Comentarios',
    selected: 'Archivos',
    multipleFiles: 'Multiples archivos seleccionados',
    metadata: 'Metadatos',
    select: 'Seleccionar',
    fileName: 'Nombre del archivo',
    deleteNotification: 'Eliminar notificación',
    menuSignedDocs: {
      1: 'Todas las categorías',
      2: 'Privados',
      3: 'Publicos'
    },
    menuTypeSignedDocs: {
      1: 'Todos los archivos',
      2: 'PDF',
      3: 'DOCX',
      4: 'XML',
      5: 'P7Z',
      6: 'XLSM',
      7: 'ODT',
      8: 'TXT',
      9: 'JPG',
      10: 'XLSX',
      11: 'ZIP'
    },
    descriptionDocsViewer: {
      'pending':'Aquí encontrarás los archivos cargados o recibidos, que están pendientes de gestionar su firma',
      'signed':'Estos son los documentos que ya han sido firmados o se les ha gestionado su firma',
      'workflow':'Estos son los documentos que has enviado para su gestión de firma',
      'templates':'Aquí encontrarás algunas plantillas PDF, previamente cargadas, que podrás utilizar para gestionar y organizar tus firmas '
    }
  },
  en: {
    private_tab: 'Private',
    public_tab: 'Public',
    pending_tab: 'Pending to sign',
    workflow_tab: 'Sent to other users',
    private_info: 'Manage your private files',
    public_info: 'Manage your public files',
    pending_info: 'Manage your pending files',
    delete_action: 'Remove',
    sign_action: 'Sign again',
    sign: 'Sign',
    email_action: 'Send copy to the mail',
    save_action: 'Save as...',
    verify_action: 'Verify signature',
    load_action: 'Upload file to another user',
    download_action: 'Download',
    document_column: 'Name',
    extension_column: 'Extension',
    modification_column: 'Modification',
    send_modal: 'Send copy to the mail',
    load_modal: 'Upload to another user',
    logout: 'Exit',
    createFolder: 'Create',
    cut: 'Cut',
    paste: 'Paste',
    defaultAction: 'Set as default folder',
    invalidMail: 'Invalid mail',
    creator: 'Creator/Sent By',
    creationDate: 'Creation Date',
    creationDate1: 'Created',
    finishDate: 'Finished Date',
    finishDate1: 'Finished',
    signersCount: 'Pending Signers',
    showTrack: 'Show track',
    pending: 'Pending',
    workflowInfo: 'Manage your files sent to another users for signature',
    workflowStatus: 'Select status',
    pendingStatus: 'Pending',
    finishedStatus: 'Finished',
    close: 'Close',
    pendingSigners: 'Pending users to sign',
    finishedSigners: 'Users who signed',
    status: 'Status',
    signed: 'Signed',
    canceled: 'Cancelled',
    wuser: 'User',
    wdate: 'Sign Date',
    wdetail: 'Details',
    wip: 'IP address',
    wpending: 'Pending',
    wactive: 'Active',
    size: 'Size',
    info: 'Information',
    category: 'Category',
    type: 'Type',
    created: 'Creation',
    folder: 'Folder',
    delete: 'Delete',
    public: 'Public',
    private: 'Private',
    selectTemplate: 'Select a template',
    noDeletePublic: 'Cannot delete public files, contact your administrator.',
    selectAll: 'Select all',
    traceability: 'Traceability',
    to: 'To: ',
    from: 'From: ',
    recipients: 'Recipients',
    comments: 'Comments',
    selected: 'Files',
    multipleFiles: 'Multiple files',
    metadata: 'Metadata',
    select: 'Select',
    fileName: 'File name',
    deleteNotification: 'Delete Notification',
    menuSignedDocs: {
      1: 'All categories',
      2: 'Private',
      3: 'Public'
    },
    menuTypeSignedDocs: {
      1: 'ALL THE DOCUMENTS',
      2: 'PDF',
      3: 'DOCX',
      4: 'XML',
      5: 'P7Z',
      6: 'XLSM',
      7: 'ODT',
      8: 'TXT',
      9: 'JPG',
      10: 'XLSX',
    },
    descriptionDocsViewer: {
      'pending':'Here you will find the files uploaded or received, which are pending to manage their signature',
      'signed':'These are the documents that have already been signed or have been signed',
      'workflow':'These are the documents you have sent for signature management',
      'templates':'Here you will find some PDF templates, pre-loaded,that you can use to manage and organize your signatures'
    }
  }
};
export default signed_files;
