import React from 'react';
import { connect } from 'react-redux';
import { Button, Drawer, Typography, Col, Row } from 'antd';
import NetcoPKIOption from './NetcoPKIOption';
import P12Option from './P12Option';
import importCertificateFromP12 from '../../actions/certificates/importCertificateFromP12';
import generateCSR from '../../actions/certificates/generateCSR';
import importCertificateFromZIP from '../../actions/certificates/importCertificateFromZIP';
import importCertificateFromFiel from '../../actions/certificates/importCertificateFromFIEL';
import CSROption from './CSROption';
import ZIPOption from './ZIPOption';
import OtherFormatsOption from './OtherFormatsOption';
import AndesOption from './AndesOption';
import certificates from '../../const/certificates';
import { ReactComponent as AndesIcon } from '../../resources/img/NewIcons/certificates/andes.svg'
import { ReactComponent as OtherCertificatesIcon } from '../../resources/img/NewIcons/certificates/other-certificates.svg'
import { ReactComponent as PencilIcon } from '../../resources/img/NewIcons/certificates/pencil.svg';
import { ReactComponent as CheckIcon } from '../../resources/img/NewIcons/certificates/check.svg';
import { ReactComponent as NetcopkiIcon }  from '../../resources/img/NewIcons/certificates/netcoPKI.svg'

class CertificateOptions extends React.Component {

    renderCardCertificates = (title, infocertificate, Icon, secondStep) => {
        return <>
            <div className='card-commom-info mt-10'>
                <Icon style={{ marginRight: 17 }} />                
                <div style={{ display: 'block', width:'80%' }}>
                    <Typography className='size13 color-black'>{title}</Typography>
                    <Typography className='size12'>{infocertificate}</Typography>
                </div>
                <CheckIcon className='right' style={{alignSelf:'center', minWidth:20}} />
            </div>
            <Typography className='text-title size12 mt-10 text-link' onClick={() => this.props.changeOption(0)} >
                <PencilIcon style={{ marginLeft: 5, marginRight: 18 }}  />
                {certificates[this.props.language].changeOption}
            </Typography>
            <Typography className='text-title size12 mt-20' style={{ marginLeft: 12 }}>2. {secondStep.toUpperCase()}</Typography>
        </>
    }

    renderOption = (option, Icon, title, description) => {
        return <Col xs={24} md={12} className='text-center'>
            <Button className='new-card' style={{ width: 290, height: 160 }} onClick={() => this.props.changeOption(option)}>
                {/*<img alt='certificate' src={icon} />*/}
                <Icon  />
                <Typography className='size13 color-black mt-15'>{title}</Typography>
                <Typography className='text-title size12'>{description}</Typography>
            </Button>
        </Col>
    }
    renderOptions = () => {
        const language = this.props.language;
        return <>
            <Row className='mt-20'>
                {this.props.netcoPKI && this.renderOption(1,NetcopkiIcon,certificates[language].pkiTitle,certificates[language].netco_info)}
                {this.renderOption(2,OtherCertificatesIcon,certificates[language].pkcs12Title,certificates[language].p12_info)}
            </Row>
            <Row className='mt-20'>
                {this.renderOption(3,OtherCertificatesIcon,certificates[language].createCSR,certificates[language].csr_info)}
                {this.renderOption(4,OtherCertificatesIcon,certificates[language].zipTitle,certificates[language].zip_info)}
            </Row>
            <Row className='mt-20'>
                {this.renderOption(5,OtherCertificatesIcon,certificates[language].otherTitle,certificates[language].fiel_info)}
                {this.renderOption(6,AndesIcon,certificates[language].andes,certificates[language].andes_info)}
            </Row>
        </>
    }

    renderOptionsCertificates = () => {
        switch (this.props.option) {
            case 0:
                return this.renderOptions()
            case 1:
                return <NetcoPKIOption changeOption={this.props.changeOption} renderCardCertificates={this.renderCardCertificates} />
            case 2:
                return <P12Option changeOption={this.props.changeOption} renderCardCertificates={this.renderCardCertificates} importCertificateFromP12={this.props.importCertificateFromP12} />
            case 3:
                return <CSROption changeOption={this.props.changeOption} renderCardCertificates={this.renderCardCertificates} generateCSR={this.props.generateCSR} />
            case 4:
                return <ZIPOption changeOption={this.props.changeOption} renderCardCertificates={this.renderCardCertificates} importCertificateFromZIP={this.props.importCertificateFromZIP} />
            case 5:
                return <OtherFormatsOption changeOption={this.props.changeOption} renderCardCertificates={this.renderCardCertificates} importCertificateFromFiel={this.props.importCertificateFromFiel} />
            case 6:
                return <AndesOption changeOption={this.props.changeOption} renderCardCertificates={this.renderCardCertificates} />
            default:
                return
        }
    }

    render() {
        const widthApp = this.props.dimApp.width
        return (
            (<Drawer open={this.props.visibleOptions} placement={'right'} width={widthApp < 697 ? widthApp : 697}
                closable={true}
                onClose={() => this.props.showOptionsCertificates(false)}
                drawerStyle={{ backgroundColor: '#FFFFFF' }}
                styles={{header:{ backgroundColor: '#FFFFFF', border:'none' }, body:{ backgroundColor: '#FFFFFF', padding:'0px 40px'}}}>
                <Typography rootClassName='text-title size22 color-black'>{certificates[this.props.language].addCertificates}</Typography>
                <Typography rootClassName='text-title size12' style={{ marginTop: 18 }}>{certificates[this.props.language].selectOption.toUpperCase()}</Typography>
                {this.renderOptionsCertificates()}
            </Drawer>)
        );
    }
}

const mapStateToProps = state => {
    return {
        dimApp: state.locate.dimensionsApp,
        netcoPKI: state.auth.netco
    }
};

export default connect(mapStateToProps, { importCertificateFromP12, generateCSR, importCertificateFromZIP, importCertificateFromFiel })(CertificateOptions);
