import netcoServer from '../../apis/netcoServer';
import {GET_TASK_CONFIGURATION} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const getTaskConfiguration= () => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/getTaskConfiguration', {}));
  handleResponse(dispatch, response, GET_TASK_CONFIGURATION, onSuccessPayload);
};

const onSuccessPayload = data => {
  return {
    adminConfiguration: {
      task: data.result
    }
  };
};
export default getTaskConfiguration;