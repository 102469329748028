const auth={
    es: {
        loginWith: 'Ingresar con',
        signUpWith: 'Crear cuenta con',
        userExistsLabel:'¿Ya tienes una cuenta?',
        modaltitle: 'Imagen de Firma encontrada',
        modalmsg: 'Se encontró una nueva imagen en su documento, desea utilizarla para firmar?',
        yesmsg: 'Si',
        nomsg: 'No',
        messageHome2: 'O ingresa con tu correo',
        messageHome3: 'O usa tu correo',
        messageHome: 'Ingresa a tu cuenta para firmar y administrar tus documentos.',
        messageVerify: 'En esta pantalla puedes verificar tus documentos firmados.',
        usernamelbl: 'Ingresa aquí tu usuario',
        passwordlbl: 'Ingresa tu contraseña',
        loginbtn: 'Ingresar',
        signupbtn: 'Regístrate aquí',
        signuplbl: '¿No tienes una cuenta?',
        signinbtn: 'Ingresa aquí',
        signinlbl: '¿Ya tienes una cuenta?',
        signinlblcert: '¿Ya tienes una cuenta con certificado?',
        forgotbtn: '¿Olvidaste tu contraseña?',
        rights: 'Version 2.4 - 2024 Derechos Reservados - Desarrollado por Netco LTDA',
        loading: 'Cargando...',
        forgotlbl: 'Recupera tu contraseña ingresando tu correo electrónico o tu usuario',
        maillbl: 'Correo electrónico o usuario',
        recoverlbl: 'Recuperar',
        backbtn: 'Regresa',
        sendbtn: 'Enviar',
        resendotpbtn: 'Reenviar Código OTP',
        quickreglbl: 'Registro sencillo (usuario y contraseña)',
        persreglbl: 'Solicita tu certificado digital con tus datos personales ',
        pkireglbl: 'Activa tu certificado digital con los códigos que recibiste en tu email',
        idreglbl: 'Usando identificación',
        regtypelbl: '- También puedes registrarte con cualquiera de las siguientes opciones.',
        quickregdesclbl: 'Selecciona como quieres crear tu cuenta',
        passconfirmlbl: 'Confirma la contraseña',
        newuserbtn: 'Registrate',
        personaldesclbl: 'Al registrarte con tus datos personales recibirás un email con unos códigos de seguridad, para que crees un nuevo usuario y recibas un certificado Netco PKI.',
        namelbl: 'Nombre y Apellido',
        emaillbl: 'Correo Electrónico',
        areacodelbl: 'Código de área',
        phonelbl: 'Número de celular/movil',
        idlbl: 'ID / Cédula',
        nextbtn: 'Continuar',
        requiredfld: 'Campo Requerido',
        invalidmail: 'E-mail inválido',
        countrylbl: 'País de Residencia',
        statelbl: 'Departamento/Estado',
        citylbl: 'Ciudad',
        addresslbl: 'Dirección',
        entnamelbl: 'Nombre de la empresa',
        entarealbl: 'Area de la empresa',
        netcocodesdesclbl: 'Ingresa tu usuario y una nueva contraseña, así como los códigos que recibiste por email para crear tu certificado digital. ',
        netcocodesdesclblPartial: 'Ingresa tu usuario y una nueva contraseña, así como los códigos que recibiste por email para crear tu ',
        netcocodesdesclblPartial2: 'certificado digital',
        digitalCertDefinition: 'Documento digital que permite identificar a las personas en internet, garantizando que la persona que firme electrónicamente un documento sea ella y no otra persona.    ',
        pkiIntro:'Tu empresa ya cuenta con Netco PKI, una plataforma que te permite generar certificados digitales. ',
        moreInfoPKI: 'Mas información',
        referencelbl: 'Número de referencia',
        authcodelbl: 'Código de autorización',
        agreelbl: 'Aceptar Terminos y Condiciones',
        enterotplbl: 'Digite el código de verificación OTP recibido',
        otplbl: 'Código de verificación OTP',
        reqotpmsg: 'El código de verificación es requerido',
        otpsentmsg: 'Se ha enviado un código de verificación al correo ',
        otpsentsmsmsg: 'Se ha enviado un código de verificación vía SMS al número ',
        otpsentcallmsg: 'Se ha generado una llamada para dictar el código al número ',
        otpresent: 'Código reenviado satisfactoriamente',
        otpnocert: 'El tipo de autenticación del usuario requiere un certificado, por favor seleccione una de las siguientes opciones para importarlo y poder ingresar a la plataforma',
        scansqrllbl: 'Escanee el QR con la app Netco Signer para celular',
        restorepasslbl:'Genere una nueva contraseña',
        newpasslbl:'Digite una nueva contraseña',
        newpassconfirmlbl:'Confirme la nueva contraseña',
        passrequired: 'La nueva contraseña es requerida',
        passdontmatch:'Contraseña y confirmación no coinciden',
        invalidparams:'URL inválida',
        passchanged:'Se ha cambiado la contraseña correctamente',
        resendfile: 'Enviar correo',
        cancel:'Cancelar',
        delReason: 'Justificación',
        passwordPoliciesTitle: 'Requisitos de la contraseña:',
        passwordLength: '*Debe tener entre {0} y  {1} caracteres de largo',
        passwordSpecialChars: '*Debe contener al menos un caracter especial como \'?*!\'',
        passwordUpperLower: '*Debe contener al menos una letra mayúscula y una minúscula'
    },
    en: {
        loginWith: 'Login With',
        signUpWith: 'Signup With',
        userExistsLabel:'¿Already registered?',
        modaltitle: 'Signature Image Found',
        modalmsg: 'New Image Found, do you want to use it to sign?',
        yesmsg: 'Yes',
        nomsg: 'No',
        messageHome2: 'Or enter with your email.',
        messageHome3: 'Or signup with your email',
        messageHome: 'Log in to your account to sign and manage your documents.',
        messageVerify: 'On this screen you can verify your signed documents.',
        usernamelbl: 'Enter here',
        passwordlbl: 'Enter your password',
        loginbtn:'Login',
        signupbtn: 'Sign up here.',
        signuplbl: '¿Don"t have an account?',
        signinbtn: 'Login here.',
        signinlbl: '¿Already have an account?',
        signinlblcert: '¿Already have an account with certificate?',
        forgotbtn: 'Did you forget your password?',
        rights: 'Version 2.4 - 2024 All Rights Reserved - Powered By Netco LTDA',
        loading: 'Loading...',
        forgotlbl: '¿Forgot your password?',
        maillbl: 'Email or username.',
        recoverlbl: 'Recover password',
        backbtn: 'Go Back',
        sendbtn: 'Send',
        resendotpbtn: 'Resend OTP code',
        quickreglbl: 'Quick Registration',
        persreglbl: 'Using Personal Data',
        pkireglbl: 'Using Netco PKI Codes',
        idreglbl: 'Using Identification',
        regtypelbl: 'Select registration type',
        quickregdesclbl: 'Choose how to create your account',
        passconfirmlbl: 'Confirm your password',
        newuserbtn: 'Create new user',
        personaldesclbl: 'Request a Netco PKI certificate. Check your mail for some security codes to create a new user and certificate',
        namelbl: 'First and Last Name',
        emaillbl: 'E-mail',
        areacodelbl: 'Area Code',
        phonelbl: 'Cellphone/mobile number',
        idlbl: 'ID Number',
        nextbtn: 'Continue',
        requiredfld: 'Mandatory Field',
        invalidmail: 'Invalid e-mail',
        countrylbl: 'Country of residence',
        statelbl: 'Departament/State',
        citylbl: 'City',
        addresslbl: 'Address',
        entnamelbl: 'Company Name',
        entarealbl: 'Company Department',
        netcocodesdesclbl: 'Select your username, use the codes you got on your mail and create a new password to create a digital certificate',
        netcocodesdesclblPartial: 'Select your username, use the codes you got on your mail and create a new password to create a',
        netcocodesdesclblPartial2: 'digital certificate',
        digitalCertDefinition: 'Digital document that allows to identify people on internet and guarantees integrity when digitally signing documents.',
        pkiIntro:'Your company uses Netco PKI, a platform to issue digital certificates',
        moreInfoPKI: 'More Information',
        referencelbl: 'Reference Number',
        authcodelbl: 'Authorization Code',
        agreelbl: 'Accept terms and conditions',
        enterotplbl: 'Enter verification code',
        otplbl: 'OTP Verification Code',
        reqotpmsg: 'OTP Verification code is mandatory',
        otpsentmsg: 'An OTP has been sent to ',
        otpsentsmsmsg: 'An OTP has been sent via SMS to ',
        otpsentcallmsg: 'A call to deliver an OTP has been generated to ',
        otpresent: 'OTP resent succesfully',
        otpnocert: 'The user authentication type requires a certificate, please select one of the following options to import it and access to Netco Signer',
        scansqrllbl: 'Scan this QR code with Netco Signer App for mobile',
        restorepasslbl:'Create a new password',
        newpasslbl:'Type a new password',
        newpassconfirmlbl:'Confirm your new password',
        passrequired: 'New password is required',
        passdontmatch:"Password and confirmation don't match",
        invalidparams:'Invalid URL',
        passchanged:'Password changed succesfully',
        resendfile: 'Send mail',
        cancel:'Cancel',
        delReason: 'Comment',
        passwordPoliciesTitle: 'Password policies:',
        passwordLength: '*Must be between {0} and  {1} charecters long',
        passwordSpecialChars: '*Must have at least one special character such as \'?*!\'',
        passwordUpperLower: '*Must have one uppercase letter and one lowercase letter'
    }
};
export default auth;