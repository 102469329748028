
const admin = {
  es: {
    licenseType: 'Tipo de licencia',
    licenseTypeDoc: 'Por documento firmado',
    licenseTypeOp: 'Por firma realizada',
    title: 'Opciones de administrador',
    tables_tab: 'Tablas',
    config_tab: 'Configuración',
    system_info: 'Información del sistema',
    system_security_provider: 'Proveedor de seguridad',
    system_state: 'Estado:',
    system_file_size: 'Archivos firmados',
    system_public_size: 'Archivos publicados',
    system_com_size: 'Acuerdos comunicaciones',
    system_wf_size: 'Archivos workflow',
    system_pending_size: 'Archivos pendientes',
    system_hsm: 'Versión librería HSM',
    system_licence_used: 'Usuarios creados',
    system_licence: 'Límite de usuarios',
    system_ops_used: 'Operaciones de firma Usadas',
    system_ops_max: 'Límite de operaciones de firma',
    system_docs_used: 'Documentos Firmados',
    system_docs_max: 'Límite de documentos para firmar',
    id_column: 'Id',
    url_column: 'Url',
    short_name_column: 'Nombre corto',
    oid_column: 'OID',
    user_column: 'Usuario',
    profile_column: 'Perfil',
    authentication_column: 'Autenticación',
    min_psw_column: 'Mínimo clave',
    max_psw_column: 'Maximo clave',
    up_down_column: 'Mayusc y Minusc',
    symbol_column: 'Símbolos',
    admin_column: 'Administrador',
    tx_column: 'Autenticacion por Tx',
    blocked_column: 'Intentos de bloqueo',
    name_column: 'Nombre',
    ip_column: 'IP',
    last_entrance_column: 'Ultimo ingreso',
    state_column: 'Estado',
    operations_column: 'Límite',
    used_column: 'Uso',
    av_column: 'Disponible',
    last_login_column: 'Último login',
    cert_exp_column: 'Validez certificado',
    value_column: 'Valor',
    order: 'Ordenar',
    servers_option: 'Servidores de sello de tiempo',
    profile_option: 'Perfiles',
    ip_option: 'IPs autorizadas',
    user_option: 'Usuarios',
    user_starts: 'Usuario comienza por...',
    del_sqrl: 'Borrar ID',
    password: 'Clave',
    new_password: 'Nueva clave',
    confirm_password: 'Confirmar clave',
    select_cert: 'Seleccionar certificado',
    create_server_modal: 'Crear servidor',
    create_profile_modal: 'Crear perfil',
    create_ip_modal: 'Crear servidor IP',
    create_user_modal: 'Crear usuario',
    edit_server_modal: 'Editar servidor',
    edit_profile_modal: 'Editar perfil',
    edit_ip_modal: 'Editar servidor IP',
    edit_user_modal: 'Editar usuario',
    edit_parameter_modal: 'Editar parámetro',
    config_option: 'Configuración',
    ldap_option: 'LDAP',
    task_option: 'Firma masiva',
    activate_ldap: 'Activar LDAP',
    server_ldap: 'Servidor',
    user_ldap: 'Usuario',
    db_ldap: 'DN base usuarios',
    id_ldap: 'ID de usuario LDAP',
    activate_task: 'Activar firma masiva',
    in_folder_task: 'Carpeta de entrada',
    out_folder_task: 'Carpeta de salida',
    minutes_task: 'Cada cuantos minutos debe correr',
    type_task: 'Tipo de firma',
    processok: 'Proceso realizado correctamente',
    buy_available: 'Productos disponibles',
    buy_info: 'Indique la cantidad de operaciones de Firma a comprar',
    buy_title: 'Compra ya',
    buy_quantity: 'Cantidad',
    buy_unitValue: 'Valor Unitario',
    buy_totalValue: 'SubTotal',
    buy_taxValue: 'Impuesto',
    buy_status: 'Estado',
    buy_button: 'Recargar',
    buy_button_payu: 'Pagar usando payulatam',
    buy_button_epayco: 'Pagar usando epayco',
    buy_button_paypal: 'Pagar usando paypal',
    buy_retry: 'Click aquí para ir a la pasarela de pagos',
    buy_detail: 'Compra de operaciones de firma',
    buy_order: 'Número de orden',
    buy_product: 'Descripción',
    buy_product_added: 'Productos agregados a la orden',
    buy_update: 'Actualizar',
    buy_delete: 'Borrar',
    check_title: 'Tu historial de compras',
    check_info: 'Compras realizadas',
    buy_date: 'Fecha de creación',
    order_ok: 'Operaciones cargadas correctamente, puede consultar su nuevo saldo accediendo a su perfil',
    buy_createdFor: 'Destinatario',
    buy_currentUser: 'Usuario actual',
    buy_anyUser: 'Cualquier Usuario',
    buy_topUptitle: 'Recargar Operaciones de firma',
    buy_activatetitle: 'Activa tu compra',
    buy_subscriptionstitle: 'Suscribete (operaciones ilimitadas)',
    buy_topUpInfo: 'Copia el código que llegó a tu email y da clic en ',
    buy_topUpInfoValidate: 'Validar',
    buy_topUpInfoBuy: 'Recargar',
    buy_topUpInfoContinue: '. Finalmente de clic en ',
    buy_topUpInfoEnd: ' para recargar su cuenta.',
    buy_topUpToken: 'Token',
    buy_topUpValidate: 'Validar',
    buy_topUpUsedBy: 'Usado por',
    buy_topUpCreatedFor: 'Creado para',
    buy_topUpCreatedForTooltip: 'Si son para tu uso, digita tu usuario. Si quieres asignárselos a otra persona, digita su usuario.',
    buy_topUpClear: 'Limpiar',
    order_token: 'Activa tu compra utilizando el siguiente código.',
    buy_modalTitle: '¡Haz realizado con éxito tu compra!',
    buy_showToken: 'Ver código',
    error_epayco: 'Error contactando epayco, intente nuevamente',
    newPassSend: 'Enviar correo para creación de clave',
    viewLogs: 'Log del sistema',
    testSMTP: 'Probar servidor SMTP',
    confirmDeleteUser: "¿Está seguro de borrar el usuario '{0}' ? . Esta operación no se puede deshacer y borrará las credenciales y archivos privados.",
    uids: 'Ids a borrar',
    deletePublic: 'Borrar archivos',
    errorInvalidTokenOperations: 'Token no válido para recarga de operaciones de firma',
    errorInvalidTokenCertificates: 'Token no válido para recarga de certificados',
    pendingLabel: 'Pendiente',
    failedLabel: 'Fallida',
    cancelledLabel: 'Cancelada',
    completedLabel: 'Completada',
    processingLabel: 'En proceso',
    addLabel: 'Asignar',
    productsTitle: 'Productos Comprados',
    assignedTitle: 'Productos asignados',
    pendingTitle: 'Productos por asignar. Selecciona el producto y la cantidad que quieras asignar.',
    confirmActivate: '¿Estás seguro de asignar estos productos? Recuerda que esta acción no se puede deshacer',
    buy_add: 'Agregar',
    buy_del: 'Quitar de la orden',
    buy_bigTotal: 'Total',
    buy_usedDate: 'Fecha de uso',
    selectProducts: 'Selecciona el producto y la cantidad que quieres comprar, luego haz clic en agregar ',
    activateText: 'Tu compra quedó activada .Ya puedes utilizar los productos que adquiriste. ',
    historyText: 'este es tu historial de compras',
    resetSession: 'Reiniciando sesión',
    activationOK: 'Tu usuario ha sido activado, ya puedes acceder a la opción Compras y cerrar esta ventana',
    waitingConfirmTitle: 'Confirmación en progreso.',
    waitingConfirmText: 'Enviamos a tu mail un link para activar tu usuario. Sigue las instrucciones para continuar el proceso. Tan pronto confirmes tu cuenta, esta ventana se cerrará automáticamente',
    typeUserInfo: 'Para acceder a nuestra tienda debes tener un email y un usuario registrado. Si ya estás registrado, digita tu email en la casilla de abajo. Si todavía no te has registrado, nuestro sistema generará automáticamente un usuario para tí',
    typeUserLabel: 'Correo electrónico usado en la tienda',
    confirmBtn: 'Confirmar',
    personalization: 'Personalización',
    system: 'Sistema',
    startupLogo: 'Logo de inicio',
    descriptionStartupLogo: ['Logo usado en las pantallas iniciales', 'El logo se usarásobre blanco, puedes incluir un logo a color'],
    menuLogo: 'Logo para el menú',
    descriptionMenuLogo: ['Logo usado en menús y otros', 'El logo será usado en fondos oscuros'],
    colorTitle: 'Colores personalizados',
    update:'Actualizar',
    restart:'Restablecer valores',
    uploadLogo:'Sube tu logo',
    downloadLogs:'Descargar logs',
    Type:'Tipo',
    mandatory:'Obligatorio',
    values: 'Valores',
    deleteNotifications: 'Eliminar notificaciones',
    deletedNotification: 'Tarea de notificación eliminada correctamente',
    noDeletedNotification: 'No se pudo eliminar la tarea de notificación',
    deleteNotificationsDetail: 'Por favor, ingresa los UID de los archivos o los ID de los procesos de notificación que deseas eliminar. Si son varios, sepáralos por comas.',
    colors: {
      'primary': 'Botones primarios',
      'secondary': 'Botones Secundarios',
      'panels': 'Paneles',
      'siderMenu': 'Menú lateral',
      'textPrimary': 'Textos de los botones primarios',
      'textSecondary': 'Textos de los botones secundarios',
      'textPanels': 'Textos de los paneles',
      'textSiderMenu': 'Textos del menú lateral'
    },
    messageTitle: 'Mensajes personalizados',
    messages: {
      'home1': 'Mensaje superior de la pantalla de inicio y bienvenida',
      'home2': 'Segundo mensaje de la pantalla de inicio',
      'verify': 'Mensaje superior en la pantalla de verificación de archivos firmados'
    },
    tablesList: {
      'users': 'Usuarios',
      'profiles': 'Perfiles',
      'servers': 'Servidores de sello de tiempo',
      'webservices': 'IPs autorizadas',
      'metaData': 'Metadatos'
    }
  },
  en: {
    licenseType: 'License type',
    licenseTypeDoc: 'Per signed document',
    licenseTypeOp: 'Per signature',
    title: 'Administrator options',
    tables_tab: 'Tables',
    config_tab: 'Configuration',
    system_info: 'System information',
    system_security_provider: 'Security provider:',
    system_state: 'Status:',
    system_file_size: 'Signed files',
    system_public_size: 'Public files',
    system_com_size: 'Communication',
    system_wf_size: 'Workflow files',
    system_pending_size: 'Pending files',
    system_hsm: 'HSM library version',
    system_licence_used: 'Used licences',
    system_licence: 'Total of licences',
    system_ops_used: 'Used operations',
    system_ops_max: 'Maximum operations',
    system_docs_used: 'Signed documents',
    system_docs_max: 'Documents limit',
    id_column: 'Id',
    url_column: 'Url',
    short_name_column: 'Short name',
    oid_column: 'OID',
    user_column: 'User',
    profile_column: 'Profile',
    authentication_column: 'Authentication',
    min_psw_column: 'Password min',
    max_psw_column: 'Password max',
    up_down_column: 'Mayusc y Minusc',
    symbol_column: 'Symbols',
    admin_column: 'Administrator',
    tx_column: 'Authentication for Tx',
    blocked_column: 'Block attempts',
    name_column: 'Name',
    ip_column: 'IP',
    last_entrance_column: 'Last entrance',
    state_column: 'Status',
    operations_column: 'Maximum operations',
    used_column: 'Used Operations',
    av_column: 'Available Operations',
    last_login_column: 'Last login',
    cert_exp_column: 'Certificate expiry',
    value_column: 'Value',
    order: 'Order',
    servers_option: 'Timestamp servers',
    profile_option: 'Profiles',
    ip_option: 'Authorized IPs',
    user_option: 'Users',
    user_starts: 'User starts with…',
    del_sqrl: 'Delete ID',
    password: 'Password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    select_cert: 'Select certificate',
    create_server_modal: 'Create server',
    create_profile_modal: 'Create profile',
    create_ip_modal: 'Create IP server',
    create_user_modal: 'Create user',
    edit_server_modal: 'Edit server',
    edit_profile_modal: 'Edit profile',
    edit_ip_modal: 'Edit IP server',
    edit_user_modal: 'Edit user',
    edit_parameter_modal: 'Edit parameter',
    config_option: 'Configuration',
    ldap_option: 'LDAP',
    task_option: 'Masive signature',
    activate_ldap: 'Activate LDAP',
    server_ldap: 'Server',
    user_ldap: 'User',
    db_ldap: 'DN user base',
    id_ldap: 'ID of LDAP user',
    activate_task: 'Activate massive signature',
    in_folder_task: 'Input folder',
    out_folder_task: 'Output folder',
    minutes_task: 'Minutes between runs',
    type_task: 'Signature type',
    processok: 'Succesful',
    buy_available: 'Available products',
    buy_info: 'Select operations to buy',
    buy_title: 'Buy Signatures',
    buy_quantity: 'Quantity',
    buy_unitValue: 'Unit Value',
    buy_totalValue: 'Total Value',
    buy_taxValue: 'Tax',
    buy_status: 'Status',
    buy_button: 'Buy',
    buy_button_payu: 'Buy with payulatam',
    buy_button_epayco: 'Buy with epayco',
    buy_button_paypal: 'Buy with paypal',
    buy_retry: 'Click here to navigate to payment site',
    buy_detail: 'Buy Signature Operations',
    buy_order: 'Order Number',
    buy_product: 'Available Products',
    buy_product_added: 'Products in order',
    buy_update: 'Update',
    buy_delete: 'Delete',
    check_title: 'Purchase History',
    check_info: 'Orders created',
    buy_date: 'Creation Date',
    order_ok: 'Operations Updated Successfully, you may check your new balance by logging into your profile',
    buy_createdFor: 'Created for',
    buy_currentUser: 'Current User',
    buy_anyUser: 'Any User',
    buy_topUptitle: 'Top Up Signature Operations',
    buy_activatetitle: 'Activate order',
    buy_subscriptionstitle: 'Subscription',
    buy_topUpInfo: 'Paste your token and click ',
    buy_topUpInfoValidate: 'validate',
    buy_topUpInfoBuy: 'buy',
    buy_topUpInfoContinue: '. Then click ',
    buy_topUpInfoEnd: '  to top-up your account.',
    buy_topUpToken: 'Token',
    buy_topUpValidate: 'Validate',
    buy_topUpUsedBy: 'Used By',
    buy_topUpCreatedFor: 'Created for',
    buy_topUpCreatedForTooltip: 'If it is for you type your username, If you want to assing them to someone else type his/her username.',
    buy_topUpClear: 'Clear',
    order_token: 'This is your token to top up signature operations, please login and access Top up Operations to use it: ',
    buy_modalTitle: 'Topup token',
    buy_showToken: 'Show Tokens',
    error_epayco: 'Error contacting epayco, try again',
    newPassSend: 'Send mail for password creation',
    viewLogs: 'System log',
    testSMTP: 'Test SMTP Server',
    confirmDeleteUser: "¿Are you sure to delete user {0} ? . This operation cannot be undone and it will delete his user's files and credentials.",
    uids: 'Id"s to delete',
    deletePublic: 'Delete files',
    errorInvalidTokenOperations: 'Invalid token to top up signature operations',
    errorInvalidTokenCertificates: 'Invalid token to top up certificates',
    pendingLabel: 'Pending',
    failedLabel: 'Failed',
    cancelledLabel: 'Cancelled',
    completedLabel: 'Completed',
    processingLabel: 'Processing',
    addLabel: 'Add',
    productsTitle: 'Ordered Products',
    assignedTitle: 'Assigned Products',
    pendingTitle: 'Pending to assign',
    confirmActivate: '¿Are you sure you want to assign the following products? You cannot undo this action',
    buy_add: 'Add to order',
    buy_del: 'Remove from order',
    buy_bigTotal: 'Total',
    buy_usedDate: 'Used date',
    selectProducts: 'Select all products you want to buy form the following list and choose the amount for each one',
    activateText: 'Your order has been processed. You may use your products',
    historyText: 'this is your purchase history',
    resetSession: 'Restarting session',
    activationOK: 'You user has been activated, you may access to buy and close this window',
    waitingConfirmTitle: 'Confirmation in progress...',
    waitingConfirmText: 'We"ve sent you an email to activate your user. Please follow the instructions to continue the process. This window will close automatically when you confirm your account',
    typeUserInfo: 'To access our shop you need a valid user, if you have it please type the associaited email on the text field',
    typeUserLabel: 'Email use on the shop',
    confirmBtn: 'Confirm',
    personalization: 'Personalization',
    system: 'System',
    startupLogo: 'Startup Logo',
    descriptionStartupLogo: ['Logo used in the initial screens', 'The logo will be used on white, you can include a color logo'],
    menuLogo: 'Logo for the menu',
    descriptionMenuLogo: ['Logo used in menus and others', 'The logo will be used on dark backgrounds'],
    colorTitle: 'Custom Colors',
    update:'Update',
    restart:'Reset values',
    uploadLogo:'Upload your logo',
    downloadLogs:'Download logs',
    Type:'Type',
    mandatory: 'Mandatory',
    values: 'Values',
    deleteNotifications: 'Delete notifications',
    deletedNotification: 'Notification task successfully deleted',
    noDeletedNotification: 'Failed to delete notification task',
    deleteNotificationsDetail: 'Please enter the UIDs of the files or the IDs of the notification processes you want to delete. If there are multiple, please separate them by commas.',
    colors: {
      'primary': 'Primary Buttons',
      'secondary': 'Secondary Buttons',
      'Panels': 'Panels',
      'siderMenu': 'Side Menu',
      'textPrimary': 'Texts of the primary buttons',
      'textSecondary': 'Secondary button texts',
      'textPanels': 'Panel Texts',
      'textSiderMenu': 'Side Menu Texts'
    },
    messageTitle: 'Custom messages',
    messages: {
      'home1': 'Home and welcome screen top message',
      'home2': 'Second home screen message',
      'verify': 'Top message on signed file verification screen'
    },
    tablesList: {
      'users': 'Users',
      'profiles': 'Profiles',
      'servers': 'Timestamp servers',
      'webservices': 'Authorized IPs',
      'metaData': 'Metadata'
    }
  }
};
export default admin;
