import netcoServer from '../../apis/netcoServer';
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const confirmWordpress= (formValues,callback,callbackError) => async (dispatch) =>
{
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/confirmWP', formValues));
  handleResponse(dispatch, response, null, null, null, [],[callback],[callbackError]);
};
export default confirmWordpress;
