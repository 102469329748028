import React from "react";
import {connect} from "react-redux";
import checkWordpress from "../../actions/admin/checkWordpress";
import {getQueryParams} from "../Helpers/utils";
import redirect from "../../actions/auth/redirect";
import showMessage from "../../actions/info/showMessage";
import admin from "../../const/admin";

class CheckWordPress extends React.Component
{
    componentDidMount()
    {
        this.props.checkWordpress({userName:decodeURIComponent(this.props.confirm)},()=>{
            this.props.showMessage('info', admin[this.props.language].activationOK);
            setTimeout(window.close,5000);
        },()=>{});
    }

    render()
    {
        return "";
    }
}
const mapStateToProps = state => {
    let queryParams = getQueryParams(state.router.location.search);
    return {
        language: state.auth.language,
        confirm: queryParams.confirm
    };
};

export default connect(mapStateToProps, {checkWordpress,redirect,showMessage})(CheckWordPress);