export const pendingDocsInitialValues={
    pageSizes:[10,50,100,1000],
    initialPageSize:10,
    sidx: 'lastModified',
    sord: 'asc',
    addTotalToSizes:true
}
export const workflowDocsInitialValues={
    pageSizes:[10,50,100,1000],
    initialPageSize:10,
    sidx: 'creationDate',
    sord: 'asc',
    addTotalToSizes:true
}
export const privateDocsInitialValues={
    pageSizes:[10,50,100,1000],
    initialPageSize:10,
    sidx: 'lastModified',
    sord: 'desc',
    addTotalToSizes:true
}
export const publicDocsInitialValues={
    pageSizes:[10,100,1000],
    initialPageSize:10,
    sidx: 'lastModified',
    sord: 'desc',
    addTotalToSizes:true
}
export const useSignaturePAD=true;