import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import {Form, Button, Modal} from "antd";

// import 'antd/lib/modal/style/css';


import {reduxForm} from "redux-form";
import TitleAndInfo from '../../Helpers/TitleAndInfo';
import auth from "../../../const/auth";

class ConfirmModal extends React.Component {

  render() {
    return (
      (<Modal
          open={this.props.modalType !== null} width={400} {...this.props.modalProps} title={this.props.modalProps.title}>
        <Form onFinish={this.props.handleSubmit(this.props.onSubmit)}>
          <TitleAndInfo info={this.props.info}/>
            {this.props.renderComponent?this.props.renderComponent():''}
          <div style={{'textAlign': 'center'}}>
          <br/>
          <br/>
          <Button htmlType={'submit'} type={'primary'}>{auth[this.props.language].yesmsg}</Button>
          <Button type={'primary'} onClick={this.props.onCancel}>{auth[this.props.language].nomsg}</Button>
          </div>
        </Form>
      </Modal>)
    );
  }


}

export default reduxForm({form: 'confirm_modal_form'})(ConfirmModal);
