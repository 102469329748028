import React from 'react';
import PublicLayout from "../../Layouts/Public";
import PasswordForm from "../PasswordForm";
import auth from "../../../const/auth";
import logoutUser from "../../../actions/auth/logoutUser";
import isLoggedIn from "../../../actions/auth/isLoggedIn";
import {connect} from "react-redux";
import PasswordPolicies from "../../PasswordPolicies";
import {Alert} from "antd";

class RestorePassword extends React.Component {

  componentDidMount()
  {
    this.props.logoutUser(true,false).then(()=>this.props.isLoggedIn());
  }

  render() {
    return (
      <PublicLayout title={'logo'}>
          <Alert style={{maxHeight:100, color:'#8F9BB3'}} className={'text-center info size12'}
                 message={auth[this.props.language].restorepasslbl} type="info"/>
        <PasswordPolicies/>
        <PasswordForm language={this.props.language} />
      </PublicLayout>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.auth.language    
  };
};

export default connect(mapStateToProps,{logoutUser,isLoggedIn})(RestorePassword);