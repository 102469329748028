import netcoServer from '../../apis/netcoServer';
import {GET_PAGES_REPORT} from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";

const getPagesReport= (formValues) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getPagesReport', formValues));
  handleResponse(dispatch, response, GET_PAGES_REPORT, onSuccessPayload)
};

const onSuccessPayload = data => {
  return {
    userReportList: {
      pagesReport: data.result.pages
    }
  };
};
export default getPagesReport;