import React from 'react';
import { connect } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import QRCode from "react-qr-code";
import { Button, Checkbox, Tooltip, Spin } from "antd";
import signature from '../../const/signature';
import { SIGNATUREQR_ROUTE } from '../Root/routes';
import { getQueryParams, showFull } from '../Helpers/utils';
import { useSignaturePAD } from '../../apis/generalValues'
import smartphone from '../../resources/img/smartphone.png';
import stepOverPad from '../../resources/img/stepoverpad.png';
import { FormOutlined } from '@ant-design/icons';
import updateHWImage from '../../actions/signature/updateHWImage';
import setHWImage from '../../actions/signature/setHWImage';
import getHWImage from '../../actions/signature/getHWImage';
import OtherOptions from './OtherOptions';
import { HANDWRITTEN_MODAL } from '../Feedback/Modal/types';
import modalShow from '../../actions/modal/modalShow';
import modalDismiss from '../../actions/modal/modalDismiss';
import showMessage from '../../actions/info/showMessage';


const isPageMobile = window.location.href.includes(SIGNATUREQR_ROUTE);
const widthPad = 254;
const heightPad = 148;

class HandwrittenForm extends React.Component {

    state = { urlQR: '', padOverwrite: false }

    componentDidMount() {
        setTimeout(() => {
            if (this.props.padData && this.sigPad)
                this.sigPad.fromData(this.props.padData);
        }, 50);
        this.initTimerStates();
    }

    componentDidUpdate(prevProps) {
        const currentInfoImageQR = this.props.signatureFiles.infoImageQR;
        if (currentInfoImageQR && prevProps.signatureFiles.infoImageQR.state !== currentInfoImageQR.state) {
            if (currentInfoImageQR.state === 'cancelled') {
                this.clearIntervals();
                if (this.props.onSubmit)
                    this.props.onSubmit()
                if (!isPageMobile)
                    this.initTimerStates()
            }
            if (currentInfoImageQR.state === 'finished') {
                this.clearIntervals();
            }
        }
        if (prevProps.useImage !== this.props.useImage) {
            if (this.props.useImage === '2')
                this.initTimerStates();
            else {
                this.clearIntervals();
                this.props.updateHWImage(JSON.stringify({ state: "cancelled" }))
            }
        }
        if (prevProps.hwimgid !== this.props.hwimgid && this.props.hwimgid)
            this.initTimerStates();

        if (!prevProps.signatureFiles.loadingPad && this.props.signatureFiles.loadingPad) {
            this.initTimerStates();
        }
    }

    componentWillUnmount() {
        this.setState({ urlQR: '' });
        this.clearIntervals();
    }

    initTimerStates = async () => {
        if (isPageMobile)
            this.timerSendImage = setInterval(() => this.sendImage(), 1000);
        else {
            if (this.props.signatureFiles.hwimgid && this.props.signatureFiles.infoImageQR.state !== 'drawingPad') {
                await this.props.getHWImage({ "id": this.props.signatureFiles.hwimgid }, this.props.signatureFiles.infoImageQR.state);
                const urlQR = window.location.origin + window.location.pathname + '#' + SIGNATUREQR_ROUTE + '?hwimgid=' + this.props.hwimgid;
                console.log(urlQR)
                this.setState({ urlQR: urlQR, padOverwrite: this.props.padOverwrite },
                    () => {
                        if (this.props.signatureFiles.infoImageQR.state !== 'drawing' && this.props.signatureFiles.infoImageQR.state !== 'drawingPad')
                            this.props.setTimerGetImageQR();
                    }
                )
            }
        }
    }

    onStartPadSigning = () => {
        if (this.props.onStartPadSigning)
            this.props.onStartPadSigning();
    }

    clearIntervals = () => {
        clearInterval(this.timerSendImage);
        this.timerSendImage = null
        if (!this.props.signatureFiles.infoImageQR.base64 && this.props.clearTimerGetImageQR)
            this.props.clearTimerGetImageQR();
    }

    trimImage = async (state, image) => {
        if (isPageMobile)
            await this.sendImage(state)
        //this.clearIntervals();
        const sigPad = this.sigPad;
        let data = "";
        if (sigPad)
            data = sigPad.toData();
        if (image) {
            this.clear()
            this.sigPad.fromDataURL(image, { height: heightPad, width: widthPad })
        }
        const sendImage = image ? image.replace("data:image/png;base64,", "") : this.getCanvasImage()
        this.props.onSubmit(sendImage, data);
    };

    clear = () => {
        this.sigPad.clear();
    }

    getCanvasImage = () => {
        if (!this.sigPad)
            return "";
        return this.sigPad.getCanvas().toDataURL('image/png').replace("data:image/png;base64,", "");
    }

    sendImage = async (state = "drawing") => {
        const base64 = this.getCanvasImage();
        const info = { state, base64 };
        const data = {
            id: this.props.params.hwimgid,
            info: JSON.stringify(info)
        }
        await this.props.setHWImage(data);
    }

    changeSaveImage = () => {
        this.props.handlePadOverwriteChange();
        this.setState({ padOverwrite: !this.state.padOverwrite })
    }

    getStyleOptionButtons = (pressed, disabled = null) => {
        if (pressed)
            return 'button-pressed'
        else if (disabled)
            return 'button-disabled'
        else
            return 'button-enabled'
    }

    renderBtnPad = (action) => {
        const disabledPad = typeof this.props.signatureFiles.loadingPad === 'undefined';
        return <Tooltip title={disabledPad ? signature[this.props.language].disabledPad : signature[this.props.language].enabledPad}>
            {this.props.signatureFiles && this.props.signatureFiles.loadingPad ? <Spin style={{ marginRight: 10 }} /> :
                <Button className={this.getStyleOptionButtons(false, disabledPad)} style={{ marginRight: '10px' }} icon={<FormOutlined />} disabled={disabledPad}
                    type={'primary sign-btn'} htmlType={'button'} onClick={action} />}
            <></>
        </Tooltip>
    }

    copyText = () => {
        navigator.clipboard.writeText(this.state.urlQR)
            .then(() => {
                this.props.showMessage('success', signature[this.props.language].copyQR);
            })
            .catch(err => {
                this.props.showMessage('error', signature[this.props.language].errCopyQR);
                console.error(signature[this.props.language].errCopyQR+': ', err);
            });
    };

    renderQR = () => {
        return <>
            <QRCode className="mt-10 mb-10" size={125} value={this.state.urlQR} />
            <Button type='ghost' onClick={this.copyText}>
                {signature[this.props.language].copyShareQR}
            </Button>
        </>
    }

    renderOptionQR = () => {
        const infoQR = <>{signature[this.props.language].signatureTextTypeID[2].instructions[0]}<br />
            {signature[this.props.language].signatureTextTypeID[2].instructions[1]}<br />
            {signature[this.props.language].signatureTextTypeID[2].instructions[2]}<br /></>

        return <OtherOptions text={signature[this.props.language].handwrittenSignatureOptions[0]} text2={infoQR}
            renderQR={this.renderQR} />
    }

    openHandwrittenModal = (isMobile) => {
        this.props.modalShow({
            modalType: HANDWRITTEN_MODAL,
            modalProps: { footer: null, title: null },
            componentProps: {
                width: isMobile ? this.props.dimApp.width - 50 : 674, height: isMobile ? this.props.dimApp.height - 100 : 172, language: this.props.language, clear: this.clear, trimImage: this.trimImage, modalDismiss: this.props.modalDismiss,
            }
        });
    }

    renderCanvas = () => {
        const isMobile = !showFull()
        return <>
            <OtherOptions text={signature[this.props.language].handwrittenSignatureOptions[2]} />
            <div style={{ position: 'relative' }}>
                <SignatureCanvas throttle={30} minDistance={0} dotSize={1} maxWidth={1} minWidth={1}
                    ref={(ref) => this.sigPad = ref} canvasProps={{ height: heightPad, width: isMobile ? this.props.dimApp.width - 50 : widthPad, className: 'canvasBorder mb-10 mt-10' }} />
                <Button className='unstyled-button' onClick={() => this.openHandwrittenModal(isMobile)} style={{ position: 'absolute', top: 10, right: 10, padding: 0 }}><FormOutlined /></Button>
            </div>
            <div style={{ display: 'flex' }}>
                <Button className='right' type='ghost' onClick={this.clear}>{signature[this.props.language].clear.toUpperCase()}</Button>
                <Button className='right' type='primary' onClick={() => { this.trimImage('finished'); this.props.closeDrawer && this.props.closeDrawer(); }}>{signature[this.props.language].accept.toUpperCase()}</Button>
            </div>
        </>
    }

    render() {
        const drawState = this.props.signatureFiles.infoImageQR.state;
        if (drawState === 'drawing' || drawState === 'drawingPad') {
            const tittle = drawState === 'drawing' ? signature[this.props.language].handwrittenSignatureMobile : signature[this.props.language].handwrittenSignaturePad;
            const text = drawState === 'drawing' ? signature[this.props.language].messageUseSignatureMobile : signature[this.props.language].messageUseSignaturePad
            const image = drawState === 'drawing' ? smartphone : stepOverPad;
            return <OtherOptions text={tittle} text2={text} image={image} />;
        }
        else {
            return <>
                {this.renderCanvas()}
                {this.renderOptionQR()}
                {useSignaturePAD && <>
                    <OtherOptions className='mt-10' text={signature[this.props.language].handwrittenSignatureOptions[1]} />
                    {this.renderBtnPad(this.onStartPadSigning)}
                </>}
                <OtherOptions className='mt-10' text={'Guarda tu firma manuscrita'} />
                <Checkbox defaultChecked checked={this.state.padOverwrite} className='size13 color-text-secondary'
                    onChange={this.changeSaveImage}>{signature[this.props.language].saveHandwrittenSignature}</Checkbox>
            </>;
        }
    }
}

const mapStateToProps = state => {
    return {
        signatureFiles: state.signatureFiles,
        language: state.auth.language,
        params: getQueryParams(state.router.location.search),
        useImage: state.user.useImage,
        dimApp: state.locate.dimensionsApp,
    };
};

export default connect(mapStateToProps, { getHWImage, setHWImage, updateHWImage, modalShow, modalDismiss, showMessage })(HandwrittenForm);