import netcoServer from '../../apis/netcoServer';
import {UPDATE_USER_IMAGE} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import loadingCall from "../helpers/loadingCall";
import modalDismiss from "../modal/modalDismiss";

const updateUserImage= (base64Image, fromText=null,fontName=null) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/updateUserImage', {base64Image: base64Image,fromText:fromText,fontName:fontName}));
  handleResponse(dispatch, response, UPDATE_USER_IMAGE, onSuccessPayload, null, [modalDismiss]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Firma grafica actualizada satisfactoriamente!');
};
export default updateUserImage;