import React, {Component} from 'react';

class PublicHeaderMenu extends Component {

  render()
  {
    //const language=this.props.language,pathName=this.props.pathname;
    return <></>;
    /*return (
      <Menu theme="light" mode={this.props.mode} defaultSelectedKeys={['/']} selectedKeys={[pathName.split('/')[1]]} style={{lineHeight: '32px'}}>
        <Menu.Item style={{backgroundColor: '#ffffff'}} onClick={()=> this.props.changeLanguage(language)}>
          {language === 'es' ? <img style={{width: 30}} alt={'COL flag'} src={coSvg}/> : <img style={{width: 30}} alt={'US flag'} src={usSvg}/>}
        </Menu.Item>
      </Menu>
    );*/
  }
}

export default PublicHeaderMenu;
