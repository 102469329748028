import React from 'react';
import {Card, Col, Modal, Row} from "antd";

// import 'antd/lib/modal/style/css';


// import 'antd/lib/col/style/css';


// import 'antd/lib/card/style/css';


// import 'antd/lib/row/style/css';


import moment from "moment";
import verify from "../../const/verify";

const VerificationsModal = (props) => {

  let fileName=props.entry.originalFileNameOID!==''?props.entry.originalFileNameOID:props.entry.originalFileName;
  if(!fileName || fileName==="")
    fileName=props.entry.fileName[0].replace(".zip","");
  const renderContent = () => {
    if(props.entry.valid !== '?') {
      return (
          <Row>
            <Col xs={24} md={8}>
              <Card>
                <h2>{verify[props.language].signer_info}</h2>
                <Row style={{display:'block'}}>
                  <label><strong>{verify[props.language].signer_name}</strong></label>
                  <p>{props.entry.signatureAuthor}</p>
                </Row>
                <Row style={{display:'block'}}>
                  <label><strong>{verify[props.language].signer_contact}</strong></label>
                  <p>{props.entry.contactInfo}</p>
                </Row>
                <Row style={{display:'block'}}>
                  <label><strong>{verify[props.language].signer_entity}</strong></label>
                  <p>{props.entry.signatureCertIssuer}</p>
                </Row>
                <Row>
                  <label><strong>{verify[props.language].signer_validity}</strong></label>
                  <p>{props.entry.signatureCertValidity}</p>
                </Row>
              </Card>
            </Col>
            <Col xs={24} md={8}>
              <Card>
                <h2>{verify[props.language].signature_info}</h2>
                <Row style={{display:'block'}}>
                  <label><strong>{verify[props.language].signature_date}</strong></label>
                  <p>{moment(props.entry.signatureDate).format('DD/MM/YYYY - HH:mm:ss')}</p>
                </Row>
                <Row style={{display:'block'}}>
                  <label><strong>{verify[props.language].signature_comment}</strong></label>
                  <p>{props.entry.reason}</p>
                </Row>
                <Row style={{display:'block'}}>
                  <label><strong>{verify[props.language].signature_location}</strong></label>
                  <p>{props.entry.location}</p>
                </Row>
                <Row style={{display:'block'}}>
                  <label><strong>{verify[props.language].signature_ltv}</strong></label>
                  <p>{props.entry.ltv}</p>
                </Row>
                {props.entry.originalFile && props.entry.originalFile.length > 0 && props.entry.originalFile[0] !=='' ?<Row style={{display:'block'}}>
                  <label><strong>{verify[props.language].originalFile}</strong></label>
                  <p><a download={fileName} href={'data:application/octet-stream;base64,' + props.entry.originalFile}>{fileName}</a></p>
                </Row>:''}
              </Card>
            </Col>
            <Col xs={24} md={8}>
              <Card>
                <h2>{verify[props.language].timestamp_info}</h2>
                <Row style={{display:'block'}}>
                  <label><strong>{verify[props.language].timestamp_authority}</strong></label>
                  <p>{props.entry.timeStampAuthority}</p>
                </Row>
                <Row style={{display:'block'}}>
                  <label><strong>{verify[props.language].timestamp_date}</strong></label>
                  <p>{props.entry.timeStampDate ? moment(props.entry.timeStampDate).format('DD/MM/YYYY - HH:mm:ss') : props.entry.timeStampDate}</p>
                </Row>
              </Card>
            </Col>
          </Row>
      );
    }
  };

  return (
    (<Modal
      open={props.modalType !== null} width={720} {...props.modalProps}>
      <Row>
        <h2>{props.entry.fileName[0]}</h2>
      </Row>
      <Row>
        <p>{props.entry.processedError}</p>
      </Row>
      {renderContent()}
    </Modal>)
  );
};

export default VerificationsModal;
