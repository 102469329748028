const  handleBase64ZipResponse= (base64Zip, fileName) => {
  let binaryString =  window.atob(base64Zip);
  let binaryLen = binaryString.length;

  let ab = new ArrayBuffer(binaryLen);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < binaryLen; i++) {
    ia[i] = binaryString.charCodeAt(i);
  }
  let blob = new Blob([ab]);
  let csv = window.document.createElement("a");
  csv.href = window.URL.createObjectURL(blob);
  csv.download = fileName;
  document.body.appendChild(csv);
  csv.click();
  document.body.removeChild(csv);
}
export default handleBase64ZipResponse;