import netcoServer from '../../apis/netcoServer';
import {IMPORT_CERTIFICATE_FROM_ENTRUST_EPF} from "../types";
import {ROOT_ROUTE} from "../../components/Root/routes";
import handleResponse from '../helpers/handleResponse';
import getBase64File from '../helpers/getBase64File';
import infoPayload from '../helpers/payloadHandlers/infoPayload';
import _ from 'lodash';
import loadingCall from "../helpers/loadingCall";
import certificates from "../../const/certificates";
import {push} from "connected-react-router";
import logoutUser from '../auth/logoutUser';
import getValidOperations from "../signature/getValidOperations";

const importCertificateFromEntrustEPF= (formValues, file) => async (dispatch) => {
  formValues.base64File = await getBase64File(file);
  const onSuccessPayload = () => {
	 if(formValues.requiresCert)
	{
		dispatch(logoutUser());
		dispatch(push('/'));
	}
	return infoPayload('success', certificates[formValues.language].processok);
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/ImportService/importCertificateFromEntrustEPF', _.omit(formValues, ['avatar','language','requiresCert'])));
  handleResponse(dispatch, response, IMPORT_CERTIFICATE_FROM_ENTRUST_EPF, onSuccessPayload, ROOT_ROUTE,[getValidOperations]);
};
export default importCertificateFromEntrustEPF;
