import React, { Component } from 'react';
import { Menu, Dropdown, Typography } from 'antd';
import Text from "antd/es/typography/Text";
import layout from '../../../../const/layout';
import {NEW_PENDING_FILES_ROUTE, PROFILE_ROUTE} from "../../../Root/routes";
import dropDownIcon from "../../../../resources/img/NewIcons/dropdown.svg"
import help from "../../../../resources/img/NewIcons/header/help.svg"
import userIcon from '../../../../resources/img/NewIcons/header/user.svg'
import configurationIcon from '../../../../resources/img/NewIcons/header/configuration.svg'
import {ReactComponent as PendingFilesIcon} from '../../../../resources/img/NewIcons/header/pendingFilesIcon.svg'
import logoutIcon from '../../../../resources/img/NewIcons/header/logout.svg'
import menuIcon from '../../../../resources/img/NewIcons/menu.svg'
import { getCustomLogos, hideMenuPath, showFullMenus } from '../../../Helpers/utils';

import { renderLanguageOptions, renderCertificate, getCustomColors, signerHelpURL } from '../../../Helpers/utils';

class PrivateHeaderMenu extends Component {

  createImageFromInitials = (size, name) => {
    const customColors = getCustomColors()
    if (name == null) return;
    name = name[0].toUpperCase()
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size
    context.fillStyle = customColors.colorPrimary
    context.fillRect(0, 0, size, size)
    context.fillStyle = customColors.colorTextPrimary;
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.font = `bold ${size / 1.5}px IBM Plex Sans`
    context.fillText(name, (size / 2), (size / 1.9))
    return canvas.toDataURL()
  };

  handleItemClick=(evt)=>
  {
      switch(evt.key)
      {
          case "profile":
              this.props.push(PROFILE_ROUTE);
              break;
          case "configurations":
              this.props.showOptionsSignature(true);
              break;
          case "appconfigurations":
              this.props.showAppOptions(true);
              break;
          case "logout":
              this.props.logOut(true, true, false);
              break;
          default:
              break;
      }

  };

  renderProfileOptions = () => {
    const items=[
        {
            key:'userName', label: <>{this.props.cn ? <>{this.props.cn}<br /></> : null}{this.props.userName}</>
            ,className:'size12 center-vertical-profile height69',disabled:true
        },
        {
            key:'profile', label: layout[this.props.language].header_profile
            ,className:'center-vertical-profile height50', icon: <img alt={''} className={'new-netco-icon'} src={userIcon} />
        },
        {
            key:'configurations', label:layout[this.props.language].header_configuration_sign
            ,className:'center-vertical-profile height50', icon: <img alt={''} className={'new-netco-icon'} src={configurationIcon} />
        },
        {
            key:'appconfigurations', label:layout[this.props.language].header_appconfiguration_sign
            ,className:'center-vertical-profile height50', icon: <img alt={''} className={'new-netco-icon'} src={configurationIcon} />
        },
        {
            key:'logout', label: layout[this.props.language].header_logOut
            ,className:'center-vertical-profile height50', icon: <img alt={''} className={'new-netco-icon'} src={logoutIcon} />
        }];
      return <Dropdown
          dropdownRender={()=>(
        <Menu className='size15' style={{ width: 260 }} items={items} onClick={this.handleItemClick}/>
      )}
      trigger={['click']}>
      <Text className="ant-dropdown-link" onClick={e => e.preventDefault()}>
        <img style={{ width: '40px', height: '40px', borderRadius: '50%', verticalAlign:'middle' }}
          src={this.createImageFromInitials(500, this.props.cn ? this.props.cn : this.props.userName)} alt='profile-pic' />
        <img alt='dropdown' className='margin-left-drop-icon' src={dropDownIcon} />
      </Text>
    </Dropdown>
  }

  renderTitle = (showFull) => {
    const title = <Typography className='text-title size22' style={!showFull ? { marginLeft: 16, minWidth:'132px' } : { marginLeft: 36, minWidth: 270 }}>{layout[this.props.language].sider_path[this.props.pathname]}</Typography>
    if (layout[this.props.language].sider_path[this.props.pathname]){
      if(showFull){
        return title
      }else if(this.props.pathname !== '/signature/firmar' && this.props.pathname !== '/locate'){
        return title
      }
    }
      
  }

  render() {
    const showFull = showFullMenus(this.props.dimApp.width)
    return (
      <>
        <div className='center-vertical-profile' style={!showFull ? {marginTop:20, marginRight:12, marginBottom:17 } : { marginTop: 33 }}>
          {!this.props.hideAllMenu && <button className='unstyled-button' style={{ marginLeft: 16 }} onClick={() => {
              if(showFull)
                this.props.showMenu(!this.props.visibleMenu)
              else
                this.props.showSiderDrawer(!this.props.visibleSiderDrawer);
          }} >
            <img alt={''} src={menuIcon} />
          </button>}
          {hideMenuPath(this.props.pathname) && <img className={'header-logo'} style={{marginLeft:32}} src={getCustomLogos().base64Logo} alt="" />}
          {this.renderTitle(showFull)}
          <div className='sider-menu' style={!showFull ? { marginRight: 12 } : { marginRight: 46 }}>
            {showFull && this.props.caID ? renderCertificate(this.props.caDescription, this.props.push, this.props.language, { marginRight: 28 }) : null}
              <button className='unstyled-button' style={{ marginRight: 16, verticalAlign:'middle' }} onClick={()=>this.props.push(NEW_PENDING_FILES_ROUTE)}>
                  <PendingFilesIcon />
                  <span>{this.props.pendingTotal}</span>
              </button>
            <button className='unstyled-button' style={{ marginRight: 16 }} onClick={() => window.open(signerHelpURL)} >
              <img alt='help' src={help} />
            </button>
            {this.renderProfileOptions()}
            {showFull ? renderLanguageOptions(this.props.changeLanguage, this.props.language, { marginLeft: 14 }) : null}
          </div>
        </div>
        {!showFull ? '' : <div className='separator' style={{width: '100%', marginTop: 17}}/>}
      </>
    )
  }
}

export default PrivateHeaderMenu;
