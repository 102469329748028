import {CHANGE_LANGUAGE} from "../types";

const changeLanguage= current => {
  return({
    type: CHANGE_LANGUAGE,
    payload: {
      auth: {
        language: current === 'es' ? 'en' : 'es'
      }
    }
  });
};
export default changeLanguage;