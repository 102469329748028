import React, { } from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form, Button, Col, Row } from "antd";
import { Field, reduxForm } from "redux-form";
import { CreateTextField } from "../../Helpers/Input/InputCreator";
import { connect } from 'react-redux'
import admin from '../../../const/admin';
import DropZoneField from "../../Helpers/DropzoneField";
import getBase64File from '../../../actions/helpers/getBase64File';
import { formValueSelector } from "redux-form";
import 'rc-color-picker/assets/index.css';
import ColorPicker from 'rc-color-picker';
import { checkMaxFileSize, getCustomColors, getCustomLogos, getCustomMessages } from '../../Helpers/utils';
import { Typography } from 'antd';


class customizationForm extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const customization = { ...getCustomLogos(), ...getCustomMessages(this.props.language), ...getCustomColors() }
    this.props.initialize(customization);
  }

  handleOnDrop = async (files, _onChange, name) => {
    if (files[0]) {
      const isValidFile = checkMaxFileSize(this.props.maxFileSize, this.props.language, this.props.dispatch, [files[0]]);
      if (isValidFile) {
        let base64 = await getBase64File(files[0]);
        this.props.change(name, `data:image/png;base64,${base64}`);
      }
    }
  }

  hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }

  RGBToHex(color) {
    const colorHex = color?color.substring(color.lastIndexOf("(")+1, color.length-1).split(','):['0','51','255']
    return "#" + ((1 << 24) + (parseInt(colorHex[0]) << 16) + (parseInt(colorHex[1]) << 8) + parseInt(colorHex[2])).toString(16).slice(1);
  }

  colorChange = (color, customColor) => {
    this.props.change(customColor, this.hexToRGB(color.color, color.alpha / 100));
  }

  getAlphaOfColor(color) {
    if (color && color.includes('rgba'))
      return parseFloat(color.substring(
        color.lastIndexOf(",") + 1,
        color.lastIndexOf(")")
      ).trim()) * 100;
    else
      return 100
  }

  render() {
    const alphaColors = {};
    Object.keys(getCustomColors()).map(label => alphaColors[label] = this.getAlphaOfColor(this.props[label]));
    return (
      <Form className='color-black' style={{margin:'44px 52px' }}>
        <Row style={{rowGap:15}}>
          <Col xs={24} md={12}>
            <Typography className='text-title size18'>{admin[this.props.language].startupLogo}</Typography>
            <Typography className='size13'>{admin[this.props.language].descriptionStartupLogo[0]} <br />{admin[this.props.language].descriptionStartupLogo[1]}</Typography>
            <img className='border-image mt-10' style={{ maxWidth: '217px', maxHeight: '130px' }} alt={''}
              src={this.props.base64LogoDark} />
          </Col>
          <Col xs={24} md={12}>
            <DropZoneField
              name="base64LogoDark"
              accept={".jpg, .jpeg, .gif, .png"}
              multiple={false}
              handleOnDrop={this.handleOnDrop}
              label={admin[this.props.language].uploadLogo.toUpperCase()}
            />
          </Col>
        </Row>
        <Row className='mt-60' style={{ rowGap:15 }}>
          <Col xs={24} md={12}>
            <Typography className='text-title size18'>{admin[this.props.language].menuLogo}</Typography>
            <Typography className='size13'>{admin[this.props.language].descriptionMenuLogo[0]}<br />{admin[this.props.language].descriptionMenuLogo[1]}</Typography>
            <img className='border-image mt-10' style={{ maxWidth: '217px', maxHeight: '130px' }} alt={''}
              src={this.props.base64Logo} />
          </Col>
          <Col xs={24} md={12}>
            <DropZoneField
              name="base64Logo"
              accept={".jpg, .jpeg, .gif, .png"}
              multiple={false}
              handleOnDrop={this.handleOnDrop}
              label={admin[this.props.language].uploadLogo.toUpperCase()}
            />
          </Col>
        </Row>

        <Row className='mt-60' style={{ rowGap: 15 }}>
          <Col xs={24}><Typography className='text-title size18'>{admin[this.props.language].colorTitle}</Typography></Col>
          <Col xs={20} md={10}>
            <div className={'mb-10'}>
            {admin[this.props.language].colors.primary}
            </div>
          </Col>
          <Col xs={4} md={2}>
            <ColorPicker
              animation="slide-up"
              alpha={alphaColors.colorPrimary}
              color={this.props.colorPrimary}
              onChange={(color) => this.colorChange(color, 'colorPrimary')}
            />
          </Col>
          <Col xs={20} md={10}>
            <div className={'mb-10'}>
            {admin[this.props.language].colors.textPrimary}
            </div>
          </Col>
          <Col xs={4} md={2}>
            <ColorPicker
              animation="slide-up"
              alpha={alphaColors.colorTextPrimary}
              color={this.props.colorTextPrimary}
              onChange={(color) => this.colorChange(color, 'colorTextPrimary')}
            />
          </Col>
          <Col xs={20} md={10}>
            <div className={'mb-10'}>
            {admin[this.props.language].colors.secondary}
            </div>
          </Col>
          <Col xs={4} md={2}>
            <ColorPicker
              animation="slide-up"
              alpha={alphaColors.colorSecondary}
              color={this.props.colorSecondary}
              onChange={(color) => this.colorChange(color, 'colorSecondary')}
            />
          </Col>
          <Col xs={20} md={10}>
            <div className={'mb-10'}>
            {admin[this.props.language].colors.textSecondary}
            </div>
          </Col>
          <Col xs={4} md={2}>
            <ColorPicker
              animation="slide-up"
              alpha={alphaColors.colorTextSecondary}
              color={this.props.colorTextSecondary}
              onChange={(color) => this.colorChange(color, 'colorTextSecondary')}
            />
          </Col>
          <Col xs={20} md={10}>
            <div className={'mb-10'}>
            {admin[this.props.language].colors.panels}
            </div>
          </Col>
          <Col xs={4} md={2}>
            <ColorPicker
              animation="slide-up"
              alpha={alphaColors.colorPanels}
              color={this.props.colorPanels}
              onChange={(color) => this.colorChange(color, 'colorPanels')}
            />
          </Col>
          <Col xs={20} md={10}>
            <div className={'mb-10'}>
            {admin[this.props.language].colors.textPanels}
            </div>
          </Col>
          <Col xs={4} md={2}>
            <ColorPicker
              animation="slide-up"
              alpha={alphaColors.colorTextPanels}
              color={this.props.colorTextPanels}
              onChange={(color) => this.colorChange(color, 'colorTextPanels')}
            />
          </Col>
          <Col xs={20} md={10}>
            <div className={'mb-10'}>
            {admin[this.props.language].colors.siderMenu}
            </div>
          </Col>
          <Col xs={4} md={2}>
            <ColorPicker
              animation="slide-up"
              alpha={alphaColors.colorMenu}
              color={this.props.colorMenu}
              onChange={(color) => this.colorChange(color, 'colorMenu')}
            />
          </Col>
          <Col xs={20} md={10}>
            <div className={'mb-10'}>
            {admin[this.props.language].colors.textSiderMenu}
            </div>
          </Col>
          <Col xs={4} md={2}>
            <ColorPicker
              animation="slide-up"
              alpha={alphaColors.colorTextMenu}
              color={this.props.colorTextMenu}
              onChange={(color) => this.colorChange(color, 'colorTextMenu')}
            />
          </Col>
        </Row>

        <Row className='mt-60' style={{ rowGap: 15 }}>
          <Col xs={24}><Typography className='text-title size18'>{admin[this.props.language].messageTitle}</Typography></Col>
          <Col xs={24}>
            <div className={'mb-10'}>{admin[this.props.language].messages.home1}</div>
            <Field
              name="messageHome"
              component={CreateTextField}
              placeholder={"Mensaje de bienvenida"}
              hasFeedback />
          </Col>
          <Col xs={24}>
            <div className={'mb-10'}>{admin[this.props.language].messages.home2}</div>
            <Field
              name="messageHome2"
              component={CreateTextField}
              placeholder={"Segundo mensaje"}
              hasFeedback />
          </Col>
          <Col xs={24}>
            <div className={'mb-10'}>{admin[this.props.language].messages.verify}</div>
            <Field
              name="messageVerify"
              component={CreateTextField}
              placeholder={"Primer mensaje"}
              hasFeedback />
          </Col>
        </Row>
        <div className='mt-20'>
          <Button className={'float-right'} type={'primary'} htmlType={'submit'} style={{ maxWidth: 200 }}
            onClick={this.props.handleSubmit(this.props.setCustomization)}>
            {admin[this.props.language].update.toUpperCase()}
          </Button>
          <Button className={'float-right'} type={'ghost'} htmlType={'submit'} style={{ maxWidth: 200, marginRight:10 }}
            onClick={this.props.handleSubmit(() => this.props.setCustomization(null))}>
            {admin[this.props.language].restart.toUpperCase()}
          </Button>
        </div>
      </Form>
    ) 
  }
}

const selector = formValueSelector('admin_customization_form');

const mapStateToProps = state => {
  return {
    base64Logo: selector(state, 'base64Logo'),
    base64LogoDark: selector(state, 'base64LogoDark'),
    messageHome: selector(state, 'messageHome'),
    messageVerify: selector(state, 'messageVerify'),
    messageHome2: selector(state, 'messageHome2'),
    colorPrimary: selector(state, 'colorPrimary'),
    colorTextPrimary: selector(state, 'colorTextPrimary'),
    colorSecondary: selector(state, 'colorSecondary'),
    colorTextSecondary: selector(state, 'colorTextSecondary'),
    colorPanels: selector(state, 'colorPanels'),
    colorTextPanels: selector(state, 'colorTextPanels'),
    colorMenu: selector(state, 'colorMenu'),
    colorTextMenu: selector(state, 'colorTextMenu'),
  };
};

customizationForm = connect(mapStateToProps, {})(customizationForm)
export default reduxForm({ form: 'admin_customization_form' })(customizationForm);
