import React from 'react';
import getConfiguration from '../../../actions/admin/getConfiguration';
import setConfigurationParam from '../../../actions/admin/setConfigurationParam';
import modalShow from '../../../actions/modal/modalShow';
import {connect} from 'react-redux'
import ConfigurationTable from './ConfigurationTable';
import getUserInfo from "../../../actions/profile/getUserInfo";
import infoShow from "../../../actions/info/infoShow";
import modalDismiss from "../../../actions/modal/modalDismiss";
import testSMTP from '../../../actions/admin/testSMTP';

class AdminConfigurations extends React.Component {
  
  componentDidMount() {
    this.props.getConfiguration();
    this.props.getUserInfo();
  }

  render() {
    return (
      <ConfigurationTable language={this.props.language} modalShow={this.props.modalShow} dimApp={this.props.dimApp} testSMTP={this.props.testSMTP}
          setConfigurationParam={this.props.setConfigurationParam} dataSource={this.props.adminConfiguration.configuration}/>
    );
  }
}


const mapStateToProps = state => {
  return {
    language: state.auth.language,
    userName: state.user.userName,
    adminConfiguration: state.adminConfiguration,
    dimApp: state.locate.dimensionsApp,
  };
};

export default connect(
    mapStateToProps,
    {
      modalShow,
        modalDismiss,
      getConfiguration,
      setConfigurationParam,
      getUserInfo,
        infoShow, testSMTP
    })(AdminConfigurations);
