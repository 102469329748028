import {
  LOCATE_SIGNATURES,
  LOCATE_APP_OPTIONS,
  LOCATE_OPTIONS_SIGNATURE,
  LOCATE_DIMENSIONS_APP,
  LOCATE_SHOW_SHORTCUTS,
  LOCATE_HIDE_MENU,
  LOCATE_SHOW_MENU, LOCATE_SHOW_SIDER, LOCATE_SHOW_SIDER_DRAWER, LOCATE_HIDE_HEADER, LOCATE_HIDE_FOOTER
} from "../actions/types";
import {showFullMenus} from "../components/Helpers/utils";

const showMenus=showFullMenus(window.innerWidth);
const INITIAL_STATE = {
  files: [],
  formValues: '',
  visibleSignatureOptions: false,
  visibleAppOptions: false,
  visibleLocateOptions: false,
  visibleShortcuts: false,
  dimensionsApp: { height: window.innerHeight, width: window.innerWidth },
  visibleMenu: showMenus,
  visibleSiderDrawer: false,
  hideAllMenu : false,
  hideHeader: false,
  hideFooter: false
};

const locateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOCATE_SIGNATURES:
      return { ...state, ...action.payload };
    case LOCATE_SHOW_SHORTCUTS:
      return { ...state, ...action.payload };
    case LOCATE_SHOW_MENU:
      return { ...state, ...action.payload };
    case LOCATE_SHOW_SIDER:
      return { ...state, ...action.payload };
    case LOCATE_SHOW_SIDER_DRAWER:
      return { ...state, ...action.payload };
    case LOCATE_OPTIONS_SIGNATURE:
      return { ...state, ...action.payload };
    case LOCATE_APP_OPTIONS:
      return { ...state, ...action.payload };
    case LOCATE_HIDE_MENU:
      return { ...state, ...action.payload };
    case LOCATE_HIDE_HEADER:
      return { ...state, ...action.payload };
    case LOCATE_HIDE_FOOTER:
      return { ...state, ...action.payload };
    case LOCATE_DIMENSIONS_APP:
      const show=state.visibleMenu?showFullMenus(window.innerWidth):false;
      return { ...state, ...action.payload,visibleMenu:show};
    default:
      return state;
  }
};
export default locateReducer;