import netcoServer from '../../apis/netcoServer';
import {INFO_SHOW} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import auth from "../../const/auth";

const activateToken= (tokenInfo, onSuccess) => async (dispatch,getState) => {
  if(!tokenInfo || !tokenInfo.token)
  {
    dispatch({type: INFO_SHOW,payload: infoPayload('error', auth[getState().auth.language].requiredfld)});
    return;
  }
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/activateToken  ', tokenInfo));
  handleResponse(dispatch, response, null, null, null, [],[onSuccess],[]);
};
export default activateToken;
 