import netcoServer from '../../apis/netcoServer';
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const activateSubscription= (formValues, onSuccess, onError) => async (dispatch) => {

  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/activateSubscription  ', formValues));
  handleResponse(dispatch, response, null, null, null, [],[onSuccess],[onError]);
};
export default activateSubscription;
