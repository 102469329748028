import netcoServer from '../../apis/netcoServer';
import {GET_PROFILES} from "../types";
import tableLoadingCall from '../helpers/tableLoadingCall';

const getProfiles= () => async (dispatch) => {
  let formData = {
    limit: 100,
    skip: 0,
    sord: 'desc',
  };
  await tableLoadingCall(dispatch, () => netcoServer.newPost('/SystemService/getAllProfiles', formData), GET_PROFILES, onSuccessPayload);
};

const onSuccessPayload = data => {
  return {
    reportList: {
      profiles: data.result.profiles
    }
  };
};
export default getProfiles;