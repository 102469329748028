import React from 'react';
import SignatureCanvas from "react-signature-canvas";
import signature from '../../const/signature';
import { Button } from "antd";


class Handwritten extends React.Component {

    getCanvasImage = () => {
        if (!this.sigPad)
            return "";
        this.props.modalDismiss()
        return this.sigPad.getCanvas().toDataURL('image/png');
    }

    clear = () => {
        this.sigPad.clear();
    }

    render() {
        return <div>
            <SignatureCanvas throttle={30} minDistance={0} dotSize={1} maxWidth={1} minWidth={1} ref={(ref) => this.sigPad = ref}
                canvasProps={{ height: this.props.height, width: this.props.width, className: 'canvasBorder mb-10 mt-10' }} />
            <div style={{ textAlign:'right', marginRight:20, marginBottom:30 }}>
                <Button type='ghost' style={{marginRight: '20px !important'}} onClick={this.clear}>{signature[this.props.language].clear.toUpperCase()}</Button>
                {this.props.trimImage && <Button type='primary' style={{marginLeft: 20}} onClick={() => this.props.trimImage('finished',this.getCanvasImage())}>{signature[this.props.language].accept.toUpperCase()}</Button>}
            </div>
        </div>
    }
}



export default Handwritten