export const authType = {
  1: 'BASIC_AUTHENTICATION',
  2: 'MAIL_OTP_AUTHENTICATION',
  3: 'SMS_OTP_AUTHENTICATION',
  4: 'PHONE_OTP_AUTHENTICATION',
  5: 'FINGERPRINT_AUTHENTICATION',
  8: 'SQRL_AUTHENTICATION',
  9: 'OTP_AUTHENTICATION'
};

// TODO - CAMBIAR SQRL por 8
