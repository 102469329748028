import React from 'react';
import {Layout} from "antd";

// import 'antd/lib/layout/style/css';


import {connect} from "react-redux";
import auth from "../../../../const/auth";

const PublicFooter = (props) => {
    const [height, setHeight] = React.useState(window.innerHeight)
    React.useEffect(() =>
    {
        const handleResize=()=>setHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);
        return _ => window.removeEventListener('resize', handleResize);
    })
    if(height<720 || (props.props.session && !props.props.hideMenu))
        return "";
    return (!props.hideFooter &&
    <Layout.Footer style={{textAlign:'center'}} className={{textAlign: 'center'}}>
	  {auth[props.language].rights}
    </Layout.Footer>
  );
};

const mapStateToProps = state => {
    return {
        hideFooter: state.locate.hideFooter
    };
};

export default connect(mapStateToProps)(PublicFooter);
