import React from 'react';
import {connect} from 'react-redux';
import {Card, Button, Table, ConfigProvider} from "antd";
import TitleAndInfo from '../Helpers/TitleAndInfo';

// import 'antd/lib/card/style/css';


// import 'antd/lib/row/style/css';


// import 'antd/lib/col/style/css';


// import 'antd/lib/alert/style/css';


import updateOrder from '../../actions/admin/updateOrder';
import getOrders from '../../actions/admin/getOrders';
import logoutUser from "../../actions/auth/logoutUser";
import checkLoggedIn from "../../actions/auth/checkLoggedIn";
import PrivateRoute from "../Root/PrivateRoute";
import admin from "../../const/admin";
import _ from "lodash";
import modalShow from '../../actions/modal/modalShow';
import {TOKEN_MODAL} from "../Feedback/Modal/types";
import esCO from "antd/es/locale/es_ES";
import enUS from "antd/es/locale/en_US";
import getToken from "../../actions/admin/getToken";
import {REDIRECT_DOCS_TABS_ROUTE} from "../Root/routes";
import modalDismiss from "../../actions/modal/modalDismiss";
import {replace} from "connected-react-router";
import {currencyFormat} from "../Helpers/utils";
import ConfirmWordPress from "../ConfirmWordPress";

const { Column } = Table;
class CheckPayment extends React.Component {
  
  state = {orders:[],loaded:false};
  token=null;
  error=true;



    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if((prevProps.active!==this.props.active && this.props.active==='check') || (this.props.wordpressUser && this.props.wordpressUser!==prevProps.wordpressUser))
            this.setState({loaded:false},this.startLoading);
    }

    startLoading=() =>
    {
        if(!this.state.loaded && this.props.wordpressUser)
        {
            this.setState({loaded: true},()=>{
                let number = this.props.checkOrder;
                if (number)
                    this.handleSubmit(number, this.onOrderUpdated, this.onAfterOrder);
                else
                    this.props.getOrders();
            });
        }
    };

    onOrderUpdated=(value) =>
    {
        if(value.token)
            this.token=value.token;
        console.log("Token:", this.token);
        this.error=false;
        this.onAfterOrder(value.token);
    };

    onCancel = () =>
    {
      this.props.logoutUser(false);
    };

    onAfterOrder=() =>
    {
        this.props.checkLoggedIn((loggedIn)=>this.afterLoad(loggedIn));
    };

  componentDidMount()
  {
      this.startLoading();
  }

  afterLoad = (loggedIn) =>
  {
      let cancel=null;
      if(loggedIn)
          this.props.getOrders();
      else
      {
          cancel = this.onCancel;
          if(this.error)
              cancel();
      }
      if(!this.error)
        this.showTokenModal(null,this.token,cancel);


  };


  handleSubmit=(value, onUpdatedOrder,onError)=>
  {
      this.props.updateOrder({number:value,payco:this.props.payco},onUpdatedOrder,onError);
  };


    currencyFormat(num)
    {
        return currencyFormat(num,this.props.currency);
    }

    dateFormat(date)
    {
        return new Date(date).toLocaleString();
    };

    showTokenModal= (order,token,onCancel) =>
    {
        if(token)
            this.showModal(token,onCancel);
        else
            this.props.getToken({number:order},()=>{},(data)=>this.showModal(data.result.token,onCancel));
    };

    activate=(token)=>
    {
        this.props.modalDismiss();
        this.props.dispatch(replace(REDIRECT_DOCS_TABS_ROUTE + '?active=activate&token=' + token));
    }

    showModal=(token,onCancel)=>
    {
        const message=admin[this.props.language].order_token;
        if(onCancel)
            this.props.modalShow({modalType: TOKEN_MODAL,
                modalProps: {footer: null, title: admin[this.props.language].buy_modalTitle,onCancel: onCancel},
                componentProps:{message:message,token:token,language:this.props.language,activate:()=>this.activate(token)}});
        else
            this.props.modalShow({modalType: TOKEN_MODAL,
                modalProps: {footer: null, title: admin[this.props.language].buy_modalTitle},
                componentProps:{message:message,token:token,language:this.props.language,activate:()=>this.activate(token)}});
    };


  render()
  {
      if(!this.props.wordpressUser)
          return <ConfirmWordPress/>;
      return (
        <Card title={this.props.wordpressUser + ', ' + admin[this.props.language].historyText} bordered={false}>
        <TitleAndInfo info={admin[this.props.language].check_info}/>
        <div style={{'textAlign':'center'}}>
            <ConfigProvider locale={this.props.language==='es'?esCO:enUS}>
            <Table scroll={window.innerWidth>500?{}:{x:true}} dataSource={this.props.orders}
                   pagination= { {showSizeChanger: false, defaultCurrent:1, pageSize:10}} rowKey="number">
                <Column title={admin[this.props.language].buy_order} dataIndex="number" key="number" />
                <Column className={'mobile-hide'} title={admin[this.props.language].buy_date} dataIndex="creationDate" key="creationDate"
                        render={value => (<span>{this.dateFormat(value)}</span>)}/>
                <Column className={'mobile-hide'} title={admin[this.props.language].buy_bigTotal} dataIndex="total" key="total"
                        render={(value) => this.currencyFormat(value)}/>
                <Column title={admin[this.props.language].buy_status} dataIndex="status" key="status" filters= {[{text: 'Pendiente',value: 'pending'}]}
                        onFilter={(value, record) => record.status.indexOf(value) === 0}
                        render={(value)=>{
                            let label=value;
                            switch(value)
                            {
                                case 'pending':
                                    label=admin[this.props.language].pendingLabel;
                                    break;
                                case 'failed':
                                    label=admin[this.props.language].failedLabel;
                                    break;
                                case 'cancelled':
                                    label=admin[this.props.language].cancelledLabel;
                                    break;
                                case 'completed':
                                case 'completed_test':
                                    label=admin[this.props.language].completedLabel;
                                    break;
                                case 'processing':
                                    label=admin[this.props.language].processingLabel;
                                    break;
                                default:
                                    break;
                            }
                            return <div>{label}</div>
                        }}
                />
                <Column title="" dataIndex="number" key="number"
                        render={(value,record) => {
                            if(record.status==='pending')
                                return <Button style={{'width':'100px'}} type="primary light-primary rounded-sm" onClick={() =>{this.handleSubmit(value,this.onOrderUpdated, this.onAfterOrder)}}>{admin[this.props.language].buy_update}</Button>;
                            if(record.status==='completed' || record.status==='completed_test')
                                return <Button style={{'width':'100px'}} type="primary light-primary rounded-sm" onClick={()=>this.showTokenModal(record.number)}>{admin[this.props.language].buy_showToken}</Button>;
                        }}/>
            </Table>
            </ConfigProvider>
        </div>
      </Card>
    );
  }
}

export default PrivateRoute(connect((state) =>
    {
        let queryParams = state.router.location.search ? _.reduce(state.router.location.search.substring(1).split("&"),
            (result, value) => {
                let params = value.split("=");
                let newParam=params[1];
                let index=newParam.indexOf('?');
                if(index>-1)
                    newParam=newParam.substr(0,index);
                result[params[0]] = newParam;
                return result;
            }, {}) : {};
        let orderToCheck=null;
        if(queryParams.paypalCode)
            orderToCheck=queryParams.paypalCode;
        else if(queryParams.referenceCode)
            orderToCheck=queryParams.referenceCode;
        else if(queryParams.x_id_factura)
            orderToCheck=queryParams.x_id_factura;
        return {
            language: state.auth.language, orders:state.payment.orders,
            userName:state.auth.userName,
            checkOrder:orderToCheck,
            payco:queryParams.x_ref_payco,
            method:state.payment.method,
            currency:state.payment.currency,
            wordpressUser: state.user.wordpressUser,
            token:state.payment.token}
    }
, {getOrders,updateOrder,logoutUser,checkLoggedIn,modalShow,getToken,modalDismiss})(CheckPayment));