import React from 'react';
import {Alert} from "antd";

const index= ({title, info}) => {
  
  return (
    <div>
      <h2 className={'title'}>{title}</h2>
      <Alert style={{maxHeight:100, color:'#8F9BB3'}} className={'mb-10 text-center info size12'} message={info} type="info"/>
    </div>
  
  );
  
}
export default index;