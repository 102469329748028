import netcoServer from '../../apis/netcoServer';
import {GET_PENDING_FILES_INFORMATION} from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";
import {fixSord} from "../../components/Helpers/utils";

const getPendingFilesInformation= (data=null,callbacks=null, errors=[]) => async (dispatch) => {
  if(!data)
    data={};
  if(data.sidx === 'lastModified')
    data.sidx='creationDate';
  if(data.sidx === 'name')
    data.sidx='fileName';
  if(callbacks)
    callbacks=[callbacks];
  else
    callbacks=[];
  data.sord=fixSord(data.sord);
  if(data.uids && data.uids.length===0)
    data.uids="";
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getPendingFilesInformation', data));
  handleResponse(dispatch, response, GET_PENDING_FILES_INFORMATION, onSuccessPayload,null,[],[],errors,callbacks);
};

const onSuccessPayload = data => {
  return {
    pendingFileList: {
      entries: data.filesInfo.pendingFilesInfo,
	  defaultOperation: data.filesInfo.defaultOperation,
      localTotal:data.filesInfo.generalTotal,
      total:data.filesInfo.generalTotal
    }
  };
};
export default getPendingFilesInformation;