//import {TABLE_LOAD_END, TABLE_LOAD_START} from "../types";
import handleResponse from './handleResponse';

const tableLoadingCall= async (dispatch, apiCall, type, onSuccessPayload, path = null, postActions = []) => {
  //dispatch({type: TABLE_LOAD_START});
  let response = await apiCall();
  handleResponse(dispatch, response, type, onSuccessPayload, path, postActions);
  //dispatch({type: TABLE_LOAD_END});
  return response;
}
export default tableLoadingCall;