import {
  CREATE_ORDER,
  GET_PRODUCT, GET_PRODUCTS_INFO,
  CLEAR_ORDER, UPDATE_ORDER, GET_ORDERS, DELETE_ORDER, CHECK_TOKEN, USE_TOKEN, GET_PURCHASEOPTIONS, GET_TOKEN, GET_TOKEN_ITEMS, GET_SUBSCRIPTION_INFO
} from "../actions/types";

const INITIAL_STATE = {
  products:[],
  paymentMethods:[],
  token:null,
  items:[],
  subscriptionInfo:null,
  plans:[],
  tokenInfo: null
};

const payment= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT:
      return {...state, ...action.payload.result};
    case GET_PRODUCTS_INFO:
      return {...state, ...action.payload.result};
    case GET_SUBSCRIPTION_INFO:
      return {...state, ...action.payload.result};
    case GET_ORDERS:
      return {...state, ...{orders:action.payload.result}};
    case CREATE_ORDER:
      return {...state, ...action.payload.result};
    case UPDATE_ORDER:
      return {...state, ...action.payload.result};
    case DELETE_ORDER:
      return INITIAL_STATE;
    case CLEAR_ORDER:
      return INITIAL_STATE;
    case CHECK_TOKEN:
      return {...state, ...action.payload.result};
    case GET_TOKEN:
      return {...state, ...action.payload.result};
    case GET_TOKEN_ITEMS:
      return {...state, ...action.payload.result};
    case GET_PURCHASEOPTIONS:
      return {...state, ...{options:action.payload.result}};
    case USE_TOKEN:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default payment;