import React from 'react';
import PublicLayout from "../../Layouts/Public";
import {connect} from 'react-redux';
import forgotPassword from '../../../actions/auth/forgotPassword';
import AuthSingleFieldForm from "../AuthSingleFieldForm";
import {required} from "../../Helpers/Input/validations";
import {userNormalizer} from "../../Helpers/Input/normalizers";
import {reduxForm} from "redux-form";
import {push} from 'connected-react-router';

// import 'antd/lib/icon/style/css';


import auth from "../../../const/auth";
import iconUser from "../../../resources/img/user.svg";
import {Typography} from "antd";

class RecoverPassword extends React.Component {
  
  forgotPassword = (formValues) => {
    this.props.forgotPassword(formValues);
  };
  
  render() {
    return (
      <PublicLayout title={'logo'}>
          <Typography className='size12 center' style={{ width: '296px', marginTop: 22.01, textAlign: 'center' }}>
              {auth[this.props.language].forgotlbl}</Typography>
          <Typography className='size12' style={{ fontWeight: 700, marginTop:'40px', marginBottom:'8px'}}>
              {auth[this.props.language].maillbl.toUpperCase()}</Typography>
        <AuthSingleFieldForm onSubmit={this.forgotPassword}
                             placeholder={auth[this.props.language].usernamelbl}
                             name={'name'}
                             suffix={<img alt='' src={iconUser} />}
                             buttonTxt={auth[this.props.language].recoverlbl.toUpperCase()}
                             validations={[required]}
                             normalizer={userNormalizer}
                             push={this.props.push}
                             {...this.props}
        />
      </PublicLayout>
    );
  }
}

function mapStateToProps(state) {
  return {    
    language: state.auth.language
  }
}

export default connect(mapStateToProps, {forgotPassword, push})(reduxForm({
  form: 'forgot_password_form'
})(RecoverPassword));