import React from 'react';
import {Card, Col, Layout, Row} from "antd";

// import 'antd/lib/card/style/css';


// import 'antd/lib/layout/style/css';


// import 'antd/lib/row/style/css';


// import 'antd/lib/col/style/css';


import './PublicLayout.css';
import { getCustomLogos } from '../../Helpers/utils';

const PublicLayout = (props) => {
  const base64LogoDark = getCustomLogos().base64LogoDark;
  const renderImg = () => {
    return (
      <img style={{maxWidth:'300px'}} alt={''} src={base64LogoDark}/>
    );
  };
  
  const getSize = (size) => {
    switch (size) {
      case 'sm':
        return props.size === 'large' ? 18 : 14;
      case 'md':
        return props.size === 'large' ? 16 : 10;
      case 'xl':
        return props.size === 'large' ? 14 : 8;
      default:
        break;
    }
  };
  
  return (
    <Layout className="h-100 public-layout">
      <Layout.Content>
        <Row type="flex" justify="space-around" align="middle" className={'h-100'}>
          <Col xs={24} sm={getSize('sm')} md={getSize('md')} xl={getSize('xl')}>
            <Card bodyStyle={props.bodyStyle?props.bodyStyle:{}} className={'public-card'} title={props.title === 'logo' ? renderImg() : props.title}
                  headStyle={{textAlign: 'center'}}>
              {props.children}
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default PublicLayout;
