import netcoServer from '../../apis/netcoServer';
import {UPDATE_USER_PASSWORD, INFO_SHOW} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import modalDismiss from "../modal/modalDismiss";
import _ from 'lodash';
import loadingCall from "../helpers/loadingCall";

const updateUserPassword= (formValues) => async (dispatch,getState) => {
  const usePAD = getState().auth.params.usePAD

  const onSuccessPayload = () => {
    return {...{auth:{params:{usePAD,isNewUser:false}}}, ...infoPayload('success', 'Contraseña cambiada satisfactoriamente!')};
  };

  if(formValues.password === formValues.password_confirmation) {
    let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/updateUserPassword', _.pick(formValues, ['password'])));
    handleResponse(dispatch, response, UPDATE_USER_PASSWORD, onSuccessPayload, null, [modalDismiss]);
  } else {
    dispatch({type: INFO_SHOW, payload: infoPayload('error', 'Las claves deben coincidir!')})
  }
};


export default updateUserPassword;