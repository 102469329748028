import netcoServer from '../../apis/netcoServer';
import handleResponse from "../helpers/handleResponse";
import {GET_TIMESTAMPS_INFO} from "../types";
import loadingCall from "../helpers/loadingCall";

const getTimestampsInfo= () => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getTimestampsInfo', {}));
  handleResponse(dispatch, response, GET_TIMESTAMPS_INFO, onSuccessPayload)
};

const onSuccessPayload = data => {
  return {
    user: {
      timeStampServers: data.result.timeStampServers
    }
  };
};
export default getTimestampsInfo;