import {push} from "connected-react-router";
import {INFO_SHOW} from "../types";
import logoutUser from '../auth/logoutUser';
import invalidSession from "../auth/invalidSession";
import {dispatchRootRoute} from "./utils";

const handleResponse= (dispatch, response, type, onSuccessPayload, path = null, postActions = [], postCallbacks = [],postErrors=[],postCallbacksData=[]) => {
  let data;
  if (response === undefined) {
    data = {
      info: {
        message: 'Error al comunicarse con el servidor, intenta de nuevo mas tarde...',
        type: 'error'
      }
    }
    dispatch({type: INFO_SHOW, payload: data});
    return;
  } else if (response.data.success) {
    if(onSuccessPayload)
      data = onSuccessPayload(response.data);
    if(path !== null)
      dispatch(push(path));
    if (postActions.length !== 0) {
      postActions.forEach((action) => {dispatch(action())})
    }
    if (postCallbacks.length !== 0) {
      postCallbacks.forEach((callback) => {callback && callback()})
    }
    if (postCallbacksData.length !== 0) {
      postCallbacksData.forEach((callback) => {callback(data)})
    }
  } else if (!response.data.success) {
    if (response.status && response.status!==200) {
      dispatch({type: INFO_SHOW,
        payload: {
          info: {
            message: 'Error ' + response.status,
            type: 'error'
          }
        }});
      return;
    }
    if(response.data.code === 440){
      dispatch(invalidSession());
      dispatch(logoutUser(null,true));
      //dispatch(push('/'));
      return;
    }
    if(response.data.code === 442){
      dispatchRootRoute(dispatch,null);
      return;
    }
    data = {
      info: {
        message: response.data.detail,
        type: 'error'
      }
    };
    //added 27012020 to show  errors
    let message=response.data.detail;
    if(message==="")
      message=response.data.code;
    dispatch({type: INFO_SHOW,
      payload: {
        info: {
          message: message,
          type: 'error'
        }
      }});
    if (postErrors.length !== 0) {
      postErrors.forEach((postError) => {postError()})
    }
    return;
  }
  if(data && type)
    dispatch({type: type, payload: data});
}
export default handleResponse;