import React from "react";
import {FileCard} from "./FileCard";
import {renderUploadCard} from "../utils";

const ImagePreview = ({imageFile,moveCard,deleteCard,viewFile}) =>
{
    const style = {
        border: '1px solid #EDF1F7',
        borderRadius: '16px',
        marginBottom: '.5rem',
        backgroundColor: 'white',
        cursor: 'move',
        textAlign:'center'
    }
    let renderCard = (card, index)=> <div key={card.name + index} style={{ ...style}}>{renderUploadCard(deleteCard,card.name,card.size,card.name,card,viewFile)}</div>;
    if(moveCard)
        renderCard=(card, index) => <FileCard key={card.name + index} index={index} id={card.name} text={card.name} moveCard={moveCard} deleteCard={deleteCard} size={card.size} style={style}/>;
    return <>{imageFile && <div style={{width:'100%'}}>{imageFile.map((card, i) => renderCard(card, i))}</div>}</>;
}
export default ImagePreview;
