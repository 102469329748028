import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Modal } from "antd";

// import 'antd/lib/modal/style/css';


import {Field, reduxForm} from "redux-form";
import {CreateTextArea} from "../Helpers/Input/InputCreator";
import {maxLengthEs500, required} from "../Helpers/Input/validations";

const RejectModal = (props) => {

  return (
    (<Modal open={props.modalType !== null} width={500} {...props.modalProps}>
      <Form onFinish={props.handleSubmit(props.onSubmit)}>
        <Field
               name="comment"
               showCount={true}
               component={CreateTextArea}
               rows={4}
               placeholder={props.message}
               validate={[required,maxLengthEs500]}
               hasFeedback/>
        <div style={{padding: '20px 0'}}>
          <Button htmlType={'submit'} type={'primary light-primary rounded-sm float-right'}>{props.btn}</Button>
        </div>
      </Form>
    </Modal>)
  );
};

export default reduxForm({form: 'reject_modal_form'})(RejectModal);
