import React from 'react';
import { connect } from "react-redux";
import './Docs.css';
import PrivateRoute from "../Root/PrivateRoute";
import getSignedFilesInfo from '../../actions/files/getSignedFilesInfo';
import getSignedFiles from '../../actions/files/getSignedFiles';
import { fixSord } from "../Helpers/utils";
import signed_files from '../../const/signed_files';
import DocsViewer from "./DocsViewer";
import { pendingDocsInitialValues } from '../../apis/generalValues';
import getUserInfo from '../../actions/profile/getUserInfo';
import updateUserSettings from '../../actions/profile/updateUserSettings';


class Docs extends React.Component {

    state = { filter: false, searchString: '', showCategory: 1  /*1.Todos   2.Privados    3.Publicos*/, type: 1};

    componentDidMount() {
        this.props.getUserInfo([], [], [(data) => {
            const userSettings = data.user;
            const limitDocs = userSettings.resultsDocs ? userSettings.resultsDocs : pendingDocsInitialValues.initialPageSize;
            this.setState({ limit: limitDocs, skip: 0 })
        }]);
    }

    handleSearchChange = (e) => {
        const value = e.target  ? e.target.value : e;
        this.setState({ searchString: value});
    };

    handleFilter = (page, pageSize, parentId) => {
        this.setState({ filter: true, type: 1 }, () => this.handleSearch(page, pageSize, parentId));
    };

    handleClean = (page, pageSize, parentId) => {
        this.setState({ filter: false, searchString: '' }, () => this.handleSearch(page, pageSize, parentId));
    };

    handleSearch = (page, pageSize, parentId, allResults, filterMetadataString) => {
        if (pageSize && pageSize !== this.state.limit && pageSize !== allResults) {
            this.props.updateUserSettings({'resultsDocs': parseInt(pageSize) })
        }

        this.props.getSignedFilesInfo(this.state.showCategory === 3,
            this.state.type === 1 ? this.state.searchString : signed_files[this.props.language].menuTypeSignedDocs[this.state.type],
            page, pageSize, parentId, this.state.sidx, fixSord(this.state.sord), this.state.showCategory === 1, true,
            this.state.type === 1 ? false : 'type', filterMetadataString);  
        
    };

    handleSort = (pagination, filters, sorter, page, pageSize, parentId) => {
        if (sorter.field !== undefined) {
            this.setState({ sidx: sorter.field, sord: sorter.order }, () => this.handleSearch(page, pageSize, parentId));
        } else {
            this.setState({ sidx: '', sord: '' }, () => this.handleSearch(page, pageSize, parentId));
        }
    };

    changeType = (type, page, pageSize, parentId, filterMetadataString) => {
        const search = {
            target: {
                value: ''
            }
        }
        this.setState({ type: parseInt(type) }, () => {
            this.handleSearch(page, pageSize, parentId, null, filterMetadataString);
            this.handleSearchChange(search);
        })
    }

    render() {
        return (
            <DocsViewer {...this.props} {...this.state} handleSearchChange={this.handleSearchChange} handleFilter={this.handleFilter}
                        docsType={'signed'} {...this.props} {...this.state} results={this.state.limit}
                handleClean={this.handleClean} handleSearch={this.handleSearch} handleSort={this.handleSort} changeType={this.changeType}
                changeCategory={(key, page, pageSize, parentId, filterMetadataString) => this.setState({ showCategory: parseInt(key) }, () => this.handleSearch(page, pageSize, parentId, null, filterMetadataString))} />
        );
    };
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        signedFileList: state.signedFileList,
        language: state.auth.language,
        user: state.user,
        dimApp: state.locate.dimensionsApp,
        metadata: state.reportList.entries
    };
};

export default PrivateRoute(connect(mapStateToProps, { getSignedFilesInfo, getSignedFiles,getUserInfo,updateUserSettings })(Docs));
