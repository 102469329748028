
const layout = {
  es: {
    error_com: 'Error al comunicarse con el servidor, intenta de nuevo mas tarde',
    search: 'Buscar',
    clean: 'Limpiar',
    actions: 'Acciones',
    results_per_page: 'Resultados por pagina',
    total_elements: '$0 elementos',
    select_user: 'Seleccione un usuario',
    comment: 'Comentario',
    commentPlaceHolder: 'Añade un mensaje general o instrucciones',
    mail: 'Agregar email',
    send: 'Enviar',
    sendToSign: 'Enviar a firmar',
    create: 'Crear',
    edit: 'Actualizar',
    delete: 'Eliminar',
    save: 'Actualizar firma',
    import: 'Importar',
    next: 'Siguiente',
    prev: 'Anterior',
    agree: 'Aceptar terminos y condiciones',
    password: 'Digita la clave del archivo',
    newFolderTitle: 'Crear nueva carpeta',
    newFolderPlaceHolder: 'Nombre de la nueva carpeta',
    newFolderCreate: 'Crear',
    reject: 'Rechazar',
    rejectComment: 'Motivo de rechazo',
    mailWarning:'El nombre de usuario seleccionado no es un email válido, si olvida su contraseña y no tiene un certificado asociado no podrá restablecerla y deberá crear un nuevo usuario, ¿Desea continuar?',
    loadingPDF: 'Cargando PDF, por favor espere',
    maxFileError:'El tamaño máximo de los archivos debe ser menor a {0} MB, actual {1} MB',
    deleteAllFiles: 'Está acción borrará todos los archivos del usuario {0}, ¿Está seguro?',
    deleteNotifications: 'Está acción borrará todas las notificaciones, ¿Está seguro?',
    zoomBtn: 'Zoom a la página actual',
    zoomIn: 'Ampliar',
    zoomOut: 'Reducir',
    zoomModal: 'Página actual',
    zoom: 'zoom',
    signAllPages: 'Agregar imagen en todas las páginas',
    deleteComment: 'Motivo de eliminación',
    skip: 'Siguiente Archivo',
    back: 'Anterior Archivo',
    totalLabel: 'Todos',
    unknownError: 'Error desconocido',
    selectFile:'Seleccionar documento',
    options:'Opciones',
    signAndShare: 'Firmar y compartir',
    btnSignShare: 'Firmar y enviar',
    messageSignShare: 'Envía el documento que firmaste a quien quieras. Introduzca una dirección de correo electrónico a continuación.',
    noThanks:'No, gracias',
    showSignatures: 'Mostrar panel de firmas' ,
    hideSignatures: 'Ocultar panel de firmas',
    signerDN:'Firmado por: ',
    showCert:'Descargar certificado',
    gotoSignature: 'Identificar campo de firma',
    signatureDate: 'Fecha de firma:',
    notUsed: 'Pendiente por firmar',
    editMetadata: 'Editar opción',
    addMetadata: 'Agregar opción',
    addMetadataTitle: 'Crear opción para el metadato: ',
    metadata: 'Metadatos',
    info: 'Información',
    properties:'Propiedades',
    size: 'Tamaño',
    type: 'Tipo',
    message: 'Mensaje',
    pages: 'Paginas',
    signed:'El documento está firmado',
    certified:'El documento está certificado',
    creator: 'Creador',
    changePassword: '¿Nuevo por aqui?'
  },
  en: {
    error_com: 'Network error, please try again later ',
    search: 'Search',
    clean: 'Clean',
    actions: 'Actions',
    results_per_page: 'Results per page: ',
    total_elements: 'Total of $0 elements',
    select_user: 'Select a user',
    comment: 'Comment',
    commentPlaceHolder: 'Leave a comment or instructions to signers',
    mail: 'Add email',
    send: 'Send',
    sendToSign: 'Send for figning',
    create: 'Create',
    edit: 'Update',
    delete: 'Delete',
    save: 'Save',
    import: 'Import',
    next: 'Next',
    prev: 'Previous',
    agree: 'Accept terms and conditions',
    password: 'Password',
    newFolderTitle: 'Create new folder',
    newFolderPlaceHolder: 'New folder"s name',
    newFolderCreate: 'Create',
    reject: 'Reject',
    rejectComment: 'Reject reason',
    mailWarning:'Username is not a valid email, if you forget your password and don"t have a certificate you won"t be able to restore it and will need to create a new user, ¿Do you want to continue?',
    loadingPDF: 'Loading PDF, please wait',
    maxFileError:'File length must be smaller than {0} MB, current {1} MB',
    deleteAllFiles: 'This action will delete all files from user {0}, ¿Are you sure?',
    deleteNotifications: 'This action will delete all notifications. Are you sure?',
    zoomBtn: 'Zoom to current page',
    zoomIn: 'Zoom in',
    zoomOut: 'Zoom out',
    zoomModal: 'Current page',
    zoom: 'zoom',
    signAllPages: 'Add image in all pages',
    deleteComment: 'Deletion Reason',
    skip: 'Next File',
    back: 'Previous file',
    totalLabel: 'All',
    unknownError: 'Error desconocido',
    selectFile:'Select file',
    options: 'Options',
    signAndShare: 'Sign and share',
    btnSignShare: 'Sign and send',
    messageSignShare: 'Send the document you signed to whomever you want. Please enter an email address below.',
    noThanks:'No, thanks',
    editMetadata: 'Edit option',
    addMetadata: 'Add option',
    addMetadataTitle: 'Create option for metadata: ',
    metadata: 'Metadata',
    info:'Information',
    properties:'Properties',
    size:'Size',
    type: 'Type',
    message: 'Message',
    pages:'Pages',
    signed:'File is signed',
    certified:'File is certified',
    creator: 'Creator',
    changePassword: 'Are you new here?'
  }
};
export default layout;
