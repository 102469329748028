import React from "react";
import {Button, Col, Input, Modal, Row, Select, Typography} from "antd";
import load_files from "../../../const/load_files";
import auth from "../../../const/auth";

class AddMetadataModal extends React.Component
{
    state={value:''}

    componentDidMount()
    {
        const values={};
        this.props.metadata.map((data)=>{
            if(data.type==='select')
            {
                const defaultOption=data.options[0];
                defaultOption.type="select";
                values[data.id]=data.options && data.options.length>0?defaultOption:'';
            }

            if(data.type==='text')
                values[data.id]= {id:data.id,value:''};
            return 0;
        });
        this.setState({value:values});
    }

    onSave=()=>
    {
        const values=this.state.value, metadata=this.props.metadata;
        const newValues=[];
        for(var i=0;i<metadata.length;i++)
        {
            const data = metadata[i];
            if (data.mandatory)
            {
                const value = values[data.id];
                if (!value || value.value === '')
                {
                    this.props.dispatch({
                        type: 'INFO_SHOW',
                        payload: {info: {message: auth[this.props.language].requiredfld + ' : ' + data.name, type: 'error'}}
                    });
                    return;
                }
            }
            newValues[i] = {...values[data.id]};
        }
        this.props.onSave(newValues);
    }

    render()
    {
        return (
            (<Modal
                open={this.props.modalType !== null} width={300} {...this.props.modalProps} title={this.props.modalProps.title} centered={true}>
                {this.props.metadata && this.props.metadata.map((data)=>{
                    const id=data.id;
                    return <Row key={id}>
                        <Col span={24}>
                            <Typography style={{paddingTop:'30px', margin:'2px 20px', textAlign:'center'}}>{data.name}</Typography>
                        </Col>
                        <Col span={24}>
                            {data.type==='select' && <Select onChange={(e)=>{
                                const temp={...this.state.value};
                                temp[id]=data.options.find((meta)=>meta.id===e);
                                temp[id].type="select";
                                this.setState({value:temp});
                            }
                            } style={{'width':'100%'}}
                                                             value={this.state.value?this.state.value[id].id:''}>
                                {data.options.map((option)=>{
                                    return <Select.Option key={option.id} value={option.id}>{option.value}</Select.Option>
                                })}
                            </Select>}
                            {data.type==='text' && <Input className={'size15'}
                                                          onChange={(e)=>{
                                                              const temp={...this.state.value};
                                                              temp[id].value=e.target.value;
                                                              this.setState({value:temp});
                                                          }}
                                                          value={this.state.value[id]?this.state.value[id].value:''} style={{background:'#F7F9FC'}}
                                                          placeholder={data.name}/>}
                        </Col>
                    </Row>;
                })}
                <Col span={24} style={{textAlign:'center',marginTop:'15px'}}>
                        <Button onClick={this.onSave} style={{width:'231px'}} className='text-title size12'
                                type={"primary light-primary rounded-sm"}> {load_files[this.props.language].saveMetadata.toUpperCase()}
                        </Button>
                </Col>
            </Modal>)
        );
    }
}
export default AddMetadataModal;