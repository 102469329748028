import netcoServer from '../../apis/netcoServer';
import {INFO_SHOW} from "../types";
import loadingCall from "../helpers/loadingCall";
import {push} from "connected-react-router";
import invalidSession from "../auth/invalidSession";
import logoutUser from "../auth/logoutUser";

const createFolder= (formValues, callback = null) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/createFolder', formValues));
  if (response === undefined) {
    dispatch({type: INFO_SHOW,
      payload: {
        info: {
          message: 'Error al comunicarse con el servidor, intenta de nuevo mas tarde',
          type: 'error'
        }
    }});
  } else if (response.data.success) {
    if(callback)
      callback();
  } else if (!response.data.success) {
    if(response.data.code === 440){
      dispatch(invalidSession());
      dispatch(logoutUser());
      dispatch(push('/'));
    } else {
      dispatch({type: INFO_SHOW,
        payload: {
          info: {
            message: response.data.detail,
            type: 'error'
          }
        }});
    }
  }
};
export default createFolder;