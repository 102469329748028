import React from "react";
import { Button, Col, Row, Typography, Form } from "antd";
import profile from "../../../const/profile";
import admin from "../../../const/admin";
import { ReactComponent as OptionSignIcon } from "../../../resources/img/NewIcons/home/option-sign.svg"
import { ReactComponent as SelectCertficateIcon } from "../../../resources/img/NewIcons/profileInfo/select-certificate.svg"
import { CreatePasswordField } from "../../Helpers/Input/InputCreator";
import { required } from "../../Helpers/Input/validations"
import { Field, reduxForm } from "redux-form";
import { CERTIFICATES_ROUTE } from "../../Root/routes";
import { iconCertificate } from "../../Helpers/utils";
import { DELETE_USER_MODAL } from "../../Feedback/Modal/types";


const NewProfile = props => {

  const selfDelete = (formValues) => {
    props.deleteUser(props.user.userName, props.logoutUser, formValues.delReason)
    props.modalDismiss();
  }

  const renderButtonCertificate = () => {
    if (props.user.caID) {
      return <Col xs={24} sm={8} style={{ textAlign: 'right', paddingRight: 36 }}>
        <Typography className={'size15 text-link'} onClick={() => props.push(CERTIFICATES_ROUTE)}>
          {profile[props.language].selectCertificate}
        </Typography>
      </Col>
    } else {
      return (
        <Col xs={24} xl={8}>
          <button className='center home-buttons' onClick={() => props.push(CERTIFICATES_ROUTE)}>
            <SelectCertficateIcon className="center" />
            <Typography className='center size15'>{profile[props.language].importCertificate}</Typography>
          </button>
        </Col>
      )
    }
  }

  const showDeleteUserModal = () => {
    props.modalShow({
      modalType: DELETE_USER_MODAL,
      modalProps: { footer: null, title: profile[props.language].deleteUserTitle },
      componentProps: {
        language: props.language, info: profile[props.language].deleteUserDescription,
        btn: profile[props.language].deleteUser.toUpperCase(), onCancel: props.modalDismiss, onSubmit: (formValues) => selfDelete(formValues)
      }
    });
  }

  const renderCertificateInfo = () => {
    if (props.user.cert) {
      const showIconCertificate = iconCertificate(props.user.caID);
      return (
        <Row className='profile-row-card mt-20'>
          <Col xs={24} xl={10}>
            <Row className="home-buttons" style={{ display: 'block', height: 142, padding: '23px 24px 0px 24px', borderRadius: 23 }}>
              {showIconCertificate && <img alt='andes' className='center' src={showIconCertificate} />}
              {props.user.caDescription && <Typography className='size13'>{props.user.caDescription}</Typography>}
              <Typography className='size12'>{profile[props.language].descriptionCertificate}</Typography>
            </Row>
          </Col>
          <Col xs={24} xl={14} className='size15' style={{ textAlign: 'left' }}>
            <Row>
              <Col className='text-title' xs={24} md={8}>CN:</Col>
              <Col xs={24} md={16}>{props.user.cn ? props.user.cn : '.'}</Col>
            </Row>
            <Row>
              <Col className='text-title' xs={24} md={8}>{profile[props.language].email}</Col>
              <Col xs={24} md={16}>{props.user.mail !== '' ? props.user.mail : '.'}</Col>
            </Row>
            <Row>
              <Col className='text-title' xs={24} md={8}>{profile[props.language].address}</Col>
              <Col xs={24} md={16}>{props.user.direccion !== '' ? props.user.direccion : '.'}</Col>
            </Row>
            <Row>
              <Col className='text-title' xs={24} md={8}>{profile[props.language].department}</Col>
              <Col xs={24} md={16}>{props.user.state !== '' ? props.user.state : '.'}</Col>
            </Row>
            <Row>
              <Col className='text-title' xs={24} md={8}>{profile[props.language].serial}</Col>
              <Col xs={24} md={16}>{props.user.serialNumberHex !== '' ? props.user.serialNumberHex : '.'}</Col>
            </Row>
            <Row>
              <Col className='text-title' xs={24} md={8}>{profile[props.language].expire}</Col>
              <Col xs={24} md={16}>{props.user.certExp !== '' ? props.user.certExp : '.'}</Col>
            </Row>
            <Row>
              <Col className='text-title' xs={24} md={8}>{profile[props.language].id}</Col>
              <Col xs={24} md={16}>{props.user.cedula !== '' ? props.user.cedula : '.'}</Col>
            </Row>
            <Row>
              <Col className='text-title' xs={24} md={8}>{profile[props.language].phone}</Col>
              <Col xs={24} md={16}>{props.user.telefono !== '' ? props.user.telefono : '.'}</Col>
            </Row>
            <Row>
              <Col className='text-title' xs={24} md={8}>{profile[props.language].country}</Col>
              <Col xs={24} md={16}>{props.user.country !== '' ? props.user.country : '.'}</Col>
            </Row>
            <Row>
              <Col className='text-title' xs={24} md={8}>{profile[props.language].city}</Col>
              <Col xs={24} md={16}>{props.user.city !== '' ? props.user.city : '.'}</Col>
            </Row>
          </Col>
        </Row>
      )
    }
  }

  return (
    <div className="color-black" style={{ paddingLeft: 35 }}>
      <Typography className='text-title size18'>{profile[props.language].profile_tab}</Typography>

      <Row className='profile-row-card text-title mt-25'>
        <Col xs={24} xl={8}>
          <div className="center profile-card">
            <Typography className='size30 color-primary'>{props.user.usedOperations !== '' ? props.user.usedOperations : '.'}</Typography>
            <Typography className='size15 mt-12'>{profile[props.language].usedOperations}</Typography>
          </div>
        </Col>
        <Col xs={24} xl={8}>
          <div className="center profile-card">
            <Typography className='size30 color-primary'>{props.user.totalOperations ? props.user.totalOperations !== -1 ? Math.max(props.user.totalOperations - props.user.usedOperations, 0) : profile[props.language].unlimited : ''}</Typography>
            <Typography className='size15 mt-12'>{profile[props.language].availableOperations}</Typography>
          </div>
        </Col>
        <Col xs={24} xl={8}>
          <div className="center profile-card">
            <Typography className='size30 color-primary'>{props.user.totalOperations !== -1 ? props.user.totalOperations : profile[props.language].unlimited}</Typography>
            <Typography className='size15 mt-12'>{profile[props.language].totalOperations}</Typography>
          </div>
        </Col>
      </Row>

      <Row className='profile-row-card' style={{ marginTop: 83, textAlign: 'left' }}>
        <Col xs={24} xl={16}>
          <Typography className='text-title size18'>{profile[props.language].settings}</Typography>
          <Typography className='size15 mt-20'>{profile[props.language].descriptionSettings}</Typography>
        </Col>
        <Col xs={24} xl={8}>
          <button className='center home-buttons' onClick={() => props.showOptionsSignature(true)}>
            <OptionSignIcon className="center" />
            <Typography className='center size15'>{profile[props.language].settings}</Typography>
          </button>
        </Col>
      </Row>

      <Row className='profile-row-card' style={{ marginTop: 62, textAlign: 'left' }}>
        <Col xs={24} sm={16}>
          <Typography className='text-title size18'>{profile[props.language].certInfo}</Typography>
          {!props.user.caID ? <Typography className='size15 mt-20'>{profile[props.language].noCertificate}</Typography> : ''}
        </Col>
        {renderButtonCertificate()}
      </Row>

      {renderCertificateInfo()}

      <Typography className='text-title size18' style={{ marginTop: 62 }}>{profile[props.language].change_password}</Typography>
      <Form onFinish={props.handleSubmit(props.updateUserPassword)}>
        <Row>
          <Col xs={24} xl={10}>
            <Form.Item style={{ paddingRight: 28 }} >
              <Typography className={'mb-10 mt-25'}>{admin[props.language].new_password}</Typography>
              <Field
                name="password"
                component={CreatePasswordField}
                size={'large'}
                className={'mb-10'}
                placeholder={admin[props.language].new_password}
                validate={[required]}
                hasFeedback />
            </Form.Item>
          </Col>
          <Col xs={24} xl={10} >
            <Form.Item style={{ paddingRight: 28 }} >
              <Typography className={'mb-10 mt-25'}>{admin[props.language].confirm_password}</Typography>
              <Field
                name="password_confirmation"
                component={CreatePasswordField}
                size={'large'}
                className={'mb-10'}
                placeholder={admin[props.language].confirm_password}
                validate={[required]}
                hasFeedback />
            </Form.Item>
          </Col>
          <Col xs={24} xl={4} style={{ alignSelf: 'end', textAlign: 'center' }}>
            <Button htmlType={'submit'} type={'primary'} className="text-title" style={{ width: 78 }} >
              {profile[props.language].send.toUpperCase()}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className='profile-row-card mb-40' style={{ marginTop: 83, textAlign: 'left' }}>
        <Col xs={24} xl={16}>
          <Typography className='text-title size18'>{profile[props.language].deleteUserTitle}</Typography>
          <Typography className='size15 mt-20'>{profile[props.language].deleteUserDescription}</Typography>
        </Col>
        <Col xs={24} xl={8} style={{alignContent:'center', textAlign:'center'}}>
          <Button type={'primary'} className="text-title" onClick={showDeleteUserModal} >
            {profile[props.language].deleteUser.toUpperCase()}
          </Button>
        </Col>
      </Row>

    </div>

  );
};

export default reduxForm({ form: 'new_password_form' })(NewProfile);
