import React from 'react';
import { useState, useEffect } from 'react';
import { Menu, Input, Row, Col, Button } from "antd";
import general from "../../../const/general";
import { Typography } from 'antd';
import SetParam from './SetParam';
import _ from 'lodash';
import admin from "../../../const/admin";

const ConfigurationTable = (props) => {

  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [selectedParam, setSelectedParam] = useState(0);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setFilteredDataSource(props.dataSource);
  }, [props.dataSource]);


  const renderHeader = () => {
    return (
      <Input.Search className='mt-10' style={{ marginLeft: 28, width: '85%' }}
        placeholder={general[props.language].search}
        onSearch={value => handleFilter(value)}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        enterButton
      />
    );
  };

  const handleFilter = (value = null) => {
    setSelectedParam(0);
    if (value) {
      setFilteredDataSource(_.without(_.map(props.dataSource, (o) => { if (o.name.toLowerCase().includes(value.toLowerCase())) { return o; } }), undefined));
    } else {
      return setFilteredDataSource(props.dataSource);
    }
  };

  const items = filteredDataSource.map((param, key) => { return { key: key, label: param.name } });
  return (
    <>
      <Button htmlType={'button'} type={'primary'} onClick={props.testSMTP}>{admin[props.language].testSMTP}</Button>
      <Row className='mt-10' style={{ border: '1px solid #E4E9F2' }}>
        <Col xs={16} sm={7}>
          <Typography className='text-title color-black size18 mt-20' style={{ marginLeft: 34 }}>{admin[props.language].system}</Typography>
          {renderHeader()}
          <Menu theme="dark" items={items} onClick={(evt) => setSelectedParam(evt.key)}
            mode="vertical"
            defaultSelectedKeys={[0]}
            selectedKeys={[selectedParam]}
            className='mt-10'
            style={{ height: props.dimApp.height - 380, overflowY: 'scroll' }} />
        </Col>
        {selectedParam > -1 && filteredDataSource[selectedParam] ?
          <Col xs={24} sm={17}>
            <SetParam language={props.language} btn={general[props.language].edit} entry={filteredDataSource[selectedParam]} onSubmit={(formValues) => {
              setInputValue('');
              props.setConfigurationParam(formValues);
            }} />
          </Col>
          : null}
      </Row>
    </>

  );
};

export default ConfigurationTable;