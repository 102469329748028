import React from 'react'
import ReduxContainer from "./ReduxContainer";

import './App.css'

const App= () => {
  
  return (
    <ReduxContainer/>
  );
  
}
export default App;