import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import {Modal} from "antd";

// import 'antd/lib/modal/style/css';


import CreditCardForm from "./CreditCardForm";
import subscriptions from "../../const/subscriptions";

class ModifySubscriptionModal extends React.Component {

  render() {
    return (
      (<Modal
          open={this.props.modalType !== null} width={950} {...this.props.modalProps} title={this.props.modalProps.title}>
        <CreditCardForm showDocument={false} showPlans={false} submitForm={this.props.onSubmit} language={this.props.language}
                        buttonLabel={subscriptions[this.props.language].modifyModalButton}/>
      </Modal>)
    );
  }


}

export default ModifySubscriptionModal;
