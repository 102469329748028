import React from "react";
import DropZone from "react-dropzone";
import Placeholder from './Placeholder';
import { Dropdown, Menu, Row, Col } from "antd";
import { renderSignCard } from "../utils";

class SignDropZoneField extends React.Component {

    handleItemClick = (inputFileRef, key) => {
        switch (key) {
            case "fromFile":
                inputFileRef.current.click();
                break;
            case "fromTemplate":
                this.props.showTemplatesModal()
                break
            default:
                break;
        }
    }

    renderDropDown = (getRootProps, getInputProps, hiddenFileInput, left) => {
        const items = [
            { label: 'Cargar desde equipo', key: 'fromFile' },
            { label: 'Escoger plantilla', key: 'fromTemplate' }
        ];
        return <Dropdown placement={'bottom'} overlayStyle={{ width: '175px', minWidth: '175px' }} trigger={['click']}
            dropdownRender={()=><Menu items={items} onClick={({ key }) => this.handleItemClick(hiddenFileInput, key)} />}>
            <div><Placeholder myRef={hiddenFileInput} error={this.props.error} touched={this.props.touched} getInputProps={getInputProps}
                getRootProps={getRootProps} label={this.props.label} label2={this.props.label2} left={left} />
            </div>
        </Dropdown>;
    }

    render() {
        const style = { backgroundColor: 'white', cursor: 'move', textAlign: 'center' };
        let renderCard = (card, index, deleteCard, id) => <div key={card.name + index} style={{ ...style }}>{renderSignCard(deleteCard, card.name, card.size, id)}</div>;
        return (
            <>
                <DropZone noClick={true}
                    accept={this.props.accept} className="upload-container"
                    onDrop={this.props.handleOnDrop} multiple={this.props.multiple}>
                    {({ getRootProps, getInputProps }) => {
                        const hiddenFileInput = React.useRef(null);
                        if ((this.props.formFile && this.props.formFile.length > 0) || (this.props.formTemplates && this.props.formTemplates.length > 0)) {
                            let form = []
                            if (this.props.formFile && this.props.formFile.length > 0)
                                form = [...form, ...this.props.formFile]
                            if (this.props.formTemplates && this.props.formTemplates.length > 0)
                                form = [...form, ...this.props.formTemplates]
                            return <Row gutter={30} justify={'start'} className={'h-100'}>
                                {form.map((card, i) => {
                                    return <Col key={i} style={{ width: '210px', height: '180px', marginTop: '20px' }}>
                                        {renderCard(card, i, card.path?this.props.deleteCard:this.props.deleteTemplate, card.path?card.name:card.id )}
                                    </Col>;
                                })}
                                <Col style={{ width: '210px', height: '180px', marginTop: '20px' }}>
                                    {this.renderDropDown(getRootProps, getInputProps, hiddenFileInput, '60%')}
                                </Col>
                            </Row>;
                        }
                        else
                            return <>{this.renderDropDown(getRootProps, getInputProps, hiddenFileInput, '52%')}</>;
                    }
                    }
                </DropZone>
            </>
        );
    }
}

export default SignDropZoneField;
