import React from 'react';
import PublicLayout from "../../Layouts/Public";
import NetcoCodeSignUpForm from './NetcoCodeSignUpForm';
import {connect} from 'react-redux';
import netcoCodesRegistration from '../../../actions/auth/netcoCodesRegistration';
import modalShow from '../../../actions/modal/modalShow';
import logoutUser from "../../../actions/auth/logoutUser";
import isLoggedIn from "../../../actions/auth/isLoggedIn";
import {push} from 'connected-react-router';
import _ from 'lodash';
import {IMAGE_MODAL} from "../../Feedback/Modal/types";
import auth from "../../../const/auth";
import modalDismiss from "../../../actions/modal/modalDismiss";
import {formValueSelector} from "redux-form";
import {getLoginPath, getPath, makeID, signerHelpURL} from "../../Helpers/utils";
import hideMenu from "../../../actions/signature/hideMenu";
import logOutAfter from "../../../actions/auth/logoutAfter";
import {Tooltip} from "antd";
import userExists from "../../../actions/auth/userExists";
import {CERTIFICATES_ROUTE, SIGNUP_ROUTE} from "../../Root/routes";
import PasswordPolicies from "../../PasswordPolicies";

class NetcoCodeSignUp extends React.Component {
  
  componentDidMount() {
      this.props.logoutUser(false,false).then(()=>{
          this.props.isLoggedIn();
          if(sessionStorage.getItem("base64Signature") !== null)
          {
              this.props.modalShow({
                  modalType: IMAGE_MODAL,
                  modalProps: {footer: null},
                  componentProps: {language: this.props.language,onSubmit: ()=>this.updateImage(true),
                      newImage: sessionStorage.getItem("base64Signature"), initialValues:this.props.initialValues,
                      onCancel:()=>this.updateImage(false)}
              });
          }
          if(this.props.hideMenuParam)
              this.props.hideMenu();
          if(this.props.logout)
              this.props.logOutAfter();
          if(this.props.initialValues && this.props.initialValues.userName)
            this.props.userExists(this.props.initialValues.userName,this.createUser);
      });
  }

  createUser=(response)=>
  {
      if(response && response.data && response.data.success && response.data.exists)
      {
          this.props.push(getLoginPath(this.props.search + "&redirect=" + CERTIFICATES_ROUTE + "&option=1"));
          return;
      }

      if(this.props.createUser==='true' &&this.props.uniqueID && this.props.uniqueID!==""
          && this.props.initialValues.authCode && this.props.initialValues.referenceNumber)
      {
          const pass=makeID();
          console.log(pass);
          const formValues=this.props.initialValues;
          formValues.password=pass;
          formValues.password_confirmation=pass;
          formValues.agree=true;
          formValues.createUser=true;
          this.netcoCodeSignUp(formValues);
      }
      this.props.push(getPath(SIGNUP_ROUTE,this.props.search + "&redirect=" + CERTIFICATES_ROUTE + "&option=1"));
  };

  netcoCodeSignUp = (formValues) => {        
  if(sessionStorage.getItem("base64Signature") !== null)
		formValues.base64Signature=sessionStorage.getItem("base64Signature");
    formValues.redirect=this.props.redirect;
    formValues.uniqueID=this.props.uniqueID;
	this.props.netcoCodesRegistration(formValues); 	
  };
  
  updateImage = (useImage) =>{
	if(!useImage)
        sessionStorage.removeItem("base64Signature");
	this.props.modalDismiss();
  };
      
  render() {
      return (
      <PublicLayout title={'logo'} size={'large'}>
          <div className={'title-info'}>
              <div data-show="true" className="ant-alert ant-alert-info ant-alert-no-icon mb-10 text-center info" role="alert"
                   style={{maxHeight: '100px'}}>
                  <div className="ant-alert-content">
                      <div className="ant-alert-message">
                          {auth[this.props.language].pkiIntro}
                          <a rel='noopener noreferrer' target={'_blank'} href={signerHelpURL}>{auth[this.props.language].moreInfoPKI}</a></div>
                  </div>
              </div>
          </div>
          <div className={'title-info'}>
              <div data-show="true" className="ant-alert ant-alert-info ant-alert-no-icon mb-10 text-center info" role="alert"
                   style={{maxHeight: '100px'}}>
                  <div className="ant-alert-content">
                      <div className="ant-alert-message">
                          {auth[this.props.language].netcocodesdesclblPartial}
                          <Tooltip title={auth[this.props.language].digitalCertDefinition}>
                              <label className={'link'}>{auth[this.props.language].netcocodesdesclblPartial2}</label>
                          </Tooltip>
                      </div>
                  </div>
              </div>
          </div>
          <PasswordPolicies/>
          <NetcoCodeSignUpForm search={this.props.search} language={this.props.language} push={this.props.push} onSubmit={this.netcoCodeSignUp}
            initialValues={this.props.initialValues} referenceNumber={this.props.referenceNumber}
            authCode={this.props.authCode}/>
      </PublicLayout>
	  
    );
	
  }
}
const selector = formValueSelector('netco_code_signup_form');
const mapStateToProps = state => {  
  let queryParams = state.router.location.search ? _.reduce(state.router.location.search.substring(1).split("&"),
                                                    (result, value) => {
                                                              let params = value.split("=");
                                                              result[params[0]] = params[1];
                                                              return result;
                                                            }, {}) : {};  
  
  if( typeof queryParams.userName == 'undefined' )
  {
	  queryParams.userName=sessionStorage.getItem("userName");
	  sessionStorage.removeItem("userName");
  }
  if( typeof queryParams.referenceNumber == 'undefined' )
  {
	  queryParams.referenceNumber=sessionStorage.getItem("referenceNumber");
	   sessionStorage.removeItem("referenceNumber");
  }
  if( typeof queryParams.authCode == 'undefined' )
  {
	  queryParams.authCode=sessionStorage.getItem("authCode");
	   sessionStorage.removeItem("authCode");
  }
  return {
    initialValues: {
      userName: queryParams.userName,
      referenceNumber: queryParams.referenceNumber,
      authCode: queryParams.authCode
    },
	language: state.auth.language,
      redirect: queryParams.redirect,
      uniqueID: queryParams.uniqueID,
      createUser: queryParams.createUser,
      referenceNumber: selector(state, 'referenceNumber'),
      authCode: selector(state, 'authCode'),
      hideMenuParam:queryParams.hideMenu,
      logout:queryParams.logout,
      search:state.router.location.search
  };
};

export default connect(mapStateToProps, {modalShow,netcoCodesRegistration,push,logoutUser,isLoggedIn,modalDismiss,
hideMenu,logOutAfter,userExists})(NetcoCodeSignUp);