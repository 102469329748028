import netcoServer from '../../apis/netcoServer';
import {FORGOT_PASSWORD} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import loadingCall from "../helpers/loadingCall";

const forgotPassword= (formValues) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/forgotPassword', formValues));
  handleResponse(dispatch, response, FORGOT_PASSWORD, onSuccessPayload, '/');
};

const onSuccessPayload = data => {
  return infoPayload('success', `Se ha enviado un correo a ${data.mail} para el cambio!`);
};
export default forgotPassword;