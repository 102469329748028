import netcoServer from '../../apis/netcoServer';
import {NETCO_PKI_REGISTRATION} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const netcoPKIRegistration= (formValues) => async (dispatch) => {
  formValues.telefono = '+' + formValues.areaCode + '-' + formValues.telefono;
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/netcoPKIRegistration', formValues));
  handleResponse(dispatch, response, NETCO_PKI_REGISTRATION, null, null);
};
export default netcoPKIRegistration;