import netcoServer from '../../apis/netcoServer';
import {INFO_SHOW} from "../types";
import handleResponse from "../helpers/handleResponse";
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import loadingCall from "../helpers/loadingCall";

const deleteWorkflowFiles= (uid,comment,callback) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/deleteWorkflowFiles', {uid: uid,comment:comment}));
  handleResponse(dispatch, response, INFO_SHOW, onSuccessPayload,null,[],[callback]);
};
const onSuccessPayload = () => {
  return infoPayload('close', '');
};
export default deleteWorkflowFiles;