import {GET_TIMESTAMP_SERVER_INFO} from "../types";

const getTimestampServerInfo= (id) => async (dispatch, getState) => {
  let payload = {
    reportList: {
      selectedEntry: id === null ? {} : getState().reportList.entries.find(x => x.id === id)
    }
  };
  dispatch({type: GET_TIMESTAMP_SERVER_INFO, payload: payload});
};
export default getTimestampServerInfo;