import React from 'react';
import { connect } from "react-redux";
import { Form, Button } from "antd";
import { CreateCheckbox, CreateTextField } from "../Helpers/Input/InputCreator";
import { required } from "../Helpers/Input/validations";
import { Field, reduxForm } from "redux-form";
import importCertificateNetcoCodes from '../../actions/certificates/importCertificateNetcoCodes';
import PrivateRoute from "../Root/PrivateRoute";
import certificates from "../../const/certificates";
import general from "../../const/general";
import { trimNormalizer } from "../Helpers/Input/normalizers";
import { ReactComponent as NetcopkiIcon }  from '../../resources/img/NewIcons/certificates/netcoPKI.svg'
import { getQueryParams } from "../Helpers/utils";

const NetcoPKIOption = (props) => {

  const onSubmit = (formValues) => {
    formValues.language = props.language;
    formValues.requiresCert = props.requiresCert;
    props.importCertificateNetcoCodes(formValues);
  };

  return (
    <>
      {props.renderCardCertificates(certificates[props.language].pkiTitle, certificates[props.language].netco_info2, NetcopkiIcon, certificates[props.language].netco_info3)}
      <Form onFinish={props.handleSubmit(onSubmit)} className={'text-title size12 mt-20'} style={{ marginLeft: 14 }}>
        <div className={'mb-10 mt-10'}>{certificates[props.language].reference_number}:</div>
        <Field
          name="referenceNumber"
          component={CreateTextField}
          placeholder={certificates[props.language].reference_number}
          validate={[required]}
          normalize={trimNormalizer}
          hasFeedback />
        <div className={'mb-10 mt-10'}>{certificates[props.language].authorization_code}:</div>
        <Field
          name="authCode"
          component={CreateTextField}
          placeholder={certificates[props.language].authorization_code}
          validate={[required]}
          normalize={trimNormalizer}
          hasFeedback />
        <Field name="agree"
          className='mt-20'
          type={'checkbox'}
          component={CreateCheckbox}
          validate={required}
          hasFeedback>
          <a rel='noopener noreferrer' target={'_blank'} href={window.netcourlid + '/comagre/AcuerdoDeComunicaciones.pdf'}>
            {general[props.language].agree}
          </a>
        </Field>
        <Field
          className='mt-20'
          name="updateGraphicImage"
          type={'checkbox'}
          component={CreateCheckbox}
          hasFeedback>
          {certificates[props.language].graphic_image}
        </Field>
        <Button htmlType={'submit'} className='mt-20' type={'primary'} style={{ marginRight: 21 }}>
          {certificates[props.language].createAndesBtn}
        </Button>
        <Button type={'ghost'} onClick={() => props.changeOption(0)}>
          {certificates[props.language].cancel}
        </Button>
      </Form>
    </>
  );
};

const mapStateToProps = state => {
  let queryParams = getQueryParams(state.router.location.search);
  return {
    language: state.auth.language,
    requiresCert: state.auth.requiresCert,
    initialValues: {
      agree: false,
      updateGraphicImage: false,
      referenceNumber: queryParams.referenceNumber,
      authCode: queryParams.authCode
    }
  }
};

export default PrivateRoute(connect(mapStateToProps, { importCertificateNetcoCodes })((reduxForm({
  form: 'netcoCertificate_form'
})(NetcoPKIOption))));

