import netcoServer from '../../apis/netcoServer';
import {SET_TASK_CONFIGURATION} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import loadingCall from "../helpers/loadingCall";
import getTaskConfiguration from "./getTaskConfiguration";

const setTaskConfiguration= (formValues) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/setTaskConfiguration', formValues.hasTask ? formValues : {hasTask: formValues.hasTask}));
  handleResponse(dispatch, response, SET_TASK_CONFIGURATION, onSuccessPayload, null, [getTaskConfiguration]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Configuracion de firma masiva guardada satisfactoriamente!');
};
export default setTaskConfiguration;