import netcoServer from '../../apis/netcoServer';
import {GET_SIGN_REPORT} from "../types";
import tableLoadingCall from '../helpers/tableLoadingCall';
import handleBase64FileResponse from "../helpers/handleBase64ZipResponse";

const getSignReport= (formValues, page, pageSize, sidx, sord, returnString = true, countDocuments=false) => async (dispatch) => {
  formValues.returnString = returnString;
  formValues.countDocuments=countDocuments;
  formValues.limit = pageSize;
  formValues.skip = (page - 1) * pageSize;
  formValues.sidx = sidx;
  formValues.sord = sord ? renderSord(sord) : '';
  const onSuccessPayload = data => {
    return {
      userReportList: {
        kind: countDocuments?'documents':'operations',
        total: data.result.total,
        entries: data.result.operations
      }
    };
  };
  if (returnString) {
    await tableLoadingCall(dispatch, () => netcoServer.newPost('/UserService/getSignReport', formValues), GET_SIGN_REPORT, onSuccessPayload);
  } else {
    let response = await netcoServer.newPost('/UserService/getSignReport', formValues);
    handleBase64FileResponse(response.data.result.operations, 'documents.zip');
  }
};

const renderSord = sord => {
  return sord === 'ascend' ? 'asc' : 'desc';
};

export default getSignReport;