import React from 'react';
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import CustomizationForm from './customizationForm';
import setConfigurationParam from '../../../actions/admin/setConfigurationParam'
import { change } from "redux-form";
import { getDefaultCustom } from '../../Helpers/utils';



class customization extends React.Component {

  updateCustomization = (custom, formValues, propertyKey, compareValues) => {
    if (formValues[propertyKey] && formValues[propertyKey] !== compareValues[propertyKey]) {
      custom[propertyKey] = formValues[propertyKey]
    }
  }

  setCustomization = (formValues) => {
    if(formValues && formValues.colorPrimary){
      const colorPrimary = formValues.colorPrimary;
      const separateColorPrimary = colorPrimary.split(',')
      if(separateColorPrimary.length === 4){
        const rgbPrimary = colorPrimary.substring(colorPrimary.lastIndexOf("("), colorPrimary.lastIndexOf(","))
        formValues.colorPrimaryOpacity = 'rgba' +rgbPrimary + ', 0.08)'
      }else if(separateColorPrimary.length === 3){
        let rgbPrimary = colorPrimary.substring(colorPrimary.lastIndexOf("("), colorPrimary.length)
        rgbPrimary = rgbPrimary.slice(0, -1)
        formValues.colorPrimaryOpacity = 'rgba' +rgbPrimary + ', 0.08)'
      }
    }
    
    const custom = {}
    const form = 'admin_customization_form';
    const defaultCustom = getDefaultCustom(this.props.language);

    defaultCustom.map(defaultGroup => Object.keys(defaultGroup).map(propertyKey =>
      formValues && Object.keys(formValues).length !== 0 ?
        this.updateCustomization(custom, formValues, propertyKey, defaultGroup) : this.props.dispatch(change(form, propertyKey, defaultGroup[propertyKey]))
    ));

    const formSend = {
      "name": "customization",
      "value": Object.keys(custom).length !== 0 ? JSON.stringify(custom) : ''
    }
    this.props.setConfigurationParam(formSend)
  }

  render() {
    return (
      <CustomizationForm setCustomization={this.setCustomization} language={this.props.language} maxFileSize={this.props.maxFileSize} />
    );
  };
}

const mapStateToProps = state => {
  return {
    maxFileSize: state.user.maxFileSize,
    language: state.auth.language,
  };
};

export default connect(mapStateToProps, { setConfigurationParam })(reduxForm({ form: 'admin_customization_form' })(customization));