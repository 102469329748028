import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Modal } from "antd";

// import 'antd/lib/modal/style/css';


import GraphicSignatureForm from "../Profile/Signature/GraphicSignatureForm";
class SignatureUpImageModal extends React.Component {

render() {
  return (
    (<Modal open={this.props.modalType !== null} width={900} {...this.props.modalProps}>
      <GraphicSignatureForm finishUpImageSignature={this.props.onSubmit}/>
    </Modal>)
  );
}
}
export default (SignatureUpImageModal);