import netcoServer from '../../apis/netcoServer';
import {GET_VALID_OPERATIONS} from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";

const getValidOperations= () => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getValidOperations', {}));
  handleResponse(dispatch, response, GET_VALID_OPERATIONS, onSuccessPayload)
};

const onSuccessPayload = data => {
  return {
    signatureFiles: {
      validOperations: data.result.operations,
      operation: data.result.operations[0].key
    }
  };
};
export default getValidOperations;