import { Col, Pagination, Row, Select } from "antd";
import general from "../../const/general";
import React from "react";

const Paginator = (props) => {
    return <Row className={'p-20'}>
        <Col xs={24} md={7}>
            <Select className='text-cente' style={{ minWidth: '61px', marginRight: 12 }} value={props.pageSize} onChange={props.handlePageSizeChange}>
                {props.initialValues.pageSizes.map((pageSize, index) => <Select.Option key={index + 'p'} value={pageSize}>{pageSize}</Select.Option>)}
                {props.initialValues.addTotalToSizes && props.total < props.limit ? <Select.Option key={props.total} value={props.total}>
                    {general[props.language].totalLabel}</Select.Option> : ''}
            </Select>
            <span>{general[props.language].results_per_page} </span>
        </Col>
        <Col xs={24} md={10} className='text-center'>
            <Pagination showSizeChanger={false} size="small" pageSize={props.pageSize} current={props.page}
                onChange={props.handlePageChange} total={props.total} />
        </Col>
        <Col xs={24} md={7} className='text-center mb-10'>
            {props.total && <span>{general[props.language].total_elements.replace('$0', props.total)}</span>}
        </Col>
    </Row>;
}
export default Paginator;