import React from "react";
import { Modal } from "antd";
import Templates from "."

class TemplatesModal extends React.Component {
  render() {
    return (
      (<Modal
        open={this.props.modalType !== null} width={820} {...this.props.modalProps} title={this.props.modalProps.title} centered={true} >
        <Templates isModal={true} selectedTemplates={this.props.modalProps.selectedTemplates} onCancel={this.props.modalProps.onCancel} />
      </Modal>)
    );
  }
}
export default TemplatesModal;