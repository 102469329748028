const reports = {
  es: {
    title: 'Reportes',
    info: 'Elige el reporte que quieres ver',
    pages_report: 'Reporte de paginas',
    logs_report: 'Historial',
    documents_report: 'Documentos firmados',
    operations_report: 'Operaciones de firma',
    pending_report: 'Documentos pendientes',
    andes_report: 'Solicitudes Andes SCD',
    search: 'Búsqueda',
    filter: 'Filtrar',
    export: 'DESCARGAR REPORTE',
    date_column: 'Fecha',
    user_column: 'Usuario',
    operation_column: 'Operacion',
    detail_column: 'Detalle',
    ip_column: 'IP',
    id_column: 'ID',
    name_column: 'Nombre',
    type_column: 'Tipo de documento',
    number_column: '# de documentos firmados',
    operations_column: '# de operaciones de firma',
    final_date: 'Fecha final',
    initial_date: 'Fecha inicial',
    certificates: 'Certificados',
    userEqual: 'Igual a',
    beginsWith: 'Comienza por',
    pendingStatus:'Pendiente',
    okStatus:'Emitido',
    cancelStatus:'Rechazado',
    fileName: 'Nombre del archivo',
    allExtensions:{
      '':'TODAS LAS EXTENSIONES',
      DOCX:'DOCX',
      PDF:'PDF',
      XML:'XML',
      P7M:'P7M',
      XLSM:'XLSM'
    },
    allOperations:{
      0: "TODAS LAS OPERACIONES",
      1: "Firma digital",
      2: "Firma electronica",
      3: "Firma digital con sello de tiempo",
      4: "Firma digitalizada"
    },
    allStatus:{
      "": "TODOS LOS ESTADOS",
      0: "Pendiente",
      2: "Rechazado",
      1: "Emitido"
    },
    allUsers: 'TODOS LOS USUARIOS'
  },
  en: {
    title: 'Reports',
    info: 'Choose in the top right corner the report you want to see',
    pages_report: 'Pages report',
    logs_report: 'Logs',
    documents_report: 'Signed documents',
    operations_report: 'Signature Operations',
    pending_report: 'Pending documents',
    andes_report: 'Andes SCD requests',
    search: 'Search',
    filter: 'Filter',
    export: 'Export',
    date_column: 'Date',
    user_column: 'User',
    operation_column: 'Operation',
    detail_column: 'Detail',
    ip_column: 'IP',
    id_column: 'ID',
    name_column: 'Name',
    type_column: 'Type of document',
    number_column: '# of signed documents',
    operations_column: '# of signature operations',
    final_date: 'End date',
    initial_date: 'Initial date',
    certificates: 'Certificates',
    userEqual: 'Equals to',
    beginsWith: 'Begins with',
    pendingStatus:'Pending',
    okStatus:'Issued',
    cancelStatus:'Rejected',
    fileName: 'File Name',
    allExtensions:{
      Todos:'ALL EXTENSIONS',
      DOCX:'DOCX',
      PDF:'PDF',
      XML:'XML',
      P7M:'P7M',
      XLSM:'XLSM'
    },
    allOperations:{
      0: "ALL OPERATIONS",
      1: "Digital Signature",
      2: "Electronic Signature",
      3: "Digital Signature with timestamp",
      4: "Graphic Signature"
    },
    allStatus:{
      "": "ALL STATUSES",
      0: "Pending",
      2: "Rejected",
      1: "Issued"
    },
    allUsers: 'ALL USERS'
  }
};
export default reports;
