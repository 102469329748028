import netcoServer from '../../apis/netcoServer';
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";
import getKeysInfo from "./getKeysInfo";
import getValidOperations from "../signature/getValidOperations";


const deleteKey= (id) => async (dispatch) => {
  let formData = {keyIDS:id};
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/deleteUserCredentials', formData));
  handleResponse(dispatch, response, null, null, null, [getKeysInfo,getValidOperations]);
};
export default deleteKey;