
const verify = {
  es: {
    title: 'Verificar firma',
    info: 'Selecciona y carga los archivos firmados que deseas verificar. ',
    select_files: 'Seleccionar archivo(s)',
    verify: 'Verificar',
    signer_info: 'Información del firmante',
    signer_name: 'Nombre:',
    signer_contact: 'Contacto:',
    signer_entity: 'Entidad emisora:',
    signer_validity: 'Validez del certificado:',
    signature_info: 'Información de la firma',
    signature_date: 'Fecha de Firma',
    signature_comment: 'Comentario:',
    signature_location: 'Ubicacion:',
    signature_ltv: 'Habilitada para LTV:',
    timestamp_info: 'Información del Sello de Tiempo',
    timestamp_authority: 'Autoridad de sello de tiempo:',
    timestamp_date: 'Fecha de sello de tiempo:',
    modal_title: 'Detalle de firma',
    state_column: 'Estado',
    document_column: 'Documento',
    details: 'Detalles',
    originalFile: 'Archivo Original',
    signatureOK: 'Firma no modificada',
    signatureError:'Error en la firma',
    verifyingMessage:'No tardamos, estamos verificando las firmas de tus documentos',
    getFileError: 'No se logró traer el archivo, puede ser que ya no esté disponible',
    infoImportant: 'Información importante',
    digitalinfo_veri_one: 'Arrastre documentos PDF  a la sección resaltada.  Tamaño máximo: 20 MB. Click en verificar.',
    info_veri_two: 'Procesaremos su solicitud para confirmar la validez de las firmas digitales en el documento.',
    info_veri_three: 'Verá una columna de ESTADO para confirmar la integridad de la firma digital. Para detalles técnicos, consulte el botón en la columna ACCIONES.',
    qrinfo_veri_one: 'Use la cámara de su celular para escanear el código QR en la parte inferior izquierda del lomo del documento físico. Esto le llevará a la URL de verificación correspondiente.',
    firstStep: 'Instrucciones de verificador online de documentos con firma digital',
    SecondStep: 'Instrucciones de verificador de documentos físicos de firmas con código QR',
    moreinfo: '¿Tienes alguna duda?',
    moreinfo2: 'Mira las instrucciones y ayuda para',
    morefromHere: 'validar tu firma de forma digital aquí'
  },
  en: {
    title: 'Signature verification',
    info: 'Select file(s) to verify',
    select_files: 'Select file(s)',
    verify: 'Verify',
    signer_info: 'Signer information',
    signer_name: 'Name:',
    signer_contact: 'Contact:',
    signer_entity: 'Issuing entity:',
    signer_validity: 'Validity of the certificate:',
    signature_info: 'Signature information',
    signature_date: 'Signature date:',
    signature_comment: 'Comment:',
    signature_location: 'Location:',
    signature_ltv: 'LTV enabled:',
    timestamp_info: 'Timestamp information',
    timestamp_authority: 'Timestamp authority:',
    timestamp_date: 'Timestamp date:',
    modal_title: 'Signature detail',
    state_column: 'Status',
    document_column: 'Document',
    details: 'Details',
    originalFile: 'Original File',
    signatureOK: 'Signature intact',
    signatureError:'Signature error',
    verifyingMessage:'Won´t take long, we are checking all signatures',
    getFileError: 'The file could not be fetched, it may be that it is no longer available',
    infoImportant: 'Important information',
    digitalinfo_veri_one: 'Drag PDF documents to the highlighted section.  Maximum size: 20 MB. Click on verify.',
    info_veri_two: 'We will process your request to confirm the validity of the digital signatures on the document.',
    info_veri_three: 'You will see a STATUS column to confirm the integrity of the digital signature. For technical details, see the button in the ACTIONS column.',
    qrinfo_veri_one: 'Use your cell phone camera to scan the QR code on the bottom left of the spine of the physical document. This will take you to the corresponding verification URL.',
    firstStep: 'Instructions for online document verifier with digital signature',
    SecondStep: 'Instructions for physical document verifier of signatures with QR code',
    moreinfo: 'Do you have any doubts?',
    moreinfo2: 'See instructions and help for',
    morefromHere: 'validate your signature digitally here'
  }
};
export default verify;
