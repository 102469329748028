import {
    GET_METADATA_OPTIONS
  } from "../actions/types";
  
  const INITIAL_STATE = {
    options: []
  };
  
  const metaDataReducer= (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_METADATA_OPTIONS:
        return {...state, ...action.payload};
      default:
        return state;
    }
  };
  export default metaDataReducer;