import {
  CHECK_APP_CODE_AUTHENTICATION,
  CHECK_STRONG_AUTHENTICATION, CLEAN_SIGNATURE_FILES,
  CREATE_PDF_FROM_TEMPLATE,
  GET_PDF_TEMPLATE_FIELDS,
  GET_USER_IMAGE_TO_SHOW,
  GET_VALID_OPERATIONS,
  LOG_OUT,
  PUT_PREVIEW_SIGN_FILES, RESET_STRONG_TX, SIGN_PENDING_FILES, SIGN_PENDING_URL_FILE, START_STRONG_TX,
  GET_SIGNATUREQR, SET_LOADING_PAD
} from "../actions/types";

const INITIAL_STATE = {
  entries: null,
  total: 0,
  operation: 1,
  selectedTemplate: null,
  selectedTemplateName: '',
  pre: true,
  mail: null,
  save: true,
  validOperations: [],
  fields: [],
  image: '',
  txCallback: null,
  txContent: null,
  txValues: {},
  selectedPendingFile: null,
  hwimgid: '',
  infoImageQR:{base64:''},
  loadingPad:false
};

const signatureFiles= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PUT_PREVIEW_SIGN_FILES:
      return {...state, ...action.payload.signatureFiles};
    case GET_VALID_OPERATIONS:
      return {...state, ...action.payload.signatureFiles};
    case GET_PDF_TEMPLATE_FIELDS:
      return {...state, ...action.payload.signatureFiles};
    case GET_USER_IMAGE_TO_SHOW:
      return {...state, ...action.payload.signatureFiles};
    case CREATE_PDF_FROM_TEMPLATE:
      return {...state, ...action.payload.signatureFiles};
    case SIGN_PENDING_FILES:
      return {...state, ...action.payload.signatureFiles};
    case START_STRONG_TX:
      return {...state, ...action.payload.signatureFiles};
    case CHECK_STRONG_AUTHENTICATION:
      return {...state, ...action.payload.signatureFiles};
    case SIGN_PENDING_URL_FILE:
      return {...state, ...action.payload.signatureFiles};
    case CHECK_APP_CODE_AUTHENTICATION:
      return {...state, ...action.payload.signatureFiles};
    case SET_LOADING_PAD:
      return {...state, ...action.payload.signatureFiles};
    case GET_SIGNATUREQR:
      return {...state, ...action.payload.signatureFiles};
    case CLEAN_SIGNATURE_FILES:
      return {...state, entries: null, total: 0};
    case RESET_STRONG_TX:
      return {...state, txCallback: null, txContent: null};
    case "@@router/LOCATION_CHANGE":
      return {...state, txCallback: null, txContent: null};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default signatureFiles;