import React from 'react';
import {Tabs} from 'antd'

import './tablhelpers.css';

const TabsHelper = (props) => {
    const items=[];
    [...Array(props.number)].forEach((x, i) => {
        const Content = props.content[i];
        items.push({
            key: props.keys[i],
            label: props.titles[i],
            children: <Content {...props.tabProps[i]}/>
        });
    });
    return (
      <Tabs defaultActiveKey={props.activeKey} onChange={props.onChange} type="card" items={items}>
      </Tabs>
  );
};


export default TabsHelper;
