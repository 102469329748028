import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form, Button, Card, Col, Row } from "antd";
import { CreateCheckbox, CreateFileField, CreatePasswordField } from "../../Helpers/Input/InputCreator";
import { required } from "../../Helpers/Input/validations";
import importCertificateFromFIEL from '../../../actions/certificates/importCertificateFromFIEL';
import { Field, reduxForm } from "redux-form";

// import 'antd/lib/card/style/css';


// import 'antd/lib/alert/style/css';


import { connect } from "react-redux";
import TitleAndInfo from "../../Helpers/TitleAndInfo";
import PrivateRoute from "../../Root/PrivateRoute";
import general from "../../../const/general";
import certificates from "../../../const/certificates";
import { LockOutlined } from "@ant-design/icons";


class FielMexico extends React.Component {

  state = { formFile: [], formCert: [] };

  onSubmit = (formValues) => {
    let key = this.state.formFile[0] === undefined ? null : this.state.formFile[0].file;
    let cert = this.state.formCert[0] === undefined ? null : this.state.formCert[0].file;
    formValues.language = this.props.language;
    formValues.requiresCert = this.props.requiresCert;
    this.props.importCertificateFromFIEL(formValues, key, cert);
  };

  handleOnDrop = (newFile, onChange, name) => {
    if (newFile[0]) {
      const formFile = {
        file: newFile[0],
        name: newFile[0].name,
        preview: URL.createObjectURL(newFile[0]),
        size: newFile[0].size
      };
      this.props.change(name, formFile);
      this.setState(name === 'key' ? { formFile: [formFile] } : { formCert: [formFile] })
    }
  }

  render() {
    return (
      <Card bordered={false}>
        <TitleAndInfo title={certificates[this.props.language].otherTitle}
          info={certificates[this.props.language].fiel_info2} />
        <Form onFinish={this.props.handleSubmit(this.onSubmit)} className={'p-20'}>
          <Row gutter={16}>
            <Col span={12}>
              <div style={{ marginTop: '10px' }} className={'mb-10'}>{certificates[this.props.language].key_file}</div>
              <Field
                name="key"
                accept={".key"}
                component={CreateFileField}
                handleOnDrop={this.handleOnDrop}
                type='file'
                formFile={this.state.formFile}
                validate={required}
                multiple={false}
              />
            </Col>
            <Col span={12}>
              <div style={{ marginTop: '10px' }} className={'mb-10'}>{certificates[this.props.language].cer_file}</div>
              <Field
                name="cert"
                accept={".cer, .crt"}
                component={CreateFileField}
                handleOnDrop={this.handleOnDrop}
                type='file'
                formFile={this.state.formCert}
                validate={required}
                multiple={false}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <div style={{ marginTop: '10px' }} className={'mb-10'}>{general[this.props.language].password}</div>
              <Field
                name="password"
                prefix={<LockOutlined className={'icon-font'} />}
                component={CreatePasswordField}
                size={'large'}
                className={'mb-10'}
                placeholder={general[this.props.language].password}
                validate={[required]}
                hasFeedback />
            </Col>
            <Col span={12}>
              <Field name="agree"
                type={'checkbox'}
                component={CreateCheckbox}
                validate={required}
                hasFeedback>
                <a rel='noopener noreferrer' target={'_blank'} href={window.netcourlid + '/comagre/AcuerdoDeComunicaciones.pdf'}>
                  {general[this.props.language].agree}
                </a>
              </Field>
              <Field name="updateGraphicImage"
                type={'checkbox'}
                component={CreateCheckbox}
                hasFeedback>
                {certificates[this.props.language].graphic_image}
              </Field>
            </Col>
            <Col span={12}>

            </Col>
          </Row>
          <Row className={'flexRight'} gutter={16}>
            <Button htmlType={'submit'} className={'float-right'} type={'primary light-primary rounded-sm'}>
              {certificates[this.props.language].createZIP}
            </Button>
          </Row>
        </Form>
      </Card>
    );
  }

}

const mapStateToProps = state => {
  return {
    language: state.auth.language,
    requiresCert: state.auth.requiresCert,
    initialValues: {
      agree: false,
      updateGraphicImage: false
    }
  }
};

export default PrivateRoute(connect(mapStateToProps, { importCertificateFromFIEL })((reduxForm({
  form: 'fielCertificate_form'
})(FielMexico))));
