import React from 'react';
import { connect } from "react-redux";
import { Button, ConfigProvider, Table, Row, Col, Typography, Menu, Dropdown, Collapse } from "antd";
import esCO from "antd/es/locale/es_ES";
import enUS from "antd/es/locale/en_US";
import getKeysInfo from '../../actions/certificates/getKeysInfo';
import updateKeyName from '../../actions/certificates/updateKeyName';
import deleteKey from '../../actions/certificates/deleteKey';
import { RENAME_KEY, INFO_KEY, CONFIRM_MODAL } from "../Feedback/Modal/types";
import modalShow from "../../actions/modal/modalShow";
import modalDismiss from "../../actions/modal/modalDismiss";
import getUserInfo from "../../actions/profile/getUserInfo";
import updateUserParam from "../../actions/profile/updateUserParam";
import handleBase64ZipResponse from "../../actions/helpers/handleBase64ZipResponse";
import general from "../../const/general";
import profile from "../../const/profile";
import certificates from "../../const/certificates";
import { ReactComponent as CertificatesIcon } from '../../resources/img/NewIcons/certificates/certificates.svg';
import rocketIcon from '../../resources/img/NewIcons/certificates/rocket.svg'
import selectedIcon from '../../resources/img/NewIcons/selected.svg'
import actionsIcon from '../../resources/img/NewIcons/management/actions.svg'
import collapseUpIcon from '../../resources/img/NewIcons/sign/collapseUpIcon.svg';
import collapseDownIcon from '../../resources/img/NewIcons/sign/collapseDownIcon.svg';

class CertificatesManager extends React.Component {

    componentDidMount() {
        this.props.getUserInfo();
        this.props.getKeysInfo();
    }

    renameKey = (id, name) => {
        this.props.modalShow({
            modalType: RENAME_KEY,
            modalProps: { footer: null, title: certificates[this.props.language].changeNameKey + ' ' + id + ' - ' + name },
            componentProps: {
                language: this.props.language, btn: certificates[this.props.language].changeNameKeyBtn,
                onSubmit: (formValues) => { this.props.updateKeyName(id, formValues.nameKey); this.props.modalDismiss() }
            }
        });
    };

    infoKey = (infoKey) => {
        this.props.modalShow({
            modalType: INFO_KEY,
            modalProps: { footer: null, title: infoKey.id + ' - ' + infoKey.name },
            componentProps: { language: this.props.language, infoKey: infoKey }
        });
    };

    downloadCert = (cert, name) => {
        handleBase64ZipResponse(cert, name + '.cer');
    }

    confirmDelete = (id, name) => {
        const msg = certificates[this.props.language].confirmDelete;
        this.props.modalShow({
            modalType: CONFIRM_MODAL,
            modalProps: { footer: null, title: certificates[this.props.language].delete + ' ' + id + ' - ' + name },
            componentProps: { info: msg, language: this.props.language, onCancel: this.props.modalDismiss, onSubmit: () => this.deleteUserCredentials(id) }
        });
    };

    deleteUserCredentials = (id) => {
        this.props.modalDismiss();
        this.props.deleteKey(id);
    };


    onRowKeysChange = keySelected => {
        this.props.updateUserParam({ paramName: "defaultkey", paramValue: keySelected[0] });
    };


    columns() {
        return [{
            key: 'selected',
            render: (text, record) => (
                record.id === this.props.defaultKey ? <img alt='selected' src={selectedIcon} /> : null
            ),
            className: 'mobile-show'
        }, {
            title: profile[this.props.language].id.toUpperCase(),
            dataIndex: 'id',
            className: 'mobile-show',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.id - b.id,
        }, {
            title: certificates[this.props.language].certificate,
            dataIndex: 'name',
            className: 'mobile-show',
            sorter: (a, b) => a.name.localeCompare(b.name)
        }, {
            title: profile[this.props.language].serial,
            dataIndex: 'serial',
            className: 'mobile-hide',
            render: (text, record) =>
                <Typography  className='text-link' style={{ paddingLeft: 0 }} onClick={() => this.downloadCert(record.cert, record.name)}>{text}</Typography>
        }, {
            title: certificates[this.props.language].since,
            dataIndex: 'notBefore',
            className: 'mobile-hide',
        },
        {
            title: certificates[this.props.language].until,
            dataIndex: 'notAfter',
            className: 'mobile-hide',
        }, {
            title: general[this.props.language].actions,
            key: 'action',
            render: (text, record) => {
                const isDefaultKey = record.id === this.props.defaultKey;
                const items = [{ key: 'detail', label: certificates[this.props.language].detailsAction }, { key: 'changeName', label: certificates[this.props.language].changeNameKeyBtn }];
                if (record.cert)
                    items.push({ key: 'download', label: certificates[this.props.language].downloadAction});
                if (record.id !== this.props.defaultKey || this.props.keys.length === 1)
                    items.push({ key: 'delete', label: certificates[this.props.language].deleteAction });
                const handleItemClick = (evt) => {
                    switch (evt.key) {
                        case "detail":
                            this.infoKey(record);
                            break;
                        case "changeName":
                            this.renameKey(record.id, record.name);
                            break;
                        case "download":
                            this.downloadCert(record.cert, record.name);
                            break;
                        case "delete":
                            this.confirmDelete(record.id, record.name);
                            break;
                        default:
                            break;
                    }
                };
                return <>
                    <Dropdown trigger={['click']}
                              dropdownRender={()=>(
                            <Menu items={items} onClick={handleItemClick} />
                        )}>
                        <button className="unstyled-button float-right" style={{ marginLeft: 19 }}
                            onClick={e => e.preventDefault()}>
                            <img alt='actions' src={actionsIcon} />
                        </button>
                    </Dropdown>
                    <Button className={ isDefaultKey ?'border-button float-right':'float-right'} type={ isDefaultKey ? '' : 'ghost'} style={isDefaultKey ? { width: 159 } : { width: 148 }}
                        onClick={() => { record.id !== this.props.defaultKey && this.onRowKeysChange([record.id]) }}>
                        {record.id === this.props.defaultKey ? certificates[this.props.language].certificateInUse.toUpperCase() : certificates[this.props.language].useCertificate.toUpperCase()}
                    </Button>
                </>;
            }
            ,
            className: 'mobile-show'
        }];
    }

    getItemsCollapse = () => {
        return [
            {
                key:'1',
                label:this.renderPanelHeader(),
                children:<Typography className='size15' style={{ marginTop: -10, marginLeft: 60, marginBottom: 25 }}>
                    {certificates[this.props.language].steps[0]} <br />
                    {certificates[this.props.language].steps[1]} <br />
                    {certificates[this.props.language].steps[2]} <br />
                    {certificates[this.props.language].steps[3]}
                    <Button className='link size15' style={{ padding: '1px 0px 0px 4px', margin:0 }} onClick={() => this.props.showOptionsCertificates(true)} >{certificates[this.props.language].fromHere}</Button>
                </Typography>
            },
          ];
    };

    renderPanelHeader = () => {
        return <Typography className='text-title size18'>
            <img alt='rocket' src={rocketIcon} style={{ marginRight: 11 }} />
            {certificates[this.props.language].firstStep}
        </Typography>
    }; 
    
    render() {       
        return (
            <div style={{ margin: '0px 46px 0px 32px' }} >
                <Row className='new-card' style={{ padding:'60px 28px 37px 28px' }}>
                    <Col xs={24} md={13}>
                        <Typography className='text-title size18' >{certificates[this.props.language].startCertificates}</Typography>
                        <Typography className='size15' style={{ marginTop: 13 }}>{certificates[this.props.language].startDescription}</Typography>
                        <Button type="primary" className='mt-20' htmlType="submit" onClick={() => this.props.showOptionsCertificates(true)} style={{ width: '205px' }}>
                            {certificates[this.props.language].addNewCertificate}
                        </Button>
                    </Col>
                    <Col className='mt-35' xs={24} md={11} style={{ textAlign: 'center' }}>
                        <CertificatesIcon style={{maxWidth:'100%'}}/>
                    </Col>
                </Row>
                <Collapse  items={this.getItemsCollapse()} className='mt-40 mb-10 collapse-noborder' expandIcon={({ isActive }) => isActive ? <img alt={''} src={collapseUpIcon} /> : <img alt={''} src={collapseDownIcon} />}
                    expandIconPosition={'end'} defaultActiveKey={['1']}>
                </Collapse>
                <ConfigProvider locale={this.props.language === 'es' ? esCO : enUS}>
                    <Table
                        columns={this.columns()}
                        dataSource={this.props.keys}
                        size={'small'}
                        pagination={false}
                        rowKey={'id'}
                    />
                </ConfigProvider>
            </div>
        );
    }
}



const mapStateToProps = state => {
    return {
        user: state.user,
        defaultKey: state.user.defaultKey,
        language: state.auth.language,
        keys: state.certificate.keys
    };
};


export default connect(mapStateToProps, { getKeysInfo, updateKeyName, deleteKey, updateUserParam, getUserInfo, modalDismiss, modalShow, })(CertificatesManager);