import {push} from "connected-react-router";
import {INFO_SHOW} from "../types";
import logoutUser from '../auth/logoutUser';
import invalidSession from "../auth/invalidSession";
import {PRIVATE_FILES_ROUTE} from "../../components/Root/routes";

const handleResponseRedirect= (dispatch, response, type, onSuccessPayload, path = null, postActions = [], postCallbacks = []) => {
  let data;
  if (response === undefined) {
    data = {
      info: {
        message: 'Error al comunicarse con el servidor, intenta de nuevo mas tarde',
        type: 'error'
      }
    }
  } else if (response.data.success) {
    data = onSuccessPayload(response.data);
    if(path !== null)
      dispatch(push(path));
    if (postActions.length !== 0) {
      postActions.forEach((action) => {dispatch(action())})
    }
    if (postCallbacks.length !== 0) {
      postCallbacks.forEach((callback) => {callback()})
    }
  } else if (!response.data.success) {
    if(response.data.code === 440){
      type = INFO_SHOW;
      dispatch(invalidSession());
      dispatch(logoutUser());
      dispatch(push('/'));
    }
	else
	{		
		if(path === null)
			path= PRIVATE_FILES_ROUTE;		
		dispatch(push(path));
	}
    data = {
      info: {
        message: response.data.detail,
        type: 'error'
      }
    };
  }      
  dispatch({type: type, payload: data});
}
export default handleResponseRedirect;