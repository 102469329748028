
const load_files = {
  es: {
    title: 'Enviar a firma',
    info: 'Configura el envío de el y/o los documentos que vas a enviar para firma. ',
    select_file: 'Seleccionar archivo(s)',
    select_user: 'Ingresa el usuario:',
    select_choose_user: 'Correo electrónico',
    select_choose_userLabel: 'Solo yo voy a firmar',
    type_userLabel: 'Digita el correo del firmante',
    send_notification: 'Envía una notificación por email a los firmantes',
    orderedLabel: 'Establecer orden de firma',
    remindEvery: 'Recordar cada',
    remind: 'Enviar recordatorios automáticos',
    maxDate: 'Número de días antes del primer recordatorio',
    requiredDate: 'Si activa notificaciones la fecha es requerida',
    sendAll: 'Envía una copia de los documentos ya firmados a todos los firmantes',
    locate: 'Ubicar la imagen gráfica de firma de cada firmante',
    next: 'Siguiente archivo',
    lock:'Fijar campo de firma',
    move: 'Mover campo de firma',
    combinedFileNameLabel: 'Nombre para el archivo combinado',
    invalidMails: 'Correos invalidos en el campo enviar copia a',
    sendCopyTo: 'Envía una copia de los documentos ya firmados a otros correos',
    stringCopyTo: 'Digite correos electrónicos separados por comas sin dejar espacios',
    multipleWorkflow: 'Envía una copia del documento para que sea firmada individualmente por los otros firmantes',
    newSignTitle: 'Firmar',
    newWorkflowTitle: 'Consultar archivos enviados',
    duplicateSigner: 'Duplicar campo de firma',
    deleteSigner: 'Eliminar',
    newOptionName: 'Alias de la preferencia',
    optionNameRequired: 'El nombre del nuevo flujo a guardar es requerido',
    optionEqualsDefaultError: 'El nombre del nuevo flujo no puede ser igual al nombre por defecto',
    addSignerLabel: 'Añadir otro firmante',
    whoSignsLabel: '¿Vas a firmar solo tú, o quieres enviar un archivo a otros firmantes?',
    justMeLabel: 'Solo voy a firmar yo',
    othersLabel: 'Quiero enviar a otros firmantes',
    sendOptionsLabel: 'Elige cómo enviar cada documento.',
    continueLabel: 'Continuar',
    cancelLabel: 'Firma después',
    cancelSendLabel: 'Cancelar',
    setBeforeSend: 'Debes fijar todos los campos para poder enviar el documento',
    documentsTitle:'Añade los documentos',
    documentsDescription:'que quieres firmar o enviar para firma',
    signersTitle:'Añade firmantes',
    signersDescription:'Añade los usuarios que van a firmar los documentos',
    optionsTitle: 'Opciones',
    uploadText:'Subir Documento',
    dragText: 'Arrastrar archivo',
    infoMessage:'Este mensaje serà enviado a todos los firmantes',
    copySubtitle: 'Copias y notificaciones',
    reminderSubtitle: 'Recordatorios',
    hours: 'Hora(s)',
    newPreferenceText1:'Las preferencias seleccionadas en este envío se cargarán por defecto en su próximo envio.',
    newPreferenceTitle: 'Crear nueva preferencia',
    newPreferenceText2: 'Recuerde que siempre puede seleccionar o eliminar las preferencias desde el cuadro de preferencia',
    savePreferenceButton: 'Guardar preferencia',
    newPreferencesView: 'Preferencias guardadas',
    newPreferenceName: 'Nombre',
    selectedPreference: 'Seleccionar preferencia',
    metadataTitle: 'Agregar metadatos',
    saveMetadata: 'Guardar',
    withSesion: 'Firma con inicio de sesión',
    withoutSesion: 'Firma sin inicio de sesión',
    transferOperations: 'Transferir operaciones de firmas',
    noteTransferOperations: '*Recuerda, mantener operaciones disponibles para tus usuarios',
    confirmTransfer: 'Confirmación de transferencia de operaciones',
    confirmTransferText: 'Al enviar este documento, se ',
    confirmTransferText1: 'transferirán ({numTransfer}) operaciones, ',
    confirmTransferText2:'dependiendo del numero de documentos y firmantes.',
    acceptSend: 'Aceptar y enviar',
    totalOperations: '# Operaciones de firma disponibles: ',
    cancel: 'Cancelar'
  },
  en: {
    title: 'Send to sign',
    info: 'Setup sending of files to sign',
    select_file: 'Select file(s)',
    select_user: 'Enter the user:',
    select_choose_user: 'Select or enter a user:',
    select_choose_userLabel: 'I am the only signer',
    type_userLabel: 'Send to (Input the users separated by comma and without leaving empty spaces):',
    send_notification: 'Send notification',
    orderedLabel: 'Load in order',
    remindEvery: 'Remind every',
    remind: 'Enable reminders',
    maxDate: 'First reminder date',
    requiredDate: 'If send reminders is enabled date is required',
    sendAll: 'Send a final document copy to all signers',
    locate: 'Set image for each signer',
    next: 'Next File',
    lock:'Lock',
    move: 'Move',
    combinedFileNameLabel: 'Combined file name',
    invalidMails: 'Invalid emails in the field send copy to',
    sendCopyTo: 'Send a copy of the final document to other emails',
    stringCopyTo: 'Type emails separated by commas without leaving spaces',
    multipleWorkflow: 'Send a copy of the document for other users to sign individually',
    newSignTitle: 'Sign',
    newWorkflowTitle: 'View files sent',
    duplicateSigner: 'Duplicate',
    deleteSigner: 'Delete',
    newOptionName: 'Name to save workflow options',
    optionNameRequired: 'Name to save workflow is required',
    optionEqualsDefaultError: 'New name cannot be the same as the default',
    addSignerLabel: 'Add ->',
    whoSignsLabel: '¿Will you sign or will you send the file to other users?',
    justMeLabel: 'Just me',
    othersLabel: 'Send to another users',
    sendOptionsLabel: 'Choose how to send each document.',
    continueLabel: 'Click to continue',
    cancelLabel: 'Sign later',
    cancelSendLabel: 'Cancel',
    setBeforeSend: 'You must set all fields before sending the document',
    documentsTitle:'Add documents',
    documentsDescription:'you want to sign or send for signing',
    signersTitle:'Add signers',
    signersDescription:'Add users to sign your documents',
    optionsTitle: 'Options',
    uploadText:'Upload document',
    dragText: 'Drag file',
    infoMessage:'This message will be sent to all signers',
    copySubtitle:'Copies and notifications',
    reminderSubtitle: 'Reminders',
    hours: 'Hour(s)',
    newPreferenceText1:'Preferences selected will be used on your next file load',
    newPreferenceTitle: 'Create new preference',
    newPreferenceText2: 'Keep in mind you can always select or delete preferences from the preview box',
    savePreferenceButton: 'Save preference',
    newPreferencesView: 'Saved preferences',
    newPreferenceName: 'Name',
    selectedPreference: 'Select preference',
    metadataTitle: 'Add metadata',
    saveMetadata: 'Save',
    withSesion: 'Signing with session', 
    withoutSesion: 'Signing without session',
    transferOperations: 'Transfer signature operations',
    noteTransferOperations: '*Remember, keep operations available to your users',
    confirmTransfer: 'Transfer of operations confirmation',
    confirmTransferText: 'By sending this document, ',  
    confirmTransferText1: 'will transfer ({numTransfer}) operations, ',
    confirmTransferText2:'depending on the number of documents and signatories.',
    acceptSend: 'Accept and send',
    totalOperations: '# Available signature operations: ',
    cancel: 'Cancel'
  }
};
export default load_files;
