import netcoServer from '../../apis/netcoServer';
import handleResponse from "../helpers/handleResponse";
import {GET_IMAGE_TYPE_LIST} from "../types";
import loadingCall from "../helpers/loadingCall";

const getImageTypesList= () => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getImageTypesList', {}));
  handleResponse(dispatch, response, GET_IMAGE_TYPE_LIST, onSuccessPayload)
};

const onSuccessPayload = data => {
  return {
    user: {
      imageTypes: data.result.imageTypes
    }
  };
};
export default getImageTypesList;