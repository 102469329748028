import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Card, Col, Row } from "antd";
import {CreateCheckbox, CreateTextField} from "../../Helpers/Input/InputCreator";
import {required} from "../../Helpers/Input/validations";
import {Field, reduxForm} from "redux-form";

// import 'antd/lib/card/style/css';


// import 'antd/lib/alert/style/css';


import {connect} from "react-redux";
import importCertificateNetcoCodes from '../../../actions/certificates/importCertificateNetcoCodes';
import PrivateRoute from "../../Root/PrivateRoute";
import _ from "lodash";
import certificates from "../../../const/certificates";
import general from "../../../const/general";
import auth from "../../../const/auth";
import {signerHelpURL} from "../../Helpers/utils";
import {trimNormalizer} from "../../Helpers/Input/normalizers";

const NetcoPki = (props) => {

  const onSubmit = (formValues) => {
    formValues.language=props.language;
	formValues.requiresCert=props.requiresCert;
	props.importCertificateNetcoCodes(formValues);
  };

  return (
    <Card bordered={false}>
      <div className={'title-info'}>
        <h2 className={'title'}>Netco PKI</h2>
        <div data-show="true" className="ant-alert ant-alert-info ant-alert-no-icon mb-10 text-center info" role="alert"
             style={{maxHeight: '100px'}}>
          <div className="ant-alert-content">
            <div className="ant-alert-message">
              {certificates[props.language].netco_info2}
              <a rel='noopener noreferrer' target={'_blank'} href={signerHelpURL}>{auth[props.language].moreInfoPKI}</a></div>
          </div>
        </div>
      </div>
      <Form onFinish={props.handleSubmit(onSubmit)} className={'p-20'}>
        <Row gutter={16}>
          <Col span={12}>
            <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[props.language].reference_number}:</div>
            <Field
                   name="referenceNumber"
                   component={CreateTextField}
                   placeholder={certificates[props.language].reference_number}
                   validate={[required]}
                   normalize={trimNormalizer}
                   hasFeedback/>
          </Col>
          <Col span={12}>
            <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[props.language].authorization_code}:</div>
            <Field
                   name="authCode"
                   component={CreateTextField}
                   placeholder={certificates[props.language].authorization_code}
                   validate={[required]}
                   normalize={trimNormalizer}
                   hasFeedback/>
          </Col>
          <Col span={12}>
            <Field name="agree"
                   type={'checkbox'}
                   component={CreateCheckbox}
                   validate={required}
                   hasFeedback>
              <a rel='noopener noreferrer' target={'_blank'} href={window.netcourlid + '/comagre/AcuerdoDeComunicaciones.pdf'}>
                {general[props.language].agree}
              </a>
            </Field>
          </Col>
          <Col span={12}>
            <Field name="updateGraphicImage"
                   type={'checkbox'}
                   component={CreateCheckbox}
                   hasFeedback>
              {certificates[props.language].graphic_image}
            </Field>
          </Col>
        </Row>
        <Row className={'flexRight'} gutter={16}>
          <Button htmlType={'submit'} className={'float-right'} type={'primary light-primary rounded-sm'}>
            {certificates[props.language].createPKI}
          </Button>
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = state => {
  let queryParams = state.router.location.search ? _.reduce(state.router.location.search.substring(1).split("&"),
      (result, value) => {
        let params = value.split("=");
        result[params[0]] = params[1];
        return result;
      }, {}) : {};
  return {
    language: state.auth.language,
	requiresCert: state.auth.requiresCert,
    initialValues: {
      agree: false,
      updateGraphicImage: false,
      referenceNumber: queryParams.referenceNumber,
      authCode: queryParams.authCode
    }
  }
};

export default PrivateRoute(connect(mapStateToProps, {importCertificateNetcoCodes})((reduxForm({
  form: 'netcoCertificate_form'
})(NetcoPki))));
