import netcoServer from '../../apis/netcoServer';
import {CHECK_BASIC_AUTHENTICATION, SET_CONFIGURATION_PARAM} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import getConfiguration from "./getConfiguration";
import modalDismiss from "../modal/modalDismiss";
import loadingCall from "../helpers/loadingCall";
import isLoggedIn from '../auth/isLoggedIn';

const setConfigurationParam= (formValues) => async (dispatch) => {
  const onSuccessPayload = () => {
    if(formValues.name==='entrustServer') {
      dispatch({type: CHECK_BASIC_AUTHENTICATION, payload: {auth: {entrust: formValues.value}}});
    }
      return infoPayload('success', 'Parametro de configuracion guardado satisfactoriamente!');
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/setConfigurationParam', formValues));
  handleResponse(dispatch, response, SET_CONFIGURATION_PARAM, onSuccessPayload, null, [getConfiguration, modalDismiss, isLoggedIn]);
};
export default setConfigurationParam;