import React, { useState } from "react";
import {Tabs} from "antd";
import GraphicSignatureForm from "../Profile/Signature/GraphicSignatureForm";
import SignatureHandleOptions from "../Signature/SignatureHandleOptions.js"
import {connect} from "react-redux";
import signature from '../../const/signature';
import imgCertifiedSignature from '../../../src/resources/img/certifiedSignature.png'
import SignatureHandwritten from "../Signature/SignatureHandwritten";
import getBase64File from "../../actions/helpers/getBase64File";
import {usePromiseTracker} from "react-promise-tracker";
import { maxWidth } from "../Helpers/utils";

const { TabPane } = Tabs;


const renderContent=(imageTypeID, props,promiseInProgress)=>
{
    const image = promiseInProgress?"":imageTypeID===3?imgCertifiedSignature:`data:image/png;base64,${props.signatureFiles.image}`;
    switch(imageTypeID)
    {
        case 1:
            return <GraphicSignatureForm finishUpImageSignature={props.getImageToShow}/>
        case 2:
            return <SignatureHandwritten onStartPadSigning={props.onStartPadSigning} onSubmit={props.onSubmit} padData={props.padData} hwimgid={props.signatureFiles.hwimgid} setTimerGetImageQR={props.setTimerGetImageQR} clearTimerGetImageQR={props.clearTimerGetImageQR} modalDismiss={props.modalDismiss}
            padOverwrite= {props.padOverwrite} handlePadOverwriteChange={()=>props.handlePadOverwriteChange()} />;
        default:
            return <SignatureHandleOptions title={signature[props.language].signatureTitleTypeID[imageTypeID]} text={signature[props.language].signatureTextTypeID[imageTypeID]} image={image} textButton={signature[props.language].accept } modalDismiss={props.modalDismiss} />;
    }
}


const GraphicChooser=(props)=>
{
    const { promiseInProgress } = usePromiseTracker();
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const isVertical=width>=maxWidth || (width>height);
    React.useEffect(() => {
        window.addEventListener('resize', ()=>setHeight(window.innerHeight))
        window.addEventListener('resize', ()=>setWidth(window.innerWidth))
    }, []);
    const imageTypes=props.imageTypes;
    if(!imageTypes || imageTypes.length<1)
        return <div>No data...</div>;
    return <div className={'tabsContainer'}>
        <Tabs moreIcon={<></>} size={isVertical?'large':'small'} style={isVertical && height<310?{height:height-60,overflowY:'scroll'}:{marginTop:0}}
               onChange={(value)=>props.onChooserChange({"paramName":"useimage","paramValue":value},false)}
               defaultActiveKey={props.defaultType} type={isVertical?'normal':'card'} tabPosition={isVertical?'left':'top'}>
        {imageTypes.map(imageType => {
            const description=imageType.description,id=imageType.id;
            return <TabPane tab={description} key={id}>
                {renderContent(id,props,promiseInProgress)}
            </TabPane>;
        })}
    </Tabs></div>;
}

function mapStateToProps(state) {
    return {
        signatureFiles: state.signatureFiles
    }
}

export default (connect(mapStateToProps, {getBase64File})(GraphicChooser));