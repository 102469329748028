import React from 'react';
import {Col, Modal, Row} from "antd";

// import 'antd/lib/modal/style/css';


import admin from "../../const/admin";
import profile from "../../const/profile";

class SystemInformationModal extends React.Component {


  render() {
    return (
      (<Modal
        open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
        <Row gutter={16}>
          <Col xs={12} md={12}>

            <strong>{admin[this.props.language].system_security_provider} </strong>
            <p>{this.props.systemReport.provider}</p>

            <strong>{admin[this.props.language].system_state} </strong><p>{this.props.systemReport.status}</p>

            <strong>{admin[this.props.language].system_file_size} </strong>
            <p>{this.props.systemReport.signedFilesSize}
            </p>

            <strong>{admin[this.props.language].system_public_size} </strong>
            <p>{this.props.systemReport.publishedFilesSize}
            </p>
            <strong>{admin[this.props.language].system_com_size} </strong><p>{this.props.systemReport.comFilesSize}
          </p>
            <strong>{admin[this.props.language].system_wf_size} </strong>
            <p>{this.props.systemReport.wfFilesSize}
            </p>
            <strong>{admin[this.props.language].system_pending_size} </strong>
            <p>{this.props.systemReport.pendingFilesSize}
            </p>
          </Col>
          <Col xs={12} md={12}>
            <strong>{admin[this.props.language].system_hsm} </strong>
            <p>{this.props.systemReport.hsmInfo}</p>
            <strong>{admin[this.props.language].system_licence_used} </strong>
            <p>{this.props.systemReport.signerUsers}
            </p>

            <strong>{admin[this.props.language].system_licence} </strong>
            <p>{this.props.systemReport.signerTotalUsers==="-1"?profile[this.props.language].unlimited:this.props.systemReport.signerTotalUsers}
            </p>

            <strong>{this.props.systemReport.countDocuments?admin[this.props.language].system_docs_used:admin[this.props.language].system_ops_used} </strong>
            <p>{this.props.systemReport.signerOperations}
            </p>

            <strong>{this.props.systemReport.countDocuments?admin[this.props.language].system_docs_max:admin[this.props.language].system_ops_max} </strong>
            <p>{this.props.systemReport.signerMaxOperations==="-1"?profile[this.props.language].unlimited:this.props.systemReport.signerMaxOperations}
            </p>
            <strong>{admin[this.props.language].viewLogs} </strong><p className={'link'} onClick={this.props.getSystemLog}>log.txt</p>
            <strong>{admin[this.props.language].licenseType} </strong>
            <p>{this.props.systemReport.countDocuments?admin[this.props.language].licenseTypeDoc:admin[this.props.language].licenseTypeOp}
            </p>
          </Col>
        </Row>
      </Modal>)
    );
  }

}

export default SystemInformationModal;
