import {usePromiseTracker} from "react-promise-tracker";
import {Button} from "antd";
import React from "react";

const SubmitButton =(props)=>
{
    let { promiseInProgress } = usePromiseTracker();
    if(props.disabled)
        promiseInProgress=true;
    return <Button style={props.style} disabled={promiseInProgress} type={props.type} onClick={props.onSubmit}>{props.label}</Button>
};
export default SubmitButton;