import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form, Button, Select } from 'antd';
import { Field, reduxForm } from "redux-form";
import { CreateSelectField, CreateTextField } from "../Helpers/Input/InputCreator";
import getUserInfo from "../../actions/profile/getUserInfo";
import general from "../../const/general";
import profile from "../../const/profile";
import '../../resources/css/fonts.css';
import { fontNames } from "../../const/fonts";
import DropZoneField from "../Helpers/DropzoneField";
import getBase64File from '../../actions/helpers/getBase64File';
import updateUserImage from '../../actions/profile/updateUserImage';
import { connect } from 'react-redux'
import { formValueSelector } from "redux-form";
import { Typography } from 'antd';

class ImageOrTextForm extends React.Component {


    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    onSubmit = async (formValues) => {
        const fromText = formValues.name;
        const fontName = fromText ? formValues.selectedFont : null;
        await this.props.updateUserImage(this.props.base64Image, fromText, fontName);
        await this.props.getUserInfo([this.props.finishUpImageSignature]);
        this.props.closeDrawer && this.props.closeDrawer();
    };

    componentDidMount() {
        this.props.initialize({ selectedFont: 'Arial', base64Image: this.props.user.image });
    }

    setText = (newFont, newValue) => {
        if (!newFont || !newValue) {
            this.props.change('base64Image', this.props.user.image);
            return;
        }
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, 300, 50);
        ctx.font = "40px " + newFont;
        ctx.textBaseline = 'middle';
        ctx.fillText(newValue, 5, canvas.height / 2, 290);
        this.props.change('base64Image', canvas.toDataURL("image/png").split(';base64,')[1]);
    };

    handleOnDrop = async (files) => {
        if (files[0]) {
            let base64 = await getBase64File(files[0]);
            await this.props.reset();
            this.props.change('base64Image', base64);
        }
    };

    render() {
        return (
            <Form onFinish={this.handleSubmit}>
                <Form.Item style={{ width: '100%' }}>
                    <Typography className={'mb-10 text-title size12'}>{profile[this.props.language].write_name.toUpperCase()}</Typography>
                    <Field
                        name="name"
                        onChange={(event, newValue)=>{this.setText(this.props.selectedFont, newValue)}}
                        component={CreateTextField}
                        placeholder={profile[this.props.language].write_name_lb}
                        maxLength={40}
                        hasFeedback />
                </Form.Item>
                <Form.Item style={{ width: '100%' }}>
                    <Typography className={'mb-10 text-title size12'}>{profile[this.props.language].font.toUpperCase()}</Typography>
                    <Field
                        name="selectedFont"
                        component={CreateSelectField}
                        onChange={(newValue) => this.setText(newValue, this.props.name)}
                        hasFeedback>
                        {fontNames.map((fontName, index) => <Select.Option key={index} value={fontName}>{fontName}</Select.Option>)}
                    </Field>
                </Form.Item>
                <DropZoneField
                    accept={".jpg, .jpeg, .gif, .png"}
                    multiple={false}
                    handleOnDrop={this.handleOnDrop}
                    label={profile[this.props.language].allowed_values}
                />
                <Form.Item style={{ width: '100%', marginBottom:0 }}>
                    <div style={{ marginTop: '10px', textAlign: 'left' }} className={'mb-10'}>{profile[this.props.language].preview}</div>
                    {this.props.user.image && this.props.user.image !== '' ? <img className='border-image' style={{ maxWidth: '260px', maxHeight: '130px' }} alt={''} src={`data:image/png;base64, ${this.props.base64Image}`} /> : profile[this.props.language].noimage}
                    <canvas width={300} height={50} ref={this.canvasRef} style={{ visibility: "hidden" }} />
                </Form.Item>
                
                <Button className={'border-button'} htmlType={'submit'}
                    onClick={this.props.handleSubmit(this.onSubmit)}>
                    {general[this.props.language].save.toUpperCase()}
                </Button>
                {fontNames.map((fontName, index) => <div key={index} style={{ fontFamily: fontName, color: 'white', display: 'inline-flex' }}>.</div>)}
            </Form>

        );
    }
}

const selector = formValueSelector('graphic_signature_form');

const mapStateToProps = state => {
    return {
        language: state.auth.language,
        user: state.user,
        base64Image: selector(state, 'base64Image'),
        selectedFont: selector(state, 'selectedFont'),
        name: selector(state, 'name')
    };
};

ImageOrTextForm = connect(mapStateToProps, { updateUserImage, getUserInfo })(ImageOrTextForm)
export default reduxForm({ form: 'graphic_signature_form' })(ImageOrTextForm);
