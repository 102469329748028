import netcoServer from '../../apis/netcoServer';
import {INFO_SHOW, LOAD_END, LOAD_START, VERIFY_FILES} from "../types";
import getBase64File from "../helpers/getBase64File";
import {checkPDFPassword} from "../../components/Helpers/utils";
import infoPayload from "../helpers/payloadHandlers/infoPayload";

const verifyFiles= (files) => async (dispatch, getState) => {
  dispatch({type: LOAD_START});
  let data = {
    verifiedFilesList: {
      entries: []
    }
  };
  for(let i = 0; i < files.length; i++ ){
    let formData = {
      base64File: await getBase64File(files[i]),
      fileName: files[i].name
    };
    let password=null;
    try
    {
      password=await checkPDFPassword(formData.base64File,formData.fileName,'',dispatch,getState().auth.language);
    }
    catch(err)
    {
      dispatch({type: INFO_SHOW, payload: err});
      return;
    }
    formData.filePasswords=password;
    const response = await netcoServer.post('/VerifyService/verifyFiles', formData);
    if(!response || !response.data)
    {
      dispatch({type: INFO_SHOW, payload: infoPayload('error','Error al comunicarse con el servidor, intenta de nuevo mas tarde...')});
      return;
    }
    const result=response.data.result;
    if(!result)
    {
      dispatch({type: INFO_SHOW, payload: infoPayload('error',response.status + "")});
      return;
    }
    await result.responses.forEach((entry) => data.verifiedFilesList.entries.push(entry))
  }
  dispatch({type: VERIFY_FILES, payload: data});
  dispatch({type: LOAD_END});
};
export default verifyFiles;