import React from 'react';
import { Button, Drawer, Typography, Form, Select } from 'antd';
import { CreateCheckbox, CreateSelectField, CreateTextArea, CreateTextField } from "../../../Helpers/Input/InputCreator";
import { emailes, maxLengthEs, required } from "../../../Helpers/Input/validations";
import { Field } from "redux-form";
import profile from '../../../../const/profile';
import signature from '../../../../const/signature';
import general from '../../../../const/general';
import { SIGNATURE_SIGN_ROUTE } from '../../../Root/routes';
import './signatureOptions.css'
import { drawerWidthOptSig } from '../../../Helpers/utils';

const max = maxLengthEs(100);
class NewSignatureOptionsForm extends React.Component {

    renderSignatureTypes = () => {
        if (this.props.user.imageTypes)
            return (this.props.user.imageTypes.map(item => <Select.Option key={item.id}>{item.description}</Select.Option>));
        return <></>;
    };

    renderOperations = () => {
        if (this.props.validOperations)
            return (this.props.validOperations.map(item => {
                const key = parseInt(item.key);
                return <Select.Option key={key} value={key}>{item.value}</Select.Option>;
            }
            ));
        return <></>;
    };

    renderTimestamps = () => {
        if (this.props.user.timeStampServers)
            return (this.props.user.timeStampServers.map(item => {
                const key = item.id;
                return <Select.Option key={key}>{item.friendlyName}</Select.Option>
            }));
        return <></>;
    };

    render() {
        const widthApp = this.props.dimApp.width
        return (
            (<Drawer styles={{body:{backgroundColor: '#FFFFFF', padding: '0px 20px 82px 20px' }}} open={this.props.visible} placement={'right'}
                width={widthApp < drawerWidthOptSig ? widthApp : drawerWidthOptSig}
                closable={true} onClose={() => this.props.showOptionsSignature(false)} >
                <Typography rootClassName='text-title size22 color-black' style={{ marginBottom: 22 }}>{profile[this.props.language].settings}</Typography>
                <Form rootClassName='text-title size12' onFinish={this.props.handleSubmit(this.props.onSubmit)}>
                    {profile[this.props.language].chooseGraphics.toUpperCase()}
                    <Field
                        rootClassName='margin-input'
                        name="useImage"
                        component={CreateSelectField}
                        placeholder=""
                        validate={[required]}
                        hasFeedback>
                        {this.renderSignatureTypes()}
                    </Field>
                    {profile[this.props.language].chooseOperation.toUpperCase()}
                    <Field
                        rootClassName='margin-input'
                        name="operation"
                        component={CreateSelectField}
                        validate={[required]}
                        hasFeedback>
                        {this.renderOperations()}
                    </Field>
                    {profile[this.props.language].server.toUpperCase()}
                    <Field
                        rootClassName='margin-input'
                        name="tsid"
                        component={CreateSelectField}
                        validate={[required]}
                        hasFeedback>
                        {this.renderTimestamps()}
                    </Field>
                    {signature[this.props.language].email.toUpperCase()}
                    <Field
                        rootClassName='margin-input'
                        name="mails"
                        component={CreateTextField}
                        validate={[emailes]}
                        hasFeedback />
                    {general[this.props.language].comment.toUpperCase()}
                    <Field
                        rootClassName='margin-input'
                        name="reason"
                        maxLength={100}
                        validate={[max]}
                        rows={3}
                        component={CreateTextArea}
                        placeholder={profile[this.props.language].optionReason}
                        hasFeedback />
                    <Typography style={{ marginTop: 24, marginBottom: 19 }}>{profile[this.props.language].additionalSettings.toUpperCase()}</Typography>
                    <Field name="publish"
                        type={'checkbox'}
                        component={CreateCheckbox}
                        hasFeedback>
                        {profile[this.props.language].publish}
                    </Field>
                    <Field
                        rootClassName='margin-top-check'
                        name="certification"
                        type={'checkbox'}
                        component={CreateCheckbox}
                        hasFeedback>
                        {profile[this.props.language].certificate_type}
                    </Field>
                    <Field
                        rootClassName='margin-top-check'
                        name="previewPDF"
                        type={'checkbox'}
                        component={CreateCheckbox}
                        disabled={this.props.pathname === SIGNATURE_SIGN_ROUTE}
                        hasFeedback>
                        {signature[this.props.language].pre_pdf}
                    </Field>
                    <Field
                        rootClassName='margin-top-check'
                        name="signAllPages"
                        type={'checkbox'}
                        component={CreateCheckbox}
                        hasFeedback>
                        {general[this.props.language].signAllPages}
                    </Field>
                    <Field
                        rootClassName='margin-top-check'
                        name="p7z"
                        onChange={this.update}
                        type={'checkbox'}
                        component={CreateCheckbox}
                        hasFeedback>
                        {profile[this.props.language].signAllP7}
                    </Field>
                    <Field
                        rootClassName='margin-top-check'
                        name="fillpdf"
                        onChange={this.update}
                        type={'checkbox'}
                        component={CreateCheckbox}
                        hasFeedback>
                        {profile[this.props.language].fillpdfLabel}
                    </Field>
                    <Button type="primary" htmlType="submit" rootClassName='save-button'>
                        {profile[this.props.language].save.toUpperCase()}
                    </Button>
                </Form>
            </Drawer>)
        );
    }
}

export default NewSignatureOptionsForm;
