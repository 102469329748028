import React from 'react';
import { connect } from "react-redux";
import getPendingFilesInformation from '../../../actions/files/getPendingFilesInformation';
import getPendingFile from '../../../actions/files/getPendingFile';
import deletePendingFiles from '../../../actions/files/deletePendingFiles';
import { push } from "connected-react-router";
import PrivateRoute from "../../Root/PrivateRoute";
import general from "../../../const/general";
import signPendingFiles from "../../../actions/files/signPendingFiles";
import getUserInfo from "../../../actions/profile/getUserInfo";
import startStrongTx from "../../../actions/signature/startStrongTx";
import cleanSignatureFiles from "../../../actions/signature/cleanSignatureFiles";
import getValidOperations from "../../../actions/signature/getValidOperations";
import modalShow from "../../../actions/modal/modalShow";
import modalDismiss from "../../../actions/modal/modalDismiss";
import { REJECT_MODAL, MESSAGE_MODAL } from "../../Feedback/Modal/types";
import resetAuthSignParams from "../../../actions/signature/resetAuthSignParams";
import updateAuthSignParams from "../../../actions/signature/updateAuthSignParams";
import { pendingDocsInitialValues } from "../../../apis/generalValues";
import { fixSord, getQueryParams } from "../../Helpers/utils";
import DocsViewer from "../../NewManagementDocs/DocsViewer";
import updateUserSettings from '../../../actions/profile/updateUserSettings';

class PendingDocs extends React.Component {
    state = { limit: 10 }
    componentDidMount() {
        this.props.getUserInfo([], [], [(data) => {
            const userSettings = data.user;
            const sidxPending = userSettings.sidxPending ? userSettings.sidxPending : pendingDocsInitialValues.sidx;
            const sordPending = userSettings.sordPending ? userSettings.sordPending : fixSord(pendingDocsInitialValues.sord);
            const limitPending = userSettings.resultsPending ? userSettings.resultsPending : pendingDocsInitialValues.initialPageSize;
            this.setState({ limit: limitPending, skip: 0, sidx: sidxPending, sord: sordPending })
            this.props.cleanSignatureFiles();
        }]);
    }

    handleSearch = (page, pageSize, parentid, allResults, filterMetadataString) => {
        if (pageSize && pageSize !== this.state.limit && pageSize !== allResults) {
            this.props.updateUserSettings({ 'resultsPending': parseInt(pageSize) })
        }
        this.setState({ skip: page - 1, limit: pageSize }, () => {
            this.props.getPendingFilesInformation({...this.state, ...{filterMetadataString}}).then(() => {
                this.props.getValidOperations().then(this.checkSign);
            })
        });
    };

    handleSort = (pagination, filters, sorter, page, pageSize) => {
        const sordPending = fixSord(sorter.order)
        if (sorter.field === 'lastModified' && sordPending && this.props.user.sordPending !== sordPending) {
            this.props.updateUserSettings({ 'sordPending': sordPending })
        }
        this.setState({ sidx: sorter.field ? sorter.field : '', sord: sorter.field ? sorter.order : '' }, () => this.handleSearch(page, pageSize));
    };

    checkSign = () => {
        if (this.props.signedFileList.entries.length > 0) {
            if (this.props.auth && this.props.auth.params) {
                if (typeof (this.props.auth.params.signFirst) !== 'undefined' && this.props.auth.params.signFirst) {
                    this.props.resetAuthSignParams();
                    this.signPendingFiles([this.props.signedFileList.entries[0]]);
                    return;
                } else if (typeof (this.props.auth.params.signAll) !== 'undefined' && this.props.auth.params.signAll) {
                    this.props.resetAuthSignParams();
                    this.signPendingFiles(this.props.signedFileList.entries);
                    return;
                }
            }
            this.props.updateAuthSignParams(this.props.usePAD);
            if (this.props.signAllLogged) {
                this.signPendingFiles(this.props.signedFileList.entries);
            }
            else if (this.props.signFirstLogged) {
                this.signPendingFiles([this.props.signedFileList.entries[0]]);
            }
        }
    };

    signPendingFiles = (records) => {
        if (this.props.auth.txAuth) {
            this.props.startStrongTx('PENDING', records);
        } else {
            this.props.signPendingFiles(records, null);
        }
    };

    deleteFiles = (uid, comment = null, page, pageSize) => {
        this.props.deletePendingFiles(uid, comment, [], [() => this.setState({ uids: [] }, () => this.handleSearch(page, pageSize))]);
        this.props.modalDismiss();
    };

    rejectFiles = (uids, page, pageSize) => {
        this.props.modalShow({
            modalType: REJECT_MODAL,
            modalProps: { footer: null, title: general[this.props.language].reject },
            componentProps: {
                language: this.props.language, btn: general[this.props.language].reject, message: general[this.props.language].rejectComment,
                onSubmit: (formValues) => this.deleteFiles(uids, formValues.comment, page, pageSize)
            }
        });
    };

    showMessage = (name, message) => {
        this.props.modalShow({
            modalType: MESSAGE_MODAL,
            modalProps: { footer: null, title: general[this.props.language].comment },
            componentProps: { language: this.props.language, message: message, name: name }
        });
    };

    render() {
        return <DocsViewer docsType={'pending'} {...this.props} {...this.state} results={this.state.limit} handleSearch={this.handleSearch} signPendingFiles={this.signPendingFiles}
            rejectFiles={this.rejectFiles} showMessage={this.showMessage} handleSort={this.handleSort} />;
    }
}

const mapStateToProps = state => {
    let queryParams = getQueryParams(state.router.location.search);
    return {
        auth: state.auth,
        signAllLogged: queryParams.signAllLogged,
        signFirstLogged: queryParams.signFirstLogged,
        usePAD: queryParams.usePAD,
        signedFileList: state.pendingFileList,
        language: state.auth.language,
        user: state.user,
        dimApp: state.locate.dimensionsApp,
        metadata: state.reportList.entries
    };
};

export default PrivateRoute(connect(mapStateToProps, {
    resetAuthSignParams, modalDismiss, modalShow, getValidOperations, updateUserSettings,
    cleanSignatureFiles, startStrongTx, push, getUserInfo, getPendingFilesInformation, getPendingFile,
    deletePendingFiles, signPendingFiles, updateAuthSignParams
})(PendingDocs));
