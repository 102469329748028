import {authType} from '../../const/authType';
import {CHECK_BASIC_AUTHENTICATION} from "../types";
import netcoServer from "../../apis/netcoServer";
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import handleResponse from "../helpers/handleResponse";
import startStrongAuthentication from './startStrongAuthentication';
import loadingCall from "../helpers/loadingCall";
import auth from "../../const/auth";

let params="";
const checkBasicAuthentication= (formValues) => async (dispatch,getState) => {
  params=formValues;

  const onSuccessPayload = data => {
    let payload = {};
    switch (authType[data.authType]) {
      case 'BASIC_AUTHENTICATION':
        payload = {
          auth: {
            admin: data.admin,
            txAuth: data.txAuth,
            requiresCert: data.requiresCert,
            detail: data.detail,
            entrust: data.entrust,
            netco: data.netco,
            ldap: data.ldap,
            enableecom:data.enableecom,
            userName:data.userName,
            limitFiles:data.limitFiles
          },
          info: {
            message: 'Bienvenido',
            type: 'success'
          }
        };
        if(payload.auth.detail!=="")
          payload.info.message=payload.auth.detail;
        else
          payload.info.message=null;
        break;
      case 'FINGERPRINT_AUTHENTICATION':
        payload = infoPayload('info', 'Espera que cargue el Fingerprint e ingresa tu huella para continuar!');
        break;
      case 'SQRL_AUTHENTICATION':
        payload = infoPayload('info', auth[getState().auth.language].scansqrllbl);
        break;
      default:
        break;
    }
    if(!payload.auth)
      payload.auth={};
    payload.auth.params=params;
    return payload;
  };

  let response = await loadingCall(dispatch, () => netcoServer.newPost('/LoginService/checkBasicAuthentication', formValues));
  handleResponse(dispatch, response, CHECK_BASIC_AUTHENTICATION, onSuccessPayload, null, [startStrongAuthentication]);
  if(response && response.data && response.data.success)
    sessionStorage.setItem("userName", response.data.userName);
};
export default checkBasicAuthentication;
