import netcoServer from '../../apis/netcoServer';
import {INFO_SHOW, NEW_PASSWORD} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import _ from 'lodash';
import loadingCall from "../helpers/loadingCall";
import auth from "../../const/auth";

const newPassword= (formValues, search) => async (dispatch) => {
  
  const onSuccessPayload = () => {
  return infoPayload('success', auth[language].passchanged);
};
  
  let language=formValues.language;
  let queryParams = search ? _.reduce(search.substring(1).split("&"),
      (result, value) => {
        let params = value.split("=");
        result[params[0]] = params[1];
        return result;
      }, {}) : {};
  if(!formValues.password)	  
	  dispatch({type: INFO_SHOW, payload: infoPayload('error', auth[language].passrequired)});
  else if(formValues.password === formValues.password_confirmation) {
    if(queryParams.check && queryParams.dat && queryParams.us && queryParams.exp) {
      formValues.check = queryParams.check;
      formValues.dat =queryParams.dat;
      formValues.us = queryParams.us;
      formValues.exp = queryParams.exp;
	  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/newPassword', _.omit(formValues, 'password_confirmation','language')));
      handleResponse(dispatch, response, NEW_PASSWORD, onSuccessPayload, '/');
    } else {
      dispatch({type: INFO_SHOW, payload: infoPayload('error', auth[language].invalidparams)});
    }
  } else {
    dispatch({type: INFO_SHOW, payload: infoPayload('error', auth[language].passdontmatch)});
  }
};
export default newPassword;

