import {
  CREATE_SESSION
} from "../actions/types";

const INITIAL_STATE = {
  hasSession:null
};

const sessionReducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_SESSION:
      return {...state, ...action.payload};
    default:
      return state;
  }
};
export default sessionReducer;