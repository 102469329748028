import React from 'react';
import { Modal, Card } from "antd";
import {Document, Page} from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import {Tooltip,Button } from "antd";
import {ZoomInOutlined,ZoomOutOutlined} from '@ant-design/icons';
import general from "../../../const/general";
import PDFPaginator from "../../Helpers/PDFPaginator";


class ZoomModal extends React.Component {

    state={pageNumber:this.props.pageNumber, numPages:this.props.numPages?this.props.numPages:null};

   

    render() {
        const renderDocument = () => {
            
            let zoomPage = parseInt(this.props.zoom) === 0 || this.props.zoom === '' ? 0 : this.props.zoom;
            if (this.props.entries) {
                return (
                    <Document onPassword={(callback) => callback(this.props.pdfPass)} loading={"..."}
                              file={this.props.entries[this.props.currentFile].content}
                              className={'documentSignatureContainer'}
                              onLoadSuccess={({ numPages }) => {
                                if (!this.state.numPages) {
                                  this.setState({ numPages });
                                }
                              }}>
                        <Page loading={"..."} pageNumber={this.state.pageNumber} width={500} scale={zoomPage / 100}/>
                    </Document>
                );
            }
        };
        const newHeight = (window.innerHeight - 150) + 'px';
        return (
            (<Modal title={general[this.props.language].zoomModal + ': ' + this.state.pageNumber}
                   open={this.props.modalType !== null} style={{top: 10}} styles={{body:{padding: 0}}}
                   width={window.innerWidth} height={window.innerHeight} {...this.props.modalProps}>
                <div style={{marginBottom: '10px', textAlign: 'center'}}>
                    <Tooltip title={general[this.props.language].zoomOut}>
                        <Button style={{marginBottom: 0, marginLeft: 0, marginRight: '10px'}} icon={<ZoomOutOutlined/>}
                                type={'primary sign-btn'} htmlType={'button'} onClick={this.props.zoomOutPage}/>
                    </Tooltip>
                    <input
                        type="text"
                        value={this.props.zoom + '%'}
                        onChange={this.props.handleChangeZoom}
                        style={{width: '70px', marginRight: '10px', textAlign: 'center'}}/>
                    <Tooltip title={general[this.props.language].zoomIn}>
                        <Button style={{marginBottom: 0, marginLeft: 0, marginRight: '10px'}} icon={<ZoomInOutlined/>}
                                type={'primary sign-btn'} htmlType={'button'} onClick={this.props.zoomInPage}/>
                    </Tooltip>
                </div>
                <PDFPaginator language={this.props.language} pageNumber={this.state.pageNumber}
                               numPages={this.state.numPages}
                               onPageChange={(newPage)=>this.setState({pageNumber:newPage})}/>
                <Card bodyStyle={{padding: 0}} style={{overflow: 'scroll', margin: '0px', height: newHeight}}>
                    <div style={{textAlign: 'center'}} className={'pdfContainer'}>
                        {renderDocument()}
                    </div>
                </Card>
            </Modal>)
        );
    }
}

export default ZoomModal;