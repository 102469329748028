import {
  GET_FONTS,
  GET_IMAGE_TYPE_LIST,
  GET_TIMESTAMPS_INFO,
  GET_USER_INFO,
  LOG_OUT
} from "../actions/types";

const INITIAL_STATE = {
  userName: '',
  mail: '',
  telefono: '',
  idKey: '',
  reason: '',
  tsid: null,
  publish: false,
  certification: false,
  cedula: '',
  direccion: '',
  country: '',
  state: '',
  city: '',
  serialNumber: '',
  zipCode: '',
  hasCertificate: false,
  cert: '',
  useImage: 1,
  fingerPrint: '',
  hasFingerprint: false,
  includeUserList: false,
  imageTypes: [],
  timeStampServers: [],
  enableecom:false,
  image: '',
  txtExtensions: '',
  imageExtensions: '',
  lly: 0,
  llx: 0,
  urx: 0,
  ury: 0,
  wordpressUser:''
};

const user= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_INFO:
      return {...state, ...action.payload.user};
    case GET_IMAGE_TYPE_LIST:
      return {...state, ...action.payload.user};
    case GET_FONTS:
      return {...state, ...action.payload.user};
    case GET_TIMESTAMPS_INFO:
      return {...state, ...action.payload.user};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default user;