import React from "react";

class TagLabel extends React.Component
{
    
    render()
    {
        let type=this.props.type,color='#A900FF';
        if(!type)
            type='Primary';
        switch(type)
        {
            case 'Danger':
                color='#FF3D71';
                break;
            case 'Success':
                color='#00D68F';
                break;
            case 'Info':
                color='#0095FF';
                break;
            case 'Warning':
                color='#FFAA00';
                break;
            case 'Primary':
            default:
                break;

        }
        const styles = { background: color, display:'flex',textAlign:'center',justifyContent:'center', borderRadius: '25px', color: 'white',fontSize: '10px', height: '18px' }
        return <div style={this.props.inTable?{...styles, ...{display:'inline-block', paddingRight:10, paddingLeft:10, marginRight:10, height:'100% !important'}}:styles}>
            {this.props.text}
        </div>
    }
}
export default TagLabel;