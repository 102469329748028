import {
  GET_ALL_USERS,
  GET_LOGS,
  GET_PAGES_REPORT,
  GET_SIGN_REPORT,
  LOG_OUT,
  GET_CERTIFICATES_REPORT,
  GET_PENDING_REPORT,
  GET_ANDES_REPORT
} from "../actions/types";

const INITIAL_STATE = {
  entries: [],
  kind: 'logs',
  total: 0,
  pagesReport: 0,
  users: [],
  certificates: []
};

const userReportList= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOGS:
      return {...state, ...action.payload.userReportList};
    case GET_SIGN_REPORT:
      return {...state, ...action.payload.userReportList};
    case GET_PAGES_REPORT:
      return {...state, ...action.payload.userReportList};
    case GET_ALL_USERS:
      return {...state, ...action.payload.userReportList};
    case GET_CERTIFICATES_REPORT:
      return {...state, ...action.payload.userReportList};
    case GET_PENDING_REPORT:
      return {...state, ...action.payload.userReportList};
    case GET_ANDES_REPORT:
      return {...state, ...action.payload.userReportList};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default userReportList;