import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import {Form, Button, Card, Col, Row } from "antd";
import {CreateCheckbox, CreateTextField} from "../../Helpers/Input/InputCreator";
import {Field, reduxForm} from "redux-form";

// import 'antd/lib/card/style/css';


// import 'antd/lib/alert/style/css';


import {connect} from "react-redux";
import {required} from "../../Helpers/Input/validations";
import PrivateRoute from "../../Root/PrivateRoute";
import generateCSR from '../../../actions/certificates/generateCSR';
import getLDAPUserInfo from '../../../actions/certificates/getLDAPUserInfo';
import general from "../../../const/general";
import certificates from "../../../const/certificates";
import TitleAndInfo from "../../Helpers/TitleAndInfo";

class Directorio extends React.Component {

  componentDidMount() {
    this.props.getLDAPUserInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {    
	if(this.props.ldapUser !== prevProps.ldapUser) {
      this.props.initialize(this.props.ldapUser);
    }
  }

  onSubmit = (formValues) => {    
	formValues.language=this.props.language;
	formValues.requiresCert=this.props.requiresCert;
    this.props.generateCSR(formValues);
  };
  
  render() {
    return (
      <Card bordered={false}>
        <TitleAndInfo title={'Directorio'} info={certificates[this.props.language].directory_info}/>
        <Form onFinish={this.props.handleSubmit(this.onSubmit)} className={'p-20'}>
          <Row gutter={16}>
            <Col span={8}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].complete_name}</div>
              <Field
                     name="cn"
                     component={CreateTextField}
                     placeholder={certificates[this.props.language].complete_name}
                     disabled={true}
                     validate={[required]}
                     hasFeedback/>
            </Col>
            <Col span={8}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].email}</div>
              <Field
                     name="mail"
                     component={CreateTextField}
                     placeholder={certificates[this.props.language].email}
                     disabled={true}
                     validate={[required]}
                     hasFeedback/>
            </Col>
            <Col span={8}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].phone}</div>
              <Field
                     name="phone"
                     component={CreateTextField}
                     disabled={true}
                     placeholder={certificates[this.props.language].phone}
                     validate={[required]}
                     hasFeedback/>
            </Col>
            <Col span={8}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].identification}</div>
              <Field
                     name="cedula"
                     component={CreateTextField}
                     placeholder={certificates[this.props.language].identification}
                     disabled={true}
                     validate={[required]}
                     hasFeedback/>
            </Col>
            <Col span={8}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].country}</div>
              <Field
                     name="country"
                     component={CreateTextField}
                     placeholder={certificates[this.props.language].country}
                     disabled={true}
                     validate={[required]}
                     hasFeedback/>
            </Col>
            <Col span={8}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].department}</div>
              <Field
                     name="state"
                     component={CreateTextField}
                     placeholder={certificates[this.props.language].department}
                     disabled={true}
                     validate={[required]}
                     hasFeedback/>
            </Col>
            <Col span={8}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].city}</div>
              <Field
                     name="city"
                     component={CreateTextField}
                     placeholder={certificates[this.props.language].city}
                     disabled={true}
                     validate={[required]}
                     hasFeedback/>
            </Col>
            <Col span={8}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].address}</div>
              <Field
                     name="streetAddress"
                     component={CreateTextField}
                     placeholder={certificates[this.props.language].address}
                     disabled={true}
                     validate={[required]}
                     hasFeedback/>
            </Col>
            <Col span={8}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].company_name}</div>
              <Field
                     name="organization"
                     component={CreateTextField}
                     placeholder={certificates[this.props.language].company_name}
                     disabled={true}
                     validate={[required]}
                     hasFeedback/>
            </Col>
            <Col span={8}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].company_area}</div>
              <Field
                     name="organizationalUnit"
                     component={CreateTextField}
                     placeholder={certificates[this.props.language].company_area}
                     disabled={true}
                     validate={[required]}
                     hasFeedback/>
            </Col>
			<Col span={8}>
            <Field name="agree"
                   type={'checkbox'}
                   component={CreateCheckbox}
                   validate={required}
                   hasFeedback>
              <a rel='noopener noreferrer' target={'_blank'} href={window.netcourlid + '/comagre/AcuerdoDeComunicaciones.pdf'}>
                {general[this.props.language].agree}
              </a>
            </Field>
          </Col>
          </Row>
          <Row gutter={16}>
            <Button htmlType={'submit'} className={'float-right'} type={'primary light-primary rounded-sm'}>
              {general[this.props.language].create}
            </Button>
          </Row>
        </Form>
      </Card>
    );
  }
  
}

const mapStateToProps = state => {
  return {
    language: state.auth.language,
	requiresCert: state.auth.requiresCert,
    ldapUser: state.ldapUser,
    initialValues: state.ldapUser
  };
};

export default PrivateRoute(connect(mapStateToProps, {generateCSR, getLDAPUserInfo})((reduxForm({
  form: 'zipCertificate_form'
})(Directorio))));