import netcoServer from '../../apis/netcoServer';
import {UPDATE_ORDER} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const deleteOrder=(order,onOrderDeleted) => async (dispatch) => {
  const onSuccessPayload = data => {
    return data;
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/deleteOrder  ', order));
  handleResponse(dispatch, response, UPDATE_ORDER, onSuccessPayload, null, [],[onOrderDeleted],[onOrderDeleted]);
};
export default deleteOrder;
