import React from 'react';
import PublicLayout from "../../Layouts/Public";
import AuthSingleFieldForm from "../AuthSingleFieldForm";
import {push} from 'connected-react-router'
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import checkStrongAuthentication from '../../../actions/auth/checkStrongAuthentication'
import startStrongAuthentication from '../../../actions/auth/startStrongAuthentication'
import { LockOutlined } from '@ant-design/icons';
import auth from "../../../const/auth";
import infoShow from '../../../actions/info/infoShow';
import infoPayload from '../../../actions/helpers/payloadHandlers/infoPayload';
import {INFO_SHOW} from "../../../actions/types";

class OptCode extends React.Component {

  state = {
    otpTimer: null,
    showResend: false
  };

  componentDidMount() {
    let otpTimer = setInterval( () => {
      if(!this.state.showResend)
        this.setState({showResend: true});
    }, 30000 );
    this.setState({otpTimer: otpTimer})
  }

  componentWillUnmount() {
    window.clearInterval(this.state.otpTimer);
  }


  submitOtp = (formValues, dispatch) => {
	if(formValues.otp) {
      formValues.params=this.props.auth.params;
	  this.props.checkStrongAuthentication(formValues);
    }
	else
		dispatch({type: INFO_SHOW, payload: infoPayload('error', auth[this.props.language].reqotpmsg)});
  };

  startStrong = () => {
    this.setState({showResend: false});
    this.props.startStrongAuthentication(true);
  };

  required = (value) => {return value ? undefined : auth[this.props.language].requiredfld;}

  render() {
    return (
      <PublicLayout title={auth[this.props.language].enterotplbl}>
        <AuthSingleFieldForm onSubmit={this.submitOtp}
                             prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                             placeholder={auth[this.props.language].otplbl}
                             name={'otp'}
                             validations={this.required}
                             showResend={this.state.showResend}
                             startStrong={this.startStrong}
                             {...this.props}
        />
      </PublicLayout>
    );
  }
}

const mapStateToProps = state =>{
  return {
	language: state.auth.language,
    auth: state.auth
  };
};

export default connect(mapStateToProps, {push, startStrongAuthentication, checkStrongAuthentication,infoShow})(reduxForm({
  form: 'opt_form'
})(OptCode));
