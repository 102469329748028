import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Card, Col, Row } from "antd";
import {CreateCheckbox, CreateFileField, CreatePasswordField} from "../../Helpers/Input/InputCreator";
import {required} from "../../Helpers/Input/validations";
import importCertificateFromP12 from '../../../actions/certificates/importCertificateFromP12';
import {Field, reduxForm} from "redux-form";

// import 'antd/lib/card/style/css';


// import 'antd/lib/alert/style/css';


import {connect} from "react-redux";
import TitleAndInfo from "../../Helpers/TitleAndInfo";
import PrivateRoute from "../../Root/PrivateRoute";
import certificates from "../../../const/certificates";
import general from "../../../const/general";
import {LockOutlined} from "@ant-design/icons";


class P12 extends React.Component {

  state = {formFile: []};

  onSubmit = (formValues) => {
    let file = this.state.formFile[0] === undefined ? null : this.state.formFile[0].file;
	formValues.language=this.props.language;
	formValues.requiresCert=this.props.requiresCert;
    this.props.importCertificateFromP12(formValues, file);
  };

  handleOnDrop = (newImageFile, onChange) => {
    const formFile = {
      file: newImageFile[0],
      name: newImageFile[0].name,
      preview: URL.createObjectURL(newImageFile[0]),
      size: newImageFile[0].size
    };
    this.props.change('avatar', formFile);
    this.setState({formFile: [formFile]}, () => onChange(formFile));
  };

  render() {
    return (
      <Card bordered={false}>
        <TitleAndInfo title={certificates[this.props.language].pkcs12Title} info={certificates[this.props.language].p12_info2}/>
        <Form onFinish={this.props.handleSubmit(this.onSubmit)} className={'p-20'}>
          <Row gutter={16}>
            <Col span={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].p12_file}</div>
              <Field
                     accept={".p12, .pfx"}
                     name="avatar"
                     component={CreateFileField}
                     handleOnDrop={this.handleOnDrop}
                     type='file'
                     formFile={this.state.formFile}
                     validate={[required]}
                     multiple={false}
              />
            </Col>
            <Col span={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{general[this.props.language].password}</div>
              <Field
                     name="password"
                     prefix={<LockOutlined className={'icon-font'} />}
                     component={CreatePasswordField}
                     size={'large'}
                     className={'mb-10'}
                     placeholder={general[this.props.language].password}
                     validate={[required]}
                     hasFeedback/>
              <Field name="agree"
                     type={'checkbox'}
                     component={CreateCheckbox}
                     validate={required}
                     hasFeedback>
                <a rel='noopener noreferrer' target={'_blank'} href={window.netcourlid + '/comagre/AcuerdoDeComunicaciones.pdf'}>
                  {general[this.props.language].agree}
                </a>
              </Field>
              <Field name="updateGraphicImage"
                     type={'checkbox'}
                     component={CreateCheckbox}
                     hasFeedback>
                {certificates[this.props.language].graphic_image}
              </Field>
            </Col>
          </Row>
          <Row className={'flexRight'} gutter={16}>
            <Button htmlType={'submit'} className={'float-right'} type={'primary light-primary rounded-sm'}>
              {certificates[this.props.language].createP12}
            </Button>
          </Row>
        </Form>
      </Card>
    );
  }

}

const mapStateToProps = state => {
  return {
    language: state.auth.language,
	requiresCert: state.auth.requiresCert,
    initialValues: {
      agree: false,
      updateGraphicImage: false
    }
  }
};

export default PrivateRoute(connect(mapStateToProps, {importCertificateFromP12})((reduxForm({
  form: 'p12Certificate_form'
})(P12))));
