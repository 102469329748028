import netcoServer from '../../apis/netcoServer';
import {GET_ALL_USERS_INFO} from "../types";
import tableLoadingCall from '../helpers/tableLoadingCall';

const getAllUsersInfo= (searchString, page, pageSize, sidx, sord) => async (dispatch,getState) => {
  if(!pageSize)
  {	  
	  pageSize=getState().reportList.entries.length;
	  sidx="userName";
	  searchString="";
	  page=1;
	  sord="ascend";
  }
  let formData = {
    searchString: searchString,
    limit: pageSize,
    skip: (page - 1) * pageSize,
    sidx: sidx === 'userStatus' ? 'locked' : sidx,
    sord: sord ? renderSord(sord) : '',
  };  
  await tableLoadingCall(dispatch, () => netcoServer.newPost('/UserService/getAllUsersInfo', formData), GET_ALL_USERS_INFO, onSuccessPayload);
};

const renderSord = sord => {
  return sord === 'ascend' ? 'asc' : 'desc';
};

const onSuccessPayload = data => {
  return {
    reportList: {
      kind: 'users',
      total: data.result.total,
      entries: data.result.usersInfo
    }
  };
};
export default getAllUsersInfo;