import netcoServer from '../../apis/netcoServer';
import { CREATE_SESSION, IS_LOGGED_IN, GET_CUSTOMIZATION } from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";
import logoutUser from "./logoutUser";

const isLoggedIn = (postCallBackData=[]) => async (dispatch) => {
    const onSuccessPayload = data => {
        return {
            auth: {
                admin: data.admin,
                txAuth: data.txAuth,
                requiresCert: data.requiresCert,
                detail: data.detail,
                entrust: data.entrust,
                netco: data.netco,
                ldap: data.ldap,
                enableecom: data.enableecom,
                userName: data.userName,
                passwordPolicies: data.passwordPolicies,
                limitFiles:data.limitFiles,
                loggedIn:data.loggedIn,
                maxFileSize:data.maxFileSize
            }
        };
    };

    let response = await loadingCall(dispatch, () => netcoServer.newPost('/LoginService/isLoggedIn', {}));
    handleResponse(dispatch, response, IS_LOGGED_IN, onSuccessPayload, null,[],[],[],postCallBackData);
    if (!response || !response.data || !response.data.success)
        dispatch(logoutUser(null, false, true));
    else if (response && response.data && response.data.customization){
        const customization = response.data.customization.replace(/"/g, "").replace(/\\/g, "\"")
        const data = {
            adminConfiguration: {
                customization: customization && customization !== '{}'?JSON.parse(customization):''
            }
        }
        dispatch({ type: GET_CUSTOMIZATION, payload: data });
    }
    const hasSession = (sessionStorage.getItem("logged") === 'true');
    dispatch({ type: CREATE_SESSION, payload: { hasSession } });
};
export default isLoggedIn;
