import React from 'react';
import { Button, Col, Input, Row, Select, Table, ConfigProvider, Dropdown, Menu, Typography, Checkbox, Card, Collapse } from "antd";
import DraggableBodyRow from "./DraggableRow";
import moment from "moment";
import signed_files from '../../const/signed_files';
import general from "../../const/general";
import enUS from 'antd/es/locale/en_US';
import esCO from 'antd/es/locale/es_ES';
import { addMetadata, parseSord, renderItem } from "../Helpers/utils";
import { privateDocsInitialValues } from "../../apis/generalValues";
import Paginator from "../Paginator";
import actionsIcon from '../../resources/img/NewIcons/management/actions.svg'
import { ReactComponent as FileInfoIcon } from '../../resources/img/NewIcons/management/fileInfo.svg';
import { ReactComponent as InfoIcon } from '../../resources/img/NewIcons/management/info.svg';
import { ReactComponent as GridIcon } from '../../resources/img/NewIcons/management/grid.svg';
import { ReactComponent as ListIcon } from '../../resources/img/NewIcons/management/list.svg';
import { ReactComponent as DocumentIcon } from '../../resources/img/NewIcons/management/document.svg';
import { ReactComponent as FolderIcon } from '../../resources/img/NewIcons/management/folder.svg';
import { xlGrid, sGrid, renderInfoTag } from '../Helpers/utils';
import PrivateRoute from "../Root/PrivateRoute";
import { connect } from "react-redux";
import getValidOperations from "../../actions/signature/getValidOperations";
import cleanSignatureFiles from "../../actions/signature/cleanSignatureFiles";
import delNotifications from '../../actions/files/delNotifications';
import getUserInfo from "../../actions/profile/getUserInfo";
import {
  FOLDER_MODAL,
  LOADING_MODAL,
  MAIL_MODAL,
  REJECT_MODAL,
  SEND_MODAL,
  TEMPLATE_VIEWER_MODAL,
  UPLOAD_TEMPLATES_MODAL
} from "../Feedback/Modal/types";
import { emaillang } from "../Helpers/Input/validations";
import { INFO_SHOW } from "../../actions/types";
import admin from "../../const/admin";
import sendSignedFilesUIDMail from "../../actions/files/sendSignedFilesUIDMail";
import startStrongTx from "../../actions/signature/startStrongTx";
import resignFiles from "../../actions/files/reSignFiles";
import getSignedFiles from "../../actions/files/getSignedFiles";
import verifyFilesWithUID from "../../actions/files/verifyFilesWithUID";
import updateDefaultFolder from "../../actions/files/updateDefaultFolder";
import modalShow from "../../actions/modal/modalShow";
import modalDismiss from "../../actions/modal/modalDismiss";
import auth from "../../const/auth";
import delSignedFiles from "../../actions/files/delSignedFiles";
import updateUserSettings from "../../actions/profile/updateUserSettings";
import templates from "../../const/templates";
import { ReactComponent as EmptyTemplatesIcon } from '../../resources/img/NewIcons/templates/emptyTemplatesIcon.svg'
import smallPadLock from '../../resources/img/NewIcons/templates/smallPadLock.svg'
import smallDocument from '../../resources/img/NewIcons/smallDocument.svg'
import pencilIcon from "../../resources/img/NewIcons/home/pencilIcon.svg";
import getPDFTemplate from "../../actions/templates/getPDFTemplate";
import handleBase64ZipResponse from "../../actions/helpers/handleBase64ZipResponse";
import deletePDFTemplate from "../../actions/templates/deletePDFTemplate";
import uploadPDFTemplate from "../../actions/templates/uploadPDFTemplate";
import DropdownList from "../Helpers/DropdownList"
import createFolder from "../../actions/files/createFolder"
import moveFiles from "../../actions/files/moveFiles"
import { push } from "connected-react-router";
import { SEND_AND_SIGN_ROUTE } from "../Root/routes";
import _ from "lodash";
import JSZip from 'jszip';
import collapseUpIcon from '../../resources/img/NewIcons/sign/collapseUpIcon.svg';
import collapseDownIcon from '../../resources/img/NewIcons/sign/collapseDownIcon.svg';
import personIcon from '../../resources/img/NewIcons/management/person.svg';
import editIcon from '../../resources/img/NewIcons/management/edit.svg';
import inboxIcon from '../../resources/img/NewIcons/management/inbox.svg';
import { Drawer } from 'antd';
import { showFull } from '../Helpers/utils';
import getAllMetadata from "../../actions/admin/getAllMetadata";
import TagLabel from '../Helpers/TagLabel';
import { getDictionaryMetadata } from '../Helpers/utils';

class DocsViewer extends React.Component {
  state = {
    newuids: [],
    ids: [],
    uids: [],
    pageSize: privateDocsInitialValues.initialPageSize,
    page: 1,
    sidx: privateDocsInitialValues.sidx,
    sord: privateDocsInitialValues.sord,
    cutFiles: [],
    parentId: null,
    publicFilesChecked: 0,
    selectAll: false,
    showDetails: {},
    dictionaryMetadata: { metadata: {}, options: {}, optionsText: [] },
    filterMetadataString: [],
    textValues: {}
  }

  componentDidMount() {
    this.props.getAllMetadata();
    this.props.cleanSignatureFiles();
    this.props.getUserInfo().then(() => this.props.getValidOperations().then(this.handleSearch));

  }

  componentDidUpdate(prevProps) {
    if (this.props.results && prevProps.results !== this.props.results) {
      this.setState({ pageSize: this.props.results })
    }
    if (this.props.metadata !== prevProps.metadata) {
      this.setState({ dictionaryMetadata: getDictionaryMetadata(this.props.metadata, this.props.language) })
    }
  }

  changeCategory = (category) => {
    this.props.changeCategory && this.props.changeCategory(category, this.state.page, this.state.pageSize, this.state.parentId, JSON.stringify(this.state.filterMetadataString));
  };

  changeType = (type) => {
    this.props.changeType && this.props.changeType(type, this.state.page, this.state.pageSize, this.state.parentId, JSON.stringify(this.state.filterMetadataString));
  };

  handleStatusChange = (value) => {
    this.selectAllGrid(false)
    this.setState({ page: 1 }, () => this.props.handleStatusChange(value, this.state.page - 1, this.state.pageSize));
  }

  handleSearch = () => {
    this.props.handleSearch(this.state.page, this.state.pageSize, this.state.parentId, this.props.signedFileList.total, JSON.stringify(this.state.filterMetadataString));
  };

  handleFilter = () => {
    this.setState({ page: 1 }, () => this.props.handleFilter(this.state.page, this.state.pageSize, this.state.parentId));
  };

  handleClean = () => {
    this.changeCategory(1);
    this.changeType(1)
    this.setState({ page: 1, filterMetadataString: [], textValues: {} }, () => {
      this.props.handleClean && this.props.handleClean(this.state.page, this.state.pageSize, this.state.parentId);
      this.handleSearch();
    });
  };

  handlePageChange = (page) => {
    this.setState({ page: page }, this.handleSearch);
  };

  handlePageSizeChange = (value) => {
    this.setState({ pageSize: parseInt(value), page: 1 }, this.handleSearch);
  };

  openFolder = (parentId) => {
    this.setState({ uids: [], newuids: [], ids: [], parentId: parentId, page: 1, selectAll: false }, this.handleSearch);
  };

  createFolder = (formValues) => {
    if (this.showTemplates()) {
      formValues.template = true;
      formValues.published = true;
    }
    this.props.createFolder(formValues, this.resetSearch);
  };

  resetSearch = () => {
    this.props.modalDismiss();
    this.handleSearch();
  };

  handleDelete(record) {
    if (record) {
      this.props.deletePDFTemplate(record.value, !record.public, this.state.parentId, record.id, this.handleSearch);
    } else if (this.state.newuids.length !== 0) {
      this.state.newuids.map(item => this.props.deletePDFTemplate(item.name, item.public, this.state.parentId, item.id, this.handleSearch))
    }
  }

  handleDownload(record) {
    this.props.getPDFTemplate(record.id).then(() => {
      handleBase64ZipResponse(this.props.signedFileList.selectedTemplate, record.value);
    });
  }

  handleShow(record) {
    this.props.getPDFTemplate(record.id);
    this.props.modalShow({
      modalType: TEMPLATE_VIEWER_MODAL,
      modalProps: { footer: null, title: record.value },
      componentProps: { modalShow: this.props.modalShow, modalDismiss: this.props.modalDismiss }
    });
  }

  showFolderModal = () => {
    this.props.modalShow({
      modalType: FOLDER_MODAL,
      modalProps: { footer: null, title: general[this.props.language].newFolderTitle },
      componentProps: { language: this.props.language, createFolder: this.createFolder, parentId: this.state.parentId }
    });
  };

  upOneLevel = () => {
    this.setState({ uids: [], newuids: [], ids: [], parentId: this.props.signedFileList.parentId, page: 1, selectAll: false }, this.handleSearch);
  };

  moveRow = (dragIndex, hoverIndex) => {
    let id = this.props.signedFileList.entries[dragIndex].id;
    let parentId = this.props.signedFileList.entries[hoverIndex].id;
    if (id !== parentId)
      this.props.moveFiles({ ids: [id], parentId: parentId !== 'up' ? parentId : null, template: this.showTemplates() }, this.handleSearch);
  };

  cut = () => {
    let selectedFiles = this.state.ids;
    this.setState({ cutFiles: selectedFiles, ids: [] });
  };

  paste = () => {
    this.props.moveFiles({ ids: this.state.cutFiles, parentId: this.state.parentId, template: this.showTemplates() }, () => this.setState({ cutFiles: [] }, this.handleSearch));
  };

  getInfoNewUids = (data) => {
    return {
      id: data.id, uid: data.uid, name: data.name ? data.name : data.fileName, lastModified: data.lastModified, fileSize: data.fileSize ? data.fileSize : data.size,
      public: data.public, isFolder: data.folder || data.id === 'up', creationDate: data.creationDate, notification: data.notification, state: data.state, optMessage: data.optMessage,
      ordered: data.ordered, remindevery: data.remindevery, sendAll: data.sendAll, signersCount: data.signersCount, workflow: data.workflow, finishDate: data.finishDate
    }
  }

  rowSelection = () => {
    return ({
      onChange: (selectedRowKeys, selectedRow) => {
        let newuids = [], uids = [], publicFilesChecked = 0;
        selectedRow.forEach((row) => {
          if (row.public)
            publicFilesChecked++;
          newuids.push(this.getInfoNewUids(row));
          if (row.uid && row.uid !== '')
            uids.push(row.uid);
        });

        if (newuids.length === 1 && this.getDocsType() === 'workflow' && showFull()) {
          this.props.getWorkflowDetailsInformation({ uid: newuids[0].uid }, [(data) => {
            newuids[0].infoWorkFlow = data.workflowList;
            this.setState({ newuids: newuids, uids: uids, ids: selectedRowKeys, publicFilesChecked })
          }], [], null, true)
        } else {
          this.setState({ newuids: newuids, uids: uids, ids: selectedRowKeys, publicFilesChecked })
        }

      },
      selectedRowKeys: this.state.ids
    });
  };

  addUpButton = () => {
    let parentId = this.props.signedFileList.parentId;
    let entries = this.props.signedFileList.entries;
    if (entries) {
      let upLevel = { id: parentId ? parentId : 'up', uid: '', name: '...' };
      if (entries.length > 0) {
        if (this.state.parentId && entries[0].name !== '...')
          entries.unshift(upLevel);
      }
      else if (this.state.parentId)
        entries.push(upLevel);
    }
  };

  deleteFiles = (comment = null) => {
    const uidsPrivate = this.state.newuids.filter(item => item.uid && !item.public).map(filterItem => filterItem.uid)
    const filterNewUids = this.state.newuids.filter(item => item.id !== 'up')
    if (uidsPrivate.length !== this.state.uids.length)
      this.props.dispatch({ type: INFO_SHOW, payload: { info: { message: signed_files[this.props.language].noDeletePublic, type: 'warning' } } });
    this.props.delSignedFiles(uidsPrivate, filterNewUids, false, this.handleSearch, this.props.auth.userName, comment);
    this.setState({ uids: [], newuids: [], ids: [], cutFiles: [] });
    this.props.modalDismiss();
  };

  deleteOneFile = (comment = null, uid = null, id = null) => {
    const newUids = id && id[0] ? [{ id: id[0] }] : null
    this.props.delSignedFiles(uid, newUids, false, this.handleSearch, this.props.auth.userName, comment);
    this.setState({ uids: [], newuids: [], ids: [], cutFiles: [] });
    this.props.modalDismiss();
  }

  rejectFiles = (uid, id) => {
    this.props.modalShow({
      modalType: REJECT_MODAL,
      modalProps: { footer: null, title: general[this.props.language].delete },
      componentProps: {
        language: this.props.language, btn: general[this.props.language].delete, message: auth[this.props.language].delReason,
        onSubmit: (formValues) => {
          if (uid && uid[0] !== '')
            this.deleteOneFile(formValues.comment, uid, [])
          else if (id && id[0] !== '')
            this.deleteOneFile(formValues.comment, [], id)
          else
            this.deleteFiles(formValues.comment)
        }
      }
    });
  };

  sendTemplates = () => {
    const idsTemplates = this.state.newuids.filter(newuid => !newuid.isFolder);
    if (idsTemplates.length === 0)
      this.props.dispatch({ type: INFO_SHOW, payload: { info: { message: signed_files[this.props.language].selectTemplate, type: 'error' } } });
    else
      this.props.selectedTemplates(idsTemplates)
  }

  handleCheck = (check, info) => {
    let updatedListids = [...this.state.ids]
    let updatedListNewuids = [...this.state.newuids]
    let updatedListuids = [...this.state.uids]
    const newUID = this.getInfoNewUids(info)
    let publicFilesChecked = this.state.publicFilesChecked;
    if (check) {
      updatedListids = [...updatedListids, info.id];
      updatedListNewuids = [...updatedListNewuids, newUID];
      if (newUID.uid)
        updatedListuids = [...updatedListuids, newUID.uid];
      if (newUID.public)
        publicFilesChecked++;
    } else {
      updatedListids = updatedListids.filter((item) => item !== newUID.id)
      if (this.getDocsType() === 'workflow')
        updatedListNewuids = updatedListNewuids.filter((item) => item.uid !== newUID.uid)
      else
        updatedListNewuids = updatedListNewuids.filter((item) => item.id !== newUID.id)
      updatedListids = updatedListids.filter((item) => item !== newUID.uid)
      updatedListuids = updatedListuids.filter((item) => item !== newUID.uid)
      if (newUID.public)
        publicFilesChecked--;
    }

    if (updatedListNewuids.length === 1 && this.getDocsType() === 'workflow') {
      this.props.getWorkflowDetailsInformation({ uid: updatedListNewuids[0].uid }, [(data) => {
        updatedListNewuids[0].infoWorkFlow = data.workflowList;
        this.setState({ ids: updatedListids, newuids: updatedListNewuids, uids: updatedListuids, publicFilesChecked, selectAll: updatedListNewuids.length === this.props.signedFileList.entries.length })
      }], [], null, true)
    } else {
      this.setState({ ids: updatedListids, newuids: updatedListNewuids, uids: updatedListuids, publicFilesChecked, selectAll: updatedListNewuids.length === this.props.signedFileList.entries.length })
    }

  };

  handleAction = (action, uid, id) => {
    const uidList = uid && uid.length !== 0 ? uid : this.state.uids;
    const uids = this.state.uids;
    switch (action) {
      case 'signp':
        this.props.signPendingFiles(this.props.signedFileList.entries.filter((value) => { return uids.includes(value.uid) }));
        break;
      case 'reject':
        this.props.rejectFiles(_.join(uids, ','), this.state.page, this.state.pageSize);
        break;
      case 'sign':
        if (this.props.auth.txAuth) {
          this.props.startStrongTx('UIDS', uidList);
        } else {
          const files = [{}];
          addMetadata(files, () => {
            this.props.resignFiles(uidList, () => {
              this.handleSearch();
              this.props.cleanSignatureFiles();
            }, files[0].metadata);
          }, this.props.metadata, this.props.modalShow, this.props.language, this.props.modalDismiss, this.props.dispatch);
        }
        break;
      case 'delete':
        if (!this.showTemplates())
          this.rejectFiles(uid, id);
        else
          this.handleDelete()
        break;
      case 'mail':
        this.props.modalShow({
          modalType: MAIL_MODAL,
          modalProps: { footer: null, title: signed_files[this.props.language].send_modal },
          componentProps: {
            language: this.props.language, uids: uidList, sendSignedFilesUIDMail: (formValues) => {
              let mails = formValues.mail.split(',');
              let invalidMails = 0;
              let msg = signed_files[this.props.language].invalidMail;
              mails.forEach(value => emaillang(msg)(value) ? invalidMails++ : '');
              if (invalidMails > 0)
                this.props.dispatch({ type: INFO_SHOW, payload: { info: { message: msg, type: 'error' } } });
              else {
                this.props.sendSignedFilesUIDMail(formValues, uidList);
              }
            }
          }
        });
        break;
      case 'save':
        this.props.getSignedFiles(uidList);
        break;
      case 'verify':
        this.props.verifyFilesWithUID(uidList);
        break;
      case 'send':
        this.props.modalShow({
          modalType: SEND_MODAL,
          modalProps: { footer: null, title: signed_files[this.props.language].load_modal },
          componentProps: { uids: uidList, sendSignedFilesUIDMail: this.props.sendSignedFilesUIDMail }
        });
        break;
      case 'default':
        const sendId = id ? id : this.state.ids[0];
        this.props.updateDefaultFolder({ published: false, parentId: sendId === 'up' ? '' : sendId }, () => this.props.dispatch({ type: 'INFO_SHOW', payload: { info: { message: admin[this.props.language].processok, type: 'success' } } }));
        this.setState({ uids: [], newuids: [], ids: [], cutFiles: [] });
        break;
      default: return null;
    }
  };

  handleItemClick = (evt, info) => {
    switch (evt.key) {
      case "signp":
        this.props.signPendingFiles([info]);
        break;
      case "sign":
      case "mail":
      case "verify":
        this.handleAction(evt.key, [info.uid]);
        break;
      case "deleteSigned":
        this.handleAction('delete', [info.uid], [info.id]);
        break
      case "save":
        break;
      case "delete":
        this.props.cancelPendingWorkflow(info.uid);
        break;
      case "default":
        this.handleAction('default', null, info.id);
        break;
      case "use":
        this.props.dispatch(push(SEND_AND_SIGN_ROUTE + '?templateID=' + info.id + "&templateName=" + info.name));
        break;
      case "view":
        this.handleShow(info);
        break;
      case "download":
        this.handleDownload(info);
        break;
      case "deletet":
        this.handleDelete(info);
        break;
      case "downloadp":
        this.props.getPendingFile(info.uid);
        break;
      case "reject":
        this.props.rejectFiles(info.uid, this.state.page, this.state.pageSize);
        break;
      case "comment":
        this.props.showMessage(info.name, info.optMessage);
        break;
      case "details":
        if (this.getDocsType() === 'workflow') {
          this.props.getWorkflowDetailsInformation({ uid: info.uid }, [(data) => {
            const newInfo = { ...this.getInfoNewUids(info), ...{ infoWorkFlow: data.workflowList } }
            this.setState({ showDetails: newInfo })
          }], [], null, true)
        } else {
          this.setState({ showDetails: this.getInfoNewUids(info) })
        }
        break;
      case "deleteNotification":
        const ids = {ids:info.uid}
        this.props.delNotifications(ids,[this.handleSearch])
        break;
      default:
        break;
    }
  };

  renderDropdownActions = (info, classIcon = '') => {
    const clase = 'unstyled-button ' + classIcon
    let items = [];
    const docsType = this.getDocsType();
    switch (docsType) {
      case "pending":
        items.push({ label: signed_files[this.props.language].download_action, key: 'downloadp' });
        items.push({ label: general[this.props.language].reject, key: 'reject' });
        break;
      case "signed":
        if (info.uid) {
          items.push({ key: 'sign', label: signed_files[this.props.language].sign_action });
          items.push({ key: 'mail', label: signed_files[this.props.language].email_action });
          items.push({ key: 'save', label: <a rel='noopener noreferrer' target={'_blank'} href={info.link}>{signed_files[this.props.language].download_action}</a> });
          items.push({ key: 'verify', label: signed_files[this.props.language].verify_action });
          items.push({ key: 'details', label: signed_files[this.props.language].wdetail });
          !info.public && items.push({ key: 'deleteSigned', label: signed_files[this.props.language].delete });
        }
        else {
          items.push({ key: 'default', label: signed_files[this.props.language].defaultAction });
          items.push({ key: 'deleteSigned', label: signed_files[this.props.language].delete });
        }
        break;
      case "workflow":
        items.push({ key: 'details', label: signed_files[this.props.language].wdetail });
        items.push({ key: 'delete', label: signed_files[this.props.language].delete });
        info.taskExists && items.push({ key: 'deleteNotification', label: signed_files[this.props.language].deleteNotification });
        break;
      case "templates":
        items = this.getTemplateItems(info);
        break;
      default:
        break;
    }
    return <Dropdown trigger={['click']}
      dropdownRender={()=>(
        <Menu items={items} onClick={(evt) => this.handleItemClick(evt, info)} />
      )}>
      <button className={clase} onClick={e => e.preventDefault()}>
        <img alt={''} src={actionsIcon} />
      </button>
    </Dropdown>
  }

  getDocsType = () => {
    const docsType = this.props.docsType;
    if (!docsType)
      return "signed";
    return docsType;
  }

  columns() {
    switch (this.getDocsType()) {
      case "pending":
        return [{
          title: signed_files[this.props.language].document_column,
          dataIndex: 'name',
          className: 'mobile-show',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.props.sidx === 'name' ? parseSord(this.props.sord) : false,
          render: (text, record) => {
            return <a href="#/files/newpending" onClick={() => this.props.getPendingFile(record.uid)} className='color-black'>{record.name}</a>;
          },
        },
        {
          title: signed_files[this.props.language].creator,
          dataIndex: 'creator',
          className: 'mobile-hide',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.props.sidx === 'creator' ? parseSord(this.props.sord) : false,
        },
        {
          title: signed_files[this.props.language].creationDate,
          dataIndex: 'lastModified',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.props.sidx === 'lastModified' ? parseSord(this.props.sord) : false,
          className: 'mobile-hide',
          render: (text, record) => {
            return moment(record.lastModified).format('DD/MM/YYYY - HH:mm:ss');
          }
        },
        {
          title: signed_files[this.props.language].size,
          dataIndex: 'size',
          sortDirections: ['ascend', 'descend'],
          sorter: true,
          sortOrder: this.props.sidx === 'size' ? parseSord(this.props.sord) : false,
          className: 'mobile-hide',
          render: (text) => {
            return parseInt(parseInt(text) / 1000) + " KB";
          }
        }, {
          title: signed_files[this.props.language].metadata,
          className: 'mobile-show',
          render: (text, record) => {
            return (
              record.metadata && record.metadata.map((metadata, index) => (
                <TagLabel key={index} type={'Info'} text={this.state.dictionaryMetadata.metadata[metadata.idmetadata] + ' : ' + metadata.value} inTable={true} />
              ))
            );
          },

        }, {
          title: general[this.props.language].actions,
          key: 'action',
          render: (text, record) => {
            const items = [{ label: signed_files[this.props.language].download_action, key: 'downloadp' }, { label: general[this.props.language].reject, key: 'reject' }];
            if (record.optMessage)
              items.push({ label: general[this.props.language].comment, key: 'comment' });
            return <>
              <Button style={{ minWidth: '110px', marginRight: '2px' }}
                className='center-vertical text-title size12' type="primary" htmlType="submit" onClick={() => this.props.signPendingFiles([record])}>
                  <div style={{ display: 'flex' }}>
                    <img style={{ paddingRight: '8px'}} alt={''} src={pencilIcon} />
                    {signed_files[this.props.language].sign.toUpperCase()}
                  </div>                
              </Button>
              <Dropdown trigger={['click']} 
                dropdownRender={()=>(
                  <Menu onClick={(evt) => this.handleItemClick(evt, record)} items={items} />
                )}>
                <button style={{ paddingBottom: 10 }} className={'unstyled-button '} onClick={e => e.preventDefault()}>
                  <img alt={''} src={actionsIcon} />
                </button>
              </Dropdown>
            </>;
          },
          className: 'mobile-show'
        }];
      case "workflow":
        let columns = [];
        columns.push({
          title: signed_files[this.props.language].document_column, dataIndex: 'fileName', className: 'mobile-show',
          render: (text, record) => {
            return <a href="#/files/workflow" onClick={() => this.props.getWorkflowDetailsInformation({ uid: record.uid, returnBase64File: true }, [this.props.openFile])} className='color-black'>{record.fileName}</a>;
          },
          sortDirections: ['ascend', 'descend'], sorter: true, sortOrder: this.props.sidx === 'fileName' ? parseSord(this.props.sord) : false,
        });
        columns.push({
          title: signed_files[this.props.language].creationDate, dataIndex: 'creationDate', className: 'mobile-show',
          sortDirections: ['ascend', 'descend'], sorter: true, sortOrder: this.props.sidx === 'creationDate' ? parseSord(this.props.sord) : false
        });
        if (this.props.pending)
          columns.push({ title: signed_files[this.props.language].signersCount, dataIndex: 'signersCount', className: 'mobile-hide' });
        else {
          columns.push({ title: signed_files[this.props.language].finishDate, dataIndex: 'finishDate', className: 'mobile-hide' });
          columns.push({
            title: signed_files[this.props.language].status, dataIndex: 'state', className: 'mobile-hide',
            render: (text, record) => {
              return record.state ? signed_files[this.props.language].signed : signed_files[this.props.language].canceled;
            }
          });
        }
        columns.push({
          title: signed_files[this.props.language].metadata,
          className: 'mobile-show',
          render: (text, record) => {
            return (
              record.documentMetadata && record.documentMetadata.map((metadata, index) => (
                <TagLabel key={index} type={'Info'} text={this.state.dictionaryMetadata.metadata[metadata.idmetadata] + ' : ' + metadata.value} inTable={true} />
              ))
            );
          },
        })
        columns.push({
          title: general[this.props.language].actions,
          key: 'action',
          render: (text, record) => this.renderDropdownActions(record),
          className: 'mobile-show'
        });
        return columns;
      case "templates":
        return (
          [{
            title: signed_files[this.props.language].document_column,
            dataIndex: 'name',
            className: 'mobile-show',
            sorter: false,
            render: (text, record) => {
              return renderItem(text, record, this.upOneLevel, this.openFolder, true);
            }
          }, {
            title: templates[this.props.language].id_column,
            dataIndex: 'id',
            className: 'mobile-show',
            render: (text, record) => record.folder ? '' : text
          }, {
            dataIndex: 'public',
            key: 'public',
            className: 'mobile-show',
            render: (text, record) => {
              return record.folder ? '' : renderInfoTag(record.public ? signed_files[this.props.language].public.toUpperCase() : signed_files[this.props.language].private.toUpperCase(), 'right')
            }
          }, {
            title: '',
            key: 'action',
            render: (text, record) => this.renderTemplateActions(record),
            className: 'mobile-show'
          }]
        );
      case "signed":
      default:
        return (
          [{
            title: signed_files[this.props.language].document_column,
            dataIndex: 'name',
            className: 'mobile-show',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            sortOrder: this.props.sidx === 'name' ? parseSord(this.props.sord) : false,
            render: (text, record) => {
              return renderItem(text, record, this.upOneLevel, this.openFolder, true);
            }
          }, {
            title: signed_files[this.props.language].extension_column,
            key: 'extension',
            className: 'mobile-hide',
            render: (text, record) => {
              return record.uid === '' ? '' : record.name ? record.name.substr(record.name.lastIndexOf('.') + 1).toUpperCase() : '';
            }
          }, {
            title: signed_files[this.props.language].modification_column,
            dataIndex: 'lastModified',
            className: 'mobile-hide',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            sortOrder: this.props.sidx === 'lastModified' ? parseSord(this.props.sord) : false,
            defaultSortOrder: 'descend',
            render: (text, record) => {
              return moment(record.lastModified).format('DD/MM/YYYY - HH:mm:ss');
            }
          }, {
            dataIndex: 'public',
            key: 'public',
            className: 'mobile-show',
            render: (text, record) => {
              if (record.uid)
                return renderInfoTag(record.public ? signed_files[this.props.language].public.toUpperCase() : signed_files[this.props.language].private.toUpperCase(), 'right')
            }
          }, {
            title: signed_files[this.props.language].metadata,
            className: 'mobile-show',
            render: (text, record) => {
              return (
                record.documentMetadata && record.documentMetadata.map((metadata, index) => (
                  <TagLabel key={index} type={'Info'} text={this.state.dictionaryMetadata.metadata[metadata.idmetadata] + ' : ' + metadata.value} inTable={true} />
                ))
              );
            },
          }, {
            title: '',
            key: 'action',
            render: (text, record) => this.renderDropdownActions(record),
            className: 'mobile-show'
          }]
        );
    }
  }

  renderTemplateActions = (record) => {
    return record.name !== '...' ? <Dropdown trigger={['click']} dropdownRender={()=>(<Menu items={this.getTemplateItems(record)} onClick={(evt) => this.handleItemClick(evt, record)} />)}>
      <button className="unstyled-button"
        onClick={e => e.preventDefault()}>
        <img alt={''} src={actionsIcon} />
      </button>
    </Dropdown> : '';
  }

  getTemplateItems = (record) => {
    const items = [];
    if (!record.folder && record.name !== '...') {
      this.props.pathname!=="/sendsign" && items.push({ key: 'use', label: templates[this.props.language].use });
      items.push({ key: 'view', label: templates[this.props.language].view });
      items.push({ key: 'download', label: templates[this.props.language].download });
    }
    if ((this.props.auth.admin || !record.public) && record.name !== '...')
      items.push({ key: 'deletet', label: templates[this.props.language].delete });
    return items;
  }

  renderClean() {
    return (
      <Col span={4} style={{ marginTop: 'auto' }}>
        <Button type="primary light-primary" className={'float-right'} htmlType={'button'} onClick={this.handleClean}>
          {general[this.props.language].clean}
        </Button>
      </Col>
    );
  }


  downloadworkFlowZIP = (data) => {
    const zip = new JSZip();
    data.forEach(file => zip.file(file.name, file.base64File, { base64: true }))
    zip.generateAsync({ type: "base64" }).then(function (content) {
      handleBase64ZipResponse(content, 'workflows.zip')
    });
  }

  handleActionWorkFlow(action) {
    switch (action) {
      case "delete":
        return this.props.cancelPendingWorkflow(this.state.uids.join())
      case "download":
      default:
        this.props.getWorkflowDetailsInformation(null, [this.downloadworkFlowZIP], null, this.state.uids)
    }
  }

  renderHeader() {
    switch (this.getDocsType()) {
      case "pending":
        return <Row style={{ marginBottom: '20px' }} gutter={16}>
          <Col span={16} />
          <Col span={8}>
            <Select disabled={this.state.uids.length === 0} value={general[this.props.language].actions} onChange={this.handleAction}
              className={'w-100'} placeholder={general[this.props.language].actions}>
              {this.state.uids.length === 0 ? '' : <Select.Option value="signp">{signed_files[this.props.language].sign}</Select.Option>}
              {this.state.uids.length === 0 ? '' : <Select.Option value="reject">{general[this.props.language].reject}</Select.Option>}
            </Select>
          </Col>
        </Row>;
      case "signed":
        return (
          <Row gutter={16} style={{ padding: '8px 8px' }}>
            <Col span={16}/>
            <Col span={8}>
              <Select disabled={this.state.ids.length === 0} value={general[this.props.language].actions} onChange={(action) => this.handleAction(action)}
                className={'w-100'} placeholder={general[this.props.language].actions}>
                {this.state.uids.length === 0 ? '' : <Select.Option value="sign">{signed_files[this.props.language].sign_action}</Select.Option>}
                {this.state.ids.length === 0 || this.state.publicFilesChecked > 0 ? '' : <Select.Option value="delete">{signed_files[this.props.language].delete_action}</Select.Option>}
                {this.state.uids.length === 0 ? '' : <Select.Option value="mail">{signed_files[this.props.language].email_action}</Select.Option>}
                {this.state.uids.length === 0 ? '' : <Select.Option value="save">{signed_files[this.props.language].save_action}</Select.Option>}
                {this.state.uids.length === 0 ? '' : <Select.Option value="verify">{signed_files[this.props.language].verify_action}</Select.Option>}
                {this.state.ids.length === 1 && this.state.uids.length === 0 ? <Select.Option value="default">{signed_files[this.props.language].defaultAction}</Select.Option> : ''}
              </Select>
            </Col>
          </Row>
        );
      case "workflow":
        return <Row style={{ marginBottom: '20px' }} gutter={16}>
          <Col span={16}>
            {signed_files[this.props.language].workflowStatus}
            <Select style={{ marginLeft: 50, width: 150 }} value={this.props.pending} onChange={this.handleStatusChange}>
              <Select.Option value={true}>{signed_files[this.props.language].pendingStatus}</Select.Option>
              <Select.Option value={false}>{signed_files[this.props.language].finishedStatus}</Select.Option>
            </Select>
          </Col>
          <Col span={8}>
            <Select disabled={this.state.uids.length === 0} value={general[this.props.language].actions} onChange={(action) => this.handleActionWorkFlow(action)}
              className={'w-100'} placeholder={general[this.props.language].actions}>
              {this.state.uids.length === 0 ? '' : <Select.Option value="delete">{signed_files[this.props.language].delete_action}</Select.Option>}
              {this.state.uids.length === 0 ? '' : <Select.Option value="download">{signed_files[this.props.language].download_action}</Select.Option>}
            </Select>
          </Col>
        </Row>;
      case "templates":
      default:
        return <></>;
    }
  };

  renderPanelHeader = (title) => {
    return <Row>
      <Col style={{ marginRight: 10 }}><img src={inboxIcon} alt='inboxIcon' /></Col>
      <Col>
        <Typography className={'size15'}>{title}</Typography>
      </Col>
    </Row>;
  };

  renderRecipients = (users) => {
    return users.map((user) => {
      const isPendingUser = !user.ip
      return <div className='mt-10' key={user.uid + (user.signer ? user.signer : user.userName)}>
        <Row>
          <Col>
            <img src={personIcon} alt='personIcon' />
          </Col>
          <Col>
            <Typography>{user.signer ? user.signer : user.userName}</Typography>
          </Col>
        </Row>
        <Row style={{ paddingLeft: 20 }}>
          <Row>
            <Col>
              <img src={editIcon} alt='editIcon' />

            </Col>
            <Col>
              <Typography className='size13 text-title color-black'>{isPendingUser ? signed_files[this.props.language].pending : (user.status === 1 ? signed_files[this.props.language].canceled : signed_files[this.props.language].signed)}</Typography>
            </Col>
          </Row>
          {
            !isPendingUser && <Row style={{ marginLeft: 10 }}>
              <Row style={{ width: '100%' }}>
                <Typography>{moment(user.fecha).format('DD/MM/YYYY - HH:mm:ss')}</Typography>
              </Row>
              <Row style={{ width: '100%' }}>
                <Typography>IP {user.ip}</Typography>
              </Row>
              <Row style={{ width: '100%' }}>
                <Typography>{signed_files[this.props.language].wdetail + ': ' + user.detail}</Typography>
              </Row>
            </Row>
          }

        </Row>
      </div>
    })
  }

  renderDetailsSigned = (fileInfo, isDrawer = false) => {
    return <>
      {!isDrawer && <div className='separator' style={{ height: '100%' }} />}
      <div className='card-info' style={isDrawer ? { paddingLeft: 30, width: '100%' } : { paddingLeft: 15 }} >
        <FileInfoIcon className='mt-50 mb-20' />
        {renderInfoTag(fileInfo.uid ? signed_files[this.props.language].signed : signed_files[this.props.language].folder)}
        <div className='mt-15 flex'>
          <InfoIcon />
          <Typography className='size15 color-text-secondary' style={{ marginLeft: 10 }}>{signed_files[this.props.language].info}</Typography>
        </div>
        <Col className='mt-15 color-black size13' style={{ textAlign: 'left' }}>
          <Row>
            <Col xs={8}>{signed_files[this.props.language].document_column}</Col>
            <Col xs={16}>{fileInfo.name}</Col>
          </Row>
          <Row>
            <Col xs={8}>{fileInfo.uid ? signed_files[this.props.language].signed : signed_files[this.props.language].created}</Col>
            <Col xs={16}>{moment(fileInfo.lastModified).format('DD/MM/YYYY - HH:mm:ss')}</Col>
          </Row>
          {fileInfo.uid ?
            <>
              <Row>
                <Col xs={8}>{signed_files[this.props.language].category}</Col>
                <Col xs={16}>{fileInfo.public ? 'Público' : 'Privado'}</Col>
              </Row>
              <Row>
                <Col xs={8}>{signed_files[this.props.language].size}</Col>
                <Col xs={16}>{Math.round(parseInt(fileInfo.fileSize) / 1000) + " KB"}</Col>
              </Row>
              <Row>
                <Col xs={8}>{signed_files[this.props.language].type}</Col>
                <Col xs={16}>{fileInfo.name.substr(fileInfo.name.lastIndexOf('.') + 1).toUpperCase()}</Col>
              </Row>
            </> : null
          }
        </Col>
      </div>
    </>
  }

  renderDetailsWF = (fileInfo, isDrawer = false) => {
    if (fileInfo && fileInfo.infoWorkFlow)
      return <>
        {!isDrawer && <div className='separator' style={{ height: '100%' }} />}
        <div className='card-info' style={isDrawer ? { paddingLeft: 30, width: '100%' } : { paddingLeft: 15 }}>
          <FileInfoIcon className='mt-50 mb-20' />
          {renderInfoTag(fileInfo.signersCount ? signed_files[this.props.language].pending : signed_files[this.props.language].signed)}
          <Typography className='text-title color-black size18 mt-10' style={{ textAlign: 'left' }}>{fileInfo.name}</Typography>
          <div className='mt-15 flex'>
            <InfoIcon />
            <Typography className='size15 color-text-secondary' style={{ marginLeft: 10 }}>{signed_files[this.props.language].info}</Typography>
          </div>
          <Col className='mt-15 color-black size13' style={{ textAlign: 'left' }}>
            <Row>
              <Col xs={9}>{signed_files[this.props.language].creationDate1}</Col>
              <Col xs={15}>{moment(fileInfo.creationDate).format('DD/MM/YYYY - HH:mm:ss')}</Col>
            </Row>
            {fileInfo.state && <Row>
              <Col xs={9}>{signed_files[this.props.language].finishDate1}</Col>
              <Col xs={15}>{moment(fileInfo.finishDate).format('DD/MM/YYYY - HH:mm:ss')}</Col>
            </Row>}

            {fileInfo.optMessage && <Row>
              <Col xs={9}>{signed_files[this.props.language].comments}</Col>
              <Col xs={15}>{fileInfo.optMessage}</Col>
            </Row>}


            {fileInfo.fileSize && <Row>
              <Col xs={9}>{signed_files[this.props.language].size}</Col>
              <Col xs={15}>{Math.round(parseInt(fileInfo.fileSize) / 1000) + " KB"}</Col>
            </Row>}
            {fileInfo.name && <Row>
              <Col xs={9}>{signed_files[this.props.language].type}</Col>
              <Col xs={15}>{fileInfo.name.substr(fileInfo.name.lastIndexOf('.') + 1).toUpperCase()}</Col>
            </Row>}

            <Collapse expandIcon={({ isActive }) => isActive ? <img alt={''} src={collapseUpIcon} /> : <img alt={''} src={collapseDownIcon} />}
              expandIconPosition={'end'} defaultActiveKey={['1']}
                      style={{ background: "transparent", marginBottom: 70, marginTop: 20, border: 'transparent', width: '100%' }} items={[
                          {
                            key:1,
                            style : { background: "transparent", height: '100%', padding: 0 },
                            label:this.renderPanelHeader(signed_files[this.props.language].traceability),
                            children: <><Typography className='mt-10'>{signed_files[this.props.language].from} {this.props.user.userName}</Typography>
              <Typography className='color-black text-title size 16 mt-15'>{signed_files[this.props.language].recipients}</Typography>
              {this.renderRecipients([...fileInfo.infoWorkFlow.finishedSigners, ...fileInfo.infoWorkFlow.pendingSigners])}</>
                          }]}>
            </Collapse>
          </Col>
        </div>
      </>
  }

  renderInfo = () => {
    const docsType = this.getDocsType()
    const isShowFull = showFull()
    const showDrawerDetails = Object.keys(this.state.showDetails).length !== 0;
    if (showDrawerDetails) {
      const fileInfo = this.state.showDetails
      return (
        <Drawer styles={{body:{ backgroundColor: '#FFFFFF', alignSelf: 'center', width: '100%' }}} open={showDrawerDetails} placement={'right'}
          width={isShowFull ? '30%' : '100%'} closable={true} onClose={() => this.setState({ showDetails: {} })}>
          {docsType === 'signed' ? this.renderDetailsSigned(fileInfo, true) : (docsType === 'workflow' ? this.renderDetailsWF(fileInfo, true) : null)}
        </Drawer>
      );
    } else if (this.state.newuids.length === 1 && isShowFull) {
      const fileInfo = this.state.newuids[0]
      if (docsType === 'signed') {
        return this.renderDetailsSigned(fileInfo)
      } else if (docsType === 'workflow') {
        return this.renderDetailsWF(fileInfo)
      }
    } else if (this.state.newuids.length > 1) {
      return <>
        <div className='separator' style={{ height: '100%' }} />
        <div className='card-info' style={{ paddingLeft: 15 }}>
          <FileInfoIcon className='mt-50 mb-20' />
          {renderInfoTag(this.state.newuids.length + ' ' + signed_files[this.props.language].selected)}
          <Typography className='text-title color-black size18 mt-10' style={{ textAlign: 'left' }}>{signed_files[this.props.language].multipleFiles}</Typography>
        </div>
      </>
    }
  }

  renderCard = (infoCard) => {
    const docsType = this.getDocsType();
    const isFolder = infoCard.uid === '' || infoCard.folder;
    let selected = this.state.ids.includes(infoCard.id);
    if (docsType === 'workflow' || docsType === 'pending')
      selected = this.state.uids.includes(infoCard.uid);
    const actionOpenFolder = () => { infoCard.name === '...' ? this.upOneLevel() : this.openFolder(infoCard.id) }
    return <>
      {this.renderDropdownActions(infoCard, 'icon-actions-card')}
      <Card className={selected ? 'card-file card-selected' : 'card-file'} hoverable
        onClick={isFolder ? null : () => this.handleCheck(!selected, infoCard, isFolder)} onDoubleClick={isFolder ? actionOpenFolder : null}>
        <Row justify="space-around" align="middle" style={{ height: '100%' }}>
          <Col span={24}>{isFolder ? <FolderIcon style={{ width: 88, height: 72 }} /> : <DocumentIcon style={{ width: 56, height: 68 }} />}</Col>
          <Col span={24} style={{ marginTop: 2, wordBreak: 'break-word' }}><Typography className='size15 color-black' style={{ lineHeight: '19px' }}>{docsType === 'workflow' ? infoCard.fileName : infoCard.name}</Typography></Col>
          {!isFolder && docsType === 'signed' ? <Col span={24} style={{ marginTop: 2 }}>{renderInfoTag(infoCard.public ? signed_files[this.props.language].public.toUpperCase() : signed_files[this.props.language].private.toUpperCase(), 'center')}</Col> : ''}
          {docsType === 'pending' && <Col span={24} style={{ marginTop: 2 }}><Button style={{ minWidth: '100px' }} icon={<img style={{ paddingRight: '8px' }} alt={''} src={pencilIcon} />} className='text-title size12' type="primary" htmlType="submit" onClick={() => this.props.signPendingFiles([infoCard])}>{signed_files[this.props.language].sign.toUpperCase()}</Button></Col>}
          <Checkbox style={{ position: 'absolute', top: 9, left: 10 }} checked={selected} onChange={(e) => this.handleCheck(e.target.checked, infoCard)} />
        </Row>
      </Card></>;
  }

  renderGrid = () => {
    const colsLength = this.props.dimApp.width > xlGrid ? 6 : (this.props.dimApp.width < sGrid ? 2 : 4);
    if (!this.props.signedFileList.entries || this.props.signedFileList.entries.length === 0)
      return;
    let i = 0, rows = [], rowsLength = 1;
    if (this.props.signedFileList.entries.length > colsLength)
      rowsLength = Math.ceil(this.props.signedFileList.entries.length / colsLength);
    for (let j = 0; j < rowsLength; j++) {
      let cols = [];
      for (let k = 0; k < colsLength && i < this.props.signedFileList.entries.length; k++) {
        cols.push(<Col key={'col' + k} span={24 / colsLength}>{this.renderCard(this.props.signedFileList.entries[i])}</Col>);
        i++;
      }
      rows.push(cols);
    }
    return rows.map((row, index) => <Row key={index} style={{ marginTop: '10px', marginBottom: '10px' }} gutter={{ xs: 2, sm: 10, md: 18, lg: 26 }}>{row.map((col) => col)}</Row>);
  }

  showTemplates = () => {
    return this.props.docsType && this.props.docsType === 'templates';
  }

  getRowKey = () => {
    const docsType = this.getDocsType();
    if (docsType === 'workflow' || docsType === 'pending')
      return "uid";
    return "id";
  }

  selectAllGrid = (check) => {
    let updatedListids = []
    let updatedListNewuids = []
    let updatedListuids = []
    let publicFilesChecked = 0;
    this.props.signedFileList.entries.forEach((item) => {
      const newUID = this.getInfoNewUids(item)
      if (check) {
        updatedListids = [...updatedListids, item.id];
        updatedListNewuids = [...updatedListNewuids, newUID];
        if (newUID.uid)
          updatedListuids = [...updatedListuids, newUID.uid];
        if (newUID.public)
          publicFilesChecked++;
      }
    })

    if (updatedListNewuids.length === 1 && this.getDocsType() === 'workflow') {
      this.props.getWorkflowDetailsInformation({ uid: updatedListNewuids[0].uid }, [(data) => {
        updatedListNewuids[0].infoWorkFlow = data.workflowList;
        this.setState({ ids: updatedListids, newuids: updatedListNewuids, uids: updatedListuids, publicFilesChecked, selectAll: check })
      }], [], null, true)
    } else {
      this.setState({ ids: updatedListids, newuids: updatedListNewuids, uids: updatedListuids, publicFilesChecked, selectAll: check })
    }
  }

  renderDocs = () => {
    if (this.props.user.filesView === 0) {
      return <Table
        columns={this.columns()}
        dataSource={this.props.signedFileList.entries}
        size={'small'}
        pagination={false}
        rowSelection={this.rowSelection()}
        title={() => this.renderHeader()}
        rowKey={this.getRowKey()}
        components={!this.props.isModal && { body: { row: DraggableBodyRow } }}
        onChange={(pagination, filters, sorter) => this.props.handleSort(pagination, filters, sorter, this.state.page, this.state.pageSize, this.state.parentId)}
        onRow={(record, index) => {
          if (!this.props.isModal)
            return {
              index, moveRow: this.moveRow, entries: this.props.signedFileList.entries, showTemplates: this.showTemplates()
            }
        }}
      />
    } else {
      return <>{this.renderHeader()}
        {this.props.signedFileList && this.props.signedFileList.total !== 0 &&
          <Checkbox className='size15 text-title color-text-secondary mb-10' checked={this.state.selectAll}
            onChange={(e) => this.selectAllGrid(e.target.checked)} >{signed_files[this.props.language].selectAll}</Checkbox>}
        {this.renderGrid()}
      </>
    }
  }

  renderIconTemplates = () => {
    if (!this.props.signedFileList.entries || !this.showTemplates())
      return <></>;
    if (this.props.signedFileList.entries.length === 0)
      return <Row className='text-center'>
        <Col style={{ paddingTop: '70px' }} span={24}>
          <EmptyTemplatesIcon />
        </Col>
        <Col span={24}>
          <Typography className='text-title size26'>{templates[this.props.language].emptyTitle}</Typography>
        </Col>
        <Col span={24}>
          <Typography style={{ minWidth: '335px', maxWidth: '513px', margin: "auto" }} className='size15'>{templates[this.props.language].emptyComment}</Typography>
        </Col>
        <Col style={{ paddingTop: '30px' }} span={24}>
          {this.renderPublicTemplateButton()}
        </Col>
      </Row>
  };

  onSubmitUploadTemplates = (files, isPrivate) => {
    this.props.modalShow({
      modalType: LOADING_MODAL,
      modalProps: { footer: null },
      componentProps: { text: templates[this.props.language].loadingTemplates }
    });
    files.forEach((file) => {
      this.props.uploadPDFTemplate({ private: isPrivate }, file).then(this.resetSearch);
    });
  }

  showUploadTemplatesModal = (isPrivate = false) => {
    this.props.modalShow({
      modalType: UPLOAD_TEMPLATES_MODAL,
      modalProps: { footer: null },
      componentProps: { language: this.props.language, onSubmit: (files) => this.onSubmitUploadTemplates(files, isPrivate) }
    });
  };

  updateFilterMetadata = (idmetadata, valor, type) => {
    let filterMetadataString = this.state.filterMetadataString;
    const nuevoElemento = { idmetadata, valor, type };
    const index = filterMetadataString.findIndex(
      (elemento) => elemento.idmetadata === nuevoElemento.idmetadata
    );
    const isEmptyOption = valor === '0' || valor === '';
    if (index === -1 && !isEmptyOption) {
      filterMetadataString.push(nuevoElemento);
    } else if (isEmptyOption) {
      filterMetadataString = filterMetadataString.filter(
        (elemento) => elemento.idmetadata !== idmetadata
      );
    } else {
      filterMetadataString[index] = nuevoElemento;
    }
    this.setState({ filterMetadataString }, () => this.handleSearch())
  }

  renderPublicTemplateButton = () => {
    return this.props.auth.admin && <Button type="primary" htmlType="submit" onClick={() => this.showUploadTemplatesModal(false)}
      style={{ width: 211, marginLeft: '15px', marginRight: '15px' }} className='text-title size12'>
      {templates[this.props.language].newTemplatePublic.toUpperCase()}<img style={{ paddingBottom: '4px', paddingLeft: '10px' }} alt={''} src={smallDocument} /></Button>;
  }

  renderCreateTemplateButtons = () => {
    return <Row>
      <Col><Button type="primary" htmlType="submit" onClick={() => this.showUploadTemplatesModal(true)}
        style={{ width: 211, }} className='text-title size12'>
        {templates[this.props.language].newTemplatePrivate.toUpperCase()}<img style={{ paddingBottom: '4px', paddingLeft: '10px' }} alt={''} src={smallPadLock} /></Button>
      </Col>
      <Col>{this.renderPublicTemplateButton()}</Col>
    </Row>;
  };

  renderFilterSelectOptions = (menu, defaultValue, onChange, title, key) => {
    return (
      <div key={key}>
        <Typography className='mt-10 size15 color-black'>{title}</Typography>
        <DropdownList menu={menu} defaultValue={defaultValue} onChange={onChange} width={200} />
      </div>
    )
  }

  renderFilterTextOptions = (idMetadata, title, action) => {
    return (
      <div key={idMetadata}>
        <Typography className='mt-10 size15 color-black'>{title}</Typography>
        <Input.Search
          value={this.state.textValues[idMetadata]}
          onChange={(e) => {
            const textValues = this.state.textValues;
            textValues[idMetadata] = e.target.value;
            this.setState({ textValues })
          }}
          onSearch={(valor) => { action ? action(valor) : this.updateFilterMetadata(idMetadata.toString(), valor, 'text') }}
          enterButton
        />
      </div>
    )
  }


  renderFiltersMetadata = () => {
    const filters = []
    const options = this.state.dictionaryMetadata.options;
    for (let idmetadata in options) {
      const index = this.state.filterMetadataString.findIndex(
        (elemento) => elemento.idmetadata === idmetadata
      );
      const defaultValue = index === -1 ? 0 : this.state.filterMetadataString[index].valor
      filters.push(this.renderFilterSelectOptions(options[idmetadata], defaultValue, (valor) => { this.updateFilterMetadata(idmetadata, valor, 'select') }, this.state.dictionaryMetadata.metadata[idmetadata], idmetadata))
    }
    const optionsText = this.state.dictionaryMetadata.optionsText;
    optionsText.forEach(optionText => {
      filters.push(this.renderFilterTextOptions(optionText, this.state.dictionaryMetadata.metadata[optionText]))
    })
    return filters
  }

  renderFilters = () => {
    if (!this.showTemplates())
      return <div className='flex' style={{ gap: 8, flexWrap: 'wrap' }}>
        {this.props.showCategory && this.renderFilterSelectOptions(signed_files[this.props.language].menuSignedDocs, this.props.showCategory, this.changeCategory, signed_files[this.props.language].category)}
        {this.props.type && this.renderFilterSelectOptions(signed_files[this.props.language].menuTypeSignedDocs, this.props.type, this.changeType, signed_files[this.props.language].type)}
        {this.renderFiltersMetadata()}
        {this.getDocsType()==='signed' && this.renderFilterTextOptions(signed_files[this.props.language].fileName,signed_files[this.props.language].fileName,(value)=>{this.handleFilter();this.props.handleSearchChange(value)})}
        {this.renderClean()}
      </div>
  }

  render() {
    this.addUpButton();
    const docsType = this.getDocsType();
    const showCutButtons = docsType !== 'workflow' && docsType !== 'pending';
    return (
      <div className='management-card'>

        <div className='w-100 '>
          <Typography className='mt-10 mb-10 size15 color-black'>{signed_files[this.props.language].descriptionDocsViewer[this.getDocsType()]}</Typography>
          {this.renderFilters()}

          <div className='flex mt-35' style={{ gap: 15 }}>
            {docsType === 'signed' || (this.showTemplates() && this.props.auth.admin) ? <Button className='dropdown-button' onClick={this.showFolderModal} style={{ display: 'block' }}>{signed_files[this.props.language].createFolder.toUpperCase()}</Button> : ''}
            {this.showTemplates() && this.renderCreateTemplateButtons()}
            {showCutButtons && <div className='separator' style={{ width: 1, height: 30 }} />}
            {showCutButtons && this.state.ids.length !== 0 ? <Button className='dropdown-button' onClick={this.cut} style={{ display: 'block' }}>{signed_files[this.props.language].cut.toUpperCase()}</Button> : ''}
            {showCutButtons && this.state.cutFiles.length !== 0 ? <Button className='dropdown-button' onClick={this.paste} style={{ display: 'block' }}>{signed_files[this.props.language].paste.toUpperCase()}</Button> : ''}
            {showCutButtons && this.state.ids.length !== 0 ? <Button className='dropdown-button' disabled={this.state.ids.length === 0} onClick={() => this.handleAction('delete')} style={{ display: 'block' }}>{signed_files[this.props.language].delete.toUpperCase()}</Button> : ''}
            <Button onClick={() => this.props.updateUserSettings({ filesView: 0 })} className='unstyled-button right'><ListIcon className={this.props.user.filesView === 0 ? 'icon-menu' : null} /></Button>
            <Button onClick={() => this.props.updateUserSettings({ filesView: 1 })} className='unstyled-button'><GridIcon className={this.props.user.filesView === 1 ? 'icon-menu' : null} /></Button>
          </div>
          <ConfigProvider locale={this.props.language === 'es' ? esCO : enUS}>
            {this.renderDocs()}
            <Paginator language={this.props.language} pageSize={this.state.pageSize} handlePageSizeChange={this.handlePageSizeChange}
              page={this.state.page} handlePageChange={this.handlePageChange} limit={this.props.auth.limitFiles}
              initialValues={privateDocsInitialValues} total={this.props.signedFileList.total}
            />
            {this.renderIconTemplates()}
          </ConfigProvider>
          {this.props.isModal && <Button type="primary" htmlType="submit" onClick={this.sendTemplates} style={{ marginRight: 12 }} >AÑADIR SELECCION</Button>}
          {this.props.isModal && <Button type="ghost" htmlType="submit" onClick={() => this.props.onCancel()}>CANCELAR</Button>}
        </div>
        {this.renderInfo()}
      </div>
    );
  }
}

export default PrivateRoute(connect(null, {
  cleanSignatureFiles, getUserInfo, createFolder, moveFiles, delNotifications,
  getValidOperations, sendSignedFilesUIDMail, startStrongTx, resignFiles, getSignedFiles, verifyFilesWithUID, modalShow, updateDefaultFolder,
  delSignedFiles, modalDismiss, updateUserSettings, getPDFTemplate, deletePDFTemplate, uploadPDFTemplate, getAllMetadata
})(DocsViewer));