import netcoServer from '../../apis/netcoServer';
import {GET_CONFIGURATION} from "../types";
import tableLoadingCall from "../helpers/tableLoadingCall";

const getConfiguration= () => async (dispatch) => {
  await tableLoadingCall(dispatch, () => netcoServer.newPost('/SystemService/getNewConfiguration', {}), GET_CONFIGURATION, onSuccessPayload);
};

const onSuccessPayload = data => {
  return {
    adminConfiguration: {
      configuration: data.result.configuration
    }
  };
};
export default getConfiguration;