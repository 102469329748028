import netcoServer from '../../apis/netcoServer';
import {
  INFO_SHOW
} from "../types";
import loadingCall from "../helpers/loadingCall";

const fillPendingPDF= (uid,password,formValues, callback = null) => async (dispatch) => {
  let formData = {uniqueID: uid,passwords:JSON.stringify(password),formFlattening:formValues.formFlattening,jsonData:JSON.stringify(formValues)};
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/fillPendingPDF', formData));
  if (!response.data.success)
  {
    let message="Error al comunicarse con el servidor, intenta de nuevo mas tarde";
    if(response && response.data && response.data.detail)
      message=response.data.detail;
    dispatch({type: INFO_SHOW,
      payload: {
        info: {
          message: message,
          type: 'error'
        }
      }});
  }
  else
  {
    if(callback)
      callback();
  }
};
export default fillPendingPDF;