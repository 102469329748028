import netcoServer from '../../apis/netcoServer';
import {GET_ORDERS} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const getOrders= () => async (dispatch) => {
  const onSuccessPayload = data => {
    return data;
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/getOrders', {}));
  handleResponse(dispatch, response, GET_ORDERS, onSuccessPayload, null, []);
};
export default getOrders;
