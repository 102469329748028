import netcoServer from '../../apis/netcoServer';
import {GET_SYSTEM_LOG} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";
import handleBase64ZipResponse from "../helpers/handleBase64ZipResponse";

const getSystemLog= () => async (dispatch) => {
  const onSuccessPayload = () => {
   };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/getSystemLog', {}));
  handleResponse(dispatch, response, GET_SYSTEM_LOG, onSuccessPayload);
  if (response.data.success)
    handleBase64ZipResponse(response.data.value, 'log.txt');
};
export default getSystemLog;
