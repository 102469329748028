export const ADMIN_SERVERS_MODAL = 'ADMIN_SERVERS_MODAL';
export const DELETE_USER_MODAL = 'DELETE_USER_MODAL';
export const ADMIN_PROFILES_MODAL = 'ADMIN_PROFILES_MODAL';
export const ADMIN_WEBSERVICES_MODAL = 'ADMIN_WEBSERVICES_MODAL';
export const ADMIN_METADATA_MODAL = 'ADMIN_METADATA_MODAL';
export const ADMIN_CREATE_METADATA_OPT_MODAL = 'ADMIN_CREATE_METADATA_OPT_MODAL';
export const ADMIN_CONFIGURATIONS_MODAL = 'ADMIN_CONFIGURATIONS_MODAL';
export const ADMIN_USERS_MODAL = 'ADMIN_USERS_MODAL';
export const VERIFICATIONS_MODAL = 'VERIFICATIONS_MODAL';
export const PAGES_REPORT_MODAL = 'PAGES_REPORT_MODAL';
export const MAIL_MODAL = 'MAIL_MODAL';
export const SEND_MODAL = 'SEND_MODAL';
export const NEW_PASSWORD_MODAL = 'NEW_PASSWORD_MODAL';
export const TEMPLATE_VIEWER_MODAL = 'TEMPLATE_VIEWER_MODAL';
export const SIGNATURE_OPTIONS_MODAL = 'SIGNATURE_OPTIONS_MODAL';
export const SYSTEM_INFORMATION_MODAL = 'SYSTEM_INFORMATION_MODAL';
export const PDF_PASSWORD_MODAL = 'PDF_PASSWORD_MODAL';
export const SIGNATURE_MODAL = 'SIGNATURE_MODAL';
export const IMAGE_MODAL = 'IMAGE_MODAL';
export const TOKEN_MODAL = 'TOKEN_MODAL';
export const FOLDER_MODAL = 'FOLDER_MODAL';
export const REJECT_MODAL = 'REJECT_MODAL';
export const WORKFLOW_MODAL = 'WORKFLOW_MODAL';
export const CONFIRM_MODAL = 'CONFIRM_MODAL';
export const MODIFY_SUBSCRIPTION_MODAL ='MODIFY_SUBSCRIPTION_MODAL';
export const SIGNATURE_UPIMAGE_MODAL = 'SIGNATURE_UPIMAGE_MODAL';
export const SIGNATURE_CHOOSER_MODAL = 'SIGNATURE_CHOOSER_MODAL';
export const RENAME_KEY = 'RENAME_KEY';
export const INFO_KEY = 'INFO_KEY';
export const MESSAGE_MODAL = 'MESSAGE_MODAL';
export const ZOOM_MODAL = 'ZOOM_MODAL';
export const UPLOAD_TEMPLATES_MODAL = 'UPLOAD_TEMPLATES_MODAL';
export const LOADING_MODAL = 'LOADING_MODAL';
export const NEW_PREFERENCE_MODAL = 'NEW_PREFERENCE_MODAL';
export const TEMPLATES_MODAL = 'TEMPLATES_MODAL'
export const NEW_VIEW_PREFERENCE_MODAL = 'NEW_VIEW_PREFERENCE_MODAL'
export const HANDWRITTEN_MODAL = 'HANDWRITTEN_MODAL'
export const NEW_CERTIFICATE_MODAL='NEW_CERTIFICATE_MODAL';
export const SIGN_AND_SHARE = 'SIGN_AND_SHARE';
export const METADATA_MODAL = 'METADATA_MODAL';
export const CONFIRM_TRANSFER_MODAL = 'CONFIRM_TRANSFER_MODAL';