import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Card, Col, Row } from "antd";
import {CreateCheckbox, CreateFileField} from "../../Helpers/Input/InputCreator";
import importCertificateFromZIP from '../../../actions/certificates/importCertificateFromZIP';
import {Field, reduxForm} from "redux-form";

// import 'antd/lib/card/style/css';


// import 'antd/lib/alert/style/css';


import {connect} from "react-redux";
import TitleAndInfo from "../../Helpers/TitleAndInfo";
import PrivateRoute from "../../Root/PrivateRoute";
import {required} from "../../Helpers/Input/validations";
import certificates from "../../../const/certificates";
import {INFO_SHOW} from "../../../actions/types";
import infoPayload from "../../../actions/helpers/payloadHandlers/infoPayload";

class Zip extends React.Component {

  state = {formFile: []};

  onSumbit = (formValues) => {
    let file = this.state.formFile[0] === undefined ? null : this.state.formFile[0].file;
	formValues.language=this.props.language;
	formValues.requiresCert=this.props.requiresCert;
    this.props.importCertificateFromZIP(formValues, file);
  };

  handleOnDropFile = (newFile, onChange) => {
    if(!newFile || newFile.length<=0)
    {
      this.props.dispatch({type: INFO_SHOW, payload: infoPayload('error', certificates[this.props.language].invalidFile)});
      return;
    }

    const formFile = {
      file: newFile[0],
      name: newFile[0].name,
      preview: URL.createObjectURL(newFile[0]),
      size: newFile[0].size
    };
    this.props.change('avatar', formFile);
    this.setState({formFile: [formFile]}, () => onChange(formFile));
  };

  render() {
    return (
      <Card bordered={false}>
        <TitleAndInfo title={certificates[this.props.language].zipTitle} info={certificates[this.props.language].zip_info2}/>
        <Form onFinish={this.props.handleSubmit(this.onSumbit)} className={'p-20'}>
          <Row gutter={16}>
            <Col span={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].zip_file}</div>
              <Field
                     name="avatar"
                     accept={".zip, .p7b, .pem, .cer, .crt"}
                     component={CreateFileField}
                     handleOnDrop={this.handleOnDropFile}
                     type='file'
                     formFile={this.state.formFile}
                     validate={required}
                     multiple={false}
              />
            </Col>
            <Col span={12}>
              <Field name="updateGraphicImage"
                     type={'checkbox'}
                     component={CreateCheckbox}
                     hasFeedback>
                {certificates[this.props.language].graphic_image}
              </Field>
            </Col>
          </Row>
          <Row className={'flexRight'} gutter={16}>
            <Button htmlType={'submit'} className={'float-right'} type={'primary light-primary rounded-sm'}>
              {certificates[this.props.language].createZIP}
            </Button>
          </Row>
        </Form>
      </Card>
    );
  }

}

const mapStateToProps = state => {
  return {
    language: state.auth.language,
	requiresCert: state.auth.requiresCert,
    initialValues: {
      updateGraphicImage: false
    }
  }
};

export default PrivateRoute(connect(mapStateToProps, {importCertificateFromZIP})((reduxForm({
  form: 'zipCertificate_form'
})(Zip))));
