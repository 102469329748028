import React, { useEffect } from 'react';
import { Button, Input, Typography} from 'antd';

// import 'antd/lib/button/style/css';


// import 'antd/lib/form/style/css';


// import 'antd/lib/input/style/css';


// import 'antd/lib/icon/style/css';


import '../../Helpers/newCustomAnt.css';
import { userNormalizer } from "../../Helpers/Input/normalizers";
import { RECOVER_PASSWORD_ROUTE, SIGNUP_ROUTE } from "../../Root/routes";
import auth from "../../../const/auth";
import { push } from "connected-react-router";
import Form from "antd/es/form/Form";
import {getCustomLogos, getCustomMessages, renderIDPS, renderSeparator} from "../../Helpers/utils";
import iconUser from '../../../resources/img/user.svg'
import padLock from '../../../resources/img/padLock.svg'

const NewLogInForm = (props) => {

    const [form] = Form.useForm();

    const messages = getCustomMessages(props.language);

    useEffect(() => {
        form.setFieldsValue({ userName: props.initialValues.userName });
    }, [form, props.initialValues.userName])

    const hasSignUpOptions = () => {
        return props.registration.quick
        //return props.registration.quick || props.registration.data || props.registration.codes || props.registration.id;
    };

    const renderSignUpBtn = () => {
        if (hasSignUpOptions())
            return <Typography className={'size15 color-black text-link'} onClick={() => props.dispatch(push(SIGNUP_ROUTE))} style={{ paddingLeft: '2px', marginBottom:43 }}>
                {auth[props.language].signupbtn}</Typography>;
    }

    const renderIDPSFrame=(idps,search,userName,orMessage)=>
    {
        return <>
            {renderIDPS(idps,search,userName,auth[props.language].loginWith)}
            {renderSeparator(idps,orMessage)}
        </>;
    };

    return (
        <div className='background'>
            <div className='card' style={{ width: 501 }}>
                <img className='logo-login center' style={{ marginTop: 53.78 }} alt={''} src={getCustomLogos().base64LogoDark} />
                <Typography className='size12 center' style={{ width: '296px', marginTop: 22.01, textAlign: 'center' }}>
                    {messages.messageHome}</Typography>
                <Form form={form} onFinish={props.onSubmit} validateMessages={{ required: auth[props.language].requiredfld }}>
                    <div className='center' style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '79.1%', paddingTop: '31px' }}>
                        {renderIDPSFrame(props.registration.idps,props.search,props.queryParams.userName,auth[props.language].messageHome2)}
                        <Typography className='size12' style={{ fontWeight: 700 }}>
                            {auth[props.language].maillbl.toUpperCase()}</Typography>
                        <Form.Item name={"userName"} normalize={userNormalizer} rules={[{ required: true, max: 50 }]} >
                            <Input suffix={<img alt='' src={iconUser} />} size={'large'}
                                placeholder={auth[props.language].usernamelbl} disabled={!!props.queryParams.userName} maxLength={50} />
                        </Form.Item>
                        <div className='margin-top' style={{ display: 'flex' }}>
                            <Typography className='text-title size12' style={{ width: '50%' }}>
                                {auth[props.language].passwordlbl.toUpperCase()}</Typography>
                            <Typography className='size12 text-link' style={{ width: '50%', textAlign: 'right' }}
                                onClick={() => { props.dispatch(push(RECOVER_PASSWORD_ROUTE)) }}>
                                {auth[props.language].forgotbtn}</Typography>
                        </div>
                        <Form.Item name={"password"} rules={[{ required: true }]}>
                            <Input.Password iconRender={() => { return (<img alt='' src={padLock} />) }}
                                size={'large'} className={'mb-10'} visibilityToggle={'false'}
                                placeholder={auth[props.language].passwordlbl} />
                        </Form.Item>
                    </div>

                    <Form.Item className={'text-center'} >
                        <Button className='margin-top text-title size16' type="primary" htmlType="submit" style={{ height: 48 }} >
                            {auth[props.language].loginbtn.toUpperCase()}
                        </Button>
                    </Form.Item>
                    {hasSignUpOptions() && <div className='center' style={{ display: 'flex', width: '261px', marginTop: 15 }}>
                        <Typography className='size15 color-black'>
                            {auth[props.language].signuplbl}</Typography>
                        {renderSignUpBtn()}
                    </div>}
                </Form>
            </div>
        </div>
    );
};

export default NewLogInForm;

