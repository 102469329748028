import React from "react";
import dropDownIcon from '../../../resources/img/NewIcons/dropdown.svg';
import actionsIcon from '../../../resources/img/NewIcons/management/actions.svg'
import { Dropdown, Menu } from "antd";


class DropdownList extends React.Component {

    renderButton = () => {
        const styleButtonWidth = this.props.styleButton ? this.props.styleButton : {}
        styleButtonWidth.width = this.props.width
        styleButtonWidth.height = this.props.height;
        if (this.props.type === 'actions') {
            return <button className={'unstyled-button'} onClick={e => e.preventDefault()}>
                <img alt="actions" src={actionsIcon} />
            </button>
        } else {
            return <button className='dropdown-button' onClick={e => e.preventDefault()} style={styleButtonWidth}>
                {this.props.menu[this.props.defaultValue] ?this.props.menu[this.props.defaultValue].toUpperCase():''}
                <img alt="dropdown" src={dropDownIcon} style={{ marginRight: 7, marginLeft: 'auto' }} />
            </button>
        }
    }

    render() {
        const menuList = this.props.menu;
        const styleMenuWidth = this.props.styleMenu ? this.props.styleMenu : {}
        styleMenuWidth.width = this.props.width;
        styleMenuWidth.height = this.props.height;
        const items=Object.keys(menuList).map((option)=>{
            return {key:option, label: menuList[option].toUpperCase(),className:'dropdown-item'};
        });
        return <Dropdown
            dropdownRender={()=>(
                <Menu className='dropdown-menu' style={styleMenuWidth} onClick={({ key }) => { this.props.onChange(key) }}
                items={items}/>
            )}
            trigger={['click']}>
            {this.renderButton()}

        </Dropdown>
    }
}
export default DropdownList;