import React from 'react';
import { Typography } from "antd";

const renderImage = (image,renderQR) => {
    if(renderQR){
        return renderQR()
    }else if(image){
        return <img className="mt-10 mb-20" style={{ width: 160, height: 160}} src={image} alt={"imgSignature"} />
    }

}

const OtherOptions = (props) => {
    const showImg = props.image && props.image.replace('data:image/png;base64,', '') === '' ? false : props.image;
    return (
        <div style={props.style} className={props.className}>
            <Typography className="text-title size15">{props.text}</Typography>
            {renderImage(showImg,props.renderQR)}
            <Typography className="size10">{props.text2}</Typography>
        </div>
    )
}

export default OtherOptions