import React, { useEffect, useRef } from 'react';
import RoutesContainer from "./RoutesContainer";
import authorization from "../../authorization";
import { connect } from "react-redux";
import { Layout } from "antd";
import NewPrivateHeader from "../Layouts/Private/NewPrivateHeader"
import PublicHeader from "../Layouts/Public/PublicHeader";
import PublicFooter from "../Layouts/Public/PublicFooter";
import NewPrivateSider from '../Layouts/Private/NewPrivateSider';
import NotificationCreator from "../Feedback/Notification";
import { Loading } from '../Feedback/Loader/Loading';
import Modal from "../Feedback/Modal";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from "react-dnd";
import isLoggedIn from "../../actions/auth/isLoggedIn";
import { showFullMenus, siderWidth, getCustomColors, hideMenuPath } from "../Helpers/utils";
import NewSignatureOptions from '../Layouts/Private/NewSignatureOptions';
import NewAppOptions from '../Layouts/Private/NewAppOptions';
import dimensionsApp from '../../actions/info/dimensionsApp';
import { useNavigate } from 'react-router-dom';

const AuthorizedHeader = authorization(NewPrivateHeader);

const AppContainer = (props) => {

    const logged = props.session;
    const localProps = useRef(props);
    const customization = props.customization;
    useEffect(() => {
        const handleResize=()=>localProps.current.dimensionsApp({height:window.innerHeight,width:window.innerWidth});
        window.addEventListener('resize', handleResize);
        customizationCSS(getCustomColors())
    },[]);
    useEffect(() => {
        if (logged === null)
            localProps.current.isLoggedIn();
        const storageCustomization = window.localStorage.getItem('customization');
        const strCustomization = customization ? JSON.stringify(customization) : ''
        if (strCustomization !== '{}' && strCustomization !== 'null') {
            if (!strCustomization && storageCustomization)
                window.localStorage.removeItem('customization')
            else if (strCustomization !== storageCustomization)
                window.localStorage.setItem('customization', strCustomization)
            customizationCSS(getCustomColors(customization))
        }
    }, [logged, customization]);

    const customizationCSS = (jsonColors) => {
        Object.keys(jsonColors).map(color => document.documentElement.style.setProperty(`--${color}`, jsonColors[color]));
    }

    const showMenus = showFullMenus(props.dimApp.width)
    return (
        <Layout style={{ minHeight: '100%', position:'absolute' }} className={'h-100 w-100'}>
            <Modal />
            <Loading language={props.language} />
            <NotificationCreator />
            <NewPrivateSider />
            <NewAppOptions />
            <NewSignatureOptions />

            <Layout style={showMenus && !props.hideAllMenu? (props.visibleMenu && logged && !props.hideMenu ? { marginLeft: siderWidth+ 'px' } : { marginLeft:61 }):{}}>
                <AuthorizedHeader />
                <PublicHeader />
                <DndProvider backend={HTML5Backend}>
                        <Layout.Content>
                            <RoutesContainer className={'p-20'} showMenus={showMenus} showShortcuts={props.showShortcuts}
                                             marginHeaderBody ={showMenus && !hideMenuPath(props.pathname) && !props.hideHeader} />
                        </Layout.Content>
                </DndProvider>
                <PublicFooter language={props.language} props={props} />
            </Layout>
        </Layout>
    );
};

function mapStateToProps(state) {
    return {
        pathname: state.router.location.pathname,
        language: state.auth.language,
        session: state.session.hasSession,
        hideMenu: state.auth.hideMenu,
        customization: state.adminConfiguration.customization,
        dimApp: state.locate.dimensionsApp,
        showShortcuts: state.locate.visibleShortcuts,
        visibleMenu: state.locate.visibleMenu,
        hideAllMenu: state.locate.hideAllMenu,
        hideHeader: state.locate.hideHeader
    }
}



export const withRouter = (Component) =>{
    return (props) =>{
        const history = useNavigate();
        return <Component history={history} {...props}/>
    }
}

export default withRouter(connect(mapStateToProps, { isLoggedIn, dimensionsApp })(AppContainer));