import {replace} from "connected-react-router";
import {ROOT_ROUTE} from "../../components/Root/routes";
import infoShow from "../info/infoShow";
import { GET_SIGNATUREQR } from "../types";
import signature from '../../const/signature';

export const dispatchRootRoute=(dispatch,params) => {
    let newRedirect=null;
    if (params && params.redirect)
    {
        const srcRedirect= decodeURIComponent(params.redirect);
        if (params.selectedTemplate)
            newRedirect=srcRedirect + "?selectedTemplate=" + params.selectedTemplate;
        else if(params.uniqueID)
            newRedirect=srcRedirect + "?uniqueID=" + params.uniqueID;
        else if(params.signFirst)
            newRedirect=srcRedirect + "?signFirst=true";
        else if(params.signAll)
            newRedirect=srcRedirect + "?signAll=true";
        else if(params.referenceNumber && params.authCode && params.option==='1')
            newRedirect=srcRedirect + "?referenceNumber=" + params.referenceNumber + "&authCode=" + params.authCode + "&option=1";
        else if(params.token && params.option==='6')
            newRedirect=srcRedirect + "?token=" + params.token  + "&option=6";
        else if(params.x_id_factura)
            newRedirect=srcRedirect + "?x_id_factura=" + params.x_id_factura;
        else
            newRedirect=srcRedirect;
    }
    if(newRedirect)
        dispatch(replace(newRedirect));
    else
        dispatch(replace(ROOT_ROUTE));
};

export const handleQRError = (dispatch,response,state,language) => {
    if(!response || !response.data || !response.data.success){
        console.error("error: ",(response && response.data)?response.data.detail:null)
        const data = {
          signatureFiles: {
            infoImageQR: {state:'cancelled'}
          }
        }
        if(state==='drawing')
            dispatch(infoShow({info: {message: signature[language].rescan, type: 'success'}}));
        dispatch({type:GET_SIGNATUREQR, payload:data})
    }
}