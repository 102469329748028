import React from 'react';

// import 'antd/lib/layout/style/css';


// import 'antd/lib/menu/style/css';


// import 'antd/lib/avatar/style/css';


// import 'antd/lib/dropdown/style/css';


// import 'antd/lib/button/style/css';


// import 'antd/lib/icon/style/css';


// import 'antd/lib/drawer/style/css';


import './PublicHeader.css';
import {connect} from "react-redux";
import PublicHeaderMenu from "./PublicHeaderMenu";
import {push} from 'connected-react-router';
import changeLanguage from "../../../../actions/auth/changeLanguage";

class PublicHeader extends React.Component {
  
  state = {
    visible: false
  };
  
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  
  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleLanguage = () => {
    this.props.changeLanguage(this.props.language);
  };
  
  render() {
    if(this.props.hasSession)
		return "";		
	return (
      <div><nav className="menuBar">
        <div className="rightMenu">
          <PublicHeaderMenu mode={'horizontal'} changeLanguage={this.props.changeLanguage}
                             language={this.props.language} logged={this.props.logged}
                             requiresCert={this.props.requiresCert} push={this.props.push}
                             pathname={this.props.pathname}/>
        </div>
      </nav>
    {/*{window.innerWidth<=576?<div style={{textAlign:'right'}}>{this.props.language === 'es' ? <img onClick={this.handleLanguage} style={{width: 30}} alt={'COL flag'} src={coSvg}/> : <img onClick={this.handleLanguage} style={{width: 30}} alt={'US flag'} src={usSvg}/>}</div>:''}*/}
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    pathname: state.router.location.pathname,
    hasSession: state.session.hasSession,
    requiresCert: state.auth.requiresCert,
    language: state.auth.language
  }
}

export default connect(mapStateToProps, {push, changeLanguage})(PublicHeader);