import React from 'react';
import {connect} from "react-redux";
import PrivateRoute from "../../Root/PrivateRoute";
import getWorkflowFilesInformation from "../../../actions/files/getWorkflowFilesInformation";
import getWorkflowDetailsInformation from "../../../actions/files/getWorkflowDetailsInformation";
import modalShow from "../../../actions/modal/modalShow";
import modalDismiss from "../../../actions/modal/modalDismiss";
import signed_files from "../../../const/signed_files";
import general from "../../../const/general";
import {WORKFLOW_MODAL, MESSAGE_MODAL, REJECT_MODAL} from "../../Feedback/Modal/types";
import handleBase64FileResponse from "../../../actions/helpers/handleBase64ZipResponse";
import deleteWorkflowFiles from "../../../actions/files/deleteWorkflowFiles";
import getPendingFilesInformation from "../../../actions/files/getPendingFilesInformation";
import DocsViewer from "../../NewManagementDocs/DocsViewer";
import clearWorkflowDetailsInformation from '../../../actions/files/clearWorkflowDetailsInformation';
import { pendingDocsInitialValues } from "../../../apis/generalValues";
import getUserInfo from "../../../actions/profile/getUserInfo";
import updateUserSettings from '../../../actions/profile/updateUserSettings';

class NewWorkflowDocs extends React.Component {

    state={pending:true};

    componentDidMount() {
        this.props.getUserInfo([], [], [(data) => {
            const userSettings = data.user;
            const limitWorkflow = userSettings.resultsWorkflow ? userSettings.resultsWorkflow : pendingDocsInitialValues.initialPageSize;
            this.setState({ limit: limitWorkflow, skip: 0 })
        }]);
    }

    getWorkflowFilesInformation=()=>
    {
        this.props.getWorkflowFilesInformation({...this.state});
    };

    handleStatusChange=(value, skip,limit)=>
    {
        this.setState({pending:value,skip,limit},this.getWorkflowFilesInformation);
    };

    handleSearch = (page, pageSize, parentid, allResults, filterMetadataString) => {
        if (pageSize && pageSize !== this.state.limit && pageSize !== allResults) {
            this.props.updateUserSettings({ 'resultsWorkflow': parseInt(pageSize) })
        }
        this.setState({skip:page-1,limit:pageSize, filterMetadataString},this.getWorkflowFilesInformation);
    };



    openTrack=(record)=>
    {
        this.props.modalShow({
            modalType: WORKFLOW_MODAL,
            modalProps: {footer: null, title: signed_files[this.props.language].showTrack},
            componentProps: {record:record}});
    };

    openFile=(data)=>
    {
        handleBase64FileResponse(data.workflowList.base64File, data.workflowList.fileName);
    };

    deleteWorkflowfiles=(uid,comment)=>
    {
        this.props.deleteWorkflowFiles(uid,comment,()=>{
            this.getWorkflowFilesInformation();
            this.props.getPendingFilesInformation({limit:1,skip:0});
        });
    };

    cancelPendingWorkflow=(uid)=>
    {
        this.props.modalShow({
            modalType: REJECT_MODAL,
            modalProps: {footer: null, title: signed_files[this.props.language].delete_action},
            componentProps: {language:this.props.language,btn: signed_files[this.props.language].delete_action,
            message:general[this.props.language].deleteComment,
            onSubmit: (formValues) => {this.props.modalDismiss();this.deleteWorkflowfiles(uid,formValues.comment)}}});
    }



    handleSort = (pagination, filters, sorter,page,pageSize) =>
    {
        this.setState({sidx: sorter.field?sorter.field:'', sord:sorter.field? sorter.order:''}, ()=>this.handleSearch(page,pageSize));
    };

    showMessage=(name, message)=>
    {
        this.props.modalShow({
            modalType: MESSAGE_MODAL,
            modalProps: {footer: null, title: general[this.props.language].comment},
            componentProps: {language:this.props.language, message: message, name: name}});
    };

    render()
    {
        return <DocsViewer handleStatusChange={this.handleStatusChange} handleSort={this.handleSort} {...this.state}
                           openTrack={this.openTrack} cancelPendingWorkflow={this.cancelPendingWorkflow}
                           showMessage={this.showMessage} openFile={this.openFile}
                           docsType={'workflow'} {...this.props} {...this.state} results={this.state.limit} handleSearch={this.handleSearch}/>;
    }
}

const mapStateToProps = state => {
  return {
      signedFileList: state.workflowList,
      language: state.auth.language,
      auth: state.auth,
      user: state.user,
      dimApp: state.locate.dimensionsApp,
      metadata: state.reportList.entries
  };
};

export default PrivateRoute(connect(mapStateToProps, {getWorkflowFilesInformation, clearWorkflowDetailsInformation,
    getWorkflowDetailsInformation,updateUserSettings,modalShow,getUserInfo,modalDismiss,deleteWorkflowFiles,getPendingFilesInformation})(NewWorkflowDocs));
