import {reducer as formReducer} from 'redux-form';
import {connectRouter} from 'connected-react-router'
import authReducer from './authReducer';
import registrationReducer from './registrationReducer';
import infoReducer from "./infoReducer";
import reportListReducer from "./reportList";
import userReportListReducer from "./userReportList";
import loadingReducer from "./loadingReducer";
import modalReducer from "./modalReducer";
import userReducer from "./user";
import paymentReducer from "./payment";
import adminConfigurationReducer from "./adminConfigurationReducer";
import verifiedFilesListReducer from "./verifiedFilesListReducer";
import privateFileListReducer from "./privateFileList";
import publicFileListReducer from "./publicFileList";
import pendingFileListReducer from "./pendingFileList";
import templateListReducer from "./templateList";
import ldapUserReducer from "./ldapUser";
import signatureFilesReducer from "./signatureFiles";
import workflowFileList from "./workflowFileList";
import certificateReducers from "./certificateReducers";
import locateReducer from "./locateReducer";
import sessionReducer from "./sessionReducer";
import signedFileListReducer from "./signedFilesList";
import metaDataReducer from './metaDataReducer';

const rootReducer = (history) => ({
  router: connectRouter(history),
  auth: authReducer,
  form: formReducer,
  info: infoReducer,
  loading: loadingReducer,
  modal: modalReducer,
  user: userReducer,
  reportList : reportListReducer,
  metaDataList: metaDataReducer,
  adminConfiguration: adminConfigurationReducer,
  verifiedFilesList: verifiedFilesListReducer,
  userReportList: userReportListReducer,
  privateFileList: privateFileListReducer,
  publicFileList: publicFileListReducer,
  pendingFileList: pendingFileListReducer,
  workflowList:workflowFileList,
  templateList: templateListReducer,
  ldapUser: ldapUserReducer,
  signatureFiles: signatureFilesReducer,
  registration: registrationReducer,
  payment: paymentReducer,
  certificate: certificateReducers,
  locate:locateReducer,
  session:sessionReducer,
  signedFileList: signedFileListReducer
});
export default rootReducer;
