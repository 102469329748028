import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Col, Modal, Row } from "antd";

// import 'antd/lib/modal/style/css';


import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import getPagesReport from '../../actions/report/getPagesReport';
import {CreateDatePicker} from "../Helpers/Input/InputCreator";
import reports from "../../const/reports";
import general from "../../const/general";

class PagesReportModal extends React.Component {

  componentDidMount() {
    this.props.getPagesReport({});
  }

  render() {
    return (
      (<Modal
        open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
        <Form onFinish={this.props.handleSubmit(this.props.getPagesReport)}>
          <Row className={'text-center'} gutter={8} type="flex" justify="center">
            <Col xs={24} md={8}>
              <Field name="initialDate"
                     component={CreateDatePicker}
                     placeholder={reports[this.props.language].date_column}
                     allowClear={false}
                     hasFeedback/>
            </Col>
            <Col xs={24} md={8}>
              <h2>{this.props.pagesReport}</h2>
            </Col>
            <Col xs={24} md={8}>
              <Button htmlType={'submit'} type={'primary'}>{general[this.props.language].search}</Button>
            </Col>
          </Row>
          
        </Form>
      </Modal>)
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.auth.language,
    pagesReport: state.userReportList.pagesReport
  };
};

export default connect(mapStateToProps, {getPagesReport})(reduxForm({form: 'pages_report_form'})(PagesReportModal));