import netcoServer from '../../apis/netcoServer';
import {IMPORT_CERTIFICATE_FROM_FIEL} from "../types";
import {ROOT_ROUTE} from "../../components/Root/routes";
import handleResponse from '../helpers/handleResponse';
import getBase64File from '../helpers/getBase64File';
import infoPayload from '../helpers/payloadHandlers/infoPayload';
import _ from "lodash";
import loadingCall from "../helpers/loadingCall";
import certificates from "../../const/certificates";
import {push} from "connected-react-router";
import logoutUser from '../auth/logoutUser';
import getValidOperations from "../signature/getValidOperations";

const importCertificateFromFiel= (formValues, key, certificate) => async (dispatch) => {
   const onSuccessPayload = () => {
	if(formValues.requiresCert)
	{
		dispatch(logoutUser());
		dispatch(push('/'));
	}
	return infoPayload('success', certificates[formValues.language].processok);
  };
  formValues.base64File = await getBase64File(key);
  formValues.base64cer = await getBase64File(certificate);
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/ImportService/importCertificateFromFIEL', _.omit(formValues, ['key', 'cert','language','requiresCert'])));

  handleResponse(dispatch, response, IMPORT_CERTIFICATE_FROM_FIEL, onSuccessPayload, ROOT_ROUTE,[getValidOperations]);
};
export default importCertificateFromFiel;
