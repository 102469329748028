import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Modal } from "antd";

// import 'antd/lib/modal/style/css';


import {Field, reduxForm} from "redux-form";
import {CreateTextField} from "../Helpers/Input/InputCreator";
import {maxLengthEs50, required} from "../Helpers/Input/validations";
import general from "../../const/general";

const FolderModal = (props) => {

  const onSubmit = (formValues) => {
    formValues.published=props.published;
    formValues.parentId=props.parentId;
    props.createFolder(formValues);
  };

  return (
    (<Modal open={props.modalType !== null} width={500} {...props.modalProps}>
      <Form onFinish={props.handleSubmit(onSubmit)}>
        <Field
               name="fileName"
               component={CreateTextField}
               placeholder={general[props.language].newFolderPlaceHolder}
               validate={[required,maxLengthEs50]}
               hasFeedback/>
        <div style={{padding: '20px 0'}}>
          <Button htmlType={'submit'} type={'primary light-primary rounded-sm float-right'}>{general[props.language].newFolderCreate}</Button>
        </div>
      </Form>
    </Modal>)
  );
};

export default reduxForm({form: 'folder_modal_form'})(FolderModal);