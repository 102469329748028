import React from 'react';
import {connect} from 'react-redux'
import getTimestampServerInfo from '../../../actions/admin/getTimestampServerInfo';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Col, Modal, Row } from "antd";

// import 'antd/lib/modal/style/css';


// import 'antd/lib/row/style/css';


// import 'antd/lib/col/style/css';


import {Field, reduxForm} from "redux-form";
import {CreatePasswordField, CreateTextField} from "../../Helpers/Input/InputCreator";
import {required} from "../../Helpers/Input/validations";
import admin from "../../../const/admin";
import general from "../../../const/general";
import {LockOutlined} from "@ant-design/icons";


class AdminServersModal extends React.Component {

  componentDidMount() {
    this.props.getTimestampServerInfo(this.props.entryId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.initialValues !== prevProps.initialValues) {
      this.props.initialize(this.props.initialValues);
    }
  }

  render() {
    return (
      (<Modal
        open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
        <Form onFinish={this.props.handleSubmit(this.props.onSubmit)}>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].url_column}</div>
              <Field
                     name="url"
                     component={CreateTextField}
                     placeholder={admin[this.props.language].url_column}
                     validate={required}
                     hasFeedback/>
            </Col>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].short_name_column}</div>
              <Field
                     name="friendlyName"
                     component={CreateTextField}
                     placeholder={admin[this.props.language].short_name_column}
                     validate={required}
                     hasFeedback/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].oid_column}</div>
              <Field
                     name="policyOID"
                     component={CreateTextField}
                     placeholder={admin[this.props.language].oid_column}
                     hasFeedback/>
            </Col>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].user_column}</div>
              <Field
                     name="userName"
                     component={CreateTextField}
                     placeholder={admin[this.props.language].user_column}
                     hasFeedback/>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <div style={{marginTop:'10px'}} className={'mb-10'}>{admin[this.props.language].password}</div>
              <Field
                     name="password"
                     size={'large'}
                     className={'mb-10'}
                     component={CreatePasswordField}
                     prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
                     placeholder={general[this.props.language].password}
                     hasFeedback/>
            </Col>
          </Row>
          <Row>
            <Button className={'float-right'} htmlType={'submit'} type={'primary'}>{this.props.btn}</Button>
          </Row>
        </Form>
      </Modal>)
    );
  }

}

function mapStateToProps(state) {
  return {
    language: state.auth.language,
    server: state.reportList.selectedEntry,
    initialValues: state.reportList.selectedEntry
  }
}

export default connect(mapStateToProps, {getTimestampServerInfo})(reduxForm({form: 'admin_servers_form'})(AdminServersModal));
