import React from 'react';
import { Modal, Form, Typography, Button, Row, Checkbox } from "antd";
import { showFull } from '../Helpers/utils';
import { Field, reduxForm } from "redux-form";
import general from '../../const/general'
import { CreateTextField } from '../Helpers/Input/InputCreator';
import certificates from '../../const/certificates';
import { connect } from 'react-redux';
import {email, required} from "../Helpers/Input/validations";


const SignAndShareModal = (props) => {
  console.log(props.dontShowSignAndShare)
  const showFullValue = showFull();
  return (
    (<Modal bodyStyle={{ padding: '34px 29px 20px 33px' }} style={showFullValue ? { top: '15%' } : { top: '5%' }} open={props.modalType !== null}
      width={showFullValue ? 500 : '90%'} {...props.modalProps}>
      <Typography className='size15 color-black'>{props.message}</Typography>
      <Form onFinish={props.handleSubmit(props.onSubmit)}>
        <Field
          name="mail"
          className={'mb-20 mt-20'}
          component={CreateTextField}
          placeholder={general[props.language].mail}
          validate={[required, email]}
          hasFeedback />
        <Row justify={'space-between'}>
          <Checkbox style={{marginTop:"-15px"}} checked={props.dontShowSignAndShare}
            onChange={(e) => props.updateUserSettings({ 'dontShowSignAndShare': e.target.checked })}>
            <span style={{top:"0.2em", position:'relative'}}>{certificates[props.language].dontShowAgain}</span></Checkbox>
          <Button onClick={props.onSubmit} style={{ width: 109, justifyContent:'center' }} className='size12 dropdown-button'>
            {general[props.language].noThanks}
          </Button>
          <Button htmlType={'submit'} type={'primary light-primary rounded-sm float-right'}>{props.btn}</Button>
        </Row>

      </Form>
    </Modal>)
  );

}

function mapStateToProps(state) {
  return { dontShowSignAndShare: state.user.dontShowSignAndShare };
} 

export default connect(mapStateToProps, {  })(reduxForm({ form: 'send_and_sign_modal_form' })(SignAndShareModal));