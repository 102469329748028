import React from "react";
import {Button, Checkbox, Modal, Typography, Row, Col} from "antd";
import newCertificate from '../../resources/img/NewIcons/certificates/newCertificate.svg'
import { push } from 'connected-react-router';
import certificates from "../../const/certificates";
import PrivateRoute from "../Root/PrivateRoute";
import {connect} from "react-redux";
import updateUserSettings from "../../actions/profile/updateUserSettings";
import {HOME_NEWCERTIFICATE_ROUTE} from "../Root/routes";
import modalDismiss from "../../actions/modal/modalDismiss";

class NewCertificateModal extends React.Component {

    render()
    {
        return (
            (<Modal
                open={this.props.modalType !== null} width={519} {...this.props.modalProps} title={this.props.modalProps.title} centered={true}>
                <Row justify={'center'}>
                    <Col style={{textAlign:'center', marginTop:'67px'}} span={24}><img alt={''} src={newCertificate}/></Col>
                    <Col style={{textAlign:'center', marginTop:'37px'}} span={24}>
                        <Typography style={{marginBottom:'14px'}} className={'text-title size15 color-black'}>{certificates[this.props.language].getCertLabel}</Typography>
                    </Col>
                    <Col style={{textAlign:'center', maxWidth:'293px'}} span={24}>
                        <Typography style={{marginBottom:'67px'}}>{certificates[this.props.language].getCertDescLabel}</Typography>
                    </Col>
                </Row>
                <Row justify={'space-between'}>
                    <Checkbox checked={this.props.user.dontShowcreateCertificate}
                        onChange={(e) => this.props.updateUserSettings({'dontShowcreateCertificate':e.target.checked})}>
                        {certificates[this.props.language].dontShowAgain}</Checkbox>
                    <Button onClick={() => {
                        this.props.modalDismiss();
                        this.props.push(HOME_NEWCERTIFICATE_ROUTE);
                    }} style={{ width: '231px' }} className='size12'
                            type={"primary light-primary rounded-sm"}> {certificates[this.props.language].getCertButton.toUpperCase()}
                    </Button>
                </Row>
            </Modal>)
        );
    }
}
const mapStateToProps = state => {
    return {
        language: state.auth.language,
        user: state.user
    };
};

export default PrivateRoute(connect(mapStateToProps,{updateUserSettings,push, modalDismiss})(NewCertificateModal));