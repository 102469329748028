import React from 'react';
import {connect} from "react-redux";
import {Modal} from "antd";

// import 'antd/lib/modal/style/css';


import modalDismiss from '../../actions/modal/modalDismiss';
import { getQueryParams } from '../Helpers/utils';
import SignatureHandwritten from './SignatureHandwritten';

class SignatureModal extends React.Component {
  state={width:window.innerWidth,height:window.innerHeight}
  componentDidMount()
  {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount()
  {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize=()=>
  {
    this.setState({width:window.innerWidth,height:window.innerHeight});
  };

  render()
  {
    return (
      <Modal style={{top:10}} styles={{body:{padding: 0}}} open={this.props.modalType !== null}
                    width={this.state.width-50} height={this.state.height-150} {...this.props.modalProps}>
            <SignatureHandwritten {...this.props} />
          </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    signatureFiles: state.signatureFiles,
    language: state.auth.language,
    params: getQueryParams(state.router.location.search)
  };
};
export default connect(mapStateToProps, {modalDismiss})(SignatureModal);
