import React from 'react';
import { Document, Page } from "react-pdf";
import { checkPDFPassword } from "../Helpers/utils";
import { connect } from "react-redux";
import PDFPaginator from '../Helpers/PDFPaginator';

class PDFViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        window.addEventListener('resize', () => this.handleResize());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.file !== prevProps.file) {
            //if moved to the next file but it is the same bytes pdf library wont call pageloadsuccess so we need to check passwords again and call it
            if (this.props.file && prevProps.file && prevProps.file.file === this.props.file.file) {
                this.setState({ loading: true }, () => {
                    this.onPassword(() => this.onPageLoadSuccess());
                });
            }
            else
                this.setState(this.getInitialState());
        }
    }

    getInitialState = () => {
        return { pageNumber: 1, numPages: 0, pdfPageInfo: undefined, loading: true, marginLeft: 0 };
    };

    getMarginLeft = (pdfPageInfo) => {
        if (!pdfPageInfo)
            pdfPageInfo = this.state.pdfPageInfo;
        if (!pdfPageInfo)
            return 0;
        return Math.max((window.innerWidth - 200 - pdfPageInfo.width) / 2, 0);
    };

    handleResize = (pdfPageInfo = undefined) => {
        this.setState({ marginLeft: this.getMarginLeft(pdfPageInfo) });
    };

    onPassword = callback => {
        let password = this.props.file.password;
        if (password) {
            callback(password);
            return;
        }
        let fileToCheck = this.props.file;
        if (fileToCheck.file)
            fileToCheck = fileToCheck.file;
        checkPDFPassword(fileToCheck, this.props.file.name, password, this.props.dispatch, this.props.language)
            .then(password => {
                if (this.props.onPassword)
                    this.props.onPassword(password);
                callback(password);
            }).catch(error => {
                console.log("Error en pdf password: ", error);
                if (this.props.onPassword)
                    this.props.onPassword(undefined, error);
            });
    };

    onPageLoadSuccess = (page) => {
        let rotatedPage = page;
        if (rotatedPage) {
            rotatedPage.number = this.state.pageNumber;
            const rotation = rotatedPage.rotate % 360;
            if (rotation === 90 || rotation === 270) { // noinspection JSSuspiciousNameCombination
                rotatedPage = { width: page.height, height: page.width, rotate: rotation, number: rotatedPage.number };
            }
        }
        else
            rotatedPage = this.state.pdfPageInfo;
        this.handleResize(rotatedPage);
        let callback = () => this.props.onPageLoadSuccess(rotatedPage);
        if (!callback)
            callback = () => { };
        this.setState({ pdfPageInfo: rotatedPage, marginLeft: this.getMarginLeft(rotatedPage) }, callback);
    };

    onLoadSuccess = (pdf) => {
        this.setState({ numPages: pdf.numPages, pageNumber: 1 });
    }

    onLoadError = (error) => {
        console.log(error);
        if (this.props.onLoadError)
            this.props.onLoadError(error);
    }


    render() {
        const widthApp = this.props.dimApp.width;
        const submitComponent = this.props.submitComponent, draggableComponent = this.props.draggableComponent;
        const pdfFile = this.props.file;
        if (!pdfFile || (!pdfFile.file && !pdfFile.path))
            return <></>;
        let srcFile = 'data:application/pdf;base64,' + pdfFile.file;
        if (pdfFile.path)
            srcFile = pdfFile
        let pageWidth = widthApp;
        if (this.state.pdfPageInfo && this.state.pdfPageInfo.width)
            pageWidth = this.state.pdfPageInfo.width;
        return <div style={{ background: '#F3F7F9', height: '110%', position: 'relative' }}>
            {<PDFPaginator style={{ width: pageWidth + 'px' }} language={this.props.language} pageNumber={this.state.pageNumber}
                numPages={this.state.numPages}
                onPageChange={(newPage) => this.setState({ pageNumber: newPage })} />}
            <div style={{ color: '#000000', marginLeft: this.state.marginLeft + 'px', marginBottom: '5px', paddingTop: '25px' }} className={'size12'}>
                <span>{pdfFile.name}</span>
            </div>

            <div style={{ position: 'absolute', lineHeight: 0, marginLeft: this.state.marginLeft + 'px' }}
                className={'pdfContainer'}>
                <Document loading={"..."} file={srcFile}
                    className={'documentSignatureContainer2'} onPassword={this.onPassword}
                    onLoadSuccess={this.onLoadSuccess} onLoadError={this.onLoadError}>
                    <Page onLoadSuccess={this.onPageLoadSuccess}
                        renderAnnotationLayer={false} loading={"..."} pageNumber={this.state.pageNumber} />
                </Document>
                {draggableComponent ? draggableComponent : ''}
            </div>
            {/*currentSigner && <div className='card-info'
                 style={{height:'100%',width:'300px',position:'absolute', top:'0', left:(widthApp-310) + 'px', background: '#FFFFFF', boxShadow: '-4px 0px 4px rgba(0, 0, 0, 0.15)'}}>
                <Row>
                    <Col style={{marginBottom:'50px'}} span={24}>
                        <Row style={{marginTop:'25px'}} justify={'center'}>
                            <Col style={{textAlign:'left'}} span={11}>
                                <span className={'text-title size15'}>{currentSigner.signer}</span>
                            </Col>
                            <Col style={{textAlign:'right'}} span={11}>
                                <CloseCircleOutlined onClick={this.props.onClose}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{marginBottom:'30px', marginLeft:'10px', textAlign:'left'}} span={24}>
                        <Switch onChange={(e)=>{this.props.onClose();this.props.onLockChange(currentSigner.signer,!e,currentSigner.id)}}
                                checked={locked} checkedChildren={<CheckOutlined />} />
                        <span style={{ marginLeft: '10px', marginRight: '25px' }}>{load_files[this.props.language].lock}</span>
                    </Col>
                    <Col style={{marginBottom:'30px', marginLeft:'10px', textAlign:'left'}} span={24}>
                        <Switch onChange={(e)=>{this.props.onClose();this.props.onLockChange(currentSigner.signer,e,currentSigner.id)}}
                                checked={!locked} checkedChildren={<CheckOutlined />} />
                        <span style={{ marginLeft: '10px', marginRight: '25px' }}>{load_files[this.props.language].move}</span>
                    </Col>
                    <Col span={24}>
                        <Button style={{ width: '150px', fontSize: '10px' }} type={'light-primary rounded-sm'} onClick={()=>this.props.onDuplicate(currentSigner.signer,this.state.pageNumber)}>
                            {load_files[this.props.language].duplicateSigner}
                        </Button>
                    </Col>
                </Row>
            </div>*/}
            {submitComponent ? submitComponent : ''}
        </div>;
    }
}
const mapStateToProps = state => {
    return {
        language: state.auth.language,
        dimApp: state.locate.dimensionsApp
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PDFViewer);