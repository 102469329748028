import {
  LOAD_END,
  LOAD_START,
  LOG_OUT,
  TABLE_LOAD_END,
  TABLE_LOAD_START
} from "../actions/types";

const INITIAL_STATE = {
  loading: false,
  tableLoading: false
};

const loadingReducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_START:
      return {...state, loading: true};
    case LOAD_END:
      return {...state, loading: false};
    case TABLE_LOAD_START:
      return {...state, tableLoading: true};
    case TABLE_LOAD_END:
      return {...state, tableLoading: false};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default loadingReducer;