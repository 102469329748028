import netcoServer from '../../apis/netcoServer';
import {CLEAR_ORDER, CREATE_SESSION, LOG_OUT} from "../types";
import handleResponse from "../helpers/handleResponse";
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import {replace} from "connected-react-router";
import loadingCall from "../helpers/loadingCall";
import {destroy} from "redux-form";

const logoutUser= (closeError=true, redirect=true,loginAgain=true) => async (dispatch) => {
    const onSuccessPayload = () => closeError?infoPayload('close', null):{};
    let response = await loadingCall(dispatch, () => netcoServer.newPost('/LoginService/logoutUser', {}));
    handleResponse(dispatch, response, LOG_OUT, onSuccessPayload);
    if(!loginAgain)
    {
        sessionStorage.removeItem("idp");
        sessionStorage.removeItem("userName");
    }

    sessionStorage.removeItem("logged");
    dispatch(destroy('signature_form'));
    dispatch({type:CREATE_SESSION,payload:{hasSession:false}});
    dispatch({type: CLEAR_ORDER});
    if(redirect)
        dispatch(replace('/'));
};
export default logoutUser;
