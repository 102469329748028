import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Modal } from "antd";

// import 'antd/lib/modal/style/css';


import GraphicChooser from "../GraphicChooser";
import {showFull} from "../Helpers/utils";
class GraphicChooserModal extends React.Component {

render() {
    const showFullValue=showFull();
    return (
      (<Modal bodyStyle={showFullValue?{paddingBottom:24}:{paddingBottom:3}} style={showFullValue?{top:'5%'}:{top:'1%',paddingBottom:0}} open={this.props.modalType !== null} width={showFullValue?'80%':'90%'}
             {...this.props.modalProps}>
        <GraphicChooser {...this.props}/>
      </Modal>)
    );
}
}
export default (GraphicChooserModal);