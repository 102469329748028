import netcoServer from '../../apis/netcoServer';
import {IMPORT_CERTIFICATE_ENTRUST_CODES} from "../types";
import {ROOT_ROUTE} from "../../components/Root/routes";
import handleResponse from '../helpers/handleResponse';
import infoPayload from '../helpers/payloadHandlers/infoPayload';
import loadingCall from "../helpers/loadingCall";
import certificates from "../../const/certificates";
import {push} from "connected-react-router";
import logoutUser from '../auth/logoutUser';
import getValidOperations from "../signature/getValidOperations";

const importCertificateEntrustCodes= (formValues) => async (dispatch) => {
  const onSuccessPayload = () => {
	if(formValues.requiresCert)
	{
		dispatch(logoutUser());
		dispatch(push('/'));
	}
	return infoPayload('success', certificates[formValues.language].processok);
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/ImportService/importCertificateEntrustCodes', formValues));
  handleResponse(dispatch, response, IMPORT_CERTIFICATE_ENTRUST_CODES, onSuccessPayload, ROOT_ROUTE,[getValidOperations]);
};
export default  importCertificateEntrustCodes;