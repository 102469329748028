import netcoServer from '../../apis/netcoServer';
import {TEST_SMTP} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import loadingCall from "../helpers/loadingCall";
import admin from '../../const/admin';

const testSMTP= () => async (dispatch,getState) => {

  const onSuccessPayload = () => {
    return infoPayload('success', admin[getState().auth.language].processok);
  };  
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/testSMTP', {}));
  handleResponse(dispatch, response, TEST_SMTP, onSuccessPayload, null, [], []);
};
export default testSMTP;