import React from 'react';
import {connect} from "react-redux";
import redirect from "../../actions/auth/redirect";
import {
  ACTIVATE_ROUTE, HOME_ROUTE,
  PENDING_FILES_ROUTE,
  SEND_AND_SIGN_ROUTE,
  WORKFLOW_FILES_ROUTE
} from "../Root/routes";
import _ from "lodash";

class RedirectDocs extends React.Component {

  componentDidMount()
  {
    switch(this.props.active)
    {
      case 'activate':
        this.props.redirect(ACTIVATE_ROUTE + '?token=' + this.props.token);
        break;
      case 'pending':
        this.props.redirect(PENDING_FILES_ROUTE);
        break;
      case 'workflow':
        this.props.redirect(WORKFLOW_FILES_ROUTE);
        break;
      case 'newworkflow':
        this.props.redirect(SEND_AND_SIGN_ROUTE + '?active=workflow');
        break;
      case 'private':
      case 'public':
      default:
        this.props.redirect(HOME_ROUTE);
        break;
    }
  }

  render() {
    return (
        <div/>
    );
  }


}

const mapStateToProps = state => {
  let queryParams = state.router.location.search ? _.reduce(state.router.location.search.substring(1).split("&"),
      (result, value) => {
        let params = value.split("=");
        result[params[0]] = params[1];
        return result;
      }, {}) : {};
  return {
    active:queryParams.active,
    token:queryParams.token
  };
};

export default connect(mapStateToProps, {redirect})(RedirectDocs);
