import _ from 'lodash';
import {
  IS_LOGGED_IN,
  LOG_IN,
  SIGN_UP,
  LOG_OUT,
  CHECK_BASIC_AUTHENTICATION,
  START_STRONG_AUTHENTICATION,
  CHECK_STRONG_AUTHENTICATION,
  INVALID_SESSION,
  CHANGE_LANGUAGE,
  RESET_STRONG_TX,
  HIDE_MENU,
  CHECK_APP_CODE_AUTHENTICATION, SHOW_MENU, CLEAR_APP_CODE, CLEAR_SIGNER_CODE, RESET_AUTH_SIGN_PARAMS, LOG_OUT_AFTER,
  UPDATE_USER_PASSWORD
} from "../actions/types";

const INITIAL_STATE = {
  admin: false,
  txAuth: false,
  txAuthenticated: false,
  sqrl: null,
  sqrlUrl: null,
  nut: null,
  requiresCert: false,
  invalidSessionPath : null,
  language: 'es',
  hideMenu: false,
  appCode: false,
  signerCode: false,
  logoutAfter:false
};

const INITIAL_STATE2 = {
  admin: false,
  txAuth: false,
  txAuthenticated: false,
  sqrl: null,
  sqrlUrl: null,
  nut: null,
  requiresCert: false,
  invalidSessionPath : null,
  hideMenu: false,
  appCode: false,
  signerCode: false,
  logoutAfter:false
};

const authReducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IS_LOGGED_IN:
      return {...state, ...action.payload.auth};
    case SIGN_UP:
      return {...state, ...action.payload.auth};
    case LOG_IN:
      return {...state, ...action.payload.auth};
    case CHECK_BASIC_AUTHENTICATION:
    case UPDATE_USER_PASSWORD:
      return {...state, ...action.payload.auth};
    case START_STRONG_AUTHENTICATION:
      return {...state, ...action.payload.auth};
    case CHECK_STRONG_AUTHENTICATION:
      return {...state, ...action.payload.auth};
    case INVALID_SESSION:
      return {...state, ...action.payload.auth};
    case CHANGE_LANGUAGE:
      return {...state, ...action.payload.auth};
    case RESET_STRONG_TX:
      return {...state, ...action.payload.auth};
    case RESET_AUTH_SIGN_PARAMS:
      return {...state, ...action.payload.auth};
    case CHECK_APP_CODE_AUTHENTICATION:
      return {...state, ...action.payload.auth};
    case HIDE_MENU:
      return {...state, hideMenu: true};
    case SHOW_MENU:
      return {...state, hideMenu: false};
    case CLEAR_APP_CODE:
      return {...state, appCode: false};
    case CLEAR_SIGNER_CODE:
      return {...state, signerCode: false};
    case LOG_OUT_AFTER:
      return {...state, logoutAfter: true};
    /*case "@@router/LOCATION_CHANGE":
      return {...state, hideMenu: false};*/
    case LOG_OUT:
      return {...state, ..._.omit(INITIAL_STATE2, ['invalidSessionPath'])};
    default:
      return state;
  }
};
export default authReducer;