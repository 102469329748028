import React from 'react';
import {connect} from 'react-redux'

// import '@ant-design/compatible/assets/index.css';


import { Modal } from "antd";

// import 'antd/lib/modal/style/css';


import { reduxForm} from "redux-form";
import SignatureOptionsForm from "../Profile/Options/SignatureOptionsForm";
import {initializeProfileOptions} from "../Helpers/utils";


class SignatureOptionsModal extends React.Component {

  componentDidMount()
  {
    initializeProfileOptions(this.props);
  }

  render() {
    return (
      (<Modal
        open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
        <SignatureOptionsForm handleSubmit={this.props.handleSubmit} onSubmit={this.props.onSubmit}
        user={this.props.user} validOperations={this.props.validOperations} language={this.props.language}
        pathname={this.props.pathname}/>
      </Modal>)
    );
  }

}

function mapStateToProps(state) {
  return {
    user: state.user,
    language:state.auth.language,
    validOperations: state.signatureFiles.validOperations,
    pathname:state.router.location.pathname
  }
}

export default connect(mapStateToProps,{})(reduxForm({form: 'signature_options_form'})(SignatureOptionsModal));
