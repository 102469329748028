import React from 'react';
import {connect} from 'react-redux';

// import '@ant-design/compatible/assets/index.css';


// import 'antd/lib/card/style/css';


// import 'antd/lib/row/style/css';


// import 'antd/lib/col/style/css';


// import 'antd/lib/alert/style/css';


// import 'antd/lib/steps/style/css';


import {formValueSelector} from "redux-form";
import modalShow from '../../actions/modal/modalShow';
import modalDismiss from '../../actions/modal/modalDismiss';
import getValidOperations from '../../actions/signature/getValidOperations';
import getUserInfo from '../../actions/profile/getUserInfo';
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import './signature.css';
import PrivateRoute from "../Root/PrivateRoute";
import getPDFTemplatesInformation from "../../actions/templates/getPDFTemplatesInformation";
import { SIGNATURE_OPTIONS_MODAL} from "../Feedback/Modal/types";
import updatePDFFeatures from "../../actions/profile/updatePDFFeatures";
import getImageTypesList from "../../actions/profile/getImageTypesList";
import getTimestampsInfo from "../../actions/profile/getTimestampsInfo";
import getPendingFilesInformation from "../../actions/files/getPendingFilesInformation";
import {push} from 'connected-react-router';
import {PENDING_FILES_ROUTE, SEND_AND_SIGN_ROUTE, SIGNATURE_OPTIONS_ROUTE} from "../Root/routes";
import _ from "lodash";
import general from "../../const/general";
import signature from "../../const/signature";
import startStrongTx from "../../actions/signature/startStrongTx";
import resetStrongTx from "../../actions/signature/resetStrongTx";
import cleanSignatureFiles from "../../actions/signature/cleanSignatureFiles";
import logOut from '../../actions/auth/logoutUser';
import templates from "../../const/templates";
import {addMetadata, addUpButton, checkMaxFileSize} from "../Helpers/utils";
import redirect from "../../actions/auth/redirect";
import signPendingFiles from "../../actions/files/signPendingFiles";
import putFiles from "../../actions/sendFiles/putFiles";
import clearFiles from "../../actions/files/clearFiles";
import '../../resources/css/steps.css'
import getAllMetadata from "../../actions/admin/getAllMetadata";

class Signature extends React.Component {
  state = {current: 0, formFile: [], txAuthenticated: false,parentId:null};

  componentWillUnmount()
  {
    if(this.props.location.pathname===SIGNATURE_OPTIONS_ROUTE)
      this.props.clearFiles();
  }

  componentDidMount()
  {
    if(!this.props.signatureFiles.txCallback && this.props.auth.txAuth && !this.props.auth.txAuthenticated)
	  this.handleTx();
    else
	{
      this.props.getAllMetadata();
      this.props.cleanSignatureFiles();
      this.props.getUserInfo().then(this.getPendingFiles);
    }
  };

  checkFile=(data)=>
  {
    const uid=this.props.queryParams.uniqueID;
    if(!data.pendingFileList.entries || data.pendingFileList.entries.length===0)
    {
      console.log("Invalid uid, will log out");
      this.props.logOut(false,false);
      return;
    }
    if(uid)
    {
      const uids=uid.split(',');
      const file = _.filter(data.pendingFileList.entries, file => uids.includes(file.uid) );
      if(!file.length)
      {
        console.log("uid not found, will log out: " + uid );
        this.props.logOut(false,false);
        return;
      }
      this.props.getValidOperations().then(()=>this.props.signPendingFiles(file).then((result)=>{
        if(result)
          this.props.redirect(SEND_AND_SIGN_ROUTE);
      }));
    }
  }

  getPendingFiles=()=>
  {
    const template=this.props.queryParams.selectedTemplate;
    const uid=this.props.queryParams.uniqueID, userName=this.props.queryParams.userName;
    if((uid || template) && userName && this.props.user && userName!==this.props.user.userName)
    {
      console.log("uid not for logged user, will log out: " + uid );
      this.props.logOut(false,false);
      return;
    }

    if(uid)
    {
      this.props.getPendingFilesInformation({uids:uid},this.checkFile,[()=>this.props.redirect(PENDING_FILES_ROUTE)]);
    }
    else if(template)
        this.props.getValidOperations().then(()=>{
          console.log(this.props.metadata);
          const id=template.replace('%20', ' ').replace('%25', '&');
          addMetadata([{id:id}],(files)=>{
            this.props.putFiles({withTemplate:true,templateIds:files},[],'',true);
          },this.props.metadata,this.props.modalShow,this.props.language,this.props.modalDismiss,this.props.dispatch);
        });
  };

  getPDFTemplatesInformation=(parentId,afterCallback=null)=>
  {
    this.setState({parentId:parentId},()=>this.props.getPDFTemplatesInformation({parentId: this.state.parentId}).then(()=>afterCallback?afterCallback():''));
  };

  handleTx = () => {
    if(!this.props.signatureFiles.txCallback && this.props.auth.txAuth && !this.props.auth.txAuthenticated && !this.state.txAuthenticated){
      this.setState({txAuthenticated: true}, () => this.props.startStrongTx('SIMPLE'));
    } else if (!this.props.signatureFiles.txCallback && this.props.auth.txAuth && this.props.auth.txAuthenticated && !this.state.txAuthenticated) {
      this.setState({txAuthenticated: true}, this.props.resetStrongTx);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.handleTx();
  };

  onSubmit = (formValues,ids) => {
    if(this.props.selectedOption === 'loadFile' &&
        !checkMaxFileSize(this.props.user.maxFileSize,this.props.language,this.props.dispatch,this.state.formFile))
      return;
    let files=this.state.formFile;
    if(this.props.selectedOption ==='useTemplate')
    {
      if(!ids || ids.length<1)
      {
        this.props.dispatch({type: 'INFO_SHOW', payload: {info: {message: templates[this.props.language].selectTemplate,type: 'error'}}});
        return;
      }
      formValues.withTemplate=true;
      formValues.templateIds=ids;
      files=[];
    }
    this.props.putFiles(formValues,files,'',true);
  };

  openOptionsModal = () => {
    this.props.modalShow({
      modalType: SIGNATURE_OPTIONS_MODAL,
      modalProps: {footer: null, title: signature[this.props.language].edit_options},
      componentProps: {btn: general[this.props.language].edit, onSubmit: this.props.updatePDFFeatures}
    });
  };

  handleOnDropFile = (files, onChange) => {
    this.setState({formFile: files}, () => onChange(files));
  };

  steps() {
    return (
        [{
          title: signature[this.props.language].options_step,
          content: <SecondStep initialValues={this.props.initialValues} language={this.props.language} next={this.next}/>,
        }, {
          title: signature[this.props.language].selection_step,
          content: <ThirdStep parentId={this.props.parentId} getPDFTemplatesInformation={this.getPDFTemplatesInformation} language={this.props.language} templates={addUpButton(this.state.parentId,this.props.parentId,this.props.templates)} initialValues={this.props.initialValues} prev={this.prev} handleOnDropFile={this.handleOnDropFile} formFile={this.state.formFile} onSubmit={this.onSubmit} selectedOption={this.props.selectedOption}/>,
        }]
    );
  }

  next = () => {

    const current = this.state.current + 1;
    if(current===1 && this.props.selectedOption==='useTemplate')
      this.getPDFTemplatesInformation(null,()=>this.setState({current}));
    else
      this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  render() {
    return (<>
    </>)
    /*<Card bordered={false} className={'signature-wizard'}>
      <div className={'title-info'}>
        <h2 className={'title'}>{signature[this.props.language].title}</h2>
      </div>
      <Form>
        <Row>
          <Steps current={this.state.current}>
            {this.steps().map(item => <Steps.Step key={item.title} title={item.title} />)}
          </Steps>
          <div className="steps-content">
            {this.steps()[this.state.current].content}
          </div>
        </Row>
      </Form>
    </Card>*/;
  }
}

const selector = formValueSelector('signature_form');
const mapStateToProps = state => {
  let queryParams = state.router.location.search ? _.reduce(state.router.location.search.substring(1).split("&"),
      (result, value) => {
        let params = value.split("=");
        result[params[0]] = params[1];
        return result;
      }, {}) : {};
    queryParams.redirect=SIGNATURE_OPTIONS_ROUTE;
    return {
    signatureFiles: state.signatureFiles,
      location:state.router.location,
    auth: state.auth,
    user:state.user,
    language: state.auth.language,
    queryParams: queryParams,
	pendingFiles: state.pendingFileList.entries,
    validOperations: state.signatureFiles.validOperations,
    templates: state.templateList.entries,
    parentId: state.templateList.parentId,
    hasEmailValue: selector(state, 'copy'),
    selectedOption: selector(state, 'option'),
    selectedOperation: selector(state, 'operation'),
    prePdf: selector(state, 'pre'),
    initialValues: {
      option:'loadFile'
    },
      metadata: state.reportList.entries
  };
};

export default PrivateRoute(connect(mapStateToProps, {putFiles,logOut,signPendingFiles, cleanSignatureFiles,
  resetStrongTx, startStrongTx,getPDFTemplatesInformation, modalShow, getUserInfo,
  getValidOperations, updatePDFFeatures, getImageTypesList, getTimestampsInfo,
  getPendingFilesInformation, push,clearFiles, redirect,getAllMetadata, modalDismiss})(Signature));
