import netcoServer from '../../apis/netcoServer';
import {
  GET_PDF_TEMPLATE_FIELDS,
  INFO_SHOW,
  LOAD_END, LOAD_START,
  SIGN_PENDING_FILES
} from "../types";
import { push } from "connected-react-router";
import {
  REDIRECT_DOCS_ROUTE,
  SIGNATURE_FIELDS_ROUTE,
  SIGNATURE_SIGN_ROUTE
} from "../../components/Root/routes";
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import { checkPDFPassword, isPDF, isPDFExtension } from "../../components/Helpers/utils";
import getBase64File from "../helpers/getBase64File";
import getPendingFile from "./getPendingFile";
import getPendingFileInformation from "./getPendingFileInformation";
import sign from "./sign";
import loadingCall from "../helpers/loadingCall";
import invalidSession from '../auth/invalidSession';
import logoutUser from '../auth/logoutUser';
import getDocumentMetadata from './getDocumentMetadata';

const signPendingFiles = (pendingFiles, callback = null) => async (dispatch, getState) => {
  dispatch({ type: LOAD_START });
  let payload = infoPayload('success', null);
  let previewFiles = 0;
  let filesList = [];
  let signedUIDS = [];
  let response, formData;
  if (!pendingFiles) {
    dispatch({ type: LOAD_END });
    return null;
  }
  for (let i = 0; i < pendingFiles.length && payload.info.type === 'success'; i++) {
    const fileName = pendingFiles[i].name;
    const currentUID = pendingFiles[i].uid;
    if (!fileName || !currentUID) {
      console.log("Invalid pending file: ", pendingFiles[i]);
      dispatch({ type: INFO_SHOW, payload: infoPayload('error', 'Archivo inválido') });
      dispatch({ type: LOAD_END });
      return;
    }
    if (pendingFiles[i].pdfInfo && pendingFiles[i].pdfInfo.isEncrypted && (!pendingFiles[i].pdfInfo.pages || !pendingFiles[i].password)) {
      const pendingFile = pendingFiles[i];
      const content = await dispatch(getPendingFile(pendingFile.uid, true));
      let password = null;
      try {
        password = await checkPDFPassword(content, pendingFile.name, '', dispatch, getState().auth.language);
      }
      catch (err) {
        dispatch({ type: INFO_SHOW, payload: err });
        return;
      }
      let passwords = {};
      passwords[pendingFile.name] = password;
      pendingFile.password = passwords;
      const updatedFile = await dispatch(getPendingFileInformation({ uids: pendingFile.uid, passwords: JSON.stringify(passwords) }));
      if (!updatedFile)
        return;
      pendingFile.pdfInfo = updatedFile.pdfInfo;
    }
    if (getState().user.fillpdf) {
      if (pendingFiles[i].pdfInfo && pendingFiles[i].pdfInfo.fields && pendingFiles[i].pdfInfo.fields.length > 0 && !pendingFiles[i].filled) {
        const updatedPendingFiles = pendingFiles.filter((pendingFile) => !signedUIDS.includes(pendingFile.uid));
        let payload = {
          signatureFiles: {
            fields: pendingFiles[i].pdfInfo.fields,
            isSigned: pendingFiles[i].pdfInfo.isSigned,
            returnPending: true,
            selectedTemplateName: fileName,
            currentFileUID: currentUID,
            pending: updatedPendingFiles,
            callback: callback
          }
        };
        dispatch({ type: GET_PDF_TEMPLATE_FIELDS, payload: payload });
        dispatch(push(SIGNATURE_FIELDS_ROUTE));
        dispatch({ type: LOAD_END });
        return;
      }
    }
    const requiresConversion = isPDFExtension(fileName, getState);
    if ((isPDF(fileName) || requiresConversion) && !getState().user.p7z && getState().user.previewPDF) {
      let file = { uid: currentUID, password: pendingFiles[i].password, optMessage: pendingFiles[i].optMessage };
      file.content = pendingFiles[i].content;
      let base64 = null;
      let gotFile = false;
      if (file.content)
        gotFile = true;
      else {
        response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getPendingFile ', { uniqueID: currentUID, returnBase64File: true }));
        if (response === undefined)
          payload = infoPayload('error', 'Error al comunicarse con el servidor, intenta de nuevo mas tarde');
        else if (response && response.data && response.data.code === 440) {
          dispatch(invalidSession());
          dispatch(logoutUser(null, false));
          return;
        }
        else {
          if (response.data.success) {
            base64 = response.data.base64File;
            gotFile = true;
          }
          else {
            payload = infoPayload('error', response.data.detail);
            dispatch({ type: INFO_SHOW, payload: payload });
            dispatch({ type: LOAD_END });
            return response.data.detail;
          }

        }
      }
      if (gotFile) {
        if (requiresConversion && !isPDF(fileName)) {
          let formDataConvert = {
            base64File: base64 ? base64 : await getBase64File(file.content),
            fileName: pendingFiles[i].name,
            returnBase64File: true
          };
          response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/convertToPDFA', formDataConvert));
          if (response.data.success) {
            file.name = pendingFiles[i].name;
            if (!isPDF(file.name))
              file.name = file.name + '.pdf';
            file.pdfInfo = response.data.info;
            base64 = response.data.base64File;
          }
          else {
            payload = infoPayload('error', response.data.detail);
            continue;
          }
        }
       
        else {
          file.name = pendingFiles[i].name;
          file.pdfInfo = pendingFiles[i].pdfInfo;
        }
        if (base64) {
          let binaryString = window.atob(base64);
          let binaryLen = binaryString.length;
          let ab = new ArrayBuffer(binaryLen);
          let ia = new Uint8Array(ab);
          for (let j = 0; j < binaryLen; j++) {
            ia[j] = binaryString.charCodeAt(j);
          }
          file.content = new Blob([ab]);
        }
        file.metadata = await dispatch(getDocumentMetadata(file.uid));
        filesList.push(file);
        previewFiles++;
      }
    }
    else {
      formData = {
        signatureType: getState().user.useImage,
        uids: currentUID,
        fileName: pendingFiles[i].name,
        passwords: JSON.stringify(pendingFiles[i].password),
        save: true
      };
      signedUIDS.push(currentUID);
      let response = await dispatch(sign(formData));
      if (response.data.success)
        payload = infoPayload('success', 'Archivos firmados satisfactoriamente!');
      else
        payload = infoPayload('error', response.data.detail);
    }
  }
  if (payload.info.type === 'success' && previewFiles > 0) {

    payload.signatureFiles = {
      total: filesList.length,
      entries: filesList,
      signatureType: getState().user.useImage,
      save: true,
      validOperations: getState().signatureFiles.validOperations,
      hideMenu: getState().signatureFiles.hideMenu
    };
    dispatch({ type: SIGN_PENDING_FILES, payload: payload });
    dispatch(push(SIGNATURE_SIGN_ROUTE));
    return;

  } else if (payload.info.type === 'success' && previewFiles === 0) {
    if (getState().auth.logoutAfter)
      this.props.logoutUser(false, true, false);
    dispatch({ type: INFO_SHOW, payload: payload });
    dispatch(push(REDIRECT_DOCS_ROUTE + '?active=' + (getState().user.publish === "true" ? 'public' : 'private')));
    if (callback)
      callback();
  } else {
    dispatch({ type: INFO_SHOW, payload: payload });
  }
  dispatch({ type: LOAD_END });
};
export default signPendingFiles;