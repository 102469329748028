import netcoServer from '../../apis/netcoServer';
import {INFO_SHOW} from "../types";
import {ROOT_ROUTE} from "../../components/Root/routes";
import handleResponse from '../helpers/handleResponse';
import infoPayload from '../helpers/payloadHandlers/infoPayload';
import loadingCall from "../helpers/loadingCall";
import certificates from "../../const/certificates";
import getBase64File from "../helpers/getBase64File";
import {checkMaxFileSize} from "../../components/Helpers/utils";
import {hsm} from "../../resources/files/hsm";

const requestCertificateAndes= (formValues) => async (dispatch,getState) => {
  const onSuccessPayload = () => {
	return infoPayload('success', certificates[getState().auth.language].processcsrok);
  };
    const base64Files=[],fileNames=[];
    if(formValues.publicLetterFile)
    {
        base64Files.push(await getBase64File(formValues.publicLetterFile.file));
        fileNames.push(formValues.publicLetterFile.file.name);
    }
    if(formValues.idFile)
    {
        base64Files.push(await getBase64File(formValues.idFile.file));
        fileNames.push(formValues.idFile.file.name);
    }
    if(formValues.rutFile)
    {
        base64Files.push(await getBase64File(formValues.rutFile.file));
        fileNames.push(formValues.rutFile.file.name);
    }
    if(formValues.certLetterFile)
    {
        base64Files.push(await getBase64File(formValues.certLetterFile.file));
        fileNames.push(formValues.certLetterFile.file.name);
    }
    if(formValues.tradeLetterFile)
    {
        base64Files.push(await getBase64File(formValues.tradeLetterFile.file));
        fileNames.push(formValues.tradeLetterFile.file.name);
    }

    if(getState().payment.tokenInfo && formValues.netcoID!=='13')
    {
        base64Files.push(hsm);
        fileNames.push("Certificación HSM.pdf");
    }
    if(!checkMaxFileSize(getState().user.maxFileSize,getState().auth.language,dispatch,base64Files))
        return;
    formValues.base64Files=base64Files;
    formValues.fileNames=fileNames;
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/requestCertificateAndes', formValues));
  handleResponse(dispatch, response, INFO_SHOW, onSuccessPayload, ROOT_ROUTE);
};
export default requestCertificateAndes;