import React from 'react';
import TabsHelper from "../Helpers/TabsHelper";
import {connect} from "react-redux";
import PrivateRoute from "../Root/PrivateRoute";
import './buy.css';
import admin from "../../const/admin";
import BuyOperations from "../BuyOperations";
import CheckPayment from "../CheckPayment";
import {ACTIVATE_ROUTE, CHECK_ECOM_ROUTE, ECOM_ROUTE, SUBSCRIPTION_ROUTE} from "../Root/routes";
import {replace} from "connected-react-router";
import Activate from "../Activate";
import Subscription from "../Subscription";

class Buy extends React.Component
{
    updateProduct=(tab)=>
    {
        if(tab==='check')
            this.props.dispatch(replace(CHECK_ECOM_ROUTE));
        if(tab==='buy')
            this.props.dispatch(replace(ECOM_ROUTE));
        if(tab==='activate')
            this.props.dispatch(replace(ACTIVATE_ROUTE));
        if(tab==='subscription')
            this.props.dispatch(replace(SUBSCRIPTION_ROUTE));
    };

    tabProps(){
    return (
        {
            number: 4,
            onChange:(tab) => {this.updateProduct(tab)},
            titles:[
                admin[this.props.language].buy_activatetitle,
                admin[this.props.language].buy_title,
                admin[this.props.language].check_title,
                admin[this.props.language].buy_subscriptionstitle,
            ],
            keys:[
                'activate',
                'buy',
                'check',
                'subscription'
            ],
            content: [
                Activate,
                BuyOperations,
                CheckPayment,
                Subscription
            ],
            tabProps: [
                {language: this.props.language, reload:true,active:this.props.active},
                {language: this.props.language, reload:true,active:this.props.active},
                {language: this.props.language, reload:true,active:this.props.active},
                {language: this.props.language, reload:true,active:this.props.active},
            ]
        }
    );
  };

  render()
  {
     return (
        <div className="card-container h-100 p-20 tabs-container bigContainer">
          <TabsHelper  activeKey={this.props.active} {...this.tabProps()}/>
        </div>
    );
  };

}

const mapStateToProps = state => {
    return {
    language: state.auth.language
  };
};

export default PrivateRoute(connect(mapStateToProps, {})(Buy));
