import netcoServer from '../../apis/netcoServer';
import {UPDATE_PROFILE} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import modalDismiss from "../modal/modalDismiss";
import _ from "lodash";
import loadingCall from "../helpers/loadingCall";
import admin from '../../const/admin';

const updateUser= (formValues, callback) => async (dispatch) => {
  let language=formValues.language;
  const onSuccessPayload = () => {
  return infoPayload('success', admin[language].processok);
  };  
  let formData = _.omit(formValues, ['lastLogin','language']);
  formData.locked = getStatus(formValues.userStatus);  
  if(isNaN(formData.profileDescription))
	formData.profileDescription=formData.profileID;
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/updateUser', formData));
  handleResponse(dispatch, response, UPDATE_PROFILE, onSuccessPayload, null, [modalDismiss], [callback]);
};
export default updateUser;


const getStatus = status => {
  switch (status) {
    case 'Bloqueado':
      return true;
    case 'Activo':
      return false;
    default:
      return null;
  }
};