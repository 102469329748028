import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form, Button, Col, Row, Select, Typography } from "antd";

// import 'antd/lib/modal/style/css';


import { Field, reduxForm } from "redux-form";
import {
  CreateCheckbox,
  CreatePasswordField,
  CreateSelectField,
  CreateTextField
} from "../../Helpers/Input/InputCreator";
import { required } from "../../Helpers/Input/validations";
import _ from 'lodash';
import { numberNormalizer } from "../../Helpers/Input/normalizers";
import admin from "../../../const/admin";

class SetParam extends React.Component {

  componentDidMount() {
    this.props.initialize(_.pick(this.props.entry, ['name', 'value']));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entry !== this.props.entry) {
      this.props.initialize(_.pick(this.props.entry, ['name', 'value']));
    }
  }

  renderField() {
    if (this.props.entry.type === 0) {
      return (
        <Field 
          name="value"
          size={'large'}
          className={'mb-10'}
          component={CreateSelectField}
          placeholder={admin[this.props.language].value_column}
          validate={this.props.entry.required ? [required] : []}
          hasFeedback>
          {this.props.entry.values.map(item => <Select.Option value={item}>{item}</Select.Option>)}
        </Field>
      );
    } else if (this.props.entry.type === 1) {
      return (
        <Field 
          name="value"
          size={'large'}
          className={'mb-10'}
          component={CreateTextField}
          placeholder={admin[this.props.language].value_column}
          validate={this.props.entry.required ? [required] : []}
          hasFeedback />
      );
    } else if (this.props.entry.type === 2) {
      return (
        <Field 
          name="value"
          size={'large'}
          className={'mb-10'}
          component={CreateCheckbox}
          placeholder={admin[this.props.language].value_column}
          hasFeedback />
      );
    } else if (this.props.entry.type === 3) {
      return (
        <Field 
          name="value"
          size={'large'}
          className={'mb-10'}
          component={CreateTextField}
          placeholder={admin[this.props.language].value_column}
          validate={this.props.entry.required ? [required] : []}
          normalize={numberNormalizer}
          hasFeedback />
      );
    } else if (this.props.entry.type === 4) {
      return (
        <Field 
          name="value"
          size={'large'}
          className={'mb-10'}
          component={CreatePasswordField}
          placeholder={admin[this.props.language].value_column}
          validate={this.props.entry.required ? [required] : []}
          hasFeedback />
      );
    }
  }

  render() {
    return (
      <Form onFinish={this.props.handleSubmit(this.props.onSubmit)} style={{ marginLeft: 30, marginRight:30 }} >
        <Typography className='text-title color-black size18' style={{ marginTop: 18, marginBottom: 12 }}>Parametro</Typography>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Typography className='size12 text-title'>{admin[this.props.language].name_column}</Typography>
            <Field
              name="name"
              size={'large'}
              className={'mb-10'}
              component={CreateTextField}
              disabled={true}
              placeholder={admin[this.props.language].name_column}
              hasFeedback />
          </Col>
          <Col xs={24} md={12}>
            <Typography className='size12 text-title'>{admin[this.props.language].value_column}</Typography>
            {this.renderField()}
          </Col>
        </Row>
        <Row>
          <Button className={'float-right'} htmlType={'submit'} type={'primary'} style={{ marginTop: 20 }} >{this.props.btn.toUpperCase()}</Button>
        </Row>
      </Form>
    )
  }

}

export default reduxForm({ form: 'admin_configurations_form' })(SetParam);
