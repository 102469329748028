import netcoServer from '../../apis/netcoServer';
import handleBase64FileResponse from "../helpers/handleBase64ZipResponse";
import loadingCall from "../helpers/loadingCall";
import { INFO_SHOW } from "../types";
import invalidSession from '../auth/invalidSession';
import logoutUser from '../auth/logoutUser';

const getPendingFile = (uid, returnFile = false) => async (dispatch) => {
  let formData = { uniqueID: uid };
  formData.returnBase64File = true;
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getPendingFile', formData));
  if (response && response.data && response.data.success) {
    if (returnFile)
      return response.data.base64File;
    else {
      handleBase64FileResponse(response.data.base64File, response.data.fileName);
      return null;
    }
  }
  else {

    let message = "Error al comunicarse con el servidor, intenta de nuevo mas tarde";
    if (response && response.data && response.data.detail)
      message = response.data.detail;
    if (response && response.data && response.data.code === 440) {
      dispatch(invalidSession());
      dispatch(logoutUser(null, false));
      return;
    }
    dispatch({
      type: INFO_SHOW,
      payload: {
        info: {
          message: message,
          type: 'error'
        }
      }
    });
    return null;
  }
};
export default getPendingFile;
