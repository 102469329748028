import React from 'react';
import { Modal } from "antd";

// import 'antd/lib/modal/style/css';


import Handwritten from './Handwritten';
import { connect } from 'react-redux';
import { showFull } from '../Helpers/utils';

const HandwrittenModal = (props) => {
    const height = props.height || props.heightApp
    const width = props.width || props.widthApp
    const showFullValue=showFull();
    return (
        <Modal bodyStyle={{ padding: 0, textAlign: 'center' }} style={showFullValue?{top:'5%'}:{top:'1%',paddingBottom:0}} open={props.modalType !== null}
            height={height} width={width} isModal={true} {...props.modalProps}>
            <Handwritten {...props} width={width-20} />
        </Modal>
    );
}


const mapStateToProps = state => {
    return {
        widthApp: state.locate.dimensionsApp.width,
        heightApp: state.locate.dimensionsApp.height,
    }
}

export default connect(mapStateToProps)(HandwrittenModal);
