import netcoServer from '../../apis/netcoServer';
import {INCREASE_PENDING_FILES_COUNT, INFO_SHOW} from "../types";
import _ from 'lodash';
import loadingCall from "../helpers/loadingCall";
import {push} from "connected-react-router";
import invalidSession from "../auth/invalidSession";
import logoutUser from "../auth/logoutUser";
import signPendingFiles from "./signPendingFiles";
import {addDocumentMetadata} from "../../components/Helpers/utils";

const resignFiles= (selectedFiles, callback = null, metadata=null) => async (dispatch) => {
  let formData = {
    uids: _.join(selectedFiles, ','),
    sendConfirmation: false
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/putFilesUID', formData));
  if (response === undefined) {
    dispatch({type: INFO_SHOW,
      payload: {
        info: {
          message: 'Error al comunicarse con el servidor, intenta de nuevo mas tarde',
          type: 'error'
        }
    }});
  } else if (response.data.success) {
    selectedFiles.map(()=>dispatch({type: INCREASE_PENDING_FILES_COUNT}));
    if(metadata && metadata.length>0)
    {
      const filesInfo=response.data.result.filesInfo;
      for(var i=0;i<filesInfo.length;i++)
      {
        const uid=filesInfo[i].uid;
        for(var j=0;j<metadata.length;j++)
        {
          await addDocumentMetadata(metadata[j],uid,dispatch);
          /*const metaInfo=metadata[j];
          const data={uid};
          data.idmetadata=metaInfo.id;
          if(metaInfo.metadataID)
            data.idmetadataoptions=metaInfo.metadataID;
          data.value=metaInfo.value;
          const metadataResponse = await loadingCall(dispatch,()=>netcoServer.newPost('/UserService/addDocumentMetadata', data));
          if(!metadataResponse || !metadataResponse.data || !metadataResponse.data.success)
            console.error("Error agregando metadatos: " + metadataResponse);*/
        }
      }
    }

    dispatch(signPendingFiles(response.data.result.filesInfo, callback));
  } else if (!response.data.success) {
    if(response.data.code === 440){
      dispatch(invalidSession());
      dispatch(logoutUser());
      dispatch(push('/'));
    } else {
      dispatch({type: INFO_SHOW,
        payload: {
          info: {
            message: response.data.detail,
            type: 'error'
          }
        }});
    }
  }
};
export default resignFiles;