import netcoServer from '../../apis/netcoServer';
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";
import modalDismiss from "../modal/modalDismiss";


const addMetadataOptions = (formValues, callback) => async (dispatch) => {
    let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/addMetadataOptions', formValues));
    handleResponse(dispatch, response, null, null, null, [modalDismiss], [callback]);
}
export default addMetadataOptions;
