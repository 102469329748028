import netcoServer from '../../apis/netcoServer';
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import loadingCall from "../helpers/loadingCall";
import modalDismiss from "../modal/modalDismiss";


const updateMetadataOptions = (formValues, callback) => async (dispatch) => {

  const onSuccessPayload = () => {
    return infoPayload('success', 'ok');
  };

  const optionsToChange = formValues.values;
  if (optionsToChange) {
    const options = Object.entries(optionsToChange);
    options.forEach(async ([key, value], index) => {
      const NewFormValues = {
        id: key,
        value: value,
        metadataID: formValues.metadataID
      };

      let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/updateMetadataOptions', NewFormValues));
      const finalIteration = index === options.length - 1;
      handleResponse(dispatch, response, null, finalIteration?onSuccessPayload:null, null, finalIteration?[modalDismiss]:[], finalIteration?[callback]:[]);
    });
  }

};
export default updateMetadataOptions;
