import netcoServer from '../../apis/netcoServer';
import {
  CLEAR_APP_CODE,
  DECREASE_PENDING_FILES_COUNT,
  CLEAR_SIGNER_CODE
} from "../types";
import {reset} from "redux-form";
import loadingCall from "../helpers/loadingCall";

const sign= (formData) => async (dispatch, getState) =>
{
  if(getState().auth.txAuth)
    formData = {...formData, ...getState().signatureFiles.txValues};
  let response = await loadingCall(dispatch,()=>netcoServer.newPost('/SignService/signPendingFiles', formData));
  //let response = await netcoServer.newPost('/SignService/signPendingFiles', formData);
  if(response.data.success)
  {
    dispatch({type: DECREASE_PENDING_FILES_COUNT});
    dispatch({type: CLEAR_APP_CODE});
    dispatch({type: CLEAR_SIGNER_CODE});
    dispatch(reset('signature_form'));
  }
  return response;
};
export default sign;