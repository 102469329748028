import netcoServer from '../../apis/netcoServer';
import {GET_SUBSCRIPTION_INFO} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const getSubscriptionPlans= () => async (dispatch) => {
  const onSuccessPayload = data => {
    return {result:{plans:data.result}};
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/getSubscriptionPlans  ', {}));
  handleResponse(dispatch, response, GET_SUBSCRIPTION_INFO, onSuccessPayload, null, [],[],[],[]);
};
export default getSubscriptionPlans;
