import netcoServer from '../../apis/netcoServer';
import loadingCall from "../helpers/loadingCall";
import { INFO_SHOW } from "../types";
import invalidSession from '../auth/invalidSession';
import logoutUser from '../auth/logoutUser';

const getDocumentMetadata = (uid) => async (dispatch) => {
    let formData = { uid };
    let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getDocumentMetadata', formData));
    if (response && response.data && response.data.success) {
        return response.data.result;
    }
    else {
        let message = "Error al comunicarse con el servidor, intenta de nuevo mas tarde";
        if (response && response.data && response.data.detail)
            message = response.data.detail;
        if (response && response.data && response.data.code === 440) {
            dispatch(invalidSession());
            dispatch(logoutUser(null, false));
            return;
        }
        dispatch({
            type: INFO_SHOW,
            payload: {
                info: {
                    message: message,
                    type: 'error'
                }
            }
        });
        return null;
    }
};
export default getDocumentMetadata;
