import React from 'react';
import {Field} from 'redux-form';
import {CreateTextField} from "../../Helpers/Input/InputCreator";

// import '@ant-design/compatible/assets/index.css';


import {Form, Button, Typography} from 'antd';

// import 'antd/lib/button/style/css';


// import 'antd/lib/form/style/css';


import auth from "../../../const/auth";
import {push} from "connected-react-router";

const AuthSingleFieldForm = (props) => {

  const renderResend = () => {
    if(props.showResend)
      return (
        <Form.Item className={'text-center'}>
          <Button type="primary dark-blue rounded" block htmlType="button" onClick={props.startStrong}>
              {auth[props.language].resendotpbtn}
          </Button>
        </Form.Item>
      );
  };

  return (
    <Form  className={'regularForm'} onFinish={props.handleSubmit(props.onSubmit)}>
      <Field name={props.name}
             prefix={props.prefix}
             suffix={props.suffix}
             component={CreateTextField}
             placeholder={props.placeholder}
             size={'large'}
             className={'mb-10'}

             normalize={props.normalizer}
             hasFeedback/>
      <Form.Item className={'text-center'}>
        <Button type="primary light-primary rounded" htmlType="submit">
          {props.buttonTxt === undefined ? auth[props.language].sendbtn : props.buttonTxt}
        </Button>
      </Form.Item>
      {renderResend()}
      {/*<Form.Item className={'text-center'}>
        <Button type="ghost rounded" block htmlType="button" onClick={() => props.push('/')}>
			{auth[props.language].backbtn}
        </Button>
      </Form.Item>*/}
        <div className='center' style={{ display: 'flex', width: '261px', marginTop: 15 }}>
            <Typography className='size15 color-black'>
                {auth[props.language].signinlbl}</Typography>
            <Typography className={'size15 color-black text-link'} onClick={() => props.dispatch(push('/'))} style={{ paddingLeft: '2px' }}>
                {auth[props.language].signinbtn}</Typography>
        </div>
    </Form>
  );
};

export default AuthSingleFieldForm
