import netcoServer from '../../apis/netcoServer';
import {INFO_SHOW} from "../types";
import loadingCall from "../helpers/loadingCall";
import invalidSession from "../auth/invalidSession";
import logoutUser from "../auth/logoutUser";
import {push} from "connected-react-router";
import {REDIRECT_DOCS_ROUTE} from "../../components/Root/routes";

const getPDFPasswordInformation= (uid,passwords,callback,{numPages}) => async (dispatch,getState) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getPendingFilesInformation', {uids:uid,passwords:JSON.stringify(passwords)}));
  if (response === undefined)
  {
    dispatch({type: INFO_SHOW,payload: { info: {  message: 'Error al comunicarse con el servidor, intenta de nuevo mas tarde', type: 'error' } }});
    dispatch(push(REDIRECT_DOCS_ROUTE + '?active=' + (getState().user.publish=== "true"?'public':'private')));
  }
  else if (response.data.success)
  {
    callback(response.data.filesInfo.pendingFilesInfo[0].pdfInfo,{numPages});
  }
  else if (!response.data.success)
  {
    if (response.data.code === 440)
    {
      dispatch(invalidSession());
      dispatch(logoutUser(null, false));
      dispatch(push('/'));
      return;
    }
    let message = response.data.detail;
    if (message === "")
      message = response.data.code;
    dispatch({type: INFO_SHOW,payload: { info: {  message: message, type: 'error' } }});
    dispatch(push(REDIRECT_DOCS_ROUTE + '?active=' + (getState().user.publish=== "true"?'public':'private')));
  }
}
export default getPDFPasswordInformation;