import netcoServer from '../../apis/netcoServer';
import { GET_SYSTEM_CONFIGURATION } from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const getSystemInformation = (after) => async (dispatch) => {
  const onSuccessPayload = data => {
    after(data.result);
    return {
      reportList: {
        systemReport: data.result
      }
    };
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/getSystemInformation', {}));
  handleResponse(dispatch, response, GET_SYSTEM_CONFIGURATION, onSuccessPayload);
};
export default getSystemInformation;

