import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button, Card, Col, Row } from "antd";
import {CreateCheckbox, CreateTextField} from "../../Helpers/Input/InputCreator";
import {required} from "../../Helpers/Input/validations";
import {Field, reduxForm} from "redux-form";

// import 'antd/lib/card/style/css';


// import 'antd/lib/alert/style/css';


import {connect} from "react-redux";
import importCertificateEntrustCodes from "../../../actions/certificates/importCertificateEntrustCodes";
import TitleAndInfo from '../../Helpers/TitleAndInfo';
import PrivateRoute from "../../Root/PrivateRoute";
import certificates from "../../../const/certificates";
import general from "../../../const/general";

const EntrustSM = (props) => {

  const onSubmit = (formValues) => {
    formValues.language=props.language;
	formValues.requiresCert=props.requiresCert;
	props.importCertificateEntrustCodes(formValues);
  };

  return (
    <Card bordered={false}>
      <TitleAndInfo title={'Entrust SM'} info={certificates[props.language].entrust_sm_info}/>
      <Form onFinish={props.handleSubmit(onSubmit)} className={'p-20'}>
        <Row gutter={16}>
          <Col span={12}>
            <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[props.language].reference_number}</div>
            <Field
                   name="referenceNumber"
                   component={CreateTextField}
                   placeholder={certificates[props.language].reference_number}
                   validate={[required]}
                   hasFeedback/>
          </Col>
          <Col span={12}>
            <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[props.language].authorization_code}</div>
            <Field
                   name="authCode"
                   component={CreateTextField}
                   placeholder={certificates[props.language].authorization_code}
                   validate={[required]}
                   hasFeedback/>
          </Col>
          <Col span={12}>
            <Field name="agree"
                   type={'checkbox'}
                   component={CreateCheckbox}
                   validate={required}
                   hasFeedback>
              <a rel='noopener noreferrer' target={'_blank'} href={window.netcourlid + '/comagre/AcuerdoDeComunicaciones.pdf'}>
                {general[props.language].agree}
              </a>
            </Field>
          </Col>
          <Col span={12}>
            <Field name="updateGraphicImage"
                   type={'checkbox'}
                   component={CreateCheckbox}
                   hasFeedback>
              {certificates[props.language].graphic_image}
            </Field>
          </Col>
        </Row>
        <Row className={'flexRight'} gutter={16}>
          <Button htmlType={'submit'} className={'float-right'} type={'primary light-primary rounded-sm'}>
            {general[props.language].create}
          </Button>
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    language: state.auth.language,
	requiresCert: state.auth.requiresCert,
    initialValues: {
      agree: false,
      updateGraphicImage: false
    }
  }
};

export default PrivateRoute(connect(mapStateToProps, {importCertificateEntrustCodes})((reduxForm({
  form: 'entrustSmCertificate_form'
})(EntrustSM))));
