import {
  ADD_WORKFLOW_OPTIONS,
  CLEAR_WORKFLOW_DETAILS_INFORMATION,
  GET_WORKFLOW_DETAILS_INFORMATION,
  GET_WORKFLOW_FILES_INFORMATION, GET_WORKFLOW_OPTIONS, REMOVE_WORKFLOW_OPTIONS
} from "../actions/types";

const INITIAL_STATE = {
  entries: [],
  total: 0,
  workflowOptions:[]
};

const workflowFileList= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_WORKFLOW_FILES_INFORMATION:
      return {...state, ...action.payload.workflowList};
    case GET_WORKFLOW_DETAILS_INFORMATION:
      return {...state, ...action.payload.workflowList};
    case CLEAR_WORKFLOW_DETAILS_INFORMATION:
      return {...state, ...action.payload.workflowList};
    case ADD_WORKFLOW_OPTIONS:
      return {...state, ...action.payload.workflowList};
    case GET_WORKFLOW_OPTIONS:
      //return {...state, workflowOptions: [...state.workflowOptions, ...action.payload.workflowList.workflowOptions]};
      return {...state, ...action.payload.workflowList};
    case REMOVE_WORKFLOW_OPTIONS:
      return {...state, ...action.payload.workflowList};
    default:
      return state;
  }
};
export default workflowFileList;