import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import {Form, Alert, Button, Card, Col, Row, Select, Input} from "antd";
import {
    CreateCheckbox,
    CreateFileField2,
    CreateSelectField,
    CreateTextField
} from "../../Helpers/Input/InputCreator";
import {Field, reduxForm} from "redux-form";

// import 'antd/lib/card/style/css';


// import 'antd/lib/alert/style/css';


// import 'antd/lib/upload/style/css';


import {connect} from "react-redux";
import {email, required,commaequals} from "../../Helpers/Input/validations";
import PrivateRoute from "../../Root/PrivateRoute";
import general from "../../../const/general";
import certificates from "../../../const/certificates";
import admin from "../../../const/admin";
import _ from "lodash";
import TitleAndInfo from "../../Helpers/TitleAndInfo";
import {CLEAR_ORDER, INFO_SHOW} from "../../../actions/types";
import requestCertificateAndes from "../../../actions/certificates/requestCertificateAndes";
import {dane} from "../../../const/dane";
import getCertificateItemsByToken from "../../../actions/admin/getCertificateItemsByToken";
import infoPayload from "../../../actions/helpers/payloadHandlers/infoPayload";

class Andes extends React.Component {

    state={token:'',showPublicLetter:true,showID:true,showRUT:true, showCertLetter:true, showTradeLetter:true, netcoID:null}

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(!this.state.netcoID && this.props.tokenInfo && this.props.tokenInfo.assignedItems.length===1)
            this.setState({netcoID:this.props.tokenInfo.assignedItems[0].netcoID});
        if(this.props.tokenInfo && this.props.tokenInfo.assignedItems.length===0 && this.props.tokenInfo.token)
        {
            this.props.dispatch({type: CLEAR_ORDER});
            this.props.dispatch({type: INFO_SHOW,payload: infoPayload('error', certificates[this.props.language].notFoundCertLabel)});
        }

    }

    componentWillUnmount()
    {
        this.props.dispatch({type: CLEAR_ORDER});
    }

    componentDidMount()
    {
        this.props.dispatch({type: CLEAR_ORDER});
        this.setState({token:this.props.token},this.getTokens)
    }

    onSubmit = (formValues) => {
        formValues.token=this.props.tokenInfo.token;
        formValues.netcoID=this.state.netcoID;
        this.props.requestCertificateAndes(formValues);
    };

    renderStates=(fieldName)=>
    {
        return <Field
            name={fieldName}
            component={CreateSelectField}
            validate={[required]}
            hasFeedback>
            {dane.map((municipio)=><Select.Option key={municipio.id} value={municipio.id}>{municipio.value}</Select.Option>)}
        </Field>
    };

    renderForm=()=>
    {
        const assignedItems=this.props.tokenInfo.assignedItems;
        if(assignedItems.length===0)
            return this.renderTokenInfo();
        const netcoID=this.state.netcoID;
        return <Card bordered={false}>
            <div className={'title-info'}>
                <Alert style={{maxHeight:80,marginTop:0,marginBottom:0,paddingBottom:0,paddingTop:0}} className={'text-center info'} message={certificates[this.props.language].andes_info} type="info"/>
                <Alert style={{maxHeight:80,marginTop:0,marginBottom:0,paddingBottom:0,paddingTop:0}} className={'mb-10 text-center info'} message={certificates[this.props.language].csr_info2} type="info"/>
            </div>
            <Row>
                {certificates[this.props.language].selectCertificateType}
            </Row>
            <Row>
                <Select disabled={assignedItems.length===1} style={{width:'100%'}} value={this.state.netcoID} onChange={(value)=>this.setState({netcoID:value})}>
                    {assignedItems.map(assignedItem=><Select.Option key={assignedItem.netcoID} value={assignedItem.netcoID}>
                        {certificates[this.props.language]["certLabelFor" + assignedItem.netcoID]}
                    </Select.Option>)}
                </Select>
            </Row>

            {netcoID && <Form onFinish={this.props.handleSubmit(this.onSubmit)}>
                <Row gutter={16}>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].idTypeLabel}</div>
                        <Field
                            name="idType"
                            component={CreateSelectField}
                            validate={[required]}
                            hasFeedback>
                            <Select.Option value="1">Cédula de ciudadanía</Select.Option>
                            <Select.Option value="3">Cédula de extranjería</Select.Option>
                            <Select.Option value="6">Pasaporte</Select.Option>
                        </Field>
                    </Col>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].identification}</div>
                        <Field
                            name="cedula"
                            component={CreateTextField}
                            placeholder={certificates[this.props.language].identification}
                            validate={[required,commaequals]}
                            hasFeedback/>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].nameLabel}</div>
                        <Field
                            name="name"
                            component={CreateTextField}
                            placeholder={certificates[this.props.language].nameLabel}
                            validate={[required,commaequals]}
                            hasFeedback/>
                    </Col>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].lastNameLabel}</div>
                        <Field
                            name="lastName"
                            component={CreateTextField}
                            placeholder={certificates[this.props.language].lastNameLabel}
                            validate={[required,commaequals]}
                            hasFeedback/>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].department}</div>
                        {this.renderStates('state')}
                    </Col>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].address}</div>
                        <Field
                            name="streetAddress"
                            component={CreateTextField}
                            placeholder={certificates[this.props.language].address}
                            validate={[required,commaequals]}
                            hasFeedback/>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].email}</div>
                        <Field
                            name="mail"
                            component={CreateTextField}
                            placeholder={certificates[this.props.language].email}
                            validate={[required, email]}
                            hasFeedback/>
                    </Col>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].phone}</div>
                        <Field
                            name="phone"
                            component={CreateTextField}
                            placeholder={certificates[this.props.language].phone}
                            validate={[required,commaequals]}
                            hasFeedback/>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].company_id}</div>
                        <Field
                            name="organizationID"
                            component={CreateTextField}
                            placeholder={certificates[this.props.language].company_id}
                            validate={[required,commaequals]}
                            hasFeedback/>
                    </Col>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].company_name}</div>
                        <Field
                            name="organization"
                            component={CreateTextField}
                            placeholder={certificates[this.props.language].company_name}
                            validate={[required,commaequals]}
                            hasFeedback/>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].company_state}</div>
                        {this.renderStates('companyState')}
                    </Col>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].company_address}</div>
                        <Field
                            name="organizationAddress"
                            component={CreateTextField}
                            placeholder={certificates[this.props.language].company_address}
                            validate={[required,commaequals]}
                            hasFeedback/>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].company_title}</div>
                        <Field
                            name="title"
                            component={CreateTextField}
                            placeholder={certificates[this.props.language].company_title}
                            validate={[required,commaequals]}
                            hasFeedback/>
                    </Col>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].company_area}</div>
                        <Field
                            name="organizationalUnit"
                            component={CreateTextField}
                            placeholder={certificates[this.props.language].company_area}
                            validate={[required,commaequals]}
                            hasFeedback/>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].attach_id}</div>
                        <Field
                            accept={".pdf"}
                            name="idFile"
                            component={CreateFileField2}
                            beforeUpload={() => {
                                this.setState({showID:false});
                                return false;
                            }
                            }
                            onRemove={()=>{
                                this.setState({showID:true});
                                return true;
                            }}
                            showUploadList={{showRemoveIcon:false}}
                            validate={[required]}
                            multiple={false}
                        >
                            {this.state.showID?<Button>{certificates[this.props.language].clickUploadLabel}</Button>:''}
                        </Field>
                    </Col>
                    <Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].attach_rut}</div>
                        <Field
                            accept={".pdf"}
                            name="rutFile"
                            component={CreateFileField2}
                            beforeUpload={() => {
                                this.setState({showRUT:false});
                                return false;
                            }
                            }
                            onRemove={()=>{
                                this.setState({showRUT:true});
                                return true;
                            }}
                            showUploadList={{showRemoveIcon:false}}
                            validate={[required]}
                            multiple={false}
                        >
                            {this.state.showRUT?<Button>{certificates[this.props.language].clickUploadLabel}</Button>:''}
                        </Field>
                    </Col>
                    {netcoID==='8' || netcoID==='13'?<Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].attach_trade}</div>
                        <Field
                            accept={".pdf"}
                            name="tradeLetterFile"
                            component={CreateFileField2}
                            beforeUpload={() => {
                                this.setState({showTradeLetter:false});
                                return false;
                            }
                            }
                            onRemove={()=>{
                                this.setState({showTradeLetter:true});
                                return true;
                            }}
                            showUploadList={{showRemoveIcon:false}}
                            validate={[required]}
                            multiple={false}
                        >
                            {this.state.showTradeLetter?<Button>{certificates[this.props.language].clickUploadLabel}</Button>:''}
                        </Field>
                    </Col>:''}
                    {netcoID==='9'?<Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].attach_cert}</div>
                        <Field
                            accept={".pdf"}
                            name="certLetterFile"
                            component={CreateFileField2}
                            beforeUpload={() => {
                                this.setState({showCertLetter:false});
                                return false;
                            }
                            }
                            onRemove={()=>{
                                this.setState({showCertLetter:true});
                                return true;
                            }}
                            showUploadList={{showRemoveIcon:false}}
                            validate={[required]}
                            multiple={false}
                        >
                            {this.state.showCertLetter?<Button>{certificates[this.props.language].clickUploadLabel}</Button>:''}
                        </Field>
                    </Col>:''}
                    {netcoID==='12'?<Col span={12}>
                        <div style={{marginTop:'10px'}} className={'mb-10'}>{certificates[this.props.language].attach_public}</div>
                        <Field
                            accept={".pdf"}
                            name="publicLetterFile"
                            component={CreateFileField2}
                            beforeUpload={() => {
                                this.setState({showPublicLetter:false});
                                return false;
                            }
                            }
                            onRemove={()=>{
                                this.setState({showPublicLetter:true});
                                return true;
                            }}
                            showUploadList={{showRemoveIcon:false}}
                            validate={[required]}
                            multiple={false}
                        >
                            {this.state.showPublicLetter?<Button>{certificates[this.props.language].clickUploadLabel}</Button>:''}
                        </Field>
                    </Col>:''}
                    <Col span={12}>
                        <Field name="agree"
                               type={'checkbox'}
                               component={CreateCheckbox}
                               validate={required}
                               hasFeedback>
                            <a rel='noopener noreferrer' target={'_blank'} href={window.netcourlid + '/comagre/AcuerdoDeComunicaciones.pdf'}>
                                {general[this.props.language].agree}
                            </a>
                        </Field>
                    </Col>
                </Row>
                <Row className={'flexRight'} gutter={16}>
                    <Button htmlType={'submit'} className={'float-right'} type={'primary light-primary rounded-sm'}>
                        {certificates[this.props.language].createAndesBtn}
                    </Button>
                </Row>
            </Form>}
        </Card>;
    }

    getTokens=()=>
    {
        const token=this.state.token;
        if(token && token!=="")
            this.props.getCertificateItemsByToken({token},()=>{
                this.setState({token:''},()=>this.props.dispatch({type: CLEAR_ORDER}));
            });
    };

    renderTokenInfo=()=>
    {
        return (
            <><TitleAndInfo info={certificates[this.props.language].andes_info}/>
            <div style={{'textAlign':'center'}}>
                <div className={'mb-10'} style={{'fontWeight': 'bold'}}>{certificates[this.props.language].andes_info2}</div>
                <div><Input style={{ width: '80%', textAlign:'center' }} value={this.state.token} onChange={(event)=>this.setState({token:event.target.value})} /></div>
                <div><Button style={{width:'100px',marginTop:'10px'}} type="primary light-primary rounded-sm" onClick={this.getTokens}>{admin[this.props.language].buy_topUpValidate}</Button></div>
            </div></>
        );
    };


    render() {
        if(this.props.tokenInfo)
            return this.renderForm();
        return this.renderTokenInfo();
    }

}

const mapStateToProps = state => {
    let queryParams = state.router.location.search ? _.reduce(state.router.location.search.substring(1).split("&"),
        (result, value) => {
            let params = value.split("=");
            result[params[0]] = params[1];
            return result;
        }, {}) : {};
    return {
        language: state.auth.language,
        userName: state.auth.userName,
        requiresCert: state.auth.requiresCert,
        initialValues: {
            agree: false,
            idType:'1',
            certValidity:'3',
            state:'11001',
            companyState:'11001'
        },
        token:queryParams.token,
        tokenInfo:state.payment.tokenInfo
    }
};

export default PrivateRoute(connect(mapStateToProps, {getCertificateItemsByToken,requestCertificateAndes})((reduxForm({
    form: 'andesCertificate_form'
})(Andes))));
