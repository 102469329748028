import netcoServer from '../../apis/netcoServer';
import {VERIFY_FILES_WITH_UID} from "../types";
import handleResponse from "../helpers/handleResponse";
import _ from 'lodash';
import getSignedFilesInfo from "./getSignedFilesInfo";
import {VERIFICATION_ROUTE} from "../../components/Root/routes";
import loadingCall from "../helpers/loadingCall";
import {PDF_PASSWORD_MODAL} from "../../components/Feedback/Modal/types";
import modalShow from "../modal/modalShow";
import modalDismiss from "../modal/modalDismiss";
import signature from "../../const/signature";

const showPasswordModal=(dispatch,language,title)=>
{
  return new Promise(function(resolve,reject)
  {
    dispatch(modalShow({
      modalType: PDF_PASSWORD_MODAL,
      modalProps: {onCancel:()=>{
        dispatch(modalDismiss());
        reject('password cancelled');
        }
        ,language: language, footer: null, title: title},
      componentProps: {language: language, onSubmit: (formValues)=>{
          dispatch(modalDismiss());
          resolve(formValues.password);
        }}
    }));
  });
};

const verifyFilesWithUID= (selectedFiles) => async (dispatch,getState) =>
{
  await verifyFiles(dispatch,getState().auth.language,{uniqueIDS: _.join(selectedFiles, ','),passwords:'{}'})};

async function verifyFiles(dispatch,language,formData)
{
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/VerifyService/verifyFilesWithUID', formData));
  let fileNames=[];
  // eslint-disable-next-line array-callback-return
  response.data.result.responses.map((response)=>{
    if(response.processedError && response.processedError.includes("cifrado") && response.uid)
      fileNames.push(response.fileName);
  });
  if(fileNames.length<=0)
    handleResponse(dispatch, response, VERIFY_FILES_WITH_UID, onSuccessPayload, VERIFICATION_ROUTE, [() => getSignedFilesInfo(false)]);
  else
  {
    let jsonPasswords=JSON.parse(formData.passwords);
    try
    {
      for(let i=0;i<fileNames.length;i++)
      {
        const fileName=fileNames[i];
        jsonPasswords[fileName]=await showPasswordModal(dispatch,language,signature[language].password_for.replace('$0',fileName));
      }
      formData.passwords=JSON.stringify(jsonPasswords);
      await verifyFiles(dispatch,language,formData);
    }
    catch(e)
    {
      //console.log("Error",e);
    }
  }
}

const onSuccessPayload = data => {
  return {
    verifiedFilesList: {
      entries: data.result.responses
    }
  };
};
export default verifyFilesWithUID;