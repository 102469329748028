// noinspection RequiredAttributes

import {Button, Col, Divider, Form, Row, Select} from "antd";
import {Field} from "redux-form";
import {CreateCheckbox, CreateSelectField, CreateTextArea, CreateTextField} from "../../Helpers/Input/InputCreator";
import {emailes, maxLengthEs, required} from "../../Helpers/Input/validations";
import signature from "../../../const/signature";
import {SIGNATURE_SIGN_ROUTE} from "../../Root/routes";
import React from "react";
import profile from "../../../const/profile";
import general from "../../../const/general";

// import 'antd/lib/divider/style/css';


const max=maxLengthEs(100);
const SignatureOptionsForm=(props)=>
{
    const renderTimestamps=() =>{
        if(props.user.timeStampServers)
            return(props.user.timeStampServers.map(item => {
                const key=item.id;
                return <Select.Option key={key}>{item.friendlyName}</Select.Option>
            }));
        return <></>;
    };

    const renderSignatureTypes=()=> {
        if(props.user.imageTypes)
            return(props.user.imageTypes.map(item =><Select.Option key={item.id}>{item.description}</Select.Option>));
        return <></>;
    };

    const renderOperations=()=> {
        if(props.validOperations)
            return(props.validOperations.map(item =>
                {
                    const key=parseInt(item.key);
                    return <Select.Option key={key} value={key}>{item.value}</Select.Option>;
                }
            ));
        return <></>;
    };

    return (
        <Form className={'profile-options'} onFinish={props.handleSubmit(props.onSubmit)}>
            <Row style={{marginBottom:'10px'}} gutter={16}>
                <Col xs={24} md={12}>
                    <div style={{color:'#8a8a8a'}}>{profile[props.language].chooseGraphics}</div>
                    <Field
                        name="signatureTypeInt"
                        component={CreateSelectField}
                        placeholder=""
                        validate={[required]}
                        hasFeedback>
                        {renderSignatureTypes()}
                    </Field>
                    <div style={{color:'#8a8a8a'}}>{profile[props.language].chooseOperation}:</div>
                    <Field
                        name="operation"
                        component={CreateSelectField}
                        validate={[required]}
                        hasFeedback>
                        {renderOperations()}
                    </Field>
                </Col>
                <Col xs={24} md={12}>
                    <div style={{color:'#8a8a8a'}}>{general[props.language].comment}:</div>
                    <Field
                        name="reason"
                        maxLength={100}
                        validate={[max]}
                        rows={3}
                        component={CreateTextArea}
                        placeholder={profile[props.language].optionReason}
                        hasFeedback/>
                </Col>


            </Row>
            <Row style={{marginBottom:'10px'}} gutter={16}>
                <Col xs={24} md={12}>
                    <div style={{color:'#8a8a8a'}}>{signature[props.language].email}:</div>
                    <Field
                        name="mails"
                        component={CreateTextField}
                        validate={[emailes]}
                        hasFeedback/>


                </Col>
                {props.user.timeStampServers && props.user.timeStampServers.length>1?
                    <Col xs={24} md={12}>
                        <div style={{color:'#8a8a8a'}}>{profile[props.language].server}</div>
                        <Field
                            name="tsid"
                            component={CreateSelectField}
                            validate={[required]}
                            hasFeedback>
                            {renderTimestamps()}
                        </Field>
                    </Col>:''}
            </Row>

            <Divider />
            <Row style={{marginBottom:'10px'}} gutter={16}>
                <Col xs={24} md={12}>
                    <Field name="publish"
                           type={'checkbox'}
                           component={CreateCheckbox}
                           hasFeedback>
                        {profile[props.language].publish}
                    </Field>
                </Col>
                <Col xs={24} md={12}>
                    <Field
                        name="certification"
                        type={'checkbox'}
                        component={CreateCheckbox}
                        hasFeedback>
                        {profile[props.language].certificate_type}
                    </Field>
                </Col>
            </Row>
            <Row style={{marginBottom:'10px'}} gutter={16}>
                <Col xs={24} md={12}>
                    <Field name="previewPDF"
                           type={'checkbox'}
                           component={CreateCheckbox}
                           disabled={props.pathname===SIGNATURE_SIGN_ROUTE}
                           hasFeedback>
                        {signature[props.language].pre_pdf}
                    </Field>
                </Col>
                <Col xs={24} md={12}>
                    <Field name="signAllPages"
                           type={'checkbox'}
                           component={CreateCheckbox}
                           hasFeedback>
                        {general[props.language].signAllPages}
                    </Field>
                </Col>
            </Row>
            <Row className={'flexRight'}>
                <Button type={'primary light-primary rounded-sm float-right'} htmlType={'submit'}>Guardar</Button>
            </Row>
        </Form>
    );
}

export default SignatureOptionsForm;