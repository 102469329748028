import React from 'react';
import { connect } from "react-redux";
import './report.css';
import PrivateRoute from "../Root/PrivateRoute";
import reports from "../../const/reports";
import NewLogsTable from "./NewLogsTable";
import getUserInfo from "../../actions/profile/getUserInfo";
import { Tabs } from "antd";
import getAllUsers from "../../actions/report/getAllUsers";
import getAllLogOperations from "../../actions/admin/getAllLogOperations";

class NewReport extends React.Component {

  state = {
    titles: [reports[this.props.language].logs_report, reports[this.props.language].documents_report, reports[this.props.language].operations_report],
    keys: ['logs', 'documents', 'operations'], activeKey: 'logs'
  };

  componentDidMount() {
    this.props.getUserInfo([this.addOptions]);
    this.props.getAllLogOperations();
    this.props.getAllUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.admin !== this.props.admin || prevProps.enableecom !== this.props.enableecom)
      this.addOptions()
  }

  addOptions = () => {
    const { admin, enableecom, language } = this.props;
    const addReport = { keys: [], titles: [] };

    if (admin) {
      addReport.keys.push('pending', 'certificates');
      addReport.titles.push(
        reports[language].pending_report,
        reports[language].certificates
      );
    }

    if (enableecom) {
      addReport.keys.push('andes');
      addReport.titles.push(reports[language].andes_report);
    }

    this.setState({
      keys: [...this.state.keys, ...addReport.keys],
      titles: [...this.state.titles, ...addReport.titles]
    });
  }

  getTabItems = () => {
    const items = [];
    [...Array(this.state.keys.length)].forEach((x, i) => {
      items.push({
        key: this.state.keys[i],
        label: this.state.titles[i],
        children: this.state.activeKey === this.state.keys[i] ? <NewLogsTable id={this.state.activeKey} /> : <></>
      });
    });
    return items;
  };

  render() {
    return <div className="card-container template-tabs h-100 p-20 tabs-container">
      <Tabs defaultActiveKey={this.state.activeKey} onChange={(option) => this.setState({ activeKey: option })} type="card"
        items={this.getTabItems()}>

      </Tabs>
    </div>;
  }

}

const mapStateToProps = state => {
  return {
    admin: state.auth.admin,
    language: state.auth.language,
    enableecom: state.auth.enableecom
  };
};

export default PrivateRoute(connect(mapStateToProps, { getAllUsers, getUserInfo, getAllLogOperations })(NewReport));
