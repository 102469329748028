import netcoServer from '../../apis/netcoServer';
import {GET_ANDES_REPORT} from "../types";
import tableLoadingCall from '../helpers/tableLoadingCall';
import handleBase64FileResponse from "../helpers/handleBase64ZipResponse";

const getAndesReport= (formValues, page, pageSize, sidx, sord, returnString = true) => async (dispatch) => {
  formValues.returnString = returnString;
  formValues.limit = pageSize;
  formValues.skip = (page - 1) * pageSize;
  formValues.sidx = sidx;
  formValues.sord = sord ? renderSord(sord) : '';
  if (returnString) {
    await tableLoadingCall(dispatch, () => netcoServer.newPost('/UserService/getAllAndesRequestsReport', formValues), GET_ANDES_REPORT, onSuccessPayload);
  } else {
    let response = await netcoServer.newPost('/UserService/getAllAndesRequestsReport', formValues);
    handleBase64FileResponse(response.data.result.requests, 'andes.zip');
  }
};

const renderSord = sord => {
  return sord === 'ascend' ? 'asc' : 'desc';
};

const onSuccessPayload = data => {
  return {
    userReportList: {
      kind: 'andes',
      total: data.result.total,
      entries: data.result.requests
    }
  };
};
export default getAndesReport;