import netcoServer from '../../apis/netcoServer';
import loadingCall from "../helpers/loadingCall";
import { handleQRError } from '../helpers/utils';

const setHWImage= (formValues) => async (dispatch,getState) => {
  let response = await loadingCall(dispatch, () => netcoServer.post('/UserService/setHWImage', formValues));
  handleQRError(dispatch,response,getState().auth.language)
};


export default setHWImage;