import {trackPromise} from "react-promise-tracker";

const getBase64File= (file) => {
  return trackPromise(new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>{
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
        resolve(encoded);
      };
    reader.onerror = error => reject(error);
  }));
};
export default getBase64File;