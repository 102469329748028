import netcoServer from '../../apis/netcoServer';
import {GET_PDF_TEMPLATE} from "../types";
import handleResponse from "../helpers/handleResponse";
import loadingCall from "../helpers/loadingCall";

const getPDFTemplate= (selectedTemplate, returnBase64File = true, callback = null) => async (dispatch) => {
  if(Array.isArray(selectedTemplate)){
    const data = []
    for(let i=0;i<selectedTemplate.length;i++){
      let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getPDFTemplate', {id: selectedTemplate[i], returnBase64File}));
      handleResponse(dispatch, response);
      if(response && response.data && response.data.success){
        data.push({id:selectedTemplate[i],name:response.data.fileName, base64File:response.data.base64File})
      }
    }
    callback && callback(data)
  }else{
    let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getPDFTemplate', {id: selectedTemplate, returnBase64File}));
    handleResponse(dispatch, response, GET_PDF_TEMPLATE, onSuccessPayload);
    return response;
  }
  
};

const onSuccessPayload = data => {
  return {
    templateList: {
      selectedTemplate: data.base64File
    }
  };
};

export default getPDFTemplate;