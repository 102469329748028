import moment from "moment";

/*export const loadFileInitialValues={
    userToPut: '',
    sendConfirmation: true,
    remindEvery:24,
    remind:true,
    sendAll:true,
    combinePDF:true,
    withTemplate:true,
    ordered:true,
    maxDate:moment().add(1, 'day').startOf('day'),
    locate:true
};*/
export const loadFileInitialValues={
    userToPut: '',
    sendConfirmation: true,
    remindEvery:24,
    remind:false,
    sendAll:true,
    combinePDF:false,
    withTemplate:false,
    ordered:false,
    locate:false,
    combinedFileName:'Documento combinado.pdf',
    copyToCheck:false,
    copyTo:'',
    maxDate:moment().add(1, 'day').startOf('day'),
    multipleWorkflow:false,
    whoSigns:0,
    showOptions:'',
    optMessage:'',
    optionName:'Flujo de firma por defecto',
    saveOption:false,
    newOptionName:'Mi Nuevo flujo de firma'
};

