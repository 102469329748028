import netcoServer from '../../apis/netcoServer';
import {DELETE_PROFILE} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import getAllProfiles from "./getAllProfiles";
import loadingCall from "../helpers/loadingCall";

const deleteProfile= (id) => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/SystemService/deleteProfile', {id: id}));
  handleResponse(dispatch, response, DELETE_PROFILE, onSuccessPayload, null, [getAllProfiles]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Perfil eliminado satisfactoriamente!');
};
export default deleteProfile;