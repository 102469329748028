import React from 'react';
import PrivateRoute from "../Root/PrivateRoute";
import { connect } from "react-redux";
import { Typography, Row, Col } from 'antd';
import PendingDocs from '../Docs/NewPendingDocs';
import {SEND_AND_SIGN_ROUTE, VERIFICATION_ROUTE} from '../Root/routes'
import { push } from 'connected-react-router';
import showOptionsSignature from '../../actions/info/showOptionsSignature';
import { ReactComponent as OptionSignIcon } from '../../resources/img/NewIcons/home/option-sign.svg';
import { ReactComponent as VerifyIcon } from '../../resources/img/NewIcons/home/verify.svg';
import { ReactComponent as SignIcon } from '../../resources/img/NewIcons/home/sign.svg';
import home from '../../const/home';
import showShortcuts from "../../actions/info/showShortcuts";
import getUserInfo from "../../actions/profile/getUserInfo";
import {NEW_CERTIFICATE_MODAL, NEW_PASSWORD_MODAL} from "../Feedback/Modal/types";
import modalShow from "../../actions/modal/modalShow";
import modalDismiss from "../../actions/modal/modalDismiss";
import certificates from "../../const/certificates";
import general from "../../const/general";
import deleteUser from "../../actions/admin/deleteUser";
import logoutUser from "../../actions/auth/logoutUser";
import updateUserPassword from "../../actions/profile/updateUserPassword";

class Home extends React.Component
{
    hasError=false;
    selfDelete=()=>
    {
        this.props.deleteUser(this.props.user.userName,this.props.logoutUser,'NOSIGNUP')
        this.props.modalDismiss();
    };

    showPopups=(data)=>
    {   
        if(this.props.createUser || this.props.isNewUser ==='true')
        {
            const message1=this.hasError?home[this.props.language].signedError1:home[this.props.language].signedOk1;
            const message2=this.hasError?home[this.props.language].signedError2:home[this.props.language].signedOk2;
            const message3=this.hasError?home[this.props.language].signedError3:home[this.props.language].signedOk3;
            this.props.modalShow({
                modalType: NEW_PASSWORD_MODAL,
                modalProps: { footer: null, title: general[this.props.language].changePassword, onCancel: this.props.modalDismiss },
                componentProps: {btn: general[this.props.language].create, entryId: null, updateUserPassword:this.props.updateUserPassword,
                    language: this.props.language, onCancel: this.props.modalDismiss, message1:message1, message2:message2,message3:message3,
                    selfDelete:this.selfDelete }
            });
            return;
        }
        if(data.user.hasCertificate!=="true" && !data.user.dontShowcreateCertificate && this.props.netcoPKI && this.props.registration.data)
        {
            this.props.modalShow({
                modalType: NEW_CERTIFICATE_MODAL,
                modalProps: { footer: null, title: certificates[this.props.language].getCertTitle, onCancel: this.props.modalDismiss },

            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(!prevProps.type.message && this.props.type.message && this.props.type.type=== 'error')
            this.hasError=true;
    }

    componentDidMount()
    {
        this.props.showShortcuts(true);
        this.props.getUserInfo(undefined,undefined,[this.showPopups]);
    }

    componentWillUnmount()
    {
        this.props.showShortcuts(false);
    }

    render() {
        const user = this.props.user;
        return (
            <div className="card-container" style={{paddingLeft:35}}>
                <Row className='text-title size36'>
                    <Col className='color-black size36' style={{marginRight:9}}>{home[this.props.language].greeting}</Col>
                    <Col className='color-primary size36'>{user.cn?user.cn:user.userName}</Col>
                </Row>
                <Typography className='size15 color-black'>{home[this.props.language].selectStartSign}</Typography>
                <Row style={{ marginTop:28, rowGap: 18}}>
                    <Col xs={24} xl={8}>
                    <button className='home-buttons' onClick={() => this.props.showOptionsSignature(true)}>
                        <OptionSignIcon className='center' />
                        <Typography className='center'>{home[this.props.language].configureSignature}</Typography>
                    </button>
                    </Col>
                    <Col xs={24} xl={8}>
                    <button className='home-buttons' onClick={() => this.props.push(SEND_AND_SIGN_ROUTE)}>
                        <SignIcon className='center' />
                        <Typography className='center'>{home[this.props.language].startSign}</Typography>
                    </button>
                    </Col>
                    <Col xs={24} xl={8}>
                    <button className='home-buttons' onClick={() => this.props.push(VERIFICATION_ROUTE)}>
                        <VerifyIcon className='center' />
                        <Typography className='center'>{home[this.props.language].verifySignature}</Typography>
                    </button>
                    </Col>
                </Row>
                <Typography style={{margin:'21px 0 21px 0'}} className='text-title size22 color-black'>{home[this.props.language].pendingDocs}</Typography>
                <div className={'separator'} style={{width:'100%'}}/>
                <PendingDocs />
            </div>
        );
    };
}

const mapStateToProps = state => {
    const params=state.auth.params;
    return {
        language: state.auth.language,
        user: state.user,
        netcoPKI: state.auth.netco,
        registration: state.registration,
        createUser: params?params.createUser:undefined,
        isNewUser: params?params.isNewUser:undefined,
        type: state.info,
    };
};

export default PrivateRoute(connect(mapStateToProps,{push, showOptionsSignature, showShortcuts, getUserInfo,modalShow,
    modalDismiss, deleteUser,logoutUser, updateUserPassword})(Home));