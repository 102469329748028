import netcoServer from '../../apis/netcoServer';
import {DELETE_USER} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import loadingCall from "../helpers/loadingCall";

const deleteUser= (userName,after, delReason) => async (dispatch) => {
  const onSuccessPayload = () => {
  after();
  return infoPayload('success', 'Usuario eliminado satisfactoriamente...');
  };
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/deleteUser', {userName,delReason}));
  handleResponse(dispatch, response, DELETE_USER, onSuccessPayload, null, []);  
};
export default deleteUser;
