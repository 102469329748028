import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'
import {getLoginPathFromState} from "../Helpers/utils";
import {HOME_NEWCERTIFICATE_ROUTE, VERIFICATION_ROUTE} from "./routes";

const privateRoute = connectedRouterRedirect({
  redirectPath: (state) => getLoginPathFromState(state),
  allowRedirectBack: false,
  authenticatedSelector: (state) => {
    let publicRoutes=[VERIFICATION_ROUTE, HOME_NEWCERTIFICATE_ROUTE];
    let currentRoute=state.router.location.pathname;
    for(let i=0;i<publicRoutes.length;i++)
    {
      if(publicRoutes[i]===currentRoute)
        return true;
    }
    return sessionStorage.logged !== undefined
  },
  wrapperDisplayName: 'PrivateRoute'
});
export default privateRoute;
