import netcoServer from '../../apis/netcoServer';
import {GET_LDAP_USER_INFO} from "../types";
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const getLDAPUserInfo= () => async (dispatch) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/getLDAPUserInfo', {}));
  handleResponse(dispatch, response, GET_LDAP_USER_INFO, onSuccessPayload);
};

const onSuccessPayload = data => {
  return {
    ldapUser: data.result
  };
};
export default getLDAPUserInfo;