import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import { Row, Col, Button } from 'antd';
import { connect } from 'react-redux'

class SignatureHandelOptions extends React.Component {
  render() {
    const showImg = this.props.image.replace('data:image/png;base64,','')===''? 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==': this.props.image;
    return (<div className={'signerBorder'}  style={{padding: 10,marginBottom:10}}>
              <h2 className={'title'}>{this.props.title}</h2>
              <div className={'signerBorder'}  style={{borderBottom:'none',marginBottom: 10}} />
              <Row gutter={16} className={'flexRight'}>
                <Col xs={24} md={12} style={{textAlign: 'left', margin: 'auto'}}>
                    <p style={{marginTop:'10px', textAlign: 'justify'}} className={'mb-10'}>{this.props.text}</p>
                </Col>
                <Col xs={24} md={12} style={{textAlign: 'center', margin: 'auto'}}>
                  <img style={{width: 200, height: 200, marginBottom:20}} src={showImg}  alt={"imgSignature"} />
                </Col>
              </Row>
              <Row className={'flexRight'}>
                  <Button className={'float-right'} type={'primary light-primary rounded-sm float-right'} onClick={this.props.modalDismiss} >
                    {this.props.textButton}
                  </Button>
              </Row>
        </div>
           
    );
  }
}


const mapStateToProps = state => {
  return {
    language: state.auth.language,
  };
};

export default connect(mapStateToProps, {})(SignatureHandelOptions);
