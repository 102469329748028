import React from 'react';

// import '@ant-design/compatible/assets/index.css';


import {Button, Divider, Form, Modal, Row} from "antd";

// import 'antd/lib/modal/style/css';


import {reduxForm} from "redux-form";
import admin from "../../const/admin";

class TokenModal extends React.Component {

    renderTokens=()=>
    {
        return <div style={{marginTop:'20px'}}>
            <Row justify={'center'}>{this.props.token}</Row>
            <Divider/>
            <Row justify={'center'}>
                <Button type={'primary'} htmlType={'button'} onClick={this.props.activate}>{admin[this.props.language].buy_activatetitle}</Button>
            </Row>
        </div>;
    };

  render() {
    return (
        (<Modal
            open={this.props.modalType !== null} width={720} {...this.props.modalProps}>
            <Form>
              <Form.Item>
                  {this.props.message}
                  {this.props.token?this.renderTokens():''}
              </Form.Item>
            </Form>
        </Modal>)
    );
  }


}

export default reduxForm({form: 'token_modal_form'})(TokenModal);