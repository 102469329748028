import {GET_PROFILE_INFO} from "../types";

const getProfileInfo= (id) => async (dispatch, getState) => {
  let payload = {
    reportList: {
      selectedEntry: id === null ? {requireUPLOWLT: false, requireSPECIALC: false, isAdmin: false, isTxAuth: false, authTypeId: 1} : getState().reportList.entries.find(x => x.id === id)
    }
  };
  dispatch({type: GET_PROFILE_INFO, payload: payload});
};
export default getProfileInfo;