import {GET_PUBLIC_SIGNED_FILES_INFO, LOG_OUT} from "../actions/types";

const INITIAL_STATE = {
  entries: null,
  total: 0
};

const publicFileList= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PUBLIC_SIGNED_FILES_INFO:
      return {...state, ...action.payload.publicFileList};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default publicFileList;