import {VERIFY_FILES, LOG_OUT, VERIFY_FILES_WITH_UID, CLEAN_VERIFIED_FILES} from "../actions/types";

const INITIAL_STATE = {
  entries: []
};

const verifiedListReducer= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VERIFY_FILES:
      return {...state, ...action.payload.verifiedFilesList};
    case VERIFY_FILES_WITH_UID:
      return {...state, ...action.payload.verifiedFilesList};
    case CLEAN_VERIFIED_FILES:
      return {...state, ...action.payload.verifiedFilesList};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default verifiedListReducer;