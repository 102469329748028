import React from 'react';
import {Field, reduxForm} from 'redux-form';

// import '@ant-design/compatible/assets/index.css';


import { Form,Button } from 'antd';
import {CreatePasswordField} from "../../Helpers/Input/InputCreator";

// import 'antd/lib/form/style/css';


import {connect} from "react-redux";
import newPassword from "../../../actions/auth/newPassword";
import {SIGNUP_ROUTE} from "../../Root/routes";
import {push} from 'connected-react-router';
import auth from "../../../const/auth";

const PasswordForm = (props) => {

  const onSubmit = (formValues) => {
    formValues.language=props.language;
	props.newPassword(formValues, props.search);
  };
  
  return (
    <Form onFinish={props.handleSubmit(onSubmit)}>
      <Form.Item>
          <div style={{marginTop:'10px'}} className={'mb-10'}>{auth[props.language].newpasslbl.toUpperCase()}</div>
        <Field
               name="password"
               size={'large'}
               component={CreatePasswordField}
               placeholder={auth[props.language].passwordlbl}
               hasFeedback/>
      </Form.Item>
      <Form.Item>
          <div style={{marginTop:'10px'}} className={'mb-10'}>{auth[props.language].newpassconfirmlbl.toUpperCase()}</div>
        <Field
               name="password_confirmation"
               size={'large'}
               component={CreatePasswordField}
			   placeholder={auth[props.language].passconfirmlbl}
               hasFeedback/>
      </Form.Item>
      <Form.Item className={'text-center'}>
        <Button type="primary light-primary rounded" htmlType="submit">
		{auth[props.language].sendbtn}
        </Button>
      </Form.Item>
      <Form.Item className={'text-center'}>
        <Button type="ghost rounded" htmlType="button" onClick={() => props.push(SIGNUP_ROUTE)}>
			{auth[props.language].backbtn}			
        </Button>
      </Form.Item>
    </Form>
  );
};

export default connect(state => ({
  search: state.router.location.search
}), {newPassword, push})(reduxForm({
  form: 'password_form'
})(PasswordForm))