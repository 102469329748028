import netcoServer from '../../apis/netcoServer';
import handleResponse from '../helpers/handleResponse';
import loadingCall from "../helpers/loadingCall";

const chargeSubscription= (onSuccess) => async (dispatch) =>
{
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/PaymentService/chargeSubscription  ', {}));
  handleResponse(dispatch, response, null, null, null, [],[onSuccess],[]);
};
export default chargeSubscription;
