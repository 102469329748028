import {
  GET_PENDING_FILES_INFORMATION,
  INCREASE_PENDING_FILES_COUNT,
  DECREASE_PENDING_FILES_COUNT,
  LOG_OUT,
  UPDATE_PENDING_FILES_COUNT
} from "../actions/types";

const INITIAL_STATE = {
  entries: [],
  totalCount: 0,
  localTotal:0
};

const pendingFileList= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PENDING_FILES_INFORMATION:
      return {...state, ...action.payload.pendingFileList};
    case INCREASE_PENDING_FILES_COUNT:
      state.localTotal++;
      return {...state};
    case UPDATE_PENDING_FILES_COUNT:
      return {...state, ...action.payload.pendingFileList};
    case DECREASE_PENDING_FILES_COUNT:
      state.localTotal--;
      return {...state};
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default pendingFileList;