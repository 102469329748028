import {
  GET_KEYS_INFO
} from "../actions/types";
  
const INITIAL_STATE = {
  keys: null
};
  
const certificateReducers= (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_KEYS_INFO:
        return {...state, ...action.payload};
    default:
        return state;
  }
};
export default certificateReducers;