import netcoServer from '../../apis/netcoServer';
import {GET_ALL_PROFILES} from "../types";
import tableLoadingCall from '../helpers/tableLoadingCall';

const getAllProfiles= (page, pageSize, sord) => async (dispatch) => {
  let formData = {
    limit: pageSize,
    skip: (page - 1) * pageSize,
    sord: sord ? renderSord(sord) : '',
  };
  await tableLoadingCall(dispatch, () => netcoServer.newPost('/SystemService/getAllProfiles', formData), GET_ALL_PROFILES, onSuccessPayload);
};

const renderSord = sord => {
  return sord === 'ascend' ? 'asc' : 'desc';
};

const onSuccessPayload = data => {
  return {
    reportList: {
      kind: 'profiles',
      total: data.result.total,
      entries: data.result.profiles
    }
  };
};
export default getAllProfiles;