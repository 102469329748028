import React from 'react';
import { connect } from "react-redux";
import PrivateRoute from "../Root/PrivateRoute";
import { Typography, Row, Col, Button } from 'antd';
import getSystemInformation from '../../actions/admin/getSystemInformation';
import getSystemLog from '../../actions/admin/getSystemLog';
import admin from '../../const/admin';
import profile from '../../const/profile';
import { ReactComponent as CheckIcon } from '../../resources/img/NewIcons/systemStatus/check.svg';
import { ReactComponent as FileTextIcon } from '../../resources/img/NewIcons/systemStatus/file-text.svg';
import { ReactComponent as ShareIcon } from '../../resources/img/NewIcons/systemStatus/share.svg';
import { ReactComponent as FileIcon } from '../../resources/img/NewIcons/systemStatus/file.svg';
import { ReactComponent as ShuffleIcon } from '../../resources/img/NewIcons/systemStatus/shuffle.svg';
import { ReactComponent as QuestionIcon } from '../../resources/img/NewIcons/systemStatus/question.svg';
import { ReactComponent as LogsIcon } from '../../resources/img/NewIcons/systemStatus/logs.svg';


class SystemStatus extends React.Component {

    state = { systemReport: {} }

    componentDidMount() {
        this.props.getSystemInformation((report) => this.setState({ systemReport: report }));
    }

    render() {
        return (
            <div style={{ margin: '40px 45px', maxWidth: 1018 }}>
                <Row className='new-card' gutter={16} style={{ padding: '20px 28px' }}>
                    <Col xs={24} sm={18} style={{ alignSelf: 'center' }}>
                        <Typography className='size18 color-black'>{admin[this.props.language].system_security_provider}</Typography>
                    </Col>
                    <Col xs={24} sm={6}>
                        <Row>
                            <Typography className='text-title size22 color-primary'>{this.state.systemReport.provider}</Typography>
                        </Row>
                        <Row>
                            <Col xs={14} style={{ alignSelf: 'center' }}>
                                <Typography>{admin[this.props.language].system_state}</Typography>
                            </Col>
                            <Col xs={10} style={this.state.systemReport.status === 'OK' || !this.state.systemReport.status ? { background: 'rgba(0, 214, 143, 0.08)', border: '1px solid #00D68F', borderRadius: 25, justifyContent: 'center', display: 'flex' } :
                                { background: 'rgba(214, 0, 37, 0.08)', border: '1px solid #d60097', borderRadius: 25, justifyContent: 'center', display: 'flex' }} >

                                <Typography style={{ margin: '5px 10px' }}>{this.state.systemReport.status}</Typography>
                                {this.state.systemReport.status ? <CheckIcon style={{ alignSelf: 'center', fill: 'green', color: 'green' }} /> : null}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='mt-10' style={{ columnGap: 43 }}>
                    <Col xs={24} sm={7} >
                        <Row className='mt-20' style={{ border: '1px solid #8F9BB3', borderRadius: 23, height: 107, alignItems: 'center', textAlign: 'center', padding: 10 }}>
                            <Col xs={6}>
                                <FileTextIcon />
                            </Col>
                            <Col xs={18}>
                                <Typography className='size15 color-black'>{admin[this.props.language].system_file_size}</Typography>
                                <Typography className='text-title size30 color-primary'>{this.state.systemReport.signedFilesSize}</Typography>
                            </Col>
                        </Row>
                        <Row className='mt-20' style={{ border: '1px solid #8F9BB3', borderRadius: 23, height: 107, alignItems: 'center', textAlign: 'center', padding: 10 }}>
                            <Col xs={6}>
                                <ShuffleIcon />
                            </Col>
                            <Col xs={18}>
                                <Typography className='size15 color-black'>{admin[this.props.language].system_wf_size}</Typography>
                                <Typography className='text-title size30 color-primary'>{this.state.systemReport.wfFilesSize}</Typography>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={7}>
                        <Row className='mt-20' style={{ border: '1px solid #8F9BB3', borderRadius: 23, height: 107, alignItems: 'center', textAlign: 'center', padding: 10 }}>
                            <Col xs={6}>
                                <ShareIcon />
                            </Col>
                            <Col xs={18}>
                                <Typography className='size15 color-black'>{admin[this.props.language].system_public_size}</Typography>
                                <Typography className='text-title size30 color-primary'>{this.state.systemReport.publishedFilesSize}</Typography>
                            </Col>
                        </Row>
                        <Row className='mt-20' style={{ border: '1px solid #8F9BB3', borderRadius: 23, height: 107, alignItems: 'center', textAlign: 'center', padding: 10 }}>
                            <Col xs={6}>
                                <QuestionIcon />
                            </Col>
                            <Col xs={18}>
                                <Typography className='size15 color-black'>{admin[this.props.language].system_pending_size}</Typography>
                                <Typography className='text-title size30 color-primary'>{this.state.systemReport.pendingFilesSize}</Typography>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={7}>
                        <Row className='mt-20' style={{ border: '1px solid #8F9BB3', borderRadius: 23, height: 107, alignItems: 'center', textAlign: 'center', padding: 10 }}>
                            <Col xs={6}>
                                <FileIcon />
                            </Col>
                            <Col xs={18}>
                                <Typography className='size15 color-black'>{admin[this.props.language].system_com_size}</Typography>
                                <Typography className='text-title size30 color-primary'>{this.state.systemReport.comFilesSize}</Typography>
                            </Col>
                        </Row>
                        <Row className='mt-20' style={{ border: '1px solid #8F9BB3', borderRadius: 23, height: 107, alignItems: 'center', textAlign: 'center', padding: 10 }}>
                            <Col xs={6}>
                                <FileIcon />
                            </Col>
                            <Col xs={18}>
                                <Typography className='size15 color-black'>{admin[this.props.language].system_hsm}</Typography>
                                <Typography className='text-title size30 color-primary'>{this.state.systemReport.hsmInfo}</Typography>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='new-card mt-30' gutter={16} style={{ padding: '20px 28px' }}>
                    <Col xs={24} sm={20} style={{ alignSelf: 'center' }}>
                        <Typography className='size18 color-black'>{admin[this.props.language].system_licence}</Typography>
                    </Col>
                    <Col xs={24} sm={4} style={{ textAlign: 'right' }}>
                        <Typography className='text-title size18' style={{ color: '#00B887' }}>{this.state.systemReport.signerTotalUsers==="-1"?profile[this.props.language].unlimited:this.state.systemReport.signerTotalUsers}</Typography>
                    </Col>
                </Row>
                <Row className='new-card mt-15' gutter={16} style={{ padding: '20px 28px' }}>
                    <Col xs={24} sm={20} style={{ alignSelf: 'center' }}>
                        <Typography className='size18 color-black'>{admin[this.props.language].system_licence_used}</Typography>
                    </Col>
                    <Col xs={24} sm={4} style={{ textAlign: 'right' }}>
                        <Typography className='text-title size18' style={{ color: '#00B887' }}>{this.state.systemReport.signerUsers}</Typography>
                    </Col>
                </Row>
                <Row className='new-card mt-15' gutter={16} style={{ padding: '20px 28px' }}>
                    <Col xs={24} sm={20} style={{ alignSelf: 'center' }}>
                        <Typography className='size18 color-black'>{admin[this.props.language].system_ops_max}</Typography>
                    </Col>
                    <Col xs={24} sm={4} style={{ textAlign: 'right' }}>
                        <Typography className='text-title size18' style={{ color: '#00B887' }}>{this.state.systemReport.signerMaxOperations==="-1"?profile[this.props.language].unlimited:this.state.systemReport.signerMaxOperations}</Typography>
                    </Col>
                </Row>
                <Row className='new-card mt-15' gutter={16} style={{ padding: '20px 28px' }}>
                    <Col xs={24} sm={20} style={{ alignSelf: 'center' }}>
                        <Typography className='size18 color-black'>{admin[this.props.language].system_ops_used}</Typography>
                    </Col>
                    <Col xs={24} sm={4} style={{ textAlign: 'right' }}>
                        <Typography className='text-title size18' style={{ color: '#00B887' }}>{this.state.systemReport.signerOperations}</Typography>
                    </Col>
                </Row>
                <Row className='new-card mt-15' gutter={16} style={{ padding: '20px 28px' }}>
                    <Col xs={24} sm={18} style={{ alignSelf: 'center' }}>
                        <Typography className='size18 color-black'>{admin[this.props.language].licenseType}</Typography>
                    </Col>
                    <Col xs={24} sm={6} style={{ textAlign: 'right' }}>
                        <Typography className='text-title size18' style={{ color: '#00B887' }}>{this.state.systemReport.countDocuments?admin[this.props.language].licenseTypeDoc:admin[this.props.language].licenseTypeOp}</Typography>
                    </Col>
                </Row>
                <div className='mt-30' style={{justifyContent:'right', display:'flex', alignItems:'center'}}>
                    <Typography className='size12 color-black' style={{marginRight:15}}>{admin[this.props.language].viewLogs}</Typography>
                    
                    <Button type='ghost' onClick={this.props.getSystemLog} style={{fontSize:12, marginBottom:0}}><LogsIcon style={{marginRight:8}} />{admin[this.props.language].downloadLogs}</Button>
                </div>


            </div>
        );
    };

}

const mapStateToProps = state => {
    return {
        language: state.auth.language
    };
};

export default PrivateRoute(connect(mapStateToProps, { getSystemInformation, getSystemLog })(SystemStatus));
