import React from "react";

// import '@ant-design/compatible/assets/index.css';


import {Form, Input, Select, Checkbox, InputNumber, Radio, DatePicker, Upload} from "antd";

// import 'antd/lib/input/style/css';


// import 'antd/lib/input-number/style/css';


// import 'antd/lib/checkbox/style/css';


// import 'antd/lib/select/style/css';


// import 'antd/lib/radio/style/css';


// import 'antd/lib/form/style/css';


// import 'antd/lib/date-picker/style/css';


import moment from 'moment';
import DropZoneField from "../DropzoneField";

const createField = (Component, checkbox = false, datePicker = false, icon=true, select=false,field2=false) => ({input, meta, children, hasFeedback, label, ...rest}) => {
  if(datePicker) {
    input.value = input.value === '' ? null : moment(input.value);
    rest['format'] = 'YYYY-MM-DD';
  }
  if(checkbox)
    input.checked = input.value === '' || input.value === 'false' || input.value === 'Off' ? false : input.value === 'true' ? true : input.value;
  if(select) {
    //input.value = input.value === '' ? undefined : input.value;
    input.onBlur=null;
  }
  if(field2)
    delete input.value;
  if(Component === Radio.Group) {
    rest.onBlur = () => {};
  }
  const hasError = meta.touched && meta.invalid;
  return (
    <Form.Item
      label={label}
      className={icon ? '' : 'no-icon'}
      validateStatus={hasError ? "error" : "success"}
      hasFeedback={hasFeedback && hasError && !checkbox}
      help={hasError && meta.error}
    >
      <Component autoComplete='chrome-off' label={label} {...input} {...rest} children={children}/>
    </Form.Item>
  );
};

export const CreateTextField = createField(Input);
export const CreateRadioGroup = createField(Radio.Group, false, false, false);
export const CreateNumberField = createField(InputNumber);
export const CreateTextArea = createField(Input.TextArea);
export const CreatePasswordField = createField(Input.Password);
export const CreateSelectField = createField(Select, false, false, false, true);
export const CreateDatePicker = createField(DatePicker, false, true);
export const CreateCheckbox = createField(Checkbox, true);
export const CreateFileField = createField(DropZoneField);
export const CreateFileField2 = createField(Upload,false,false,false,false,true);
