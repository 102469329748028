import React from "react";

// import 'antd/lib/icon/style/css';


import {Button, Col, Row} from "antd";
import { ReactComponent as PlusCircle } from '../../../resources/img/NewIcons/sign/plus-circle.svg';
import { ReactComponent as SignUploadIcon } from '../../../resources/img/NewIcons/sign/signUploadIcon.svg';

const Placeholder = ({getInputProps, getRootProps, error, touched, label,label2,myRef}) => (
  <div
    {...getRootProps()}
    className={`placeholder-preview ${error && touched ? "has-error" : ""}`}
  >
    <input {...getInputProps()} ref={myRef}/>
    <Row style={{height:'180px'}} className={'h-100'}>
      <Col style={{height:'180px', background:'white', paddingTop:'20px'}} span={24}>
        <div style={{display:'inline-block', position:'relative'}}>
          <SignUploadIcon />
          <PlusCircle style={{width:'30px', height:'30px',position:'absolute', top:-16, right:-14}} />
        </div>
        
        <div>
          <Button style={{width:170,margin:0}} type={'primary light-primary rounded-sm'}>{label}</Button>
        </div>
        <div>
          <span style={{color:'black'}}>{label2}</span>
        </div>
      </Col>
    </Row>
  </div>
);

export default Placeholder;
