import React from "react";

// import 'antd/lib/popover/style/css';


import PrivateRoute from "../Root/PrivateRoute";
import { connect } from "react-redux";
import PDFViewer from "../PDFViewer";
import { Resizable } from "react-resizable";
import Draggable from "react-draggable";
import { Button, Col, Dropdown, Menu, Row, Tooltip, Typography } from "antd";
import load_files from "../../const/load_files";
import { putFilesLocalNew } from "../../actions/sendFiles/putFiles";
import { INFO_SHOW, PUT_FILES } from "../../actions/types";
import infoPayload from "../../actions/helpers/payloadHandlers/infoPayload";
import { push } from "connected-react-router";
import { HOME_ROUTE, REDIRECT_DOCS_TABS_ROUTE, SEND_AND_SIGN_ROUTE } from "../Root/routes";
import { isPDF, sGrid } from "../Helpers/utils";
import SubmitButton from "../Helpers/SubmitButton";
import signPendingFiles from "../../actions/files/signPendingFiles";
import hideAllMenu from "../../actions/info/hideAllMenu";
import dotsIcon from "../../resources/img/NewIcons/DotsIcon.svg";
import layout from "../../const/layout";

class Locate extends React.Component {
    minWidth = 80;
    minHeight = 40;
    initialHeight = this.minHeight;
    multipleLabel = "";
    constructor(props) {
        super(props);
        let coords = {};
        this.getSigners(props).map((signer, index) => {
            const first = this.getInitialCoords(signer, index);
            first.dbPage = 1;
            first.id = 0;
            coords[signer] = [first];
            return "";
        });
        this.state = { currentFile: 0, coords: coords, pdfPageInfo: undefined, passwords: props.formValues.passwords, currentSigner: null };
    }

    componentWillUnmount() {
        this.props.hideAllMenu(false);
    }

    componentDidMount() {
        this.props.hideAllMenu(true);
        if (!this.props.files || this.props.files.length < 1)
            this.props.dispatch(push(SEND_AND_SIGN_ROUTE));
        this.moveNextFile();
    }

    moveNextFile = () => {
        const files = this.props.files, newCurrentFile = this.state.currentFile + 1;
        if (files.length < newCurrentFile)
            this.handleSubmit();
        else {
            const fileName = this.getCurrentFile().name;
            if (!isPDF(fileName))
                this.setState({ currentFile: newCurrentFile }, this.moveNextFile);
        }
    };

    getInitialCoords = (signer, index) => {
        const llx = 0, lly = this.getLly(index) + 10, urx = llx, ury = lly + this.initialHeight;
        const fieldWidth = Math.max(this.getTextWidth(signer) + 5, this.minWidth);
        return { "llx": llx - fieldWidth, "lly": lly, "urx": urx, "ury": ury, "locked": false, "saved": false };
    };

    getLly = (index) => {
        return index * this.initialHeight;
    };

    getSigners = (props = undefined) => {
        if (!props)
            props = this.props;
        if (props.formValues.multipleWorkflow)
            return [this.multipleLabel];
        return this.getSignersFromPutFiles(props);
    };

    getSignersFromPutFiles = (props) => {
        let signers = props.formValues.usersToPut;
        if (!signers || signers.length < 1) {
            const userName = props.user.userName;
            if (userName && userName !== '')
                signers = [props.user.userName];
            else
                signers = [];
        }
        return signers;
    }

    getTextWidth = (text) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = "14px sans-serif";
        return context.measureText(text).width;
    }

    onResize = (event, { size }, signer, id) => {
        const pdfPageInfo = this.state.pdfPageInfo;
        let coords = Object.assign({}, this.state.coords);
        coords[signer][id].ury = coords[signer][id].lly + size.height;
        coords[signer][id].urx = coords[signer][id].llx + size.width;
        const newCoords = this.fixCoordsDraggableSize(coords[signer][id].llx, coords[signer][id].lly, size.width,
            size.height, pdfPageInfo.width, pdfPageInfo.height);
        newCoords.dbPage = coords[signer][id].dbPage;
        newCoords.rotate = coords[signer][id].rotate;
        newCoords.id = coords[signer][id].id;
        newCoords.saved = coords[signer][id].saved;
        this.updateCoords(newCoords, signer);
    };

    onLock = (signer, locked, id) => {
        this.setState(prevState => {
            let coords = Object.assign({}, prevState.coords);
            const newLocked = locked ? undefined : true;
            coords[signer][id].locked = newLocked;
            coords[signer][id].pdfPageInfo = this.state.pdfPageInfo;
            return { coords, currentSigner: { signer, id, locked: newLocked } };
        });
    };

    addField = (signer, page) => {
        const currentPage = page ? page : this.state.pdfPageInfo.number;
        this.setState(prevState => {
            let coords = Object.assign({}, prevState.coords);
            const newField = this.getInitialCoords(signer, 0);
            newField.dbPage = currentPage;
            newField.id = coords[signer].length;
            coords[signer].push(newField);
            return { coords };
        });
    };

    removeField = (signer) => {
        this.setState(prevState => {
            let coords = Object.assign({}, prevState.coords);
            coords[signer].pop();
            return { coords };
        }, () => this.setState({ currentSigner: null }));
    };

    onChange = (e, data, signer, coord) => {
        const size = this.getSize(coord), newCoords = {
            llx: data.x, lly: data.y, urx: data.x + size.width,
            ury: data.y + size.height, dbPage: coord.dbPage, rotate: coord.rotate, id: coord.id, saved: coord.saved
        };
        this.updateCoords(newCoords, signer);
    };

    updateCoords = (newCoord, signer) => {
        const id = newCoord.id;
        this.setState(prevState => {
            let coords = Object.assign({}, prevState.coords);
            coords[signer][id].llx = newCoord.llx;
            coords[signer][id].lly = newCoord.lly;
            coords[signer][id].urx = newCoord.urx;
            coords[signer][id].ury = newCoord.ury;
            coords[signer][id].dbPage = newCoord.dbPage;
            coords[signer][id].rotate = newCoord.rotate;
            coords[signer][id].id = id;
            return { coords };
        });
    };

    getSize = (signerCoords) => {
        const width = signerCoords.urx - signerCoords.llx, height = signerCoords.ury - signerCoords.lly;
        return { width, height }
    };

    fixCoordsDraggableSize = (xCord, yCord, width, height, pageWidth, pageHeight) => {
        let urx = xCord + width, ury = yCord + height;
        let draggableWidth = width, draggableHeight = height;
        if (xCord < -pageWidth) {
            console.warn("xcord negative, using -" + pageWidth);
            return this.fixCoordsDraggableSize(-pageWidth, yCord, width, height, pageWidth, pageHeight);
        }
        if (yCord < 0) {
            console.warn("ycord negative, using 0", yCord);
            return this.fixCoordsDraggableSize(xCord, 0, width, height, pageWidth, pageHeight);
        }
        if (urx > 0) {
            let newXCord = xCord - urx;
            console.warn("Draggable position greater than page width, set xcord to:" + (newXCord));
            return this.fixCoordsDraggableSize(newXCord, yCord, width, height, pageWidth, pageHeight);
        }
        if (ury > pageHeight) {
            let newYCord = yCord - (ury - pageHeight);
            console.warn("Draggable position greater than page height, set ycord to:" + (newYCord));
            return this.fixCoordsDraggableSize(xCord, newYCord, width, height, pageWidth, pageHeight);
        }
        if (pageWidth < draggableWidth) {
            console.warn("Draggable width greater than page width, set to:" + pageWidth);
            return this.fixCoordsDraggableSize(xCord, yCord, pageWidth, height, pageWidth, pageHeight);
        }
        if (pageHeight < draggableHeight) {
            console.warn("Draggable height greater than page height, set to:" + pageHeight);
            return this.fixCoordsDraggableSize(xCord, yCord, width, pageHeight, pageWidth, pageHeight);
        }
        return { llx: parseInt(xCord), lly: parseInt(yCord), urx: parseInt(urx), ury: parseInt(ury) };
    }

    getButtonLabel = (locked) => {
        return locked ? load_files[this.props.language].move : load_files[this.props.language].lock;
    };

    getCursor = (locked) => {
        return locked ? 'default' : 'move';
    };

    onPageLoadSuccess = (pdfPageInfo) => {
        this.setState({ pdfPageInfo: pdfPageInfo }, () => this.resetCoords(pdfPageInfo));
    };

    resetCoords = (pdfPageInfo) => {
        const coords = this.state.coords, signers = Object.keys(coords);
        let total = 0;
        for (let i = 0; i < signers.length; i++) {
            const signer = signers[i], signerCoords = coords[signer];
            for (let j = 0; j < signerCoords.length; j++) {
                const coord = signerCoords[j];
                if (!coord.saved && !coord.locked) {
                    const initialCoords = this.getInitialCoords(signer, total);
                    initialCoords.dbPage = pdfPageInfo.number;
                    initialCoords.rotate = pdfPageInfo.rotate;
                    initialCoords.id = coord.id;
                    initialCoords.saved = coord.saved;
                    this.updateCoords(initialCoords, signer);
                    total++;
                }
            }
        }
    };

    handleItemClick = (evt, signer, locked, id) => {
        switch (evt.key) {
            case "0":
                this.onLock(signer, locked, id);
                break;
            case "1":
                this.addField(signer);
                break;
            case "2":
                this.removeField(signer);
                break;
            default:
                break;
        }
    }

    renderLocateOptions = (signer, locked, id) => {
        const items = [{ key: '0', label: this.getButtonLabel(locked) },
        { key: '1', label: load_files[this.props.language].duplicateSigner }
        ];
        if (id > 0 && this.state.coords[signer].length === id + 1)
            items.push({ key: '2', label: load_files[this.props.language].deleteSigner });
        return <Menu items={items} onClick={(evt) => this.handleItemClick(evt, signer, locked, id)} />;
    };

    renderField = (signer, index, pdfPageInfo, coord) => {
        const { llx, lly, locked, id } = coord;
        const { width, height } = this.getSize(coord);
        return <Draggable key={index} axis="both" bounds={'.pdfContainer'} handle={'.myHandle'}
            position={{ x: llx, y: lly }} disabled={locked}
            scale={1}
            onStop={(e, data) => this.onChange(e, data, signer, coord)}>
            <div style={{ display: 'inline-flex', position: 'absolute', zIndex:2 }}
                onClick={() => this.setState({ currentSigner: { signer, locked, id } })}>
                <Row style={{ width: '100%' }}>
                    <Col style={{ height: '10px' }} span={24}>
                        <div>{signer}</div>
                        <div style={{ position: 'absolute', top: '-10px', left: (width + 5) + 'px' }}>
                            <Dropdown trigger={['click']} overlay={() => this.renderLocateOptions(signer, locked, id)}>
                                <Button style={{ border: 0, backgroundColor: 'transparent', width: '5px', height: '15px' }} htmlType={'button'} icon={<img alt={''} src={dotsIcon} />} />
                            </Dropdown>
                        </div>
                    </Col>
                    <Col span={24}>
                        <Resizable height={height} width={width} axis={locked ? 'none' : 'both'}
                            onResize={(event, { size }) => this.onResize(event, { size }, signer, id)}
                            minConstraints={[this.minWidth, this.minHeight]}
                            //maxConstraints={[pdfPageInfo.width, pdfPageInfo.height]}>
                            maxConstraints={[300, 200]}>
                            <div style={{
                                display: 'flex', textAlign: 'center', alignItems: 'baseline', paddingTop: '8px',
                                width: width + 'px', height: height + 'px', cursor: this.getCursor(locked)
                            }}>
                                <Row style={{ width: '100%' }}>
                                    <Col span={24}>
                                        <div className={'myHandle'}
                                            style={{
                                                cursor: this.getCursor(locked), height: height + 'px', fontFamily: 'sans-serif',
                                                fontSize: '14px'
                                            }}>
                                        </div>
                                    </Col>
                                    {/*<Col span={24}>
                                <Button style={{borderRadius:'0', height:'15px', lineHeight:0}} htmlType={'button'}
                                        type={'primary'} onClick={()=>this.onLock(signer,locked,id)}>
                                    {this.getButtonLabel(locked)}
                                </Button>
                            </Col>*/}
                                    {/*<Col span={24}>
                                <Dropdown overlay={()=>this.renderLocateOptions(signer,locked,id)}>
                                    <Button style={{borderRadius:'0', maxHeight:'15px', lineHeight:0}} htmlType={'button'}
                                            type={locked?'danger':'primary'}>...</Button>
                                </Dropdown>
                            </Col>*/}
                                </Row>
                            </div>
                        </Resizable>
                    </Col>
                </Row>
            </div>
        </Draggable>
    };

    renderSignatureFields = () => {
        const pdfPageInfo = this.state.pdfPageInfo, fields = [];
        if (pdfPageInfo) {
            const coords = this.state.coords, signers = Object.keys(coords);
            for (let i = 0; i < signers.length; i++) {
                const signer = signers[i], signerCoords = coords[signer];
                for (let j = 0; j < signerCoords.length; j++) {
                    const coord = signerCoords[j];
                    if (!coord.saved) {
                        const fieldPage = coord.dbPage, currentPage = pdfPageInfo.number;
                        if (fieldPage === currentPage)
                            fields.push(this.renderField(signer, i + '-' + j, pdfPageInfo, coord));
                    }
                }
            }
            return fields;
            //return this.getSigners().map((signer,index)=>this.renderField(signer,index,pdfPageInfo));
        }

    }

    handleSubmit = () => {
        const files = this.props.files, newCurrentFile = this.state.currentFile + 1;
        if (!files || files.length < 1)
            return;
        const coords = this.state.coords, signers = Object.keys(coords);
        if (files.length > newCurrentFile) {
            for (let i = 0; i < signers.length; i++) {
                const signer = signers[i], signerCoords = coords[signer];
                let lastID = 0;
                for (let j = 0; j < signerCoords.length; j++) {
                    const newCoord = signerCoords[j];
                    if (!newCoord.fileName)
                        newCoord.fileName = this.getCurrentFile().name;
                    newCoord.saved = true;
                    lastID = newCoord.id;
                    this.updateCoords(newCoord, signer);
                }
                const first = this.getInitialCoords(signer, i);
                first.dbPage = 1;
                first.id = lastID + 1;
                signerCoords.push(first);
            }
            this.setState({ pdfPageInfo: undefined, currentFile: newCurrentFile }, this.moveNextFile);
            return;
        }

        let newUsersToPut = {};
        for (let i = 0; i < signers.length; i++) {
            const signer = signers[i], signerCoords = coords[signer];
            for (let j = 0; j < signerCoords.length; j++) {
                const pdfPageInfo = signerCoords[j].pdfPageInfo;
                if (pdfPageInfo) {
                    let { width, height, rotate } = pdfPageInfo;
                    let tempWidth = width, tempHeight = height;
                    const currentRotation = signerCoords[j].rotate;
                    if (currentRotation !== rotate) {
                        const newRotation = Math.abs(currentRotation - rotate);
                        if (newRotation === 90 || newRotation === 270) {
                            // noinspection JSSuspiciousNameCombination
                            tempWidth = height;
                            // noinspection JSSuspiciousNameCombination
                            tempHeight = width;
                        }
                    }
                    signerCoords[j].llx += tempWidth;
                    signerCoords[j].urx += tempWidth;
                    const oldURY = signerCoords[j].ury;
                    signerCoords[j].ury = tempHeight - signerCoords[j].lly;
                    signerCoords[j].lly = signerCoords[j].ury - oldURY + signerCoords[j].lly;
                    if (!signerCoords[j].fileName)
                        signerCoords[j].fileName = this.getCurrentFile().name;
                    delete signerCoords[j].locked;
                    delete signerCoords[j].rotate;
                    delete signerCoords[j].saved;
                    delete signerCoords[j].pdfPageInfo;
                }
            }
            newUsersToPut[signer] = JSON.stringify(signerCoords);
        }
        const newFormValues = this.props.formValues;
        if (this.props.formValues.multipleWorkflow) {
            newUsersToPut = {};
            const originalSigners = this.getSignersFromPutFiles(this.props);
            for (let i = 0; i < originalSigners.length; i++)
                newUsersToPut[originalSigners[i]] = JSON.stringify(coords[this.multipleLabel]);
        }
        newFormValues.newUsersToPut = newUsersToPut;
        //console.log(newUsersToPut);
        //return;
        const dispatch = this.props.dispatch;
        newFormValues.passwords = this.state.passwords;
        this.props.putFilesLocalNew(files, newFormValues).then((result) => {
            if (result && Array.isArray(result) && result.length > 0) {
                if (this.props.formValues.multipleWorkflow)
                    result = result.filter((file) => file.signer === this.props.user.userName);
                if (this.shouldSign(newFormValues) && result.length > 0)
                    dispatch(signPendingFiles(result));
                else {
                    dispatch({ type: PUT_FILES, payload: infoPayload('success', 'Archivos cargados') });
                    dispatch(push(REDIRECT_DOCS_TABS_ROUTE + '?active=newworkflow'));
                }
            }
        });
    };

    shouldSign = (newFormValues) => {
        const users = Object.keys(newFormValues.newUsersToPut), userName = this.props.user.userName;
        return users.includes(userName) && (!newFormValues.ordered || (newFormValues.ordered && users[0] === userName));
    };

    renderSendComponent = () => {
        let render = true;
        const coords = this.state.coords, signers = Object.keys(coords), files = this.props.files;
        const isMobile = this.props.dimApp.width <= sGrid, cardPadding = isMobile ? 5 : 70
        if (!files || files.length < 1)
            return <></>;
        for (let i = 0; i < signers.length; i++) {
            const signerCoords = coords[signers[i]];
            for (let j = 0; j < signerCoords.length; j++) {
                if (!signerCoords[j].locked) {
                    render = false;
                    break;
                }
            }
        }
        return <Row style={{
            width: '100%', position: 'fixed', bottom: 0, left: 0, paddingTop: '10px', paddingBottom: '10px', alignItems: 'center',
            background: "#FFFFFF", boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.1)"
        }}>
            <Col xs={24} sm={16}>
                <Typography className='size12' style={{ marginRight: 3, marginLeft: cardPadding }}>
                    {layout[this.props.language].footer + ' '}
                    <a target="_blank" rel="noopener noreferrer" href='https://www.netcosigner.com/' className='text-title size12 mb-20'>{layout[this.props.language].footer1}</a>
                </Typography>

            </Col>
            <Col style={{ textAlign: 'right' }} xs={10} sm={4}>
                <Button style={{ minHeight: '48px', width: '138px', marginBottom: 0 }} type={'light-primary rounded-sm'}
                    onClick={() => this.props.dispatch(push(HOME_ROUTE))}>
                    {load_files[this.props.language].cancelLabel.toUpperCase()}
                </Button>
            </Col>
            <Col style={{ textAlign: 'center' }} xs={10} sm={4}>
                <Tooltip placement="bottom" title={render ? '' : load_files[this.props.language].setBeforeSend}><div style={{ textAlign: 'center' }}>
                    <SubmitButton disabled={!render} style={{ minHeight: '48px', width: '138px', marginBottom: 0 }} type={"primary light-primary rounded-sm"}
                        label={files.length > this.state.currentFile + 1 ? load_files[this.props.language].next : load_files[this.props.language].title}
                        onSubmit={this.handleSubmit} />
                </div></Tooltip>
            </Col>
        </Row>;
        /*return <Tooltip placement="bottom" title={render?'':load_files[this.props.language].setBeforeSend}><div style={{textAlign:'center'}}>
            <SubmitButton disabled={!render} type={'primary dark-blue'} onSubmit={this.handleSubmit}
                          label={files.length>this.state.currentFile+1?load_files[this.props.language].next:load_files[this.props.language].title}/>
        </div></Tooltip>;*/
    };

    getCurrentFile = () => {
        const files = this.props.files, filesLength = files.length, currentFile = this.state.currentFile;
        if (!files || filesLength < 1)
            return undefined;
        if (filesLength < currentFile) {
            console.log("Error, invalid currentFile " + currentFile);
            return undefined;
        }
        return files[currentFile];
    }

    onPassword = (newPassword, error) => {
        if (newPassword) {
            const newPasswords = {};
            newPasswords[this.getCurrentFile().name] = newPassword;
            this.setState(prevState => {
                let passwords = Object.assign(newPasswords, prevState.passwords);
                return { passwords };
            });
        }
        else if (error) {
            console.log(error);
            this.props.dispatch(push(SEND_AND_SIGN_ROUTE));
        }
    };

    onLoadError = (error) => {
        const data = { info: { message: error.message, type: 'error' } };
        this.props.dispatch({ type: INFO_SHOW, payload: data });
        this.props.dispatch(push(SEND_AND_SIGN_ROUTE));
    }

    render() {

        const file = this.getCurrentFile();
        if (!file || !isPDF(file.name))
            return <></>;
        return <><nav>
            {/*<NewLocateOptionsForm mode={'horizontal'}  dimApp={this.props.dimApp} language={this.props.language}
                                  onClose={()=>this.setState({currentSigner:null})} currentSigner={this.state.currentSigner}
                                  onLockChange={this.onLock} onDuplicate={this.addField}/>*/}
        </nav><PDFViewer onPassword={this.onPassword} onLoadError={this.onLoadError} currentSigner={this.state.currentSigner}
            onClose={() => this.setState({ currentSigner: null })} onLockChange={this.onLock} onDuplicate={this.addField}
            onPageLoadSuccess={this.onPageLoadSuccess}
            submitComponent={this.renderSendComponent()}
            file={file} draggableComponent={this.renderSignatureFields()} />
        </>;
    }
}

const mapStateToProps = state => {
    return {
        language: state.auth.language,
        files: state.locate.files,
        formValues: state.locate.formValues,
        user: state.user,
        visible: state.locate.visibleLocateOptions,
        dimApp: state.locate.dimensionsApp,
    };
};

export default PrivateRoute(connect(mapStateToProps, { putFilesLocalNew, hideAllMenu })(Locate));

