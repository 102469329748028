import netcoServer from "../../apis/netcoServer";
import {push} from 'connected-react-router';
import {authType} from "../../const/authType";
import auth from "../../const/auth";
import {CHECK_STRONG_AUTHENTICATION, CREATE_SESSION, INFO_SHOW, START_STRONG_AUTHENTICATION} from "../types";
import {
  CERTIFICATES_ROUTE,
  FINGERPRINT_ROUTE,
  OTP_CODE_ROUTE,
  SQRL_ROUTE
} from "../../components/Root/routes";
import loadingCall from "../helpers/loadingCall";
import infoShow from "../info/infoShow";
import invalidSession from "./invalidSession";
import {dispatchRootRoute} from "../helpers/utils";


const startStrongAuthentication= (resend = false) => async (dispatch, getState) => {
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/LoginService/startStrongAuthentication', {}));
  if (response.data.success === 'true' || response.data.success === true) {
      switch (authType[response.data.authType]) {
        case 'BASIC_AUTHENTICATION':
          sessionStorage.setItem("idp", getState().auth.params.state);
          sessionStorage.setItem("logged", true);
          dispatch({type:CREATE_SESSION,payload:{hasSession:true}});
          if (getState().auth.invalidSessionPath) {
            dispatch(push(getState().auth.invalidSessionPath));
            dispatch(invalidSession(true));
          } else
            dispatchRootRoute(dispatch,getState().auth.params);
          break;
        case 'FINGERPRINT_AUTHENTICATION':
          dispatch(push(FINGERPRINT_ROUTE));
          break;
        case 'SQRL_AUTHENTICATION':
          dispatch({type: START_STRONG_AUTHENTICATION, payload: {auth: {sqrl: response.data.sqrl, sqrlUrl: response.data.sqrlUrl, nut: response.data.nut}}});
          dispatch(push(SQRL_ROUTE));
          break;
        default:
          switch (authType[response.data.authType]) {
            case 'SMS_OTP_AUTHENTICATION':
              dispatch(infoShow({info: {message: auth[getState().auth.language].otpsentsmsmsg + `${response.data.phone}`, type: 'success'}}));
              break;
            case 'MAIL_OTP_AUTHENTICATION':
              dispatch(infoShow({info: {message: auth[getState().auth.language].otpsentmsg + `${response.data.mail}`, type: 'success'}}));
              break;
            case 'PHONE_OTP_AUTHENTICATION':
              dispatch(infoShow({info: {message: auth[getState().auth.language].otpsentcallmsg + `${response.data.phone}`, type: 'success'}}));
              break;
            default:
              break;
          }
          dispatch(push(OTP_CODE_ROUTE));
          break;
      }
      if(resend)
        dispatch({type: INFO_SHOW, payload: {info: {type: 'info', message: auth[getState().auth.language].otpresent}}})
    }
    else
    {
      if (response.data.requiresCert) {
        dispatch({type: CHECK_STRONG_AUTHENTICATION, payload: {
            auth: {
              requiresCert: response.data.requiresCert,
              entrust: response.data.entrust,
              netco: response.data.netco,
              ldap: response.data.ldap,
              enableecom:response.data.enableecom,
              limitFiles:response.data.limitFiles
            },
            info: {
              message: auth[getState().auth.language].otpnocert,
              type: 'success'
            }
          }});
        sessionStorage.setItem("logged", true);
        dispatch({type:CREATE_SESSION,payload:{hasSession:true}});
        dispatch(push(CERTIFICATES_ROUTE));
      }
      else
        dispatch(infoShow({info: {message: response.data.detail, type: 'error'}}));
    }
  
};
export default startStrongAuthentication;