import netcoServer from '../../apis/netcoServer';
import {DEL_SIGNED_FILES} from "../types";
import handleResponse from "../helpers/handleResponse";
import _ from 'lodash';
import loadingCall from "../helpers/loadingCall";

const delSignedFiles= (selectedFiles,newuids,published, callback,userName='',comment=null) => async (dispatch) => {
  let formData = {uids: _.join(selectedFiles, ','),published:published,userName:userName,comment:comment};
  if(newuids && newuids.length>0)
    formData.newuids=JSON.stringify(newuids);
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/delSignedFiles', formData));
  handleResponse(dispatch, response, DEL_SIGNED_FILES, onSuccessPayload, null, [], [callback]);
};

const onSuccessPayload = () => {
  //return infoPayload('success', 'Archivos eliminados correctamente!');
};
export default delSignedFiles;