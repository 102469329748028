import React, { useState } from 'react';
import { Field, reduxForm } from "redux-form";
import { Form, Button } from "antd";
import { CreateCheckbox, CreateFileField, CreatePasswordField } from "../Helpers/Input/InputCreator";
import { required } from "../Helpers/Input/validations";
import { connect } from "react-redux";
import importCertificateNetcoCodes from '../../actions/certificates/importCertificateNetcoCodes';
import PrivateRoute from "../Root/PrivateRoute";
import certificates from "../../const/certificates";
import general from "../../const/general";
import { ReactComponent as OtherCertificatesIcon } from '../../resources/img/NewIcons/certificates/other-certificates.svg'
import { INFO_SHOW } from "../../actions/types";
import infoPayload from "../../actions/helpers/payloadHandlers/infoPayload";

const P12Option = (props) => {

    const [formFile, setFormFile] = useState([])

    const onSubmit = (formValues) => {
        let file = formFile[0] === undefined ? null : formFile[0].file;
        formValues.language = props.language;
        formValues.requiresCert = props.requiresCert;
        props.importCertificateFromP12(formValues, file);
    };

    const handleOnDropFile = (newFile) => {
        if (!newFile || newFile.length <= 0) {
            props.dispatch({ type: INFO_SHOW, payload: infoPayload('error', certificates[props.language].invalidFile) });
            return;
        }
        const formFileDrop = {
            file: newFile[0],
            name: newFile[0].name,
            preview: URL.createObjectURL(newFile[0]),
            size: newFile[0].size
        };
        props.change('avatar', formFileDrop);
        setFormFile([formFileDrop])
    };

    return (
        <>
            {props.renderCardCertificates(certificates[props.language].pkcs12Title, certificates[props.language].p12_info, OtherCertificatesIcon, certificates[props.language].p12_info2)}
            <Form onFinish={props.handleSubmit(onSubmit)} className={'text-title size12 mt-20'} style={{ marginLeft: 14 }}>
                <div className={'mb-10 mt-10'}>{certificates[props.language].p12_file}</div>
                <Field
                    name="avatar"
                    component={CreateFileField}
                    handleOnDrop={handleOnDropFile}
                    type='file'
                    formFile={formFile}
                    validate={[required]}
                    multiple={false}
                />
                <div className={'mb-10 mt-10'}>{general[props.language].password}</div>
                <Field
                    name="password"
                    component={CreatePasswordField}
                    size={'large'}
                    className={'mb-10'}
                    placeholder={general[props.language].password}
                    validate={[required]}
                    hasFeedback />
                <Field name="agree"
                    type={'checkbox'}
                    component={CreateCheckbox}
                    validate={required}
                    hasFeedback>
                    <a rel='noopener noreferrer' target={'_blank'} href={window.netcourlid + '/comagre/AcuerdoDeComunicaciones.pdf'}>
                        {general[props.language].agree}
                    </a>
                </Field>
                <Field name="updateGraphicImage"
                    type={'checkbox'}
                    component={CreateCheckbox}
                    hasFeedback>
                    {certificates[props.language].graphic_image}
                </Field>
                <Button htmlType={'submit'} className='mt-20' type={'primary'} style={{ marginRight: 21 }}>
                    {certificates[props.language].createP12}
                </Button>
                <Button type={'ghost'} onClick={() => props.changeOption(0)}>
                    {certificates[props.language].cancel}
                </Button>
            </Form>
        </>
    );
};

const mapStateToProps = state => {
    return {
        language: state.auth.language,
        requiresCert: state.auth.requiresCert,
        initialValues: {
            agree: false,
            updateGraphicImage: false
        }
    }
};

export default PrivateRoute(connect(mapStateToProps, { importCertificateNetcoCodes })((reduxForm({
    form: 'netcoCertificate_form'
})(P12Option))));

