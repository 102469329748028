const home = {
    es: {
        greeting: 'Hola,',
        selectStartSign:'Elige cómo quieres gestionar tus firmas.',
        configureSignature:'Configura tú firma',
        startSign:'Empieza a firmar',
        verifySignature:'Verificar firmas',
        pendingDocs: 'Pendientes por firmar',
        signedOk1: '¡Únete y firma digitalmente! Regístrate ahora',
        signedOk2: 'Podrás acceder y visualizar tus documentos',
        signedOk3: 'No te preocupes, si decides no registrarte, aún recibirás una copia de tus documentos firmados por correo electrónico.',
        signedError1: 'No pudimos finalizar esta acción',
        signedError2: '',
        signedError3: 'Si tienes dudas, simplemente comunicate con quién compartió el documento contigo o con tu administrador. ¡Estamos aquí para ayudarte!',
        noThanks: 'No, gracias',
        signUpHere: '¡REGÍSTRATE AQUÍ!',
        deleteUser: 'Eliminar usuario',
        deleteUserDescription: 'Tambien puedes eliminar tu cuenta'
    },
    en: {
        greeting: 'Hello,',
        selectStartSign:'Choose how you want to manage your signatures.',
        configureSignature:'Configure your signature',
        startSign:'Start signing',
        verifySignature:'Verify signatures',
        pendingDocs: 'Pending sign',
        signedOk1: 'Join and sign digitally! Sign up now',
        signedOk2: 'You will be able to access and view your documents',
        signedOk3: 'Do not worry, if you decide not to register, you will still receive a copy of your signed documents by email.',
        signedError1: 'Error signing',
        signedError2: '',
        signedError3: 'Please contact the creator of the document or your system administrator. ¡ We are here for you!',
        noThanks: 'No, thanks',
        signUpHere: 'SIGN UP HERE!',
        deleteUser: 'Delete user',
        deleteUserDescription: 'You can also delete your account'
    }
};
export default home;
