import netcoServer from '../../apis/netcoServer';
import {UPDATE_USER_FINGERPRINT} from "../types";
import handleResponse from '../helpers/handleResponse';
import infoPayload from "../helpers/payloadHandlers/infoPayload";
import {ROOT_ROUTE} from "../../components/Root/routes";
import getUserInfo from "./getUserInfo";
import loadingCall from "../helpers/loadingCall";
import {INFO_SHOW} from "../types";

const updateUserFingerprint= (formValues) => async (dispatch) => {
  formValues.encrypted = false;
  let response = await loadingCall(dispatch, () => netcoServer.newPost('/UserService/updateUserFingerprint', formValues));
  if (response.data.success)
	dispatch({type: INFO_SHOW, payload: infoPayload('success', 'Huella guardada satisfactoriamente!')})
  handleResponse(dispatch, response, UPDATE_USER_FINGERPRINT, onSuccessPayload, ROOT_ROUTE, [getUserInfo]);
};

const onSuccessPayload = () => {
  return infoPayload('success', 'Huella guardada satisfactoriamente!');
};
export default updateUserFingerprint;